import React from 'react';
import {observer} from 'mobx-react';
import {Helmet} from "react-helmet";


class ReactHelmet extends React.Component {
    componentDidMount() {
        // QUICKFIX
        // window.document.title = this.titleString()
    }
    
    titleString(){
        const {title} = this.props
        return `${title} | BlueBic`
    }

    render(){
        return (
            <Helmet>
                <title>{this.titleString()}</title>
            </Helmet>
        )
    }
}

export default ReactHelmet;