import { types } from "mobx-state-tree"
import { Employee } from './Employee';
import { Batch } from './Batch';

export const BatchesReportCardData = types
    .model("BatchesReportCardData", {
        id: types.identifier, // ReportCardGroup.id + batch.id
        batch_id: types.late(()=> types.reference(Batch)),
        teacher_ids: types.array(types.late(() => types.reference(Employee))),
        student_ids: types.array(types.number),
        subjects: types.array(types.model({
            subject_base_id: types.number,
            teachers: types.array(types.late(() => types.reference(Employee))),
            cognitive_columns_summary: types.array(types.model({
                column_id: types.number,
                max_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
                average_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
                student_ids: types.array(types.number)
            })),
        })),
        cognitive_columns_summary: types.array(types.model({
            column_id: types.number,
            max_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
            average_weighted_grade: types.maybeNull(types.union(types.string, types.number))
        })),
        
    })