import React from 'react'
import {inject, observer} from 'mobx-react';
import {controlled, converters, Field, Form} from 'mstform'
import isEmpty from 'lodash.isempty'

import {ModalContext} from '../common/modal'
import ModalForm from '../containers/ModalForm'
import Button from '../common/button';
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import SelectInput from '../common/form/SelectInput';
import {StudentTransaction} from '../store/actions/Student';

export function handlePayFees({ setContent, closeModal }, studentFeeIds, studentID) {
    return setContent(
        isEmpty(studentFeeIds) ? "Credit Wallet" : "Pay Fees",
        <PayFeesForm
            closeModal={closeModal}
            studentFeeIds={studentFeeIds}    
            studentID={studentID}
        />,
        true
    )
}

function PayFees({ studentFeeIds, studentID}) {
    return (
        <ModalContext.Consumer>
            {(context) => (
                <PayFeesButton
                    onClick={() => handlePayFees(context, studentFeeIds, studentID)}
                />
            )}
        </ModalContext.Consumer>
    )
}

const PayFeesButton = inject("display")(
    observer(({ display, onClick }) => {
        if (display.isMobile) {
            return (
                <Button
                    className="utils-prepend"
                    buttonType="success"
                    onClick={onClick}
                >
                    Pay
                </Button>
            )
        }
        return (
            <button
                type="button"
                className="
                touchable-opacity
                text--primary
                text-p
                utils-prepend"
                onClick={onClick}
            >
                Pay
            </button>
        )
    })
)

@inject('store')
@observer
class PayFeesForm extends React.Component {
    state = {baseFormErrors: undefined }

    constructor(props) {
        super(props)
        
        const {studentID, studentFeeIds, store: {studentStore: { getTransactionPreflight }, meta: {currentUser}}} = this.props

        const form = new Form(StudentTransaction, {
            payment_method: new Field(converters.string, {
                controlled: controlled.object,
                required: true
            }),
            fee_amount: new Field(converters.decimal({maxWholeDigits:10, decimalPlaces:2, allowNegative:false}), { required: true }),
            student_fee_ids: new Field(converters.stringArray, {required: false})
        })

        const formInstance = StudentTransaction.create({
            student_fee_ids: studentFeeIds,
            fee_amount: '',
            payment_method: currentUser.is_employee_or_admin ?  '' : 'web_pay'
        })
        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors } = await getTransactionPreflight(studentID, node.toJSON())
                if (errors) this.setState({baseFormErrors: errors.base})
                return errors
            }
        })
    }

    handleSubmit = async () => {
        const {
            studentID,
            closeModal,
            store: {
                view: {
                    openStudentPayFeesPageById,
                    setBackPage,
                    getStudentPage
                }
            }
        } = this.props
        
        
        const success = await this.formState.save()
        if (success) {
            closeModal()
            setBackPage('Student Profile', getStudentPage())
            openStudentPayFeesPageById(studentID)
        }
    }

    render() {
        const { closeModal, store } = this.props
        const {
            paymentMethodOptions,
            onlinePaymentMethodOptions,
            studentStore,
            meta: {currentUser}
        } = store

        const fee_amount = this.formState.field("fee_amount")
        const payment_method = this.formState.field("payment_method")

        const {baseFormErrors} = this.state;

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText="Make Payment"
                okButtonType="success"
                isOkButtonDisabled={!this.formState.isValid || studentStore.payFeeLoading}
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                    
                <Label text="Enter Amount" required={fee_amount.required}>
                    <InlineError field={fee_amount}>
                        <TextInput {...fee_amount.inputProps} />
                    </InlineError>
                </Label>

                {currentUser.is_employee_or_admin && (
                    <Label text="Payment Method" required={payment_method.required}>
                        <InlineError field={payment_method}>
                            <SelectInput
                                {...payment_method.inputProps}
                                options={currentUser.is_employee_or_admin ? paymentMethodOptions : onlinePaymentMethodOptions}
                                placeholder="Select payment method"
                            />
                        </InlineError>
                    </Label>
                )}
            </ModalForm>
        )
    }

}

// export { handlePayFees }
export default PayFees