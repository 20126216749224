/* eslint-disable camelcase */
import './StudentSummary.css'

import React, { Component } from 'react'
import {Tooltip} from 'antd'
import { observer, inject } from 'mobx-react'
import { Collapse } from 'react-collapse'
import NotDesktop from '../common/display/NotDesktop'
import Avatar, { AVI_TYPES } from '../common/avatar'
import create from './assets/create.svg'
import { titleCase } from '../lib/titleCase';
import ProfileLoading from '../common/loading/ProfileLoading'
import StudentPolicy from "../policies/StudentPolicy";

@inject('display', 'store')
@observer
class StudentSummary extends Component {
    state = {
        showMore: false
    }

    get enrollStatusClass() {
        const { student } = this.props
        const { enrollStatus } = student
        const baseClass = "text-h4"
        if (enrollStatus === "enrolled") {
            return `${baseClass} text--success`
        }
        if (enrollStatus === "graduated") {
            return `${baseClass} text--primary`
        }
        return `${baseClass} text--danger`
    }

    toggleShowMore = () => {
        let { showMore } = this.state
        showMore = !showMore
        this.setState({ showMore })
    }

    render() {
        const { student, display, store } = this.props
        const { formatCurrency, view: { openEditStudentPage, selectedStudent }, meta: {currentUser} } = store
        const { showMore } = this.state
        const {
            attributes: {
                photo_url,
                gender,
                block_reason,
                due_fees_balance,
                wallet_balance
            },
            studentName,
            batchNameNoSession: batch,
            session,
            age,
            enrollStatus,
            accountStatus,
            admissionNumber,
            batchTransfersFirstAndLastItems,
            isLoading
        } = student
        
        const studentPolicy = new StudentPolicy(currentUser, student)
 
        if (isLoading) return <ProfileLoading useCard={false} />

        return (
        <div className="StudentSummary">
            <div className="StudentSummary-cell1">
            <Avatar
                className="StudentSummary-avatar"
                src={photo_url}
                bordered
                type={AVI_TYPES.STUDENT}
                sex={gender}
            />
            <div>
                <div className="text--grey text-small">Student name</div>
                <div className="text-h4">{studentName}</div>
            </div>
            <div>
            <div className="text--grey text-small">Batch</div>
            <div className="text-p">{batch}</div>
            </div>
            <div>
            <div className="text--grey text-small">Session</div>
            <div className="text-p">{session}</div>
            </div>
            </div>

            <Collapse isOpened={display.isNotMobile ? true : showMore}>
                <div className="StudentSummary-cell2">
                <div>
                    <div className="flex-row flex-alignCenter flex-spaceBetween">
                    <div className="flex-row flex-alignCenter">
                        <span className={this.enrollStatusClass}>{titleCase(enrollStatus)}</span>
                        {/* {enrolled.tip && (
                        <Tooltip  title={enrolled.tip}>
                            <i className="material-icons
                                    text-p
                                    utils-append
                                    text--primary
                                    touchable-opacity"
                            >info</i>
                        </Tooltip>
                        )} */}
                    </div>
                        
                        {
                            studentPolicy.can_update && (
                                <button type="button" title="Edit" onClick={() => openEditStudentPage(selectedStudent)}>
                                    <img src={create} className="touchable-opacity" alt="" />
                                </button>
                            )
                        }
                    </div>
                    {batchTransfersFirstAndLastItems.map(item => (
                        <div className="text-p text--noWrap" key={item}>{item}</div>
                    ))}
                </div>
                <div>
                    <div className="text--grey text-small">Account Status</div>
                    <div className="text-p flex-row">
                        <span className={accountStatus === 'active' ? 'text--success' : 'text--grey'}>
                            {titleCase(accountStatus)}
                        </span>
                        {block_reason && (
                            <Tooltip title={block_reason}>
                            <i
                                className="
                                    material-icons
                                    text-p
                                    utils-append
                                    text--primary
                                    touchable-opacity
                                "
                            >
                                info
                            </i>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div>
                    <div className="text--grey text-small">Admission Number</div>
                    <div className="text-p">{admissionNumber}</div>
                </div>
                </div>
            </Collapse>

            <Collapse isOpened={display.isDesktop ? true : showMore}>  
                <div className="StudentSummary-cell3">
                <div>
                    {due_fees_balance && (
                    <>
                        <div className="text--grey text-small">Due Fees</div>
                        <div className="text--danger text-h4">{formatCurrency(due_fees_balance)}</div>
                    </>
                    )}
                    {wallet_balance && !due_fees_balance && (
                    <>
                        <div className="text--grey text-small">Wallet Balance</div>
                        <div className="text--success text-h4">{formatCurrency(wallet_balance)}</div>
                    </>
                    )}
                </div>
                <div>
                    <div className="text--grey text-small">Gender</div>
                    <div className="text-p">{titleCase(gender)}</div>
                </div>
                <div>
                    <div className="text--grey text-small">Age</div>
                    <div className="text-p">{age}</div>
                </div>
                </div>
            </Collapse>
            <NotDesktop>
                <div className="flex-col flex-alignEnd">
                    <span
                        className="
                            touchable-opacity
                            text-p text--bold
                            text--primary
                        "
                        style={{marginTop: -15}}
                        onClick={this.toggleShowMore}
                    >
                    {showMore ? 'Less' : 'More'}
                    </span>
                </div>
            </NotDesktop>
        </div>
        )
    }
}

export default StudentSummary