import { types } from "mobx-state-tree";
import { SearchResultsPattern } from "./searchResultsPattern";

export const CollectionPattern = ({
    collectionType,
    searchType
}) => (
        types.compose(
            SearchResultsPattern({
                searchResultType: collectionType,
                searchFormType: searchType
            }),
            types
                .model({
                    items: types.map(collectionType),
                    selectedItemKeys: types.array(types.string),
                })
                .views(self => ({
                    get isAllSelected() {
                        return !self.searchMeta.total_ids.some(val => self.selectedItemKeys.indexOf(val) === -1)
                    }
                }))
                .actions(self => {

                    function updateSearchResultsSubscribe(data) {
                        self.updateCollection(data)
                    }

                    function selectItemById(id) {
                        if (self.selectedItemKeys.includes(id)) {
                            const index = self.selectedItemKeys.findIndex(id_ => id_ === id)
                            self.selectedItemKeys.splice(index, 1)
                        } else {
                            self.selectedItemKeys.push(id)
                        }
                    }

                    function selectAllItems() {
                        if (self.isAllSelected) {
                            self.selectedItemKeys.length = 0
                        } else {
                            self.searchMeta.total_ids.forEach(id => {
                                if (!self.selectedItemKeys.includes(id)) {
                                    self.selectedItemKeys.push(id)
                                }
                            })
                        }
                    }

                    function updateCollection(data) {
                        data.forEach(json => self.items.put(json))
                    }

                    function putItem(item) {
                        self.items.put(item)
                    }

                    return {
                        selectAllItems,
                        selectItemById,
                        updateCollection,
                        updateSearchResultsSubscribe,
                        putItem
                    }

                })
        )
    )