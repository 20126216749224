import { types } from 'mobx-state-tree'
import { SafeJSONReference } from '../types/JSONAPIReferenceTypes'
import { Student } from './Student'
import { Assessment } from './Assessment'
import { AssessmentScore } from './AssessmentScore'

export const SubjectScoresheet = types
    .model("SubjectScoresheet", {
        id: types.identifier, // same as subject_id
        type: "subject_scoresheet",
        attributes: types.maybe(types.model({
            academic_term: types.maybeNull(types.number),
            subject_id: types.number
        })),
        relationships: types.maybeNull(types.model({
            assessment_scores: types.model({
                data: types.array(SafeJSONReference(AssessmentScore))
            }),
            assessments: types.model({
                data: types.array(SafeJSONReference(Assessment))
            }),
            students: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            }),
        })),
        filter_term: types.maybe(types.number),
        isLoading: true
    })
    .views(self => ({
        get assessments() {
            try {
                return self.relationships
                .assessments
                .data
                .map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get assessment_scores() {
            try {
                return self.relationships
                .assessment_scores
                .data
                .map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get students() {
            try {
                return self.relationships
                .students
                .data
                .map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        }
    }))
    .actions(self => {
        
        function markLoading(loading){
            self.isLoading = loading
        }
        
        function update({ id, attributes, relationships }) {
            self.id = id
            self.attributes = attributes
            self.relationships = relationships
        }
        
        function addAssessmentScore(  {id, type} ) {
            const { data } = self.relationships.assessment_scores
            const foundIndex = data.findIndex(({ id: n }) => n === id)
            if(foundIndex < 0){
                self.relationships.assessment_scores.data.push( {id, type})
            }
        }
        
        function setAcademicTermFilter(term) {
            self.filter_term = term
        }


        return {
            markLoading,
            update,
            addAssessmentScore,
            setAcademicTermFilter
        }
        
    })