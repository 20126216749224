import './SchoolSwap.css'

import React from 'react'
import {inject,observer} from 'mobx-react';
import SchoolSwapMenu from './SchoolSwapMenu'
import arrowDown from './assets/arrow-down.svg'


@inject("store","display", "sidebar")
@observer
class SchoolSwap extends React.Component {
    state = {showMenu: false}

    toggleMenu = () => {
        const {store: {meta: {currentUser}}} = this.props
        if (!(currentUser.is_admin || currentUser.is_employee)) return
        let {showMenu} = this.state
        showMenu = !showMenu
        this.setState({showMenu})
    }

    render() {
        const {store: {meta: {currentInstitution, currentUser}}, display, sidebar: {isSettingsPaneVisible}} = this.props
        const {attributes: {name, name_abbreviation, logo_url}} = currentInstitution
        const {showMenu} = this.state

        return (
            <div>
                <div
                    onClick={this.toggleMenu}
                    className="schoolSwap touchable-opacity"
                >
                    <div className="schoolSwap-avatar">
                        <img src={logo_url} alt="logo" />
                    </div>
                    {
                        !isSettingsPaneVisible && (
                            <>
                                <div className="schoolSwap-title">{(name.length > 23) ? name_abbreviation: name}</div>
                                {(currentUser.is_admin || currentUser.is_employee) && (
                                    <img src={arrowDown} alt="" />
                                )}
                            </>
                        )
                    }
                </div>
                <SchoolSwapMenu
                    visible={showMenu}
                    onClose={this.toggleMenu}
                />
            </div>
        )
    }
}

export default SchoolSwap
