import 'react-day-picker/lib/style.css'
import './DateRangeInput.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import Cleave from 'cleave.js/react';

import { dateRangePropType } from '../../lib/dateRangePropType';
// import withFormInput from './withFormInput'
import DateRangePicker from '../date_range_picker/DateRangePicker'
import Desktop from '../display/Desktop'
import NotDesktop from '../display/NotDesktop'
import { ModalContext } from '../modal'

// import error_ from './assets/Error.svg'
// import success_ from './assets/Success.svg'
import calendar from './assets/Calendar.svg'
import {convertDateArrayToDateRange, isValidDateRangeString, lastMonthDate} from "../../lib/formatDate";

class DateRangeInput extends Component {
    state = {
        isPopoverVisible: false,
        rangeValue: ""
    }

    static propTypes = {
        placeholder: PropTypes.string,
        // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
        // success: PropTypes.bool,
        value: dateRangePropType,
        label: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        // onBlur: PropTypes.func.isRequired,
        // onFocus: PropTypes.func.isRequired,
        className: PropTypes.string,
        showOptions: PropTypes.bool
    }

    static defaultProps = {
        value: "",
        placeholder: "",
        className: "",
        label: "",
        showOptions: true
    }

    handleVisibleChange = (isPopoverVisible) => {
        this.setState({ isPopoverVisible })
        // const { onFocus, onBlur } = this.props
        // visible ? onFocus() : onBlur()
    }

    handleIconClick = () => {
        this.setState({ isPopoverVisible: true })
    }

    handleRangeChange = (dateRange) => {
        const { onChange } = this.props
        onChange(dateRange)
        this.handleVisibleChange(false)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {value} = nextProps
        const {rangeValue} = prevState || {}
        
        if (value === rangeValue) return null;
        return { rangeValue: value };
    }
    
    componentDidMount() {
        if (this.dateRangeInput){
            this.dateRangeInput.onblur = (e) => {
                const val = e.target.value.trim()
                if (!isValidDateRangeString(val)){
                    this.setState({rangeValue: ''})
                    this.handleRangeChange('');
                }
            }
        }
    }


    render() {
        const { onChange, className, showOptions, value, label, ...rest } = this.props
        const { isPopoverVisible, rangeValue } = this.state
        
        
        const popoverContent = (context) => {
            return (
                <DateRangePicker
                    defaultRange={rangeValue}
                    onChange={range => {
                        if (context) context.closeModal()
                        this.handleRangeChange(range)
                    }}
                    showOptions={showOptions}
                />
            );
        }

        return (
            <div
                className={`
                flex-row flex-spaceBetween flex-alignCenter FormInputControl 
                ${className} DateRangeInput
                `}
                style={{ paddingLeft: 0 }}
            >
                <div className="DateRangeInput-prepend touchable-opacity" onClick={this.handleIconClick}>
                    <img src={calendar} alt="" />
                </div>
                <Desktop>
                    <Popover
                        overlayClassName="DateRangeInput-popover override-popover"
                        content={popoverContent()}
                        placement="bottom"
                        trigger="click"
                        visible={isPopoverVisible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <Cleave
                            {...rest}
                            className="FormInputControl-textInput"
                            style={{ padding: '0 10px' }}
                            options={{
                                delimiters: ['/', '/', ' - ', '/', '/'],
                                blocks: [2, 2, 4, 2, 2, 4],
                                numericOnly: true
                            }}
                            htmlRef={(ref) => this.dateRangeInput = ref}
                            value={rangeValue || ""}
                            placeholder={`e.g. ${convertDateArrayToDateRange([lastMonthDate, new Date()])}`}
                            onChange={(e) => {
                                const val = e.target.value.trim()
                                if (isValidDateRangeString(val)){
                                    this.setState({rangeValue: val}, ()=> {
                                        this.handleRangeChange(val);
                                    })
                                }
                            }}
                        />
                    </Popover>
                </Desktop>
                <NotDesktop>
                    <ModalContext.Consumer>
                        {(context) => (
                            <span
                                {...rest}
                                className="FormInputControl-textInput flex-row"
                                style={{ padding: '0 10px' }}
                                onClick={() => context.setContent(
                                    label,
                                    popoverContent(context),
                                    { width: 'fit-content' }
                                )}
                            >{value}
                            </span>
                        )}
                    </ModalContext.Consumer>
                </NotDesktop>
                {/* {!!error && <img src={error_} alt="error"/>}
        {!!success && <img src={success_} alt="success"/>} */}
            </div>
        )
    }
}
export default DateRangeInput