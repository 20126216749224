import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { observer } from 'mobx-react'
import { Menu, Dropdown } from 'antd'
import classNames from 'classnames'
// import withFormInput from './withFormInput'
import select from './assets/select.svg'

// @observer
class SelectInput extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // success: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // onBlur: PropTypes.func.isRequired,
    // onFocus: PropTypes.func.isRequired,
    className: PropTypes.string,
    defaultOptionText: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    placeholder: 'Please select',
    options: [],
    className: "",
    value: null,
    defaultOptionText: null,
    disabled: false
  }

  get selected() {
      const { value, options, placeholder } = this.props
      if (!value) return placeholder

      const selectedOption = options.find(option => option.value.toString() === value.toString())
      if (selectedOption) return selectedOption.text

      return "Not found"
  }

  renderOptions = (options) => {
    const { onBlur, disabled } = this.props
    if (disabled) return null
    return (
      <Menu selectable onClick={onBlur}>
        {options.map(option => (
          <Menu.Item key={option.text} onClick={() => this.handleSelect(option)}>
            <span>{option.text}</span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  handleSelect = (option) => {
    const { onChange } = this.props
    onChange(option.value)
  }

  handleVisibleChange = (visible) => {
    // const { onBlur, onFocus } = this.props
    // if (visible) {
    //   onFocus()
    // } else {
    //   onBlur()
    // }
  }

  render() {
    const { className, options, value, defaultOptionText, disabled } = this.props
    const formInputControlClass = classNames(
      'FormInputControl',
      'flex-row flex-spaceBetween flex-alignCenter touchable-opacity',
      className,
      { 'FormInputControl-isDisabled': disabled }
    )
    const selectedClass = classNames(
      'text-p utils-fullwidth',
      { 'text--grey': !value }
    )
    let localOptions = options
    if (defaultOptionText) {
      localOptions = [{ text: defaultOptionText, value: "" }, ...options]
    }
    return (
      <Dropdown
        disabled={disabled}
        overlay={this.renderOptions(localOptions)}
        trigger={['click']}
        onVisibleChange={this.handleVisibleChange}
      >
        <div className={formInputControlClass}>
          <div className={selectedClass}>{this.selected}</div>
          <img src={select} alt="" className="utils-append" />
        </div>
      </Dropdown>
    )
  }
}
export default SelectInput
// export default withFormInput(SelectInput)