import './FinanceList.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col, Checkbox } from 'antd';
import { reaction } from 'mobx';
import classNames from 'classnames'
import { Collapse } from 'react-collapse'
import { formatDate } from '../lib/formatDate'
import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import { ModalContext } from '../common/modal'
import Avatar, { AVI_TYPES } from '../common/avatar'
import Tablet from '../common/display/Tablet'
import Desktop from '../common/display/Desktop'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import MobilePageHeader from '../containers/MobilePageHeader';
import FinanceTransactionSearch from './FinanceTransactionSearch';
import Segment from '../containers/Segment'
import Pagination from '../common/pagination/Pagination';
import EmptyList from '../common/empty_list/empty_list';
import { scrollToTop } from '../lib/scrollToTop';
import MobileListLoading from '../common/loading/MobileListLoading';
import { FinanceTransactionsSummary } from './FinanceTransactionsSummary';

import arrowDown from '../common/assets/arrow-down-white.svg'
import moneyIcon from '../common/assets/money-bag.svg'
import calculator from '../common/assets/calculator.svg'
import spreadsheet from '../common/assets/spreadsheet.svg'
import { ExportCollection } from "../store/actions/ExportCollection";
import Loading from "../common/loading/Loading";

const { MobileForm, TabletForm, DesktopForm } = FinanceTransactionSearch



@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportFinanceTransactionListAction = ExportCollection.create({})
        this.state = { exportFinanceTransactionListAction }
    }

    initiateFinanceTransactionListExportDownload = () => {
        const { exportFinanceTransactionListAction } = this.state
        const {store} = this.props;
        const { financeTransactionStore: {selectedFinanceTransactions, downloadFinanceTransactionListSpreadsheet}} = store

        exportFinanceTransactionListAction.setToGetItems(selectedFinanceTransactions.toJSON())
        downloadFinanceTransactionListSpreadsheet(exportFinanceTransactionListAction)
    }

    render() {
        const { exportFinanceTransactionListAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { financeTransactionStore: {selectedFinanceTransactions}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedFinanceTransactions.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedFinanceTransactions.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                context.setContent(
                                    `Finance Summary for ${selectedFinanceTransactions.length} Transactions`,
                                    <FinanceTransactionsSummary financeTransactionIDs={selectedFinanceTransactions} />,
                                    true
                                )
                            }}
                        >
                            <img src={calculator} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Finance Summary
                        </button>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedFinanceTransactions.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                this.initiateFinanceTransactionListExportDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheet} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

@inject("store") @observer
class FinanceTransactions extends Component {
    componentWillMount() {
        const { store } = this.props
        const { financeTransactionStore } = store
        financeTransactionStore.search()

        reaction(
            () => financeTransactionStore.isLoading,
            loading => {
                if (loading) scrollToTop()
            }
        )
    }

    get listSummary() {
        const { store } = this.props
        const { financeTransactionStore } = store
        const {
            searchMeta,
            searchResults,
            searchFormInstance
        } = financeTransactionStore

        const totalCount = searchMeta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = searchFormInstance.per_page * searchFormInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }

    render() {
        const { store } = this.props
        const {
            financeTransactionStore,
            view: {
                openStudentPageById,
                openFinanceTransactionPageById,
                getFinanceTransactionsPage,
                setBackPage
            },
            formatCurrency
        } = store
        const {
            searchMeta,
            isLoading,
            searchResults,
            selectTransactionById,
            selectedFinanceTransactions,
            isAllSelected,
            selectAllTransactions,
            searchFormInstance,
        } = financeTransactionStore

        const columns = [
            { key: 'trans_ref', render: 'Receipt No.' },
            { key: 'transaction_date', render: 'Trans. Date' },
            { key: 'student_name', render: 'Student' },
            { key: 'payment_method_name', render: 'Pay. Method' },
            { key: 'amount_received', render: `Received` },
            { key: 'amount_payed_out', render: `Paid Out` },
            { key: 'created_by_name', render: 'Created By' },
            { key: 'created_at', render: 'Creation Date' },
        ]
        const defaultColumns = [
            'trans_ref', 'transaction_date', 'student_name', 'payment_method_name',
            'amount_received', 'amount_payed_out'
        ]
        const tableData = searchResults
            .map((financeTransaction) => {
                const {
                    id,
                    attributes: {
                        trans_ref,
                        transaction_date,
                        payment_method_name,
                        amount_received,
                        amount_payed_out,
                        created_by_name,
                        created_at
                    },
                    studentName: student_name,
                    studentId
                } = financeTransaction
                return {
                    id,
                    trans_ref: (
                        <a
                            href={`/finance/transactions/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Receipts', getFinanceTransactionsPage())
                                openFinanceTransactionPageById(id)
                            }}
                        >
                            {trans_ref}
                        </a>
                    ),
                    transaction_date: formatDate(transaction_date),
                    student_name: (
                        <a
                            href={`/students/${studentId}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openStudentPageById(studentId)
                            }}
                        >
                            {student_name}
                        </a>
                    ),
                    payment_method_name,
                    amount_received: formatCurrency(amount_received),
                    amount_payed_out: formatCurrency(amount_payed_out),
                    created_by_name,
                    created_at: formatDate(created_at)
                }
            })
        const pagination = (
            <Pagination
                pageCount={searchMeta.total_pages}
                currentPage={searchFormInstance.page}
                onClickPrev={searchFormInstance.prevPage}
                onClickNext={searchFormInstance.nextPage}
            />
        )

        return (
            <div className="FinanceList">
                <NotMobile>
                    <Segment>
                        <Tablet>
                            <TabletForm settings={financeTransactionStore} />
                        </Tablet>
                        <Desktop>
                            <DesktopForm settings={financeTransactionStore} />
                        </Desktop>
                    </Segment>

                    <div className="flex-row flex-spaceBetween">
                        <div className="flex-row">
                            <ActionsButton className="utils-prepend" />
                            {Boolean(selectedFinanceTransactions.length) && (`${selectedFinanceTransactions.length} selected`)}
                        </div>
                        {this.listSummary}
                        <div>&nbsp;</div>
                    </div>
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        defaultVisibleColumnKeys={defaultColumns}
                        data={tableData}
                        onRowSelectionChange={selectTransactionById}
                        onError={e => console.log(e)}
                        loading={isLoading}
                        onColumnKeysChange={e => console.log(e)}
                        selectedRows={selectedFinanceTransactions}
                        isAllSelected={isAllSelected}
                        onSelectAll={selectAllTransactions}
                        footer={pagination}
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        <Row gutter={15} type="flex" align="middle" className="mb-1">
                            <Col span={12}>
                                <img src={moneyIcon} alt="" />
                                <span className="text-small utils-append text--uppercase">Receipts</span>
                            </Col>
                            <Col span={12}>&nbsp;</Col>
                        </Row>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <ActionsButton fullwidth />
                            </Col>
                            <Col span={12}>
                                <MobileForm settings={financeTransactionStore} />
                            </Col>
                        </Row>
                        <Collapse isOpened={Boolean(selectedFinanceTransactions.length)}>
                            <div className="text--center">{`${selectedFinanceTransactions.length} selected`}</div>
                        </Collapse>
                    </MobilePageHeader>

                    {!isLoading && (searchResults.length === 0) && <EmptyList />}

                    {(isLoading || searchResults.length > 0) && (
                        <>
                            <div className="flex-row flex-spaceBetween">
                                {this.listSummary}
                                <div className="flex-row flex-alignCenter utils-prepend--large">
                                    <span className="text--bold text-p utils-prepend">Select All</span>
                                    <Checkbox checked={isAllSelected} onChange={selectAllTransactions} />
                                </div>
                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {searchResults.map(financeTransaction => <FinanceTransactionsItem key={financeTransaction.id} financeTransaction={financeTransaction} id={financeTransaction.id} />)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default FinanceTransactions

const FinanceTransactionsItem = inject("store")(
    observer(({ store, financeTransaction }) => {
        const {
            view: { openStudentPageById, openFinanceTransactionPageById },
            financeTransactionStore: {selectTransactionById, selectedFinanceTransactions},
            formatCurrency
        } = store
        const {
            id,
            student,
            attributes: {
                trans_ref,
                transaction_date,
                payment_method,
                total_fee_paid,
                payment_method_name,
                amount_received,
                amount_payed_out
            },
        } = financeTransaction
        return (
            <Card key={id} id={id.toString()} className="mb-1">
                <Row gutter={15} type="flex" className="mb-1" key='un'>
                    {
                        student && (
                            <>
                                <Col span={4}>
                                    <Avatar
                                        type={AVI_TYPES.STUDENT}
                                        src={student.attributes.photo_url}
                                        sex={student.attributes.gender && student.attributes.gender[0]}
                                        small
                                    />
                                </Col>
                                <Col span={18}>
                                    <a
                                        href={`/students/${id}`}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            openStudentPageById(student.id)
                                        }}
                                    >
                                        {student.studentName}
                                    </a>
                                </Col>
                            </>
                        )
                    }
                    <Col push={student ? 0 : 22} span={2}>
                        <Checkbox
                            checked={selectedFinanceTransactions.includes(id)}
                            onChange={() => selectTransactionById(id)}
                        />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle" className="mb-1" key='deu'>
                    <Col span={12} className="mb-1">
                        <div className="text-small text--uppercase text--spacedLetters">Receipt No.</div>
                        <a
                            href={`/finance/transactions/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openFinanceTransactionPageById(id)
                            }}
                            className="text-large text--uppercase"
                        >
                            {trans_ref}
                        </a>
                    </Col>
                    <Col span={12} className="mb-1">
                        <div className="text-small text--uppercase text--spacedLetters">Trans. Date</div>
                        <div className="text-large text--uppercase">{formatDate(transaction_date)}</div>
                    </Col>
                    { amount_received && (
                        <Col span={12}>
                            <div className="text-small text--uppercase text--spacedLetters">Received</div>
                            <div className="text-large text--uppercase">{formatCurrency(amount_received)}</div>
                        </Col>
                    )}
                    { amount_payed_out && (
                        <Col span={12}>
                            <div className="text-small text--uppercase text--spacedLetters">Paid Out</div>
                            <div className="text-large text--uppercase">{formatCurrency(amount_payed_out)}</div>
                        </Col>
                    )}
                    
                    <Col span={12}>
                        <div className="text-small text--uppercase text--spacedLetters">Pay Method</div>
                        <div className="text-large text--uppercase">{payment_method_name}</div>
                    </Col>
                    
                    { payment_method === 'student_wallet' && (
                        <Col span={12}>
                            <div className="text-small text--uppercase text--spacedLetters">Fee Amount</div>
                            <div className="text-large text--uppercase">{formatCurrency(total_fee_paid)}</div>
                        </Col>
                    )}
                </Row>
            </Card>
        )
    })
)