import React from 'react'
import { inject, observer } from 'mobx-react';
import { Field, Form, converters, controlled } from 'mstform'
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import SelectInput from '../common/form/SelectInput';
import { StudentTransaction } from '../store/actions/StudentTransaction'
import MultilineInput from '../common/form/MultilineInput'
import DateInput from '../common/form/DateInput'

const StudentTransactionForm = new Form(StudentTransaction, {
    transaction_date: new Field(converters.string, { controlled: controlled.object, required: true }),
    payment_method: new Field(converters.string, { controlled: controlled.object, required: true }),
    note: new Field(converters.maybe(converters.string)),
    wallet_credit: new Field(converters.maybe(converters.decimal({maxWholeDigits:10, decimalPlaces:2, allowNegative:false}))),
    wallet_debit: new Field(converters.maybe(converters.decimal({maxWholeDigits:10, decimalPlaces:2, allowNegative:false}))),
});

const StudentTransactionDefaultFormInstance = StudentTransaction.create({
    payment_method: '',
    transaction_date: '',
})

export function handleDebitWallet({ setContent, closeModal }) {
    return setContent(
        "Debit Wallet",
        <WalletTransactionForm
            closeModal={closeModal}
            transactionMode="debit"
        />,
        true
    )
}

export function handleCreditWallet({ setContent, closeModal }) {
    return setContent(
        "Credit Wallet",
        <WalletTransactionForm
            closeModal={closeModal}
            transactionMode="credit"
        />,
        true
    )
}

@inject('store', 'display')
@observer
class WalletTransactionForm extends React.Component {

    constructor(props) {
        super(props)
        this.formState = StudentTransactionForm.state(StudentTransactionDefaultFormInstance, {
            addMode: false,
            save: async (node) => {
                const {
                    store: {
                        studentStore: { postTransaction },
                        view: { selectedStudentId }
                    }
                } = this.props
                const { errors, data } = await postTransaction(selectedStudentId, node.toJSON())
                if (errors) return errors
                this.postTransactionResponseData = data
                return null
            }
        })
    }

    handleSubmit = async () => {
        const success = await this.formState.save()

        if (!success) return

        const {
            closeModal,
            store: {
                view: {
                    openFinanceTransactionPageById,
                    setBackPage,
                    getStudentPage
                }
            }
        } = this.props

        closeModal()

        const data = this.postTransactionResponseData

        setBackPage('Student profile', getStudentPage())
        openFinanceTransactionPageById(data.id)
    }

    render() {
        const { closeModal, transactionMode, store } = this.props
        const {
            offlinePaymentMethodOptions,
            studentStore
        } = store

        const transaction_date = this.formState.field("transaction_date")
        const debit_amount = this.formState.field("wallet_debit")
        const credit_amount = this.formState.field("wallet_credit")
        const payment_method = this.formState.field("payment_method")
        const note = this.formState.field("note")

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText="Save Transaction"
                okButtonType="success"
                isOkButtonDisabled={!this.formState.isValid || studentStore.payFeeLoading}
            >
                {transactionMode === 'debit' &&
                    (
                        <Label text="Enter Amount" required>
                            <InlineError field={debit_amount}>
                                <TextInput {...debit_amount.inputProps} />
                            </InlineError>
                        </Label>
                    )
                }

                {transactionMode === 'credit' &&
                    (
                        <Label text="Enter Amount" required>
                            <InlineError field={credit_amount}>
                                <TextInput {...credit_amount.inputProps} />
                            </InlineError>
                        </Label>
                    )
                }

                <Label text="Payment Method" required>
                    <InlineError field={payment_method}>
                        <SelectInput
                            {...payment_method.inputProps}
                            options={offlinePaymentMethodOptions}
                            placeholder="Select payment method"
                        />
                    </InlineError>
                </Label>

                <Label text="transaction_date" required={transaction_date.required}>
                    <InlineError field={transaction_date}>
                        <DateInput {...transaction_date.inputProps} />
                    </InlineError>
                </Label>

                <Label
                    text="Note"
                    required={note.required}
                >
                    <MultilineInput {...note.inputProps} />
                </Label>
                
            </ModalForm>
        )
    }

}