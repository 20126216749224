import { reaction } from 'mobx';
import { applySnapshot, getParent, types } from 'mobx-state-tree';
import { datesToDateRangeType } from '../../lib/formatDate';
import { dateRangeType } from "../types/dateRangeType";


export const forOptions = ['first_term', 'second_term', 'third_term']
export const reportTypeOptions = ['final', 'progress']


export const ReportCardGroup = types.model({
        course_set_id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        report_card_template_id: types.maybeNull(types.string),
        report_type: types.maybeNull(types.enumeration('ReportCardType', reportTypeOptions)),
        published: types.optional(types.boolean, true),
        for: types.maybeNull(types.enumeration(forOptions)),
        attendance_date_range: types.maybeNull(dateRangeType),
        cognitive_column_date_ranges:  types.array(types.model(
            {
                id: types.string,
                date_range: types.maybe(types.string)
            }
        )),
        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, false)
        }), {})
    })
    .views(self => ({
        get reportCardGroupStore() {
            return getParent(self)
        },
        get isSaving() {
            return self.client_state.isSaving
        },
        get template() {
            return self.reportCardGroupStore.reportCardTemplates.get(self.report_card_template_id)
        },
        get columnsWithSpecificDateRange(){
            if (self.template) return self.template.columnsWithSpecificDateRange
            return []
        },
        get usesSpecificAttendanceDateRange(){
            if (self.template) return self.template.usesSpecificAttendanceDateRange
            return false
        },
    }))
    .actions(self => ({
        afterCreate(){
            reaction(
                () => self.report_card_template_id,
                // eslint-disable-next-line no-unused-vars
                (template_id) =>{
                    self.updateCognitiveDateRangeFields()
                }
            )
        },
        
        addCognitiveColumnDateRangeField(field){
            self.cognitive_column_date_ranges.push(field)
        },
        
        markSaving(flag){
            self.client_state.isSaving = flag
        },

        togglePublished(flag){
            self.published = !!flag
        },
        
        updateCognitiveDateRangeFields(){
            if (self.report_card_template_id){
                Object.keys(self.columnsWithSpecificDateRange).forEach((key) => {
                    const nRequiredColumn = self.columnsWithSpecificDateRange[key]
                    const requiredColumnPresent  = self.cognitive_column_date_ranges.find(({id}) => nRequiredColumn.id === id)

                    if (!requiredColumnPresent){
                        self.addCognitiveColumnDateRangeField({id: nRequiredColumn.id})
                    }
                })
            }
        },
        
        reset(){
            applySnapshot(self, {})
        },
        
        setFormInstance(reportCardGroup) {
            const {
                attributes: {
                    name,
                    course_set_id,
                    report_card_template_id,
                    report_type,
                    for: for_value,
                    published: isPublished,
                    attendance_start_date,
                    attendance_end_date,
                    cognitive_column_date_ranges: cognitive_date_ranges
                }
            } = reportCardGroup;


            const cognitive_column_date_ranges = cognitive_date_ranges.map(({id, start_date, end_date}) => ({
                id,
                date_range: datesToDateRangeType(start_date, end_date)
            }));

            let attendance_date_range
            if (attendance_start_date && attendance_end_date) attendance_date_range = datesToDateRangeType(attendance_start_date, attendance_end_date)
            
            const published = !!isPublished; // convert to boolean
            
            applySnapshot(self, {
                name,
                course_set_id: course_set_id.toString(),
                report_card_template_id: report_card_template_id.toString(),
                report_type,
                for: for_value,
                published,
                attendance_date_range,
                cognitive_column_date_ranges
            })
        },
    }))