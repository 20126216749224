/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Button.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Spin, Icon } from 'antd';
import { Link } from 'react-router-dom'
import className from 'classnames'

export const buttonTypes = ['primary', 'secondary', 'warning', 'danger', 'success', 'outline', 'flat']

export default class Button extends Component {
    static propTypes = {
        size: PropTypes.oneOf(['large', 'small']),
        buttonType: PropTypes.oneOf(buttonTypes),
        disabled: PropTypes.bool,
        fullwidth: PropTypes.bool,
        popover: PropTypes.shape({
            content: PropTypes.node,
            placement: PropTypes.oneOf([
                'top', 'left', 'right', 'bottom',
                'topLeft', 'topRight', 'bottomLeft',
                'bottomRight', 'leftTop', 'leftBottom',
                'rightTop', 'rightBottom',
            ]),
        }),
        onClick: PropTypes.func,
        to: PropTypes.string,
        className: PropTypes.string,
        loading: PropTypes.bool,
        loadingText: PropTypes.string,
        style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    }

    static defaultProps = {
        size: 'large',
        buttonType: 'primary',
        disabled: false,
        fullwidth: false,
        onClick: () => {},
        to: null,
        className: '',
        loading: false,
        loadingText: 'Please wait...',
        popover: null,
        style: {},
    }

    wrapWithPopover(button) {
        const { popover } = this.props
        const { content, placement } = popover
        return (
            <Popover
                content={content}
                placement={placement || 'bottom'}
                overlayClassName="override-popover"
            >
                {button}
            </Popover>
        )
    }

    wrapWithLink(button) {
        const { to } = this.props
        return (
            <Link to={to}>
                {button}
            </Link>
        )
    }

    render() {
        const {
            size, buttonType, disabled, popover,
            fullwidth, onClick, to, children,
            className: className_, loading,
            loadingText, style,
        } = this.props

        const isSmallSize = size === 'small'

        const antIcon = <Icon type="loading" style={{ color: 'white' }} spin />;

        const buttonClass = className(
            className_,
            'Button touchable-highlight flex-row flex-alignCenter',
            `Button--${buttonType}`,
            `Button--${size}`,
            isSmallSize ? 'text-small' : 'text-p',
            {
                'Button--disabled': disabled,
                'Button--fullwidth': fullwidth,
            },
        )

        const button = (
            <button
                type="button"
                className={buttonClass}
                onClick={() => disabled ? undefined : onClick()}
                style={style}
            >
                {loading || children}
                {loading && (
                    <>
                        <Spin indicator={antIcon} />{'\u00A0\u00A0'} {loadingText}
                    </>
                )}
            </button>
        )

        if (popover && popover.content) {
            return this.wrapWithPopover(button)
        }

        if (to !== null) {
            return this.wrapWithLink(button)
        }

        return button
    }
}
