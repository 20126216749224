import './TransactionReceiptPOS.css'
import React, {Component} from 'react'
import { Row, Col } from 'antd'
import { inject, observer } from 'mobx-react'
import Card from '../common/card'
import {formatDateLong} from '../lib/formatDate'
import barcode from '../common/assets/barcode.svg'
import Loading from '../common/loading/Loading'
import { titleCase } from '../lib/titleCase'
import StripeLoading from "../common/loading/StripeLoading";


@inject('store')
@observer
class TransactionReceiptPOS extends Component {
    render() {
        const {store, receiptData} = this.props
        const {formatCurrency} = store
        

        if ( !receiptData || !receiptData.isInitialized || !receiptData.isAssociationsLoaded) return <Loading />


        const { attributes, student, feeTransactions, walletTransactions, customTransactions, isLoading} = receiptData

        const fee_balance = student ? student.totalUnpaidStudentFees: undefined

        const {total_fee_paid, note, amount_received, amount_payed_out} = attributes
        

        const grandTotal = amount_payed_out || amount_received || 0
        const grandTotalDescription = amount_payed_out ? 'Amount Received' : 'Total Paid'
        
        return (
            <div className="pos StripeLoadingContainer">
                <StripeLoading loading={isLoading} />
                <TransactionReceiptStats receiptData={receiptData} />
                <div className="pos-cardHeader ">
                    <h2 className="text--alignCenter text--bold">Receipt</h2>
                </div>


                {(walletTransactions.length > 0 || feeTransactions.length > 0) && (
                    <>
                        {feeTransactions.map(item => (
                            <FeeTransactionsItem key={item.id} feeTransaction={item} />
                        ))}

                        {feeTransactions.length > 1 && (
                            <div className=" utils-padded-small flex-row flex-spaceBetween">
                                <div className=" text--uppercase ">
                                    <strong className="text--blue">Fee Total</strong>
                                </div>
                                <div>
                                    <strong className="text--blue">{formatCurrency(total_fee_paid)}</strong>
                                </div>
                            </div>
                        )}

                        {Boolean(walletTransactions.length) && (
                            walletTransactions.map(item => <WalletTransactionsItem key={item.id} walletTransaction={item} />)
                        )}

                        <div>
                            <div className="pos-total flex-row flex-spaceBetween utils-padded-small mt-1">
                                <div className="text--uppercase">
                                    <strong>{grandTotalDescription}</strong>
                                </div>
                                <div>
                                    <strong>{formatCurrency(grandTotal)}</strong>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {customTransactions.map(item => <CustomTransactionsItem customTransaction={item} note={note} />)}


                <div>
                    <div className="utils-padded text--alignCenter">
                        <img src={barcode} alt="bluebic barcode" />
                    </div>
                </div>

                {fee_balance > 0 && (
                    <div className="text-p text">
                        <span>Pending Fees: </span>
                        <span className="text--danger text-h4 text--bold">{formatCurrency(fee_balance)}</span>
                    </div>
                )}
                Printed: {formatDateLong(Date.now())}
            </div>
        )
    }
}

const FeeTransactionsItem = inject('store')(
    observer(({ store, feeTransaction }) => {
        const { formatCurrency } = store
        const {
            attributes: {amount: transaction_amount},
            studentFee: {
                attributes: {fee_type_name, balance, fee_description, charged}
            },
        } = feeTransaction

        return (
            <Card className="FeeTransactionsItem mt-1 pos-card">
                <Row gutter={5}>
                    <Col xs={24}>
                        <div className="text-p text">{fee_type_name}
                            <span style={{ display: 'inline', fontSize: '20px' }}>&middot;</span>
                            {formatCurrency(charged)}
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className="text-p text">{fee_description}</div>
                    </Col>
                    <Col xs={12}>
                        <div className="text--uppercase text-small text--bold text--spacedLetters ">
                            Paid
                        </div>
                        <div className="text-p text">
                            {formatCurrency(transaction_amount)}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="text--uppercase text-small text--bold text--spacedLetters ">
                            Balance
                        </div>
                        <div className="text-p text">
                            {formatCurrency(balance)}
                        </div>
                    </Col>
                </Row>
            </Card>
        )
    }),
)

const WalletTransactionsItem = inject('store')(
    observer(({ store, walletTransaction }) => {
        const { formatCurrency } = store
        const { attributes: { credit, debit }} = walletTransaction
        
        return (
            <Card className="pos-walletTransactions mt-1 pos-card">
                <div className="pos-cardHeader ">
                    <h2 className="text--alignCenter text--bold pb-1 text--spacedLetters ">
                        WALLET TRANSACTION
                    </h2>
                </div>
                <Row
                    gutter={50}
                    className=" flex-row flex-justifyCenter pb-1"
                >
                    <Col xs={8} className="text--alignCenter">
                        <div className="text--uppercase text-small text--bold">
                            Debit
                        </div>
                        <div className="text-p text">{debit ? formatCurrency(debit) : '-'}</div>
                    </Col>
                    <Col xs={8} className="text--alignCenter">
                        <div className="text--uppercase text-small text--bold">
                            Credit
                        </div>
                        <div className="text-p text ">{credit ? formatCurrency(credit) : '-'}</div>
                    </Col>
                </Row>
            </Card>
        )
    }),
)

const middledot = '\u00B7'

const TransactionReceiptStats = inject('store')(
    observer(({ store, receiptData }) => {
        const { student, attributes } = receiptData

        const { trans_ref, transaction_date, payment_method_name } = attributes
        
        const { meta: { currentInstitution: { attributes: { logo_url } } } } = store
        
        return (
            <div className="pos-header">
                <div className="pos-header-logo flex-justifySelfCenter ">
                    <img src={logo_url} alt="logo" className="utils-logo" />
                </div>
                <div>
                    <div className="text-p text text--grey">#{trans_ref} {middledot} {formatDateLong(transaction_date)}</div>
                    {student && <div className="text-h4 text--bold">{titleCase(student.studentName)}</div>}
                    <div className="text-p text text--grey">Payment Method: {payment_method_name}</div>
                </div>
            </div>
        )
    }),
)

const CustomTransactionsItem = inject("store")(
    observer(({ store, customTransaction, note }) => {
        const {formatCurrency} = store
        const {id, attributes: {transaction_category_name, expense_amount, income_amount}} = customTransaction
        
        return (
            <Card className="customTransactionsItem mt-1" key={id}>
                <Row gutter={15}>
                    <Col xs={12}>
                        <div className="text--uppercase text-small text--bold">Category</div>
                        <div className="text-p text">{transaction_category_name}</div>
                    </Col>
                    {expense_amount && (
                        <Col xs={12}>
                            <div className="text--uppercase text-small text--bold">Expense Amount</div>
                            <div className="text-p text">{formatCurrency(expense_amount)}</div>
                        </Col>
                    )}
                    {income_amount && (
                        <Col xs={12}>
                            <div className="text--uppercase text-small text--bold">Income Amount</div>
                            <div className="text-p text">{formatCurrency(income_amount)}</div>
                        </Col>
                    )}
                </Row>
                <Row gutter={15} className="mt-1">
                    <Col xs={24}>
                        <div className="text--uppercase text-small text--bold">Note</div>
                        <div className="text-p text">{note}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)

export default TransactionReceiptPOS