import {isValid, format, parse} from 'date-fns';
import { Converter } from 'mstform';
import {DateUtils} from "react-day-picker";


const d = new Date()
d.setMonth(d.getMonth() - 1);
d.setHours(0, 0, 0);
d.setMilliseconds(0);
export const lastMonthDate = d;


export const timeFormat = 'h:mma'
export const dateFormatOnlyDay = 'd'
export const dateFormatOnlyDayLeadingZero = 'dd'
export const dayOfWeekFormat = 'EEEEEE'
export const localDayOfWeekNameShort = 'eee'
export const defaultDateFormat = 'dd/MM/yyyy'
export const dateFormatFull = 'do MMM, yyyy'
export const dateFormatShort = 'do MMM'
export const dateFormatShortNoLeadingZero = 'd MMM'
export const dateFormatDeeEmmEmm = 'd MM'
export const dateFormatLong = 'MMMM d, yyyy'
export const dateFormatLongLeadingZero = 'MMM dd, yyyy'
export const dateFormatMonthYearShort = 'MMM yyyy'
export const dateFormatMonthYearLong = 'MMMM yyyy'
export const apiDateFormat = 'yyyy-MM-dd'
export const fullDateTimeFormat = "dd/MM/yyyy h:mm a"


export const apiDateRegexFormat = /^\d{4}-\d{1,2}-\d{1,2}$/
export const dateRegexFormat = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/
export const dateRangeRegex = /^\d{1,2}\/\d{1,2}\/\d{2,4} - \d{1,2}\/\d{1,2}\/\d{2,4}$/ // dd/MM/yyyy - dd/MM/yyyy



export function isValidDateRangeString(value){
    if (typeof value === 'string'){
        if (value.trim()) return dateRangeRegex.test(value.trim());
        return true
    }
    return false
}


export function parseDate(str, formatVar = apiDateFormat, options = {}){
    const parsed = parse(str, formatVar, new Date(), options);
    if (DateUtils.isDate(parsed)) return parsed
    return undefined;
}

export function formatDate(date, formatVar = defaultDateFormat, locale = undefined) {
    if (!date) return null
    const dateToUse = new Date(date)
    if (isValid(dateToUse)) return format(dateToUse, formatVar, { locale });
    return 'Date Error'
}


export function formatDateShort(date) {
    const dateToUse = new Date(date)
    if (isValid(dateToUse)) return format(dateToUse, dateFormatShort)
    return null
}

export function formatDateLong(date) {
    const dateToUse = new Date(date)
    if (isValid(dateToUse)) return format(dateToUse, dateFormatLong)
    return null
}

export function datesToDateRangeType(start_date, end_date) {
    const startDateToUse = new Date(start_date)
    const endDateToUse = new Date(end_date)
    
    // temporary 
    // if (typeof start_date === 'string' || typeof end_date === 'string') debugger
    
    if (isValid(startDateToUse) && isValid(endDateToUse)) {
        return `${format(startDateToUse, defaultDateFormat)} - ${format(endDateToUse, defaultDateFormat)}`
    }
    return null
}

export function dateRangeTypeToDates(dateRange) {
    let [startDate, endDate] = dateRange.split('-')
    const [s_day, s_month, s_year] = startDate.trim().split('/')
    startDate = new Date(s_year, s_month - 1, s_day)
    const [e_day, e_month, e_year] = endDate.trim().split('/')
    endDate = new Date(e_year, e_month - 1, e_day)
    return [startDate, endDate]
}

export const dateRangeConverter = new Converter({
    // FIXME: For some reason this function appears to not be getting called.
    convert(dates) {
        convertDateArrayToDateRange(dates)
    },

    render(value) {
        return value
    }
})

export function convertDateArrayToDateRange(dates){
    return dates
        .map(date => format(date, defaultDateFormat))
        .join(" - ")
}

export function prepareAcademicSessionDates(data) {
    const [first_term_begins, first_term_ends] = dateRangeTypeToDates(data.first_term);
    const [second_term_begins, second_term_ends] = dateRangeTypeToDates(data.second_term);
    const [third_term_begins, third_term_ends] = dateRangeTypeToDates(data.third_term);

    return {
        name: data.name,
        first_term_begins: formatDateLong(first_term_begins),
        first_term_ends: formatDateLong(first_term_ends),
        second_term_begins: formatDateLong(second_term_begins),
        second_term_ends: formatDateLong(second_term_ends),
        third_term_begins: formatDateLong(third_term_begins),
        third_term_ends: formatDateLong(third_term_ends),
    };
}

export function prepareAcademicSessionDatesForEdit(data) {
    const {
        name, first_term_begins, first_term_ends, second_term_begins,
        second_term_ends, third_term_begins, third_term_ends,
    } = data;

    return {
        name,
        first_term: datesToDateRangeType(first_term_begins, first_term_ends),
        second_term: datesToDateRangeType(second_term_begins, second_term_ends),
        third_term: datesToDateRangeType(third_term_begins, third_term_ends),
    };
}
