import './LogIn.css'

import React from 'react'
import { observer, inject } from 'mobx-react'
import { Field, Form as MstForm, converters } from "mstform"
import { Checkbox } from 'antd'
import Card from '../common/card'
import Label from '../common/form/Label'
import TextInput from '../common/form/TextInput'
import Button from '../common/button'
import ContentLoader from '../common/content_loader'
import { CreateAccessToken } from '../store/actions/CreateAccessToken'
import BluebicLogo from './assets/BluebicLogo.svg'

import {DEMO_DOMAIN_REGEX_LIST, DEMO_DOMAIN_URL} from '../service/constants'

const formModel = new MstForm(CreateAccessToken, {
  username: new Field(converters.string, {required: true}),
  password: new Field(converters.string, {required: true}),
})

const formInstance = CreateAccessToken.create({
  username: "",
  password: ""
})

const formState = formModel.state(formInstance)

@inject('store')
@observer
class Login extends React.Component {

    componentDidMount() {
        const { store } = this.props
        const { meta: { getInstitution } } = store
        getInstitution(this.defaultDomainToUse())
    }
    
    componentWillUnmount() {
        formInstance.resetPassword()
    }

    defaultDomainToUse = () => {
        const URL = window.location.hostname
        const matched = DEMO_DOMAIN_REGEX_LIST.some((regex) => regex.test(URL))
        if (matched) return DEMO_DOMAIN_URL
        return URL
    }

    handleSubmit = () => {
        const { store } = this.props
        const { authStore } = store
        const { createAccessToken } = authStore
        createAccessToken(formState.value)
    }

    render() {
    const { store } = this.props
    const {
      authStore: { isLoading },
      meta: { isLoading: metaIsLoading, attributes: metaAttributes },
      rememberMe,
      toggleRememberMe
    } = store
      
      
      let currentInstitution
      if (!metaIsLoading) currentInstitution = metaAttributes.current_institution_id

    const username = formState.field("username")
    const password = formState.field("password")

    return (
      <div className="LogIn flex-col flex-alignCenter flex-justifyCenter">
        <Card className="flex-col flex-alignStretch LogIn-card">
          <div className="LogIn-schoolLogo">
            {metaIsLoading && (
              <ContentLoader height="200" width="200">
                <circle cx="100" cy="100" r="100" />
              </ContentLoader>
            )}
            {metaIsLoading || (
              <div className="LogIn-schoolLogoContent">
                <img src={currentInstitution.attributes.logo_url} alt="" />
              </div>
            )}
          </div>

          {metaIsLoading && (
            <ContentLoader height="200" width="300">
              <rect x="5%" y="5" rx="4" ry="4" width="90%" height="30" />
              <rect x="0" y="60" rx="4" ry="4" width="100%" height="8" />
              <rect x="5%" y="80" rx="4" ry="4" width="90%" height="8" />
              <rect x="25%" y="100" rx="4" ry="4" width="50%" height="8" />
            </ContentLoader>
          )}
          {metaIsLoading || (
            <>
              <div className="text-h2 text--alignCenter">{currentInstitution.attributes.name}</div>
              <div className="text-p text--grey text--alignCenter">
                Fill in your Username and Password to access your account.
                Ask your school administrator for your username and password
              </div>
              <Label text="Username" required={username.required}>
                <TextInput {...username.inputProps} />
              </Label>
              <Label text="Password" required={password.required}>
                <TextInput
                  {...password.inputProps}
                  type="password"
                  handleReturnKey={this.handleSubmit}
                />
              </Label>
              <Checkbox
                className="text-p text--grey utils-padBottom"
                checked={rememberMe}
                onChange={toggleRememberMe}
              >Remember me
              </Checkbox>
              <Button fullwidth loading={isLoading} disabled={isLoading} onClick={this.handleSubmit}>
                  Continue
              </Button>
            </>
          )}
        </Card>
        <div className="LogIn-poweredBy">
          Powered by :
          <img src={BluebicLogo} className="utils-append" alt="Bluebic" />
        </div>
      </div>
    )
  }
}

export default Login

// @inject('store')
// @observer
// class LogIn extends Component {
//   render() {
//     const {
//       state,
//       institution,
//       fields,
//       onFieldChange,
//       isRememberMeSet,
//       onClickRememberMe
//     } = this.props.AuthModel
//     return (

//     )
//   }
//   handleSubmit = () => {
//     const { location, history, AuthModel } = this.props
//     const parsed = qs.parse(location.search)
//     const callback = () => history.replace(parsed.next || '/')
//     AuthModel.submit(callback)
//   }
// }
// export default Login