import React from 'react'
import * as PropTypes from 'prop-types'
import {isWeekend, startOfMonth, endOfMonth, endOfWeek, startOfWeek, addDays} from 'date-fns'
import Hashmap from 'hashmap'
import CalendarCell from './CalendarCell'
import {formatDate} from "../../lib/formatDate";
// import { observer } from 'mobx-react';

export default function CalendarCells({ currentMonth, calendarData }) {
  const monthStart = startOfMonth(currentMonth)
  const monthEnd = endOfMonth(monthStart)
  const startDate = startOfWeek(monthStart)
  const endDate = endOfWeek(monthEnd)
  const rows = []
  let days = []
  let day = startDate

  while (day <= endDate) {
    for (let i = 0; i < 7; i += 1) {
      const formattedDate = formatDate(day)
      if (calendarData.get(formattedDate) && !isWeekend(day)) {
        days.push(
          <CalendarCell
            key={day}
            day={day}
            currentMonth={currentMonth}
            calendarData={calendarData}
          // onDateClick={this.onDateClick}
          />
        )
      }
      day = addDays(day, 1)
    }
    rows.push(
      <div className="Calendar-days" key={day}>{days}</div>
    )
    days = []
  }

  return <div className="Calendar-body">{rows}</div>
}
CalendarCells.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  calendarData: PropTypes.instanceOf(Hashmap).isRequired
}
