import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import TransactionReceipt from './TransactionReceipt';
import TransactionReceiptA4 from "./TransactionReceiptA4";
import TransactionReceiptPOS from "./TransactionReceiptPOS";
import MaybeBackPage from '../containers/MaybeBackPage';

@inject("store", "display")
@observer
class SelectedFinanceTransaction extends Component {
    componentDidMount() {
        const {
            store: {
                view: { selectedFinanceTransactionId },
                financeTransactionStore: {
                    loadFinanceTransactionById
                }
            }
        } = this.props
        loadFinanceTransactionById(selectedFinanceTransactionId)
    }

    render() {
        const {
            store: {
                view: { selectedFinanceTransaction, templateVariant }
            },
            display: { isDesktop }
        } = this.props

        if (templateVariant === 'html+A4') return (<TransactionReceiptA4 receiptData={selectedFinanceTransaction} />)
        if (templateVariant === 'html+POS') return (<TransactionReceiptPOS receiptData={selectedFinanceTransaction} />)
        
        const receipt = (<TransactionReceipt receiptData={selectedFinanceTransaction} />)
        if (isDesktop) return (<MaybeBackPage>{receipt}</MaybeBackPage>)
        return receipt
    }
}

export default SelectedFinanceTransaction