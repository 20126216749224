import { types } from "mobx-state-tree"
import { FinanceTransaction } from "./FinanceTransaction";
import { FinanceTransactionCategory } from "./FinanceTransactionCategory";

export const CustomTransaction = types
    .model("CustomTransaction", {
        id: types.identifier,
        type: "custom_finance_transaction",
        attributes: types.maybe(types.model({
            income_amount: types.maybeNull(types.string),
            transaction_category_name: "",
            expense_amount: types.maybeNull(types.string),
            created_at: "",
            updated_at: "",
        })),
        relationships: types.optional(types.model({
            finance_transaction_category: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(FinanceTransactionCategory))
                })
            })),
            finance_transaction: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(FinanceTransaction))
                })
            })),
        }), {}),
        isLoading: true,
    })
    .views(self => ({
        get financeTransaction() {
            return self
                .relationships
                .finance_transaction
                .data
                .id
        },
        get financeTransactionCategory() {
            return self
                .relationships
                .finance_transaction_category
                .data
                .id
        },
    }))