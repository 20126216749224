import { types } from "mobx-state-tree"

export const LoadingPattern = (defaultLoading = true) => {
    let model = {}
    if (typeof defaultLoading === "boolean") {
        model = { isLoading: defaultLoading }
    } else if (typeof defaultLoading === "object") {
        model = {
            isLoading: false,
            ...defaultLoading
        }
    }

    return types
        .model(model)
        .actions(self => {

            function markLoading(loadingKey, isLoading) {
                if (typeof loadingKey === "boolean") {
                    self.isLoading = loadingKey
                } else {
                    self[loadingKey] = isLoading
                }
            }

            return { markLoading }
        })
}