import './GuardianSummary.css'

import React from 'react'
import NotMobile from '../common/display/NotMobile'
import Avatar, { AVI_TYPES } from '../common/avatar'
import { titleCase } from '../lib/titleCase'
import ProfileLoading from '../common/loading/ProfileLoading';

export default function GuardianSummary({ guardian }) {
  const { attributes, guardianName, guardianId, isLoading } = guardian
  const { photo_url: photo, mobile_phone: mobile, email, status, gender } = attributes

  if (isLoading) return <ProfileLoading useCard={false} />
  
  return (
    <div className="guardianSummary">
      <Avatar
        className="guardianSummary-avatar"
        src={photo}
        bordered
        sex={gender}
        type={AVI_TYPES.GUARDIAN}
      />
      <div>
        <div className="text--grey text-small">Full name</div>
        <div className="text-h4">{guardianName}</div>
      </div>
      <div>
        <div className="text--grey text-small">Guardian ID</div>
        <div className="text-h4">{guardianId}</div>
      </div>
      <div>
        <div className="text--grey text-small">Mobile <NotMobile>Phone</NotMobile></div>
        <div className="text-p">{mobile}</div>
      </div>
      <NotMobile>
        <div>
          <div className="text--grey text-small">Email</div>
          <div className="text-p">{email}</div>
        </div>
        <div>
          <div className="text--grey text-small">Status</div>
          <div className="text-p text--success">{titleCase(status)}</div>
        </div>
      </NotMobile>
    </div>
  )
}
