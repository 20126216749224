import { types } from 'mobx-state-tree'


export const Announcement = types
    .model("Announcement", {
        id: types.identifier,
        type: "announcement",
        attributes: types.maybe(types.model({
            title: types.string,
            body: types.maybeNull(types.string),
            public: types.boolean,
        })),
        isLoading: false
    })
