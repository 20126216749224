import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters } from "mstform";
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import MultilineInput from '../common/form/MultilineInput';
import {Discount}  from '../store/actions/Discount'
import StudentFeeDiscountPolicy from "../policies/StudentFeeDiscountPolicy";


@inject('store', 'display')
@observer
class AddDiscountForm extends React.Component {
    
    componentWillMount() {
        const form = new Form(Discount, {
            amount: new Field(converters.number, { required: true }),
            description: new Field(converters.string, { required: true }),
        })
        const formInstance = Discount.create({})
        this.formState = form.state(formInstance, {
            addMode: true,
            save: async (node) => {
                const {
                    store: {
                        feeStore: {addDiscount},
                        view : {selectedFeeId : id},
                    },
                    studentFeeId
                } = this.props
                const { errors } = await addDiscount(node.toJSON(), studentFeeId, id)
                return errors
            }
        })
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        // if (success) {
        //     const { closeModal } = this.props
        //     closeModal()
        // }
    }
    
    render() {
        const { display, closeModal, store, autoFocus } = this.props
        const { currency, meta: {currentUser} } = store
        
        const policy = new StudentFeeDiscountPolicy(currentUser, 'StudentFeeDiscount')

        const description = this.formState.field("description")
        const amount = this.formState.field("amount")

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText="Add Discount"
                okButtonTitle={policy.can_create ? undefined : 'No permission.'}
                isOkButtonDisabled={!this.formState.isValid || !policy.can_create}
                style={{padding: '15px'}}
            >
                <Label text="Description" inline={display.isNotMobile} required={description.required}>
                    <InlineError field={description}>
                        <MultilineInput {...description.inputProps} autoFocus={autoFocus} />
                    </InlineError>
                </Label>
                <Label text={`Amount (${currency})`} inline={display.isNotMobile} required={amount.required}>
                    <InlineError field={amount}>
                        <TextInput {...amount.inputProps} />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}

export default AddDiscountForm