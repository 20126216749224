import React from 'react'
import { LineChart, Line, ResponsiveContainer } from 'recharts'
import { computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import { colors} from '../common/colors'

import arrowDownFat from './assets/arrow-down-fat.svg'
import arrowUp from './assets/arrow-up.svg'

@inject('display')
@observer
class TrendChart extends React.Component {
  constructor(props) {
    super(props)
    this.trend = props.trend
    this.data = props.data
    this.display = props.display
    this.reversePolarity = props.reversePolarity || false
  }
    /**
     * adds a plus sign to number greater than 0
     */
    @computed get signedTrend() {
        if (this.trend > 0) {
            return `+${  this.trend}`
        }
        return this.trend
    }
  
  render() {

      const upTrend = [
          {name: 'Page A', pv: 6},
          {name: 'Page B', pv: 10},
          {name: 'Page C', pv: 9},
          {name: 'Page D', pv: 20},
          {name: 'Page E', pv: 23},
      ]

      const downTrend = [
          {name: 'Page A', pv: 10},
          {name: 'Page B', pv: 20},
          {name: 'Page C', pv: 12},
          {name: 'Page D', pv: 17},
          {name: 'Page E', pv: 5},
      ]

      const staticTrend = [
          {name: 'Page A', pv: 0},
          {name: 'Page B', pv: 0}
      ]
      
      let data
      let classNameText
      let stroke
      let arrow
      let reverseArrow
      
      if (this.data) {
          ({data} = this)
      } else if (this.trend > 0) {
          data = upTrend;
      } else if (this.trend < 0) {
          data = downTrend
      } else {
          data = staticTrend
      }
      
      
      if (this.reversePolarity){
          classNameText  = this.trend > 0 ? 'text--danger' : (this.trend === 0 ? undefined : 'text--success' )
          arrow = this.trend < 0 ? arrowUp : (this.trend === 0 ? undefined : arrowDownFat )
          stroke = this.trend > 0 ? colors.red : (this.trend === 0 ? undefined : colors.green )
          reverseArrow = true
      }else{
          classNameText  = this.trend > 0 ? 'text--success' : (this.trend === 0 ? undefined : 'text--danger' )
          arrow = this.trend < 0 ? arrowDownFat : (this.trend === 0 ? undefined : arrowUp )
          stroke = this.trend > 0 ? colors.green : (this.trend === 0 ? undefined : colors.red )
          reverseArrow = false
      } 
      
    return (
      <div
        className="flex-col flex-alignCenter"
        style={{ width: this.display.isMobile ? 50 : 100 }}
      >
        <div className="flex-row">
          <div className={[this.display.isMobile ? 'text-h3' : 'text-h2', classNameText].join(' ')}>
              {this.signedTrend}
          </div>
          <img className={reverseArrow ? 'rotate180' : undefined} src={arrow} alt="" style={{ padding: 5 }} />
        </div>
        <ResponsiveContainer height={30}>
          <LineChart data={data}>
            <Line
              type='natural'
              dataKey='pv'
              stroke={stroke}
              strokeWidth={1}
              dot={false} 
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default TrendChart
