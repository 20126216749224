import { types, onSnapshot, onPatch } from 'mobx-state-tree'
import route from "path-match"
import { message as antMessage } from 'antd';

antMessage.config({
    maxCount: 1
})

const Feedback = types
    .model("Feedback", {
        messages: types.array(types.model({
            loading: types.maybe(types.string),
            info: types.maybe(types.string),
            warning: types.maybe(types.string),
            success: types.maybe(types.string),
            error: types.maybe(types.string)
        })),
        alerts: types.array(types.model({
            success: types.maybe(types.string),
            info: types.maybe(types.string),
            error: types.maybe(types.string),
            warning: types.maybe(types.string)
        }))
    })
    .actions(self => ({
        push(newFeedbackItem) {
            const { message } = newFeedbackItem
            if (message) {
                self.messages.push(message)
            } else {
                self.alerts.push(newFeedbackItem)
            }
        },
        afterCreate() {
            onPatch(self, (patch) => {
                const { path, value } = patch
                const matcher = route()('/messages/:id')
                const params = matcher(path)
                if (params.id) {
                    const { loading, info, warning, success, error } = value
                    
                    if (loading) antMessage.loading(loading)
                    if (info) antMessage.info(info)
                    if (warning) antMessage.warn(warning)
                    if (success) antMessage.success(success)
                    if (error) antMessage.error(error)
                }
            })
        }
    }))

export const feedback = Feedback.create({})

onSnapshot(feedback, (snapshot) => {
    console.log('Feedback: ', snapshot)
})