import ApplicationPolicy from "./ApplicationPolicy";

class FeePolicy extends ApplicationPolicy{
    get can_index(){
        return this.user.has_any_role(['finance', 'finance_control'])
    }

    get can_show(){
        return this.can_index
    }

    get can_create(){
        return this.can_index
    }

    get can_update(){
        return this.user.has_role('finance_control')
    }

    get can_destroy(){
        return this.can_update
    }

    get can_assign_to_student(){
        return this.can_index
    }

    get can_unassign_from_student(){
        return this.can_update
    }

    get can_create_discount(){
        return this.can_update
    }

    get can_update_status(){
        return this.can_update
    }
}

export default FeePolicy