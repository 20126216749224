import { types, getParent } from "mobx-state-tree"
import { StudentFee } from "./StudentFee"
import { User } from "./User"

export const StudentFeeDiscount = types
    .model("StudentFeeDiscount", {
        id: types.identifier,
        type: "student_fee_discount",
        attributes: types.model({
            description: "",
            amount: "",
            created_by_name: "",
            created_by_id: types.maybe(types.number),
            created_at: "",
        }), 
    })
    //     relationships: types.maybe(types.model({
    //         student_fee: types.maybe(types.model({
    //             data: types.array(types.model({
    //                 id: types.late(() => types.safeReference(StudentFee))
    //             }))
    //         })),
    //         created_by: types.maybe(types.model({
    //             data: types.model({
    //                 id: types.late(() => types.safeReference(User))
    //             })
    //         })),
    //     }), {}),
    // })
    // .views(self => ({
    //     // get isSelected() {
    //     //     return getParent(self, 2)
    //     //         .students
    //     //         .get(self.attributes.student_id)
    //     //         .selectedFees
    //     //         .includes(self.id)
    //     // }
    // }))