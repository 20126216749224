import './Pagination.css'

import React from 'react'
import PropTypes from 'prop-types'
import arrowLeft from './assets/arrow-left.svg'
import arrowRight from './assets/arrow-right.svg'

export default function Pagination({ onClickPrev, onClickNext, currentPage, pageCount }) {
    // TODO disable next button when on last page
  return (
    <div className="Pagination">
      <div>{`Page ${currentPage} of ${pageCount}`}</div>
      <div className="flex-row flex-alignCenter">
        <button type="button" className="Pagination-button touchable-highlight" onClick={onClickPrev}>
          <img src={arrowLeft} alt="" />
        </button>
        <button type="button" className="Pagination-button touchable-highlight" onClick={onClickNext}>
          <img src={arrowRight} alt="" />
        </button>
      </div>
    </div>
  )
}
Pagination.propTypes = {
  onClickPrev: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired
}
