import { applyPatch, types } from 'mobx-state-tree'
import snakeCase from 'lodash.snakecase'
import camelCase from 'lodash.camelcase'

export const JSONReference = (type) => {
    const key = type.name

    return types.model(
        {
            id: types.reference(type),
            type: snakeCase(key)
        }
    )
}

export const SafeJSONReference = (type) => {
    const key = type.name

    return types.model(
        {
            id: types.reference(type, {
                onInvalidated(event) {
                    if (event.cause==="destroy"){
                        event.parent.removeJSONReference()
                    }
                }
            }),
            type: snakeCase(key)
        }
    ).actions(self => ({
        removeJSONReference() {
            applyPatch(self.$treenode.root.storedValue, {
                op: "remove",
                path: self.$treenode.path
            })
        }
    }))
}

export const PolymorphicJSONReference = (typesArray) => {
    const referenceTypes = typesArray.map((type) => JSONReference(type))

    const dispatcher = (snapshot) => {
        let typeName = camelCase(snapshot.type)
        typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1) // Capitalize first letter

        const type = typesArray.find((a) => a.name === typeName )
        return JSONReference(type)
    }
    const args = referenceTypes.slice()
    args.unshift({dispatcher})

    return types.union.apply(null, args);
}