import ApplicationPolicy from "./ApplicationPolicy";

class StudentPolicy extends ApplicationPolicy{

    get can_index() {
        return this.user.has_any_role([ 'admission', 'student_control', 'student_view', 'finance', 'finance_control'] )
    }

    get can_create() {
        return this.user.has_any_role([ 'admission', 'student_control'] )
    }

    get can_update() {
        return this.can_create
    }

    get can_block() {
        return this.user.has_any_role([ 'finance_control', 'student_control'] )
    }

    get can_restore_student() {
        return this.user.is_admin
    }

    get can_unblock() {
        return this.can_block
    }

    get can_create_note() {
        if (this.user.has_any_role([ 'admission', 'student_control', 'finance', 'finance_control'] )) return true
        return this.user.has_any_special_role(['class_teacher', 'principal'], this.record.batchModel);
    }

    get can_destroy() {
        return this.user.has_role('student_control')
    }

    get can_drop() {
        return this.can_destroy
    }

    get can_transfer_students() {
        return this.can_destroy
    }

    get can_show() {
        if (this.user.has_any_role([ 'admission', 'student_control', 'student_view'] )) return true
        if (this.user.has_any_special_role([ 'class_teacher', 'principal'], this.record.batchModel )) return true
        return this.is_owner_student_or_student_guardian
    }

    get can_attendance_report() {
        if (this.user.has_any_role([ 'student_attendance_register', 'student_attendance_view'] )) return true
        if (this.user.has_any_special_role([ 'class_teacher', 'principal'], this.record.batchModel)) return true
        return this.is_owner_student_or_student_guardian
    }

    get can_view_unpaid_fees() {
        if (this.can_enter_paid_fees) return true
        return this.is_owner_student_or_student_guardian
    }

    get can_enter_paid_fees() {
        return this.user.has_any_role(['finance_control', 'finance'] )
    }

    get can_web_pay_fees() {
        return this.can_view_unpaid_fees
    }

    get can_view_finance_transactions() {
        return this.can_view_unpaid_fees
    }

    get can_view_batch_transfers() {
        if (this.user.has_any_role(['student_control', 'student_view'] )) return true
        return this.is_owner_student_or_student_guardian
    }


    get can_view_subject_list() {
        if (this.is_owner_student_or_student_guardian) return true
        return this.user.has_role('view_score')
    }


    get can_view_assessments_list() {
        return this.can_view_subject_list
    }

    get can_view_report_card_list() {
        if (this.user.has_any_role(['report_control', 'view_report'] )) return true
        return this.is_owner_student_or_student_guardian
    }

    get is_owner_student_or_student_guardian() {
        if (this.user.is_student){
            return this.user === this.record.user
        }

        if (this.user.is_guardian){
            return this.user.userable.students.includes(this.record)
        }
        
        return false
    }

}

export default StudentPolicy