import React from 'react'
import * as PropTypes from 'prop-types'
import {Checkbox} from "antd";
import {observer} from "mobx-react";


function CheckBoxInput({ onChange, checked, children, disabled, className, indeterminate }) {

    return (
        <Checkbox
            onChange={onChange}
            className={className}
            disabled={disabled}
            indeterminate={indeterminate}
            checked={checked}
        >
            {children}
        </Checkbox>
    )
}
CheckBoxInput.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    indeterminate: PropTypes.bool
}

CheckBoxInput.defaultProps = {
    className: "text-p text--grey utils-padBottom",
    indeterminate: false
}
export default observer(CheckBoxInput)