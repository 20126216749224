import './AddEmployee.css'

import React from 'react'
import { SubForm, Field, Form, converters, controlled } from "mstform";
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty';

import Card from '../common/card';
import Label from '../common/form/Label';
import { CreateEmployee, qualificationOptions, maritalStatusOptions, bloodGroupOptions, religionOptions } from '../store/actions/Employee';
import { genderOptions } from '../store/models/Gender';
import InlineError from '../common/form/InlineError';
import TextInput from '../common/form/TextInput';
import DateInput from '../common/form/DateInput';
import SelectInput from '../common/form/SelectInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import MultilineInput from '../common/form/MultilineInput';
import PhoneInput from '../common/form/PhoneInput';
import PhotoInput from '../common/form/PhotoInput';
import Button from '../common/button'
import EditFormLoading from '../common/loading/EditFormLoading';
import MobilePageHeader from '../containers/MobilePageHeader';
import employee_icon from '../common/assets/teacher-pointing-blackboard.svg';
import { mapStringListToOptions } from '../lib/mapStringListToOptions';
import { mapCountriesJSONtoOptions } from '../lib/mapCountriesJSONtoOptions';

const validators = [(value) => (value.length > 0) ? false : 'Field is required']
const form = new Form(CreateEmployee, {
    id: new Field(converters.maybeNull(converters.string)),
    username: new Field(converters.string, { required: true, validators }),
    email: new Field(converters.maybeNull(converters.string)),
    title: new Field(converters.maybeNull(converters.string)),
    date_joined: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    first_name: new Field(converters.string, { required: true, validators }),
    middle_name: new Field(converters.maybeNull(converters.string)),
    last_name: new Field(converters.string, { required: true, validators }),
    gender: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    employee_category_id: new Field(converters.string, {
        controlled: controlled.object,
        required: false
    }),
    employee_position_id: new Field(converters.string, {
        controlled: controlled.object,
        required: false
    }),
    employee_department_id: new Field(converters.string, {
        controlled: controlled.object,
        required: false
    }),
    qualification: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    photo: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    signature_photo: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    blood_group: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    nationality: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    religion: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    marital_status: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    address: new Field(converters.maybeNull(converters.string)),
    next_of_kin_name: new Field(converters.maybeNull(converters.string)),
    relationship_with_next_of_kin: new Field(converters.maybeNull(converters.string)),
    next_of_kin_address: new Field(converters.maybeNull(converters.string)),
    next_of_kin_mobile_phone: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    next_of_kin_phone: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    mobile_phone: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    phone: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    date_of_birth: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    bank_account_attributes: new SubForm({
        bank_id: new Field(converters.maybeNull(converters.string), {
            controlled: controlled.object
        }),
        account_name: new Field(converters.maybeNull(converters.string)),
        account_number: new Field(converters.maybeNull(converters.string))
    })
});

@inject("store", "display")
@observer
class AddEmployee extends React.Component {
    state = { createdEmployee: null, selectedEmployee: null }

    constructor(props) {
        super(props)

        const { store, editMode } = props
        const { employeeStore: { addEmployeeFormInstance, createEmployee, editEmployee }  } = store

        const action = editMode ? editEmployee : createEmployee


        this.formState = form.state(addEmployeeFormInstance, {
            addMode: false,
            save: async (node) => {
                const { data: createdEmployee, errors } = await action(node.toJSON())
                if (createdEmployee) this.setState({ createdEmployee })
                return errors
            }
        })

    }

    async componentDidMount() {
        const { store, editMode } = this.props
        if (!editMode) return

        const { employeeStore: {selectEmployeeForEdit}, view: {selectedEmployeeId} } = store
        const selectedEmployee = await selectEmployeeForEdit(selectedEmployeeId)
        this.setState({ selectedEmployee })

    }

    componentWillUnmount() {
        const { editMode, store: { employeeStore: {resetAddEmployeeForm} } } = this.props
        if (editMode) {
            resetAddEmployeeForm()
        }
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { store } = this.props
            const { employeeStore, view } = store
            const { resetAddEmployeeForm } = employeeStore
            resetAddEmployeeForm()
            view.openAddEmployeePage()
        }
    }

    handleSubmitAndExit = async () => {
        const success = await this.formState.save()
        const { createdEmployee } = this.state
        if (success && createdEmployee) {
            const { store } = this.props
            const { view, employeeStore } = store
            const { resetAddEmployeeForm } = employeeStore
            resetAddEmployeeForm()
            view.openEmployeePage(createdEmployee)
        }
    }

    render() {
        const { editMode } = this.props
        const { selectedEmployee } = this.state
        
        const Header = (
            <MobilePageHeader>
                <div className="flex-row text-small text--uppercase">
                    <img src={employee_icon} className="utils-prepend" alt="" />
                    {editMode ? "Edit" : "Add"} Employee
                </div>
            </MobilePageHeader>
        )
        
        if (editMode) {
            if (isEmpty(selectedEmployee) || selectedEmployee.isLoading !== false) {
                return (
                    <div className="AddEmployee">
                        {Header}
                        <EditFormLoading />
                    </div>
                )
            }
        }

        const { store, display } = this.props
        const {
            meta: {
                attributes: {
                    banks,
                    employee_categories,
                    employee_departments,
                    employee_positions
                }
            }, employeeStore: {isLoading: employeeStoreLoading}
        } = store
        
        const last_name = this.formState.field("last_name")
        const first_name = this.formState.field("first_name")
        const middle_name = this.formState.field("middle_name")
        const title = this.formState.field("title")
        const username = this.formState.field("username")
        const date_joined = this.formState.field("date_joined")
        const date_of_birth = this.formState.field("date_of_birth")
        const photo = this.formState.field("photo")
        const signature_photo = this.formState.field("signature_photo")
        const gender = this.formState.field("gender")
        const blood_group = this.formState.field("blood_group")
        const marital_status = this.formState.field("marital_status")
        const employee_category_id = this.formState.field("employee_category_id")
        const employee_position_id = this.formState.field("employee_position_id")
        const employee_department_id = this.formState.field("employee_department_id")
        const qualification = this.formState.field("qualification")
        const email = this.formState.field("email")
        const mobile_phone = this.formState.field("mobile_phone")
        const nationality = this.formState.field("nationality")
        const religion = this.formState.field("religion")
        const phone = this.formState.field("phone")
        const address = this.formState.field("address")
        const next_of_kin_name = this.formState.field("next_of_kin_name")
        const relationship_with_next_of_kin = this.formState.field("relationship_with_next_of_kin")
        const next_of_kin_address = this.formState.field("next_of_kin_address")
        const next_of_kin_mobile_phone = this.formState.field("next_of_kin_mobile_phone")
        const next_of_kin_phone = this.formState.field("next_of_kin_phone")
        const bank_account_id = this.formState.subForm("bank_account_attributes").field("bank_id");
        const bank_account_name = this.formState.subForm("bank_account_attributes").field("account_name");
        const bank_account_number = this.formState.subForm("bank_account_attributes").field("account_number");

        return (
            <div className="AddEmployee">
                {Header}

                <Card title="Employee Information">
                    <div className="AddEmployee-formGrid">
                        <Label text="Surname" required={last_name.required}>
                            <InlineError field={last_name}>
                                <TextInput {...last_name.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="First Name" required={first_name.required}>
                            <InlineError field={first_name}>
                                <TextInput {...first_name.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Middle Name" required={middle_name.required}>
                            <TextInput {...middle_name.inputProps} />
                        </Label>
                        <Label text="Title" required={title.required}>
                            <TextInput {...title.inputProps} />
                        </Label>
                        <Label text="Employee No" required={username.required}>
                            <InlineError field={username}>
                                <TextInput {...username.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Date of Birth" required={date_of_birth.required}>
                            <DateInput {...date_of_birth.inputProps} />
                        </Label>
                        <UploadPhoto field={photo} />
                        <Label text="Gender" required={gender.required}>
                            <SelectInput
                                {...gender.inputProps}
                                placeholder="Select gender"
                                options={mapStringListToOptions(genderOptions)}
                            />
                        </Label>
                        <Label text="Date Joined" required={date_joined.required}>
                            <DateInput {...date_joined.inputProps} />
                        </Label>
                        <Label text="Blood Group" required={blood_group.required}>
                            <SelectInput
                                {...blood_group.inputProps}
                                placeholder="Select blood group"
                                options={mapStringListToOptions(bloodGroupOptions, { transformCase: false })}
                            />
                        </Label>
                        <Label text="Marital Status" required={marital_status.required}>
                            <SelectInput
                                {...marital_status.inputProps}
                                placeholder="Select marital status"
                                options={mapStringListToOptions(maritalStatusOptions, { transformCase: true })}
                            />
                        </Label>
                        <Label text="Employee Position" required={employee_position_id.required}>
                            <InlineError field={employee_position_id}>
                                <SelectInput
                                    {...employee_position_id.inputProps}
                                    placeholder="Select employee position"
                                    options={mapModelNameToOptions(employee_positions)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Employee Category" required={employee_category_id.required}>
                            <InlineError field={employee_category_id}>
                                <SelectInput
                                    {...employee_category_id.inputProps}
                                    placeholder="Select employee category"
                                    options={mapModelNameToOptions(employee_categories)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Employee Department" required={employee_department_id.required}>
                            <InlineError field={employee_department_id}>
                                <SelectInput
                                    {...employee_department_id.inputProps}
                                    placeholder="Select employee department"
                                    options={mapModelNameToOptions(employee_departments)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Qualification" required={qualification.required}>
                            <SelectInput
                                {...qualification.inputProps}
                                placeholder="Select qualification"
                                options={mapStringListToOptions(qualificationOptions, { transformCase: false })}
                            />
                        </Label>
                        <Label text="Email" required={email.required}>
                            <TextInput {...email.inputProps} />
                        </Label>
                        <Label text="Mobile Number" required={mobile_phone.required}>
                            <PhoneInput {...mobile_phone.inputProps} />
                        </Label>
                        <Label text="Nationality" required={nationality.required}>
                            <SelectInput
                                {...nationality.inputProps}
                                placeholder="Select nationality"
                                options={mapCountriesJSONtoOptions()}
                            />
                        </Label>
                        <Label text="Religion" required={religion.required}>
                            <SelectInput
                                {...religion.inputProps}
                                placeholder="Select religion"
                                options={mapStringListToOptions(religionOptions)}
                            />
                        </Label>
                        <Label text="Phone" required={phone.required}>
                            <PhoneInput {...phone.inputProps} />
                        </Label>
                        <UploadSignaturePhoto field={signature_photo} />
                        <Label
                            text="Address"
                            required={address.required}
                            style={{ gridColumn: display.isMobile ? 'unset' : 'span 2' }}
                        >
                            <MultilineInput {...address.inputProps} />
                        </Label>
                    </div>

                    <div>
                        <p className="text-small text--uppercase text--spacedLetters mb-2">Next Of Kin Information</p>
                        <div className="AddEmployee-formGrid">
                            <Label text="Name" required={next_of_kin_name.required}>
                                <TextInput {...next_of_kin_name.inputProps} />
                            </Label>
                            <Label text="Relationship with next of kin" required={relationship_with_next_of_kin.required}>
                                <TextInput {...relationship_with_next_of_kin.inputProps} />
                            </Label>
                            <Label text="Next of Kin Mobile Phone" required={next_of_kin_mobile_phone.required}>
                                <PhoneInput {...next_of_kin_mobile_phone.inputProps} />
                            </Label>
                            <Label text="Next of Kin Phone" required={next_of_kin_phone.required}>
                                <PhoneInput {...next_of_kin_phone.inputProps} />
                            </Label>
                            <Label
                                text="Address"
                                required={next_of_kin_address.required}
                                style={{ gridColumn: display.isMobile ? 'unset' : 'span 2' }}
                            >
                                <MultilineInput {...next_of_kin_address.inputProps} />
                            </Label>
                        </div>
                    </div>

                    <div>
                        <p className="text-small text--uppercase text--spacedLetters mb-2">Bank account Information</p>
                        <div className="AddEmployee-formGrid">
                            <Label text="Bank" required={bank_account_id.required}>
                                <SelectInput
                                    {...bank_account_id.inputProps}
                                    placeholder="Select bank"
                                    options={mapModelNameToOptions(banks)}
                                />
                            </Label>
                            <Label text="Account Name" required={bank_account_name.required}>
                                <TextInput {...bank_account_name.inputProps} />
                            </Label>
                            <Label text="Account Number" required={bank_account_number.required}>
                                <TextInput {...bank_account_number.inputProps} />
                            </Label>
                        </div>
                    </div>
                </Card>

                <div className="AddEmployee-saveButton">
                    <Button
                        buttonType="success"
                        fullwidth
                        onClick={this.handleSubmit}
                        disabled={!this.formState.isValid || employeeStoreLoading}
                    >Save and add another
                    </Button>
                    <Button
                        fullwidth
                        onClick={this.handleSubmitAndExit}
                        disabled={!this.formState.isValid || employeeStoreLoading}
                    >
                        Save employee
                    </Button>
                </div>
            </div>
        )
    }
}
AddEmployee.propTypes = {
    editMode: PropTypes.bool
}
AddEmployee.defaultProps = {
    editMode: false
}
export default AddEmployee

const UploadPhoto = inject("display")(observer(({ display, field }) => {
    const InputControl = (
        <PhotoInput
            style={display.isDesktop ? { gridRow: "span 2" } : null}
            {...field.inputProps}
        />
    )
    if (display.isDesktop) {
        return InputControl
    }
    return (
        <Label text="Upload Picture" required={field.required}>
            <InlineError field={field}>
                {InputControl}
            </InlineError>
        </Label>
    )
}))

const UploadSignaturePhoto = inject("display")(observer(({ display, field }) => {
    const InputControl = (
        <PhotoInput
            style={display.isDesktop ? { gridRow: "span 2" } : null}
            displayText="Upload Signature Image"
            {...field.inputProps}
        />
    )
    if (display.isDesktop) {
        return InputControl
    }
    return (
        <Label text="Upload Signature Photo" required={field.required}>
            <InlineError field={field}>
                {InputControl}
            </InlineError>
        </Label>
    )
}))