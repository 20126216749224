import { types } from "mobx-state-tree"
import { dateType } from "../types/dateType";

export const Transfer = types.model("Transfer", {
    batch_id: types.string,
    first_day_in_batch: types.maybeNull(dateType),
    last_day_in_batch: types.maybeNull(dateType),
    transfer_out_reason: types.maybeNull(types.string)
})

export const studentsBatchTransfer = types.model("studentsBatchTransfer", {
    transfer_to_batch_id: types.maybeNull(types.string),
    type: types.string,
    student_ids: types.array(types.string),
    last_day_in_current_batch: types.maybeNull(dateType),
    reason: types.maybeNull(types.string)
})