import ApplicationPolicy from "./ApplicationPolicy";

class SubjectPolicy extends ApplicationPolicy{

    get can_index(){
        const a = this.user.has_any_role(['subject_master', 'enter_score', 'view_score'] )
        const b = this.user.has_any_special_role(['subject_teacher', 'principal'])
        return a || b
    }

    get can_show(){
        return this.can_scoresheet
    }

    get can_create(){
        return this.user.has_role('subject_master')
    }

    get can_update(){
        return this.user.has_role('subject_master')
    }

    get can_destroy(){
        return this.user.has_role('subject_master')
    }

    get can_update_assigned_employees(){
        return this.user.has_role('subject_master')
    }

    get can_update_registered_students(){
        return this.user.has_role('subject_master') || this.user.has_special_role('subject_teacher', this.record)
    }

    get can_assessment_list(){
        return this.can_scoresheet
    }

    get can_manage_assessments(){
        return this.user.has_role('enter_score') || this.user.has_special_role('subject_teacher', this.record)
    }

    get can_scoresheet(){
        return this.user.has_any_role(['enter_score', 'view_score']) || this.user.has_special_role('subject_teacher', this.record)
    }

    get can_update_assessment_score(){
        return this.can_manage_assessments
    }

    get can_view_all_subjects(){
        return this.user.has_any_role(['subject_master', 'enter_score', 'view_score'] )
    }

}

export default SubjectPolicy