import './PhotoInputDisplay.css';

import React, { useState } from 'react';
import PhotoInput from './PhotoInput';

export default function PhotoInputDisplay(props) {
    const { value, displayText, logoInputProps } = props;
    const [displayUpload, setDisplayUpload] = useState(false);
    const shouldDisplayUpload = displayUpload && value;

    return (
        <div className="PhotoInputDisplay">
            {
                shouldDisplayUpload
                    ? <PhotoInput {...logoInputProps} displayText={displayText} />
                    : (
                        <>
                            <img src={value} alt="Logo" />
                            <span
                                tabIndex={0}
                                role="button"
                                aria-label="Remove Image"
                                onClick={() => setDisplayUpload(true)}
                                className="PhotoInputDisplay--remove-image touchable-opacity"
                            >
                                Remove Image
                            </span>
                        </>
                    )
            }
        </div>
    );
}
