import './TransactionReceiptA4.css'
import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { formatDateLong } from '../lib/formatDate'
import barcode from '../common/assets/barcode.svg'
import Loading from '../common/loading/Loading'
import Table from '../common/table'
import { titleCase } from '../lib/titleCase'
import StripeLoading from "../common/loading/StripeLoading";


const walletTransactionsTableColumns = [
    { key: 'debit', render: 'Debit' },
    { key: 'credit', render: 'Credit' },
]

const feeTransactionsTableColumns = [
    { key: 'fee_type_name', render: 'Fee Type' },
    { key: 'fee_description', render: 'Description' },
    { key: 'amount', render: 'Amount' },
    { key: 'discount', render: 'Discount' },
    { key: 'balance', render: 'Balance' },
    { key: 'transaction_amount', render: 'Paid' },
]

const customTransactionsTableColumns = [
    { key: 'transaction_category_name', render: 'Category' },
    { key: 'expense_amount', render: 'Expense' },
    { key: 'income_amount', render: 'Income' },
    { key: 'note', render: 'Note' }
]

@inject('store')
@observer
class TransactionReceiptA4 extends Component {
    render() {
        const {store, receiptData} = this.props
        const {formatCurrency} = store

        if ( !receiptData || !receiptData.isInitialized || !receiptData.isAssociationsLoaded) return <Loading />

        const { attributes, feeTransactions, walletTransactions, customTransactions, isLoading } = receiptData

        const {total_fee_paid, note} = attributes
        
        const feeTransactionsTableRows = feeTransactions.map((feeTransaction) => {
            const {
                id,
                attributes: {amount: transaction_amount},
                studentFee: {attributes: {fee_type_name, fee_description, total_discount, balance, fee_amount }}
            } = feeTransaction

            return {
                id,
                fee_type_name,
                fee_description,
                amount: formatCurrency(fee_amount),
                discount: formatCurrency(total_discount),
                balance: formatCurrency(balance),
                transaction_amount: formatCurrency(transaction_amount),
            }
        })

        

        const walletTransactionsTableRows = walletTransactions.map((walletTransaction) => {
            const {id, attributes: {credit, debit}} = walletTransaction

            return {id, credit: formatCurrency(credit), debit: formatCurrency(debit)}
        })

        const customTransactionsTableRows = customTransactions.map((customTransaction) => {
            const {attributes: {transaction_category_name, expense_amount, income_amount}} = customTransaction

            return {
                transaction_category_name,
                expense_amount: formatCurrency(expense_amount),
                income_amount: formatCurrency(income_amount),
                note
            }
        })
        
        return (
            <div className="pdf StripeLoadingContainer">
                <StripeLoading loading={isLoading} />
                <TransactionReceiptStats receiptData={receiptData} />
                <div className="pos-cardHeader ">
                    <h2 className="text--alignCenter text--bold">Receipt</h2>
                </div>
                {(walletTransactions.length > 0 || feeTransactions.length > 0) && (
                    <div className="Table Table--striped Table-responsive">
                        <table>
                            {feeTransactions.length > 0 && (
                                <thead>
                                <tr>
                                    {feeTransactionsTableColumns.map(({ key, render }) => (
                                        <th key={key}>{render}</th>
                                    ))}
                                </tr>
                                </thead>
                            )}
                            <tbody>
                            {feeTransactions.length > 0 && feeTransactionsTableRows.map((row) => (
                                <tr key={row.id}>
                                    {feeTransactionsTableColumns.map(({ key, style = {} }) => (
                                        <td style={style} key={key}>
                                            {row[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                            }


                            {feeTransactions.length > 1 && (
                                <tr className=" Table--bordered receipt-total text-p text--uppercase text--primary">
                                    <td colSpan="5" className="text--right">Fee Total</td>
                                    <td>{formatCurrency(total_fee_paid)}</td>
                                </tr>
                            )}


                            {walletTransactions.length > 0 && (
                                <React.Fragment>
                                    <tr className="Row--bordered-top  Table--bordered">
                                        <th rowSpan="2" colSpan="4">Wallet Transaction</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                    </tr>
                                    {walletTransactionsTableRows.map((row) => (
                                        <tr key={row.id} className="Table--bordered">
                                            {walletTransactionsTableColumns.map(({ key, style = {} }) => (
                                                <td style={style} key={key}>
                                                    {row[key]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </React.Fragment>
                            )}

                            {(walletTransactions.length > 0 || feeTransactions.length > 0 ) && (
                                <GrandTotalRow receiptData={receiptData} />
                            )}

                            </tbody>
                        </table>
                    </div>
                )}

                {customTransactions.length > 0 && (
                    <Table
                        columns={customTransactionsTableColumns}
                        data={customTransactionsTableRows}
                        striped
                    />
                )}

                <div>
                    <div className="utils-padded text--alignCenter">
                        <img src={barcode} alt="bluebic barcode" />
                    </div>
                </div>

                Printed: {formatDateLong(Date.now())}
            </div>
        )
    }
}

const TransactionReceiptStats = inject("store")(
    observer(({ store, receiptData }) => {
        
        
        const { student, attributes } = receiptData

        const { trans_ref, payment_method_name, transaction_date} = attributes
        const fee_balance = student ? student.totalUnpaidStudentFees : undefined
        const {formatCurrency, meta: { currentInstitution: {attributes: { logo_url }}}} = store

        return (
            <div className="pdf-header">
                <div className="pos-header-logo flex-justifySelfCenter ">
                    <img src={logo_url} alt="logo" className="utils-logo"   />
                </div>
                <div>
                    <div className="text-p text text--grey">
                        #{trans_ref}
                    </div>
                    {student && (
                        <div className="text-h4 text--bold">
                            {titleCase(student.studentName)}
                        </div>
                    )}
                </div>
                <div>
                    <div className="text-p text">
                        <span className="text--grey">Trans. Date: &nbsp;</span>
                        {formatDateLong(transaction_date)}
                    </div>
                    <div className="text-p text">
                        <span className="text--grey">Payment method: &nbsp;</span>
                        {payment_method_name}
                    </div>
                </div>
                {fee_balance > 0 && (
                    <div className="text-p text">
                        <span>Pending Fees: </span>
                        <span className="text--danger text-h4 text--bold">{formatCurrency(fee_balance)}</span>
                    </div>
                )}
            </div>
        )
    })
)


const GrandTotalRow = inject("store")(
    observer(({ store, receiptData }) => {
        const { attributes: { amount_received, amount_payed_out} } = receiptData
        const {formatCurrency} = store
        const amount = amount_payed_out || amount_received || 0
        const header = amount_payed_out ? "Amount Received" : "Total Paid"

        return (
            <tr className="Row--bordered-top text--bold  text--primary">
                <td colSpan="5" className="text--right  text-h4">{header}</td>
                <td>{formatCurrency(amount)}</td>
            </tr>
        )
    })
) 

export default TransactionReceiptA4


