import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ModalContext } from '../common/modal'

import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import UpdatePassword from '../common/update_password';
import ProfileLoading from '../common/loading/ProfileLoading';

import GuardianBioData from './GuardianBioData'
import GuardianWards from './GuardianWards'
import GuardianNotes from './GuardianNotes';
import GuardianSummary from './GuardianSummary'
import GuardianFinance from './GuardianFinance';
import DeleteGuardian from "./DeleteGuardian";
import CreateGuardian from "../students/CreateGuardian";

import GuardianPolicy from "../policies/GuardianPolicy";
import UserPolicy from "../policies/UserPolicy";

import edit from "../common/assets/edit.svg";
import trash from "../common/assets/bin.svg";
import password from './assets/password.svg'
import parents from './assets/parents.svg'
import chat from './assets/chat.svg'
import block from "../common/assets/block.svg";

import  '../common/modal/Modal.css'
import ReactHelmet from "../containers/ReactHelmet";
import employeeIcon from "../common/assets/teacher-pointing-blackboard.svg";
import EmployeeSummary from "../employees/EmployeeSummary";

@inject("store","display")
@observer
class SelectedGuardian extends Component {
    tabs = []

    componentDidMount() {
        const { store } = this.props
        const { guardianStore: {loadGuardianById}, view } = store
        const {selectedGuardianId } = view
        loadGuardianById(selectedGuardianId)
    }

    options = (context) => {
        const { store : { meta: {currentUser}, view : { selectedGuardian}} } = this.props

        const policy = new GuardianPolicy(currentUser, selectedGuardian )
        const userPolicy = new UserPolicy(currentUser, selectedGuardian.user )

        return  [
            {
                text: 'Change Password',
                disabled: !userPolicy.can_update_password,
                title: userPolicy.can_update_password ? undefined : 'No permission.',
                icon: password,
                onClick: () => context.setContent(
                    'Change Password',
                    <UpdatePassword onCancel={context.closeModal} id={selectedGuardian.user.id} />,
                    true
                ),
            },
            {
                text: 'Edit Guardian',
                disabled: !policy.can_update,
                title: policy.can_update ? undefined : "No permission.",
                onClick: () => context.setContent(
                    'Edit Guardian',
                    <CreateGuardian onClose={context.closeModal} guardianInfo={selectedGuardian} editMode />
                ),
                icon: edit
            },
            // {
            //     text: 'Move To archive',
            //     disabled: !policy.can_destroy,
            //     title: policy.can_destroy ? undefined : "No permission.",
            //     onClick: () => { },
            //     icon: chat
            // },
            {
                text: 'Delete Guardian',
                disabled: !policy.can_destroy,
                title: policy.can_destroy ? undefined : "No permission.",
                onClick: () => context.setContent(
                    'Deleting Guardian',
                    <DeleteGuardian guardianId={selectedGuardian.id} onClose={context.closeModal}  />,
                    true
                ),
                icon: trash
            },
        ]
    }

    render() {
        const { store: { view: {selectedGuardian} } } = this.props
        if (!selectedGuardian || !selectedGuardian.isInitialized) return <ProfileLoading />

        const { attributes: {notes} , students: wards, bioData }  = selectedGuardian;

        this.tabs = [
            new Tab("Bio", <GuardianBioData {...bioData} />),
            new Tab("Wards", <GuardianWards guardian={selectedGuardian} />),
            new Tab("Notes", <GuardianNotes notes={notes} />),
            new Tab("Finance", <GuardianFinance wards={wards} />, true),
        ]

        return (

            <>
                <ReactHelmet title={`Guardian Profile | ${selectedGuardian.guardianName}`} />
                <ModalContext.Consumer>
                    {context => (
                        <Tabs
                            title={{ text: 'Guardian Profile', icon: parents }}
                            tabs={this.tabs}
                            canPrint={false}
                            content={<GuardianSummary guardian={selectedGuardian} />}
                            options={selectedGuardian.isLoading ? [] : this.options(context)}
                        />
                    )}
                </ModalContext.Consumer>
            </>
            
        )
    }
}

export default SelectedGuardian
