import React from 'react'
import {observer, inject} from 'mobx-react'
import isEmpty from 'lodash.isempty';
import TrendChart from './TrendChart'
import Desktop from '../common/display/Desktop'
import NotDesktop from '../common/display/NotDesktop'
import Card from '../common/card'
import Table from '../common/table'
import icon from './assets/user.svg'
import './AbsentStudents.css'
import EmptyList from "../common/empty_list/empty_list";
import ShortLoadingText from "../common/loading/ShortLoadingText";

const columns = [
    {key: 'Name', render: 'Name'},
    {key: 'class', render: 'Class'},
    {key: 'No of days absent', render: 'No of days absent'},
    {key: 'Action', render: '', style: {textAlign: 'right'}},
]

@inject('store','display')
@observer
class AbsentStudents extends React.Component {
    

    render() {
        const {store : { view : { openStudentPageById } }, display, topAbsentStudents, attendanceStats} = this.props
        
        const dataSetToUse = attendanceStats || [
            { name: 'Absences' },
            { name: 'No of Students Absent' }
        ]

        let tableData = []
        
        if (topAbsentStudents){
            tableData = topAbsentStudents.map(({student_id, days}) => {
                const {id, nameAbbreviation, studentName, batchNameNoSession} = student_id
                return {
                    id,
                    'Name': display.isMobile ? nameAbbreviation : studentName,
                    'class': batchNameNoSession,
                    'No of days absent': days,
                    'Action': <div className="text--primary text-small text--bold touchable-opacity text--uppercase" onClick={() => openStudentPageById(id)}>View Contact Information</div>
                }
            })
        }
        
        
        return (
            <div className='dashboard-absentStudents absentStudents'>
                <div className="absentStudents-head flex-row">
                    <img className="dashboard-icon" src={icon} alt="" />
                    <span className="text-small text--uppercase">Absent students</span>
                </div>

                <Card className="card absentStudents-summaryCard">
                    {dataSetToUse.map(({name, amount, trend}) => (
                        <React.Fragment key={name}>
                            <div className="flex-col flex-alignCenter">
                                <div className={display.isMobile ? 'text-h2' : 'text-h1'}>{amount || ShortLoadingText() }</div>
                                <div className="text-small text--grey text--alignCenter">{name}</div>
                            </div>
                            <TrendChart trend={trend || 0} reversePolarity />
                        </React.Fragment>
                    ))}
                </Card>
                
                <Desktop>
                    { isEmpty(tableData) ? <EmptyList /> : (
                        <Table columns={columns} data={tableData} height={230} containerClass="card" />
                    )}
                </Desktop>
                <NotDesktop>
                    {isEmpty(tableData) ? <EmptyList /> :
                        (
                            <>
                                {tableData.filter((_, i) => i < 3).map(row => (
                                    <Card key={row.Name} className="card absentStudents-mobileCard">
                                        <div>
                                            <div className="text-p">{row.Name}</div>
                                            <div className="text-small text--grey">{row.Class}</div>
                                        </div>
                                        <div>
                                            <div className="text-small">{row['No of days absent']} Days Absent</div>
                                            <div
                                                title="View Contact Details"
                                                onClick={() => {
                                                    openStudentPageById(row.id)
                                                }}
                                                className="text--primary text-small text--bold touchable-opacity"
                                            >
                                                CONTACT
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                                {/* <div className="text--primary text-small text--bold touchable-opacity">VIEW ALL</div> */}
                            </>
                        )}
                </NotDesktop>
            </div>
        )
    }
}

export default AbsentStudents


