import './StudentAttendanceReport.css'

import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import {isSameMonth} from 'date-fns'
import HashMap from 'hashmap'
import isEmpty from 'lodash.isempty'

import Card from '../common/card'
import Mobile from '../common/display/Mobile'
import NotMobile from '../common/display/NotMobile'
import Calendar from '../common/attendance_calendar'
import SelectInput from '../common/form/SelectInput'
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup'
import StripeLoading from '../common/loading/StripeLoading'
import AttendanceReportLoading from '../common/loading/AttendanceReportLoading'
import {formatDate} from "../lib/formatDate";

@inject('store')
@observer
class StudentAttendanceReport extends React.Component {
    state = {
        termOptions: [
            { label: 'Session', value: undefined },
            { label: 'First Term', value: 1 },
            { label: 'Second Term', value: 2 },
            { label: 'Third Term', value: 3 }
        ],
        value: undefined
    }

    componentDidMount() {
        const { store } = this.props
        const {
            view: { selectedStudent }
        } = store

        const { attendanceReport: {sort} } = selectedStudent
        if (!sort.academic_session_id){
            this.handleSessionSelect(selectedStudent.attributes.academic_session_history[0].id)
        }
    }

    get academicSessionOptions() {
        const { store } = this.props
        const { view} = store
        const { academic_session_history: academicSessions } = view.selectedStudent.attributes
        const academicSessionOptions = []
        academicSessions.forEach(({ id, attributes }) => {
            academicSessionOptions.push({ text: attributes.name, value: id })
        })
        return academicSessionOptions
    }

    handleTermSelect = (selectedOption) => {
        // update store
        const { store } = this.props
        const { view, studentStore } = store
        const { attendanceReport } = view.selectedStudent
        const { sort } = attendanceReport
        sort.setAcademicTerm(selectedOption)
        studentStore.loadAttendanceReportById(view.selectedStudentId)
    }

    handleSessionSelect = (selectedOption) => {
        const { store } = this.props
        const { view, studentStore } = store
        const { attendanceReport } = view.selectedStudent
        const { sort } = attendanceReport
        sort.setAcademicSessionId(selectedOption)
        studentStore.loadAttendanceReportById(view.selectedStudentId)
    }

    render() {
        const { termOptions, value } = this.state
        const { store } = this.props
        const { view } = store
        const { attendanceReport } = view.selectedStudent
        const { sort } = attendanceReport

        return (
            <>
                <Card
                    title="Academic Session"
                    style={{ marginBottom: 10 }}
                >
                    <div className="StudentAttendanceReport">
                        <SelectInput
                            value={sort.academic_session_id}
                            onChange={this.handleSessionSelect}
                            placeholder="Select Session"
                            options={this.academicSessionOptions}
                        />
                        <SelectButtonGroup
                            selectOptions={termOptions}
                            onChange={this.handleTermSelect}
                            value={value}
                        />
                    </div>
                </Card>

                <Report reportData={attendanceReport} />
            </>
        )
    }
}

const Report = observer(({ reportData }) => {
    const {
        attributes,
        isLoading,
        daysOpened,
        daysPresent,
    } = reportData

    if ((isLoading && isEmpty(attributes)) || isEmpty(attributes)) return <AttendanceReportLoading />

    const {
        score,
        full_day_leaves_count,
        half_day_leaves_count,
        holiday_dates_and_title,
        insession_dates,
        percentage
    } = attributes
    const months = []
    insession_dates.forEach((date) => {
        const idx = months.findIndex((month) => (
            isSameMonth(month, new Date(date))
        ))
        if (idx < 0) {
            months.push(new Date(date))
        }
    })
    const firstMonth = months.slice().shift()
    const lastMonth = months.slice().pop()
    const calendarData = getCalendarMap(attributes)
    const attendanceReportClassName = classNames(
      'AttendanceReport',
      {
        'AttendanceReport-loading': isLoading
      }
    )

    return (
        <div style={{position: "relative"}}>
            <StripeLoading loading={isLoading} />
            <Card
                title="Attendance Report"
                className={attendanceReportClassName}
            >
                <div className="AttendanceReport-head">
                    <div className="AttendanceReport-stats1">
                        <div className="AttendanceReport-stats1-cell">
                            <div className="text-small text--uppercase text--bold">Days Opened</div>
                            <div className="text-p">{daysOpened}</div>
                        </div>
                        <div className="AttendanceReport-stats1-cell">
                            <div className="text-small text--uppercase text--bold">Score</div>
                            <div className="text-p">{score}</div>
                        </div>
                        <div className="AttendanceReport-stats1-cell">
                            <div className="text-small text--uppercase text--bold">Percentage</div>
                            <div className="text-p">{Math.round(percentage)}</div>
                        </div>
                    </div>

                    <div className="AttendanceReport-stats2">
                        <Stats2Item
                            variant="daysAbsent"
                            label="Days Absent"
                            value={full_day_leaves_count}
                        />
                        <Stats2Item
                            variant="daysLate"
                            label="Days Late"
                            value={half_day_leaves_count}
                        />
                        <Stats2Item
                            variant="daysPresent"
                            label="Days Present"
                            value={daysPresent}
                        />
                        <Stats2Item
                            variant="holiday_dates_and_title"
                            label="Holidays"
                            value={Object.keys(holiday_dates_and_title).length}
                        />
                    </div>
                </div>

                <div className="AttendanceReport-grid">
                    <Mobile>
                        <Calendar
                            currentMonth={firstMonth}
                            firstMonth={firstMonth}
                            lastMonth={lastMonth}
                            calendarData={calendarData}
                        />
                    </Mobile>
                    <NotMobile>
                        {months.map(month => (
                            <Calendar
                                key={month}
                                calendarData={calendarData}
                                currentMonth={month}
                                navigationDisabled
                            />
                        ))}
                    </NotMobile>
                </div>
            </Card>
        </div>
        
    )
})

function Stats2Item({ variant, label, value }) {
    const stats2ItemClass = classNames(
        'AttendanceReport-stats2-item',
        'flex-row flex-alignCenter',
        `AttendanceReport-stats2-item--${variant}`
    )
    return (
        <div className={stats2ItemClass}>
            <span className="AttendanceReport-stats2-itemTag utils-prepend">
                {value}
            </span>
            {label}
        </div>
    )
}

function getCalendarMap({ holiday_dates_and_title, insession_dates, leaves }) {
    const map = new HashMap()
    insession_dates.forEach(date => {
        const type = 'insession'
        const formattedDate = formatDate(date)
        map.set(formattedDate, { type })
    })
    holiday_dates_and_title.forEach((memo, date) => {
        const type = 'holiday'
        const formattedDate = formatDate(date)
        map.set(formattedDate, { type, memo })
    })
    leaves.forEach(leave => {
        const type = leave.half_day ? 'daysLate' : 'daysAbsent'
        const memo = leave.reason
        const date = new Date(leave.date)
        const formattedDate = formatDate(date)
        map.set(formattedDate, { type, memo })
    })
    return map
}

export default StudentAttendanceReport