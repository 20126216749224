import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import DatePicker from "react-datepicker";
import "../../vendor/react-datepicker.css";
import classNames from 'classnames'
import Calendar from './assets/Calendar.svg'
import {fullDateTimeFormat} from "../../lib/formatDate";



class DateTimeInput extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        placeholder: "Select time",
        className: ""
    }

    onTimeChange = time => {
        const { onChange } = this.props
        onChange(time.toString())
    }

    render() {
        const { className, value, placeholder } = this.props
        const formInputControlClass = classNames(
            'FormInputControl',
            'flex-row flex-spaceBetween flex-alignCenter',
            className
        )

        const dateTimeInputRef = React.createRef();
        const InputBox = React.forwardRef((props, ref) => (
            <input
                {...props}
                style={{ paddingLeft: 10 }}
                className="FormInputControl-textInput"
                ref={ref}
            />
        ));
        
        return (
            <div className={formInputControlClass} style={{ paddingLeft: 0 }}>
                <div className="flex-row flex-alignCenter">
                    <div
                        className="dateInput-prepend touchable-opacity"
                        onClick={() => dateTimeInputRef.current.focus()}
                    >
                        <img src={Calendar} alt="" />
                    </div>

                    <DatePicker
                        showTimeSelect
                        selected={value? new Date(value) : undefined}
                        onChange={this.onTimeChange}
                        customInputRef={dateTimeInputRef}
                        placeholderText={placeholder}
                        customInput={<InputBox ref={dateTimeInputRef} />}
                        timeInputLabel="Time:"
                        timeIntervals={15}
                        dateFormat={fullDateTimeFormat}
                        // timeCaption="time"
                        showTimeInput
                        
                    />
                </div>
            </div>
        )
    }
}
export default DateTimeInput