import { types } from "mobx-state-tree"
import { BankAccount } from "./BankAccount"

export const BankTeller = types
    .model("BankTeller", {
        id: types.identifier,
        type: "bank_teller",
        attributes: types.maybe(types.model({
            teller_number: "",
            teller_date: "",
            amount: ""
        })),
        relationships: types.model({
            institution_bank_account: types.model({
                data: types.model({
                    id: types.late(() => types.reference(BankAccount))
                })
            })
        }),
    })
    .views(self => ({
        get bankName() {
            try {
                return self.relationships.institution_bank_account.bankName
            } catch (e) {
                console.warn(e)
                return null
            }
        }
    }))