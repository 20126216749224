import axios from './axios'
import { TESTENV } from './constants'
import dashboardJSON from '../json/dashboard_report.json'

export function DashboardService(env) {

    async function getDashboard() {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/dashboard`)
            return data
        }
        return dashboardJSON
    }

    return {
        getDashboard
    }
}