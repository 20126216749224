import { applySnapshot, types } from 'mobx-state-tree';

export const BehaviouralDomainGroup = types.model({
    grades:  types.array(types.model(
            {
                indicator_id: types.string,
                grade_scale_level_id: types.maybe(types.string)
            }
        )),
    })
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        },
       
    }))
    .actions(self => ({
        reset(){
            applySnapshot(self, {})
        },
        
        setFormInstance(studentBehavDomainSores, indicators) {
            const grades = studentBehavDomainSores.map(({attributes : {indicator_id, grade_scale_level_id}}) => ({
                indicator_id : indicator_id.toString(),
                grade_scale_level_id : grade_scale_level_id.toString()
            }));
            
            indicators.forEach(({id}) => {
                const indicator_id = id.toString();
                const hasScore = grades.some(grade => grade.indicator_id === indicator_id);

                if(!hasScore) grades.push({indicator_id, grade_scale_level_id: undefined})

            })
            
            applySnapshot(self, {
                grades
            })
        },
    }))