import { types } from "mobx-state-tree"
import { GradeScale } from './GradeScale';

export const BehaviouralDomainGroup = types
    .model("BehaviouralDomainGroup", {
        id: types.identifier,
        type: "behavioural_domain_group",
        attributes: types.maybe(types.model({
            name: types.string,
            grade_scale_id: types.number,
            learning_domain: types.string,
            description: types.maybeNull(types.string),
            indicators: types.array(
                types.model({
                    id: types.number,
                    name: types.string,
                    abbreviation: types.maybeNull(types.string),
                    position: types.number,
                    description: types.maybeNull(types.string)
                })
            )
        })),
        relationships: types.maybe(types.model({
            grade_scale: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(GradeScale))
                })
            }))
        })),
    }).views(self => ({
        get gradeScale() {
            return self
                .relationships
                .grade_scale
                .data
                .id
        },
    }))