import './StripeLoading.css'

import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

function StripeLoading({ loading }) {
  const tableLoadingClass = className(
    'StripeLoading',
    { 'StripeLoading-isHidden': !loading }
  )

  return (
    <div className={tableLoadingClass}>
      <div className="StripeLoading-indeterminate" />
    </div>
  )
}
StripeLoading.propTypes = {
  loading: PropTypes.bool
}
StripeLoading.defaultProps = {
  loading: false
}

export default StripeLoading

