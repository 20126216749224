import './PhotoInput.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Desktop from '../display/Desktop'
import NotDesktop from '../display/NotDesktop'
import Button from '../button'
import upload from './assets/upload.svg'
import upload2 from './assets/upload-2.png'
import classNames from 'classnames'

const dropzoneRef = React.createRef()

class PhotoInput extends Component {
  static propTypes = {
    // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    // success: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.array, PropTypes.object
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    displayText: PropTypes.string,
    // onBlur: PropTypes.func.isRequired,
    // onFocus: PropTypes.func.isRequired,
  }

  static defaultProps = {
    style: null,
    value: null,
    displayText: "Upload Picture"
  }

  state = { fileName: null }

  onDrop = (files) => {
    const { onChange } = this.props
    const file = files.slice().shift()
    getBase64(file, (e) => onChange(e))
    this.setState({ fileName: file.name })
  }

  onCancel = () => {
    const { onChange } = this.props

    // TODO: Cancel should not be used to clear the field. Requires UI interface
    onChange('')
  }

  get filename() {
    const { fileName } = this.state
    return fileName || "Upload Picture"
  }

  render() {
    const { style, displayText } = this.props
    const { fileName } = this.state
    const desktopPhotoInputClass = classNames(
      'photoInput flex-col flex-alignCenter flex-justifyCenter touchable-highlight',
      {
        'photoInput--display': displayText
      }
    )
    return (
      <>
        <Desktop>
          <Dropzone
            onDrop={this.onDrop}
            onFileDialogCancel={this.onCancel}
            style={style}
            className={desktopPhotoInputClass}
            activeClassName="photoInput--focus"
            acceptClassName="photoInput--success"
            rejectClassName="photoInput--error"
            accept="image/*"
            multiple={false}
          >
            <img src={displayText ? upload2 : upload} alt="upload" />
            {
              !displayText && (<div className="text-p text--primary text--alignCenter">{this.filename}</div>)
            }
            {
              displayText
                ? <span className="display-text">{fileName || displayText}</span>
                : (
                  <>
                    <div className="text-p text--grey">You can also upload files by</div>
                    <div className="text-p text--primary">clicking here</div>
                  </>
                )
            }

          </Dropzone>
        </Desktop>
        <NotDesktop>
          <Dropzone
            className="utils-hide"
            ref={dropzoneRef}
            onDrop={this.onDrop}
            onFileDialogCancel={this.onCancel}
            accept="image/*"
            multiple={false}
          />
          <Button
            onClick={() => { dropzoneRef.current.open() }}
            fullwidth
          >
            {this.filename}
          </Button>
        </NotDesktop>
      </>
    )
  }
}
export default PhotoInput

function getBase64(file, next) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => next(reader.result);
  reader.onerror = (error) => {
    throw error;
  };
}
