/* eslint-disable camelcase */
import './UserSummary.css'

import React from 'react'
import isEmpty from 'lodash.isempty'
import Avatar, { AVI_TYPES } from '../common/avatar'
import ProfileLoading from '../common/loading/ProfileLoading';

export default function UserSummary({ user }) {
  const { attributes, fullName, isLoading } = user
  const { username, photo_url, gender, email, status } = attributes

  if (isEmpty(attributes)) return <ProfileLoading useCard={false} />
  
  return (
    <div className="UserSummary">
      <Avatar
        className="UserSummary-avatar"
        src={photo_url}
        bordered
        sex={gender}
        type={AVI_TYPES.EMPLOYEE}
      />
      <div>
        <div className="text--grey text-small">Full Name</div>
        <div className="text-h4">{fullName}</div>
      </div>
      <div>
        <div className="text--grey text-small">Username</div>
        <div className="text-h4">{username}</div>
      </div>
      <div>
        <div className="text--grey text-small">Email</div>
        <div className="text-h4">{email}</div>
      </div>
    </div>
  )
}
