import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import NotDesktop from '../display/NotDesktop'
import close from './assets/close-white.svg'
import menu from './assets/menu.svg'
import { sidebar } from './index'

function SidebarToggle() {
    const sidebarToggleClass = classNames(
        'sidebarToggle touchable-opacity',
        { 'sidebarToggle-isFixed': sidebar.isVisible }
    )

    return (
        <NotDesktop>
            <div
                onClick={() => {
                    sidebar.toggleVisible();
                    sidebar.toggleSettingsPane(false);
                }}
                className={sidebarToggleClass}
            >
                {sidebar.isVisible && (
                    <img className="sidebarToggle-icon" src={close} alt="" />
                )}
                {sidebar.isVisible || (
                    <>
                        <img className="sidebarToggle-icon" src={menu} alt="" />
                        <div className="sidebarToggle-text">Menu</div>
                    </>
                )}
            </div>
        </NotDesktop>
    )
}

export default observer(SidebarToggle)
