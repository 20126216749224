import './FinanceList.css'

import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd';
import classNames from "classnames";

import { formatDate } from '../lib/formatDate'
import Card from '../common/card'
import CustomTransactionSearch from './CustomTransactionSearch'
import Collection from '../common/Collection';
import NewCustomTransaction from "./NewCustomTransaction";

import moneyIcon from '../common/assets/money-bag.svg'



import {ExportCollection} from "../store/actions/ExportCollection";
import {ModalContext} from "../common/modal";
import {CustomTransactionsSummary} from "./FinanceTransactionsSummary";
import calculator from "../common/assets/calculator.svg";
import spreadsheet from "../common/assets/spreadsheet.svg";
import Loading from "../common/loading/Loading";
import Button from "../common/button";
import arrowDown from "../common/assets/arrow-down-white.svg";





const columns = [
    { key: 'trans_ref', render: 'Receipt No.' },
    { key: 'transaction_category_name', render: 'Category' },
    { key: 'note', render: 'Note' },
    { key: 'transaction_date', render: 'Trans. Date' },
    { key: 'income_amount', render: 'Income' },
    { key: 'expense_amount', render: 'Expense' },
    { key: 'payment_method_name', render: 'Pay. Method' },
    { key: 'created_by_name', render: 'Recorded By' },
    { key: 'created_at', render: 'Creation Date' },
]
const defaultColumns = [
    'trans_ref', 'transaction_category_name', 'note',
    'transaction_date', 'income_amount', 'expense_amount'
]




@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportCustomFinanceTransactionListAction = ExportCollection.create({})
        this.state = { exportCustomFinanceTransactionListAction }
    }

    initiateCustomFinanceTransactionListExportDownload = () => {
        const { exportCustomFinanceTransactionListAction } = this.state
        const {store} = this.props;
        const { customTransactionStore: {selectedItemKeys, downloadCustomTransactionListSpreadsheet}} = store

        exportCustomFinanceTransactionListAction.setToGetItems(selectedItemKeys.toJSON())
        downloadCustomTransactionListSpreadsheet(exportCustomFinanceTransactionListAction)
    }

    render() {
        const { exportCustomFinanceTransactionListAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { customTransactionStore: {selectedItemKeys}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedItemKeys.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                context.setContent(
                                    `Finance Summary for ${selectedItemKeys.length} Transactions`,
                                    <CustomTransactionsSummary customTransactionIDs={selectedItemKeys} />,
                                    true
                                )
                            }}
                        >
                            <img src={calculator} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Transactions Summary
                        </button>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                this.initiateCustomFinanceTransactionListExportDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheet} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

const CustomTransactions = inject("store")(
    observer(({ store }) => {
        const {
            customTransactionStore: settings,
            view: {
                openFinanceTransactionPageById,
                setBackPage,
                getCustomTransactionsPage
            },
            formatCurrency
        } = store
        
        const {customTransactionStore: {selectedItemKeys, searchResults}} = store
        
        const tableData = searchResults
            .map((customTransaction) => {
                const {
                    id,
                    attributes: {
                        income_amount,
                        transaction_category_name,
                        expense_amount,
                        created_at
                    },
                    financeTransaction: {
                        id: financeTransactionID,
                        attributes: {
                            trans_ref,
                            transaction_date,
                            payment_method_name,
                            created_by_name,
                            note
                        }
                    }
                } = customTransaction
                return {
                    id,
                    trans_ref: (
                        <a
                            href={`/finance/transactions/${financeTransactionID}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Income/Expense', getCustomTransactionsPage())
                                openFinanceTransactionPageById(id)
                            }}
                        >
                            {trans_ref}
                        </a>
                    ),
                    transaction_category_name,
                    note,
                    transaction_date: formatDate(transaction_date),
                    income_amount: formatCurrency(income_amount),
                    expense_amount: formatCurrency(expense_amount),
                    payment_method_name,
                    created_by_name,
                    created_at: formatDate(created_at)
                }
            })
        const tableSettings = {
            tableData,
            columns,
            defaultColumns,
            tableProps: {}
        }
        
        const buildMobileItem = customTransaction => (
            <CustomTransactionsItem key={customTransaction.id} customTransaction={customTransaction} />
        )
        
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">
                        <ActionsButton className="utils-prepend" />
                        {Boolean(selectedItemKeys.length) && (`${selectedItemKeys.length} selected`)}
                    </div>
                    {collectionSummaryText}
                    <NewCustomTransaction />
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={moneyIcon} alt="" />
                        <span className="text-small utils-append text--uppercase">Income/Expense</span>
                    </Col>
                    <Col span={12}>
                        <NewCustomTransaction />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12}>
                        <ActionsButton fullwidth />
                    </Col>
                    <Col span={12}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={CustomTransactionSearch}
            />
        )
    }))

export default CustomTransactions

const CustomTransactionsItem = inject("store")(
    observer(({ store, customTransaction }) => {
        const { formatCurrency, view: { openFinanceTransactionPageById } } = store
        const {
            id,
            attributes: {
                income_amount,
                transaction_category_name,
                expense_amount,
            },
            financeTransaction: {
                id: finance_transaction_id,
                attributes: {
                    trans_ref,
                    transaction_date,
                    note
                }
            }
        } = customTransaction
        return (
            <Card key={id} className="mb-1">
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={8} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Receipt No.</div>
                        <a
                            href={`/finance/transactions/${finance_transaction_id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openFinanceTransactionPageById(id)
                            }}
                        >
                            {trans_ref}
                        </a>
                    </Col>
                    <Col span={16} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Category</div>
                        <div>{transaction_category_name}</div>
                    </Col>
                    <Col span={24} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Note</div>
                        <div>{note}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Trans. Date</div>
                        <div>{formatDate(transaction_date)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Income</div>
                        <div>{formatCurrency(income_amount)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Expense</div>
                        <div>{formatCurrency(expense_amount)}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)