import React from 'react';
import isEmpty from "lodash.isempty";
import { formatDate } from '../lib/formatDate';
import EmptyList from "../common/empty_list/empty_list";
import NotMobile from '../common/display/NotMobile';
import Mobile from '../common/display/Mobile';
import Table from '../common/table';
import Card from '../common/card';
import icon from '../common/assets/newspaper.svg';
import './Assessments.css';
import '../styles/scoreCell.css';

const columns = [
  { key: 'title', render: 'Title' },
  { key: 'student', render: 'Student' },
  { key: 'entered_by', render: 'Scored By' },
  { key: 'date', render: 'Date' },
  { key: 'score', render: 'Mark Given' },
  { key: 'subject', render: 'Subject' }
]

function Assessments(props) {
    const {recentAssessmentScores } = props

    let data = []
    try {
        if (!isEmpty(recentAssessmentScores)){
            data = recentAssessmentScores.map((assessmentScore) => {
                const {
                    id,
                    student,
                    subjectName,
                    createdByName,
                    attributes: {comment, exempt, late, collected, missing, score, batch_name_without_session, updated_at},
                    assessment: {attributes: {name: title}, describePoints},
                } = assessmentScore

                const commentIcon = <i style={{color: 'hsla(0,0%,74%,1)', fontSize: '14px', paddingLeft: '2px'}} className="material-icons">message</i>

                const columnDetails =
                    (
                        <>
                            {exempt && <span title='Student Exempted.'>EX</span>}
                            {!exempt && `${score || ''}/${describePoints}`}
                            {!exempt && (late || collected || missing) && (
                                <div className='badgeWrapper'>
                                    {(collected) && (<div title='Collected' className='numberCircle collected'>C</div>)}
                                    {(late) && (<div title='Late' className='numberCircle late'>L</div>)}
                                    {(missing) && (<div title='Missing' className='numberCircle missing'>M</div>)}
                                </div>
                            )}
                            {comment && <sup title={comment}>{commentIcon}</sup>}
                        </>
                    )


                return {
                    id,
                    title,
                    student: student.studentName,
                    entered_by: createdByName,
                    date: formatDate(updated_at),
                    score: columnDetails,
                    subject: subjectName,
                    batchNameWithoutSession: batch_name_without_session,
                }
            });
        }
    } catch (e) {
        console.error(e)
    }
    
    return (
        <div className='dashboard-assessments assessments'>
            <div className="flex-row flex-spaceBetween assessments-head">
                <div className="flex-row">
                    <img className="dashboard-icon" src={icon} alt="" />
                    <span className="text-small text--uppercase">Assessments</span>
                </div>
                {/*  <NotMobile>
                    <span className="text--primary text-small text--bold text--uppercase touchable-opacity">View all</span>
                </NotMobile>  */}
            </div>

            {isEmpty(data) && <EmptyList />}

            {!isEmpty(data) && (
                <>
                    <NotMobile>
                        <Table columns={columns} data={data} height={400} containerClass="card" responsive={false} />
                    </NotMobile>
                    <Mobile>
                        {data.filter((_, i) => i < 5).map(row => (
                            <Card key={row.id} className="assessments-mobileCard">
                                <div>
                                    <div className="text-p">{row.student}</div>
                                    <div className="text-p text--grey">{row.batchNameWithoutSession}</div>
                                </div>
                                <div>
                                    <div className="text-p">{row.subject}</div>
                                    <div className="text-p">{row.score}</div>
                                </div>
                            </Card>
                        ))}
                        {/* <div className="text--primary text-small text--bold touchable-opacity">VIEW ALL</div> */}
                    </Mobile>
                </>
            )}
        </div>
    );
}

export default Assessments