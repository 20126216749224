import { types } from "mobx-state-tree"
import { FinanceTransaction } from "./FinanceTransaction";
import { StudentFee } from "./StudentFee";

export const FeeTransaction = types
    .model("FeeTransaction", {
        id: types.identifier,
        type: "student_fee_transaction",
        attributes: types.maybe(types.model({
            amount: "",
            created_at: "",
        })),
        relationships: types.optional(types.model({
            finance_transaction: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(FinanceTransaction))
                })
            })),
            student_fee: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(StudentFee))
                })
            })),
        }), {}),
        isLoading: true,
    })
    .views(self => ({
        get financeTransaction() {
            try {
                return self
                    .relationships
                    .finance_transaction
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get studentFee() {
            try {
                return self
                    .relationships
                    .student_fee
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
    }))