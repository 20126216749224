import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import {isSameYear, isSameMonth, isSameDay} from 'date-fns'
import { Icon, Popconfirm } from 'antd'
import Card from '../common/card'
import edit from '../common/assets/edit-blue.svg'
import trashcan from '../common/assets/trash_can.svg'
import Loading from '../common/loading/Loading'
import EventPolicy from "../policies/EventPolicy";
import {dateFormatFull, dateFormatOnlyDay, dateFormatShort, formatDate, timeFormat} from "../lib/formatDate";


@inject("store")
@observer
class SelectedEvent extends Component {
    constructor(props){
        super(props)
        this.state = { }
    }

    componentDidMount() {
        const { store: {view, eventStore: {loadEventById }} } = this.props
        loadEventById(view.selectedEventId)
    }

    render() {
        const { store } = this.props
        const { view } = store
        const { selectedEvent } = view
        const { isLoading, attributes } = selectedEvent
        
        if (isLoading) return <Loading />

        return (
            <Card title={!isLoading && <EventHeader />}>
                <div>
                    <span className='text-h1 text--primary'>{selectedEvent.attributes.title}</span>
                    <span className='text-small'>{selectedEvent.start_time}</span>
                </div>
                <div className='text-small'>
                    <span><DescribeTimeRange /></span>
                    { attributes.holiday && (<span>&nbsp; &middot;&nbsp; Holiday</span>)}
                    { attributes.common && (<span>&nbsp; &middot;&nbsp; Common to all</span>)}
                </div>
                <div className='mt-2 mb-3'><p>{attributes.description}</p></div>
            </Card>
        )
    }
}

const EventHeader = inject("store")(
    observer(({ store, children }) => {
        const { view: {
            selectedEvent, selectedEventId, getEventPage, openEditEventPageById, openEventsPage, setBackPage
        }, eventStore: {deleteEventById}, meta: {currentUser} } = store

        const policy = new EventPolicy(currentUser, selectedEvent)


        async function handleDelete() {
            await deleteEventById(selectedEventId)
            openEventsPage()
        }
        
        return (
            <div className="TransactionReceipt-header">
                <span className="text--uppercase text-small text--spacedLetters">Event</span>

                <div className="flex-row">
                    {children}
                    
                    { (currentUser.is_admin || currentUser.is_employee) && (
                        <div className="TransactionReceipt-buttonGroup text-p">
                            <div className="flex-row flex-alignCenter touchable-highlight">
                                <a
                                    href={`/events/edit/${selectedEventId}`}
                                    title={policy.can_update ? undefined : "No permission."}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!policy.can_update) return
                                        setBackPage('Event', getEventPage(selectedEventId))
                                        openEditEventPageById(selectedEventId)
                                    }}
                                >
                                    <img src={edit} alt="" className="utils-prepend" />
                                    <span>Edit</span>
                                </a>
                            </div>
                            <div className="flex-row flex-alignCenter touchable-highlight">
                                <Popconfirm
                                    title="Are you sure delete this item?"
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={handleDelete}
                                >
                                    <button
                                        type='button'
                                        disabled={!policy.can_destroy}
                                        title={policy.can_destroy ? undefined : "No permission."}
                                        className='text--primary'
                                    >
                                        <img src={trashcan} alt="" className="utils-prepend" />
                                        <span>Delete</span>
                                    </button>
                                </Popconfirm>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    })
)

const DescribeTimeRange = inject("store")(
    observer(({ store}) => {
        const { view: { selectedEvent: {startTime, endTime, attributes: {all_day}} } } = store
        
        
        try {
            if (all_day) return `${formatDate(startTime, `${dateFormatFull} (${timeFormat})`)}`
            
            const timeDuration = `(${formatDate(startTime, timeFormat)} - ${formatDate(endTime, timeFormat)})`.replace(/ /g, "\u00a0")
            const fullDateEndTime = `${formatDate(endTime, dateFormatFull)} ${timeDuration}`
            const fullDateStartTime = `${formatDate(startTime, dateFormatFull)} ${timeDuration}`
            
            if (isSameDay(startTime, endTime)){
                return fullDateStartTime
            }
            if (isSameMonth(startTime, endTime)){
                return `${formatDate(startTime, dateFormatOnlyDay)}-${fullDateEndTime}`
            }
            if (isSameYear(startTime, endTime)){
                return `${formatDate(startTime, dateFormatShort)} - ${fullDateEndTime}`
            }
            return `${formatDate(startTime, dateFormatFull)} - ${fullDateEndTime}`


        } catch (e) {
            return 'Error!'
        }
    })
)


export default SelectedEvent
