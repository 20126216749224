import React from 'react'
import {inject} from "mobx-react";
import { SidebarModel } from './SidebarModel'
import Sidebar from './Sidebar';
import { SidebarItem, SidebarItemChild } from './SidebarItem';

import dashboard from './assets/dashboard.svg'
import dashboard_active from './assets/dashboard-active.svg'
import student from './assets/student.svg'
import student_active from './assets/student-active.svg'
import employee from './assets/employees.svg'
import employee_active from './assets/employees-active.svg'
import guardian from './assets/guardians.svg'
import guardian_active from './assets/guardians-active.svg'
import finance from './assets/finance.svg'
import finance_active from './assets/finance-active.svg'
import subjects from './assets/subjects.svg'
import subjects_active from './assets/subjects-active.svg'
import batches from './assets/batches.svg'
import batches_active from './assets/batches-active.svg'
import report_cards from './assets/report-cards.svg'
import report_cards_active from './assets/report-cards-active.svg'
import events from './assets/events.svg'
import events_active from './assets/events_active.svg'
import chat from './assets/chat.svg'
import chat_active from './assets/chat_active.svg'

import StudentPolicy from "../../policies/StudentPolicy";
import GuardianPolicy from "../../policies/GuardianPolicy";
import EventPolicy from "../../policies/EventPolicy";
import BatchPolicy from "../../policies/BatchPolicy";
import SubjectPolicy from "../../policies/SubjectPolicy";
import FeePolicy from "../../policies/FeePolicy";
import EmployeePolicy from "../../policies/EmployeePolicy";
import AttendancePolicy from "../../policies/AttendancePolicy";
import InstitutionPolicy from "../../policies/InstitutionPolicy";
import ReportCardGroupPolicy from "../../policies/ReportCardGroupPolicy";
import DashboardPolicy from "../../policies/DashboardPolicy";
import MessagePolicy from "../../policies/MessagePolicy";

export const sidebar = SidebarModel.create({})

@inject('store')
class SidebarContainer extends React.Component {
    state = { activeId: null }

    handleItemClick = (id) => {
        let { activeId } = this.state
        activeId = (id === activeId) ? null : id
        this.setState({ activeId })
    }

    render() {
        const { activeId } = this.state
        const { store: {meta: {currentUser}}, viewStore } = this.props
        
        const studentPolicy = new StudentPolicy(currentUser, 'Student')
        const guardianPolicy = new GuardianPolicy(currentUser, 'Guardian')
        const employeePolicy = new EmployeePolicy(currentUser, 'Employee')
        const feePolicy = new FeePolicy(currentUser, 'Fee')
        const subjectPolicy = new SubjectPolicy(currentUser, 'Subject')
        const batchPolicy = new BatchPolicy(currentUser, 'Batch')
        const eventPolicy = new EventPolicy(currentUser, 'Event')
        const attendancePolicy = new AttendancePolicy(currentUser, 'Attendance')
        const institutionPolicy = new InstitutionPolicy(currentUser, 'Institution')
        const reportCardGroupPolicy = new ReportCardGroupPolicy(currentUser, 'ReportCardGroup')
        const dashboardPolicy = new DashboardPolicy(currentUser, 'Dashboard')
        const messagePolicy = new MessagePolicy(currentUser, 'Message')
        
        const {
            page,
            openDashboardPage,
            openStudentsPage,
            openGuardianPage,
            openStudentPage,
            openEmployeesPage,
            openGuardiansPage,
            openFinanceTransactionsPage,
            openFeeTransactionsPage,
            openCustomTransactionsPage,
            openExpenseAccountTransactionsPage,
            openFeesPage,
            openSubjectsPage,
            openBatchesPage,
            openEventsPage,
            openReportCardGroupsPage,
            openMessagesPage
        } = viewStore


        if (currentUser.is_admin || currentUser.is_employee){
            return (
                <Sidebar>
                    {dashboardPolicy.can_show && (
                        <SidebarItem
                            icon={dashboard}
                            activeIcon={dashboard_active}
                            label="Dashboard"
                            isActive={page === 'dashboard'}
                            handleClick={openDashboardPage}
                            href="/dashboard"
                            id={1}
                        />
                    )}

                    {studentPolicy.can_index && (
                        <SidebarItem
                            icon={student}
                            activeIcon={student_active}
                            label="Students"
                            isActive={['students', 'student', 'students/add', 'students/edit'].includes(page)}
                            id={2}
                            handleClick={openStudentsPage}
                            href="/students"
                        />
                    )}

                    {employeePolicy.can_index && (
                        <SidebarItem
                            icon={employee}
                            activeIcon={employee_active}
                            label="Employees"
                            isActive={['employees', 'employee', 'employees/add', 'employees/edit'].includes(page)}
                            id={3}
                            isOpened={activeId === 3}
                            handleClick={openEmployeesPage}
                            href="/employees"
                        />
                    )}

                    {guardianPolicy.can_index && (
                        <SidebarItem
                            icon={guardian}
                            activeIcon={guardian_active}
                            label="Guardians"
                            isActive={['guardians', 'guardian'].includes(page)}
                            handleClick={openGuardiansPage}
                            href="/guardians"
                            id={4}
                        />

                    )}

                    {feePolicy.can_index && (
                        <SidebarItem
                            icon={finance}
                            activeIcon={finance_active}
                            label="Finance"
                            isActive={[
                                'finance/transactions',
                                'finance/transaction',
                                'finance/fee-transactions',
                                'finance/custom-transactions',
                                'finance/expense-account-transactions',
                                'finance/fees',
                            ].includes(page)}
                            id={5}
                            isOpened={activeId === 5}
                            handleClick={this.handleItemClick}
                        >
                            <SidebarItemChild
                                label="Receipts"
                                isActive={page === 'finance/transactions'}
                                handleClick={openFinanceTransactionsPage}
                                href="/finance/transactions"
                            />
                            <SidebarItemChild
                                label="Fee Transactions"
                                isActive={page === 'finance/fee-transactions'}
                                handleClick={openFeeTransactionsPage}
                                href="/finance/fee-transactions"
                            />
                            <SidebarItemChild
                                label="Income/Expense"
                                isActive={page === 'finance/custom-transactions'}
                                handleClick={openCustomTransactionsPage}
                                href="/finance/custom-transactions"
                            />
                            <SidebarItemChild
                                label="Wallet Transactions"
                                isActive={page === 'finance/expense-account-transactions'}
                                handleClick={openExpenseAccountTransactionsPage}
                                href="/finance/expense-account-transactions"
                            />
                            <SidebarItemChild
                                label="Fee Management"
                                isActive={page === 'finance/fees'}
                                handleClick={openFeesPage}
                                href="/finance/fees"
                            />
                        </SidebarItem>
                    )}

                    {subjectPolicy.can_index && (
                        <SidebarItem
                            icon={subjects}
                            activeIcon={subjects_active}
                            label="Subjects"
                            isActive={page === 'subjects'}
                            handleClick={openSubjectsPage}
                            href="/subjects"
                            id={6}
                        />
                    )}

                    {batchPolicy.can_index && (
                        <SidebarItem
                            icon={batches}
                            activeIcon={batches_active}
                            label="Batches"
                            isActive={page === 'batches'}
                            handleClick={openBatchesPage}
                            href="/batches"
                            id={7}
                        />
                    )}

                    {reportCardGroupPolicy.can_index && (
                        <SidebarItem
                            icon={report_cards}
                            activeIcon={report_cards_active}
                            label="Report Cards"
                            isActive={['report-cards', 'report-card'].includes(page)}
                            handleClick={openReportCardGroupsPage}
                            href="/report-cards"
                            id={8}
                        />
                    )}

                    {messagePolicy.can_index && (
                        <SidebarItem
                            icon={chat}
                            activeIcon={chat_active}
                            label="Messages"
                            isActive={['messages', 'message'].includes(page)}
                            handleClick={openMessagesPage}
                            href="/messages"
                            id={9}
                        />
                    )}

                    {eventPolicy.can_view_calendar && (
                        <SidebarItem
                            icon={events}
                            activeIcon={events_active}
                            label="Events"
                            isActive={['events','event'].includes(page)}
                            handleClick={openEventsPage}
                            href='/events'
                            id={10}
                        />
                    )}

                    {/* <SidebarItem
                    icon={sms}
                    activeIcon={sms}
                    label="SMS"
                    isActive={false}
                    handleClick={() => { }}
                    id={9}
                /> */}
                </Sidebar>
            )
        }
        
        if (currentUser.is_student){
            return (
                <Sidebar>

                    <SidebarItem
                        icon={student}
                        activeIcon={student_active}
                        label="My Profile"
                        isActive={['student'].includes(page)}
                        id={2}
                        handleClick={() => { openStudentPage(currentUser.userable)}}
                        href={`/students/${currentUser.userable.id}`}
                    />
                    
                    {eventPolicy.can_view_calendar && (
                        <SidebarItem
                            icon={events}
                            activeIcon={events_active}
                            label="Events"
                            isActive={['events','event'].includes(page)}
                            handleClick={openEventsPage}
                            href='events'
                            id={10}
                        />
                    )}
                </Sidebar>
            )
        }
        
        if (currentUser.is_guardian){
            return (
                <Sidebar>

                    <SidebarItem
                        icon={guardian}
                        activeIcon={guardian_active}
                        label="My Profile"
                        isActive={['guardian'].includes(page)}
                        id={2}
                        handleClick={() => { openGuardianPage(currentUser.userable)}}
                        href={`/guardians/${currentUser.userable.id}`}
                    />
                    
                    {eventPolicy.can_view_calendar && (
                        <SidebarItem
                            icon={events}
                            activeIcon={events_active}
                            label="Events"
                            isActive={['events','event'].includes(page)}
                            handleClick={openEventsPage}
                            href='events'
                            id={10}
                        />
                    )}
                </Sidebar>
            )
        }
        
    }
}
export default SidebarContainer
