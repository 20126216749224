import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import classNames from 'classnames'
import { PropTypes as MxPropTypes, observer } from 'mobx-react'
import ShowMoreText from 'react-show-more-text'

@observer
class TableBody extends Component {
  static propTypes = {
    rows: MxPropTypes.arrayOrObservableArray.isRequired,
    onRowSelect: PropTypes.func,
    visibleColumns: MxPropTypes.arrayOrObservableArray.isRequired,
    isDefaultVisibleColumnsKeysExceeded: PropTypes.bool.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])),
    rowSelectionKey: PropTypes.string,
    useShowMore: PropTypes.bool,
    shouldSelectAllColumns: PropTypes.bool,
  }

  static defaultProps = {
    onRowSelect: null,
    selectedRows: null,
    rowSelectionKey: null,
    useShowMore: false,
    shouldSelectAllColumns: false,
  }

  getRowClass = (shouldSelectAllColumns, selectedRows, rowKey) => classNames(
    {
      'Row--isSelected': shouldSelectAllColumns && selectedRows.includes(rowKey),
    }
  )

  render() {
    const {
      rows, onRowSelect, visibleColumns, isDefaultVisibleColumnsKeysExceeded,
      selectedRows, rowSelectionKey, useShowMore, shouldSelectAllColumns,
    } = this.props

    return (
      <React.Fragment>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={row[rowSelectionKey] || index}
              className={this.getRowClass(shouldSelectAllColumns, selectedRows, row[rowSelectionKey])}
              // onClick={onRowSelect ? () => onRowSelect(row) : null}
            >
              {!!onRowSelect && !shouldSelectAllColumns && (
                <td>
                  <Checkbox checked={selectedRows.includes(row[rowSelectionKey])} onChange={() => onRowSelect(row)} />
                </td>
              )}
              {visibleColumns.map(({ key, style = {}, display }) => (
                <td style={style} key={key}>
                  {typeof row[key] === 'string' && useShowMore && !display && (
                    <ShowMoreText
                      lines={3}
                      more='More'
                      less='Less'
                    >
                      {row[key]}
                    </ShowMoreText>
                  )}
                  {(typeof row[key] !== 'string' || !useShowMore || !display) && row[key]}
                  {typeof display === 'function' && display(row)}
                </td>
              ))}
              {isDefaultVisibleColumnsKeysExceeded && <td>&nbsp;</td>}
            </tr>
          ))}
        </tbody>
      </React.Fragment>
    )
  }
}

export default TableBody
