import React from "react";
import PropTypes from "prop-types";

import Button from "../common/button";

export default function ButtonDropDownContent({ handleEditClick, handleDeleteClick, disabled }) {
  return (
      <div>
          <Button disabled={disabled} buttonType="flat" onClick={handleEditClick}>Edit</Button>
          <Button disabled={disabled} buttonType="flat" onClick={handleDeleteClick}>Delete</Button>
      </div>
  );
}

ButtonDropDownContent.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
