import { types } from "mobx-state-tree"
import { FinanceTransaction } from "./FinanceTransaction";
import { Student } from "./Student";

export const WalletTransaction = types
    .model("WalletTransaction", {
        id: types.identifier,
        type: "expense_account_transaction",
        attributes: types.maybe(types.model({
            credit: types.maybeNull(types.string),
            debit: types.maybeNull(types.string),
            created_at: "",
            updated_at: "",
        })),
        relationships: types.optional(types.model({
            finance_transaction: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(FinanceTransaction))
                })
            })),
            student: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(Student))
                })
            })),
        }), {}),
        isLoading: true,
    }) 
    .views(self => ({
        get financeTransaction() {
            try {
                return self
                    .relationships
                    .finance_transaction
                    .data
                    .id
            } catch (e) {
                return undefined
            }
        },
        get student() {
            try {
                return self
                    .relationships
                    .student
                    .data
                    .id
            } catch (e) {
                return undefined
            }
        }
    }))