import React, { Component } from 'react'
import Button from './button';

function handleReload() {
    window.location.reload()
}

export default class ErrorBoundary extends Component {

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        const { children } = this.props
        if (nextProps.children !== children) {
            this.setState({ hasError: false });
        }
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) return (
            <div>
                <div className="text--danger text-h3 text--bold text--spacedLetters text--uppercase">
                    Oops! An error occured while loading this page
                </div>
                Please contact our <a href="mailto:support@bluebic.com">support center</a> for help
                <br />
                <br />
                <br />
                <Button onClick={handleReload}>
                    <span className="text--spacedLetters">RELOAD THE PAGE</span>
                </Button>
            </div>
        )
        return children
    }
}
