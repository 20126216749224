import React from 'react'
import { observer, inject } from 'mobx-react'
import ContentLoader from '../content_loader';
import Card from '../card';

const Loading = inject("display")(
    observer(({display}) => {
        let itemCount = 3
        if (display.isTablet) {
          itemCount = 4
        }
        return Array(itemCount).fill().map((_, index) => (
            <Card className="StudentGuardianRelationsItem">
                <ContentLoader height="100" width="150%">
                    <circle cx="20" cy="20" r="20" />
                    <rect x="50" y="5" rx="2" ry="2" width="60" height="10" />
                    <rect x="120" y="5" rx="2" ry="2" width="30" height="10" />
                    <rect x="50" y="25" rx="2" ry="2" width="15" height="10" />
                    <rect x="0" y="60" rx="2" ry="2" width="35" height="10" />
                    <rect x="45" y="60" rx="2" ry="2" width="80" height="10" />
                    <rect x="0" y="85" rx="2" ry="2" width="35" height="10" />
                    <rect x="45" y="85" rx="2" ry="2" width="100" height="10" />
                </ContentLoader>
            </Card>
        ))
    })
)

export default Loading