import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MxPropTypes, observer } from 'mobx-react'
import { Checkbox, Popover } from 'antd'
import add from './assets/add.svg'
import select from './assets/select.svg'

@observer
class TableHead extends Component {
  static propTypes = {
    showToggleAllCheckbox: PropTypes.bool,
    onToggleAllChecked: PropTypes.func.isRequired,
    isAllChecked: PropTypes.bool.isRequired,
    visibleColumns: MxPropTypes.arrayOrObservableArray.isRequired,
    columns: MxPropTypes.arrayOrObservableArray.isRequired,
    isDefaultVisibleColumnsKeysExceeded: PropTypes.bool.isRequired,
    toggleColumnKeyVisibility: PropTypes.func
  }

  static defaultProps = {
    showToggleAllCheckbox: false,
    toggleColumnKeyVisibility: () => { }
  }

  render() {
    const {
      showToggleAllCheckbox, onToggleAllChecked, columns,
      isAllChecked, visibleColumns, isDefaultVisibleColumnsKeysExceeded,
      toggleColumnKeyVisibility
    } = this.props

    let popoverContent
    if (isDefaultVisibleColumnsKeysExceeded) {
      popoverContent = columns.map((col, index) => (
        <React.Fragment key={col.key}>
          {index > 0 && <div className="divider" />}
          <div className="Button Button--clear">
            <Checkbox
              checked={visibleColumns.find(({ key }) => key === col.key)}
              onChange={() => toggleColumnKeyVisibility(col.key)}
            >{col.render}
            </Checkbox>
          </div>
        </React.Fragment>
      ))
    }

    return (
      <thead>
        <tr>
          {showToggleAllCheckbox && (
            <th>
              <Checkbox checked={isAllChecked} onChange={onToggleAllChecked} />
            </th>
          )}
          {visibleColumns.map(({ key, render, style = {}, onSort = null }) => (
            <th
              key={key}
              onClick={() => {if (onSort) onSort(key)}}
              style={style}
              className={onSort ? 'touchable-opacity' : ''}
            >
              {render}
              {onSort && <img src={select} className="utils-append" alt="" />}
            </th>
          ))}
          {isDefaultVisibleColumnsKeysExceeded && (
            <th>
              <Popover
                content={popoverContent}
                placement="bottomRight"
                overlayClassName="override-popover"
              >
                <div className="Table-plusButton touchable-highlight">
                  <img src={add} alt="+" />
                </div>
              </Popover>
            </th>
          )}
        </tr>
      </thead>
    )
  }
}

export default TableHead