import { types } from "mobx-state-tree"
import { dateRangeType } from "../types/dateRangeType";
import { Search } from "./Search";

export const FinanceTransactionSearch = types
    .compose(
        Search,
        types
            .model("FinanceTransactionSearch", {
                transaction_date_range: types.maybe(dateRangeType),
                date_created_range: types.maybe(dateRangeType),
                payment_method: "",
                username: ""
            })
    )
