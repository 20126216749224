/* eslint-disable react/no-multi-comp */
import "./BatchCollectionSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import SelectInput from '../common/form/SelectInput';
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'
import { mapModelNameToOptions } from "../lib/mapModelNameToOptions";
import { BatchSearch } from "../store/models/BatchSearch";

import filter from './assets/filter-blue.svg'

const formModel = new MstForm(BatchSearch, {
    academic_session_id: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    course_id: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
})

const AcademicSessionField = inject("store")(observer(({ formState, store }) => {
    const field = formState.field("academic_session_id")
    const { academicSessions } = store
    return (
        <Label text="Year">
            <SelectInput
                {...field.inputProps}
                placeholder="Select year"
                defaultOptionText="Select year"
                options={mapModelNameToOptions(academicSessions)}
            />
        </Label>
    )
}))

const CourseField = inject("store")(observer(({ formState, store }) => {
    const field = formState.field("course_id")
    const { courseStore: {courses} } = store
    return (
        <Label text="Class">
            <SelectInput
                {...field.inputProps}
                placeholder="Select class"
                defaultOptionText="Select class"
                options={mapModelNameToOptions(courses)}
            />
        </Label>
    )
}))

export default function BatchCollectionSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" /> Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, searchFormInstance } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <AcademicSessionField formState={this.formState} />
                    <CourseField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        return (
            <form className="BatchCollectionSearch-TabletForm">
                <AcademicSessionField formState={this.formState} />
                <CourseField formState={this.formState} />
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    render() {
        const { settings: { search, isLoading } } = this.props

        return (
            <form className="BatchCollectionSearch-DesktopForm">
                <AcademicSessionField formState={this.formState} />
                <CourseField formState={this.formState} />
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

BatchCollectionSearch.MobileForm = MobileForm
BatchCollectionSearch.TabletForm = TabletForm
BatchCollectionSearch.DesktopForm = DesktopForm
