// Ported from: https://github.com/livebg/smstools/blob/master/lib/assets/javascripts/sms_tools/message.js.coffee

const gsmEncodingPattern = /^[0-9a-zA-Z@Δ¡¿£_!Φ"¥Γ#èΛ¤éΩ%ùΠ&ìΨòΣçΘΞ:Ø;ÄäøÆ,<Ööæ=ÑñÅß>ÜüåÉ§à€~ \$\.\-\+\(\)\*\\\/\?\|\^\}\{\[\]\'\r\n]*$/;
const asciiPattern = /^[\x00-\x7F]*$/;

const maxLengthForEncoding = {
    ascii: {
        normal: 160,
        concatenated: 153
    },
    gsm: {
        normal: 160,
        concatenated: 153
    },
    unicode: {
        normal: 70,
        concatenated: 67
    }
};
const doubleByteCharsInGsmEncoding = {
    '^': true,
    '{': true,
    '}': true,
    '[': true,
    '~': true,
    ']': true,
    '|': true,
    '€': true,
    '\\': true
};

export class SMSTools {
    constructor(_at_text, configuration = {use_gsm_encoding: true}) {
        this.text = _at_text;
        this.text = this.text.replace(/\r\n?/g, "\n");
        this.use_gsm_encoding = configuration.use_gsm_encoding
    }

    get encoding() {
        if (asciiPattern.test(this.text)) return 'ascii';
        if (this.use_gsm_encoding && gsmEncodingPattern.test(this.text)) return 'gsm';
        return 'unicode';
    };

    get length() {
        let totalLength = this.text.length;

        if (this.encoding === 'gsm') {
            const ref = this.text;
            let char; let i; let len;
            
            for (i = 0, len = ref.length; i < len; i += 1) {
                char = ref[i];
                if (doubleByteCharsInGsmEncoding[char]) {
                    totalLength += 1;
                }
            }
        }

        return totalLength;
    };

    get concatenatedPartsCount() {
        const {encoding, length} = this;
        if (length <= maxLengthForEncoding[encoding].normal) return 1;
        const maxLengthForConcatenated = maxLengthForEncoding[encoding].concatenated
        return Math.ceil(length / maxLengthForConcatenated )
        
    };

    maxLengthFor(concatenatedPartsCount) {
        const {encoding} = this;
        const messageType = concatenatedPartsCount > 1 ? 'concatenated' : 'normal';
        return concatenatedPartsCount * maxLengthForEncoding[encoding][messageType];
    };
    
}