import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import {Popover, Row, Col, Icon, Popconfirm, Tooltip} from 'antd';
import { titleCase } from '../lib/titleCase';
import Card from '../common/card';
import SwitchInput from '../common/form/SwitchInput';
import { ModalContext } from '../common/modal';
import Collection from '../common/Collection'

import ReportCardGroupCollectionSearch from './ReportCardGroupCollectionSearch';

import ReportCardGroupForm from './ReportCardGroupForm';  
import NewReportCardGroup from './NewReportCardGroup';

import more_vert from '../common/assets/more-vert.svg'
import newspaper from '../common/assets/newspaper.svg';


function MoreButtonPopover({reportCardGroup, deleteReportCardGroup, regenerateReportCardGroup }) {
    const {id, attributes: { name }} = reportCardGroup;
    
    return (
        <ModalContext.Consumer>
            {context => (
                <React.Fragment>
                    <div>
                        <button
                            type="button"
                            onClick={() => {if (!reportCardGroup.isLoading) regenerateReportCardGroup(id)}}
                            className="Button Button--clear touchable-opacity text-p"
                        >
                            {reportCardGroup.isLoading ? 'Please wait...' : 'Recalculate' } 
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            onClick={() => {
                                context.setContent(
                                    `Edit (${name})`,
                                    <ReportCardGroupForm closeModal={context.closeModal} reportCardGroup={reportCardGroup} editMode />
                                )
                            }}
                            className="Button Button--clear touchable-opacity text-p"
                        >
                            Edit
                        </button>
                        <div className="divider" />
                        <Popconfirm
                            title="Are you sure you want to delete this report?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={() => deleteReportCardGroup(reportCardGroup.id)}
                        >

                            <button
                                type="button"
                                className="Button Button--clear touchable-opacity text-p "
                            >
                                Delete
                            </button>
                        </Popconfirm>
                    </div>
                </React.Fragment>
            )}
        </ModalContext.Consumer>
    )
}



@inject('store', 'display')
@observer
class ReportCardGroupCollection extends Component{

    get popoverPlacement() {
        const { display } = this.props
        return display.isDesktop ? 'bottomRight' : 'topRight'
    }

    handlePublishToggle = async (group, value) => {
        const { store: {reportCardGroupStore: {updateReportCardGroupPublished}} } = this.props
        await updateReportCardGroupPublished(group.id, value)
    }

    render(){
        const { store } = this.props;
        const { view, reportCardGroupStore: settings } = store
        
        const { deleteReportCardGroup, regenerateReportCardGroup } = settings
        const { searchResults } = settings
        
        const columns = [
            { key: 'name', render: 'Name' },
            { key: 'class_set', render: 'Class Set' },
            { key: 'term', render: 'Term' },
            { key: 'type', render: 'Type' },
            { key: 'published', render: 'Publish' },
            { key: 'status', render: 'Status' },
            { key: 'more_vert', render: ''}
        ]
        
        
        const tableData = searchResults
            .map((curSearchResult) => {
                const { id, classSetName, attributes: { name, for: term, report_type, status, status_message, published } } = curSearchResult;
                return ({
                        id,
                        name: titleCase(status) === 'Failed' ? (
                            <>
                                <span title={status_message}>{name}</span>
                                <Tooltip title={status_message}>
                                    <i
                                        className="
                                        material-icons
                                        text-p
                                        utils-append
                                        text--primary
                                        touchable-opacity
                                    "
                                    >
                                        error
                                    </i>
                                </Tooltip>
                            </>
                        ) : (
                            <a
                                href={view.getReportCardGroupPage(id)}
                                onClick={(e) => {
                                    e.preventDefault()
                                    view.openReportCardGroupPageById(id)
                                }}
                            >
                                {name}
                            </a>
                        ),
                        class_set: classSetName,
                        term: titleCase(term),
                        type: titleCase(report_type),
                        status: !status_message ? status : (
                            <>
                                <span>{titleCase(status)}</span>
                                <Tooltip title={status_message}>
                                    <i
                                        className="
                                        material-icons
                                        text-p
                                        utils-append
                                        text--primary
                                        touchable-opacity
                                    "
                                    >
                                        info
                                    </i>
                                </Tooltip>
                            </>
                        ),
                        published: <SwitchInput size='small' value={!!published} onChange={e => this.handlePublishToggle(curSearchResult, e)} />,
                        more_vert: (
                            <Popover
                                content={<MoreButtonPopover reportCardGroup={curSearchResult} regenerateReportCardGroup={regenerateReportCardGroup} deleteReportCardGroup={deleteReportCardGroup} />}
                                placement={this.popoverPlacement}
                            >
                                <div className="touchable-opacity text--alignCenter ">
                                    <img src={more_vert} alt="..." />
                                </div>
                            </Popover>

                        )}
                )
            })
        const tableProps = { onRowSelectionChange: null }
        const tableSettings = {
            tableData,
            columns,
            tableProps
        }
        const buildMobileItem = (reportCardGroup) => (
            <ReportCardGroupCollectionItem id={reportCardGroup.id} key={reportCardGroup.id} reportCardGroup={reportCardGroup} handlePublishToggle={this.handlePublishToggle} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div>&nbsp;</div>
                    {collectionSummaryText}
                    <NewReportCardGroup />
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={newspaper} alt="" />
                        <span className="text-small utils-append text--uppercase">Report Card Groups</span>
                    </Col>
                    <Col span={12}>
                        <NewReportCardGroup />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={18} offset={3}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={ReportCardGroupCollectionSearch}
            />
        )
    }
}


export default ReportCardGroupCollection


const ReportCardGroupCollectionItem = inject("store")(
    observer(({ store, reportCardGroup, handlePublishToggle }) => {
        

        const { id, classSetName, attributes: { name, for: term, report_type, status, published } } = reportCardGroup
        const { reportCardGroupStore:{deleteReportCardGroup, regenerateReportCardGroup}, view} = store
        
        
        return (
            <Card className="mb-1">
                <a
                    className="text--spacedLetters text-h3"
                    href={view.getReportCardGroupPage(id)}
                    onClick={(e) => {
                        e.preventDefault()
                        view.openReportCardGroupPageById(id)
                    }}
                >
                    {name}
                </a>
                <div className="flex-col flex-alignStart mt-1 mb-1">
                    <div className="">
                        {classSetName} &middot; {titleCase(term)} &middot; {titleCase(report_type)} Report
                    </div>
                </div>
                
                <div className="flex-row  flex-spaceBetween">

                    <div className="">
                        <SwitchInput size='small' value={!!published} onChange={e => handlePublishToggle(reportCardGroup, e)} />
                        <span className="text-small text--bold text--uppercase text--spacedLetters utils-prepend"> Published</span>
                    </div>
                    <Popover
                        content={<MoreButtonPopover reportCardGroup={reportCardGroup} regenerateReportCardGroup={regenerateReportCardGroup} deleteReportCardGroup={deleteReportCardGroup} />}
                        placement='bottomRight'
                        overlayClassName="override-popover"
                    >
                        <div className="touchable-opacity text--right ">
                            <img src={more_vert} alt="..." />
                        </div>
                    </Popover>
                </div>
            </Card>
        )
    })
)