import {applySnapshot, types} from 'mobx-state-tree';

export const BatchSetupTransfer = types.model({
    from_batch_id: types.maybeNull(types.string),
    transfer_batch_employee_assignments: types.optional(types.boolean, false),
    transfer_subjects: types.optional(types.boolean, false),
    transfer_subject_teachers: types.optional(types.boolean, false),
    transfer_assessments: types.optional(types.boolean, false),
    transfer_learning_domains: types.optional(types.boolean, false),
    
    client_state: types.optional(types.model({
        isSaving: types.optional(types.boolean, false)
    }), {})
})
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        }
    }))