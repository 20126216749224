import { types, flow, destroy, applySnapshot } from "mobx-state-tree"
import FileSaver from "file-saver";
import * as ReactGA from "react-ga";
import isEmpty from 'lodash.isempty';

import { Student } from "./models/Student"
import { StudentBatchTransfer } from "./models/StudentBatchTransfer";
import { ReportCardGroup } from "./models/ReportCardGroup";
import { StudentReportCardDatum } from "./models/StudentReportCardDatum";
import { SearchMeta } from "./models/SearchMeta";
import { StudentSearch } from "./models/StudentSearch";
import { CreateStudent } from "./actions/Student";
import { LoadingPattern } from "./patterns/LoadingPattern";
import { UsesBluebicStorePattern } from "./patterns/UsesBluebicStorePattern";
import { validationErrorsHandler } from "./helpers/errors";
import { filterType } from "./helpers/filterType";

export const StudentStore = types
    .compose(
        LoadingPattern({
            payFeeLoading: false
        }),
        UsesBluebicStorePattern,
        types
            .model("StudentStore", {
                students: types.map(Student),
                searchResults: types.array(types.reference(Student)),
                studentBatchTransfers: types.map(StudentBatchTransfer),
                reportCardGroups: types.map(ReportCardGroup),
                studentReportCardData: types.map(StudentReportCardDatum),
                meta: types.optional(SearchMeta, {}),
                selectedStudents: types.array(types.string),
                searchFormInstance: types.optional(StudentSearch, {}),
                addStudentFormInstance: types.optional(CreateStudent, {}),
            })
            .views(self => ({
                get isAllSelected() {
                    return !self.meta.total_ids.some(val => self.selectedStudents.indexOf(val) === -1)
				},
				get selectedStudentsDetails() { 
					return self.selectedStudents.map((id) => self.students.get(id))
				},
                get service() {
                    return self.bluebic.studentService
                }
            }))
            .actions(self => {

                function updateStudents(students) {
                    students.forEach(student => self.students.put(student))
                }

                function updateFilteredStudents(data) {
                    updateStudents(data)
                    self.searchResults = []
                    data.forEach(item => self.searchResults.push(item.id))
                }

                function updateStudentBatchTransfers(batchTransfers) {
                    batchTransfers.forEach(json => self.studentBatchTransfers.put(json))
                }

                function updateSelectedStudents(data) {
                    updateStudents(data)
                    self.selectedStudents = []
                    data.forEach(item => self.selectedStudents.push(item.id))
                }
                function markStudentLoadingById(id, flag) {
                    if (!self.students.has(id)) {
                        self.students.put({ id })
                    }
                    self.students.get(id).isLoading = flag
                }

                const loadStudentById = flow(function* loadStudentById(id) {
                    try {
                        markStudentLoadingById(id, true)
                        const { data, included } = yield self.bluebic.studentService.showStudent(id)
                        self.bluebic.handleUpdateStores(included)
                        self.students.put(data)
                        markStudentLoadingById(id, false)
                    } catch (err) {
                        markStudentLoadingById(id, false)
                        console.error(`Failed to load student with id ${id}`, err)
                    }
                })

                const loadAttendanceReportById = flow(function* loadAttendanceReportById(id) {
                    try {
                        const student = self.students.get(id)
                        student.attendanceReport.markLoading(true)
                        const action = student.attendanceReport.sort.toJSON()
                        const { data } = yield self.bluebic.studentService.fetchAttendanceReport(id, action)
                        student.attendanceReport.update(data)
                        student.attendanceReport.markLoading(false)
                    } catch (err) {
                        const student = self.students.get(id)
                        student.attendanceReport.markLoading(false)
                        console.error(`Failed to load student with id ${id}`, err)
                    }
                })

                function updateSearch(model) {
                    self.searchFormInstance = model
                }

                function removeStudentFromSearchResults(id) {
                    const index = self.searchResults.findIndex(item => item.id === id)
                    if (index >= 0) self.searchResults.splice(index, 1)
                }

                const search = flow(function* search() {
                    try {
                        self.markLoading(true)
                        const { data, meta } = yield self.bluebic.studentService.search(self.searchFormInstance.toJSON())
                        updateFilteredStudents(data)
                        self.meta = meta
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to load students", err)
                    }
                })

                function onUpdate(included) {
                    filterType("student", included, updateStudents)
                    filterType("student_batch_transfer", included, updateStudentBatchTransfers)
                }

                const addNote = flow(function* addNote(student_id, action) {
                    try {
                        const { data } = yield self.bluebic.noteService.addStudentNote(student_id, action)
                        self.students.get(student_id).attributes.notes.push(data)
                    } catch (err) {
                        console.error("Failed to add note", err)
                    }
                })

                const editNote = flow(function* editNote(student_id, note_id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.noteService.updateNote(note_id, action)
                        const { notes } = self.students.get(student_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        applySnapshot(notes[index], data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to edit note", err)
                    }
                })

                const removeNote = flow(function* removeNote(student_id, note_id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.noteService.removeNote(note_id)
                        const { notes } = self.students.get(student_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        destroy(notes[index])
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to remove note", err)
                    }
                })

                const updateBatchTransfer = flow(function* updateBatchTransfer(id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.studentService.updateBatchTransfer(id, action)
                        self.studentBatchTransfers.put(data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to update batch transfer", err)
                    }
                })

                const removeBatchTransfer = flow(function* removeBatchTransfer(batchTransfer) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.studentService.destroyBatchTransfer(batchTransfer.id)
                        const { student_id } = batchTransfer.attributes
                        self.students.get(student_id).removeBatchTransfer(batchTransfer)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to remove batch transfer", err)
                    }
                })

                const archiveStudentById = flow(function* archiveStudentById(id, dropStudent) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.studentService.archiveStudent(id, dropStudent)
                        self.students.get(id).attributes = data.attributes
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to archive student", err)
                    }
                })

                const deleteStudentById = flow(function* deleteStudentById(id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.studentService.deleteStudent(id)
                        removeStudentFromSearchResults(id)
                        self.bluebic.view.openStudentsPage()
                        destroy(self.students.get(id))
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to delete student", err)
                    }
                })

                const reEnrollStudentById = flow(function* reEnrollStudentById(id) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.studentService.reEnrollStudentById(id)
                        self.bluebic.handleUpdateStores(included)
                        self.students.put(data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to Re-Enroll student", err)
                    }
                })

                const createStudent = flow(function* createStudent(student) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.studentService.createStudent(student)
                        self.students.put(data)
                        self.markLoading(false)
                        return { data }
                    } catch (e) {
                        self.markLoading(false)
                        console.error("Failed to create student", e)
                        return validationErrorsHandler(e)
                    }
                })

                const editStudent = flow(function* editStudent(student) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.studentService.editStudent(student)
                        self.students.put(data)
                        self.markLoading(false)
                        return { data }
                    } catch (e) {
                        self.markLoading(false)
                        console.error("Failed to edit student", e)
                        return validationErrorsHandler(e)
                    }
                })

                const deleteFeeById = flow(function* deleteFeeById(id) {
                    try {
                        self.markLoading(true)
                        const { meta } = yield self.bluebic.financeService.deleteStudentFee(id)
                        const { fee, student } = meta
                        self.students.put(student.data)
                        self.students.get(student.data.id).isLoading = false
                        if (self.bluebic.feeStore.studentFees.has(fee.data.id)) {
                            destroy(self.bluebic.feeStore.studentFees.get(fee.data.id))
                        }
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to delete fee", err)
                        self.markLoading(false)
                    }
                })

                const fetchFinanceSummary = flow(function* fetchFinanceSummary(studentIDs) {
                    try {
                        const { data } = yield self.bluebic.studentService.financeSummary(studentIDs)
                        return {data}
                    } catch (err) {
                        console.error("Failed to fetch finance summary", err)
                        return {error: "Failed to fetch finance summary"}
                    }
                })

                function blockStudentsById({ student_ids, reason }) {
                    student_ids.forEach(id => {
                        self.students.get(id).block(reason)
                    })
                }

                const blockStudents = flow(function* blockStudents(block) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.studentService.blockStudents(block)
                        blockStudentsById(block)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to block student(s)", err)
                    }
                })


                function unblockStudentsById(student_ids) {
                    student_ids.forEach(id => {
                        self.students.get(id).unblock()
                    })
                }
                
                const unblockStudents = flow(function* unblockStudents(action) {
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        yield self.bluebic.studentService.unblockStudents(action_without_client)
                        unblockStudentsById(action_without_client.student_ids)
                        action.markSaving(false)

                        ReactGA.event({
                            category: 'Students unblocked',
                            action: 'Unblocked students',
                            nonInteraction: false,
                            value: action.student_ids.length,
                            // 'label: 'Game Widget'',
                        });
                        
                    } catch (err) {
                        console.error(`Failed to unblock student(s)`, err)
                        action.markSaving(false)
                    }
                })

                function selectStudentById(id) {
                    if (self.selectedStudents.includes(id)) {
                        const index = self.selectedStudents.findIndex(id_ => id_ === id)
                        self.selectedStudents.splice(index, 1)
                    } else {
                        self.selectedStudents.push(id)
                    }
                }

                function selectAllStudents() {
                    if (self.isAllSelected) {
                        self.selectedStudents.length = 0
                    } else {
                        self.meta.total_ids.forEach(id => {
                            if (!self.selectedStudents.includes(id)) {
                                self.selectedStudents.push(id)
                            }
                        })
                    }
                }

                const assignGuardian = flow(function* assignGuardian(student_guardian_relation) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.studentService.assignGuardian(student_guardian_relation)
                        self.bluebic.handleUpdateStores([data, ...included])
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to assign guardian", err)
                    }
                })

                const unassignGuardian = flow(function* unassignGuardian(student_guardian_relation_id, student_id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.studentService.unassignGuardian(student_guardian_relation_id)
                        self.markLoading(false)
                        self.students
                            .get(student_id)
                            .removeGuardianRelation(student_guardian_relation_id)
                    } catch (err) {
                        console.error("Failed to unassign guardian", err)
                    }
                })

                const getTransactionPreflight = flow(function* getTransactionPreflight(student_id, student_transaction) {
                    try {
                        self.markLoading('payFeeLoading', true)
                        const { data } = yield self.bluebic.studentService.getTransactionPreflight(student_id, student_transaction)
                        self.students.get(student_id).transactionPreflight = data
                        self.markLoading('payFeeLoading', false)
                        return { data }
                    } catch (err) {
                        self.markLoading('payFeeLoading', false)
                        console.error("Failed to get transaction preflight", err)
                        return validationErrorsHandler(err)
                    }
                })

                const postTransaction = flow(function* postTransaction(student_id, student_transaction) {

                    if (typeof window.FS === 'object') {
                        window.FS.event('Posted Student Transaction', {
                            transaction_date_str: student_transaction.transaction_date,
                            payment_method_str: student_transaction.payment_method,
                            wallet_credit_str: student_transaction.wallet_credit,
                            wallet_debit_str: student_transaction.wallet_debit,
                            fee_amount_str: student_transaction.fee_amount,
                        });
                    }
                    
                    try {
                        self.markLoading('payFeeLoading', true)
                        const { data } = yield self.bluebic.studentService.postTransaction(student_id, student_transaction)
                        self.markLoading('payFeeLoading', false)
                        
                        if (typeof window.mixpanel === 'object'){
                            window.mixpanel.track('Added student transaction', {
                                'transaction date': student_transaction.transaction_date,
                                'payment method': student_transaction.payment_method,
                                'wallet credit': student_transaction.wallet_credit,
                                'wallet debit': student_transaction.wallet_debit,
                                'fee amount': student_transaction.fee_amount,
                            });
                        }
                        ReactGA.event({
                            category: 'Finance',
                            action: 'Added Student transaction',
                            nonInteraction: false,
                            // 'label: 'Game Widget'',
                            // value: undefined,
                        });
                        
                        return { data }
                    } catch (err) {
                        self.markLoading('payFeeLoading', false)
                        console.error("Failed to save student transaction", err)
                        return validationErrorsHandler(err)
                    }
                })

                const getVerifyWebPayTransaction = flow(function* getVerifyWebPayTransaction(student_id, trans_ref) {
                    let interaction
                    let loadTime
                    let returnedData
                    const start = Date.now()
                    
                    if (typeof window.newrelic === 'object') {
                        interaction = window.newrelic.interaction()
                        interaction.setName('verifyWepPay')
                        interaction.onEnd(ctx => {
                            interaction.setAttribute('totalLoadTime', ctx.loadTime)
                        })
                    }
                    
                    if (typeof window.mixpanel === 'object') window.mixpanel.time_event('Verify Webpay transaction')
                    
                    try {
                        self.markLoading('payFeeLoading', true)
                        const { data } = yield self.bluebic.studentService.getVerifyWebPayTransaction(student_id, trans_ref)
                        returnedData = data
                        return { data }
                    } catch (err) {
                        console.error("Failed to verify web pay transaction", err)
                        loadTime = Date.now() - start
                        return validationErrorsHandler(err)
                    } finally {
                        self.markLoading('payFeeLoading', false)
                        
                        loadTime = Date.now() - start
                        
                        if (typeof window.newrelic === 'object') {
                            interaction.getContext(ctx => {
                                ctx.loadTime = loadTime
                            })
                            // interaction.end()
                        }
                        
                        ReactGA.event({
                            category: 'Finance',
                            action: 'Verify Webpay transaction',
                            nonInteraction: true,
                            // 'label: 'Game Widget'',
                            // value: undefined,
                        });
                        
                        if (typeof window.mixpanel === 'object') window.mixpanel.track('Verify Webpay transaction', returnedData);
                        
                    }
                })

                function resetAddStudentForm() {
                    self.addStudentFormInstance = {}
                }

                const selectStudentForEdit = flow(function* selectStudentForEdit(student_id) {
                    let selectedStudent = self.students.get(student_id)
                    if (isEmpty(selectedStudent) || !selectedStudent.isAssociationsLoaded) {
                        yield self.loadStudentById(student_id)
                        selectedStudent = self.students.get(student_id)
                    }
                    self.addStudentFormInstance.setFormInstance(selectedStudent)
                    return Promise.resolve(selectedStudent)
                })
                
                const getSelectedStudentRecords = flow(function* getSelectedStudentRecords(students_ids) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.studentService.getSelectedStudentRecords(students_ids)
                        updateSelectedStudents(data)
                        self.markLoading(false)
                        return { data }
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to get selected student Records", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const downloadStudentListSpreadsheet = flow(function* downloadStudentListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadStudentListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Student Data',
                            nonInteraction: false,
                            value: action.student_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of student list`, err)
                        action.markSaving(false)
                    }
                })
                
                return {
                    addNote,
                    archiveStudentById,
                    reEnrollStudentById,
                    assignGuardian,
                    blockStudents,
                    createStudent,
                    deleteFeeById,
                    deleteStudentById,
                    editNote,
                    editStudent,
                    fetchFinanceSummary,
                    getTransactionPreflight,
                    postTransaction,
                    getVerifyWebPayTransaction,
                    loadAttendanceReportById,
                    loadStudentById,
                    onUpdate,
                    removeBatchTransfer,
                    removeNote,
                    resetAddStudentForm,
                    // saveTransaction,
                    search,
                    selectAllStudents,
                    selectStudentById,
                    selectStudentForEdit,
                    unassignGuardian,
                    unblockStudents,
                    updateBatchTransfer,
                    updateSearch,
                    getSelectedStudentRecords,
                    downloadStudentListSpreadsheet
                }
            })
    )
