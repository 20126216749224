import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd';
import { titleCase } from '../lib/titleCase';
import Card from '../common/card';
import Collection from '../common/Collection'

import MessageCollectionSearch from './MessageCollectionSearch';

import newspaper from '../common/assets/newspaper.svg';
import {formatDate, fullDateTimeFormat} from "../lib/formatDate";
import TopUpHistoryButton from "./TopUpHistoryButton";

const columns = [
    { key: 'title', render: 'Message' },
    { key: 'send_as_email', render: 'Email' },
    { key: 'send_as_sms', render: 'SMS' },
    { key: 'sms_units_used', render: 'SMS Units' },
    { key: 'status', render: 'Status' },
    { key: 'created_by', render: 'Created By' },
    { key: 'created_at', render: 'Created At' }
]

@inject('store', 'display')
@observer
class MessageCollection extends Component{

    render(){
        const { store } = this.props;
        const { view, messageStore, meta: {currentInstitution: {smsMaster}} } = store
        const { searchResults } = messageStore
        
        
        const tableData = searchResults
            .map((curSearchResult) => {
                const { id, title, createdByName, attributes: { send_as_email, send_as_sms, status, sms_units_used, created_at } } = curSearchResult;
                return ({
                    id,
                    title: (
                        <a
                            href={view.getMessagePage(id)}
                            onClick={(e) => {
                                e.preventDefault()
                                view.openMessagePageById(id)
                            }}
                        >
                            {title}
                        </a>
                    ),
                    send_as_email: send_as_email ? 'Yes' : 'No',
                    send_as_sms: send_as_sms ? 'Yes' : 'No',
                    sms_units_used,
                    status: titleCase(status),
                    created_by: createdByName,
                    created_at: formatDate(created_at, fullDateTimeFormat)
                })
            })
        const tableProps = { onRowSelectionChange: null }
        const tableSettings = {
            tableData,
            columns,
            tableProps
        }
        const buildMobileItem = (message) => (
            <MessageCollectionItem id={message.id} key={message.id} message={message} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="text-h2 text--primary">SMS Unit Balance: {smsMaster.unitBalance}</div>
                    {collectionSummaryText}
                    <TopUpHistoryButton />
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={newspaper} alt="" />
                        <span className="text-small utils-append text--uppercase">Messages</span>
                    </Col>
                    <Col span={12}>
                        <TopUpHistoryButton />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={18} offset={3}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={messageStore}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={MessageCollectionSearch}
            />
        )
    }
}


export default MessageCollection


const MessageCollectionItem = inject("store")(
    observer(({ store: {view}, message}) => {
        
        const { id, title, createdByName, attributes: { send_as_email, send_as_sms, status, sms_units_used, created_at } } = message
        
        return (
            <Card className="mb-1">
                <a
                    className="text--spacedLetters text-h3"
                    href={view.getMessagePage(id)}
                    onClick={(e) => {
                        e.preventDefault()
                        view.openMessagePageById(id)
                    }}
                >
                    {title}
                </a>
                <div className="flex-col flex-alignStart mt-1 mb-1">
                    <div className="">
                        <div>Email: {send_as_email ? "Yes" : "No"}</div>
                        <div>SMS: {send_as_sms ? `Yes (${sms_units_used ? sms_units_used : 0 } units)` : "No"}</div>
                        <div>Status: {titleCase(status)}</div>
                    </div>
                </div>
                
                <div className="flex-row  flex-spaceBetween">
                    <div className="">
                        <span className="text-small text--bold text--uppercase text--spacedLetters utils-prepend">
                            {createdByName} &middot; {formatDate(created_at, fullDateTimeFormat)}
                        </span>
                    </div>
                </div>
            </Card>
        )
    })
)