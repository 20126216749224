import { types, flow, destroy } from 'mobx-state-tree'
import { LoadingPattern } from './patterns/LoadingPattern'
import { UsesBluebicStorePattern } from './patterns/UsesBluebicStorePattern'
// import { filterType } from './helpers/filterType'
import { Event } from './models/Event'
import { validationErrorsHandler } from './helpers/errors'
import { CreateEvent, SearchEvent } from './actions/Event'


export const EventStore = types
    .compose(
        'EventStore',
        LoadingPattern(),
        UsesBluebicStorePattern,
        types
            .model({
                events: types.map(Event),
                searchFormInstance: types.optional(SearchEvent, {}),
                searchResults: types.array(types.reference(Event)),
                addEventFormInstance: types.optional(CreateEvent, {})
            })
            .actions(self => {

                function updateEvents(events) {
                    events.forEach(event => self.events.put(event))
                }

                function updateSearchResults(data) {
                    updateEvents(data)
                    self.searchResults = []
                    data.forEach(item => self.searchResults.push(item.id))
                }

                function markEventLoadingById(id, flag) {
                    if (!self.events.has(id)) {
                        self.events.put({ id })
                    }
                    self.events.get(id).isLoading = flag
                }

                const loadEventById = flow(function* loadEventById(id) {
                    try {
                        markEventLoadingById(id, true)
                        const { data, included } = yield self.bluebic.eventService.getEvent(id)
                        self.bluebic.handleUpdateStores(included)
                        self.events.put(data)
                        markEventLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load event with id ${id}`, err)
                    }
                })

                const getEvents = flow(function* getEvents() {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.eventService.getEvents(self.searchFormInstance.toJSON())
                        updateSearchResults(data)
                        self.bluebic.handleUpdateStores(included)
                        self.markLoading(false)
                        return self.searchResults
                    } catch (err) {
                        self.markLoading(false)
                        console.error('Failed to load events', err)
                    }
                })

                const createEvent = flow(function* createEvent(event) {
                    try {
                        self.addEventFormInstance.isSaving = true
                        const { data, included } = yield self.bluebic.eventService.createEvent(event)
                        self.bluebic.handleUpdateStores(included)
                        self.events.put(data)
                        self.addEventFormInstance.isSaving = false
                        return { data }
                    } catch (err) {
                        self.addEventFormInstance.isSaving = false
                        console.error('Failed to create event', err)
                        return validationErrorsHandler(err)
                    }
                })

                const updateEvent = flow(function* updateEvent(event) {
                    try {
                        self.addEventFormInstance.isSaving = true
                        const { data } = yield self.bluebic.eventService.updateEvent(event)
                        self.events.put(data)
                        self.addEventFormInstance.isSaving = false
                        return { data }
                    } catch (err) {
                        self.addEventFormInstance.isSaving = false
                        console.error("Failed to update event", err)
                        return validationErrorsHandler(err)
                    }
                })

                const selectEventForEdit = flow(function* selectEventForEdit(event_id) {
                    let selectedEvent = self.events.get(event_id)
                    if (!selectedEvent || !selectedEvent.isInitialized) {
                        yield self.loadEventById(event_id)
                    }
                    selectedEvent = self.events.get(event_id)
                    self.addEventFormInstance.setFormInstance(selectedEvent)
                    return Promise.resolve(selectedEvent)
                })

                function resetAddEventForm() {
                    self.addEventFormInstance = {}
                }
                
                

                // eslint-disable-next-line no-unused-vars
                function onUpdate(included) {
                    // filterType('course', included, updateStudents)
                    // filterType('student_category', included, updateStudentFees)
                    // filterType('employee_category', included, updateStudentBatchTransfers)
                    // filterType('employee_position', included, updateReportCardData)
                    // filterType('employee_department', included, updateReportCardGroups)
                }
                
                const deleteEventById = flow(function* deleteEventById(id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.eventService.deleteEvent(id)
                        destroy(self.events.get(id))
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to delete event", err)
                    }
                })
                return {
                    loadEventById,
                    selectEventForEdit,
                    resetAddEventForm,
                    createEvent,
                    updateEvent,
                    deleteEventById,
                    onUpdate,
                    getEvents,
                }
            }),
    )