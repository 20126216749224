import {types, getParent, destroy, flow} from "mobx-state-tree"
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import { AsYouType } from 'libphonenumber-js'
import { titleCase } from "../../lib/titleCase"
import { formatDate } from "../../lib/formatDate"
import { formatNationality } from "../../lib/formatNationality"
import { Note } from "./Note"
import { StudentAttendanceReport } from "./StudentAttendanceReport";
import { Gender } from "./Gender"
import { AcademicSession } from "./AcademicSession";
import {StudentAssessmentSearchMeta} from "./SearchMeta";
import { StudentAssessmentSearch} from "../actions/AssessmentSearch";
import {validationErrorsHandler} from "../helpers/errors";



/* eslint-disable import/no-cycle */
import { StudentBatchTransfer } from "./StudentBatchTransfer"
import { StudentGuardianRelation } from "./StudentGuardianRelation";
import { StudentFee } from "./StudentFee";
import { FinanceTransaction } from "./FinanceTransaction";
import { StudentReportCardDatum } from "./StudentReportCardDatum"
import {Batch} from "./Batch";
import {User} from "./User";
import {AssessmentScore} from "./AssessmentScore";
/* eslint-enable import/no-cycle */




export const Student = types
    .model("Student", {
        id: types.identifier,
        type: "student",
        attributes: types.maybe(types.model({
            username: "",
            first_name: "",
            middle_name: types.maybeNull(types.string),
            last_name: "",
            gender: types.maybeNull(Gender),
            email: types.maybeNull(types.string),
            mobile_phone: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            status: "",
            admission_date: "",
            date_of_birth: types.maybeNull(types.string),
            due_fees_balance: types.maybeNull(types.string),
            unpaid_fees_balance: types.maybeNull(types.string),
            wallet_balance: types.maybeNull(types.string),
            blood_group: types.maybeNull(types.string),
            batch_name: types.maybeNull(types.string),
            batch_id_cache: types.maybeNull(types.number),
            state_of_origin: types.maybeNull(types.string),
            lga_of_origin_id: types.maybeNull(types.number),
            place_of_origin: types.maybeNull(types.string),
            health_info: types.maybeNull(types.string),
            religion: types.maybeNull(types.string),
            blocked_at: types.maybeNull(types.union(types.Date, types.string)),
            block_reason: types.maybeNull(types.string),
            nationality: types.maybeNull(types.string),
            photo_url: types.maybeNull(types.string),
            notes: types.array(Note),
            academic_session_history: types.array(types.reference(AcademicSession)),
            created_at: types.maybeNull(types.string),
            updated_at: types.maybeNull(types.string),
        })),
        relationships: types.maybe(types.model({
            unpaid_student_fees: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(StudentFee))
                }))
            })),
            student_guardian_relations: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(StudentGuardianRelation))
                }))
            })),
            batch_transfers: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(StudentBatchTransfer))
                }))
            })),
            finance_transactions: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(FinanceTransaction))
                }))
            })),
            student_report_card_data: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(StudentReportCardDatum))
                }))
            })),
            batch: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.safeReference(Batch))
                }))
            })),
            user: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(User))
                })
            })),
        })),
        attendanceReport: types.optional(StudentAttendanceReport, {}),
        isLoading: false,
        selectedUnpaidFees: types.array(types.string),
        transactionPreflight: types.maybe(types.model({
            total_fee_to_pay: types.maybe(types.union(types.number, types.string)),
            payment_method: types.string,
            payment_method_name: types.maybe(types.string),
            wallet_credit: types.maybe(types.string),
            wallet_debit: types.maybe(types.string),
            service_charge: types.maybe(types.number),
            total_amount_to_pay: types.maybe(types.string),
            total_amount_to_receive: types.maybe(types.string),
            student_fees_to_be_paid: types.array(
                types.model({
                    amount: types.string,
                    student_fee: types.model({
                        data: types.model({
                            id: types.late(() => types.safeReference(StudentFee))
                        })
                    })
                })
            )
        })),
        assessmentSearchInstance: types.optional(StudentAssessmentSearch, {}),
        assessmentsMeta: types.optional(StudentAssessmentSearchMeta, {}),
        assessmentSearchResult: types.array(types.reference(AssessmentScore)),
    })
    .views(self => ({
        get isInitialized(){
            return self.attributes
        },

        get isAssociationsLoaded(){
            return !!self.relationships.batch_transfers
        },
        
        get studentStore() {
            return getParent(self, 2)
        },
        
        get bluebic() {
            return self.studentStore.bluebic
        },

        get studentName() {
            const { last_name, first_name, middle_name } = self.attributes
            return titleCase(
                `${last_name}, ${first_name}${ middle_name ? ` ${middle_name}` : "" }`
            )
        },

        get nameAbbreviation() {
            const { last_name, first_name, middle_name } = self.attributes
            return titleCase(
                `${last_name}, ${first_name[0]}.${middle_name ? ` ${middle_name[0]}.` : ''}`
            )
        },

        get admissionNumber() {
            return self.attributes.username
        },

        get age() {
            if (self.attributes.date_of_birth){
                return formatDistanceStrict(
                    new Date(),
                    new Date(self.attributes.date_of_birth),
                    { unit: 'year' }
                )
            }
            return undefined
        },

        get batchNameNoSession() {
            if (!self.attributes.batch_name) return null
            return self.attributes
                .batch_name
                .split(' (')
                .shift()
        },

        get batchModel() {
            try {
                return self
                    .relationships
                    .batch
                    .data
                    .id
            } catch (e) {
                return null
            }
        },

        get user() {
            try {
                return self
                    .relationships
                    .user
                    .data
                    .id
            } catch (e) {
                return null
            }
        },

        get session() {
            if (self.attributes.batch_name == null) {
                return null
            }
            return self.attributes
                .batch_name
                .split('(')
                .pop()
                .split(')')
                .shift()
        },

        get enrollStatus() {
            if (self.attributes.status === 'active') {
                return 'enrolled'
            }
            return self.attributes.status
        },

        get isEnrolled() {
            return self.attributes.status === 'active'
        },

        batchWithId(id) {
            const { batches } = getParent(self, 3)
            if (batches.has(id)) {
                return batches.get(id)
            }
            return null
        },

        get batchTransfersFirstAndLastItems() {
            try {
                const { batchTransfers } = self
                const { 0: last_batch, [batchTransfers.length - 1]: first_batch } = batchTransfers
                const batchesToConsider = self.attributes.status === 'active' ?
                    [first_batch] : [last_batch, first_batch]

                return batchesToConsider
                    .map(({ attributes }, index) => {
                        const { first_day_in_batch, last_day_in_batch } = attributes
                        const { batch_id } = attributes
                        const reason = (batchesToConsider.length > 1 && index === 0) ? titleCase(self.attributes.status) : 'Enrollment'
                        let date = (reason !== 'Enrollment') ? last_day_in_batch : first_day_in_batch
                        date = formatDate(date)
                        const batch = self.batchWithId(batch_id)
                        let batchName = ""
                        if (batch) {
                            batchName = batch.attributes.name
                            batchName = batchName.split(' (').shift()
                        }

                        return [date, ' | ', batchName, '--', reason].join(' ')
                    })
            } catch (e) {
                console.warn(e)
                return []
            }
        },

        get accountStatus() {
            if (!self.attributes.blocked_at) {
                return 'active'
            }
            return 'blocked'
        },

        get notes() {
            try {
                return self.attributes.notes
            } catch (e) {
                console.warn(e)
                return []
            }
        },

        get bioData() {
            const { attributes } = self

            try {
                return {
                    studentProfile: {
                        "first name": attributes.first_name,
                        "middle name": attributes.middle_name,
                        "last name": attributes.last_name,
                        "date of birth": formatDate(attributes.date_of_birth),
                        "gender": attributes.gender,
                        "blood group": attributes.blood_group,
                        "admission date": formatDate(attributes.admission_date),
                        "student categories"    : "",
                        "nationality": formatNationality(attributes.nationality),
                        "place of origin": attributes.place_of_origin,
                        "religion": attributes.religion,
                        "health info": attributes.health_info,
                    },
                    contacts: {
                        "phone": new AsYouType().input(attributes.phone),
                        "mobile phone": new AsYouType().input(attributes.mobile_phone),
                        "email": attributes.email
                    },
                    address: attributes.address
                }
            } catch (e) {
                return {
                    studentProfile: {
                    },
                    contacts: {
                    },
                    address: ""
                }
            }
        },

        get guardianRelations() {
            try {
                if (!self.isAssociationsLoaded) return []
                return self
                    .relationships
                    .student_guardian_relations
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get batchTransfers() {
            try {
                if (!self.isAssociationsLoaded) return []
                return self
                    .relationships
                    .batch_transfers
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },

        get reportCardData() {
            try {
                return self
                    .relationships
                    .student_report_card_data
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get isSelected() {
            return getParent(self, 2).selectedStudents.includes(self.id)
        },
        get isBlocked() {
            return !!self.attributes.block_reason || !!self.attributes.blocked_at
        },
        get walletBalance() {
            return self.attributes.wallet_balance
        },
        get unpaidStudentFees() {
            try {
                return self
                    .relationships
                    .unpaid_student_fees
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get financeTransactions() {
            try {
                return self
                    .relationships
                    .finance_transactions
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return null
            }
        },
        get areAllUnpaidFeesSelected() {
            const unpaidFeeIDs = self.unpaidStudentFees.map(({id}) => id)
            const selectedFeeIDs = self.selectedUnpaidFees.toJSON()
            return !unpaidFeeIDs.some(val => selectedFeeIDs.indexOf(val) === -1)
        },
        get totalUnpaidStudentFees() {
            return self.unpaidStudentFees
                .map(({ balance }) => balance)
                .reduce((prevVal, currVal) => (prevVal + currVal), 0)
        },
        get totalSelectedUnpaidFees() {
            return self.unpaidStudentFees
                .filter(({ id }) => self.selectedUnpaidFees.includes(id))
                .map(({ balance }) => balance)
                .reduce((prevVal, currVal) => (prevVal + currVal), 0)
        }
    }))
    .actions(self => ({

        updateAssessmentSearchResultReferences(assessment_scores) {
            self.assessmentSearchResult=[]
            assessment_scores.forEach(assessment_score => self.assessmentSearchResult.push(assessment_score.id))
        },
        
        searchAssessments: flow(function* getAssessments() {
            const action = self.assessmentSearchInstance
            try {
                action.markLoading(true)
                const {client_state, ...action_without_client} = action
                const { data, meta, included } = yield self.bluebic.assessmentService.getAssessmentsForStudent(self.id, action_without_client)
                self.bluebic.handleUpdateStores([...data, ...included])
                self.assessmentsMeta = meta
                self.updateAssessmentSearchResultReferences(data)
                action.markLoading(false)
            } catch (err) {
                console.error("Failed to fetch assessments", err)
                action.markLoading(false)
                return validationErrorsHandler(err)
            }
            return null;
        }),
        
        removeBatchTransfer(batchTransfer) {
            const { data } = self.relationships.batch_transfers
            const foundIndex = data.findIndex(({ id }) => id === batchTransfer.id)
            self.relationships.batch_transfers.data.splice(foundIndex, 1)
        },
        selectUnpaidFeeById(id) {
            if (self.selectedUnpaidFees.includes(id)) {
                const index = self.selectedUnpaidFees.findIndex(id_ => id_ === id)
                self.selectedUnpaidFees.splice(index, 1)
            } else {
                self.selectedUnpaidFees.push(id)
            }
        },
        selectAllUnpaidFees() {
            if (self.areAllUnpaidFeesSelected) {
                self.selectedUnpaidFees.length = 0
            } else {
                self.unpaidStudentFees.forEach(({ id }) => {
                    if (!self.selectedUnpaidFees.includes(id)) {
                        self.selectedUnpaidFees.push(id)
                    }
                })
            }
        },
        block(reason) {
            self.attributes.blocked_at = Date.now()
            self.attributes.block_reason = reason
        },
        unblock(){
            self.attributes.blocked_at = null
            self.attributes.block_reason = null
        },
        markLoading(loading) {
            self.isLoading = loading
        },
        removeGuardianRelation(relation_id) {
            const { data } = self.relationships.student_guardian_relations
            const foundIndex = data.find(({ id }) => id === relation_id)
            data.splice(foundIndex, 1)
        },
        removeTransactionPreflight() {
            destroy(self.transactionPreflight)
        }
    }))
