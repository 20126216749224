import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cleave from 'cleave.js/react';
import { Menu, Dropdown } from 'antd'
// import withFormInput from './withFormInput'
// import Error from './assets/Error.svg'
// import Success from './assets/Success.svg'
import ArrowDownGrey from './assets/ArrowDownGrey.svg'

const comparatorOptions = [
  { text: 'Above', value: '>' },
  { text: 'Below', value: '<' },
]
const cleaveOptions = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand'
}

export default class ComparatorInput extends Component {
  state = { localValue: '> ' }

  static propTypes = {
    placeholder: PropTypes.string,
    // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    // success: PropTypes.bool,
    value: PropTypes.string,
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // onBlur: PropTypes.func.isRequired,
    // onFocus: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    placeholder: "",
    className: "",
    value: null
  }

  // handleVisibleChange = (visible) => {
  //   if (visible) {
  //     this.props.onFocus()
  //   } else {
  //     this.props.onBlur()
  //   }
  // }

  handleComparatorChange = newComparator => {
    const { onChange, value } = this.props
    const { localValue } = this.state
    const [_, textVal] = value ? value.split(' ') : localValue.split(' ')
    if (textVal) {
      onChange(`${newComparator} ${textVal}`)
    } else {
      onChange("")
    }
  }

  handleTextChange = newText => {
    const { onChange, value } = this.props
    const { localValue } = this.state
    const [comparator, _] = value ? value.split(' ') : localValue.split(' ')
    if (newText) {
      onChange(`${comparator} ${newText}`)
    } else {
      onChange("")
    }
  }

  render() {
    const { className, value, ...rest } = this.props
    const { localValue } = this.state
    // const style = (error || success) ? {  } : { paddingRight: 0 }
    const options = (
      // <Menu selectable onClick={ this.props.onBlur } >
      <Menu selectable>
        {comparatorOptions.map(({ text, value: comparator }) => (
          <Menu.Item key={text} onClick={() => this.handleComparatorChange(comparator)}>
            {text}
          </Menu.Item>
        ))}
      </Menu>
    )
    const [comparator, textValue] = value ? value.split(' ') : localValue.split(' ')
    return (
      <div className={`flex-row flex-spaceBetween flex-alignCenter FormInputControl ${className}`}>
        <Dropdown overlay={options} trigger={['click']} onVisibleChange={this.handleVisibleChange}>
          <div className="flex-row flex-spaceBetween flex-alignCenter touchable-opacity FormInputControl-prepend text-p">
            {comparatorOptions.find(o => o.value === comparator).text}
            <img src={ArrowDownGrey} alt="" className="utils-append" />
          </div>
        </Dropdown>
        <Cleave
          {...rest}
          value={textValue}
          className="FormInputControl-textInput"
          options={cleaveOptions}
          onChange={(e) => this.handleTextChange(e.target.rawValue)}
        />
        {/* {error && <img src={Error}/>}
        {success && <img src={Success}/>} */}
      </div>
    )
  }
}
// export default withFormInput(ComparatorInput)