import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox } from 'antd';
import isEmpty from 'lodash.isempty'

import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import MobileListLoading from '../common/loading/MobileListLoading';

import './AssignStudentsToSubject.css'
import {ElectiveSubjectRegistration} from "../store/actions/ElectiveSubjectRegistration";


const columns = [
    { key: 'photo', render: 'Photo' },
    { key: 'name', render: 'Name' },
    { key: 'admissionNumber', render: 'Adm. No.' },
    { key: 'batch', render: 'Batch' },
]

@inject('store')
@observer
class AssignStudentsToSubject extends Component {
    constructor(props) {
        super(props);
        const { subjectModel } = this.props;

        const formInstance = ElectiveSubjectRegistration.create({})
        formInstance.setFormInstance(subjectModel)

        this.formInstance = formInstance
    }

    handleSave = async () => {
        const { subjectModel, store : { subjectStore : { updateElectiveSubjectRegistration } }, closeModal } = this.props
        const {data, errors} = await updateElectiveSubjectRegistration(subjectModel.id, this.formInstance)
        if (isEmpty(errors)) closeModal()
    }

    render() {
        const {subjectModel } = this.props;
        const {selectedStudentIDs, selectStudentById, areAllStudentsSelected, selectAllStudents, isSaving} = this.formInstance
        const {assignableStudents, isLoading} = subjectModel

        const sortedStudents = assignableStudents.sort((a, b) => a.studentName.localeCompare(b.studentName, 'en', {sensitivity: 'base', ignorePunctuation: true}))

        const tableData = sortedStudents.map(({ id, attributes, studentName, admissionNumber, batchNameNoSession }) => ({
            id,
            name: studentName,
            admissionNumber,
            batch: batchNameNoSession,
            photo: (
                <Avatar
                    src={attributes.photo_url}
                    small
                    type={AVI_TYPES.STUDENT}
                    sex={attributes.gender}
                />
            ),
        }))

        return (
            <div>
                <div>
                    <NotMobile>
                        <div className="flex-row flex-spaceBetween">
                            <div className="flex-row">
                                {Boolean(selectedStudentIDs.length) && (
                                    `${selectedStudentIDs.length} selected`
                                )}
                            </div>
                        </div>
                        <Table
                            striped
                            containerClass="Card"
                            columns={columns}
                            data={tableData}
                            onRowSelectionChange={selectStudentById}
                            onError={e => console.error(e)}
                            loading={isLoading}
                            onColumnKeysChange={e => console.log(e)}
                            selectedRows={selectedStudentIDs}
                            isAllSelected={areAllStudentsSelected}
                            onSelectAll={selectAllStudents}
                        />
                    </NotMobile>

                    <Mobile>
                        <>
                            <div className="flex-row flex-spaceBetween mr-1 mb-1">
                                <div className="text--bold text-p utils-prepend">Select All</div>
                                <div>
                                    <span className="mr-2">{Boolean(selectedStudentIDs.length) && (`${selectedStudentIDs.length} selected`)}</span>
                                    <Checkbox
                                        checked={areAllStudentsSelected}
                                        onChange={selectAllStudents}
                                        className="utils-append"
                                    />
                                </div>

                            </div>
                            <MobileListLoading isLoading={isSaving}>
                                {sortedStudents.map(student => {
                                    const { id, studentName, attributes: { photo_url, gender }, admissionNumber, batchNameNoSession } = student

                                    return (
                                        <Card className="mb-1" key={student.id}>
                                            <div>
                                                <Checkbox
                                                    style={{position: 'absolute', right: '10%'}}
                                                    checked={selectedStudentIDs.includes(id)}
                                                    onChange={() => selectStudentById(id)}
                                                />
                                            </div>
                                            <Avatar
                                                small
                                                src={photo_url}
                                                sex={gender}
                                                type={AVI_TYPES.STUDENT}
                                            />
                                            <div>{studentName}</div>
                                            <div>Adm. No: {admissionNumber}</div>
                                            <div>batch: {batchNameNoSession}</div>
                                        </Card>
                                    )
                                })}
                            </MobileListLoading>

                        </>
                    </Mobile>
                    <Button
                        className="utils-center mt-2"
                        onClick={this.handleSave}
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </div>
        )
    }
}

export default AssignStudentsToSubject