import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/report_card_groups.json';
import addCommentJSON from '../json/add_report_card_comment_success.json'
import showReportCardGroupJSON from '../json/report_card_group_data.json';
import createReportCardGroupJSON from '../json/create_report_card_group_success.json';
import updateRportCardGroupJSON from '../json/update_report_card_group_success.json';
import regenerateRportCardGroupJSON from '../json/regenerate_report_card_group_success.json';
import deleteReportCardGroupJSON from '../json/delete_report_card_group_success.json';

export function ReportCardGroupService(env) {
    async function search(action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("report_search", action)
            const { data } = await axios.get(`/api/v1/reports/report_card_groups?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showReportCardGroup(id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/reports/report_card_groups/${id}/data`, { report: action })
            return data
        }
        return showReportCardGroupJSON
    }

    async function createReportCardGroup(action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/reports/report_card_groups`, { report_card_group: action })
            return data
        }
        return createReportCardGroupJSON
    }

    async function updateReportCardGroup(report_card_group_id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(
                `/api/v1/reports/report_card_groups/${report_card_group_id}`,
                { report_card_group: action }
            )
            return data
        }
        return updateRportCardGroupJSON
    }

    async function regenerateReportCardGroup(id) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/reports/report_card_groups/${id}/regenerate`)
            return data
        }
        return regenerateRportCardGroupJSON
    }

    async function deleteReportCardGroup(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/reports/report_card_groups/${id}`)
            return data
        }
        return deleteReportCardGroupJSON
    }

    async function addComment(report_card_group_id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.put(`/api/v1/reports/report_card_groups/${report_card_group_id}/comments`, {comment: action})
            return data
        }
        return addCommentJSON
    }

    async function downloadPDFReport(report_card_group_id, action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/reports/report_card_groups/${report_card_group_id}/pdf`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/pdf'},
            data: {report: action}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        
        return {fileData, fileName}
    }

    async function downloadBroadsheet(report_card_group_id, action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/reports/report_card_groups/${report_card_group_id}/broadsheet`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: {report: action}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        search,
        showReportCardGroup,
        downloadPDFReport,
        addComment,
        deleteReportCardGroup,
        regenerateReportCardGroup,
        updateReportCardGroup,
        createReportCardGroup,
        downloadBroadsheet
    }
}

export default ReportCardGroupService(TESTENV)