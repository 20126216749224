import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ModalContext } from '../common/modal'
import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import SubjectAssessment from './SubjectAssessment'
import SubjectScoreSheet from './SubjectScoreSheet';
import SubjectStudentsList from './SubjectStudentsList';
import AddSubject from './AddSubject'
import AssignRole from './AssignRole'
import MobileConfirmDialog from '../common/mobile_confirm_dialog';
import ContentLoader from '../common/content_loader'

import subjectIcon from './assets/book.svg'
import SubjectPolicy from "../policies/SubjectPolicy";
import AssignStudentsToSubject from "./AssignStudentsToSubject";


@inject("store")
@observer
class SelectedSubject extends Component {
    
    componentDidMount() {
        const {store : { subjectStore : { loadSubjectById }, view}} = this.props
        const  { selectedSubjectId } = view
        loadSubjectById(selectedSubjectId)
    }

    tabs = () => {
        const {store : {meta: {currentUser}, view}} = this.props
        const  { selectedSubject } = view

        const policy = new SubjectPolicy(currentUser, selectedSubject)

        let activeTab
        if (policy.can_scoresheet) activeTab = 2
        else if (policy.can_assessment_list) activeTab = 3
        else if (policy.can_show) activeTab = 1
        else return []

        const tabs = []
        if (policy.can_show){
            tabs.push(new Tab("Student List", <SubjectStudentsList subjectDetails={selectedSubject} />, activeTab === 1))
        }
        if (policy.can_scoresheet){
            tabs.push(new Tab("Score Sheet", <SubjectScoreSheet />, activeTab === 2))
        }
        if (policy.can_assessment_list){
            tabs.push(new Tab("Assessments", <SubjectAssessment />, activeTab === 3))
        }
        
        return tabs
    }

    options = (context) =>{
        const {
            store : {
                view : { selectedSubject },
                subjectStore : { deleteSubjectById },
                meta: {currentUser}
            }
        } = this.props

        const policy = new SubjectPolicy(currentUser, selectedSubject)

        const options =  [
            {
                text: 'Assign Employee',
                disabled: !policy.can_update_assigned_employees,
                title: policy.can_update_assigned_employees ? undefined : "No permission.",
                onClick: () => context.setContent(
                    'Assign Employees',
                    <AssignRole onCancel={context.closeModal} subjectId={selectedSubject.id} />,
                    true
                ),
            },
            {
                text: 'Edit Subject',
                disabled: !policy.can_update,
                title: policy.can_update ? undefined : "No permission.",
                onClick: () => context.setContent(
                    'Edit Subject',
                    <AddSubject onCancel={context.closeModal} editMode subjectId={selectedSubject.id} />,
                    true
                ),
            },
            {
                text: 'Delete Subject',
                disabled: !policy.can_destroy,
                title: policy.can_update ? undefined : "No permission.",
                onClick: () => context.setContent(
                    "Deleting subject...",
                    <MobileConfirmDialog
                        handleConfirm={() => {
                            deleteSubjectById(selectedSubject.id)
                            context.closeModal()
                        }}
                        handleCancel={context.closeModal}
                    />,
                    true
                ),
            },
        ]

        if (selectedSubject.isElective){
            options.unshift(
                {
                    text: 'Assign Students',
                    disabled: !policy.can_update_registered_students,
                    title: policy.can_update_registered_students ? undefined : "No permission.",
                    onClick: () => context.setContent(
                        `Assign Students`,
                        <AssignStudentsToSubject subjectModel={selectedSubject} closeModal={context.closeModal} />,
                        true
                    ),
                }
            )
        }

        return options
    }

    render() {
        const {
            store : {
                view : { selectedSubject },
                subjectStore: { isLoading }
            }
        } = this.props

        if(!selectedSubject || !selectedSubject.attributes) return <Loading />

        return (
            <ModalContext.Consumer>
                {context => (
                    <Tabs
                        title={{ text: 'Subject', icon: subjectIcon }}
                        tabs={this.tabs()}
                        content={<SubjectName loading={isLoading} subject={selectedSubject} />}
                        options={this.options(context)}
                        canPrint={false}
                    />
                )}
            </ModalContext.Consumer>
        )
    }
}

export default SelectedSubject

const SubjectName = ({subject: {subjectBaseName, batchName}, loading}) => !loading ? (
        <div
            style={{fontSize: "18px", fontWeight: 600}}
        >
            {subjectBaseName}
            <div className="text-p">{batchName}</div>
        </div>
    ) :
    (
        <ContentLoader height="20" width='500'>
            <rect x="0" y="0" rx="1" ry="1" width="150" height="12" />
        </ContentLoader>
    )

const Loading = () => (
    <div className="pl-2 " style={{ background: 'white'}}>
        <ContentLoader height="120" width="150%">
            <rect x="0" y="10" width="150" height="20" />
            <rect x="0" y="90" width="30%" height="20" />
            <rect x="35%" y="90" width="30%" height="20" />
            <rect x="70%" y="90" width="30%" height="20" />
        </ContentLoader>
    </div>
)