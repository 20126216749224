import { types } from "mobx-state-tree"

export const StudentGuardianRelation = types
    .model("StudentGuardianRelation", {
        id: types.maybe(types.string),
        student_id: types.maybeNull(types.string),
        guardian_id: types.maybeNull(types.string),
        relation: "",
        emergency_contact: false,
        authorized_to_pick_up: false
    })
