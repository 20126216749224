/* eslint-disable camelcase */
import { types } from 'mobx-state-tree';
import {AttachedDocument} from "./AttachedDocument";
import {Subject} from "./Subject";

export const publishTypeOptions = ["immediately", "due_date", "days_before_due", "specific_date"]

export const Assessment = types
    .model('Assessment', {
        id: types.identifier,
        type: 'assessment',
        attributes: types.maybe(
            types.model({
                name: types.string,
                abbreviation: types.maybeNull(types.string),
                description: types.maybeNull(types.string),
                possible_points: types.number,
                subject_id: types.number,
                extra_points: types.maybeNull(types.number),
                due_date: types.string,
                include_in_final_grade: types.boolean,
                publish_type: types.optional(types.enumeration(publishTypeOptions), publishTypeOptions[0]),
                score_display_as: types.string,
                assessment_category_name: types.string,
                assessment_category_abbreviation: types.maybeNull(types.string),
                publish_on: types.maybeNull(types.string),
                publish_days_before_due_value: types.maybeNull(types.number),
                publish_scores: types.boolean,
                published: types.boolean
            }),
        ),
        relationships: types.maybe(
            types.model({
                assessment_category: types.model({
                    data: types.model({
                        id: types.string,
                        type: "assessment_category",
                    })
                }),
                subject: types.model({
                    data: types.model({
                        id: types.late(() => types.safeReference(Subject)),
                        type: "subject",
                    })
                }),
                attached_documents: types.maybe(types.model({
                    data: types.array(types.model({
                        id: types.late(() => types.reference(AttachedDocument)),
                        type: "attached_document"
                    }))
                }))
            }),
        ),
        isLoading: false,
    })
    .views(self => ({
        get name() {
            try {
                return self.attributes.name;
            } catch (e) {
                return null;
            }
        },
        get subject_id() {
            return self.attributes.subject_id.toString()
        },
        get abbreviation() {
            try {
                return self.attributes.abbreviation;
            } catch (e) {
                return null;
            }
        },
        get description() {
            try {
                return self.attributes.description;
            } catch (e) {
                return null;
            }
        },
        get possiblePoints() {
            try {
                return self.attributes.possible_points
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
        get extraPoints() {
            try {
                return self.attributes.extra_points
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
        get dueDate() {
            try {
                return self.attributes.due_date;
            } catch (e) {
                return null;
            }
        },
        get finalGrade() {
            try {
                return self.attributes.include_in_final_grade;
            } catch (e) {
                return null;
            }
        },
        get publishScores() {
            try {
                return self.attributes.publish_scores;
            } catch (e) {
                return null;
            }
        },
        get publishType() {
            try {
                return self.attributes.publish_type;
            } catch (e) {
                return null;
            }
        },
        get publishOn() {
            try {
                return self.attributes.publish_on;
            } catch (e) {
                return null;
            }
        },
        get subject() {
            try {
                return self.relationships.subject.data.id;
            } catch (e) {
                return null;
            }
        },
        get category() {
            try {
                return self.relationships.assessment_category.data.id;
            } catch (e) {
                return null;
            }
        },
        get attachedDocuments() {
            try {
                return self.relationships.attached_documents.data.map(({ id }) => id )
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get assessmentCategoryName() {
            try {
                return self.attributes.assessment_category_name;
            } catch (e) {
                return null;
            }
        },
        get assessmentCategoryAbbreviation() {
            try {
                return self.attributes.assessment_category_abbreviation;
            } catch (e) {
                return null;
            }
        },
        get describePoints() {
            try {
                if (self.extraPoints) return `${self.possiblePoints} + ${self.extraPoints}`
                return self.possiblePoints
            } catch (e) {
                return null
            }
        },
        get scoreDisplay() {
            try {
                return self.attributes.score_display_as;
            } catch (e) {
                return null;
            }
        }
    }));
