import React from "react";
import PropTypes from "prop-types";

import "./BackButtonMobile.css";
import NotDesktop from "../common/display/NotDesktop";
import { handleButtonKeyPress } from "../tools/utils";
import arrowOutlineLeft from "./assets/arrow-outline-left.png";

export default function BackButtonMobile({ onClick }) {
    return (
        <NotDesktop>
            <div
                className="BackButtonMobile--cta-wrapper"
                role="button"
                tabIndex={0}
                onClick={onClick ? onClick : () => window.history.back()}
                onKeyDown={handleButtonKeyPress(onClick ? onClick : () => window.history.back())}
            >
                <img src={arrowOutlineLeft} alt="icon: arrow back" />
                <span>Go Back</span>
            </div>
        </NotDesktop>
    );
}

BackButtonMobile.defaultProps = {
    onClick: null,
};

BackButtonMobile.propTypes = {
    onClick: PropTypes.func,
};
