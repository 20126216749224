import React from 'react';
import { inject, observer } from 'mobx-react';
import { controlled, converters, Field, Form, RepeatingForm } from 'mstform';
import ModalForm from '../containers/ModalForm';
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import SelectInput from '../common/form/SelectInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import { forOptions, ReportCardGroup, reportTypeOptions } from '../store/actions/ReportCardGroup';
import DateRangeInput from '../common/form/DateRangeInput';
import RadioGroupInput from '../common/form/RadioGroupInput';
import { mapStringListToOptions } from '../lib/mapStringListToOptions';
import SwitchInput from '../common/form/SwitchInput';


const form = new Form(ReportCardGroup, {
    name: new Field(converters.maybeNull(converters.string), { required: true }),
    course_set_id: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: true }),
    report_card_template_id: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: true }),
    
    published: new Field(converters.boolean, { controlled: controlled.object, required: true }),
    
    report_type: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: true }),
    for: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: true }),
    attendance_date_range: new Field(converters.maybeNull(converters.string),{ controlled: controlled.object }),
    cognitive_column_date_ranges: new RepeatingForm({
        id: new Field(converters.maybeNull(converters.string), { required: true}),
        date_range: new Field(converters.maybeNull(converters.string),{ controlled: controlled.object, required: true })
    })
    
})


@inject('store', 'display')
@observer
class ReportCardGroupForm extends React.Component {

    state = {baseFormErrors: undefined }
    
    constructor(props) {
        super(props)

        const { reportCardGroup, editMode, store } = props;
        const { reportCardGroupStore: {
            createReportCardGroup,
            updateReportCardGroup,
            addReportCardGroupFormInstance
        } } = store
        
        const formInstance = addReportCardGroupFormInstance
        
        formInstance.reset()
        
        if (editMode /* && reportCardGroup */) {
            formInstance.setFormInstance(reportCardGroup)
            this.formState = form.state(formInstance, {
                addMode: false,
                save: async (node) => {
                    const { errors } = await updateReportCardGroup(reportCardGroup.id, node)
                    if (errors) this.setState({baseFormErrors: errors.base})
                    return errors
                }
            })
        }else {
            this.formState = form.state(formInstance, {
                addMode: false,
                save: async (node) => {
                    const { errors } = await createReportCardGroup(node)
                    if (errors) this.setState({baseFormErrors: errors.base})
                    return errors
                }
            })
        }

        this.state = {formModel: formInstance}
    }



    componentDidMount() {
        const { store:{ courseStore: {getCourseSets}, reportCardGroupStore: {getReportCardTemplates} } } = this.props;
        
        // TODO: Can these happen simultaneously?
        getCourseSets();
        getReportCardTemplates();
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const {baseFormErrors} = this.state;
        const { formModel: { isSaving, cognitive_column_date_ranges, columnsWithSpecificDateRange, usesSpecificAttendanceDateRange} } = this.state
        
        const { closeModal, store, editMode } = this.props
        const {
            reportCardGroupStore: {reportCardTemplates, reportCardTemplateCognitiveColumns},
            courseStore: {courseSets}
        } = store
        

        const course_set_id = this.formState.field("course_set_id")
        const name = this.formState.field("name")
        const report_card_template_id = this.formState.field("report_card_template_id")
        const report_type = this.formState.field("report_type")
        const published = this.formState.field("published")
        const for_field = this.formState.field("for")
        const attendance_date_range = this.formState.field("attendance_date_range")
        
        
        const cognitiveDateRangesForm = this.formState.repeatingForm("cognitive_column_date_ranges")
        
        
        const cognitiveDateRangeFields = []
        cognitive_column_date_ranges.forEach((column, index) => {

            const nColumnIsRequired = columnsWithSpecificDateRange.find(({id}) => id === column.id)

            if (nColumnIsRequired){
                // get the sub-form we want
                const dateRangeForm = cognitiveDateRangesForm.index(index);
                
                // and get the fields as usual
                const id = dateRangeForm.field("id");
                const date_range = dateRangeForm.field("date_range");
                
                
                const currentColumn = reportCardTemplateCognitiveColumns.get(column.id)
                let currentColumnName
                if (currentColumn) currentColumnName = currentColumn.attributes.heading
                else currentColumnName = 'Undefined column'

                cognitiveDateRangeFields.push(
                    <div key={column.id}>
                        <input type="hidden" {...id.inputProps} />
                        <Label text={`${currentColumnName} date range`} required>
                            <InlineError field={date_range}>
                                <DateRangeInput {...date_range.inputProps} />
                            </InlineError>
                        </Label>
                    </div>
                );
            }
            
        })

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={isSaving ? "Please wait..." : "Save"}
                isOkButtonDisabled={!this.formState.isValid || isSaving}
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                
                <Label text="Name" required>
                    <InlineError field={name}>
                        <TextInput placeholder="Primary 3 First Term 2020/2021" {...name.inputProps} />
                    </InlineError>
                </Label>
                {
                    editMode && (
                        <Label text="Published To Students" required>
                            <InlineError field={published}>
                                <SwitchInput {...published.inputProps} />
                            </InlineError>
                        </Label>
                    )
                }
                <Label text="Class Set" required>
                    <InlineError field={course_set_id}>
                        <SelectInput
                            {...course_set_id.inputProps}
                            options={mapModelNameToOptions(courseSets)}
                            placeholder="Select Class Set"
                        />
                    </InlineError>
                </Label>
                <Label text="Type" required>
                    <InlineError field={report_type}>
                        <RadioGroupInput
                            {...report_type.inputProps}
                            options={mapStringListToOptions(reportTypeOptions)}
                            name="Report Type"
                        />
                    </InlineError>
                </Label>
                <Label text="For Period" required>
                    <InlineError field={for_field}>
                        <SelectInput
                            {...for_field.inputProps}
                            options={mapStringListToOptions(forOptions)}
                            placeholder="Select Period"
                        />
                    </InlineError>
                </Label>
                <Label text="Template" required>
                    <InlineError field={report_card_template_id}>
                        <SelectInput
                            {...report_card_template_id.inputProps}
                            options={mapModelNameToOptions(reportCardTemplates)}
                            placeholder="Select Template"
                        />
                    </InlineError>
                </Label>
                {
                    usesSpecificAttendanceDateRange && (
                        <Label text="Attendance Date Range" required>
                            <InlineError field={attendance_date_range}>
                                <DateRangeInput {...attendance_date_range.inputProps} />
                            </InlineError>
                        </Label>
                    )
                }
                {cognitiveDateRangeFields}
            </ModalForm>
        )
    }
}

export default ReportCardGroupForm