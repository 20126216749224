class ApplicationPolicy {

    constructor(user, record){
        this._user = user;
        this._record = record;
    }

    get user() {
        return this._user;
    }

    get record() {
        return this._record;
    }

    get can_index() {
        return false
    }

    get can_show() {
        return false
    }

    get can_create() {
        return false
    }

    get can_update() {
        return false
    }

    get can_destroy() {
        return false
    }

}

export default ApplicationPolicy