import ApplicationPolicy from "./ApplicationPolicy";

class SettingPolicy extends ApplicationPolicy{
    
    get can_show(){
        return this.user.has_role('general_settings')
    }

    get can_update(){
        return this.user.has_role('general_settings')
    }
}

export default SettingPolicy