import React from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd';
import { formatDate } from '../lib/formatDate'
import Card from '../common/card'
import { formatCurrency } from '../lib/formatCurrency';

const FeesItem = inject("store")(
    observer(({ store, fee }) => {
        const { currency, view: {
            getFeesPage,
            openFeePageById,
            setBackPage
        }   } = store
        const {
            id,
            feeType: {
                attributes: {
                    name,
                }
            },
            attributes: {
                description,
                amount,
                start_date,
                due_date,
                created_at,
            }
        } = fee
        return (
            <Card key={id} className="mb-1">
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={8} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Fee Type</div>
                        <a
                            href={`/finance/fees/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Fee Management', getFeesPage())
                                openFeePageById(id)                                
                            }}
                        >
                            {name}
                        </a>
                    </Col>
                    <Col span={16} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Creation Date</div>
                        <div>{formatDate(created_at)}</div>
                    </Col>
                    <Col span={24} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Description</div>
                        <div>{description}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Amount</div>
                        <div>{formatCurrency(amount, currency)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Start Date</div>
                        <div>{formatDate(start_date)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Due Date</div>
                        <div>{formatDate(due_date)}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)

export default FeesItem