import './NoteItem.css'

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {observer, inject} from 'mobx-react'
import {Popover} from 'antd'

import SwitchInput from '../form/SwitchInput'
import {ModalContext} from '../modal'
// import trunc from '../../lib/trunc'
import CreateNote from './CreateNote'
import more from './assets/more-vert.svg'
import Card from '../card';
import ContentLoader from '../content_loader';
import { formatDateShort } from "../../lib/formatDate";

@inject('display')
@observer
class NoteItem extends Component {
    static propTypes = {
        note: PropTypes.shape({}).isRequired,
        visibilityText: PropTypes.string.isRequired,
        onDeleteNote: PropTypes.func.isRequired,
        onEditNote: PropTypes.func.isRequired,
        canUpdateNote: PropTypes.bool.isRequired
    }

    get moreButtonPopover() {
        const {onDeleteNote, onEditNote, note} = this.props

        return (
            <ModalContext.Consumer>
                {context => (
                    <React.Fragment>
                        <button
                            type="submit"
                            onClick={() => {
                                context.setContent(
                                    'Edit Note',
                                    <CreateNote
                                        onClose={context.closeModal}
                                        editNote={note}
                                        onEdit={updates => onEditNote(note.id, updates)}
                                        onDelete={() => onDeleteNote(note.id)}
                                    />
                                )
                            }}
                            className="Button Button--clear touchable-opacity text-p"
                        >
                            Edit Note
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            onClick={() => onDeleteNote(note.id)}
                            className="Button Button--clear touchable-opacity text-p text--danger"
                        >
                            Delete Note
                        </button>
                    </React.Fragment>
                )}
            </ModalContext.Consumer>
        )
    }

    get popoverPlacement() {
        const {display} = this.props
        return display.isDesktop ? 'bottomRight' : 'topRight'
    }

    render() {
        const {note, visibilityText, onEditNote, canUpdateNote} = this.props
        const {id, title, body, updated_at, author, visible} = note
        return (
            <Card className="NoteItem">
                <div className="text-p text--bold NoteItem-title">{title}</div>
                <div className="text-p NoteItem-text text-clamp2lines">{body}</div>
                <div className="text-p text--grey NoteItem-createdAt">{formatDateShort(updated_at)}</div>
                <div className="text-p text--grey NoteItem-author">Created by {author}</div>
                {
                    canUpdateNote && (
                        <>
                            <div className="NoteItem-visibilitySwitch">
                                <span className="text-small text--bold text--uppercase text--spacedLetters utils-prepend">{visibilityText}</span>
                                <SwitchInput value={visible} onChange={e => onEditNote(id, {...note, visible_to_owner: e})} />
                            </div>

                            <Popover
                                content={this.moreButtonPopover}
                                placement={this.popoverPlacement}
                                overlayClassName="override-popover"
                            >
                                <div className="touchable-opacity NoteItem-moreButton">
                                    <img src={more} alt="..." />
                                </div>
                            </Popover>
                        </>
                    )
                }
            </Card>
        )
    }
}

export default NoteItem

export function Loading() {
    return (
        <>
            <Card>
                <ContentLoader height="40" width="600">
                    <rect x="0" y="3" rx="2" ry="2" width="40" height="5" />
                    <rect x="95%" y="3" rx="2" ry="2" width="5%" height="5" />
                    <rect x="0" y="18" rx="2" ry="2" width="80%" height="5" />
                    <rect x="0" y="35" rx="2" ry="2" width="60" height="5" />
                    <rect x="90%" y="35" rx="2" ry="2" width="10%" height="5" />
                </ContentLoader>
            </Card>
            <div style={{marginBottom: 10}} />
            <Card>
                <ContentLoader height="40" width="600">
                    <rect x="0" y="3" rx="2" ry="2" width="40" height="5" />
                    <rect x="95%" y="3" rx="2" ry="2" width="5%" height="5" />
                    <rect x="0" y="18" rx="2" ry="2" width="80%" height="5" />
                    <rect x="0" y="35" rx="2" ry="2" width="60" height="5" />
                    <rect x="90%" y="35" rx="2" ry="2" width="10%" height="5" />
                </ContentLoader>
            </Card>
        </>
    )
}