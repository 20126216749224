import React from 'react'
import PropTypes from 'prop-types'
import Button, { buttonTypes } from '../common/button';

function ModalForm({ children, onCancel, onOk, okButtonText, okButtonType, isOkButtonDisabled, ...rest }) {
    return (
        <form {...rest}>
            {children}
            <div className="flex-row flex-spaceBetween utils-padTop">
                <button
                    type="button"
                    className="text--grey touchable-opacity"
                    onClick={onCancel}
                >
                    Cancel
                </button>
                <Button
                    onClick={onOk}
                    disabled={isOkButtonDisabled}
                    buttonType={okButtonType}
                >
                    {okButtonText}
                </Button>
            </div>
        </form>
    )
}

ModalForm.propTypes = {
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    okButtonText: PropTypes.string,
    okButtonType: PropTypes.oneOf(buttonTypes),
    isOkButtonDisabled: PropTypes.bool
}

ModalForm.defaultProps = {
    onCancel: () => { },
    onOk: () => { },
    okButtonText: "Ok",
    okButtonType: "primary",
    isOkButtonDisabled: false,
}

export default ModalForm

