import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form, converters, controlled } from "mstform";
import { observer, inject } from "mobx-react";

import "./NewClass.css";
import { CreateCourse } from "../../store/actions/Course";
import InlineError from "../../common/form/InlineError";
import Label from "../../common/form/Label";
import TextInput from "../../common/form/TextInput";
import SwitchInput from "../../common/form/SwitchInput";
import Button from "../../common/button";

const validators = [(value) => (value.length > 0) ? false : 'Field is required'];
const form = new Form(CreateCourse, {
    name: new Field(converters.string, { required: true, validators }),
    abbreviation: new Field(converters.string, { required: true, validators }),
    is_active: new Field(converters.boolean, { required: true, controlled: controlled.object }),
});

@inject("store")
@observer
export default class NewClass extends Component {
    constructor(props) {
        super(props);
        const { store, editMode, selectedClassId, closeForm } = props;
        const { courseStore: { courses, createCourse, updateCourse, addCourseFormInstance } } = store;

        if (editMode) {
            const selectedCourse = courses.get(selectedClassId);
            this.formState = form.state(CreateCourse.create({ ...selectedCourse, ...selectedCourse.attributes }), {
                addMode: false,
                save: async (node) => {
                    const { errors, data } = await updateCourse(node.toJSON(), selectedClassId);
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        } else {
            this.formState = form.state(addCourseFormInstance, {
                save: async (node) => {
                    this.setState({ errors: null });
                    const { errors, data } = await createCourse(node.toJSON());
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        }

        this.state = {
            errors: null,
        };
    }

    handleSubmit = async () => {
        await this.formState.save();
    }

    render() {
        const { errors } = this.state;
        const name = this.formState.field("name")
        const abbreviation = this.formState.field("abbreviation");
        const is_active = this.formState.field("is_active");
        const { editMode, store: { courseStore: { isCreateCourseLoading, isUpdateCourseLoading } } } = this.props;

        return (
            <div className="NewAcademicSession">
                <div className="NewAcademicSession--Header">
                    <h2>{`${editMode ? 'Edit' : 'Create New'} Class`}</h2>
                </div>
                {
                    errors && (
                        <div className="NewAcademicSession--Errors">
                            {
                                Object.keys(errors).map(key => (<p key={errors[key] + key}>{`Error Message - ${key.toUpperCase()}: ${errors[key]}`}</p>))
                            }
                        </div>
                    )
                }

                <div className="NewAcademicSession--Body">
                    <Label text="Name" required={name.required}>
                        <InlineError field={name}>
                            <TextInput {...name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Abbreviation" required={abbreviation.required}>
                        <InlineError field={abbreviation}>
                            <TextInput {...abbreviation.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Status" required={is_active.required}>
                        <InlineError field={is_active}>
                            <SwitchInput {...is_active.inputProps} />
                        </InlineError>
                    </Label>
                    <Button
                        loading={isCreateCourseLoading || isUpdateCourseLoading}
                        onClick={this.handleSubmit}
                    >
                        {`${editMode ? 'Edit' : 'Create'} Class`}
                    </Button>
                </div>
            </div>
        );
    }
}

NewClass.defaultProps = {
    editMode: false,
};

NewClass.propTypes = {
    editMode: PropTypes.bool,
    closeForm: PropTypes.func.isRequired,
    selectedClassId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
