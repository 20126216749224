import axios from 'axios'
import { BASE_URL } from './constants'

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        
        // Managed by store via setCurrentInstitutionTenantName
        // 'X-Institution-Tenant-Name': TENANT_NAME,
    }
})

export function setAuthHeader(token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
}

export function removeAuthHeader() {
    axiosInstance.defaults.headers.Authorization = ""
}

export function setCurrentInstitutionTenantName(name) {
    if (name){
        axiosInstance.defaults.headers['X-Institution-Tenant-Name'] = name
    }else{
        delete axiosInstance.defaults.headers["X-Institution-Tenant-Name"];
    }
}

export default axiosInstance

/**
 * Code references
 * - https://gist.github.com/saqueib/a495af17d7c0e2fd5c2316b0822ebac3
 */
