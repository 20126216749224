import React from 'react';
import isEmpty from 'lodash.isempty'
import EmptyList from '../common/empty_list/empty_list';
import NotMobile from '../common/display/NotMobile';
import Mobile from '../common/display/Mobile';
import Card from '../common/card';
import icon from './assets/megaphone.svg';
import './Announcements.css';


export default function Announcements(props) {
    const {recentAnnouncements: data } = props
    return (
        <div className='dashboard-announcements announcements'>
            <div className="flex-row announcements-head">
                <img className="dashboard-icon" src={icon} alt="" />
                <span className="text-small text--uppercase">Announcements</span>
            </div>
            
            {(isEmpty(data)) && <EmptyList />}

            {
                !isEmpty(data) && (
                    <>
                        <NotMobile>
                            <Card className="card--flat">
                                <div className="table">
                                    <div className="table-body announcements-tableBody">
                                        {data.map(x => (
                                            <div
                                                key={x.name}
                                                className={`table-row announcements-tableRow${  x.hasBadge ? ' announcements-tableRow-hasNotification' : ''}`}
                                            >
                                                <div className="text-h4">{x.name}</div>
                                                <div className="text-p text--grey">{x.text}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Card>
                        </NotMobile>
                        <Mobile>
                            {data.map((x) => (
                                    <Card key={x.id} className={`announcements-mobileCard${  x.hasBadge ? ' announcements-mobileCard-hasNotification' : ''}`}>
                                        <div className="text-h3">{x.name}</div>
                                        <div className="text-p text--grey">{x.text}</div>
                                    </Card>
                                ))}
                            <div className="text--primary text-small text--bold touchable-opacity">VIEW ALL</div>
                        </Mobile>
                    </>
                )
            }
        </div>
    );
}
