import React from 'react'
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform"
import DateInput from '../common/form/DateInput';
import MultilineInput from '../common/form/MultilineInput';
import Label from '../common/form/Label';
import { studentsBatchTransfer } from '../store/actions/Transfer'
import ModalForm from '../containers/ModalForm';
import SelectInput from '../common/form/SelectInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import InlineError from '../common/form/InlineError';

const formModel = new Form(studentsBatchTransfer, {
    transfer_to_batch_id: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object,
        required: false
    }),
    type: new Field(converters.string, {
        controlled: controlled.object,
        required: false
    }),
    student_ids: new Field(converters.stringArray, {required: false}),
    last_day_in_current_batch:new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object,
        required: true
    }),
    reason: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.string,
        required: true
    }),
})

@inject("store", "display")
@observer
class BatchStudentTransfer extends React.Component {
    static propTypes = {
        batchId: PropTypes.string.isRequired,
        studentIds:  PropTypes.array.isRequired,
        batch_transfer_type: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired
    }

    state = {baseFormErrors: undefined }

    constructor(props) {
        super(props)
        const { studentIds, batch_transfer_type } = props
        const stuIds  = this.mapStudentIds(studentIds)
        const formInstance = studentsBatchTransfer.create({
            type: batch_transfer_type,
            student_ids: stuIds

        })
        this.formState = formModel.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const {
                    store: {
                        batchStore: { batchTransfer },
                    },
                    batchId
                } = this.props
                const { errors } = await batchTransfer(batchId,node.toJSON())
                if (errors) this.setState({baseFormErrors: errors.base})
                return errors
            }
        })
    }
    
    mapStudentIds(studentIds) {
        try {
            return studentIds
                .map(( id ) => id)
        } catch (e) {
            console.warn(e)
            return []
        }
    }
    
    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { onClose } = this.props
            onClose()
        }
    }

    render() {
        const {baseFormErrors} = this.state
        const transfer_to_batch_id = this.formState.field("transfer_to_batch_id")
        const last_day_in_current_batch = this.formState.field("last_day_in_current_batch")
        const reason = this.formState.field("reason")
        const { onClose, display, store:{ batchStore : { items }}, batch_transfer_type} = this.props
     
        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Save"
                isOkButtonDisabled={!this.formState.isValid }
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                {batch_transfer_type === "change_batch" ? <Label text="Batches" inline={display.isNotMobile} required={transfer_to_batch_id.required}>
                        <InlineError field={transfer_to_batch_id}>
                            <SelectInput
                                {...transfer_to_batch_id.inputProps}
                                options={mapModelNameToOptions(items)}
                                placeholder="Select Batch to Transfer Student "
                            />
                        </InlineError>
                </Label> : '' }
                
                <Label text="Last Day In Batch" required={last_day_in_current_batch.required} inline={display.isNotMobile}>
                    <InlineError field={last_day_in_current_batch}>
                        <DateInput {...last_day_in_current_batch.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Reason" required={reason.required} inline={display.isNotMobile}>
                    <InlineError field={reason}>
                        <MultilineInput {...reason.inputProps} />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}
export default BatchStudentTransfer