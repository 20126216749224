import { types } from 'mobx-state-tree';
import {SmsMaster} from "./SmsMaster";

export const SmsTopup = types
    .model('SmsTopup', {
        id: types.identifier,
        type: 'sms_topup',
        attributes: types.maybe(
            types.model({
                units: types.number,
                amount_paid: types.union(types.string, types.number),
                note: types.maybeNull(types.string),
                created_at: types.string
            }),
        ),
        relationships: types.maybe(
            types.model({
                sms_master: types.model({
                    data: types.maybeNull(types.model({
                        id: types.late(() => types.reference(SmsMaster)),
                        type: "sms_master"
                    }))
                })
            }),
        )
    });