import {applySnapshot, types} from "mobx-state-tree"

export const Block = types
    .model("Block", {
        student_ids: types.array(types.string),
        reason: types.string,
    })

export const UnblockStudent = types
    .model("UnblockStudent", {
        student_ids: types.array(types.string),

        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, false)
        }), {})
    })
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        reset(){
            applySnapshot(self, {})
        }
    }))