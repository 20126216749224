import './Modal.css'

import React from 'react'
import { Modal as AntModal } from 'antd'
import { ModalContext } from './ModalContext'
import ErrorBoundary from '../error_boundary';

export function ModalConsumer({ screensize }) {
    return (
        <ModalContext.Consumer>
            {context => (
                <AntModal
                    maskClosable={false}
                    title={context.state.modalTitle}
                    visible={context.state.isModalVisible}
                    onOk={context.closeModal}
                    onCancel={context.closeModal}
                    afterClose={context.dispose}
                    zIndex={10}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
                    footer={null}
                    wrapClassName={context.state.isModalSmall ? 'Modal-smallSize' : `Modal ${screensize}`}
                    destroyOnClose
                    width={context.state.width}
                >
                    <ErrorBoundary>
                        {context.state.modalContent}
                    </ErrorBoundary>
                </AntModal>
            )}
        </ModalContext.Consumer>
    )
}

