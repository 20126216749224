import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import Tabs, {Tab} from '../common/tabs'
import Register from './BatchAttendanceRegister'
import Report from './BatchAttendanceReport'


@inject('store')
@observer
class Attendance extends Component {

    constructor(props) {
        super(props)
        const { batchDetails } = this.props
        this.tabs = [
            new Tab('Register', <Register batchDetails={batchDetails} />, true),
            new Tab('Report', <Report batchDetails={batchDetails} />),
        ]
    }
    
    render() {
        return <Tabs tabs={this.tabs} responsive={false} />
    }

}

export default Attendance
