import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form, converters, controlled } from "mstform";
import { observer, inject } from "mobx-react";

import "./NewAcademicSession.css";
import { CreateAcademicSession } from "../../store/actions/AcademicSession";
import InlineError from "../../common/form/InlineError";
import Label from "../../common/form/Label";
import TextInput from "../../common/form/TextInput";
import DateRangeInput from "../../common/form/DateRangeInput";
import Button from "../../common/button";
import { dateRangeConverter, prepareAcademicSessionDatesForEdit } from "../../lib/formatDate";

const validators = [(value) => (value.length > 0) ? false : 'Field is required']
const form = new Form(CreateAcademicSession, {
    name: new Field(converters.string, { required: true, validators }),
    first_term: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object,
        required: true, validators,
    }),
    second_term: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object,
        required: true, validators,
    }),
    third_term: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object,
        required: true, validators,
    }),
});

@inject("store", "display")
@observer
export default class NewAcademicSession extends Component {
    constructor(props) {
        super(props);
        const { store, editMode, selectedSessionId, closeForm } = props;
        const { academicSessionStore: { academicSessions, updateAcademicSession, createAcademicSession, addAcademicSessionFormInstance } } = store;

        if (editMode) {
            const selectedSession = academicSessions.get(selectedSessionId);
            this.formState = form.state(CreateAcademicSession.create(prepareAcademicSessionDatesForEdit({ ...selectedSession, ...selectedSession.attributes })), {
                addMode: false,
                save: async (node) => {
                    const { errors, data } = await updateAcademicSession(node.toJSON(), selectedSessionId);
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        } else {
            this.formState = form.state(addAcademicSessionFormInstance, {
                addMode: false,
                save: async (node) => {
                    this.setState({ errors: null });
                    const { errors, data } = await createAcademicSession(node.toJSON());
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        }

        this.state = {
            errors: null,
        };
    }

    handleSubmit = async () => {
        await this.formState.save();
    }

    render() {
        const { errors } = this.state;
        const name = this.formState.field("name")
        const first_term = this.formState.field("first_term");
        const second_term = this.formState.field("second_term");
        const third_term = this.formState.field("third_term");
        const { editMode, store: { academicSessionStore: { isUpdateSessionLoading, isCreateSessionLoading } } } = this.props;

        return (
            <div className="NewAcademicSession">
                <div className="NewAcademicSession--Header">
                    <h2>{`${editMode ? 'Edit' : 'Create New'} Session`}</h2>
                </div>
                {
                    errors && (
                        <div className="NewAcademicSession--Errors">
                            {
                                Object.keys(errors).map(key => (<p key={errors[key] + key}>{`Error Message - ${key.toUpperCase()}: ${errors[key]}`}</p>))
                            }
                        </div>
                    )
                }

                <div className="NewAcademicSession--Body">
                    <Label text="Name" required={name.required}>
                        <InlineError field={name}>
                            <TextInput {...name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="First Term" required={first_term.required}>
                        <InlineError field={first_term}>
                            <DateRangeInput {...first_term.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Second Term" required={second_term.required}>
                        <InlineError field={second_term}>
                            <DateRangeInput {...second_term.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Third Term" required={third_term.required}>
                        <InlineError field={third_term}>
                            <DateRangeInput {...third_term.inputProps} />
                        </InlineError>
                    </Label>
                    <Button
                        loading={isCreateSessionLoading || isUpdateSessionLoading}
                        onClick={this.handleSubmit}
                    >
                        {`${editMode ? 'Edit' : 'Create'} Academic Session`}
                    </Button>
                </div>
            </div>
        );
    }
}

NewAcademicSession.defaultProps = {
    editMode: false,
};

NewAcademicSession.propTypes = {
    editMode: PropTypes.bool,
    closeForm: PropTypes.func.isRequired,
};
