import './StudentReportCards.css'
import '../common/notes/Notes.css'

import React from 'react'
import { observer, inject } from 'mobx-react'
import Card from '../common/card';
import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Table from '../common/table';
import { titleCase } from '../lib/titleCase'
import Desktop from "../common/display/Desktop";
import Tablet from "../common/display/Tablet";

const tableColumns = [
    { key: 'name', render: 'Name' },
    { key: 'classSet', render: 'Class Set' },
    { key: 'report_type', render: 'Type' },
    { key: 'report_for', render: 'For' },
    { key: 'status', render: 'Status' },
    { key: 'published', render: 'Published' },
]

const StudentReportCards =inject("store")(
    observer(({ store }) => {
        
        const {
            view: {
                getStudentPage,
                openStudentReportCardPageById,
                selectedStudent,
                selectedStudent: {reportCardData},
                setBackPage
            }, 
        } = store
        
        const {isLoading} = selectedStudent
        
        const tableData = reportCardData
            .map(({ id, reportCardGroup }) => {
                const {attributes: {name, report_type, for: forAttrib, status, published}, classSet} = reportCardGroup
                
                return {
                    id,
                    name: (
                        published ? 
                            (
                                <a
                                    href={`/report-cards/${id}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setBackPage('Student Profile', getStudentPage())
                                        openStudentReportCardPageById(id)
                                    }}
                                >
                                    {name}
                                </a>
                            ) : name
                    ),
                    classSet: classSet.attributes.name,
                    report_type: titleCase(report_type),
                    report_for: titleCase(forAttrib),
                    status: titleCase(status),
                    published: (published ? <span className="text--success">Published</span> : <span className="text--grey">Not Published</span>),
                }
            })
        
        return (
            <>
                <Desktop>
                    <Card title="Student Report Cards">
                        <Table
                            columns={tableColumns}
                            data={tableData}
                            striped
                            responsive
                            loading={isLoading}
                        />
                    </Card>
                </Desktop>
                <Tablet>
                    <Card className="Notes-head">
                        <div className="text-small text--uppercase text--spacedLetters utils-prepend">Student Report Cards</div>
                    </Card>
                    <Table
                        columns={tableColumns}
                        data={tableData}
                        striped
                        responsive
                        loading={isLoading}
                    />
                </Tablet>
                <Mobile>
                    <Card className="Notes-head">
                        <div className="text-small text--uppercase text--spacedLetters utils-prepend">Student Report Cards</div>
                    </Card>
                    {tableData.map(item => <StudentReportCardsItem key={item.id} keys={item} />)}
                </Mobile>
            </>
        )
}))

function StudentReportCardsItem({ keys }) {
    return (
        <Card className="StudentReportCardsItem">
            {tableColumns.map(({ key, render }) => (
                <div key={key}>
                    <div className="text-small text--uppercase">{render}</div>
                    <div className="text-p text--bold">{keys[key]}</div>
                </div>
            ))}
        </Card>
    )
}

export default StudentReportCards