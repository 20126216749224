import { types } from "mobx-state-tree"

export const SearchMeta = types.model({
    total_count: 0,
    total_pages: 0,
    total_ids: types.array(types.string)
})

export const StudentAssessmentSearchMeta = types.compose(
    SearchMeta,
    types.model({
        statistics: types.maybe(types.model({
            line_chart_data: types.array(types.model({
                name: types.string,
                short_name: types.string,
                id: types.number,
                data: types.array(types.model({
                    period: types.string,
                    value: types.string
                }))
            })),
            radar_chart_data: types.array(types.model({
                name: types.string,
                short_name: types.string,
                mark: types.string,
                full_mark: types.number,
                percentage: types.string
            })),
            overall_average_percentage: types.maybeNull(types.string)
        })),
    })
)