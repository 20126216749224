import {applySnapshot, types} from 'mobx-state-tree';

export const ReportCardGroupData = types.model({
    student_ids: types.array(types.string),
    batch_ids: types.array(types.string),
    is_broadsheet: false,
    
    client_state: types.optional(types.model({
        isSaving: types.optional(types.boolean, false)
    }), {})
})
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        setToGetStudents(studentIDs){
            applySnapshot(self, {student_ids: studentIDs})
        },
        setToGetBatches(batchIDs){
            applySnapshot(self, {batch_ids: batchIDs})
        },
        isBroadsheetRequest(flag){
            self.is_broadsheet = flag
        }
    }))