import { types } from 'mobx-state-tree'

const Breakpoints = new Map()
Breakpoints.set('large', {
    event: window.matchMedia('(min-width: 1200px)'),
    screensize: 'desktop'
})
Breakpoints.set('medium', {
    event: window.matchMedia('(max-width: 1199px) and (min-width: 992px)'),
    screensize: 'tablet'
})
Breakpoints.set('small', {
    event: window.matchMedia('(max-width: 991px) and (min-width: 768px)'),
    screensize: 'tablet'
})
Breakpoints.set('xsmall', {
    event: window.matchMedia('(max-width: 767px)'),
    screensize: 'mobile'
})

export const Display = types
    .model("Display", {
        screensize: ""
    })
    .views(self => ({
        get isNotDesktop() {
            return self.screensize !== 'desktop'
        },
        get isDesktop() {
            return self.screensize === 'desktop'
        },
        get isTablet() {
            return self.screensize === 'tablet'
        },
        get isNotTablet() {
            return self.screensize !== 'tablet'
        },
        get isMobile() {
            return self.screensize === 'mobile'
        },
        get isNotMobile() {
            return self.screensize !== 'mobile'
        },
    }))
    .actions(self => {
        function afterCreate() {
            /**
             * listens to media queries for desktop,
             * tablet and mobile screens.
             * Reactively sets this.responsiveClassNameSuffix, to be
             * used app-wide to make components responsive
             * NOTE: Responsiveness is handled using dynamic
             * classNames by observing uiStore.responsiveClassNameSuffix
             */
            Breakpoints.forEach(breakPoint => {
                breakPoint.event.addListener(evt => {
                    self.onWidthChange(evt, breakPoint.screensize)
                })
            })

            const onDOMLoad = () => {
                Breakpoints.forEach(breakPoint => {
                    self.onWidthChange(breakPoint.event, breakPoint.screensize)
                })
            }

            /**
             * ensures that breakpoint query listeners fire
             * after DOM is loaded
             */
            // window.addEventListener("DOMContentLoaded", onDOMLoad, false)

            // Fire
            onDOMLoad()
        }

        function onWidthChange(mediaQuery, suffix) {
            if (mediaQuery.matches) {
                self.screensize = suffix
            }
        }

        return {
            afterCreate,
            onWidthChange
        }
    })

export const display = Display.create({})