import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import './RadioGroupInput.css'

@observer
class RadioGroupInput extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        options: PropTypes.array.isRequired
    }

    static defaultProps = {
        value: undefined
    };
    
    @computed get options() {
        const { options } = this.props
        return options.map(({ text, value }) => (<Radio key={value} value={value}>{text}</Radio>))
    }

    onChange = (e) => {
        const { onChange } = this.props
        onChange(e.target.value)
    }

    render() {
        const { value } = this.props
        return (
            <Radio.Group onChange={this.onChange} value={value} defaultValue={value}>
                {this.options}
            </Radio.Group>
        )
    }
}

export default RadioGroupInput