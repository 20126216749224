import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";

import "./ClassSets.css";
import BackButtonMobile from "../BackButtonMobile";
import NoDataAvailable from "../NoDataAvailable";

@inject("store")
@observer
export default class ClassSets extends Component {
    render() {
        return (
            <div className="ClassSets">
                <div className="ClassSets--Header">
                    <BackButtonMobile />
                    <h2>Class Sets</h2>
                </div>
                <div className="ClassSets--Body">
                    <NoDataAvailable
                        buttonText="Create New Class"
                        title="There's Nothing Here At The Moment"
                        description="You haven't added any class sets yet."
                    />
                </div>
            </div>
        );
    }
}