import { types } from "mobx-state-tree"

export const Bank = types
    .model("Bank", {
        id: types.identifier,
        type: "bank",
        attributes: types.maybe(types.model({
            short_name: "",
            name: "",
        }))
    })