import React from 'react'
import * as PropTypes from 'prop-types'
import {addDays, isWeekend, startOfWeek} from 'date-fns'
import {formatDate, localDayOfWeekNameShort} from "../../lib/formatDate";
// import { observer } from 'mobx-react';

export default function CalendarDays({ currentMonth }) {
  const days = []
  const startDate = startOfWeek(currentMonth)

  for (let i = 0; i < 7; i += 1) {
    const day = addDays(startDate, i)
    if (!isWeekend(day)) {
      days.push(
        <div key={i}>
          {formatDate(day, localDayOfWeekNameShort)}
        </div>
      )
    }
  }

  return <div className="Calendar-days">{days}</div>
}
CalendarDays.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired
}
