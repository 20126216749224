import React from 'react'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase'
import Button from '../button'
import arrowDown from './assets/arrow-down-white.svg'


const OPTION_PROP = {
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    title: PropTypes.string
}
const OPTIONS_PROP = PropTypes.arrayOf(PropTypes.shape(OPTION_PROP))


function OptionItem(props) {

    const {disabled, text, title, onClick, icon} = props
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            title={title}
            className="Button Button--clear touchable-opacity text-p"
        >
            {icon && <img src={icon} alt="" className="utils-prepend" />}
            {text}
        </button>
    );
}

OptionItem.propTypes = OPTION_PROP
OptionItem.defaultProps = {
    icon: undefined,
    onClick: () => {},
    disabled: false,
    title: undefined
}

@observer
class OptionsPopover extends React.Component {
  static propTypes = {
    options: OPTIONS_PROP
  }

  static defaultProps = {
    options: []
  }

  render() {
    const { options } = this.props
    const popoverContent = options.map((option, idx) => (
        <React.Fragment key={camelCase(option.text)}>
            {idx > 0 && <div className="divider" />}
            <OptionItem
                text={option.text}
                disabled={option.disabled}
                title={option.title}
                onClick={option.onClick}
                icon={option.icon}
            />
        </React.Fragment>
    ))

    return (
      <Button
        fullwidth
        popover={{
          content: popoverContent,
          placement: 'bottomRight'
        }}
      >
        Options
        <img src={arrowDown} alt="" className="utils-append" />
      </Button>
    )
  }
}

export {OptionsPopover, OPTIONS_PROP}