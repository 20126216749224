import { types, flow, destroy } from 'mobx-state-tree'
import { UsesBluebicStorePattern } from './patterns/UsesBluebicStorePattern'
import { Attendance } from './models/Attendance'
import { validationErrorsHandler } from './helpers/errors'
import { filterType } from './helpers/filterType'
import FileSaver from "file-saver";


export const AttendanceStore = types
    .compose(
        'AttendanceStore',
        UsesBluebicStorePattern,
        types
            .model({
                attendances: types.map(Attendance)
            })
            .views(self => ({
                get service() {
                    return self.bluebic.attendanceService
                },
            }))
            .actions(self => {

                function updateAttendances(attendances) {
                    attendances.forEach(event => self.attendances.put(event))
                }
                
                function markAttendanceRecordSaving(attendanceFormStore, flag){
                    self.attendances.forEach(attendance => {
                        const {attributes: {date, student_id}} = attendance
                        const { date: formDate, student_id: formStudentID} = attendanceFormStore
                        if (date === formDate && student_id === formStudentID){
                            attendance.isSaving = flag
                        }
                    })
                }

                const updateAttendance = flow(function* updateAttendance(attendance) {
                    try {
                        markAttendanceRecordSaving(attendance, true)
                        const { data } = yield self.bluebic.attendanceService.update(attendance)
                        self.attendances.put(data)
                        markAttendanceRecordSaving(attendance, false)
                        return { data }
                    } catch (err) {
                        markAttendanceRecordSaving(attendance, false)
                        console.error('Failed to update attendance', err)
                        return validationErrorsHandler(err)
                    }
                })
                
                function onUpdate(included) {
                    filterType('attendance', included, updateAttendances)
                }
                
                const deleteAttendanceById = flow(function* deleteAttendanceById(id) {
                    try {
                        const storeRecord = self.attendances.get(id)
                        storeRecord.isSaving = true
                        const response = yield self.bluebic.attendanceService.destroy(id)
                        storeRecord.isSaving = false
                        destroy(storeRecord)
                        return {response}
                    } catch (err) {
                        console.error("Failed to delete attendance", err)
                        self.attendances.get(id).isSaving = false
                        return false
                    }
                })
                
                const downloadSpreadsheet = flow(function* downloadSpreadsheet(batch_id, action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadSpreadsheet(batch_id, action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                    } catch (err) {
                        action.markSaving(false)
                        console.error(`Failed to initiate Attendance spreadsheet download for Batch ${batch_id}`, err)
                    }
                })
                
                return {
                    onUpdate,
                    downloadSpreadsheet,
                    updateAttendance,
                    deleteAttendanceById
                }
            }),
    )