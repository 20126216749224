import './StudentAssessmentList.css'

import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import {onPatch} from "mobx-state-tree";
import numeral from "numeral";
import {Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer,} from 'recharts';

import Card from '../common/card'
import Mobile from '../common/display/Mobile'
import NotMobile from '../common/display/NotMobile'
import SelectInput from '../common/form/SelectInput'
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup'
import AttendanceReportLoading from '../common/loading/AttendanceReportLoading'
import {formatDate} from "../lib/formatDate";
import Pagination from "../common/pagination/Pagination";
import Table from "../common/table";
import EmptyList from "../common/empty_list/empty_list";
import MobileListLoading from "../common/loading/MobileListLoading";
import {scrollToTop} from "../lib/scrollToTop";
import {debounce} from "../lib/debounce";
import StudentPerformanceChart from "./StudentPerformanceChart";
import icon from "../dashboard/assets/analysis.svg";

const termOptions = [
    { label: 'All', value: undefined },
    { label: '1st Term', value: 1 },
    { label: '2nd Term', value: 2 },
    { label: '3rd Term', value: 3 }
]


@inject('store')
@observer
class StudentAssessmentList extends React.Component {

    get academicSessionOptions() {
        const { store: { view: {selectedStudent} } } = this.props
        const academicSessionOptions = []
        if (selectedStudent.isInitialized){
            const { academic_session_history: academicSessions } = selectedStudent.attributes
            academicSessions.forEach(({ id, attributes }) => {
                academicSessionOptions.push({ text: attributes.name, value: id })
            })
            
        }
        return academicSessionOptions
    }

    handleTermSelect = (selectedOption) => {
        const { store: { view: {selectedStudent} } } = this.props
        const { assessmentSearchInstance } = selectedStudent
        assessmentSearchInstance.setAcademicTerm(selectedOption)
    }

    handleSessionSelect = (selectedOption) => {
        const { store: { view: {selectedStudent} } } = this.props
        const { assessmentSearchInstance } = selectedStudent
        assessmentSearchInstance.setAcademicSessionId(selectedOption)
    }

    render() {
        const { store: {view: {selectedStudent}} } = this.props
        const { assessmentSearchInstance, assessmentSearchResult, assessmentsMeta } = selectedStudent
        
        return (
            <>
                <Card
                    title="Academic Session"
                    style={{ marginBottom: 10 }}
                >
                    <div className="StudentAttendanceReport">
                        <SelectInput
                            defaultOptionText="All Sessions"
                            value={assessmentSearchInstance.academic_session_id}
                            onChange={this.handleSessionSelect}
                            placeholder="Select Session"
                            options={this.academicSessionOptions}
                        />
                        <SelectButtonGroup
                            selectOptions={termOptions}
                            onChange={this.handleTermSelect}
                            value={assessmentSearchInstance.academic_term}
                        />
                    </div>
                </Card>

                <StudentAssessmentReport searchInstance={assessmentSearchInstance} searchResult={assessmentSearchResult} searchResultMeta={assessmentsMeta} />
            </>
        )
    }
}


const columns = [
    { key: 'name', render: 'Name' },
    { key: 'subject', render: 'Subject' },
    { key: 'category', render: 'Category' },
    { key: 'due_date', render: 'Due Date' },
    { key: 'score', render: 'Mark Given' }
]


const commentIcon = <i style={{color: 'hsla(0,0%,74%,1)', fontSize: '14px', paddingLeft: '2px'}} className="material-icons">message</i>

@inject('store')
@observer
class StudentAssessmentReport extends React.Component {
    
    componentDidMount() {
        const { store: { view: {selectedStudent} } } = this.props
        const { searchAssessments, assessmentSearchInstance } = selectedStudent
        searchAssessments()
        onPatch(assessmentSearchInstance, debounce((patch) => {
            if (patch.path.startsWith("/client_state/")) return
            if (patch.path !== "/page") assessmentSearchInstance.goToFirstPage()
            scrollToTop()
            searchAssessments()
        }, 250))
    }

    get listSummary() {
        const {store: {view :{selectedStudent}}} = this.props
        const { assessmentsMeta, assessmentSearchResult, assessmentSearchInstance } = selectedStudent

        const totalCount = assessmentsMeta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = assessmentSearchInstance.per_page * assessmentSearchInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - assessmentSearchResult.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }


    render() {
        const {store: {view :{openAssessmentPageById}}} = this.props
        const { searchResultMeta: {statistics: reportData, total_pages}, searchInstance, searchResult } = this.props
        const {isLoading, page} = searchInstance
        
        if ((isLoading && isEmpty(reportData)) || isEmpty(reportData)) return <AttendanceReportLoading />

        const {line_chart_data, radar_chart_data, overall_average_percentage} = reportData


        const attendanceReportClassName = classNames('AttendanceReport', {'AttendanceReport-loading': isLoading})


        const tableData = searchResult.map((assessmentScore) => {
            const {assessment, attributes: {exempt, score, collected, missing, late, comment}} = assessmentScore
            const {
                id: assessmentID,
                describePoints,
                assessmentCategoryName,
                assessmentCategoryAbbreviation,
                subject,
                attributes: {due_date, name, publish_scores, description, include_in_final_grade}
            } = assessment
            
            const {attributes: {base_name, base_short_name}} = subject
            

            const columnDetails = !publish_scores ? (<span title='Not Published'>NP</span>) :
                (
                    <>
                        {exempt && <span title='Student Exempted.'>EX</span>}
                        {!exempt && `${score || ''}/${describePoints}`}
                        {!exempt && (late || collected || missing) && (
                            <div className='badgeWrapper'>
                                {(collected) && (<div title='Collected' className='numberCircle collected'>C</div>)}
                                {(late) && (<div title='Late' className='numberCircle late'>L</div>)}
                                {(missing) && (<div title='Missing' className='numberCircle missing'>M</div>)}
                            </div>
                        )}
                        {comment && <sup title={comment}>{commentIcon}</sup>}
                    </>
                )
            
            return {
                name: (
                    <a
                        href={`/assessments/${assessmentID}`}
                        onClick={(e) => {
                            e.preventDefault()
                            openAssessmentPageById(assessmentID)
                        }}
                    >
                        {name}
                    </a>
                ),
                points: describePoints,
                due_date: formatDate(due_date),
                subject: base_name,
                category: assessmentCategoryName,
                include_in_final_grade: include_in_final_grade ? "YES" : "NO",
                score: columnDetails,
                id: assessmentScore.id
            }
        })

        const pagination = (
            <Pagination
                pageCount={total_pages}
                currentPage={page}
                onClickPrev={searchInstance.prevPage}
                onClickNext={searchInstance.nextPage}
            />
        )
        
        const transformedRadarData = radar_chart_data.map((row) => {
            const numeralAmount = numeral(row.percentage)
            return {subject_name: row.short_name, percentage_value: numeralAmount.value()}
        })
        
        return (
            <>

                <div id="studentAssessmentChartReports">
                    <div className="studentPerformanceRadar">
                        <div className="flex-row">
                            <img className="dashboard-icon" src={icon} alt="" />
                            <span className="text-small text--uppercase">Performance Radar</span>
                        </div>
                        <Card>
                            <ResponsiveContainer height={280}>
                                <RadarChart cx={140} cy={125} outerRadius={80} width={300} height={312} data={transformedRadarData}>
                                    {!isEmpty(transformedRadarData) && <PolarGrid />}
                                    <PolarAngleAxis dataKey="subject_name" />
                                    <PolarRadiusAxis domain={[0, 100]} />
                                    <Radar name="Student Name" dataKey="percentage_value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                </RadarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>

                    <StudentPerformanceChart chartData={line_chart_data} />
                </div>
                
                <div className="StudentSubjectAssessment">

                    <NotMobile>
                        <div className="flex-row flex-spaceBetween">
                            {this.listSummary}
                        </div>
                        <Table
                            striped
                            containerClass="Card"
                            columns={columns}
                            data={tableData}
                            onError={e => console.error(e)}
                            loading={isLoading}
                            footer={pagination}
                            responsive
                        />
                    </NotMobile>

                    <Mobile>
                        {!isLoading && ( searchResult.length === 0) && <EmptyList />}

                        {(isLoading || searchResult.length > 0) && (
                            <>
                                <div className="flex-row flex-spaceBetween">
                                    {this.listSummary}
                                </div>
                                <MobileListLoading isLoading={isLoading}>
                                    {tableData.map(assessmentData => <AssessmentItem key={assessmentData.id} assessmentData={assessmentData} />)}
                                </MobileListLoading>
                                {pagination}
                            </>
                        )}
                    </Mobile>
                </div>
            </>
            
        )
    }
    
}

export default StudentAssessmentList

@observer
class AssessmentItem extends React.Component {
    render() {
        const { assessmentData } = this.props
        const  {name, points, subject, due_date, category, include_in_final_grade, score, id} = assessmentData

        return (
            <Card className="StudentSubjectAssessmentItem mb-1">
                <div>
                    {name}
                </div>
                <div>
                    <span>Subject</span>
                    {subject}
                </div>
                <div className="assCatBlock">
                    <span>Category</span>
                    {category}
                </div>
                <div>
                    <span>Due Date</span>
                    {due_date}
                </div>
                <div>
                    <span>Mark Given</span>
                    {score}
                </div>
            </Card>
        )
    }
}