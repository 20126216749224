import { types } from "mobx-state-tree"

export const CustomPaymentMethod = types
    .model("CustomPaymentMethod", {
        id: types.identifier,
        type: types.literal("custom_payment_method"),
        attributes: types.maybe(types.model({
            name: "",
            description: types.maybeNull(types.string),
            is_incoming: types.boolean,
        })),
    })