import {types, flow, destroy} from "mobx-state-tree";
import { Fee } from "./models/Fee";
import { FeeSearch } from "./models/FeeSearch";
import { CollectionPattern } from "./patterns/collectionPattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";
import { LoadingPattern } from "./patterns/LoadingPattern";
import {StudentFee} from "./models/StudentFee";

export const FeeStore = types
    .compose(
        CollectionPattern({
            collectionType: Fee,
            searchType: FeeSearch
        }),
        LoadingPattern({
            createFeeLoading: false,
            deleteFeeLoading: false
        }),

        types
            .model("FeeStore", {
                studentFees: types.map(StudentFee),
            })
            .views(self => ({
                get searchService() {
                    return self.bluebic.feeService.search
                },
            }))
            .actions(self => {
                
                function updateStudentFees(fees) {
                    fees.forEach(json => self.studentFees.put(json))
                }

                function onUpdate(included) {
                    filterType("student_fee", included, updateStudentFees)
                    filterType("fee", included, self.updateCollection)
                }
                
                function markFeeLoadingById(id, flag) {
                    if (!self.items.has(id)) {
                        self.items.put({ id })
                    }
                    self.items.get(id).isLoading = flag
                }

                const createFee = flow(function* createFee(fee) {
                    try {
                        self.markLoading("createFeeLoading", true)
                        const { data } = yield self.bluebic.feeService.createFee(fee)
                        self.putItem(data)
                        self.searchResults.unshift(data.id)
                        self.markLoading("createFeeLoading", false)
                        return { data }
                    } catch (err) {
                        self.markLoading("createFeeLoading", false)
                        console.error("Failed to create fee", err)
                        return validationErrorsHandler(err)
                    }
                })
                const loadFeeById = flow(function* loadFeeById(id){
                    try{
                        markFeeLoadingById(id,true)
                        const { data, included } = yield self.bluebic.feeService.showFee(id)
                        self.bluebic.handleUpdateStores(included)
                        self.items.put(data)
                        markFeeLoadingById(id,false)
                        return { data }
                    } catch(err) {
                        markFeeLoadingById(id,false)
                        console.error(`Failed to Load Fee with ${id}`, err)
                        return validationErrorsHandler(err)
                    }
                })
                const assignFeeToSeletedStudents = flow(function* assignFeeToSeletedStudents(id,student_ids) {
                    try {
                        markFeeLoadingById(id,true)
                        const { data, included } = yield self.bluebic.feeService.assignFee(id,student_ids)
                        self.bluebic.handleUpdateStores(included)
                        self.items.put(data)
                        markFeeLoadingById(id,false)
                        return { data }
                    } catch (err) {
                        markFeeLoadingById(id,false)
                        console.error("Failed to Assifgn Fee to Students", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const addDiscount = flow(function* addDiscount(discounts, student_fee_id, id){
                    try{
                        markFeeLoadingById(id, true)
                        const { data, included } = yield self.bluebic.feeService.addDiscount(discounts, student_fee_id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markFeeLoadingById(id, false)
                        return { data }
                    } catch (err) {
                        markFeeLoadingById(id, false)
                        console.error(`Failed to Add Student Fee Discounts with ${student_fee_id}`, err)
                        return validationErrorsHandler(err)
                    }
                })
                const deleteDiscountbyId = flow(function* deleteDiscountbyId(selected_fee_id, id) {
                    try {
                        const fee = self.items.get(selected_fee_id)
                        markFeeLoadingById(selected_fee_id, true)
                        yield self.bluebic.feeService.deleteDiscountbyId(id)
                        fee.studentFees.map((elem)=> elem.removeStudentFeeDiscount(id))
                        markFeeLoadingById(selected_fee_id, false)
                        return {}
                    } catch (err) {
                        markFeeLoadingById(selected_fee_id, false)
                        console.error(`Failed to delete Student Discount with ${id}`, err)
                        return validationErrorsHandler(err)
                    }
                })
                const UnassignStudentFee = flow(function* UnassignStudentFee(selected_fee_id, id) {
                    try {
                        const fee = self.items.get(selected_fee_id)
                        markFeeLoadingById(selected_fee_id, true)
                        yield self.bluebic.feeService.UnassignStudentFee(id)
                        fee.removeStudentFee(id)
                        markFeeLoadingById(selected_fee_id, false)
                        return {}
                    } catch (err) {
                        markFeeLoadingById(selected_fee_id, false)
                        console.error(`Failed to delete Student Discount with ${id}`, err)
                        return validationErrorsHandler(err)
                    }
                })

                const deleteFeeById = flow(function* deleteFeeById(id) {
                    try {
                        self.markLoading("deleteFeeLoading", true)
                        yield self.bluebic.feeService.deleteFeeById(id)
                        destroy(self.items.get(id))
                        self.bluebic.view.openFeesPage()
                        self.markLoading("deleteFeeLoading", false)
                    } catch (err) {
                        console.error("Failed to delete fee", err)
                        self.markLoading("deleteFeeLoading", false)
                        return validationErrorsHandler(err)
                    }
                })

                const editFee = flow(function* editFee(fee, id) {
                    try {
                        markFeeLoadingById(id, true)
                        const { data, included } = yield self.bluebic.feeService.editFee(fee, id)
                        self.bluebic.handleUpdateStores(included)
                        self.items.put(data)
                        markFeeLoadingById(id, false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to edit fee", err)
                        markFeeLoadingById(id, false)
                        return validationErrorsHandler(err)
                    }
                })
                

                return {
                    onUpdate,
                    createFee,
                    editFee,
                    loadFeeById,
                    assignFeeToSeletedStudents,
                    addDiscount,
                    deleteDiscountbyId,
                    UnassignStudentFee,
                    deleteFeeById
                }
            })
    )
