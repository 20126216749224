// https://stackoverflow.com/questions/21474678/scrolltop-animation-without-jquery


// FIXME: doesn't seem to work
export function scrollToTop(scrollDuration = 0) {
    const cosParameter = window.scrollY / 2;
    let scrollCount = 0;
    let oldTimestamp = performance.now();
    function step(newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}


// https://gist.github.com/joshcanhelp/a3a669df80898d4097a1e2c01dea52c1
/*

export function scrollToLocation(scrollToOffset, scrollDuration = 1000) {

    //
    // Set a default for where we're scrolling to
    //

    if (typeof scrollToOffset === 'string') {
        // Assuming this is a selector we can use to find an element
        const scrollToObj = document.querySelector(scrollToOffset);

        if (scrollToObj && typeof scrollToObj.getBoundingClientRect === 'function') {
            scrollToOffset = window.pageYOffset + scrollToObj.getBoundingClientRect().top;
        } else {
            throw `error: No element found with the selector "${  scrollToOffset  }"`;
        }
    } else if (typeof scrollToOffset !== 'number') {

        // If it's nothing above and not an integer, we assume top of the window
        scrollToOffset = 0;
    }

    // Set this a bit higher

    const anchorHeightAdjust = 30;
    if (scrollToOffset > anchorHeightAdjust) scrollToOffset -= anchorHeightAdjust;
    
    // Declarations

    const cosParameter = (window.pageYOffset - scrollToOffset) / 2;
    let scrollCount = 0;
    let oldTimestamp = window.performance.now();

    function step(newTimestamp) {
        let tsDiff = newTimestamp - oldTimestamp;


        // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
        // on the first go-through than we want so I'm adjusting the difference down here.
        // Regardless, we would rather have a slightly slower animation than a big jump so a good
        // safeguard, even if we're not using the polyfill.

        if (tsDiff > 100) tsDiff = 30

        scrollCount += Math.PI / (scrollDuration / tsDiff);

        // As soon as we cross over Pi, we're about where we need to be
        if (scrollCount >= Math.PI) return

        const moveStep = Math.round(scrollToOffset + cosParameter + cosParameter * Math.cos(scrollCount));
        window.scrollTo(0, moveStep);
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
};

*/
