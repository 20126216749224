import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform';
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/fees_list.json'
import createFeeJSON from '../json/create_fee_success.json'
import showFeeDiscountsJSON from '../json/show_fee_discounts.json'
import deleteFeeJSON from '../json/delete_fee_success'
import  EditFeeJSON from '../json/edit_fee_success';

export function FeeService(env) {
    async function search(fee_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("fee_search", fee_search)
            const { data } = await axios.get(`/api/v1/finance/fees?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function createFee(fee) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/fees`, { fee })
            return data
        }
        return createFeeJSON
    }
    async function showFee(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/finance/fees/${id}/`)
            return data
        }
        return showFeeJSON
    }
    async function assignFee(id,student_ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/fees/${id}/assign_to_students`, { student_ids })
            return data
        }
        return showFeeJSON
    }
    async function addDiscount(discount, student_fee_id) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/student_fees/${student_fee_id}/discounts`, {discount})
            return data
        }
        return showFeeDiscountsJSON
    }
    async function deleteDiscountbyId(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/finance/student_fees/discounts/${id}`)
            return data
        }
        return showFeeJSON
    }
    async function UnassignStudentFee(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/finance/student_fees/${id}`)
            console.log(data)
            return data
        }
        return showFeeJSON
    }
         
    async function deleteFeeById(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/finance/fees/${id}`)
            return data
        }
        return deleteFeeJSON
    }

    async function editFee(fee, id) {
        if (env !== TESTENV) {
            const { data } = await axios.put(`/api/v1/finance/fees/${id}`,
                { fee },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return EditFeeJSON
    }
    
    return {
        createFee,
        editFee,
        search,
        showFee,
        assignFee,
        addDiscount,
        deleteDiscountbyId,
        UnassignStudentFee,
        deleteFeeById
    }
}