import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform";
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import SelectInput from '../common/form/SelectInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import { Batch } from "../store/actions/Batch";

const form = new Form(Batch, {
    course_id: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    academic_session_id: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    arm: new Field(converters.string, { required: true }),
})

@inject('store', 'display')
@observer
class CreateBatchForm extends React.Component {

    componentWillMount() {
        const { batchInfo, editMode, store: { batchStore: {createBatch, updateBatch} } } = this.props;

        if (editMode && batchInfo) {
            const formInstance = Batch.create({})
            formInstance.setFormInstance(batchInfo)
            this.formState = form.state(formInstance, {
                addMode: false,
                save: async (node) => {
                    const { errors } = await updateBatch(batchInfo.id, node.toJSON())
                    return errors
                }
            })
        }else {
            const formInstance = Batch.create({})
            this.formState = form.state(formInstance, {
                addMode: true,
                save: async (node) => {
                    const { errors } = await createBatch(node.toJSON())
                    return errors
                }
            })
        }
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const { editMode, closeModal, store } = this.props
        const { courseStore: { courses }, academicSessions, batchStore: { createBatchLoading } } = store
        const course_id = this.formState.field("course_id")
        const academic_session_id = this.formState.field("academic_session_id")
        const arm = this.formState.field("arm")

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={createBatchLoading ? "Please wait..." : "Save Batch"}
                isOkButtonDisabled={!this.formState.isValid || createBatchLoading}
            >
                <Label text="Arm" required={arm.required}>
                    <InlineError field={arm}>
                        <TextInput {...arm.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Course" required={course_id.required}>
                    <InlineError field={course_id}>
                        <SelectInput
                            {...course_id.inputProps}
                            options={mapModelNameToOptions(courses)}
                            placeholder="Select course"
                            disabled={editMode}
                        />
                    </InlineError>
                </Label>
                <Label text="Academic Session" required={academic_session_id.required}>
                    <InlineError field={academic_session_id}>
                        <SelectInput
                            {...academic_session_id.inputProps}
                            options={mapModelNameToOptions(academicSessions)}
                            placeholder="Select academic session"
                            disabled={editMode}
                        />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}

export default CreateBatchForm