import { reaction } from "mobx"
import { types, getParent } from "mobx-state-tree"
import isEmpty from "lodash.isempty"
import StudentPolicy from "../policies/StudentPolicy";
import SubjectPolicy from "../policies/SubjectPolicy";
import BatchPolicy from "../policies/BatchPolicy";
import DashboardPolicy from "../policies/DashboardPolicy";

export const ViewStore = types
    .model({
        page: "login",
        nextPage: "/",
        templateVariant: "html",
        selectedUserId: "",
        selectedStudentId: "",
        selectedGuardianId: "",
        selectedEmployeeId: "",
        selectedFinanceTransactionId: "",
        selectedFeeId: "",
        selectedSubjectId: "",
        selectedBatchId: "",
        selectedEventId: "",
        selectedAssessmentId: "",
        selectedReportCardGroupId: "",
        selectedMessageId: "",
        backPage: types.maybe(types.model({
            title: "Back",
            page: types.string
        }))
    })
    .views(self => ({
        get bluebic() {
            return getParent(self)
        },
        get isLoading() {
            return self.bluebic.isLoading
        },
        get currentUrl() {
            switch (self.page) {
                case "dashboard":
                    return "/dashboard"
                case "user":
                    return `/users/${self.selectedUserId}`
                case "students":
                    return "/students"
                case "student":
                    return `/students/${self.selectedStudentId}`
                case "students/add":
                    return `/students/add`
                case "students/edit":
                    return `/students/edit/${self.selectedStudentId}`
                case "studentPayFees":
                    return `/students/${self.selectedStudentId}/pay-fees`
                case "studentReportCard":
                    return `/student-report-card/${self.selectedStudentReportCardId}`
                case "batches":
                    return "/batches"
                case "batch":
                    return `/batches/${self.selectedBatchId}`
                case "guardians":
                    return "/guardians"
                case "guardian":
                    return `/guardians/${self.selectedGuardianId}`
                case "employees":
                    return "/employees"
                case "employee":
                    return `/employees/${self.selectedEmployeeId}`
                case "employees/add":
                    return `/employees/add`
                case "employees/edit":
                    return `/employees/edit/${self.selectedEmployeeId}`
                case "login":
                    return `/login?next=${self.nextPage}`
                case "finance/transactions":
                    return "/finance/transactions"
                case "finance/transaction":
                    return `/finance/transactions/${self.selectedFinanceTransactionId}`
                case "finance/fee-transactions":
                    return "/finance/fee-transactions"
                case "finance/custom-transactions":
                    return "/finance/custom-transactions"
                case "finance/expense-account-transactions":
                    return "/finance/expense-account-transactions"
                case "finance/fees":
                    return "/finance/fees"
                case "finance/fee":
                    return `/finance/fees/${self.selectedFeeId}`
                case "subjects":
                    return "/subjects"
                case "subject":
                    return `/subjects/${self.selectedSubjectId}`
                case "events/edit":
                    return `/events/edit/${self.selectedEventId}`
                case "events/new":
                    return `/events/new`
                case "events":
                    return `/events`
                case "event":
                    return `/events/${self.selectedEventId}`
                case "assessment":
                    return `/assessments/${self.selectedAssessmentId}`
                case "report-cards":
                    return `/report-cards`
                case "report-card":
                    return `/report-cards/${self.selectedReportCardGroupId}`;
                case "messages/sms-topups":
                    return "messages/sms-topups"
                case "messages":
                    return `/messages`
                case "message":
                    return `/messages/${self.selectedMessageId}`;
                case "settings/school-details":
                    return "/settings/school-details";
                case "settings/academic-sessions":
                    return "/settings/academic-sessions";
                case "settings/classes":
                    return "/settings/classes";
                case "settings/class-sets":
                    return "/settings/class-sets";
                case "settings/subject-names":
                    return "/settings/subject-names";
                default:
                    return "/404"
            }
        },
        
        get selectedUser() {
            return self.isLoading || !self.selectedUserId
                ? null : self.bluebic.users.get(self.selectedUserId)
        },
        
        get selectedStudent() {
            return self.isLoading || !self.selectedStudentId
                ? null
                : self.bluebic.students.get(self.selectedStudentId)
        },
        get selectedGuardian() {
            return self.isLoading || !self.selectedGuardianId
                ? null
                : self.bluebic.guardians.get(self.selectedGuardianId)
        },
        get selectedBatch() {
            return self.isLoading || !self.selectedBatchId
                ? null
                : self.bluebic.batches.get(self.selectedBatchId)
        },
        get selectedEmployee() {
            return self.isLoading || !self.selectedEmployeeId
                ? null
                : self.bluebic.employees.get(self.selectedEmployeeId)
        },
        get selectedEvent() {
            return self.isLoading || !self.selectedEventId
                ? null
                : self.bluebic.events.get(self.selectedEventId)
        },
        get selectedMessage() {
            return self.isLoading || !self.selectedMessageId
                ? null
                : self.bluebic.messages.get(self.selectedMessageId)
        },
        get selectedAssessment() {
            return self.isLoading || !self.selectedAssessmentId
                ? null
                : self.bluebic.subjectStore.assessments.get(self.selectedAssessmentId)
        },
        get selectedFinanceTransaction() {
            return self.isLoading || !self.selectedFinanceTransactionId
                ? null
                : self.bluebic.financeTransactions.get(self.selectedFinanceTransactionId)
        },
        get selectedFee() {
            return self.isLoading || !self.selectedFeeId
                ? null
                : self.bluebic.fees.get(self.selectedFeeId)
        },
        get selectedSubject() {
            return self.isLoading || !self.selectedSubjectId
                ? null
                : self.bluebic.subjects.get(self.selectedSubjectId)
        },
        get selectedReportCardGroup() {
            return self.isLoading || !self.selectedReportCardGroupId
                ? null
                : self.bluebic.reportCardGroupStore.reportCardGroups.get(self.selectedReportCardGroupId)
        },
        get isSettingsPage() {
            return String(self.currentUrl).includes('/settings/')
        },
        userablePageAddressFromUser(user) {
            if (user.is_employee){
                return `/employees/${user.userable.id}`
            }
            if (user.is_guardian){
                return `/guardians/${user.userable.id}`
            }
            if (user.is_student){
                return `/students/${user.userable.id}`
            }
            return undefined
        },
    }))
    .actions(self => ({
        afterCreate() {
            reaction(
                () => self.page,
                currentPage => {
                    if (currentPage !== 'login' && !self.bluebic.isUserLoggedIn) {
                        self.openLoginPage(currentPage)
                    }
                }
            )
        },
        openDefaultPage(){
            const {currentUser} = self.bluebic.meta
            if (isEmpty(currentUser)) return
            
            const studentPolicy = new StudentPolicy(currentUser, 'Student')
            const subjectPolicy = new SubjectPolicy(currentUser, 'Subject')
            const batchPolicy = new BatchPolicy(currentUser, 'Batch')
            const dashboardPolicy = new DashboardPolicy(currentUser, 'Dashboard')
            
            
            if (currentUser.is_guardian){
                self.openUserablePageForUser(currentUser)
            }else if(currentUser.is_student){
                self.openUserablePageForUser(currentUser)
            }else if (dashboardPolicy.can_show) {
                    self.openDashboardPage()
                }else if (studentPolicy.can_index){
                    self.openStudentsPage()
                }else if (batchPolicy.can_index){
                    self.openBatchesPage()
                }else if (subjectPolicy.can_index){
                    self.openSubjectsPage()
                }else {
                    self.openEmployeePage(currentUser.userable)
                }
        },
        openDashboardPage(){
            self.openPage("dashboard")
        },
        openUserPage(user) {
            self.selectedUserId = user.id
            self.openPage(self.getUserPage())
        },
        openUserPageById(id) {
            self.openPage(self.getUserPage())
            self.selectedUserId = id
        },
        getUserPage() {
            return "user"
        },
        openStudentsPage() {
            self.selectedStudentId = ""
            self.openPage("students")
        },
        getStudentPage() {
            return "student"
        },
        openStudentPage(student) {
            self.openPage(self.getStudentPage())
            self.selectedStudentId = student.id
        },
        openStudentPageById(id) {
            self.openPage(self.getStudentPage())
            self.selectedStudentId = id
        },
        openStudentPayFeesPageById(id) {
            self.openPage("studentPayFees")
            self.selectedStudentId = id
        },
        openStudentReportCardPageById(id) {
            self.openPage("studentReportCard")
            self.selectedStudentReportCardId = id
        },
        openAddStudentPage() {
            self.openPage("students/add")
        },
        openEditStudentPage(student) {
            self.openPage("students/edit")
            self.selectedStudentId = student.id
        },
        openEditStudentPageById(id) {
            self.openPage("students/edit")
            self.selectedStudentId = id
        },
        openBatchesPage() {
            self.selectedBatchId = ""
            self.openPage("batches")
        },
        openBatchPageById(id) {
            self.selectedBatchId = id
            self.openPage("batch")
        },
        openGuardiansPage() {
            self.selectedGuardianId = ""
            self.openPage("guardians")
        },
        openGuardianPage(guardian) {
            self.selectedGuardianId = guardian.id
            self.openPage("guardian")
        },
        openGuardianPageById(id) {
            self.selectedGuardianId = id
            self.openPage("guardian")
        },
        openEmployeesPage() {
            self.selectedEmployeeId = ""
            self.openPage("employees")
        },
        openEmployeePage(employee) {
            self.selectedEmployeeId = employee.id
            self.openPage("employee")
        },
        openEmployeePageById(id) {
            self.selectedEmployeeId = id
            self.openPage("employee")
        },
        openAddEmployeePage() {
            self.openPage("employees/add")
        },
        openEditEmployeePage(employee) {
            self.openPage("employees/edit")
            self.selectedEmployeeId = employee.id
        },
        openEditEmployeePageById(id) {
            self.openPage("employees/edit")
            self.selectedEmployeeId = id
        },
        openSubjectsPage() {
            self.selectedSubjectId = ""
            self.openPage("subjects")
        },
        getMessagePage(message_id) {
            return `messages/${message_id}`
        },
        getMessagesPage() {
            return `messages`
        },
        openMessagePageById(id) {
            self.selectedMessageId = id
            self.openPage("message")
        },
        openMessagesPage() {
            self.selectedMessageId = ""
            self.openPage("messages")
        },
        openMessagePage(message) {
            self.selectedMessageId = message.id
            self.openPage("message")
        },
        openSMSTopupsPage() {
            self.openPage("messages/sms-topups")
        },
        openSubjectPageById(id) {
            self.selectedSubjectId = id
            self.openPage("subject")
        },
        openLoginPage(nextPage) {
            if (self.bluebic.isUserLoggedIn) {
                self.openPage("students")
            } else {
                self.nextPage = nextPage || self.nextPage
                self.openPage("login")
            }
        },
        openNextPage() {
            if (self.nextPage) self.openPage(self.nextPage)
            self.openDefaultPage()
        },
        getFinanceTransactionsPage() {
            return "finance/transactions"
        },
        openFinanceTransactionsPage() {
            self.openPage(self.getFinanceTransactionsPage())
        },
        openFinanceTransactionPage(financeTransaction) {
            self.selectedFinanceTransactionId = financeTransaction.id
            self.openPage("finance/transaction")
        },
        openFinanceTransactionA4PageById(id) {
            self.selectedFinanceTransactionId = id
            self.openPage("finance/transaction", 'html+A4')
        },
        openFinanceTransactionPOSPageById(id) {
            self.selectedFinanceTransactionId = id
            self.openPage("finance/transaction", 'html+POS')
        },
        openFinanceTransactionPageById(id) {
            self.selectedFinanceTransactionId = id
            self.openPage("finance/transaction")
        },
        getFeeTransactionsPage() {
            return "finance/fee-transactions"
        },
        openFeeTransactionsPage() {
            self.openPage(self.getFeeTransactionsPage())
        },
        getCustomTransactionsPage() {
            return "finance/custom-transactions"
        },
        openCustomTransactionsPage() {
            self.openPage(self.getCustomTransactionsPage())
        },
        getExpenseAccountTransactionsPage() {
            return "finance/expense-account-transactions"
        },
        openExpenseAccountTransactionsPage() {
            self.openPage(self.getExpenseAccountTransactionsPage())
        },
        getFeePageById(fee_id) {
            return `finance/fees/${fee_id}`
        },
        getFeesPage() {
            return "finance/fees"
        },
        openFeesPage() {
            self.openPage(self.getFeesPage())
        },
        openFeePageById(id) {
            self.selectedFeeId = id
            self.openPage("finance/fee")
        },
        setBackPage(title, page) {
            self.backPage = { title, page }
        },
        openPage(page, templateVariant = 'html' ) {
            // destroy(self.backPage)
            self.page = page
            self.templateVariant = templateVariant
        },
        openEventsPage() {
            self.openPage('events')
        },
        openAddEventPage() {
            self.openPage("events/new")
        },
        openEditEventPage(event) {
            self.selectedEventId = event.id
            self.openPage("events/edit")
        },
        openEditEventPageById(id) {
            self.selectedEventId = id
            self.openPage("events/edit")
        },
        getEventPage(event_id) {
            return `event/${event_id}`
        },
        openEventPage(event) {
            self.selectedEventId = event.id
            self.openPage("event")
        },
        openEventPageById(id) {
            self.selectedEventId = id
            self.openPage("event")
        },
        openAssessmentPageById(id) {
            self.selectedAssessmentId = id
            self.openPage("assessment")
        },
        getReportCardGroupPage(group_id) {
            return `report-cards/${group_id}`
        },
        openReportCardGroupsPage() {
            self.openPage("report-cards")
        },
        openReportCardGroupPage(group) {
            self.selectedReportCardGroupId = group.id
            self.openPage("report-card")
        },
        openReportCardGroupPageById(id) {
            self.selectedReportCardGroupId = id
            self.openPage("report-card")
        },
        getUserablePageForUser(user){
            if (user.is_employee){
                return `employees/${user.attributes.userable_id}`
            }else if (user.is_guardian){
                return `guardians/${user.attributes.userable_id}`
            }else if (user.is_student){
                return `students/${user.attributes.userable_id}`
            }else if (user.is_admin){
                return `users/${user.id}`
            }
        },
        openUserablePageForUser(user) {
            if (user.is_employee){
                self.openEmployeePage(user.userable)
            }else if (user.is_guardian){
                self.openGuardianPage(user.userable)
            }else if (user.is_student){
                self.openStudentPage(user.userable)
            }else if (user.is_admin){
                self.openUserPage(user)
            }
        },
        openSchoolDetailsPage() {
            self.openPage('settings/school-details')
        },
        openAcademicSessionsPage() {
            self.openPage('settings/academic-sessions')
        },
        openClassesPage() {
            self.openPage('settings/classes')
        },
        openClassSetsPage() {
            self.openPage('settings/class-sets')
        },
        openSubjectNamesPage() {
            self.openPage('settings/subject-names')
        },
    }))
