import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Script from 'react-load-script'
import { controlled, converters, Field, Form, SubForm } from 'mstform'
import { Col, Row } from 'antd'
import classNames from "classnames";
import isEmpty from "lodash.isempty"
import * as ReactGA from "react-ga";

import MaybeBackPage from '../containers/MaybeBackPage'
import Card from '../common/card'
import Desktop from '../common/display/Desktop'
import NotDesktop from '../common/display/NotDesktop'
import MobilePageHeader from '../containers/MobilePageHeader'
import { titleCase } from '../lib/titleCase'
import '../finance/TransactionReceipt.css'

import Label from '../common/form/Label'
import InlineError from '../common/form/InlineError'
import TextInput from '../common/form/TextInput'
import DateInput from '../common/form/DateInput'
import SelectInput from '../common/form/SelectInput'
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions'
import MultilineInput from '../common/form/MultilineInput'
import Button from '../common/button'
import { StudentTransaction } from '../store/actions/StudentTransaction'

import './TransactionPreflightForm.css'


const form = new Form(StudentTransaction, {
    transaction_date: new Field(converters.string, { controlled: controlled.object, required: false }),
    note: new Field(converters.maybe(converters.string)),
    bank_teller: new SubForm({
        bank_account_id: new Field(converters.maybe(converters.string), {controlled: controlled.object}),
        amount: new Field(converters.maybe(converters.decimal({maxWholeDigits:10, decimalPlaces:2, allowNegative:false}))),
        teller_no: new Field(converters.maybe(converters.string)),
        date: new Field(converters.maybe(converters.string), {controlled: controlled.object})
    })
});

const feeTransactionsTableColumns = [
    { key: 'fee_type_name', render: 'Fee Type' },
    { key: 'fee_description', render: 'Description' },
    { key: 'charged', render: 'Charged' },
    { key: 'transaction_amount', render: 'To Pay' },
]



@inject('store', 'display')
@observer
class TransactionPreflightForm extends Component {
    state = {baseFormErrors: undefined }

    constructor(props) {
        super(props)
        const { store: {
            studentStore: { postTransaction },
            view: {selectedStudent, selectedStudentId, openStudentPageById, openStudentsPage}}
        } = this.props

        if (!selectedStudentId){
            openStudentsPage()
        }

        if ( isEmpty(selectedStudent) || isEmpty(selectedStudent.transactionPreflight)) {
            openStudentPageById(selectedStudentId)
            return
        }
        
        const formInstance = StudentTransaction.create({})
        formInstance.setFormInstanceFromPreflight(selectedStudent.transactionPreflight)
        
        this.formInstance = formInstance
        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors, data } = await postTransaction(selectedStudentId, node.toJSON())
                if (errors){
                    this.setState({baseFormErrors: errors.base})
                    return errors
                }
                this.postTransactionResponseData = data
                return null
            }
        })
    }

    getFormFields() {
        const {payment_method} = this.formInstance
        const transaction_date = this.formState.field("transaction_date")
        const note = this.formState.field("note")
        
        if (payment_method !== "bank_teller") return {note, transaction_date}
        
        const bank_account_id = this.formState.subForm('bank_teller').field("bank_account_id")
        const teller_amount = this.formState.subForm('bank_teller').field("amount")
        const teller_no = this.formState.subForm('bank_teller').field('teller_no')
        const teller_date = this.formState.subForm('bank_teller').field("date")
        
        return {note, transaction_date, bank_account_id, teller_amount, teller_no, teller_date}
    }

    handlePaystackScriptCreate = () => {
        // this.setState({ paystackScriptLoaded: false })
    }

    handlePaystackScriptLoad = () => {
        // this.setState({ paystackScriptLoaded: true })
    }

    handlePaystackScriptError = () => {
        // this.setState({ paystackScriptError: true })
        throw new Error('Paystack failed to load')
    }

    handleSubmit = async () => {
        const success = await this.formState.save({ ignoreRequired: false })

        if (!success) return

        const {
            store: {
                view: {
                    setBackPage,
                    getStudentPage,
                    openFinanceTransactionPageById,
                }
            },
        } = this.props

        const data = this.postTransactionResponseData

        if (data.type === 'web_pay_student_fee_transaction'){
            this.payWithPaystack(data)
        }else if (data.type === 'finance_transaction'){
            setBackPage('Student profile', getStudentPage())
            openFinanceTransactionPageById(data.id)
        }
    }

    paystackCallBack = async (response) => {
        const {
            store: {
                studentStore: { getVerifyWebPayTransaction },
                view: {
                    selectedStudentId,
                    setBackPage,
                    getStudentPage,
                    openFinanceTransactionPageById,
                }
            }
        } = this.props

        const { data } = await getVerifyWebPayTransaction(selectedStudentId, response.reference)
        
        if (data && data.type === 'finance_transaction'){
            setBackPage('Student profile', getStudentPage())
            openFinanceTransactionPageById(data.id)
        }
    }

    payWithPaystack(web_pay_student_fee_transaction){
        const { store } = this.props
        const { view: {selectedStudent}, meta: {currentUser, paystackSubAccountCode, currentInstitution} } = store
        const paystackKey = store.paystackPublicKey


        ReactGA.event({
            category: 'Finance',
            action: 'Pay with Paystack',
            nonInteraction: false,
            // 'label: 'Game Widget'',
            // value: undefined,
        });
        
        
        // eslint-disable-next-line no-undef
        const handler = PaystackPop.setup({
            key: paystackKey,
            email: currentUser.attributes.preferred_email,
            amount: web_pay_student_fee_transaction.attributes.total_amount_in_kobo,
            transaction_charge: web_pay_student_fee_transaction.attributes.service_charge_in_kobo,
            subaccount: paystackSubAccountCode,
            bearer: 'account',
            currency: "NGN",
            ref: web_pay_student_fee_transaction.attributes.trans_ref,
            metadata: {
                custom_fields: [
                    {
                        display_name: "School Name",
                        variable_name: "school_name",
                        value: currentInstitution.attributes.name
                    },
                    {
                        display_name: "Tenant Name",
                        variable_name: 'tenant_name',
                        value: currentInstitution.attributes.tenant_name
                    },
                    {
                        display_name: "Student Reg. No.",
                        variable_name: "student_username",
                        value: selectedStudent.admissionNumber
                    }
                ]
            },
            callback: (response) => {
                this.paystackCallBack(response)
            },
            onClose(){
                // alert('window closed');
            }
        });
        handler.openIframe();
    }

    render() {
        const { store: {studentStore, view: {selectedStudent}, meta: {currentUser, institutionBankAccounts}} } = this.props
        const { store: { formatCurrency, currency } } = this.props
        const {baseFormErrors} = this.state;
        
        if (!selectedStudent || !selectedStudent.transactionPreflight) return ''

        const {payment_method, wallet_credit, wallet_debit} = this.formInstance
        const transactionPreflightData = selectedStudent.transactionPreflight
        const bankAccountOptions = mapModelNameToOptions(institutionBankAccounts)
        
        const {note, transaction_date, bank_account_id, teller_amount, teller_no, teller_date} = this.getFormFields()
        
        
        const { student_fees_to_be_paid, total_fee_to_pay, total_amount_to_pay, payment_method_name, service_charge } = transactionPreflightData

        const { attributes: {wallet_balance, due_fees_balance}, studentName } = selectedStudent
        
        const formattedWalletBalance = formatCurrency(wallet_balance)
        const formattedDueFeesBalance = formatCurrency(due_fees_balance)
        
        const cardTitle = (
            <div className="TransactionReceipt-header">
                <span className="text--uppercase text-small text--spacedLetters">Pay Fees</span>

                <div className="flex-row">
                    <div className="flex-row text-p" style={{ textTransform: 'none' }}>
                        <div style={{ marginRight: 30 }}>
                            Wallet Balance:
                            <span className="text--bold text--primary"> {formattedWalletBalance}</span>
                        </div>
                        <div style={{ marginRight: 30 }}>
                            Due Fees:
                            <span className="text--bold text--danger"> {formattedDueFeesBalance}</span>
                        </div>
                    </div>

                </div>
            </div>
        )

        const TransactionReceiptStats = (
            <div className="TransactionReceiptStats">
                <Row>
                    <Col xs={24} sm={12} md={8}>
                        <div className="text--uppercase text-small text--bold text--grey">Student Name</div>
                        <div className="text-h4 text--bold">{titleCase(studentName)}</div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div className="text--uppercase text-small text--bold text--grey">Payment Method</div>
                        <div className="text-p text">{payment_method_name}</div>
                    </Col>
                </Row>
            </div>
        )

        const grandTotalMobileClassNames = classNames(
            'Card mt-1 ant-row text-h4 text--bold text--uppercase  text--primary',
            {'mb-5': !currentUser.is_employee_or_admin}
        );
        
        const grandTotal = (
            <React.Fragment>
                <Desktop>
                    <tr className="Row--bordered-top text--bold  text--primary">
                        <td colSpan="3" className="text--right text-h4">Amount to pay</td>
                        <td className="text-h4">{formatCurrency(total_amount_to_pay)}</td>
                    </tr>
                </Desktop>
                <NotDesktop>
                    <div className={grandTotalMobileClassNames}>
                        <Col xs={12} sm={12} md={20}>
                            <span>Amount to pay</span>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                            <span>{formatCurrency(total_amount_to_pay)}</span>
                        </Col>
                    </div>
                </NotDesktop>
            </React.Fragment>
        )

        const feeTransMobile = student_fees_to_be_paid.map(item =>
            (
                <Card className="FeeTransactionsItem mt-1" key={item.student_fee.data.id.id}>
                    <Row gutter={15}>
                        <Col xs={8} md={5}>
                            <div className="text--uppercase text-small text--bold">Fee Type</div>
                            <div className="text-p text">{item.student_fee.data.id.attributes.fee_type_name}</div>
                        </Col>
                        <Col xs={16} md={11}>
                            <div className="text--uppercase text-small text--bold">Description</div>
                            <div className="text-p text">{item.student_fee.data.id.attributes.fee_description}</div>
                        </Col>
                        <Col xs={8} md={4}>
                            <div className="text--uppercase text-small text--bold">Charged</div>
                            <div className="text-p text">
                                {
                                    formatCurrency(item.student_fee.data.id.attributes.charged)
                                }
                            </div>
                        </Col>
                        <Col xs={8} md={4}>
                            <div className="text--uppercase text-small text--bold">Pay</div>
                            <div className="text-p text">{formatCurrency(item.amount)}</div>
                        </Col>
                    </Row>
                </Card>
            ),
        )
        

        return (
            <MaybeBackPage>
                { payment_method === 'web_pay' && (
                    <Script
                        url="https://js.paystack.co/v1/inline.js"
                        onCreate={this.handlePaystackScriptCreate}
                        onError={this.handlePaystackScriptError}
                        onLoad={this.handlePaystackScriptLoad}
                    />
                )}
                <div className="TransactionReceipt">
                    <Desktop>
                        <Card title={cardTitle}>
                            {TransactionReceiptStats}
                            <div className="Table Table--striped Table-responsive">
                                <table>
                                    {student_fees_to_be_paid.length > 0 && (
                                        <thead>
                                        <tr>
                                            {feeTransactionsTableColumns.map(({ key, render }) => (
                                                <th key={key}>{render}</th>
                                            ))}
                                        </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                    {student_fees_to_be_paid.length > 0 &&
                                    student_fees_to_be_paid.map((row) => (
                                        <tr key={row.student_fee.data.id.id}>
                                            <td>
                                                {row.student_fee.data.id.attributes.fee_type_name}
                                            </td>
                                            <td>
                                                {row.student_fee.data.id.attributes.fee_description}
                                            </td>
                                            <td>
                                                {formatCurrency(row.student_fee.data.id.attributes.charged)}
                                            </td>
                                            <td>
                                                {formatCurrency(row.amount)}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    {student_fees_to_be_paid.length > 1 && (
                                        <tr className=" Table--bordered receipt-total text-p text--uppercase text--primary">
                                            <td colSpan="3" className="text--right">Fee Total</td>
                                            <td>{formatCurrency(total_fee_to_pay, currency)}</td>
                                        </tr>
                                    )}

                                    {(wallet_credit || wallet_debit) && (
                                        <React.Fragment>
                                            <tr className="Row--bordered-top  Table--bordered">
                                                <th rowSpan="2" colSpan="2">Wallet</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                            </tr>
                                            <tr className="Table--bordered">
                                                <td>
                                                    {wallet_debit}
                                                </td>
                                                <td>
                                                    {wallet_credit}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {service_charge && (
                                        <tr className="Row--bordered-top  Table--bordered">
                                            <th colSpan="3">Service Charge</th>
                                            <td>{formatCurrency(service_charge)}</td>
                                        </tr>
                                    )}


                                    {grandTotal}

                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </Desktop>
                    <NotDesktop>
                        <MobilePageHeader tablet>
                            {cardTitle}
                            {TransactionReceiptStats}
                        </MobilePageHeader>

                        {feeTransMobile}
                        {student_fees_to_be_paid.length > 1 && (
                            <div className="Card mt-1 ant-row text-p text--uppercase text--primary">
                                <Col xs={12} sm={12} md={20}>
                                    <span>Fee Total</span>
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <span>{formatCurrency(total_fee_to_pay)}</span>
                                </Col>
                            </div>
                        )}

                        {(wallet_credit || wallet_debit) && (
                            <Card className="walletTransactionsItem mt-1">
                                <Row gutter={15}>
                                    <Col xs={12} sm={12} md={20}>
                                        <div className="text--uppercase text-small text--bold wallet-transaction">
                                            Student Wallet
                                        </div>
                                    </Col>
                                    
                                    {wallet_debit && (
                                        <Col xs={12} sm={12} md={4}>
                                            <div className="text--uppercase text-small text--bold">Debit</div>
                                            <div className="text-p text">{formatCurrency(wallet_debit, currency)}</div>
                                        </Col>
                                    )}
                                    
                                    {wallet_credit && (
                                        <Col xs={12} sm={12} md={4}>
                                            <div className="text--uppercase text-small text--bold">Credit</div>
                                            <div className="text-p text">{formatCurrency(wallet_credit, currency)}</div>
                                        </Col>
                                    )}
                                </Row>
                            </Card>
                        )}

                        {service_charge && (
                            <Card className="walletTransactionsItem mt-1">
                                <Row gutter={15}>
                                    <Col xs={12} sm={12} md={20}>
                                        <div className="text--uppercase text-small text--bold wallet-transaction">
                                            Service Charge
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={12} md={4}>
                                        <div className="text-p text">{formatCurrency(service_charge)}</div>
                                    </Col>
                                </Row>
                            </Card>
                        )}

                        {grandTotal}

                    </NotDesktop>
                </div>
                
                {
                    currentUser.is_employee_or_admin && (
                        <Card className='mt-1 mb-1 TransactionForm'>
                            
                            {baseFormErrors && (
                                <div className="mb-1">
                                    <span className="text--danger">{baseFormErrors}</span>
                                </div>
                            )}
                            
                            <div className="AddStudent-studentInfo">

                                { !(['web_pay', 'bank_teller'].includes(payment_method)) && (
                                    <Label text="transaction_date" required={transaction_date.required}>
                                        <InlineError field={transaction_date}>
                                            <DateInput {...transaction_date.inputProps} />
                                        </InlineError>
                                    </Label>
                                )}

                                { payment_method === 'bank_teller' && (
                                    <>
                                        <Label text="Bank Account" required>
                                            <InlineError field={bank_account_id}>
                                                <SelectInput
                                                    {...bank_account_id.inputProps}
                                                    placeholder="Select Account"
                                                    defaultOptionText="Select Account"
                                                    options={bankAccountOptions}
                                                />
                                            </InlineError>
                                        </Label>
                                        <Label text="Teller Amount" required>
                                            <InlineError field={teller_amount}>
                                                <TextInput {...teller_amount.inputProps} />
                                            </InlineError>
                                        </Label>
                                        <Label text="Teller Date" required>
                                            <InlineError field={teller_date}>
                                                <DateInput {...teller_date.inputProps} />
                                            </InlineError>
                                        </Label>
                                        <Label text="Teller No" required>
                                            <InlineError field={teller_no}>
                                                <TextInput {...teller_no.inputProps} />
                                            </InlineError>
                                        </Label>
                                    </>
                                )}

                                <Label
                                    text="Note (optional)"
                                    required={note.required}
                                >
                                    <MultilineInput {...note.inputProps} />
                                </Label>

                            </div>
                        </Card>
                    )
                }
                
                <div className="mt-1 AddTransaction-saveButton">
                    <Button
                        buttonType="success"
                        fullwidth
                        onClick={this.handleSubmit}
                        disabled={studentStore.payFeeLoading}
                    >
                        {payment_method === 'web_pay' ? 'Continue' : 'Save'}
                    </Button>
                </div>
            </MaybeBackPage>
        )
    }
}


export default TransactionPreflightForm