import React, {Component} from 'react'
import { inject } from 'mobx-react';
import Button from '../common/button';

import historyWhite from '../common/assets/history_white.svg'

@inject('store')
class TopUpHistoryButton extends Component {
    
    render() {
        const { store: {view} } = this.props;

        return (
            <Button
                onClick={()=>{
                    view.setBackPage('Back to Messages', view.getMessagesPage());
                    view.openSMSTopupsPage()
                }}
                disabled={false}
                title={''}
            >
                <img src={historyWhite} alt="" className="utils-prepend" />
                SMS TOPUPS
            </Button>
        )
    }
}

export default TopUpHistoryButton