import './EmployeePrivileges.css'

import React from 'react'
import { Checkbox } from 'antd'
import { observer, inject } from 'mobx-react';
import classNames from 'classnames'
import { arrCompare } from '../lib/arrCompare';
import Card from '../common/card';
import Button from '../common/button';
import ContentLoader from '../common/content_loader';
import EmployeePolicy from "../policies/EmployeePolicy";

const EmployeePrivileges = inject("display", "store")(
    observer(({ employeeData, display, store }) => {
        const { meta: {currentUser} } = store
        const policy = new EmployeePolicy(currentUser, employeeData)
        
        const { attributes, employeeName, togglePrivilege, selectAllPrivilegesInCategory, updatePrivileges } = employeeData
        const { privileges } = attributes
        const content = (
            <>
                <div className="EmployeePrivileges-grid">
                    {Object
                        .keys(Privileges)
                        .map((key) => {
                            const privilegeValue = Privileges[key];
                            const privilegesCategory = [];
                            privilegeValue.forEach((elem) => {
                               if(privileges.includes(elem.key)) {
                                   privilegesCategory.push(elem.key)
                               }
                            })
                           return ( <PrivilegeGroup
                                title={key}
                                privileges={Privileges[key]}
                                employeeData={employeeData}
                                values={privilegesCategory}
                                key={key}
                                handleTogglePrivilege={togglePrivilege}
                                selectAllPrivilegesInCategory={selectAllPrivilegesInCategory}
                            />)
                        })
                    }
                </div>
                
                {policy.can_update_privileges && (
                    <div className="EmployeePrivileges-buttonContainer">
                        <Button onClick={updatePrivileges}>Update Privileges</Button>
                    </div>
                )}
            </>
        )

        if (display.isDesktop) {
            return (
                <Card title={`Privileges for ${employeeName}`}>
                    {content}
                </Card>
            )
        }
        return content
    })
)
export default EmployeePrivileges

const PrivilegeGroup = inject("display", "store")(
    observer(({ title, privileges, values, display, handleTogglePrivilege, selectAllPrivilegesInCategory, store, employeeData }) => {
        const privilegesKeys = privileges.map(({ key }) => key)
        const isAllChecked = arrCompare(privilegesKeys, values)
        const { isLoading } = employeeData

        const employeePrivilegesGroupClassName = classNames(
            'EmployeePrivileges-group text-p',
            { 'Card': display.isNotDesktop }
        )
        
        if (isLoading) return <Loading className={employeePrivilegesGroupClassName} />


        const { meta: {currentUser} } = store
        const policy = new EmployeePolicy(currentUser, employeeData)
        
        return (
            <div className={employeePrivilegesGroupClassName}>
                <div className="EmployeePrivileges-groupItem">
                    <div>
                        <Checkbox checked={isAllChecked} onChange={() => { selectAllPrivilegesInCategory(isAllChecked, privileges )}}/>
                    </div>
                    {title}
                </div>
                {privileges.map(({ key, name }) => (
                    <div className="EmployeePrivileges-groupItem" key={key}>
                        <div>
                            <Checkbox
                                checked={values.includes(key)}
                                disabled={!policy.can_update_privileges}
                                title={policy.can_update_privileges ? undefined : "No permission."}
                                onChange={() => handleTogglePrivilege(key)}
                            />
                        </div>
                        {name}
                    </div>
                ))}
            </div>
        )
    })
)

const Privileges = {
    'Administration / Operations': [
        { key: 'hr_control', name: 'HR Control'},
        { key: 'employee_view', name: 'Employee View'},
        { key: 'grade_book_control', name: 'Gradebook Control'},
        { key: 'finance_control', name: 'Finance Control'},
        { key: 'finance', name: 'Finance'},
        { key: 'event_management', name: 'Event Management'},
        { key: 'announcement', name: 'Announcement Management'},
        { key: 'dashboard', name: 'View Dashboard'},
        { key: 'subject_master', name: 'Subject Master'},
        { key: 'batch_control', name: 'Batch Control'},
        { key: 'message_management', name: 'Message Management'},
    ],
    'Student Management': [
        { key: 'admission', name: 'Admission'},
        { key: 'student_control', name: 'Student Control'},
        { key: 'student_view', name: 'Student View'},
        { key: 'student_attendance_register', name: 'Student Attendance Register'},
        { key: 'student_attendance_view', name: 'Student Attendance View'},
    ],
    'Academics': [
        { key: 'report_control', name: 'Reports Control'},
        { key: 'view_report', name: 'View Reports'},
        { key: 'enter_score', name: 'Enter Score'},
        { key: 'view_score', name: 'View Score'},
    ],
    'System Settings': [
        { key: 'general_settings', name: 'General Settings'},
    ],
}

const Loading = ({className}) => {
    return (
        <div className={className}>
            <ContentLoader height="300" width="150%">
                <rect x="0" y="10" rx="2" ry="2" width="20" height="20" />
                <rect x="45" y="13" rx="2" ry="2" width="150" height="15" />
                <rect x="0" y="46" rx="2" ry="2" width="20" height="20" />
                <rect x="45" y="49" rx="2" ry="2" width="150" height="15" />
                <rect x="0" y="86" rx="2" ry="2" width="20" height="20" />
                <rect x="45" y="89" rx="2" ry="2" width="150" height="15" />
                <rect x="0" y="126" rx="2" ry="2" width="20" height="20" />
                <rect x="45" y="129" rx="2" ry="2" width="150" height="15" />
            </ContentLoader>
        </div>
    )
}