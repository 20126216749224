// Credit: https://medium.com/@robertsavian/javascript-case-converters-using-lodash-4f2f964091cc
import camelCase from "lodash.camelcase";
import snakeCase from "lodash.snakecase";
import kebabCase from "lodash.kebabcase";
import upperCase from "lodash.uppercase";
import lowerCase from "lodash.lowercase";
import startCase from "lodash.startcase";
import upperFirst from "lodash.upperfirst";

export function toUpperFirst(str) {
    return upperFirst(str);
}

export function toCamelCase(str) {
    return camelCase(str);
}

export function toTitleCase(str) {
    return startCase(camelCase(str));
}

export function toPascalCase(str) {
    return startCase(camelCase(str)).replace(/ /g, '');
}

export function toConstantCase(str) {
    return upperCase(str).replace(/ /g, '_');
}

export function toDotCase(str) {
    return lowerCase(str).replace(/ /g, '.');
}

export function toKebabCase(str) {
    return kebabCase(str);
}

export function toLowerCase(str) {
    return lowerCase(str).replace(/ /g, '');
}

export function toPathCase(str) {
    return lowerCase(str).replace(/ /g, '/');
}

export function toSnakeCase(str) {
    return snakeCase(str);
}

export function toSentenceCase(str) {
    return upperFirst(lowerCase(str));
}