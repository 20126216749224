import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import { ModalContext } from '../common/modal'
import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import UserSummary from './UserSummary'
import UserBioData from './UserBioData';
import UpdatePassword from '../common/update_password';

import employeeIcon from '../common/assets/teacher-pointing-blackboard.svg'
import password from '../common/assets/password.svg'
import UserPolicy from "../policies/UserPolicy";
import ReactHelmet from "../containers/ReactHelmet";
import Loading from "../common/loading";

@inject("store")
@observer
class SelectedUser extends Component {
    tabs = []

    componentDidMount() {
        const { store } = this.props
        const { userStore, view } = store
        const { loadUserById } = userStore
        loadUserById(view.selectedUserId)
    }

    options = (context) =>{ 
        const { store: { meta: {currentUser}, view : { selectedUser} } } = this.props
        const policy = new UserPolicy(currentUser, selectedUser)
        return [
            {
                text: 'Change Password',
                disabled: !policy.can_update_password,
                title: policy.can_update_password ? undefined : "No permission.",
                icon: password,
                onClick: () => context.setContent(
                    'Change Password',
                    <UpdatePassword onCancel={context.closeModal} id={currentUser.id} />,
                    true
                ),
            }
        ]
    }

    render() {
        const { store } = this.props
        const { view, meta: {currentUser} } = store
        const { selectedUser } = view
        
        if (isEmpty(selectedUser) || !selectedUser.isInitialized) return <Loading />
        
        const policy = new UserPolicy(currentUser, selectedUser)
        if (!policy.can_show) throw new Error('You do not have permission to view this record')
        
        const { isLoading, attributes: userAttributes, bioData } = selectedUser
        const canPrint = !isLoading
        

        const tabs = [
            new Tab("Bio", <UserBioData {...bioData} />, true),
        ]

        return (
            <>
                <ReactHelmet title={`User Profile | ${selectedUser.fullName}`} />
                <ModalContext.Consumer>
                    {context => (
                        <Tabs
                            title={{ text: 'User Profile', icon: employeeIcon }}
                            tabs={tabs}
                            content={<UserSummary user={selectedUser} />}
                            options={!selectedUser.isInitialized ? [] : this.options(context)}
                            canPrint={canPrint}
                        />
                    )}
                </ModalContext.Consumer>
            </>
        )
    }
}

export default SelectedUser
