import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import ReactGA from 'react-ga';

// Mobx
import { reaction, observable } from 'mobx';
import { Provider } from 'mobx-react';
import makeInspectable from 'mobx-devtools-mst';

// Router
import createRouter from "./tools/router"

import App from './containers/App';
import { BluebicStore, routerModel } from './store/BluebicStore';
import { display } from './common/display';
import { sidebar } from './common/sidebar';
import { feedback } from './common/feedback';
import {APP_VERSION, CURRENTENV} from './service/constants';

const fetcher = url => window.fetch(url).then(response => response.json())
const initialState = {
  router: routerModel,
};
const bluebic = BluebicStore.create(
    initialState,
    {
      env: CURRENTENV,
      fetch: fetcher,
      alert: feedback.push
    }
)

const history = {
  snapshots: observable.array([], { deep: false }),
  actions: observable.array([], { deep: false }),
  patches: observable.array([], { deep: false })
}

// Devtools
makeInspectable(bluebic);

// Google Analytics
ReactGA.initialize('UA-85520663-2', {
    debug: false,
    titleCase: true,
    gaOptions: {}
});

ReactGA.set({dimension3: APP_VERSION});
if (typeof window.mixpanel === 'object') window.mixpanel.register({'App Version': APP_VERSION});
if (typeof window.newrelic === 'object') window.newrelic.addRelease('App Version', APP_VERSION);


if (window.Beacon){
    // Don't init by default
    // window.Beacon('init', '330892b1-6464-47e6-8893-48dafb89584a')
    window.Beacon('config', {
        docsEnabled: true,
        messagingEnabled: true,
        enableFabAnimation: true,
        hideFABLabelOnMobile: true,
        hideFABOnMobile: true,
        mode: 'neutral', // selfService, askFirst, 
        color: '#1C75BB',
        display: {
            style: 'icon',
            iconImage: 'message',
        },
        messaging: {
            chatEnabled: true,
            chat: {
                customFieldsEnabled: true,
            },
        },
        contactForm: {
            customFieldsEnabled: true,
            showName: true,
        }
    })
}

/**
 * Routing
 */

reaction(
    () => bluebic.view.currentUrl,
    path => {
        // TODO: next line ideally should not be needed. Investigate
        if (display.isNotDesktop) sidebar.setVisible(false)
        if (window.location.pathname !== path) window.history.pushState(null, null, path)

        ReactGA.set({ page: path }); // Update the user's current page
        ReactGA.pageview(path); // Record a pageview for the given page

        if (typeof window.mixpanel === 'object') window.mixpanel.track('Page View', {path})
    }
)

const router = createRouter({
  "/dashboard": bluebic.view.openDashboardPage,
  "/students/:id(\\d+)/pay-fees": ({ id }) => bluebic.view.openStudentPayFeesPageById(id),
  "/student-report-card/:student_report_card_id(\\d+)": ({ student_report_card_id }) => bluebic.view.openStudentReportCardPageById(student_report_card_id),
  "/students/:id(\\d+)": ({ id }) => bluebic.view.openStudentPageById(id),
  "/students": bluebic.view.openStudentsPage,
  "/students/add": bluebic.view.openAddStudentPage,
  "/students/edit/:id(\\d+)": ({ id }) => bluebic.view.openEditStudentPageById(id),
  "/guardians/:id": ({ id }) => bluebic.view.openGuardianPageById(id),
  "/guardians": bluebic.view.openGuardiansPage,
  "/employees/:id(\\d+)": ({ id }) => bluebic.view.openEmployeePageById(id),
  "/employees": bluebic.view.openEmployeesPage,
  "/employees/add": bluebic.view.openAddEmployeePage,
  "/employees/edit/:id(\\d+)": ({ id }) => bluebic.view.openEditEmployeePageById(id),
  "/batches/:id": ({ id }) => bluebic.view.openBatchPageById(id),
  "/batches": bluebic.view.openBatchesPage,
  "/login": () => bluebic.view.openLoginPage(),
  "/finance/transactions": () => bluebic.view.openFinanceTransactionsPage(),
  "/finance/transactions/:id(\\d+)/a4": ({ id }) => bluebic.view.openFinanceTransactionA4PageById(id),
  "/finance/transactions/:id(\\d+)/pos": ({ id }) => bluebic.view.openFinanceTransactionPOSPageById(id),
  "/finance/transactions/:id(\\d+)": ({ id }) => bluebic.view.openFinanceTransactionPageById(id),
  "/finance/fee-transactions": () => bluebic.view.openFeeTransactionsPage(),
  "/finance/custom-transactions": () => bluebic.view.openCustomTransactionsPage(),
  "/finance/expense-account-transactions": () => bluebic.view.openExpenseAccountTransactionsPage(),
  "/finance/fees": () => bluebic.view.openFeesPage(),
  "/finance/fees/:id(\\d+)": ({id}) => bluebic.view.openFeePageById(id),
  "/events/new": bluebic.view.openAddEventPage,
  "/events/edit/:id(\\d+)": ({ id }) => bluebic.view.openEditEventPageById(id),
  "/events/:id": ({ id }) => bluebic.view.openEventPageById(id),
  "/events": () => bluebic.view.openEventsPage(),
  "/subjects/:id": ({ id }) => bluebic.view.openSubjectPageById(id),
  "/subjects": () => bluebic.view.openSubjectsPage(),
  "/assessments/:id": ({ id }) => bluebic.view.openAssessmentPageById(id),
  "/report-cards/:id(\\d+)": ({ id }) => bluebic.view.openReportCardGroupPageById(id),
  "/report-cards": () => bluebic.view.openReportCardGroupsPage(),
  "/messages/:id(\\d+)": ({ id }) => bluebic.view.openMessagePageById(id),
  "/messages": () => bluebic.view.openMessagesPage(),
  "/messages/sms-topups": () => bluebic.view.openSMSTopupsPage(),
  "/settings/school-details": bluebic.view.openSchoolDetailsPage,
  "/settings/academic-sessions": bluebic.view.openAcademicSessionsPage,
  "/settings/classes": bluebic.view.openClassesPage,
  "/settings/class-sets": bluebic.view.openClassSetsPage,
  "/settings/subject-names": bluebic.view.openSubjectNamesPage,
  "/": () => { bluebic.view.openDefaultPage()},
})

window.onpopstate = function historyChange(ev) {
  if (ev.type === "popstate") router(window.location.pathname)
}

router(window.location.pathname)

window.ononline = () => feedback.push({ success: "You're Back Online" });

window.onoffline = () =>
    feedback.push({ error: "Oops! Looks Like You're Offline" });

window.onerror = function(error) {
    try {
        ReactGA.exception({
            description: `JavaScript Error: ${error.message}. Filename: ${error.filename} | Line ${error.lineno}`,
            fatal: false
        });
        
        if (typeof window.newrelic === 'object') window.newrelic.noticeError(error);
        
    } catch(e) {
        console.error(e)
    }
};

// ---------------
/**
 * for playing in the console
 */
window.bluebic = bluebic
window.display = display
window.sidebar = sidebar
window.router = router
window.feedback = feedback

@hot(module)
class Root extends Component {
  render() {
    return (
        <Provider store={bluebic} history={history} display={display} sidebar={sidebar}>
          <App />
        </Provider>
    );
  }
}

export default Root
