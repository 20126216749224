import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/student_fee_transactions.json'
import financeSummaryJSON from "../json/finance_transactions_summary";

export function FeeTransactionService(env) {
    async function search(fee_transaction_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("fee_transaction_search", fee_transaction_search)
            const { data } = await axios.get(`/api/v1/finance/fee_transactions?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function fetchFinanceSummary(ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/fee_transactions/summary`, {ids})
            return data
        }
        return financeSummaryJSON
    }

    async function downloadFeeTransactionsSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/finance/fee_transactions`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: {ids: action.item_ids}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        search,
        downloadFeeTransactionsSpreadsheet,
        fetchFinanceSummary
    }
}

export default FeeTransactionService(TESTENV)