import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform';
import { stringifyQuery } from '../lib/stringifyQuery'
import showBatchJSON from '../json/batch_data.json'
import attendanceReportJSON from '../json/batch_attendance_report.json'
import attendanceRegisterJSON from '../json/batch_attendance_register.json'
import behaviouralScoresheetJSON from '../json/behavioural_Scoresheet.json'
import sendMessageJSON from "../json/send_message";

export function BatchService(env) {

    async function search(batch_search) {
        const queryString = stringifyQuery('batch_search', batch_search)
        const { data } = await axios.get(`/api/v1/batches?${queryString}`)
        return data
    }

    async function showBatch(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/batches/${id}`)
            return data
        }
        return showBatchJSON
    }

    async function createBatch(batch) {
        const { data } = await axios.post(`/api/v1/batches`, { batch })
        return data
    }
    async function updateBatch(id,batchUpdate) {
        const { data } = await axios.patch(`/api/v1/batches/${id}/`,batchUpdate)
        return data
    }
    async function deleteBatch(id) {
        const { data } = await axios.delete(`/api/v1/batches/${id}/`)
        return data
    }
    async function batchTransfer(students_transfer) {
        const { data } = await axios.post(`/api/v1/student_batch_transfers`, { students_transfer })
        return data
    }

    async function fetchAttendanceReport(id, term = '') {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/batches/${id}/attendance_report?term=${term}`)
            return data
        }
        return attendanceReportJSON
    }

    async function fetchAttendanceRegister(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/batches/${id}/attendance_register`)
            return data
        }
        return attendanceRegisterJSON
    }

    async function fetchBehaviouralScoreSheet(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/batches/${id}/behavioural_scoresheet`)
            return data
        }
        return behaviouralScoresheetJSON
    }

    async function updateBehaviouralScoreSheet(id, student_id, academic_term, grades) {
        const result = await axios.patch(`/api/v1/batches/${id}/behavioural_scoresheet/${academic_term}/${student_id}`, {scoresheet: grades}, 
        { transformRequest: formDataTransform })
        const { data } = result
        return data
    }

    async function assignRole({batch_id, employee_id, special_role_id}) {
        const role = {
            employee_id,
            special_role_id
        }
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/batches/${batch_id}/roles`,
                { role },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createSubjectJSON
    }
    async function unassignRole(id, special_role_assignment_id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/batches/${id}/roles/${special_role_assignment_id}`)
            return data
        }
        return unassignEmployeeJSON
    }

    async function batchSetupTransfer(id, setup_transfer_action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/batches/${id}/setup`, { setup_transfer: setup_transfer_action})
            return data
        }
        return sendMessageJSON
    }

    return {
        createBatch,
        search,
        showBatch,
        updateBatch,
        deleteBatch,
        batchTransfer,
        fetchAttendanceReport,
        fetchAttendanceRegister,
        fetchBehaviouralScoreSheet,
        updateBehaviouralScoreSheet,
        assignRole,
        unassignRole,
        batchSetupTransfer
    }
}

export default BatchService(TESTENV)