import { types, flow } from "mobx-state-tree"

import { LoadingPattern } from "./patterns/LoadingPattern";
import { UsesBluebicStorePattern } from "./patterns/UsesBluebicStorePattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";
import {User} from "./models/User";

export const UserStore = types
    .compose(
        LoadingPattern(),
        UsesBluebicStorePattern,
        types
            .model("UserStore", {
                users: types.map(User)
            })
            .views(self => ({
                get service() {
                    return self.bluebic.userService
                }
            }))
            .actions(self => {

                function updateUsers(users) {
                    users.forEach(json => self.users.put(json))
                }
                
                function markUserLoadingById(id, flag) {
                    if (self.users.has(id)) {
                        self.users.get(id).isLoading = flag
                    } else {
                        self.users.put({ id })
                    }
                }

                const loadUserById = flow(function* loadUserById(id) {
                    try {
                        markUserLoadingById(id, true)
                        const { data, included } = yield self.bluebic.userService.showUser(id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markUserLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load user with id ${id}`, err)
                        markUserLoadingById(id, false)
                        return {error: `Failed to load employee with id ${id}`}
                    }
                })

                const updateUserPassword = flow(function* updateUserPassword(id, update_password) {
                    try {
                        self.markLoading(true)
                        const { flash } = yield self.bluebic.userService.updateUserPassword(id, update_password)
                        self.markLoading(false)
                        return { flash }
                    } catch (err) {
                        self.markLoading(false)
                        console.error(`Failed to update user password with id ${id}`, err)
                        return validationErrorsHandler(err)
                    }
                })

                function onUpdate(included) {
                    filterType("user", included, updateUsers)
                }
                

                return {
                    onUpdate,
                    loadUserById,
                    updateUserPassword
                }
            })
    )
