import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform';

export function AcademicSessionService(env) {
    async function getAcademicSessions() {
        if (env !== TESTENV) {
            const { data } = await axios.get('/api/v1/configuration/academic_sessions');
            return data;
        }
        return {};
    }

    async function createAcademicSession(academic_session) {
        if (env !== TESTENV) {
            const { data } = await axios.post('/api/v1/configuration/academic_sessions',
                { academic_session },
                { transformRequest: formDataTransform }
            );
            return data;
        }
        return {};
    }

    async function updateAcademicSession(academic_session, id) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/configuration/academic_sessions/${id}`,
                { academic_session },
                { transformRequest: formDataTransform }
            );
            return data;
        }
        return {};
    }

    async function deleteAcademicSession(session_id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/configuration/academic_sessions/${session_id}`);
            return data;
        }
        return {};
    }

    return {
        getAcademicSessions,
        createAcademicSession,
        updateAcademicSession,
        deleteAcademicSession,
    }
}

export default AcademicSessionService(TESTENV)
