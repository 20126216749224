import './MaybeBackPage.css'

import React from 'react'
import { inject, observer } from 'mobx-react';

const MaybeBackPage = inject("store")(
    observer(({ store, children }) => {
        const {
            view: {
                backPage = {},
                openPage
            }
        } = store
        const title = backPage.title || "Back"
        const page = backPage.page || null

        return (
            <div className="MaybeBackPage">
                <button
                    type="button"
                    onClick={() => handleOpenPage(page, openPage)}
                    className="MaybeBackPage-backBtn touchable-opacity"
                >
                    <i className="material-icons">chevron_left</i>
                    {title}
                </button>
                {children}
            </div>
        )
    })
)
export default MaybeBackPage

function handleOpenPage(page, handler) {
    if (page && handler) {
        handler(page)
    } else {
        window.history.back()
    }
}