import { applySnapshot, flow, types } from 'mobx-state-tree';
import { Student } from './Student';
import { FinanceTransaction } from './FinanceTransaction';
import { AssessmentScore } from './AssessmentScore';
import { Announcement } from './Announcement';
import { Course } from './Course';
import { UsesBluebicStorePattern } from '../patterns/UsesBluebicStorePattern';
import { LoadingPattern } from '../patterns/LoadingPattern';
import isEmpty from "lodash.isempty";

export const DashboardReport = types
    .compose(
        UsesBluebicStorePattern,
        LoadingPattern(false),
        types.model('DashboardReport', {
            id: types.maybe(types.string),
            type: 'dashboard_report',
            attributes: types.maybe(types.model({
                total_invoiced: types.string,
                total_received: types.string,
                total_pending: types.string,
                recent_transaction_ids: types.array(types.reference(FinanceTransaction)),
                recent_assessment_score_ids: types.array(types.reference(AssessmentScore)),
                recent_announcement_ids: types.array(types.reference(Announcement)),
                income_trend: types.array(types.model({
                    current_name: types.string,
                    previous_name: types.string,
                    current: types.union(types.string, types.number),
                    previous: types.union(types.string, types.number),
                })),
                people_count: types.model({
                    student: types.model({ current: types.number, previous: types.number }),
                    employee: types.model({ current: types.number, previous: types.number }),
                    guardian: types.model({ current: types.number, previous: types.number }),
                }),
                attendance_stats: types.model({
                    absences: types.model({
                        current: types.number,
                        previous: types.number,
                    }),
                    students: types.model({
                        current: types.number,
                        previous: types.number,
                    })
                }),
                top_absent_students: types.array(types.model({
                    student_id: types.reference(Student),
                    days: types.number,
                })),
                debtors_by_class: types.array(types.model({
                    course_id: types.maybeNull(types.reference(Course)),
                    debtors_count: types.number,
                    total_debt: types.string,
                })),
            })),
            isLoading: true,
        }).actions(self => {
            const fetchDashboardReport = flow(function* fetchDashboardReport() {
                try {
                    self.markLoading(true)
                    const { data, included } = yield self.bluebic.dashboardService.getDashboard();
                    self.bluebic.handleUpdateStores(included)
                    applySnapshot(self, data)
                    self.markLoading(false)
                } catch (err) {
                    self.markLoading(false);
                    console.error(`Failed to load dashboard`, err)
                }
            });

            return {
                fetchDashboardReport
            }

        }).views(self => ({
            get isInitialized() {
                return !!self.attributes
            },
            get incomeTrendChart() {
                if (!self.attributes) return undefined 
                
                return self.attributes.income_trend.map(
                    ({ current_name, previous_name, current, previous }) => ({
                        'Last 4 Months': current,
                        'Previous 4 Months': previous,
                        'current_name': current_name,
                        'previous_name': previous_name
                    })
                )
            },
            get financeStat() {
                if (!self.attributes) return undefined
                
                const {total_invoiced, total_received, total_pending} = self.attributes
                
                return {
                    'Invoiced': self.bluebic.formatCurrency(total_invoiced),
                    'Received': self.bluebic.formatCurrency(total_received),
                    'Pending': self.bluebic.formatCurrency(total_pending)
                }
            },
            
            get peopleStat() {
                if (!self.attributes) return undefined
                const {people_count} = self.attributes
                return Object.keys(people_count).map((key) => {
                    const currentPeopleCount = people_count[key].current
                    const trend = currentPeopleCount - people_count[key].previous
                    return {
                        name: key,
                        amount: currentPeopleCount,
                        trend
                    }
                })
            },

            get topAbsentStudents() {
                if (!self.attributes) return  undefined
                const {top_absent_students} = self.attributes
                return top_absent_students
            },

            get recentAnnouncements() {
                if (!self.attributes) return  undefined
                return self.attributes.recent_announcement_ids.map(({id, attributes: {title, body}}) => ( {
                        id,
                        name: title,
                        text: body,
                        hasBadge: true,
                    }
                ))
            },

            get attendanceStats() {
                if (!self.attributes) return  undefined
                const {attendance_stats: {
                    absences: {
                        current: currentAbsences,
                        previous: previousAbsences
                    },
                    students: {
                        current: currentStudents,
                        previous: previousStudents
                    },
                } } = self.attributes
                
                
                return [
                    { name: 'Absences', amount: currentAbsences, trend: currentAbsences - previousAbsences },
                    { name: 'No of Students Absent', amount: currentStudents, trend: currentStudents - previousStudents },
                ]
            },

            get debtorsByClass() {
                if (!self.attributes) return undefined
                const {debtors_by_class } = self.attributes
                
                return debtors_by_class.map(({course_id, debtors_count, total_debt}) => ({
                    course_id: isEmpty(course_id) ? null : course_id.id,
                    className: isEmpty(course_id) ? null : course_id.attributes.name,
                    debtorsCount: debtors_count,
                    totalDebt: self.bluebic.formatCurrency(total_debt)
                }))
            },

            get recentTransactions() {
                if (!self.attributes) return  undefined
                const {recent_transaction_ids}  = self.attributes
                
                return recent_transaction_ids.map(({derivedCategoryName, studentName, note, attributes: {
                    created_by_name,
                    amount_received,
                    amount_payed_out,
                    transaction_date,
                    trans_ref
                }}) => ({
                    categoryName: derivedCategoryName,
                    transRef: trans_ref,
                    note: note || studentName,
                    enteredBy: created_by_name,
                    amount: amount_received - amount_payed_out,
                    date: transaction_date
                }))
            },

            get recentAssessmentScores() {
                if (!self.attributes) return undefined
                const {recent_assessment_score_ids}  = self.attributes
                return recent_assessment_score_ids
            },

            get date() {
                return self.id;
            },

        }))
    );