import './GuardianWards.css'

import React from 'react'
import { inject, observer } from 'mobx-react';

import {formatDateLong} from "../lib/formatDate";
import { titleCase } from '../lib/titleCase';

import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar';
import Loading from '../common/loading/WardGuardianCardLoading'

const GuardianWards = inject("store")(
    observer(
        ({ guardian, store }) => {
            const { view } = store
            const { openStudentPageById } = view
            
            const {isLoading} = guardian
            return (
                <Card title="Ward(s) Information" className="GuardianWards">
                    <div className="GuardianWards-grid">
                        {isLoading && <Loading /> }
                        
                        {isLoading || guardian.studentGuardianRelations.map(SGR => {
                            const {student, attributes: {relation, emergency_contact, authorized_to_pick_up}} = SGR
                            const {id: wardID, studentName, attributes: {photo_url, username, gender, admission_date, status, batch_name}} = student
                            
                            return (
                                <Card key={wardID} className="GuardianWards-item text-p">
                                    <div className="flex-row flex-alignCenter">
                                        <div className="GuardianWards-avi">
                                            <Avatar
                                                small
                                                src={photo_url}
                                                type={AVI_TYPES.STUDENT}
                                                sex={gender}
                                            />
                                        </div>
                                        <div>
                                            <a
                                                href={`/students/${wardID}`}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    openStudentPageById(wardID)
                                                }}
                                            >{studentName}
                                            </a>
                                            <div className="flex-row flex-alignCenter">
                                                <span>{username}</span>
                                                <span className="pl-1 text--success text--bold">{status === 'active' ? 'Enrolled' : titleCase(status)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>Relationship: {relation}</div>
                                    <div>Emergency Contact: {emergency_contact ? 'Yes' : 'No'}</div>
                                    <div>Can Pick Up?: {authorized_to_pick_up ? 'Yes' : 'No'}</div>
                                    <div>Batch: {batch_name}</div>
                                    <div>Adm. Date: {formatDateLong(admission_date)}</div>
                                </Card>
                            )
                        })}
                    </div>
                </Card>
            )
        }
    )
)
export default GuardianWards