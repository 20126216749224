import React from 'react';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import isEmpty from "lodash.isempty";
import LoadingText from './LoadingText';
import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Button from '../common/button';
import Table from '../common/table';
import Card from '../common/card';
import MobileListLoading from '../common/loading/MobileListLoading';
import avatar from '../common/assets/avatar-dark.svg';

import './AssignSelectedStudents.css';


const columns = [
    {key: 'name', render: 'Name'},
    {key: 'reg_no', render: 'Reg. No'},
    {key: 'batch', render: 'Batch'}
];

@inject('store', 'display')
@observer
class AssignSelectedStudents extends React.Component {
    static propTypes = {
        onClose: PropTypes.func
    }
    
    static defaultProps = {
        onClose: () => {}
    }
    
    state = {selectedStudentsLoaded: false}

    async componentDidMount() {
        const {selectedStudents, store} = this.props;
        if (isEmpty(selectedStudents)) return 
        const {studentStore: {getSelectedStudentRecords}} = store;
        await getSelectedStudentRecords(selectedStudents);
        this.setState({selectedStudentsLoaded: true})
    }

    handleSubmit = async () => {
        const {selectedStudents, store,onClose} = this.props;
        const {feeStore: {assignFeeToSeletedStudents}, view: {selectedFee: {id}}} = store
        
        await assignFeeToSeletedStudents(id, selectedStudents)
        onClose();
    };

    render() {
        const {selectedStudentsLoaded} = this.state
        const {store} = this.props;
        const {studentStore: {selectedStudentsDetails, isLoading}} = store;

        if (isEmpty(selectedStudentsDetails)) return <div className="assignSelectedStudents"><p>No Students selected!</p></div>
        if (isLoading || !selectedStudentsLoaded) return <LoadingText />
        const tableData = selectedStudentsDetails.map(({studentName, admissionNumber, batchNameNoSession}) => ({
            name: studentName,
            reg_no: admissionNumber,
            batch: batchNameNoSession
        }));

        return (
            <div className="assignSelectedStudents">
                <p>Are you sure you want to assign this fee to the underlisted students?</p>
                <NotMobile>
                    <Table striped containerClass="assignSelectedStudents-table" columns={columns} data={tableData} />
                </NotMobile>
                <Mobile>
                    <MobileListLoading isLoading={isLoading}>
                        {selectedStudentsDetails.map(({id, studentName, admissionNumber, batch}) => (
                            <div className="assignSelectedStudents-items" id={id} key={id}>
                                <Card>
                                    <div className="assignSelectedStudents-item">
                                        <img src={avatar} alt="" />
                                        <div>
                                            <div>{studentName}</div>
                                            <div>{admissionNumber}</div>
                                        </div>
                                        <div>
                                            <div>Batch</div>
                                            <div>{batch}</div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </MobileListLoading>
                </Mobile>
                <Button className="assignSelectedStudents-updateButton" onClick={this.handleSubmit}>Confirm</Button>
            </div>
        );
    }
}

export default AssignSelectedStudents;

