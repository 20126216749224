import React from 'react';
import PropTypes from 'prop-types';

import "./NoDataAvailable.css";
import Button from "../common/button";
import mailBox from "./assets/mailbox.svg";

export default function NoDataAvailable({ title, description, buttonText, handleButtonClick }) {
    return (
        <div className="NoDataAvailable">
            <h3 className="NoDataAvailable--Title">
                {title}
            </h3>
            <img className="NoDataAvailable--Logo" src={mailBox} alt="logo of an empty mail box" />
            <p className="NoAcademicSessions--Description">
                {description}
            </p>
            {buttonText && handleButtonClick && <Button onClick={handleButtonClick}>{buttonText}</Button>}
        </div>
    );
}

NoDataAvailable.defaultProps = {
    handleButtonClick: null,
    buttonText: '',
}

NoDataAvailable.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func,
    buttonText: PropTypes.string,
};
