import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform'

import indexJSON from '../json/course_sets.json'


export function CourseSetService(env) {
    async function index() {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/configuration/course_sets`)
            return data
        }
        return indexJSON
    }

    async function getCourses() {
        if (env !== TESTENV) {
            const { data } = await axios.get('/api/v1/configuration/courses/');
            return data;
        }
        return {};
    }

    async function createCourse(course) {
        if (env !== TESTENV) {
            const { data } = await axios.post('/api/v1/configuration/courses/',
                { course },
                { transformRequest: formDataTransform }
            );
            return data;
        }
        return {};
    }

    async function updateCourse(course, id) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/configuration/courses/${id}`,
                { course },
                { transformRequest: formDataTransform }
            );
            return data;
        }
        return {};
    }

    async function deleteCourse(course_id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/configuration/courses/${course_id}`);
            return data;
        }
        return {};
    }


    return {
        index,
        getCourses,
        createCourse,
        updateCourse,
        deleteCourse,
    }
}

export default CourseSetService(TESTENV)
