import { types } from "mobx-state-tree"

export const Attendance = types
    .model("Attendance", {
        id: types.identifier,
        type: "attendance",
        attributes: types.maybe(types.model({
            student_id: types.number,
            date: "",
            half_day: types.maybeNull(types.boolean),
            reason: types.maybeNull(types.string),
        })),
        isSaving: false
    })
