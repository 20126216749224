import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/students.json'
import showStudentJSON from '../json/student_data.json'
import attendanceReportJSON from '../json/student_attendance_report.json'
import destroyBatchTransferJSON from '../json/remove_batch_history_success.json'
import editBatchHistoryJSON from '../json/edit_batch_history_success.json'
import blockStudentsJSON from '../json/block_student_success.json'
import archiveStudentJSON from '../json/archive_student_success.json'
import deleteStudentJSON from '../json/delete_student_success.json'
import financeSummaryJSON from '../json/student_finance_summary.json'
import createStudentJSON from '../json/create_student_success.json'
import editStudentJSON from '../json/edit_student_success.json'
import createStudentGuardianRelationJSON from '../json/create_student_guardian_relation_success.json'
import studentTransactionPreflightJSON from '../json/finance_transaction_preflight.json'
import studentTransactionJSON from '../json/student_finance_transaction.json'
import unassignGuardianJSON from '../json/unassign_guardian_success.json'
import { formDataTransform } from './formDataTransform';

export function StudentService(env) {
    async function search(action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("student_search", action)
            const { data } = await axios.get(`/api/v1/students?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showStudent(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/students/${id}`)
            return data
        }
        return showStudentJSON
    }

    async function fetchAttendanceReport(id, action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("attendance_report", action)
            const { data } = await axios.get(`/api/v1/students/${id}/attendance_report?${queryString}`)
            return data
        }
        return attendanceReportJSON
    }

    async function destroyBatchTransfer(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/student_batch_transfers/${id}`)
            return data
        }
        return destroyBatchTransferJSON
    }

    async function updateBatchTransfer(id, transfer) {
        if (env !== TESTENV) {
            const { data } = await axios.put(`/api/v1/student_batch_transfers/${id}`, { transfer })
            return data
        }
        return editBatchHistoryJSON
    }

    async function blockStudents(block) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/students/block`, { block })
            return data
        }
        return blockStudentsJSON
    }

    async function unblockStudents(unblock) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/students/unblock`, { unblock })
            return data
        }
        return blockStudentsJSON
    }

    async function archiveStudent(id, student) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/students/${id}/drop`, { student })
            return data
        }
        return archiveStudentJSON
    }

    async function reEnrollStudentById(id) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/students/${id}/restore_to_active`)
            return data
        }
        return showStudentJSON //FIXME
    }

    async function deleteStudent(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/students/${id}`)
            return data
        }
        return deleteStudentJSON
    }

    async function financeSummary(student_ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/finance_summary`, {student_ids})
            return data
        }
        return financeSummaryJSON
    }

    async function createStudent(student) {
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/students`,
                { student },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createStudentJSON
    }

    async function assignGuardian(student_guardian_relation) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/student_guardian_relations`, { student_guardian_relation })
            return data
        }
        return createStudentGuardianRelationJSON
    }

    async function unassignGuardian(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/student_guardian_relations/${id}`)
            return data
        }
        return unassignGuardianJSON
    }

    async function getTransactionPreflight(student_id, student_transaction) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/${student_id}/transaction_preflight`, { student_transaction })
            return data
        }
        return studentTransactionPreflightJSON
    }

    async function postTransaction(student_id, student_transaction) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/${student_id}/finance_transaction`, { student_transaction })
            return data
        }
        
        // TODO: next line returns JSON for web_pay. Conditionally return appropriate JSON file.
        return studentTransactionJSON
    }

    async function getVerifyWebPayTransaction(student_id, trans_ref) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/students/${student_id}/verify_transaction?trans_ref=${trans_ref}`)
            return data
        }
        return undefined
    }

    async function editStudent(student) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/students/${student.id}`,
                { student },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return editStudentJSON
    }

    async function getSelectedStudentRecords(student_ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/get_records`, {student_ids})
            return data
        }
        return showStudentJSON
    }
    
    async function downloadStudentListSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/students`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: action
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        archiveStudent,
        assignGuardian,
        blockStudents,
        unblockStudents,
        createStudent,
        deleteStudent,
        reEnrollStudentById,
        destroyBatchTransfer,
        editStudent,
        fetchAttendanceReport,
        financeSummary,
        getTransactionPreflight,
        getVerifyWebPayTransaction,
        postTransaction,
        search,
        showStudent,
        unassignGuardian,
        updateBatchTransfer,
        getSelectedStudentRecords,
        downloadStudentListSpreadsheet
    }
}

export default StudentService(TESTENV)