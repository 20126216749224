import './AddEvent.css'

import React from 'react'
import { Field, Form, converters, controlled } from "mstform";

import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types'
import Card from '../common/card';
import Label from '../common/form/Label';
import InlineError from '../common/form/InlineError';
import TextInput from '../common/form/TextInput';
import MultilineInput from '../common/form/MultilineInput';
import Button from '../common/button'
import EditFormLoading from '../common/loading/EditFormLoading';
import MobilePageHeader from '../containers/MobilePageHeader';
import calendar_icon from './assets/calendar.svg';
import { CreateEvent } from '../store/actions/Event'
import SwitchInput from '../common/form/SwitchInput'
import DateTimeInput from '../common/form/DateTimeInput'
import MultiselectInput from '../common/form/MultiselectInput'
import { mapModelNameToGlobalIDOptions } from '../lib/mapModelNameToOptions'


const validators = [(value) => (value.length > 0) ? false : 'Field is required']

const form = new Form(CreateEvent, {
    id: new Field(converters.maybeNull(converters.string)),
    title: new Field(converters.maybeNull(converters.string), { required: true, validators }),
    description: new Field(converters.maybeNull(converters.string)),
    start_time: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: true, validators }),
    end_time: new Field(converters.maybeNull(converters.string), { controlled: controlled.object, required: false }),
    common: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    all_day: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    holiday: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    user_groups_gid: new Field(converters.stringArray, { controlled: controlled.object, required: false}),
});

@inject("store", "display")
@observer
class AddEvent extends React.Component {
    
    constructor(props) {
        super(props)

        const { store, editMode } = this.props
        const { eventStore, view } = store
        const { selectedEventId } = view
        const { addEventFormInstance, createEvent, updateEvent, selectEventForEdit } = eventStore
        addEventFormInstance.reset()

        let action
        if (editMode) {
            selectEventForEdit(selectedEventId)
            action = updateEvent
        } else {
            action = createEvent
        }
        
        this.formState = form.state(addEventFormInstance, {
            save: async (node) => {
                const { data: updatedEvent, errors } = await action(node.toJSON())
                if (updatedEvent) {
                    this.setState({ updatedEvent })
                }
                return errors
            }
        })
    }
    

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { store: {eventStore: {resetAddEventForm}, view: {openAddEventPage}} } = this.props
            resetAddEventForm()
            openAddEventPage()
        }
    }

    handleSubmitAndExit = async () => {
        const success = await this.formState.save()
        const { updatedEvent } = this.state
        if (success && updatedEvent) {
            const { store } = this.props
            const { view: {openEventPage}, eventStore: {resetAddEventForm} } = store
            resetAddEventForm()
            openEventPage(updatedEvent)
        }
    }

    render() {
        const { editMode, store, display } = this.props
        
        const {
            view: {selectedEvent},
            eventStore: {
                addEventFormInstance: {isSaving, all_day: all_day_instance, common: common_instance}
            },
            meta: {
                attributes: {
                    student_categories,
                    employee_categories,
                    employee_departments,
                    employee_positions
                }
            },
            courseStore: {courses}
        } = store
        
        const isLoading = (editMode && !selectedEvent) || (editMode &&  selectedEvent.isLoading)

        const Header = (
            <MobilePageHeader>
                <div className="flex-row text-small text--uppercase">
                    <img src={calendar_icon} className="utils-prepend" alt="" />
                    {editMode ? "Edit" : "Add"} Event
                </div>
            </MobilePageHeader>
        )
        
        if (isLoading) {
            return (
                <div className="AddEvent">
                    {Header}
                    <EditFormLoading />
                </div>
            )
        }
        
        const userGroupOptions =  mapModelNameToGlobalIDOptions(
            [
                student_categories,
                employee_categories,
                employee_departments,
                employee_positions,
                courses
            ]
        )
        


        const title = this.formState.field("title")
        const description = this.formState.field("description")
        const start_time = this.formState.field("start_time")
        const end_time = this.formState.field("end_time")
        const common = this.formState.field("common")
        const all_day = this.formState.field("all_day")
        const holiday = this.formState.field("holiday")
        const user_groups_gid = this.formState.field("user_groups_gid")

        return (
            <div className="AddEmployee">
                {Header}

                <Card title="Event Details">

                    <div className="AddEvent-formGrid">
                        <div>
                            <Label text="Title" required={title.required}>
                                <InlineError field={title}>
                                    <TextInput {...title.inputProps} />
                                </InlineError>
                            </Label>
                            <Label text="Start Time" required={start_time.required}>
                                <InlineError field={start_time}>
                                    <DateTimeInput {...start_time.inputProps} />
                                </InlineError>
                            </Label>
                            {  all_day_instance !== true && (
                                <Label text="End Time" required>
                                    <InlineError field={end_time}>
                                        <DateTimeInput {...end_time.inputProps} />
                                    </InlineError>
                                </Label>
                            ) }
                            <Label text="All Day?" required={all_day.required} inline>
                                <SwitchInput {...all_day.inputProps} />
                            </Label>
                            <Label text="Is Holiday?" required={holiday.required} inline>
                                <SwitchInput {...holiday.inputProps} />
                            </Label>
                            <Label text="Is Common?" required={common.required} inline>
                                <SwitchInput {...common.inputProps} />
                            </Label>
                            <Label
                                text="Description"
                                required={description.required}
                                style={{ gridColumn: display.isMobile ? 'unset' : 'span 2' }}
                            >
                                <MultilineInput {...description.inputProps} />
                            </Label>
                        </div>

                        {  common_instance !== true && (
                            <div>
                                <Label text="Select Event Groups" required>
                                    <InlineError field={user_groups_gid}>
                                        <MultiselectInput
                                            {...user_groups_gid.inputProps}
                                            placeholder="Select Groups"
                                            options={userGroupOptions}
                                        />
                                    </InlineError>
                                </Label>
                            </div>
                        ) }

                    </div>

                </Card>

                <div className="AddEmployee-saveButton">
                    <Button
                        buttonType="success"
                        fullwidth
                        onClick={this.handleSubmit}
                        disabled={!this.formState.isValid || isSaving}
                    >Save and add another
                    </Button>
                    <Button
                        fullwidth
                        onClick={this.handleSubmitAndExit}
                        disabled={!this.formState.isValid || isSaving}
                        loading={isSaving}
                    >Save Event
                    </Button>
                </div>
            </div>
        )
    }
}
AddEvent.propTypes = {
    editMode: PropTypes.bool
}
AddEvent.defaultProps = {
    editMode: false
}

export default AddEvent
