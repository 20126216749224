import { types, applySnapshot } from "mobx-state-tree"

export const CreateEvent = types
    .model("CreateEvent", {
        id: types.maybeNull(types.string),
        title: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        start_time: types.maybeNull(types.string),
        end_time: types.maybeNull(types.string),
        common: types.optional(types.boolean, true),
        all_day: types.optional(types.boolean, true),
        holiday: types.optional(types.boolean, false),
        user_groups_gid: types.array(types.string),
        isSaving: false
    })
    .actions(self => ({
        setFormInstance(event) {
            const { 
                id,
                userGroupsGid: user_groups_gid,
                attributes: {
                    title,
                    description,
                    start_time,
                    end_time,
                    common,
                    all_day,
                    holiday,
                    
                }
            } = event
            applySnapshot(self, { 
                id, 
                title,
                description,
                start_time,
                end_time,
                common,
                all_day,
                holiday,
                user_groups_gid
             })
        },
        reset(){
            applySnapshot(self, {})
        }
    }))


export const SearchEvent = types
    .model('SearchEvent', {
        start_time: types.maybe(types.string),
        end_time: types.maybe(types.string),
        all_events: false,
        user_id: types.maybe(types.string)
    })
    .actions(self => ({
        setFormInstanceFromFullCalendarFetchInfo(viewInfo) {
            const { timeZone, start, end, startStr, endStr } = viewInfo
            applySnapshot(self, {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
                all_events: false
             })
        },
        setAllEvents(flag){
            self.all_events = flag
        }
    }))
