import './GuardianFinance.css'

import React from 'react'
import isEmpty from 'lodash.isempty'
import Desktop from '../common/display/Desktop'
import NotDesktop from '../common/display/NotDesktop'
import FinanceItemDesktop from './GuardianFinanceItemDesktop'
import Card from '../common/card'
import FinanceItemMobile from './GuardianFinanceItemMobile'
import EmptyList from '../common/empty_list'

export default function GuardianFinance({ wards }) {
  return (
    <div className="guardianFinance">
      {isEmpty(wards) ? <EmptyList /> :(
          <>
            <Desktop>
                <Card>
                {wards.map((ward) => (
                    <FinanceItemDesktop key={ward.id} ward={ward} />
                ))}
                </Card>
            </Desktop>
            <NotDesktop>
                {wards.map((ward) => <FinanceItemMobile key={ward.id} ward={ward} />)}
            </NotDesktop>
          </>
      )}
    </div>
  )
}
