import "./TransactionReceipt.css"
import "../students/StudentFinance.css"

import React, {Component} from 'react'
import {Row, Col} from "antd"
import { inject, observer } from 'mobx-react'
import ShowMoreText from 'react-show-more-text'
import isEmpty from "lodash.isempty"

import { display} from "../common/display"
import Card from '../common/card'
import { formatDateLong } from '../lib/formatDate'
import { titleCase } from '../lib/titleCase'
import Desktop from "../common/display/Desktop";
import NotDesktop from "../common/display/NotDesktop";
import MobilePageHeader from "../containers/MobilePageHeader";
import Table from "../common/table";
import StripeLoading from "../common/loading/StripeLoading";
import ContentLoader from '../common/content_loader'


import printer from '../common/assets/printer-blue.svg'
import pdf from '../common/assets/pdf.svg'
import more from "../students/assets/more-vert.svg";
import Loading from "../common/loading/Loading";

const feeTransactionsTableColumns = [
    { key: 'fee_type_name', render: 'Fee Type' },
    { key: 'fee_description', render: 'Description' },
    { key: 'amount', render: 'Amount' },
    { key: 'discount', render: 'Discount' },
    { key: 'balance', render: 'Balance' },
    { key: 'transaction_amount', render: 'Paid' },
]

const walletTransactionsTableColumns = [
    { key: 'debit', render: 'Debit' },
    { key: 'credit', render: 'Credit' }
]

const customTransactionsTableColumns = [
    { key: 'transaction_category_name', render: 'Category' },
    { key: 'expense_amount', render: 'Expense' },
    { key: 'income_amount', render: 'Income' },
    { key: 'note', render: 'Note' }
]


@inject('store')
@observer
class TransactionReceipt extends Component {
    render() {
        const {store, receiptData} = this.props
        const {formatCurrency} = store

        if ( !receiptData || !receiptData.isInitialized || !receiptData.isAssociationsLoaded) return <Loading />
        
        const { attributes, feeTransactions, walletTransactions, customTransactions, isLoading } = receiptData

        const {total_fee_paid, note} = attributes
        
        const feeTransactionsTableRows = feeTransactions.map((feeTransaction) => {
            const {
                id,
                attributes: {amount: transaction_amount},
                studentFee: {attributes: {fee_type_name, fee_description, total_discount, balance, fee_amount }}
            } = feeTransaction

            return {
                id,
                fee_type_name,
                fee_description,
                amount: formatCurrency(fee_amount),
                discount: formatCurrency(total_discount),
                balance: formatCurrency(balance),
                transaction_amount: formatCurrency(transaction_amount),
            }
        })

        const walletTransactionsTableRows = walletTransactions.map((walletTransaction) => {
            const {id, attributes: {credit, debit}} = walletTransaction
            
            return {id, credit: formatCurrency(credit), debit: formatCurrency(debit)}
        })

        const customTransactionsTableRows = customTransactions.map((customTransaction) => {
            const {attributes: {transaction_category_name, expense_amount, income_amount}} = customTransaction
            
            return {
                transaction_category_name,
                expense_amount: formatCurrency(expense_amount),
                income_amount: formatCurrency(income_amount),
                note
            }
        })

        return (
            <div className="TransactionReceipt StripeLoadingContainer">
                <StripeLoading loading={isLoading} />
                <Desktop>
                    <Card title={<TransactionReceiptHeader />}>
                        <TransactionReceiptStats receiptData={receiptData} />
                        {(walletTransactions.length > 0 || feeTransactions.length > 0 ) && (
                            <div className="Table Table--striped Table-responsive">
                                <table>
                                    {feeTransactions.length > 0 && (
                                        <thead>
                                        <tr>
                                            {feeTransactionsTableColumns.map(({ key, render }) => (
                                                <th key={key}>{render}</th>
                                            ))}
                                        </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                    {feeTransactions.length > 0 && feeTransactionsTableRows.map((row) => (
                                        <tr key={row.id}>
                                            {feeTransactionsTableColumns.map(({ key, style = {} }) => (
                                                <td style={style} key={key}>
                                                    {typeof row[key] === 'string' && (
                                                        <ShowMoreText
                                                            lines={3}
                                                            more='More'
                                                            less='Less'
                                                        >
                                                            {row[key]}
                                                        </ShowMoreText>
                                                    )}
                                                    {typeof row[key] === 'string' || row[key]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    
                                    
                                    {feeTransactions.length > 1 && (
                                        <tr className=" Table--bordered receipt-total text-p text--uppercase text--primary">
                                            <td colSpan="5" className="text--right">Fee Total</td>
                                            <td>{formatCurrency(total_fee_paid)}</td>
                                        </tr>
                                    )}
                                    

                                    {walletTransactions.length > 0 && (
                                        <>
                                            <tr className="Row--bordered-top  Table--bordered">
                                                <th rowSpan="2" colSpan="4">Wallet Transaction</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                            </tr>
                                            {walletTransactionsTableRows.map((row) => (
                                                <tr key={row.id} className="Table--bordered">
                                                    {walletTransactionsTableColumns.map(({ key, style = {} }) => (
                                                        <td style={style} key={key}>
                                                            {row[key]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </>
                                    )}


                                    {(walletTransactions.length > 0 || feeTransactions.length > 0 ) && (
                                        <GrandTotalRow receiptData={receiptData} />
                                    )}

                                    </tbody>
                                </table>
                            </div>
                        )}

                        {customTransactions.length > 0 && (
                            <Table
                                columns={customTransactionsTableColumns}
                                data={customTransactionsTableRows}
                                striped
                            />
                        )}
                    </Card>
                </Desktop>
                <NotDesktop>
                    <MobilePageHeader tablet>
                        <TransactionReceiptHeader />
                        <TransactionReceiptStats receiptData={receiptData} />
                    </MobilePageHeader>

                    {(Boolean(feeTransactions.length) || Boolean(walletTransactions.length) || Boolean(customTransactions.length)) && (
                        <>
                            {feeTransactions.map(item => <FeeTransactionsItem key={item.id} id={item.id} feeTransaction={item} />)}
                            {feeTransactions.length > 1 && (
                                <div className="Card mt-1 ant-row text-p text--uppercase text--primary">
                                    <Col xs={12} sm={12} md={20}>
                                        <span>Fee Total</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <span>{formatCurrency(total_fee_paid)}</span>
                                    </Col>
                                </div>
                            )}

                            {walletTransactions.map(item => <WalletTransactionsItem key={item.id} walletTransaction={item} />)}

                            {(Boolean(walletTransactions.length) || Boolean(feeTransactions.length) ) && (
                                <GrandTotalRow receiptData={receiptData} />
                            )}

                            {customTransactions.map(item => <CustomTransactionsItem key={item.id} customTransaction={item} note={note} />)}
                        </>
                    )}

                </NotDesktop>
            </div>
        )
    }
}


const FeeTransactionsItem = inject("store")(
    observer(({ store, feeTransaction }) => {
        const {formatCurrency} = store
        const {attributes: {amount: transaction_amount }, studentFee} = feeTransaction
        const {attributes: {fee_type_name, balance, total_paid, fee_description, total_discount, fee_amount}} = studentFee
        
        return (
            /* FIXME StudentFeesItem temporarily imported from StudentFinance Component CSS */
            <Card className="StudentFeesItem mt-1">
                <div className="flex-col flex-alignStart">
                    <div className="text--spacedLetters">
                        {fee_type_name} &middot; {formatCurrency(fee_amount)}
                    </div>
                </div>
                <div>{fee_description}</div>
                
                {(
                    <div className="flex-col flex-alignStart">
                        <div className="text-small text--uppercase text--bold text--spacedLetters">
                            Paid
                        </div>
                        <div>{formatCurrency(transaction_amount)}</div>
                    </div>
                )}
                
                {(parseInt(balance, 10) > 0 && (
                    <div className="flex-col flex-alignStart">
                        <div className="text-small text--uppercase text--bold text--spacedLetters">
                            Unpaid
                        </div>
                        <div className="text--danger">{formatCurrency(balance)}</div>
                    </div>
                ))}
                
                {(parseInt(total_discount, 10) > 0 && (
                    <div className="flex-col flex-alignStart">
                        <div className="text-small text--uppercase text--bold text--spacedLetters">
                            Discount
                        </div>
                        <div className="text--danger">{formatCurrency(total_discount)}</div>
                    </div>
                ))}
            </Card>
        )
    })
)

const WalletTransactionsItem = inject("store")(
    observer(({ store, walletTransaction }) => {
        const {formatCurrency} = store
        const {attributes: {credit, debit}} = walletTransaction
        
        return (
            <Card className="walletTransactionsItem mt-1">
                <Row gutter={15}>
                    <Col xs={12} sm={12} md={20}>
                        <div className="text--uppercase text-small text--bold wallet-transaction">Student Wallet</div>
                    </Col>
                    {debit && (
                        <Col xs={12} sm={12} md={4}>
                            <div className="text--uppercase text-small text--bold">Debit</div>
                            <div className="text-p text">{formatCurrency(debit)}</div>
                        </Col>
                    )}
                    {credit && (
                        <Col xs={12} sm={12} md={4}>
                            <div className="text--uppercase text-small text--bold">Credit</div>
                            <div className="text-p text">{formatCurrency(credit)}</div>
                        </Col>
                    )}
                </Row>
            </Card>
        )
    })
)

const CustomTransactionsItem = inject("store")(
    observer(({ store, customTransaction, note }) => {
        const {formatCurrency} = store
        const {attributes: {transaction_category_name, expense_amount, income_amount}} = customTransaction
        return (
            <Card className="customTransactionsItem mt-1">
                <Row gutter={15}>
                    <Col xs={12} sm={8} md={8}>
                        <div className="text--uppercase text-small text--bold">Category</div>
                        <div className="text-p text">{transaction_category_name}</div>
                    </Col>
                    {expense_amount && (
                        <Col xs={12} sm={4} md={4}>
                            <div className="text--uppercase text-small text--bold">Expense</div>
                            <div className="text-p text">{formatCurrency(expense_amount)}</div>
                        </Col>
                    )}
                    {income_amount && (
                        <Col xs={12} sm={4} md={4}>
                            <div className="text--uppercase text-small text--bold">Income</div>
                            <div className="text-p text">{formatCurrency(income_amount)}</div>
                        </Col>
                    )}
                    <Col xs={24} sm={12} md={12}>
                        <div className="text--uppercase text-small text--bold">Note</div>
                        <div className="text-p text">{note}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)

const TransactionReceiptHeader = inject("store")(
    observer(({ store, children }) => {
        const { view: {
            selectedFinanceTransactionId,
        } } = store
        return (
            <div className="TransactionReceipt-header">
                <span className="text--uppercase text-small text--spacedLetters">Transaction Receipt</span>

                <div className="flex-row">
                    {children}
                    <div className="TransactionReceipt-buttonGroup text-p">
                        <div className="flex-row flex-alignCenter touchable-highlight">
                            <a
                                href={`/finance/transactions/${selectedFinanceTransactionId}/pos`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={printer} alt="" className="utils-prepend" />
                                <span>POS</span>
                            </a>
                        </div>
                        <div className="flex-row flex-alignCenter touchable-highlight">
                            <a
                                href={`/finance/transactions/${selectedFinanceTransactionId}/a4`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={pdf} alt="" className="utils-prepend" />
                                <span>PDF</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

const TransactionReceiptStats = inject("store")(
    observer(({ store, receiptData }) => {
        const { student, attributes } = receiptData

        const { trans_ref, payment_method_name, payment_method, transaction_date, created_by_name, bank_details, teller_number, note} = attributes
        const wallet_balance = student ? student.walletBalance : undefined
        const fee_balance = student ? student.totalUnpaidStudentFees : undefined
        const {formatCurrency} = store

        return (
            <div className="TransactionReceiptStats">
                {student && (
                    <Row>
                        <Col span={24}>
                            <div className="text--uppercase text-small text--bold text--grey">Student Name</div>
                            <div className="text-h4 text--bold">{titleCase(student.studentName)}</div>
                        </Col>
                    </Row>
                )}
                <Row gutter={15}>
                    <Col xs={9} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Receipt No.</div>
                        <div className="text-p text">#{trans_ref}</div>
                    </Col>
                    <Col xs={15} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Payment Method</div>
                        <div className="text-p text">{payment_method_name}</div>
                    </Col>
                    {student && (
                        <React.Fragment>
                            {fee_balance > 0 && (
                                <Col xs={9} md={4} lg={3}>
                                    <div className="text--uppercase text-small text--bold text--grey">Fee balance</div>
                                    <div className="text-p text">{formatCurrency(fee_balance)}</div>
                                </Col>
                            )}
                            {wallet_balance > 0 &&  (
                                <Col xs={9} md={4} lg={3}>
                                    <div className="text--uppercase text-small text--bold text--grey">Wallet{display.isNotMobile && ' balance'}</div>
                                    <div className="text-p text">{formatCurrency(wallet_balance)}</div>
                                </Col>
                            )}
                        </React.Fragment>
                    )}
                    <Col xs={9} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Trans. Date</div>
                        <div className="text-p text">{formatDateLong(transaction_date)}</div>
                    </Col>
                    {payment_method === 'bank_teller' && (
                        <React.Fragment>
                            <Col xs={9} md={5} lg={4}>
                                <div className="text--uppercase text-small text--bold text--grey">Bank</div>
                                <div className="text-p text">{bank_details}</div>
                            </Col>
                            <Col xs={9} md={4} lg={2}>
                                <div className="text--uppercase text-small text--bold text--grey">Teller No</div>
                                <div className="text-p text">{teller_number}</div>
                            </Col>
                        </React.Fragment>
                    )}
                    <Col xs={15} md={8} lg={7}>
                        <div className="text--uppercase text-small text--bold text--grey">{payment_method === 'web_pay' ? 'Paid By' : 'Recorded By'}</div>
                        <div className="text-p text">{titleCase(created_by_name)}</div>
                    </Col>
                    {note && (
                        <Col xs={15} md={8} lg={7}>
                            <div className="text--uppercase text-small text--bold text--grey">Note</div>
                            <div className="text-p text">{note}</div>
                        </Col>
                    )}
                </Row>
            </div>
        )
    })
)

const GrandTotalRow = inject("store")(
    observer(({ store, receiptData }) => {
        const { attributes: { amount_received, amount_payed_out} } = receiptData
        const {formatCurrency} = store
        const amount = amount_payed_out || amount_received || 0
        const header = amount_payed_out ? "Amount Received" : "Total Paid"

        if (display.isDesktop){
            return (
                <tr className="Row--bordered-top text--bold  text--primary">
                    <td colSpan="5" className="text--right  text-h4">{header}</td>
                    <td>{formatCurrency(amount)}</td>
                </tr>
            )
        }

        return (
            <div className="Card mt-1 ant-row text-h4 text--bold text--uppercase  text--primary">
                <Col xs={12} sm={12} md={20}>
                    <span>{header}</span>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <span>{formatCurrency(amount)}</span>
                </Col>
            </div>
        )
    })
)

export default TransactionReceipt
export { TransactionReceiptHeader }