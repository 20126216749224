import { types, applySnapshot } from "mobx-state-tree"
import {dateType, today} from "../types/dateType"

export const EditFee = types
    .model("EditFee", {
        due_date: dateType,
        start_date: dateType,
        description: types.string,
        amount: types.string,
        fee_type: types.string
    })

export const CreateFee = types
    .model({
        fee_type_id: "",
        amount: "",
        description: "",
        start_date: types.optional(
            types.union(dateType, types.string),
            today
        ),
        due_date: types.optional(
            types.union(dateType, types.string),
            today
        ),
        status: ""
    }).actions(self => ({
    setFormInstance(feeModel) {
        const { amount, start_date, due_date , description, fee_type_id : { id :fee_type_id }, status } = feeModel;
        applySnapshot(self, { amount, start_date, due_date, description, fee_type_id, status });
    }
}));


export const CreateCustomTransaction = types
    .model({
        note: "",
        expense_amount: types.maybe(types.number),
        income_amount: types.maybe(types.number),
        payment_method: "",
        transaction_category_id: "",
        transaction_date: types.optional(
            types.union(dateType, types.string),
            today
        ),
    })