import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import ModalForm from '../containers/ModalForm';

@inject('store')
@observer
class DeleteGuardian extends React.Component {
    static propTypes = {
        guardianId: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired
    }

    handleSubmit = () => {
        const { store, guardianId } = this.props
        const { guardianStore } = store
        const { deleteGuardianById } = guardianStore
        deleteGuardianById(guardianId)
        const { onClose } = this.props
        onClose()
    }

    render() {
        const {store : {guardianStore: {isLoading}}, onClose} = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Destroy Record"
                okButtonType="danger"
                isOkButtonDisabled={isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--danger"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">
                            Are you sure you want to perform this action?
                        </div>
                        Use this option only if you created the Guardian record
                        by accident and want to remove it completely. All records
                        associated with Guardian will be deleted and <strong>cannot be recovered</strong>.
                    </div>
                </div>
            </ModalForm>
        )
    }
}
export default DeleteGuardian