import FileSaver from "file-saver";
import * as ReactGA from "react-ga";
import { types, flow } from "mobx-state-tree"

import {CollectionPattern} from "./patterns/collectionPattern";
import { WalletTransaction } from "./models/WalletTransaction";
import { FinanceTransactionSearch } from "./models/FinanceTransactionSearch";
import { filterType } from "./helpers/filterType";




export const WalletTransactionStore = types
    .compose(
        CollectionPattern({
            collectionType: WalletTransaction,
            searchType: FinanceTransactionSearch
        }),
        types
            .model("WalletTransactionStore", {})
            .views(self => ({
                get service() {
                    return self.bluebic.walletTransactionService
                },
                get searchService() {
                    return self.service.search
                },
            }))
            .actions(self => {

                function onUpdate(included) {
                    filterType("expense_account_transaction", included, self.updateCollection)
                }

                const downloadWalletTransactionListSpreadsheet = flow(function* downloadWalletTransactionListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadWalletTransactionListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Wallet Transactions Data',
                            nonInteraction: false,
                            value: action.item_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of Wallet Transaction list`, err)
                        action.markSaving(false)
                    }
                })

                const fetchFinanceSummary = flow(function* fetchFinanceSummary(transactionIDs) {
                    try {
                        const {data} = yield self.bluebic.walletTransactionService.fetchFinanceSummary(transactionIDs)
                        return {data}
                    } catch (err) {
                        console.error("Failed to fetch wallet transactions summary", err)
                        return {error: "Failed to fetch wallet transactions summary"}
                    }
                })

                return {
                    onUpdate,
                    downloadWalletTransactionListSpreadsheet,
                    fetchFinanceSummary
                }
            })
    )

