import React, { Component } from 'react'
import * as PropTypes from "prop-types";
import { inject, observer } from 'mobx-react';
import isEmpty from "lodash.isempty";
import {Col, Row} from "antd";

import ContentLoader from '../common/content_loader';

const LoadingText = (
    <div style={{ width: 150, height: 20 }}>
        <ContentLoader width="150" height="20">
            <rect x="20" y="2.5" rx="4" ry="4" width="130" height="15" />
        </ContentLoader>
    </div>
)

@inject("store")
@observer
class FinanceTransactionsSummary extends Component {
    
    static propTypes = {
        financeTransactionIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }

    constructor(props) {
        super(props)
        this.state = { financeSummary: {} }
    }

    async componentDidMount() {
        const { store, financeTransactionIDs } = this.props
        const { financeTransactionStore } = store
        // eslint-disable-next-line no-unused-vars
        const {data, error} = await financeTransactionStore.fetchFinanceSummary(financeTransactionIDs)
        if (data) this.setState({financeSummary: data})
    }
    
    render() {
        const { store: {formatCurrency} } = this.props
        const { financeSummary } = this.state

        return (
            <>
                <Row gutter={15} type="flex" className="text-h4 mb-1">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend text--right">TOTAL RECEIVED</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_received) || 'Nil'}</span>
                        )}
                    </Col>
                </Row>
                <Row gutter={15} type="flex" className="text-h4">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">TOTAL PAID OUT</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_paid_out)}</span>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

@inject("store")
@observer
class CustomTransactionsSummary extends Component {
    
    static propTypes = {
        customTransactionIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }

    constructor(props) {
        super(props)
        this.state = { financeSummary: {} }
    }

    async componentDidMount() {
        const { store, customTransactionIDs } = this.props
        const { customTransactionStore } = store
        // eslint-disable-next-line no-unused-vars
        const {data, error} = await customTransactionStore.fetchFinanceSummary(customTransactionIDs)
        if (data) this.setState({financeSummary: data})
    }
    
    render() {
        const { store: {formatCurrency} } = this.props
        const { financeSummary } = this.state

        return (
            <>
                <Row gutter={15} type="flex" align="right" className="text-h4 mb-1">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend text--right">TOTAL INCOME</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_income)}</span>
                        )}
                    </Col>
                </Row>
                <Row gutter={15} type="flex" className="text-h4">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">TOTAL EXPENSE</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_expense)}</span>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}



@inject("store")
@observer
class FeeTransactionsSummary extends Component {
    
    static propTypes = {
            feeTransactionIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
        }

        constructor(props) {
            super(props)
            this.state = { financeSummary: {} }
        }

        async componentDidMount() {
            const { store, feeTransactionIDs } = this.props
            const { feeTransactionStore } = store
            // eslint-disable-next-line no-unused-vars
            const {data, error} = await feeTransactionStore.fetchFinanceSummary(feeTransactionIDs)
            if (data) this.setState({financeSummary: data})
        }

        render() {
            const { store: {formatCurrency} } = this.props
            const { financeSummary } = this.state

            return (
                <>
                    <Row gutter={15} type="flex" className="text-h4 mb-1">
                        <Col span={12} className="text--right">
                            <span className="text--primary utils-prepend text--right">TOTAL PAID</span>
                        </Col>
                        <Col span={12}>
                            {isEmpty(financeSummary) ? LoadingText : (
                                <span className="utils-append">{formatCurrency(financeSummary.total_amount)}</span>
                            )}
                        </Col>
                    </Row>
                </>
            )
    }
}


@inject("store")
@observer
class WalletTransactionsSummary extends Component {
    static propTypes = {
        walletTransactionIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }

    constructor(props) {
        super(props)
        this.state = { financeSummary: {} }
    }

    async componentDidMount() {
        const { store, walletTransactionIDs } = this.props
        const { walletTransactionStore } = store
        // eslint-disable-next-line no-unused-vars
        const {data, error} = await walletTransactionStore.fetchFinanceSummary(walletTransactionIDs)
        if (data) this.setState({financeSummary: data})
    }

    render() {
        const { store: {formatCurrency} } = this.props
        const { financeSummary } = this.state

        return (
            <>
                <Row gutter={15} type="flex" className="text-h4 mb-1">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend text--right">TOTAL CREDIT</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_credit)}</span>
                        )}
                    </Col>
                </Row>
                <Row gutter={15} type="flex" className="text-h4">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">TOTAL DEBIT</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_debit)}</span>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}


export {FinanceTransactionsSummary, CustomTransactionsSummary, FeeTransactionsSummary, WalletTransactionsSummary}
