import { types } from "mobx-state-tree"
// eslint-disable-next-line import/no-cycle
import {Batch} from "./Batch";
import {formatDate} from "../../lib/formatDate";

export const StudentBatchTransfer = types
    .model("StudentBatchTransfer", {
        id: types.identifier,
        type: "student_batch_transfer",
        attributes: types.maybe(types.model({
            student_id: types.number,
            first_day_in_batch: types.maybeNull(types.string),
            last_day_in_batch: types.maybeNull(types.string),
            transfer_out_reason: types.maybeNull(types.string),
            batch_id: types.reference(Batch),
            is_active: types.boolean
        }))
    })
    .views(self => ({
        get batchName() {
            const { batch_id } = self.attributes
            return batch_id.attributes.name
        },
        get firstDay() {
            if (!self.attributes.first_day_in_batch) {
                return null
            }
            return formatDate(self.attributes.first_day_in_batch)
        },
        get lastDay() {
            if (!self.attributes.last_day_in_batch) {
                return null
            }
            return formatDate(self.attributes.last_day_in_batch)
        }
    }))
    // .actions(self => ({
    //     changeFirstDay(newFirstDay) {
    //         self.attributes.first_day_in_batch = newFirstDay
    //     },
    //     changeLastDay(newLastDay) {
    //         self.attributes.last_day_in_batch = newLastDay
    //     },
    //     changeBatchId(newBatchId) {
    //         self.attributes.batch_id = newBatchId
    //     },
    //     changeTransferOutReason(newReason) {
    //         self.attributes.transfer_out_reason = newReason
    //     },
    // }))