import './Notes.css'

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {observer, PropTypes as MxPropTypes} from 'mobx-react'
import {ModalContext} from '../modal'
import NoteItem, {Loading} from './NoteItem'
import CreateNote from './CreateNote'
import create from './assets/create.svg'
import Card from '../card';
import Alert from '../alert'

@observer
class Notes extends Component {
    static propTypes = {
        visibilityText: PropTypes.string,
        onCreateNote: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        notes: MxPropTypes.arrayOrObservableArray.isRequired,
        onDeleteNote: PropTypes.func.isRequired,
        onEditNote: PropTypes.func.isRequired,
        canCreateNote: PropTypes.bool
    }

    static defaultProps = {
        visibilityText: 'Visible',
        canCreateNote: false
    }

    onCreate = context => {
        const {onCreateNote} = this.props
        context.setContent(
            'New Note',
            <CreateNote
                onClose={context.closeModal}
                onCreate={onCreateNote}
            />
        )
    }

    render() {
        const {isLoading, notes, visibilityText, onToggleVisibility, onDeleteNote, onEditNote, canCreateNote} = this.props

        return (
            <div className="Notes">
                <Card className="Notes-head">
                    <div className="text-small text--uppercase text--spacedLetters utils-prepend">Recent Notes</div>
                    {
                        canCreateNote && (
                            <ModalContext.Consumer>
                                {context => (
                                    <button
                                        type="button"
                                        onClick={() => this.onCreate(context)}
                                        className="
                                          touchable-opacity
                                          flex-row
                                          utils-append
                                          text--bold
                                          text-small
                                          text--primary
                                          text--uppercase
                                        "
                                    >
                                        <img src={create} alt="" className="utils-prepend" />
                                        New Note
                                    </button>
                                )}
                            </ModalContext.Consumer>
                        )
                    }
                </Card>

                {isLoading && <Loading />}

                {isLoading || (
                    <>
                        {notes.length === 0 && (<Alert type="warning" title=" " message="No Notes Added" />)}

                        {notes.length > 0 && (
                            notes.map(note => (
                                <NoteItem
                                    key={note.id}
                                    note={note}
                                    canUpdateNote={canCreateNote}
                                    visibilityText={visibilityText}
                                    onToggleVisibility={onToggleVisibility}
                                    onDeleteNote={onDeleteNote}
                                    onEditNote={onEditNote}
                                />
                            ))
                        )}
                    </>
                )}
            </div>
        )
    }
}

export default Notes