/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './SelectButtonGroup.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


export default class SelectButtonGroup extends Component {
    
    static propTypes = {
        onChange: PropTypes.func,
        selectOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            })
        ),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }
    
    static defaultProps = {
        onChange: (e) => console.log(e),
        selectOptions: [],
        value: undefined
    }

    constructor(props) {
        super(props)
        const { value } = this.props
        this.state = { value }
    }

    static getSelectButtonClass(isActive) {
        return classNames(
            'SelectButtonGroup-button',
            'touchable-opacity',
            {
                'SelectButtonGroup-button-isActive': isActive
            }
        )
    }

    handleSelect = (value) => {
        const {onChange} = this.props
        this.setState({ value })

        onChange(value)
    }

    render() {
        const {selectOptions} = this.props
        const { value } = this.state
        
        return (
            <div className="SelectButtonGroup">
                {selectOptions.map(selectOption => (
                    <button
                        key={selectOption.value || 'nil'}
                        type="button"
                        className={SelectButtonGroup.getSelectButtonClass(selectOption.value === value )}
                        onClick={() => this.handleSelect(selectOption.value)}
                    >
                        {selectOption.label}
                    </button>
                ))}
            </div>
        )
    }
}