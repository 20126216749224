import './GuardianFinanceItem.css'

import React, {Component} from 'react'
import {Collapse} from 'react-collapse'
import {Checkbox, Popover} from 'antd'
import {observer, inject} from 'mobx-react'
import numeral from 'numeral'

import Tablet from '../common/display/Tablet'
import {ModalContext} from '../common/modal'
import Avatar from '../common/avatar'
import Button from '../common/button'
import Card from '../common/card'
import pdf from './assets/pdf.svg'
import seemoreBtn from './assets/See_more.svg';
import seelessBtn from './assets/See_less.svg';
import more from './assets/more-vert.svg'
import {handlePayFees} from "../students/PayFees";
import MobileConfirmDialog from "../common/mobile_confirm_dialog";

@inject('display', 'store')
@observer
class GuardianFinanceItemMobile extends Component {
    state = {
        showMore: false
    }

    toggleShowMore = () => {
        let {showMore} = this.state
        showMore = !showMore
        this.setState({showMore})
    }

    render() {
        const {ward, store, display} = this.props
        const {formatCurrency, meta: {currentUser}, studentStore: {deleteFeeById}} = store
        const {showMore} = this.state

        const {
            id: studentID,
            attributes: wardAttributes,
            studentName,
            admissionNumber,
            unpaidStudentFees,
            totalUnpaidStudentFees,
            selectedUnpaidFees,
            totalSelectedUnpaidFees,
            areAllUnpaidFeesSelected,
            selectAllUnpaidFees,
            selectUnpaidFeeById
        } = ward
        const {photo_url, wallet_balance, gender} = wardAttributes
        
        
        const middledot = '\u00B7'

        const feeIdsToPay = selectedUnpaidFees.length > 0 ? selectedUnpaidFees.toJSON() : unpaidStudentFees.map(({id}) => id)
        
        return (
            <div className="financeItem">
                <Card className="financeItem-summary">
                    <div className="studentDetails">
                        <Avatar sex={gender} src={photo_url} alt="" small />
                        <div className="text-h3">
                            {studentName}
                            <div className="text-p">{admissionNumber}</div>
                        </div>
                        <Tablet>
                            <div className="flex-row flex-alignCenter text-p" />
                        </Tablet>
                    </div>
                    
                    <div className="flex-row flex-alignCenter mt-1 studentFinanceSummary">
                        <div className="flex-row flex-alignCenter walletBalance">
                            <span className="utils-prepend">Wallet Balance:</span>
                            <span className={`${numeral(wallet_balance).value() > 0 ? 'text--primary  text--bold' : ''}`}>{formatCurrency(wallet_balance)}</span>
                        </div>
                        <div className="flex-row flex-alignCenter dueFees">
                            <span className="utils-prepend">Due Fees:</span>
                            <span className={`${numeral(totalUnpaidStudentFees).value() > 0 ? 'text--danger text--bold' : ''}`}>{formatCurrency(totalUnpaidStudentFees)}</span>
                        </div>
                    </div>
                    
                    <div className="seeMoreButton flex-row">
                        <div className="touchable-opacity" onClick={this.toggleShowMore}>
                            {showMore ? <img src={seemoreBtn} alt="see more" /> : <img src={seelessBtn} alt="see less" />}
                        </div>
                    </div>
                    <div className="payButton">
                        <ModalContext.Consumer>
                            {(context) => (
                                <Button
                                    buttonType="success"
                                    onClick={() => handlePayFees(context, feeIdsToPay, studentID)}
                                >
                                    { totalUnpaidStudentFees > 0 && (
                                        <>Pay {selectedUnpaidFees.length > 0 ? `Selected (${formatCurrency(totalSelectedUnpaidFees)})` : `Due Fees${ showMore ? ` (${formatCurrency(totalUnpaidStudentFees)})` : ''}`}</>
                                    ) }

                                    { totalUnpaidStudentFees <= 0 && (
                                        <>Credit Student Wallet</>
                                    ) }

                                </Button>
                            )}
                        </ModalContext.Consumer>
                    </div>
                    { false && (
                        <div className="printButtonnnn flex-row flex-justifyCenter text-p touchable-opacity">
                            <img src={pdf} alt="pdf" className="utils-prepend" />
                            Print
                        </div>
                    )}


                    <Collapse isOpened={display.isDesktop ? true : showMore}>
                        <div>
                            {unpaidStudentFees.map((unpaidStudentFee) => {
                                const {id: feeID, attributes: {fee_description, fee_type_name, fee_amount, total_paid, balance}} = unpaidStudentFee
                                
                                const PopoverContent = (
                                    <React.Fragment>
                                        <ModalContext.Consumer>
                                            {context => (
                                                <>
                                                    <div
                                                        onClick={() => handlePayFees(context, [feeID], studentID)}
                                                        className="Button Button--clear touchable-opacity"
                                                    >
                                                        <span className="text-p">Pay</span>
                                                    </div>
                                                    <div className="divider" />
                                                    {
                                                        currentUser.is_employee_or_admin && (
                                                            <button
                                                                onClick={() => context.setContent(
                                                                    "Unassigning student fee...",
                                                                    <MobileConfirmDialog
                                                                        handleConfirm={() => {
                                                                            deleteFeeById(feeID)
                                                                            context.closeModal()
                                                                        }}
                                                                        handleCancel={context.closeModal}
                                                                    />,
                                                                    true
                                                                )}
                                                                type="button"
                                                                className="Button Button--clear touchable-opacity text--danger text-p"
                                                            >
                                                                Unassign
                                                            </button>
                                                        )
                                                    }
                                                </>
                                            )}
                                        </ModalContext.Consumer>
                                    </React.Fragment>
                                )
                                
                                return (
                                    <Card className="financeItem-card" key={feeID}>
                                        <Checkbox checked={selectedUnpaidFees.includes(feeID)} onChange={() => selectUnpaidFeeById(feeID)} />
                                        {renderAttribute(`${fee_type_name}\xa0\xa0${middledot}\xa0\xa0`, formatCurrency(fee_amount))}
                                        {renderAttribute('', fee_description)}
                                        {renderAttribute('Unpaid', formatCurrency(balance))}
                                        {renderAttribute('Paid', formatCurrency(total_paid))}

                                        <Popover content={PopoverContent} placement="bottomRight">
                                            <div className="touchable-opacity flex-justifySelfEnd flex-alignSelfEnd" style={{paddingBottom: 4}}>
                                                <img src={more} alt="more" />
                                            </div>
                                        </Popover>
                                    </Card>
                                )
                            })}
                        </div>
                    </Collapse>
                </Card>
            </div>
        )
    }
}

export default GuardianFinanceItemMobile

function renderAttribute(label, value) {
    return (
        <div>
            <div>{label}</div>
            <div className={label === 'Unpaid' ? "text--danger " : (label === 'Paid' ? "text--primary " : " ")}>
                {value}
            </div>
        </div>
    )
}
