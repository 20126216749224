import React, {Component} from 'react'
import {inject,observer} from "mobx-react";
import SettingPolicy from "../../policies/SettingPolicy";
import help from './assets/help.svg';
import settings from './assets/settings.svg'
import {HELPSCOUT_BEACON_ID} from "../../service/constants";

@inject('store', 'sidebar', 'display')
@observer
class SidebarFooter extends Component {
    state = {
        helpScoutBeaconOpen: false
    }
    
    toggleHelpScoutBeacon = () => {
        let { helpScoutBeaconOpen } = this.state
        helpScoutBeaconOpen = !helpScoutBeaconOpen
        this.setState({ helpScoutBeaconOpen })

        if (window.Beacon){
            if (helpScoutBeaconOpen){
                window.Beacon('init', HELPSCOUT_BEACON_ID)
                window.Beacon('open')
            } else{
                window.Beacon('close')
                window.Beacon('destroy')
            }
        }
    }
    
    render() {
        
        const {store: {meta: {currentUser, currentInstitution: {legacyLoginUrl}}}, sidebar, display} = this.props
        const settingPolicy = new SettingPolicy(currentUser, 'Setting')

        return (
            <div className="sidebarFooter">
                <div className="sidebarFooter-divider" />
                {settingPolicy.can_show && (
                    <a
                        // href="/configuration"
                        // onClick={(e) => {
                        //     e.preventDefault();
                        //     sidebar.toggleSettingsPane();
                        //     return false;
                        // }}
                        href={legacyLoginUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                       className="touchable-opacity sidebarItem settings-action"
                    >
                        {!display.isMobile && (<img src={settings} className="sidebarFooter-actionIcon" alt="" />)}
                        {!sidebar.isSettingsPaneVisible || display.isMobile ? 'Settings' : ''}
                    </a>
                )}
                {!sidebar.isSettingsPaneVisible || display.isMobile ? (
                    <div className="sidebarFooter-actions">
                        <a
                            href="https://www.bluebic.com/help"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="sidebarFooter-action touchable-highlight help"
                            onClick={(e)=> {
                                e.preventDefault()
                                this.toggleHelpScoutBeacon()
                            }}
                        >
                            <img src={help} className="sidebarFooter-actionIcon" alt="" />
                            {!sidebar.isSettingsPaneVisible || display.isMobile ? 'Help' : ''}
                        </a>
                        <a href="https://www.bluebic.com/terms" target="_blank" rel="noopener noreferrer" className="white-link touchable-opacity">Terms</a>
                        <div>&middot;</div>
                        <a href="https://www.bluebic.com/privacy" target="_blank" rel="noopener noreferrer" className="white-link touchable-opacity">Privacy</a> 
                    </div>
                ): ''}
            </div>
        )
    }
}


export default SidebarFooter
