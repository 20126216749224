import React from 'react';
import * as ReactGA from "react-ga";
import { inject, observer } from 'mobx-react';
import {controlled, converters, Field, Form} from 'mstform';
import * as PropTypes from "prop-types";

import ModalForm from '../containers/ModalForm';
import Label from '../common/form/Label';
import InlineError from '../common/form/InlineError';
import {BatchSetupTransfer} from "../store/actions/BatchSetupTransfer";
import SelectInput from "../common/form/SelectInput";
import SwitchInput from "../common/form/SwitchInput";
import {mapModelNameToOptions} from "../lib/mapModelNameToOptions";


export function handleImportSetup(context, {batch_id}) {
    const { setContent, closeModal } = context

    return setContent(
        "Import Setup",
        <ImportBatchSetupForm
            closeModal={closeModal}
            batch_id={batch_id}
        />,
        true
    )
}


const form = new Form(BatchSetupTransfer, {
    from_batch_id: new Field(converters.string, { controlled: controlled.object, required: true }),
    transfer_batch_employee_assignments: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    transfer_subjects: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    transfer_subject_teachers: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    transfer_assessments: new Field(converters.boolean, { controlled: controlled.object, required: true}),
    transfer_learning_domains: new Field(converters.boolean, { controlled: controlled.object, required: true}),
})


@inject('store', 'display')
@observer
class ImportBatchSetupForm extends React.Component {
    static propTypes = {
        batch_id: PropTypes.string.isRequired,
        closeModal: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)

        const { batch_id, store } = props;
        const { batchStore: {batchSetupTransfer} } = store
        
        const formInstance = BatchSetupTransfer.create({})

        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors } = await batchSetupTransfer(batch_id, node)
                if (errors) this.setState({baseFormErrors: errors.base})
                return errors
            }
        })

        this.state = {formModel: formInstance, baseFormErrors: undefined}
    }
    
    componentDidMount() {
        ReactGA.modalview('ImportBatchSetupFormModal');
        // ReactGA.event({
        //     category: 'Communication',
        //     action: 'Open Send Message Modal',
        //     nonInteraction: false,
        //     // 'label: 'Game Widget'',
        //     // value: undefined,
        // });
    }


    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const {baseFormErrors} = this.state
        const { formModel: {isSaving} } = this.state
        const { closeModal, store:{ batchStore : { items }} } = this.props
        
        const from_batch_id = this.formState.field("from_batch_id")
        const transfer_batch_employee_assignments = this.formState.field("transfer_batch_employee_assignments")
        const transfer_subjects = this.formState.field("transfer_subjects")
        const transfer_subject_teachers = this.formState.field("transfer_subject_teachers")
        const transfer_assessments = this.formState.field("transfer_assessments")
        const transfer_learning_domains = this.formState.field("transfer_learning_domains")
        
        
        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={isSaving ? "Sending..." : "Import "}
                isOkButtonDisabled={!this.formState.isValid || isSaving}
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}

                <Label text="From Batch" required={from_batch_id.required}>
                    <InlineError field={from_batch_id}>
                        <SelectInput
                            {...from_batch_id.inputProps}
                            options={mapModelNameToOptions(items)}
                            placeholder="Select Batch to copy/import from"
                        />
                    </InlineError>
                </Label>
                <Label text="Transfer Batch Employee Roles" required={transfer_batch_employee_assignments.required}>
                    <InlineError field={transfer_batch_employee_assignments}>
                        <SwitchInput {...transfer_batch_employee_assignments.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Transfer Subjects" required={transfer_subjects.required}>
                    <InlineError field={transfer_subjects}>
                        <SwitchInput {...transfer_subjects.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Transfer Subject Teachers" required={transfer_subject_teachers.required}>
                    <InlineError field={transfer_subject_teachers}>
                        <SwitchInput {...transfer_subject_teachers.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Transfer Assessments" required={transfer_assessments.required}>
                    <InlineError field={transfer_assessments}>
                        <SwitchInput {...transfer_assessments.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Transfer Learning Domains" required={transfer_learning_domains.required}>
                    <InlineError field={transfer_learning_domains}>
                        <SwitchInput {...transfer_learning_domains.inputProps} />
                    </InlineError>
                </Label>
                
            </ModalForm>
        )
    }
}

export default  ImportBatchSetupForm