import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import ContentLoader from '../content_loader';
import Card from '../card';

const GroupContainer = posed.div({
    enter: { opacity: 1, delay: 200, beforeChildren: true },
    exit: { opacity: 0 }
});

const ListContainer = posed.div({
    enter: { staggerChildren: 50 },
    exit: { staggerChildren: 20, staggerDirection: -1 }
});

const Item = posed.div({
    enter: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 }
});

export default function MobileListLoading({ isLoading, children }) {
    return (
        <PoseGroup>
            {isLoading && (
                <GroupContainer key="chi">
                    <ListContainer>
                        <LoadingList />
                    </ListContainer>
                </GroupContainer>
            )}
            {isLoading || (
                <GroupContainer key="bar">
                    <ListContainer>
                        {children.map((child) => <Item key={child.props.id}>{child}</Item>)}
                    </ListContainer>
                </GroupContainer>
            )}
        </PoseGroup>
    )
}

function LoadingList() {
    return (
        Array(5).fill().map((_, idx) => (
            <Item key={idx}>
                <Card className="mb-1">
                    <ContentLoader height="80" width="400">
                        <rect x="0" y="5" rx="1" ry="1" width="30%" height="15" />
                        <rect x="0" y="35" rx="1" ry="1" width="40%" height="15" />
                        <rect x="0" y="65" rx="1" ry="1" width="15%" height="15" />
                    </ContentLoader>
                </Card>
            </Item>
        ))
    )
}
