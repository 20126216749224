import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import { Field, Form as MstForm, converters, controlled } from "mstform"
import MultilineInput from '../common/form/MultilineInput'
import Label from '../common/form/Label'
import { DropStudent } from '../store/actions/Student';
import DateInput from '../common/form/DateInput';
import Alert from '../common/alert'
import ModalForm from '../containers/ModalForm';
import { formatDate } from '../lib/formatDate';

const formModel = new MstForm(DropStudent, {
    date_dropped: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    reason_for_leaving: new Field(converters.string, {
        controlled: controlled.string,
        required: true
    })
})

@inject('store')
@observer
class ArchiveStudent extends React.Component {
    static propTypes = {
        studentId: PropTypes.string.isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    constructor(props) {
        super(props)
        const { store, studentId } = props
        const { studentStore } = store
        const { archiveStudentById } = studentStore
        const formInstance = DropStudent.create({
            date_dropped: formatDate(Date.now()),
            reason_for_leaving: ""
        })
        this.formState = formModel.state(formInstance, {
            save: async (node) => archiveStudentById(studentId, node.toJSON())
        })
    }

    handleSubmit = () => {
        this.formState.save()
        const { onClose } = this.props
        onClose()
    }

    render() {
        const date_dropped = this.formState.field("date_dropped")
        const reason_for_leaving = this.formState.field("reason_for_leaving")
        const { onClose, store: {studentStore: {isLoading}} } = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Move To Archive"
                isOkButtonDisabled={!this.formState.isValid || isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--info"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">Student leaving institution</div>
                        For students leaving the institution.
                        Use this option to remove them from the list
                        of enrolled students and place them in the
                        dropped students list.
                    </div>
                </div>

                <Label text="Last Day In Batch" required={date_dropped.required}>
                    <DateInput {...date_dropped.inputProps} />
                </Label>

                <div className="mb-1">
                    <Alert
                        type="warning"
                        message="
                            Be sure to select the last date student is 
                            assumed to have been an active student of this 
                            institution. Selected date has effects accross
                            the application and cannot be edited or reversed.
                        "
                    />
                </div>

                <Label text="Reason" required={reason_for_leaving.required}>
                    <MultilineInput
                        {...reason_for_leaving.inputProps}
                        placeholder="eg: moved to a different town and changed school"
                    />
                </Label>
            </ModalForm>
        )
    }
}
export default ArchiveStudent