import { types } from "mobx-state-tree"

export const BehaviouralDomainScore = types
    .model("BehaviouralDomainScore", {
        id: types.identifier,
        type: "behavioural_domain_score",
        attributes: types.maybe(types.model({
            student_id: types.number,
            indicator_id: types.number,
            course_set_id: types.number,
            grade_scale_level_id: types.number,
            term: types.string,
        })),
    })
    .views(self => ({
        get termID(){
            const termName = self.attributes.term
            switch (termName) {
                case "first_term": return 1;
                case "second_term": return 2;
                case "third_term": return 3;
                default: throw new Error('Unknown term name');
            }
        },
    }))