import React from 'react'
import PropTypes from 'prop-types'
import { Spin, Icon } from 'antd';

import './Loading.css'


export default function Loading({ color, message, fontSize, direction, contentWrapperLoader, inline }) {

    const antIcon = <Icon type="loading" style={{ color, fontSize: `${fontSize}px` }} spin />;

    const flexDirection = direction === 'column' ? 'flex-col' : 'flex-row'
    const CWL = contentWrapperLoader ? 'contentWrapperLoader' : undefined
    const INL = inline ? 'inline' : undefined

    return (
        <div
            className={[
                flexDirection,
                CWL,
                INL,
                'flex-justifyCenter flex-alignCenter'
            ].join(' ')}
            style={{color, fontSize: `${fontSize}px`}}
        >
            <Spin indicator={antIcon} />{'\u00A0\u00A0'}{message}
        </div>
    )
}


Loading.propTypes = {
    message: PropTypes.string,
    fontSize: PropTypes.number,
    direction: PropTypes.oneOf(['column', 'row']),
    contentWrapperLoader: PropTypes.bool,
    color: PropTypes.string,
    inline: PropTypes.bool
}
Loading.defaultProps = {
    message: 'Please wait...',
    contentWrapperLoader: true,
    direction: 'row',
    fontSize: 24,
    color: '#1C75BB',
    inline: false
}