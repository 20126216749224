import React from "react";
import { observer, inject } from "mobx-react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { sidebar } from "./index";
import arrowRight from "./assets/arrow-right.png";
import NotDesktop from "../display/NotDesktop";

function SidebarSettingsPane({ store: { view } }) {
    const settingsPaneClass = classNames('settingsPane', {
        'settingsPane-isHidden': !sidebar.isSettingsPaneVisible,
    });

    return (
        <div className={settingsPaneClass}>
            <h3 className="settingsPaneTitle">Settings</h3>
            <div className="settingsPaneItemList">
                <SettingsPaneItem
                    label="School Details"
                    isOpened={view.page === 'settings/school-details'}
                    href="settings/school-details"
                    handleClick={view.openSchoolDetailsPage}
                />
                <SettingsPaneItem
                    label="Academic Sessions"
                    isOpened={view.page === 'settings/academic-sessions'}
                    href="settings/academic-sessions"
                    handleClick={view.openAcademicSessionsPage}
                />
                <SettingsPaneItem
                    label="Classes"
                    isOpened={view.page === 'settings/classes'}
                    href="settings/classes"
                    handleClick={view.openClassesPage}
                />
                <SettingsPaneItem
                    label="Class Sets"
                    isOpened={view.page === 'settings/class-sets'}
                    href="settings/class-sets"
                    handleClick={view.openClassSetsPage}
                />
                <SettingsPaneItem
                    label="Subject Names"
                    isOpened={view.page === 'settings/subject-names'}
                    href="settings/subject-names"
                    handleClick={view.openSubjectNamesPage}
                />

            </div>
        </div>
    );
}

export default inject('store')(observer(SidebarSettingsPane));

function SettingsPaneItem({ label, href, isOpened, handleClick }) {
    const settingsPaneItemClass = classNames(
        'touchable-highlight settingsPaneItem',
        { 'settingsPaneItem-isOpen': isOpened }
    );

    return (
        <a
            href={href}
            className={settingsPaneItemClass}
            onClick={(e) => {
                e.preventDefault();
                handleClick();
                sidebar.toggleSettingsPane(false);
                return false;
            }}
        >
            {label}
            <NotDesktop>
                <img src={arrowRight} alt="icon: left arrow" />
            </NotDesktop>
        </a>
    );
}

SettingsPaneItem.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    isOpened: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired
};
