// https://stackoverflow.com/questions/10014271/generate-random-color-distinguishable-to-humans

export const Colors = {}

Colors.names = {
    black: "#000000",
    aqua: "#00ffff",
    blue: "#0000ff",
    darkviolet: "#9400d3",
    indigo: "#4b0082",
    darkcyan: "#008b8b",
    darkgrey: "#a9a9a9",
    darkorange: "#ff8c00",
    red: "#ff0000",
    lime: "#00ff00",
    darksalmon: "#e9967a",
    fuchsia: "#ff00ff",
    lightblue: "#add8e6",
    yellow: "#ffff00",
    lightgreen: "#90ee90",
    darkred: "#8b0000",
    darkkhaki: "#bdb76b",
    darkolivegreen: "#556b2f",
    pink: "#ffc0cb",
    purple: "#800080",
    lightgrey: "#d3d3d3",
    brown: '#9a6324',
    navy: "#000080",
};

Colors.random = function() {
    let result;
    let count = 0;
    for (const prop in this.names){
        count += 1
        if (Math.random() < 1/ count) result = prop;
    }
        
    return result;
};