import './BatchAttendanceReport.css'
import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import Table from '../common/table'
import Card from '../common/card'
import Mobile from '../common/display/Mobile'
import NotMobile from '../common/display/NotMobile'
import AttendanceReportLoading from '../common/loading/AttendanceReportLoading'
import StripeLoading from '../common/loading/StripeLoading'
import pdf from '../common/assets/pdf.svg'
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup'

@inject("store", "display")
@observer
class BatchAttendanceReport extends React.Component {

    componentDidMount() {
        const { store, batchDetails } = this.props
        const { batchStore: { loadAttendanceReportById } } = store

        loadAttendanceReportById(batchDetails.id)
    }

    handleTermSelect = selectedValue => {
        const { store, batchDetails } = this.props
        const { batchStore: { loadAttendanceReportById } } = store
        const { attendanceReport: {setAcademicTermFilter} } = batchDetails

        setAcademicTermFilter(selectedValue)
        loadAttendanceReportById(batchDetails.id)
    }

    render() {
        const { batchDetails, store: { view : viewStore}, display: {isMobile} } = this.props
        const { attendanceReport } = batchDetails
        
        if (isEmpty(attendanceReport) || isEmpty(attendanceReport.attributes)) return <AttendanceReportLoading />

        const { isLoading, attributes, daysOpenedCount, reportStudents } = attendanceReport

        const sortedStudents = reportStudents.sort((a, b) =>
            a.studentName.localeCompare(b.studentName, 'en', {sensitivity: 'base', ignorePunctuation: true})
        )

        const { students_report } = attributes
        const attendanceReportClassName = classNames(
            'BatchAttendanceReport-card mb-1',
            { 'AttendanceReport-loading': isLoading },
        )

        let average
        if (students_report.length > 0) {
            const sum = students_report.reduce((a, b) => ({
                percentage: a.percentage + b.percentage,
            }))
            average = parseFloat(
                (sum.percentage / students_report.length).toFixed(2),
            )
        }
        
        const termOptions = [
            { label: isMobile ? 'All' : 'Session' ,value: undefined },
            { label: 'First Term', value: '1' },
            { label: 'Second Term', value: '2' },
            { label: 'Third Term', value: '3' },
        ]

        return (
            <>
                <Card
                    title="BATCH ATTENDANCE REPORT"
                    style={{ marginBottom: 10, position: ' relative' }}
                >
                    <NotMobile>
                        <div className="BatchAttendanceReport-print flex-row flex-alignCenter text-p touchable-opacity">
                            <img src={pdf} className="utils-prepend" alt="" />
                            <span>Print as Pdf</span>
                        </div>
                    </NotMobile>
                    <div className="BatchAttendanceReport-summary1">
                        <SelectButtonGroup
                            selectOptions={termOptions}
                            onChange={this.handleTermSelect}
                        />
                        <div className="BatchAttendanceReport-summary2">
                            <div className='text--alignCenter'>
                                <div className="text--grey">Days Opened</div>
                                <div>{daysOpenedCount}</div>
                            </div>
                            <div className='text--alignCenter'>
                                <div className="text--grey">Average %</div>
                                <div>{average}</div>
                            </div>
                        </div>
                    </div>
                </Card>

                <NotMobile>
                    <NotMobileReport studentsAttendanceReport={attendanceReport} students={sortedStudents} viewStore={viewStore} />
                </NotMobile>
                <Mobile>
                    <div style={{ position: 'relative' }}>
                        <StripeLoading loading={isLoading} />
                        {sortedStudents.map(({ id, studentName }) => {

                            const report = students_report.find(a => a.student_id  === parseInt(id, 10))
                            
                            const {
                                student_id,
                                full_day_leaves,
                                half_day_leaves,
                                score,
                                percentage,
                            } = report
                                
                            return (
                                <Card key={id} className={attendanceReportClassName}>
                                    <div className="text--bold text--center text--primary mb-1">
                                        <a
                                            href={`/students/${student_id}`}
                                            onClick={e => {
                                                e.preventDefault()
                                                viewStore.openStudentPageById(student_id.toString())
                                            }}
                                        >
                                            {studentName}
                                        </a>
                                    </div>
                                    <div className="BatchAttendanceReport-details">
                                        <div>
                                            <div className="text--uppercase text-small text--bold text--spacedLetters">
                                                Full Day
                                            </div>
                                            <div className="text--center">
                                                {full_day_leaves}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text--uppercase text-small text--bold text--spacedLetters">
                                                Half Day
                                            </div>
                                            <div className="text--center">
                                                {half_day_leaves}
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text--uppercase text-small text--bold text--spacedLetters"
                                            >
                                                percent.(%)
                                            </div>
                                            <div className="text--center">
                                                {parseFloat(percentage.toFixed(2))}
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text--uppercase text-small text--bold text--spacedLetters"
                                            >
                                                Score
                                            </div>
                                            <div className="text--center">
                                                {score}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )

                        })}
                    </div>
                </Mobile>
            </>
        )
    }
}

export default BatchAttendanceReport

const NotMobileReport = observer(({ studentsAttendanceReport, students, viewStore }) => {
    const { isLoading, attributes } = studentsAttendanceReport
    const { students_report } = attributes
    const columns = [
        { key: 'student_name', render: '' },
        { key: 'full_day_leaves', render: 'Full Day.' },
        { key: 'half_day_leaves', render: 'Half Day' },
        { key: 'score', render: 'score' },
        { key: 'percentage', render: 'Percent. (%)' },
    ]

    const tableData = students.map(({ id, studentName }) => {


        const report = students_report.find(a => a.student_id  === parseInt(id, 10))

        const {
            student_id,
            full_day_leaves,
            half_day_leaves,
            score,
            percentage,
        } = report
        
        const pct = parseFloat(percentage.toFixed(2))
        
        return {
            
            student_name: (
                <a
                    href={`/students/${student_id}`}
                    onClick={e => {
                        e.preventDefault()
                        viewStore.openStudentPageById(student_id.toString())
                    }}
                >
                    {studentName}
                </a>
            ),
            full_day_leaves,
            half_day_leaves,
            score,
            percentage: pct
        }
    })

    return (
        <Table
            striped
            containerClass="Card"
            columns={columns}
            data={tableData}
            onError={e => console.log(e)}
            loading={isLoading}
            onColumnKeysChange={e => console.log(e)}
        />
    )
})