import './SchoolSwapMenu.css'

import React from 'react'
import { inject } from 'mobx-react';
import PropTypes from 'prop-types'
import className from 'classnames'
import logo from './assets/bluebicLogo.png'
import close from './assets/close.svg'
import check from './assets/check.svg'
import plus from './assets/plus.svg'

@inject("store")
class SchoolSwapMenu extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  }

  render() {
    const { visible, store: {institutions, meta: {currentInstitution}} } = this.props
      
      const schools_info = []
      institutions.forEach(({id, attributes: {name}}) => {
          schools_info.push({name, isActive: currentInstitution.id === id})
      })
      
      
    const menuClass = className('schoolSwapMenu', {
      'schoolSwapMenu-isHidden': !visible
    })
    function getMenuItemClass(isActive) {
      return className('schoolSwapMenu-item', 'touchable-highlight', {
        'schoolSwapMenu-item-isActive': isActive
      })
    }

    return (
      <div className={menuClass}>
        <div className="schoolSwapMenu-header">
          <div className="flex-row">
            <img className="schoolSwapMenu-logo" src={logo} alt="" />
            <span className="schoolSwapMenu-title">Your Bluebic Account</span>
          </div>
          <img
            className="schoolSwapMenu-closeButton touchable-opacity"
            src={close}
            onClick={this.props.onClose}
            alt=""
          />
        </div>
        {schools_info.map((s) => (
          <div key={s.name} className={getMenuItemClass(s.isActive)}>
            {s.name}
            {s.isActive && <img src={check} alt="" />}
          </div>
        ))}
        <div className="schoolSwapMenu-item schoolSwapMenu-item--secondary touchable-highlight">
          <a href="https://www.bluebic.com/get-started" target="_blank" rel="noopener noreferrer">
            <img className="utils-prepend" src={plus} alt="" />
            Add a New School
          </a>
        </div>
      </div>
    )
  }
}

export default SchoolSwapMenu