import { types } from "mobx-state-tree"
import * as uuidv1 from 'uuid/v1';



function assignUUID(snapshot) {
    if (!snapshot.id) {
        return Object.assign({}, snapshot, { id: uuidv1() });
    }
    return Object.assign({}, snapshot);
}

export const statusOptions = ["editing", "saving", "failed"];

export const UpdateAssessmentScore = types
    .model("UpdateAssessmentScore", {
        id: types.identifier,
        assessment_id: types.string,
        student_id: types.string,
        comment: types.maybeNull(types.string),
        late: types.optional(types.boolean, false),
        collected: types.optional(types.boolean, false),
        missing:types.optional(types.boolean, false),
        exempt: types.optional(types.boolean, true),
        score: types.maybeNull(types.string),
        cell_value: types.maybe(types.string),
        status: types.optional(types.enumeration(statusOptions), statusOptions[0]),
    })
    .preProcessSnapshot(snapshot => assignUUID(snapshot))
    .actions(self => ({
        
        toggleLate(value){
            self.late = value
        },
        
        toggleCollected(value){
            self.collected = value
        },
        
        toggleExempt(value){
            self.exempt = value
        },
        
        toggleMissing(value){
            self.missing = value
        },
        
        setComment(value){
            self.comment = value
        },
        
        setCellValue(value){
            self.cell_value = value
        },
        
        setScore(value){
            self.score = value
        }
    }))
    .views(self => ({
            get subject_id(){
                return self.assessment_id.subject_id
            }
        })
    );
