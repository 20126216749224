import { types } from "mobx-state-tree"
import { GlobalIDPattern } from '../patterns/globalIDPattern'

export const EmployeeCategory = types
    .compose(
        "EmployeeCategory",
        GlobalIDPattern,
        types
            .model({
                id: types.identifier,
                type: "employee_category",
                attributes: types.maybe(types.model({
                    name: ""
                })),
            })
    )