import numeral from 'numeral'
import getSymbolFromCurrency from 'currency-symbol-map'

export function formatCurrency(amount, currency) {
    if (amount !== 0  && !amount) return null
    
    let numeralAmount = numeral(amount)
    const isNegative = numeralAmount.value() < 0
    if (isNegative) numeralAmount = numeral(-numeralAmount.value())
    
    const formatted = numeralAmount.format('0,0.00')
    const symbol = getSymbolFromCurrency(currency)
    if (Boolean(currency) && Boolean(symbol)) {
        return `${isNegative ? '-' : ''}${symbol}${formatted}`
    }
    return `${isNegative ? '-' : ''}${formatted}`
}