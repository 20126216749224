import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { ModalContext, ModalProvider, ModalConsumer } from '../common/modal'
import Sidebar from '../common/sidebar'
import SchoolSwap from '../common/schoolswap'
import Desktop from '../common/display/Desktop'
import Search from '../common/assets/Search.svg'
import AccountActions from '../common/account_actions'
import Feedback from '../common/feedback/Feedback';
import ErrorBoundary from '../common/error_boundary';

import GuardianList from '../guardians/GuardianList'
import SelectedGuardian from '../guardians/SelectedGuardian'
import EmployeeList from '../employees/EmployeeList'
import SelectedEmployee from '../employees/SelectedEmployee'
import StudentList from '../students/StudentList';
import SelectedStudent from '../students/SelectedStudent'
import AddStudent from '../students/AddStudent';
import AddEmployee from '../employees/AddEmployee';
import FinanceTransactions from '../finance/FinanceTransactions'
import FeeTransactions from '../finance/FeeTransactions'
import CustomTransactions from '../finance/CustomTransactions'
import WalletTransactions from '../finance/WalletTransactions'
import Fees from '../finance/Fees'
import SelectedFee from '../finance/SelectedFee'
import SelectedFinanceTransaction from '../finance/SelectedFinanceTransaction';
import SubjectList from '../subjects/SubjectList';
import SelectedSubject from '../subjects/SelectedSubject';
import BatchCollection from '../batches/BatchCollection'
import SelectedBatch from '../batches/SelectedBatch';
import TransactionPreflightForm from '../students/TransactionPreflightForm';
import AddEvent from '../events/AddEvent';
import Events from '../events/Events';
import SelectedEvent from '../events/SelectedEvent'
import Dashboard from '../dashboard/Dashboard'
import SelectedReportCardGroup from '../report_cards/SelectedReportCardGroup';
import ReportCardGroupCollection from '../report_cards/ReportCardGroupCollection';
import SelectedAssessment from "../subjects/SelectedAssessment";
import StudentReportCardPage from "../students/StudentReportCardPage";
import SelectedUser from "../users/SelectedUser";
import SchoolDetails from '../settings/school_details/SchoolDetails';
import AcademicSessions from '../settings/academic_sessions/AcademicSessions';
import Classes from '../settings/classes/Classes';
import ClassSets from '../settings/class_sets/ClassSets';
import SubjectNames from '../settings/subject_names/SubjectNames';
import SelectedMessage from "../messages/SelectedMessage";
import MessageCollection from "../messages/MessageCollection";
import SmsTopupCollection from "../messages/SmsTopupCollection";

function Toolbar() {
    return (
        <Desktop>
            <div className="WrapperToolbar">
                <div className="WrapperToolbar-searchContainer FormInputControl">
                    <img src={Search} className="WrapperToolbar-searchIcon" alt="search" />
                    <input
                        className="FormInputControl-textInput WrapperToolbar-searchInput"
                        type="text"
                        placeholder="Search"
                    />
                </div>
                <AccountActions />
            </div>
        </Desktop>
    )
}

function Sider({ viewStore }) {
    return (
        <>
            <SchoolSwap />
            <Sidebar viewStore={viewStore} />
        </>
    )
}

const Container = inject('store', 'display')(
    observer(({ store, display, children }) => (
        <ModalProvider>
            <ModalContext.Consumer>
                {context => (
                    <div className={display.screensize}>
                        <div
                            className={classNames(
                                'Wrapper',
                                { 'Wrapper-isModalVisible': context.state.isModalVisible },
                                { 'Settings': store.view.isSettingsPage }
                            )}
                        >
                            <Sider viewStore={store.view} />
                            <div className="Wrapper-content">
                                <Toolbar />
                                <ErrorBoundary>
                                    {children}
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div id="portal-root">
                            <Feedback />
                        </div>
                    </div>
                )}
            </ModalContext.Consumer>
            <ModalConsumer screensize={display.screensize} />
        </ModalProvider>
    )))

const EmployeeApp = inject("store")(
    observer(({ store }) => {
        if (store.view.templateVariant === 'html'){
            return (
                <Container> 
                    {renderPage(store.view)}
                </Container>
            )
        } 

        return renderPage(store.view)
    })
)

function renderPage(viewStore) {
    switch (viewStore.page) {
        case "dashboard":
            return <Dashboard />
        case "user":
            return <SelectedUser />
        case "students":
            return <StudentList />
        case "student":
            return <SelectedStudent />
        case "studentPayFees":
            return <TransactionPreflightForm />
        case "studentReportCard":
            return <StudentReportCardPage />
        case "students/add":
            return <AddStudent key="1" />
        case "students/edit":
            return <AddStudent key="2" editMode />
        case "guardians":
            return <GuardianList />
        case "guardian":
            return <SelectedGuardian />
        case "employees":
            return <EmployeeList />
        case "employee":
            return <SelectedEmployee />
        case "employees/add":
            return <AddEmployee key="1" />
        case "employees/edit":
            return <AddEmployee key="2" editMode />
        case "finance/transactions":
            return <FinanceTransactions />
        case "finance/transaction":
            return <SelectedFinanceTransaction />
        case "finance/fee-transactions":
            return <FeeTransactions />
        case "finance/custom-transactions":
            return <CustomTransactions />
        case "finance/expense-account-transactions":
            return <WalletTransactions />
        case "finance/fee":
            return <SelectedFee />
        case "finance/fees":
            return <Fees />
        case "subjects":
            return <SubjectList />
        case "subject":
            return <SelectedSubject />
        case "batches":
            return <BatchCollection />
        case "batch":
            return <SelectedBatch />
        case "events/new":
            return <AddEvent key="1" />
        case "events/edit":
            return <AddEvent key="2" editMode />
        case "event":
            return <SelectedEvent />
        case "events":
            return <Events />
        case "assessment":
            return <SelectedAssessment />
        case "report-card":
            return <SelectedReportCardGroup />
        case "report-cards":
            return <ReportCardGroupCollection />
        case "messages/sms-topups":
            return <SmsTopupCollection />
        case "message":
            return <SelectedMessage />
        case "messages":
            return <MessageCollection />
        case "settings/school-details":
            return <SchoolDetails />
        case "settings/academic-sessions":
            return <AcademicSessions />
        case "settings/classes":
            return <Classes />
        case "settings/class-sets":
            return <ClassSets />
        case "settings/subject-names":
            return <SubjectNames />
        default:
            return "Welcome! Choose a page from the menu."
    }
}

export default EmployeeApp;
