import { types } from "mobx-state-tree"
import {dateFormatDeeEmmEmm, dateFormatShortNoLeadingZero, formatDate} from "../../lib/formatDate";

export const Note = types
    .model("Note", {
        id: types.identifier,
        type: "note",
        attributes: types.maybe(types.model({
            title: types.string,
            body: types.string,
            created_by_name: "",
            student_batch_id: types.maybeNull(types.number), // FIXME: Temporary hack for frontend permissions
            created_by_id: types.number,
            created_at: "",
            updated_at: types.maybeNull(types.string),
            visible_to_owner: true,
        }))
    })
    .views(self => ({
        get visible() {
            return self.attributes.visible_to_owner
        },
        get updated() {
            if (self.attributes.updated_at) {
                return formatDate(self.attributes.updated_at, dateFormatShortNoLeadingZero)
            }
            return formatDate(self.attributes.created_at, dateFormatDeeEmmEmm)
        },
        get author() {
            return self.attributes.created_by_name
        }
    }))
    .actions(self => ({
        toggle() {
            self.attributes.visible_to_owner = !self.attributes.visible_to_owner
        },
        changeTitle(newTitle) {
            self.attributes.title = newTitle
        },
        changeBody(newBody) {
            self.attributes.body = newBody
        }
    }))