import { types, flow } from "mobx-state-tree"
import { SearchMeta } from "../models/SearchMeta";
import { LoadingPattern } from "./LoadingPattern";
import { UsesBluebicStorePattern } from "./UsesBluebicStorePattern";

export const SearchResultsPattern = ({
    searchResultType,
    searchFormType
}) => (
        types.compose(
            LoadingPattern(),
            UsesBluebicStorePattern,
            types
                .model({
                    searchResults: types.optional(
                        types.array(types.safeReference(searchResultType)),
                        []
                    ),
                    searchMeta: types.optional(SearchMeta, {}),
                    searchFormInstance: types.optional(searchFormType, {})
                })
                .actions(self => {

                    function updateSearchResultsSubscribe(data) {
                        // will be overwritten by subtype
                    }

                    function updateSearchResults(data) {
                        self.updateSearchResultsSubscribe(data)
                        self.searchResults = []
                        data.forEach(json => self.searchResults.push(json.id))
                    }

                    function updateSearch(model) {
                        self.searchFormInstance = model
                        search()
                    }

                    const search = flow(function* search() {
                        try {
                            self.markLoading(true)
                            const { data, meta, included } = yield self.searchService(self.searchFormInstance.toJSON())
                            self.bluebic.handleUpdateStores(included)
                            updateSearchResults(data)
                            self.searchMeta = meta
                            self.markLoading(false)
                        } catch (err) {
                            self.markLoading(false)
                            console.error("Failed to load search results", err)
                        }
                    })

                    return {
                        search,
                        updateSearch,
                        updateSearchResults,
                        updateSearchResultsSubscribe
                    }

                })
        )
    )