import './style.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import warning from './assets/warning.svg'
import success from '../assets/Success.svg'
import {toUpperFirst} from "../../lib/stringUtility";

export default class Alert extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['success', 'info', 'notice', 'warning', 'danger']).isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string
  }

  static defaultProps = {
    title: null,
    icon: null
  }

  renderIcon() {
    const { type, icon } = this.props
    if (icon) {
      return <img className="Alert-icon" src={icon} alt="!" />
    }
    if (type === 'success') {
      return <img className="Alert-icon" src={success} alt="!" />
    }
    if (type === 'info') {
      return <img className="Alert-icon" src={warning} alt="!" />
    }
    if (type === 'notice') {
      return <img className="Alert-icon" src={warning} alt="!" />
    }
    if (type === 'warning') {
      return <img className="Alert-icon" src={warning} alt="!" />
    }
    if (type === 'danger') {
      return <img className="Alert-icon" src={warning} alt="!" />
    }
    return null
  }

  render() {
    const { type, message, title } = this.props
    const localType = title || toUpperFirst(type)
    const alertClass = classNames('Alert', `Alert--${type}`)

    return (
      <div className={alertClass}>
        {this.renderIcon()}
        <div className="text-p">
          <span className="text--bold utils-prepend">{localType}</span>
          {message}
        </div>
      </div>
    )
  }
}
