import ApplicationPolicy from "./ApplicationPolicy";

class ReportCardGroupPolicy extends ApplicationPolicy{


    get can_index(){
        return this.can_view_all_reports || this.user.has_any_special_role(['class_teacher', 'principal', 'guidance_counsellor'])
    }

    get can_view_all_reports(){
        return this.user.has_any_role(['report_control', 'view_report'])
    }

    get can_create(){
        return this.user.has_role('report_control')
    }

    get can_update(){
        return this.can_create
    }

    get can_destroy(){
        return this.can_create
    }
    
}

export default ReportCardGroupPolicy