/* eslint-disable camelcase */
import './EmployeeSummary.css'

import React from 'react'
import isEmpty from 'lodash.isempty'
import Avatar, { AVI_TYPES } from '../common/avatar'
import ProfileLoading from '../common/loading/ProfileLoading';

export default function EmployeeSummary({ employee }) {
  const { attributes, employeeName, departmentName, positionName, categoryName, isLoading } = employee
  const { username, photo_url, gender } = attributes

  if (isEmpty(attributes)) return <ProfileLoading useCard={false} />
  
  return (
    <div className="EmployeeSummary">
      <Avatar
        className="EmployeeSummary-avatar"
        src={photo_url}
        bordered
        sex={gender}
        type={AVI_TYPES.EMPLOYEE}
      />
      <div>
        <div className="text--grey text-small">Employee name</div>
        <div className="text-h4">{employeeName}</div>
      </div>
      <div>
        <div className="text--grey text-small">Employee no.</div>
        <div className="text-h4">{username}</div>
      </div>
      <div>
        <div className="text--grey text-small">Department</div>
        <div className="text-p">{departmentName}</div>
      </div>
      <div>
        <div className="text--grey text-small">Position</div>
        <div className="text-p">{positionName}</div>
      </div>
      <div>
        <div className="text--grey text-small">Category</div>
        <div className="text-p">{categoryName}</div>
      </div>
      {/* <NotMobile>
        <div>
          <div className="text--grey text-small">Email</div>
          <div className="text-p">{email}</div>
        </div>
        <div>
          <div className="text--grey text-small">Status</div>
          <div className="text-p text--success">{status}</div>
        </div>
      </NotMobile> */}
    </div>
  )
}
