import "./SchoolDetails.css";

import React, { Component } from "react";
import { Field, Form, converters, controlled } from "mstform";
import { observer, inject } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";

import Label from "../../common/form/Label";
import { EditInstitution, countryOptions, currencyOptions, } from "../../store/actions/Institution";
import InlineError from "../../common/form/InlineError";
import TextInput from "../../common/form/TextInput";
import SelectInput from "../../common/form/SelectInput";
import Button from "../../common/button";
import MultilineInput from "../../common/form/MultilineInput";
import PhotoInputDisplay from "../../common/form/PhotoInputDisplay";
import BackButtonMobile from "../BackButtonMobile";

const validators = [(value) => (value.length > 0) ? false : 'Field is required']
const form = new Form(EditInstitution, {
    tenant_name: new Field(converters.maybeNull(converters.string)),
    logo_url: new Field(converters.maybeNull(converters.string)),
    logo: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    name: new Field(converters.string, { required: true, validators }),
    name_abbreviation: new Field(converters.maybeNull(converters.string), { required: true, validators }),
    subdomain: new Field(converters.maybeNull(converters.string), { required: true, validators }),
    slogan: new Field(converters.maybeNull(converters.string)),
    website: new Field(converters.maybeNull(converters.string)),
    phone: new Field(converters.maybeNull(converters.string)),
    email: new Field(converters.maybeNull(converters.string)),
    mobile_phone: new Field(converters.maybeNull(converters.string)),
    address: new Field(converters.maybeNull(converters.string)),
    country: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    currency: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
});

@inject("store", "display")
@observer
class SchoolDetails extends Component {
    constructor(props) {
        super(props)
        const { store } = props;
        const { meta: { attributes } } = store;

        this.formState = form.state(EditInstitution.create(getSnapshot(attributes.current_institution_id.attributes)), {});
    }

    async componentDidMount() {
        const { store } = this.props;
        const { meta: { attributes, getInstitution, updateInstitution } } = store;

        await getInstitution();

        this.formState = form.state(EditInstitution.create(getSnapshot(attributes.current_institution_id.attributes)), {
            addMode: false,
            save: async (node) => {
                const { errors } = await updateInstitution(node.toJSON());
                return errors;
            }
        });
    }

    handleSubmit = async () => {
        await this.formState.save();
    }

    render() {
        const logo_url = this.formState.field("logo_url");
        const logo = this.formState.field("logo");
        const name = this.formState.field("name");
        const name_abbreviation = this.formState.field("name_abbreviation");
        const subdomain = this.formState.field("subdomain");
        const slogan = this.formState.field("slogan");
        const email = this.formState.field("email");
        const website = this.formState.field("website");
        const phone = this.formState.field("phone");
        const mobile_phone = this.formState.field("mobile_phone");
        const address = this.formState.field("address");
        const country = this.formState.field("country");
        const currency = this.formState.field("currency");
        const { store: { meta: { isUpdateInstitutionLoading } } } = this.props;

        return (
            <div className="SchoolDetails">
                <div className="SchoolDetails--Header">
                    <BackButtonMobile />
                    <h2>School Details</h2>
                    <span>Please fill in the required information. Only fields marked with a red asterisk are required and cannot be skipped</span>
                </div>
                <div className="SchoolDetails--Body">
                    <div className="SchoolDetails--Image">
                        <InlineError field={logo}>
                            <PhotoInputDisplay {...logo_url.inputProps} logoInputProps={logo.inputProps} displayText="Upload Logo" />
                        </InlineError>
                    </div>
                    <div className="SchoolDetails--Fields">
                        <div className="school-name-wrapper">
                            <Label text="School Name" required={name.required}>
                                <InlineError field={name}>
                                    <TextInput {...name.inputProps} />
                                </InlineError>
                            </Label>
                        </div>
                        <div className="inline-item-wrapper">
                            <Label text="School Name Abbreviation" required={name_abbreviation.required}>
                                <InlineError field={name_abbreviation}>
                                    <TextInput {...name_abbreviation.inputProps} />
                                </InlineError>
                            </Label>
                            <Label text="Sub Domain" required={subdomain.required}>
                                <InlineError field={subdomain}>
                                    <TextInput {...subdomain.inputProps} />
                                </InlineError>
                            </Label>
                        </div>

                        <div className="inline-item-wrapper">
                            <Label text="Slogan" required={slogan.required}>
                                <InlineError field={slogan}>
                                    <TextInput {...slogan.inputProps} />
                                </InlineError>
                            </Label>
                            <Label text="Website" required={website.required}>
                                <InlineError field={website}>
                                    <TextInput {...website.inputProps} />
                                </InlineError>
                            </Label>
                        </div>

                        <div className="inline-item-wrapper">
                            <Label text="Phone Number (Mobile)" required={mobile_phone.required}>
                                <InlineError field={mobile_phone}>
                                    <TextInput {...mobile_phone.inputProps} />
                                </InlineError>
                            </Label>
                            <Label text="Phone Number (Office)" required={phone.required}>
                                <InlineError field={phone}>
                                    <TextInput {...phone.inputProps} />
                                </InlineError>
                            </Label>
                        </div>

                        <div className="inline-item-wrapper">
                            <Label text="Email" required={email.required}>
                                <InlineError field={email}>
                                    <TextInput {...email.inputProps} />
                                </InlineError>
                            </Label>
                            <Label className="currency" text="Currency" required={currency.required}>
                                <InlineError field={currency}>
                                    <SelectInput
                                        {...currency.inputProps}
                                        placeholder="Select a currency"
                                        options={currencyOptions}
                                    />
                                </InlineError>
                            </Label>
                        </div>

                        <div className="address-country-currency-wrapper">
                            <Label className="address" text="Address" required={address.required}>
                                <InlineError field={address}>
                                    <MultilineInput {...address.inputProps} />
                                </InlineError>
                            </Label>
                            <div className="country-currency-wrapper">
                                <Label className="country" text="Country" required={country.required}>
                                    <InlineError field={country}>
                                        <SelectInput
                                            {...country.inputProps}
                                            placeholder="Select a country"
                                            options={countryOptions}
                                        />
                                    </InlineError>
                                </Label>
                            </div>
                        </div>
                        <Button onClick={this.handleSubmit} loading={isUpdateInstitutionLoading}>
                            Save
                        </Button>
                    </div>

                </div>
            </div>
        );
    }
}

export default SchoolDetails;
