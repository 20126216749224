export const nullFieldsToExcludeName = "_nullFieldsToExclude"

export function objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '[' + key + ']');
                        }
                    }
                }
            } else if (isBase64(data)) {
                // if it's a string or a File object
                // Split the base64 string in data and contentType
                const block = data.split(";");
                // Get the content type of the image
                const contentType = block[0].split(":")[1];
                // get the real base64 content of the file
                const realData = block[1].split(",")[1];
                // Convert it to a blob to upload
                const blob = b64toBlob(realData, contentType);

                formData.append(root, blob, makeFilename(contentType));
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root) {
        return Array.isArray(ignoreList)
            && ignoreList.some(function (x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}

// https://gist.github.com/ghinda/8442a57f22099bdb2e34
/* eslint-disable no-restricted-syntax */
// export function objectToFormData(obj, form, namespace) {

//     const fd = form || new FormData();
//     let formKey;

//     for (let property in obj) {
//         if (obj.hasOwnProperty(property)) {

//             if (namespace) {
//                 formKey = namespace + '[' + property + ']';
//             } else {
//                 formKey = property;
//             }


//             // if the property is an object, but not a File,
//             // use recursivity.
//             if (Array.isArray(obj[property])) {
//                 obj[property].forEach(item => {
//                     fd.append(
//                         `${property}[]`,
//                         objectToFormData(item, fd)
//                     )
//                 })
//             } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

//                 objectToFormData(obj[property], fd, property);

//             } else if (isBase64(obj[property])) {
//                 // if it's a string or a File object
//                 // Split the base64 string in data and contentType
//                 const block = obj[property].split(";");
//                 // Get the content type of the image
//                 const contentType = block[0].split(":")[1];
//                 // get the real base64 content of the file
//                 const realData = block[1].split(",")[1];
//                 // Convert it to a blob to upload
//                 const blob = b64toBlob(realData, contentType);

//                 fd.append(formKey, blob, makeFilename(contentType));
//             } else {
//                 fd.append(formKey, obj[property]);
//             }

//         }
//     }

//     return fd;

// };

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 * 
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

function isBase64(str) {
    if (typeof str !== "string") return false
    try {
        if (str === '' || str.trim() === '') { return false; }
        const block = str.split(";");
        // get the real base64 content of the file
        const realData = block[1].split(",")[1];
        const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return base64regex.test(realData);
    } catch (err) {
        return false;
    }
}

function makeFilename(contentType) {
    const length = 8;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const fileExtension = contentType.split("/").pop()
    return `${result}.${fileExtension}`;
}