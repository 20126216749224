import { types, flow } from "mobx-state-tree"
import FileSaver from "file-saver";
import * as ReactGA from "react-ga";

import { CustomTransaction } from "./models/CustomTransaction";
import { CustomTransactionSearch } from "./models/CustomTransactionSearch";
import { CollectionPattern } from "./patterns/collectionPattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";
import { LoadingPattern } from "./patterns/LoadingPattern";

export const CustomTransactionStore = types
    .compose(
        CollectionPattern({
            collectionType: CustomTransaction,
            searchType: CustomTransactionSearch
        }),
        LoadingPattern({
            createTransactionLoading: false
        }),
        types
            .model('CustomTransactionStore', {})
            .views(self => ({
                get service() {
                    return self.bluebic.customTransactionService
                },
                get searchService() {
                    return self.service.search
                },
            }))
            .actions(self => {

                function onUpdate(included) {
                    filterType("custom_finance_transaction", included, self.updateCollection)
                }
                
                const createTransaction = flow(function* createTransaction(custom_transaction) {
                    try {
                        self.markLoading("createTransactionLoading", true)
                        const { data } = yield self.bluebic.customTransactionService.createTransaction(custom_transaction)
                        self.putItem(data)
                        self.markLoading("createTransactionLoading", false)
                        return { data }
                    } catch (err) {
                        self.markLoading("createTransactionLoading", false)
                        console.error("Failed to create custom transaction", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const downloadCustomTransactionListSpreadsheet = flow(function* downloadCustomTransactionListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadCustomTransactionListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Custom Transactions Data',
                            nonInteraction: false,
                            value: action.item_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of Custom Finance Transaction list`, err)
                        action.markSaving(false)
                    }
                })
                
                const fetchFinanceSummary = flow(function* fetchFinanceSummary(customTransactionIDs) {
                    try {
                        const {data} = yield self.bluebic.customTransactionService.fetchFinanceSummary(customTransactionIDs)
                        return {data}
                    } catch (err) {
                        console.error("Failed to fetch custom transactions summary", err)
                        return {error: "Failed to fetch custom transactions summary"}
                    }
                })

                return {
                    createTransaction,
                    onUpdate,
                    fetchFinanceSummary,
                    downloadCustomTransactionListSpreadsheet
                }
            })
    )
