/* eslint-disable react/no-multi-comp */
import "./SubjectListSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import {onPatch} from 'mobx-state-tree';
import { SubjectSearch } from "../store/models/SubjectSearch"
import SelectInput from '../common/form/SelectInput';
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce';
import Button from '../common/button';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions'

const formModel = new MstForm(SubjectSearch, {
    batch_id: new Field(converters.string, {
        controlled: controlled.object
    }),
})

const BatchField = inject("store")(
    observer(({ store, formState }) => {
        const { meta: { accessibleSubjectsBatches } } = store

        const batch = formState.field("batch_id")
        return (
            <Label text="Batch">
                <SelectInput
                    {...batch.inputProps}
                    placeholder="Select batch"
                    defaultOptionText="All Batches"
                    options={mapModelNameToOptions(accessibleSubjectsBatches)}
                />
            </Label>
        )
    })
)

export default function SubjectListSearch() {
    return null
}

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        onPatch(searchFormInstance, debounce((patch) => {
                if (patch.path !== "/page"){
                    searchFormInstance.goToFirstPage()
                }
                updateSearch(searchFormInstance.toJSON())
         }, 250))
    }

    render() {
        return (
            <form className="SubjectListSearch-MobileForm">
                <BatchField formState={this.formState} />
            </form>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        onPatch(searchFormInstance, debounce((patch) => {
            if (patch.path !== "/page") searchFormInstance.goToFirstPage()
            updateSearch(searchFormInstance.toJSON())
        }, 250))
    }

    render() {
        const { store: { search } } = this.props
        return (
            <form className="SubjectListSearch-TabletForm">
                <BatchField formState={this.formState} />
                <Button onClick={search} fullwidth>Apply Filter</Button>
            </form>
        )
    }
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        onPatch(searchFormInstance, debounce((patch) => {
            if (patch.path !== "/page") searchFormInstance.goToFirstPage()
            updateSearch(searchFormInstance.toJSON())
        }, 250))
    }

    render() {
        const { store } = this.props
        const { search } = store

        return (
            <form className="SubjectListSearch-DesktopForm">
                <BatchField formState={this.formState} />
                <Button onClick={search} fullwidth>Apply Filter</Button>
            </form>
        )
    }
}

SubjectListSearch.MobileForm = MobileForm
SubjectListSearch.TabletForm = TabletForm
SubjectListSearch.DesktopForm = DesktopForm
