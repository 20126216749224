import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform";
import * as PropTypes from "prop-types";

import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Button from '../common/button';
import { ModalContext } from '../common/modal'
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import { CreateCustomTransaction } from '../store/actions/Finance';
import SelectInput from '../common/form/SelectInput';
import MultilineInput from '../common/form/MultilineInput';
import DateInput from '../common/form/DateInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import { titleCase } from '../lib/titleCase';

import plus from '../common/assets/plus.svg'
import plusBlue from '../common/assets/plus-blue.svg'
import arrowDownBlue from '../common/assets/arrow-down.svg'
import arrowDown from '../common/assets/arrow-down-white.svg'
import CustomFinanceTransactionPolicy from "../policies/CustomFinanceTransactionPolicy";

function handleNewCustomTransaction({ modalContext, type }) {
    const { setContent, closeModal } = modalContext
    return setContent(
        `New ${titleCase(type)} Transaction`,
        <NewCustomTransactionForm closeModal={closeModal} type={type} />,
        true
    )
}

function NewCustomTransaction() {
    return (
        <ModalContext.Consumer>
            {(modalContext) => (
                <NewCustomTransactionButton
                    onClick={(type) => handleNewCustomTransaction({ modalContext, type })}
                />
            )}
        </ModalContext.Consumer>
    )
}

export default NewCustomTransaction

@inject('store')
class NewCustomTransactionButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired
    }
    
    render() {
        const {onClick, store} = this.props;
        const {meta: {currentUser}} = store
        const policy = new CustomFinanceTransactionPolicy(currentUser, 'CustomFinanceTransaction')
        
        const popoverContent = (
            <>
                <button
                    type="button"
                    className="Button Button--clear touchable-opacity text-p"
                    onClick={() => onClick("expense")}
                    disabled={!policy.can_create}
                    title={policy.can_create ? undefined : 'No permission.'}
                >
                    Expense
                </button>
                <div className="divider" />
                <button
                    type="button"
                    className="Button Button--clear touchable-opacity text-p"
                    onClick={() => onClick("income")}
                    disabled={!policy.can_create}
                    title={policy.can_create ? undefined : 'No permission.'}
                >
                    Income
                </button>
            </>
        )
        const popoverPlacement = "bottomRight"
        const popover = {content: popoverContent, placement: popoverPlacement}

        return (
            <>
                <Mobile>
                    <Button
                        className="text-h4 utils-fullwidth"
                        buttonType="flat"
                        popover={popover}
                    >
                        <img src={plusBlue} alt="" className="utils-prepend" />
                        New Transaction
                        <img src={arrowDownBlue} alt="" className="utils-append" />
                    </Button>
                </Mobile>
                <NotMobile>
                    <Button popover={popover}>
                        <img src={plus} alt="" className="utils-prepend" />
                        New Transaction
                        <img src={arrowDown} alt="" className="utils-append" />
                    </Button>
                </NotMobile>
            </>
        )
    }
}

@inject('store', 'display')
@observer
class NewCustomTransactionForm extends React.Component {

    componentWillMount() {

        const { type } = this.props
        const form = new Form(CreateCustomTransaction, {
            transaction_category_id: new Field(converters.string, {
                controlled: controlled.object,
                required: true
            }),
            payment_method: new Field(converters.string, {
                controlled: controlled.object,
                required: true
            }),
            income_amount: new Field(
                converters.maybe(converters.number),
                { required: type === "income" }
            ),
            expense_amount: new Field(
                converters.maybe(converters.number),
                { required: type === "expense" }
            ),
            note: new Field(converters.string, { required: true }),
            transaction_date: new Field(converters.string, {
                controlled: controlled.object,
                required: true
            }),
        })
        const formInstance = CreateCustomTransaction.create({
            income_amount: type === "income" ? 0 : undefined,
            expense_amount: type === "expense" ? 0 : undefined,
        })
        this.formState = form.state(formInstance, {
            addMode: true,
            save: async (node) => {
                const {
                    store: {
                        customTransactionStore: { createTransaction }
                    }
                } = this.props
                const { errors } = await createTransaction(node.toJSON())
                return errors
            }
        })
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }

    render() {
        const { display, closeModal, store, type } = this.props
        const {
            defaultPaymentMethods,
            meta: { attributes: {
                finance_transaction_categories,
                custom_payment_methods
            } },
            customTransactionStore: { createTransactionLoading }
        } = store
        const payment_options = defaultPaymentMethods.map((paymentMethod) => ({
            text: titleCase(paymentMethod),
            value: paymentMethod
        })).concat(
            mapModelNameToOptions(custom_payment_methods)
        )
        const transaction_category_id = this.formState.field("transaction_category_id")
        const payment_method = this.formState.field("payment_method")
        const income_amount = this.formState.field("income_amount")
        const expense_amount = this.formState.field("expense_amount")
        const note = this.formState.field("note")
        const transaction_date = this.formState.field("transaction_date")

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText="Save"
                isOkButtonDisabled={!this.formState.isValid || createTransactionLoading}
            >
                <Label text="Trans. Category" inline={display.isNotMobile} required={transaction_category_id.required}>
                    <InlineError field={transaction_category_id}>
                        <SelectInput
                            {...transaction_category_id.inputProps}
                            options={mapModelNameToOptions(finance_transaction_categories)}
                            placeholder="Select transaction category"
                        />
                    </InlineError>
                </Label>
                <Label text="Note" inline={display.isNotMobile} required={note.required}>
                    <InlineError field={note}>
                        <MultilineInput {...note.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Payment Method" inline={display.isNotMobile} required={payment_method.required}>
                    <InlineError field={payment_method}>
                        <SelectInput
                            {...payment_method.inputProps}
                            options={payment_options}
                            placeholder="Select payment method"
                        />
                    </InlineError>
                </Label>
                {type === "income" && (
                    <Label text="Income Amount" inline={display.isNotMobile} required={income_amount.required}>
                        <InlineError field={income_amount}>
                            <TextInput {...income_amount.inputProps} />
                        </InlineError>
                    </Label>
                )}
                {type === "expense" && (
                    <Label text="Expense Amount" inline={display.isNotMobile} required={expense_amount.required}>
                        <InlineError field={expense_amount}>
                            <TextInput {...expense_amount.inputProps} />
                        </InlineError>
                    </Label>
                )}
                <Label text="Transaction Date" inline={display.isNotMobile} required={transaction_date.required}>
                    <InlineError field={transaction_date}>
                        <DateInput {...transaction_date.inputProps} />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}

export { NewCustomTransactionForm }