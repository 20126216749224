import { applySnapshot, types } from 'mobx-state-tree'

export const Attendance = types
    .model("UpdateBatchAttendance", {
        student_id: types.maybe(types.string),
        reason: types.maybeNull(types.string),
        half_day: false,
        date:types.maybe(types.string),
        isSaving: false
    }).actions(self => {
        function setFormInstance(attendance){
            const {
                attributes: {
                    student_id,
                    reason,
                    half_day,
                    date
                }
            } = attendance
            applySnapshot(self, {
                student_id: student_id.toString(),
                reason: reason || '',
                half_day,
                date
            })
        }

        return {
            setFormInstance
        }
    })

export const AttendanceSpreadsheet = types.model({
    batch_id: "",

    client_state: types.optional(types.model({
        isSaving: types.optional(types.boolean, false)
    }), {})
})
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        setToGetBatch(batchID){
            applySnapshot(self, {batch_id: batchID})
        }
    }))