import './PhotoInput.css'

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Desktop from '../display/Desktop'
import NotDesktop from '../display/NotDesktop'
import Button from '../button'
import upload from './assets/upload.svg'

const dropzoneRef = React.createRef()

class MultiFileInput extends Component {
    static propTypes = {
        // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
        // success: PropTypes.bool,
        style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        attachDocument: PropTypes.func.isRequired,
        // onBlur : PropTypes.func.isRequired,
        // onFocus: PropTypes.func.isRequired,
    }

    static defaultProps = {
        style: null,
        value: null
    }

    state = {fileNames: []}

    onDrop = (acceptedFiles) => {
        const {attachDocument} = this.props
        
        const fileNames = []

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = (error) => {throw error;};
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                attachDocument(binaryStr, file.name)
            }
            // reader.readAsArrayBuffer(file)
            reader.readAsDataURL(file);
            fileNames.push(file.name)
        })
        
        this.setState({fileNames})
    }

    onCancel = () => {
        // TODO
    }

    get filename() {
        const {fileNames} = this.state
        return fileNames.join(', ') || "Upload files"
    }

    render() {
        const {style} = this.props
        return (
            <>
                <Desktop>
                    <Dropzone
                        onDrop={this.onDrop}
                        onFileDialogCancel={this.onCancel}
                        style={style}
                        className="fileInput flex-col flex-alignCenter flex-justifyCenter touchable-highlight"
                        activeClassName="fileInput--focus"
                        acceptClassName="fileInput--success"
                        rejectClassName="fileInput--error"
                        accept="video/*, image/*,.pdf,.doc,.docx,.pps,.ppt,.pptx,.odt,.txt,.rtf,.xls,.xlsx,.htm,.html,.key,.odp"
                        multiple
                    >
                        <img src={upload} alt="upload" />
                        <div className="text-p text--primary text--alignCenter">{this.filename}</div>
                        <div className="text-p text--grey">You can also upload files by</div>
                        <div className="text-p text--primary">clicking here</div>
                    </Dropzone>
                </Desktop>
                <NotDesktop>
                    <Dropzone
                        className="utils-hide"
                        ref={dropzoneRef}
                        onDrop={this.onDrop}
                        onFileDialogCancel={this.onCancel}
                        accept="video/*, image/*,.pdf,.doc,.docx,.pps,.ppt,.pptx,.odt,.txt,.rtf,.xls,.xlsx,.htm,.html,.key,.odp"
                        multiple
                    />
                    <Button
                        onClick={() => {
                            dropzoneRef.current.open()
                        }}
                        fullwidth
                    >
                        {this.filename}
                    </Button>
                </NotDesktop>
            </>
        )
    }
}

export default MultiFileInput