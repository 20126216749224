import { types, flow, destroy } from "mobx-state-tree"
import { Batch } from './models/Batch'
import { BatchSearch } from "./models/BatchSearch";
import { CollectionPattern } from "./patterns/collectionPattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";
import { LoadingPattern } from "./patterns/LoadingPattern";
import {BatchAttendanceReport} from "./models/BatchAttendanceReport";
import {BatchAttendanceRegister} from "./models/BatchAttendanceRegister";
import {BatchBehaviouralScoresheet} from "./models/BehaviouralScoresheet";

export const BatchStore = types
    .compose(

        CollectionPattern({
            collectionType: Batch,
            searchType: BatchSearch
        }),
        LoadingPattern({
            createBatchLoading: false
        }),

        types
            .model("batchStore", {
                attendanceReports: types.map(BatchAttendanceReport),
                attendanceRegisters: types.map(BatchAttendanceRegister),
                behaviouralScoresheets: types.map(BatchBehaviouralScoresheet),
            })
            .views(self => ({
                get searchService() {
                    return self.bluebic.batchService.search
                },
                get batches() {
                    return self.items
                },
            }))
            .actions(self => {
                function markBatchLoadingById(id, flag) {
                    if (self.items.has(id)) {
                        self.items.get(id).isLoading = flag
                    } else {
                        self.items.put({ id, type: 'batch', isLoading: flag })
                    }
                }
                
                function markAttendanceReportLoadingById(id, flag) {
                    if (self.attendanceReports.has(id)) {
                        self.attendanceReports.get(id).isLoading = flag
                    } else {
                        self.attendanceReports.put({ id, type: 'batch_attendance_report', isLoading: flag })
                    }
                }
                
                function markAttendanceRegisterLoadingById(id, flag) {
                    if (self.attendanceRegisters.has(id)) {
                        self.attendanceRegisters.get(id).isLoading = flag
                    } else {
                        self.attendanceRegisters.put({ id, type: 'batch_attendance_register', isLoading: flag })
                    }
                }
                
                function markBehaviouralScoresheetLoadingById(id, flag) {
                    if (self.behaviouralScoresheets.has(id)) {
                        self.behaviouralScoresheets.get(id).isLoading = flag
                    } else {
                        self.behaviouralScoresheets.put({ id, type: 'batch_behavioural_scoresheet', isLoading: flag })
                    }
                }

                function updateAttendanceReports(models) {
                    models.forEach(model => self.attendanceReports.put(model))
                }

                function updateAttendanceRegisters(models) {
                    models.forEach(model => self.attendanceRegisters.put(model))
                }

                function updateBehaviouralScoreSheets(models) {
                    models.forEach(model => self.behaviouralScoresheets.put(model))
                }

                const loadBatchById = flow(function* loadBatchById(id) {
                    try {
                        markBatchLoadingById(id, true)
                        const { data, included } = yield self.bluebic.batchService.showBatch(id)
                        self.bluebic.handleUpdateStores(included)
                        self.items.put(data)
                        markBatchLoadingById(id, false)
                    } catch (err) {
                        markBatchLoadingById(id, false)
                        console.error("Failed to load Batch data", err)
                    }
                })

                const loadAttendanceReportById = flow(function* loadAttendanceReportById(id) {
                    const batch = self.items.get(id)
                    try {
                        markAttendanceReportLoadingById(id, true)
                        const term = batch.attendanceReport.filter_term
                        const { data, included } = yield self.bluebic.batchService.fetchAttendanceReport(id, term)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markAttendanceReportLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load batch attendance report with id ${id}`, err)
                        markAttendanceReportLoadingById(id, false)
                    }
                })
                
                const loadAttendanceRegisterById = flow(function* loadAttendanceRegisterById(id) {
                    try {
                        markAttendanceRegisterLoadingById(id, true)
                        const { data, included } = yield self.bluebic.batchService.fetchAttendanceRegister(id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markAttendanceRegisterLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load batch with id ${id}`, err)
                        markAttendanceRegisterLoadingById(id, false)
                    }
                })
                
                const updateBatchAttendanceRecord = flow(function* updateBatchAttendanceRecord(id,attendance) {
                    try {
                        markAttendanceRegisterLoadingById(id, true)
                        const { data } = yield self.bluebic.attendanceStore.updateAttendance(attendance)
                        self.bluebic.handleUpdateStores([data])
                        markAttendanceRegisterLoadingById(id, false)
                        self.attendanceRegisters.get(id).addAttendance(data)
                        return { data }
                    } catch (err) {
                        console.error(`Failed to update Attendance in batch id ${id}`, err)
                        markAttendanceRegisterLoadingById(id, false)
                        return validationErrorsHandler(err)
                    }
                })
                
                const loadBehaviouralScoresheetById = flow(function* loadBehaviouralScoresheetById(id) {
                    try {
                        markBehaviouralScoresheetLoadingById(id, true)
                        const { data, included } = yield self.bluebic.batchService.fetchBehaviouralScoreSheet(id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markBehaviouralScoresheetLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load batch with id ${id}`, err)
                        markBehaviouralScoresheetLoadingById(id, false)
                    }
                })
                
                const updateBehaviouralScoreSheet = flow(function* updateBehaviouralScoreSheet(id, student_id, term_id, grades) {
                    try {
                        markBehaviouralScoresheetLoadingById(id, true)
                        const { data, included } = yield self.bluebic.batchService.updateBehaviouralScoreSheet(id, student_id, term_id, grades)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markBehaviouralScoresheetLoadingById(id, false)
                        return data
                    } catch (err) {
                        console.error(`Failed to Update Behavioural scoresheet of student with ${student_id}`, err)
                        markBehaviouralScoresheetLoadingById(id, false)
                        return validationErrorsHandler(err)
                    }
                })
                
                function onUpdate(included) {
                    filterType("batch", included, self.updateCollection)
                    filterType('batch_attendance_report', included, updateAttendanceReports)
                    filterType('batch_attendance_register', included, updateAttendanceRegisters)
                    filterType('batch_behavioural_scoresheet', included, updateBehaviouralScoreSheets)
                }

                const createBatch = flow(function* createBatch(batch) {
                    try {
                        self.markLoading("createBatchLoading", true)
                        const { data, included } = yield self.bluebic.batchService.createBatch(batch)
                        self.bluebic.handleUpdateStores(included)
                        self.putItem(data)
                        self.markLoading("createBatchLoading", false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to create batch", err)
                        self.markLoading("createBatchLoading", false)
                        return validationErrorsHandler(err)
                    }
                })
                
                const updateBatch = flow(function* updateBatch(id,batchUpdate) {
                    try {
                        const { data, included } = yield self.bluebic.batchService.updateBatch(id,batchUpdate)
                        self.bluebic.handleUpdateStores(included)
                        self.putItem(data)
                        return { data }
                    } catch (err) {
                        console.error("Failed to Update batch", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const deleteBatch = flow(function* deleteBatch(batch_id) {
                    try {
                        yield self.bluebic.batchService.deleteBatch(batch_id)
                        const index = self.searchResults.findIndex(({ id }) => id === batch_id)
                        self.searchResults.splice(index,1)
                        self.searchMeta.total_count -= 1 
                        destroy(self.items.get(batch_id))
                        return {}
                    } catch (err) {
                        console.error("Failed to delete batch", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const batchTransfer = flow(function* batchTransfer(batchId, studentTransferDetails) {
                    try {
                        const batch = self.items.get(batchId)
                        const {student_ids} = studentTransferDetails
                        yield self.bluebic.batchService.batchTransfer(studentTransferDetails)
                        batch.removeStudentIDsFromBatch(student_ids)
                        return {}
                    } catch (err) {
                        console.error("Failed to Update batch", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const assignRole = flow(function* assignRole(role) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.batchService.assignRole(role)
                        self.bluebic.handleUpdateStores([data, ...included])
                        self.markLoading(false)
                        return {data}
                    } catch (err) {
                        console.error("Failed to assign employee", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                })
                
                const unassignEmployeeById = flow(function* unassignEmployeeById(id, special_role_assignment_id) {
                    try {
                        self.markLoading(true)
                        const batch = self.items.get(id)
                        yield self.bluebic.batchService.unassignRole(id, special_role_assignment_id)
                        batch.removeSpecialRole(special_role_assignment_id)
                        self.markLoading(false)
                        return {}
                    } catch (err) {
                        console.error("Failed to unassign employee", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                })

                const batchSetupTransfer = flow(function* batchSetupTransfer(id, setup_transfer_action) {
                    try {
                        setup_transfer_action.markSaving(true)
                        const {client_state, ...action_without_client} = setup_transfer_action
                        const { data } = yield self.bluebic.batchService.batchSetupTransfer(id, action_without_client)
                        self.bluebic.handleUpdateStores([data])
                        setup_transfer_action.markSaving(false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to save setup transfer request", err)
                        setup_transfer_action.markSaving(false)
                        return validationErrorsHandler(err)
                    }
                })
        

                return {
                    createBatch,
                    updateBatch,
                    onUpdate,
                    loadBatchById,
                    deleteBatch,
                    batchTransfer,
                    loadAttendanceReportById,
                    loadAttendanceRegisterById,
                    loadBehaviouralScoresheetById,
                    updateBatchAttendanceRecord,
                    updateBehaviouralScoreSheet,
                    assignRole,
                    unassignEmployeeById,
                    batchSetupTransfer
                }
            })
    )
