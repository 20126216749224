import './StudentBioData.css'

import React from 'react'
import { Typography, Row, Col } from 'antd'
import { observer, inject } from 'mobx-react'
import Card from '../common/card'
import { titleCase } from '../lib/titleCase'
import person from './assets/person.svg'
import address_book from './assets/contacts.svg'
import map_pin from './assets/map-pin.svg'
import Loading from '../common/loading/BioDataLoading'

const { Text } = Typography

@inject("store")
@observer
class StudentBioData extends React.Component {
    renderGroup = (data) => {
        return Object.keys(data).map((key, idx) => (
            <React.Fragment key={key}>
                {idx > 0 && <div className="divider my-1" />}
                <Row>
                    <Col span={6}>
                        <span className="text-small text--bold">{key.toUpperCase()}</span>
                    </Col>
                    <Col>
                        <Text>{titleCase(data[key])}</Text>
                    </Col>
                </Row>
            </React.Fragment>
        ))
    }

    render() {
        const { studentProfile, contacts, address, store } = this.props
        const {selectedStudent: { isLoading }} = store.view

        return (
            <div className="StudentBioData">
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={person} className="utils-prepend" alt="" />
                            Student Profile
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> : this.renderGroup(studentProfile)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={address_book} className="utils-prepend" alt="" />
                            Contacts
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> : this.renderGroup(contacts)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={map_pin} className="utils-prepend" alt="" />
                            Address
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> : titleCase(address)}
                </Card>
            </div>
        )
    }
}

export default StudentBioData