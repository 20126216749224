import React from 'react'
import ModalForm from '../containers/ModalForm';

export default function MobileConfirmDialog({ handleConfirm, handleCancel }) {
    return (
        <ModalForm
            onCancel={handleCancel}
            onOk={handleConfirm}
            okButtonType="danger"
            okButtonText="Yes"
        >
            <div className="flex-row flex-alignStart">
                <i
                    className="material-icons utils-prepend text--danger"
                    style={{ paddingTop: 4 }}
                >
                    info
                </i>
                <div className="text-h3 text--bold">
                    Are you sure you want to perform this action?
                </div>
            </div>
        </ModalForm>
    )
}