import { types } from "mobx-state-tree";

export const currencyOptions = [{ text: "Naira", value: "NGN" }];
export const countryOptions = [{ text: "Nigeria", value: "NG" }];

export const EditInstitution = types
    .model("EditInstitution", {
        tenant_name: "",
        logo_url: types.maybeNull(types.string),
        logo: types.maybeNull(types.string),
        name: "",
        name_abbreviation: types.maybeNull(types.string),
        subdomain: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        slogan: types.maybeNull(types.string),
        website: types.maybeNull(types.string),
        mobile_phone: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        address: types.maybeNull(types.string),
        country: types.maybeNull(types.enumeration("countries", countryOptions.map(country => country.value))),
        currency: types.maybeNull(types.enumeration("currencies", currencyOptions.map(currency => currency.value))),
    });
