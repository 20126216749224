import './PhoneInput.css'
import 'react-phone-input-2/dist/style.css'

import React from 'react'
import PropTypes from 'prop-types'
import ReactPhoneInput from 'react-phone-input-2'
import classNames from 'classnames'

function PhoneInput({ className, onChange, value, placeholder }) {
  const phoneInputClass = classNames(
    'flex-row flex-spaceBetween flex-alignCenter FormInputControl text-p',
    className
  )
  return (
    <ReactPhoneInput
      preferredCountries={['ng']}
      enableSearchField
      disableSearchIcon
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      // inputExtraProps={{ onBlur, onFocus }}
      inputClass={phoneInputClass}
    />
  )
}
PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  // success: PropTypes.bool,
  value: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // onBlur: PropTypes.func.isRequired,
  // onFocus: PropTypes.func.isRequired,
  className: PropTypes.string,
}
PhoneInput.defaultProps = {
  placeholder: "",
  className: null
}
export default PhoneInput