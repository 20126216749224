import { types, flow, applySnapshot, destroy } from "mobx-state-tree"
import FileSaver from "file-saver";
import * as ReactGA from "react-ga";

import { Guardian } from './models/Guardian'
import { GuardianSearch } from "./models/GuardianSearch";
import { CollectionPattern } from "./patterns/collectionPattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";

export const GuardianStore = types
    .compose(
        CollectionPattern({
            collectionType: Guardian,
            searchType: GuardianSearch
        }),
        types
            .model({})
            .views(self => ({
                get searchService() {
                    return self.bluebic.guardianService.search
                },
                get service() {
                    return self.bluebic.guardianService
                },
                get selectedGuardians() {
                    return self.selectedItemKeys
                }
            }))
            .actions(self => {

                function markGuardianLoadingById(id, flag) {
                    if (self.items.has(id)) {
                        self.items.get(id).isLoading = flag
                    } else {
                        self.items.put({ id })
                    }
                }

                const loadGuardianById = flow(function* loadGuardianById(id) {
                    try {
                        markGuardianLoadingById(id, true)
                        const { data, included } = yield self.bluebic.guardianService.showGuardian(id)
                        self.bluebic.handleUpdateStores(included)
                        self.items.put(data)
                        markGuardianLoadingById(id, false)
                    } catch (err) {
                        markGuardianLoadingById(id, false)
                        console.error("Failed to load guardian data", err)
                    }
                })

                function onUpdate(included) {
                    filterType("guardian", included, self.updateCollection)
                }

                const createGuardian = flow(function* createGuardian(action) {
                    try {
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { data, included } = yield self.bluebic.guardianService.createGuardian(action_without_client)
                        self.bluebic.handleUpdateStores([data, ...included])
                        action.markSaving(false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to create guardian", err)
                        action.markSaving(false)
                        return validationErrorsHandler(err)
                    }
                })

                const updateGuardian = flow(function* updateGuardian(id, action) {
                    try {
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { data, included } = yield self.bluebic.guardianService.updateGuardian(id, action_without_client)
                        self.bluebic.handleUpdateStores([data, ...included])
                        action.markSaving(false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to edit guardian", err)
                        action.markSaving(false)
                        return validationErrorsHandler(err)
                    }
                })

                // eslint-disable-next-line consistent-return
                const deleteGuardianById = flow(function* deleteGuardianById(guardian_id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.guardianService.deleteGuardian(guardian_id)
                        self.bluebic.view.openGuardiansPage()
                        destroy(self.items.get(guardian_id))
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to delete employee", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                })

                const addNote = flow(function* addNote(guardian_id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.noteService.addGuardianNote(guardian_id, action)
                        self.items.get(guardian_id).attributes.notes.push(data)
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to create note", err)
                        self.markLoading(false)
                    }
                })

                const editNote = flow(function* editNote(guardian_id, note_id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.noteService.updateNote(note_id, action)
                        const { notes } = self.items.get(guardian_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        applySnapshot(notes[index], data)
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to edit note", err)
                        self.markLoading(false)
                    }
                })

                const removeNote = flow(function* removeNote(guardian_id, note_id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.noteService.removeNote(note_id)
                        const { notes } = self.items.get(guardian_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        destroy(notes[index])
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to remove note", err)
                        self.markLoading(false)
                    }
                })

                const downloadGuardianListSpreadsheet = flow(function* downloadGuardianListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadGuardianListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Guardian Data',
                            nonInteraction: false,
                            value: action.guardian_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of guardian list`, err)
                        action.markSaving(false)
                    }
                })

                return {
                    addNote,
                    createGuardian,
                    updateGuardian,
                    deleteGuardianById,
                    editNote,
                    loadGuardianById,
                    onUpdate,
                    removeNote,
                    downloadGuardianListSpreadsheet
                }
            })
    )
