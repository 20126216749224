import ApplicationPolicy from "./ApplicationPolicy";


// TODO: WIP
class StudentReportCardCommentPolicy extends ApplicationPolicy{
    get can_update(){
        return this.user.is_employee || this.user.is_admin // HACK
        
        // eslint-disable-next-line no-unreachable
        if (this.user.has_role('report_control')) return true
        const {special_role: comment_role, report } = this.record.comment
        const {assignment_level} = comment_role
        const {report_card_group} = report

        

        if (assignment_level === 'course_set'){
           return this.user.has_special_role(comment_role, report_card_group.course_set)
        }

        if (assignment_level === 'batch'){
            const {student_id} = report
            const student_batch = Batch.find(report_card_group.student_batch_hash[student_id])
            this.user.has_special_role(comment_role, student_batch)
        }
        
        
        return false
    }
        
    
}

export default StudentReportCardCommentPolicy