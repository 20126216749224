import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import { ModalContext } from '../common/modal'
import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import EmployeeSummary from './EmployeeSummary'
import EmployeeBioData from './EmployeeBioData';
import EmployeeNotes from './EmployeeNotes';
import EmployeePrivileges from './EmployeePrivileges';
import UpdatePassword from '../common/update_password';

import employeeIcon from '../common/assets/teacher-pointing-blackboard.svg'
import chat from '../common/assets/chat.svg'
import password from '../common/assets/password.svg'
import edit from './assets/edit.svg'
import EmployeePolicy from "../policies/EmployeePolicy";
import UserPolicy from "../policies/UserPolicy";
import archive from "../students/assets/archive.svg";
import trash from "../students/assets/trash.svg";
import ArchiveEmployee from "./ArchiveEmployee";
import DeleteEmployee from "./DeleteEmployee";
import ReactHelmet from "../containers/ReactHelmet";
import {handleSendMessage} from "../messages/SendMessageForm";
import MessagePolicy from "../policies/MessagePolicy";

@inject("store")
@observer
class SelectedEmployee extends Component {
    tabs = []

    componentDidMount() {
        const { store } = this.props
        const { employeeStore, view } = store
        const { loadEmployeeById } = employeeStore
        loadEmployeeById(view.selectedEmployeeId)
    }

    options = (context) =>{ 
        const { store: { alert, meta: {currentUser}, view : { selectedEmployee, openEditEmployeePage } } } = this.props
        const policy = new EmployeePolicy(currentUser, selectedEmployee)
        const userPolicy = new UserPolicy(currentUser, selectedEmployee.user)
        const messagePolicy = new MessagePolicy(currentUser, selectedEmployee.user)
        return [
            {
                text: 'Edit Employee',
                disabled: !policy.can_update,
                title: policy.can_update ? undefined : "No permission.",
                onClick: () => openEditEmployeePage(selectedEmployee),
                icon: edit
            },
            {
                text: 'Send Message',
                disabled: false,
                title: messagePolicy.can_create ? undefined : "No permission.",
                onClick: () => {
                    if (!messagePolicy.can_create){
                        alert({ message: { warning: "You do not have permission to send messages." } })
                        return
                    }
                    handleSendMessage(context, { employeeIDs: [selectedEmployee.id]})
                },
                icon: chat
            },
            {
                text: 'Change Password',
                disabled: !userPolicy.can_update_password,
                title: userPolicy.can_update_password ? undefined : "No permission.",
                icon: password,
                onClick: () => context.setContent(
                    'Change Password',
                    <UpdatePassword onCancel={context.closeModal} id={selectedEmployee.user.id} />,
                    true
                ),
            },
            {
                text: 'Move to archive',
                disabled: !policy.can_to_archive,
                title: policy.can_to_archive ? undefined : "No permission.",
                icon: archive,
                onClick: () => context.setContent(
                    'Archiving Employee',
                    <ArchiveEmployee employeeId={selectedEmployee.id} onClose={context.closeModal} />,
                    true
                ),
            },
            {
                text: 'Delete Employee',
                disabled: !policy.can_destroy,
                title: policy.can_destroy ? undefined : "No permission.",
                icon: trash,
                onClick: () => context.setContent(
                    'Deleting Employee',
                    <DeleteEmployee employeeId={selectedEmployee.id} onClose={context.closeModal} />,
                    true
                ),
            },
        ]
    }

    render() {
        const { store } = this.props
        const { view, meta: {currentUser} } = store
        const { selectedEmployee } = view
        
        if (isEmpty(selectedEmployee) || isEmpty(selectedEmployee.attributes)) return ''// HACK
        
        const policy = new EmployeePolicy(currentUser, selectedEmployee)
        if (!policy.can_show) throw new Error('You do not have permission to view this record')
        
        const { isLoading, attributes: employeeAttributes, bioData } = selectedEmployee
        const canPrint = !isLoading
        
        const notes = employeeAttributes ? employeeAttributes.notes : []
        

        const tabs = [
            new Tab("Bio", <EmployeeBioData {...bioData} />, true),
            new Tab("Notes", <EmployeeNotes notes={notes} />),
        ]

        if (policy.can_view_privileges) tabs.push(new Tab("Privileges", <EmployeePrivileges employeeData={selectedEmployee} />))

        return (
            <>
                <ReactHelmet title={`Employee Profile | ${selectedEmployee.employeeName}`} />
                <ModalContext.Consumer>
                    {context => (
                        <Tabs
                            title={{ text: 'Employee Profile', icon: employeeIcon }}
                            tabs={tabs}
                            content={<EmployeeSummary employee={selectedEmployee} />}
                            options={isEmpty(employeeAttributes) ? [] : this.options(context)}
                            canPrint={canPrint}
                        />
                    )}
                </ModalContext.Consumer>
            </>
        )
    }
}

export default SelectedEmployee
