import './empty_list.css'

import React from 'react'
import Card from '../card';

export default function EmptyList() {
    return (
        <Card className="EmptyList">
            <i className="material-icons">inbox</i>
            <span>No records to display</span>
        </Card>
    )
}
