import React from 'react';
import { inject, observer } from 'mobx-react';
import NotMobile from '../common/display/NotMobile';
import Mobile from '../common/display/Mobile';
import Table from '../common/table';
import Card from '../common/card';
import icon from './assets/buy.svg';
import './RecentTransactions.css';
import { formatDate } from '../lib/formatDate';
import isEmpty from "lodash.isempty";
import EmptyList from "../common/empty_list/empty_list";

const columns = [
    { key: 'Basic Info', render: 'Basic Info' },
    { key: 'Note', render: 'Note' },
    { key: 'Entered By', render: 'Entered By' },
    {
        key: 'Amount',
        render: 'Amount',
        style: { textAlign: 'right' },
    },
];

@inject('store', 'display')
@observer
class RecentTransactions extends React.Component {
    render() {
        const { store: { formatCurrency }, display: { isTablet }, recentTransactions } = this.props

        const data = !recentTransactions ? [] : recentTransactions.map((trans) => {
            const { categoryName, transRef, note, enteredBy, amount, date } = trans
            const trendUp = amount > 0

            return {
                'Basic Info': (
                    <div>
                        <div>{categoryName}</div>
                        <div className="text-small text--grey">#{transRef}</div>
                    </div>
                ),
                'Note': note,
                'Entered By': enteredBy,
                'Amount': (
                    <div>
                        <strong className={trendUp ? 'text--success' : 'text--danger'}>
                            {formatCurrency(amount)}
                        </strong>
                        <div className="text-small text--grey">{formatDate(date)}</div>
                    </div>
                ),
                transRef
            }
        });

        const tableHeight = isTablet ? 300 : 600;
        
        return (
            <div className='dashboard-recentTransactions recentTransactions'>
                <div className="flex-row recentTransactions-head">
                    <img className="dashboard-icon" src={icon} alt="" />
                    <span className="text-small text--uppercase">Recent Transactions</span>
                </div>

                {isEmpty(data) && <EmptyList />}

                {!isEmpty(data) && (
                    <>
                        <NotMobile>
                            <Table columns={columns} data={data} height={tableHeight} containerClass="Card" responsive={false} />
                        </NotMobile>
                        
                        <Mobile>
                            {data.filter((_, i) => i < 5).map((item) => (
                                <Card key={item.transRef} className="recentTransactions-mobileCard">
                                    <div>
                                        {item['Basic Info']}
                                        <div className="text-p">{item.Note}</div>
                                    </div>
                                    {item.Amount}
                                </Card>
                            ))}
                            <div className="text--primary text-small text--bold touchable-opacity">VIEW ALL</div>
                        </Mobile>
                    </>
                )}
            </div>
        );
    }
}

export default RecentTransactions;