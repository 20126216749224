import React from 'react'
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import ContentLoader from '../content_loader';
import MobilePageHeader from '../../containers/MobilePageHeader';
import Card from '../card';

const ProfileLoading = inject("display")(
    observer(({ display, useCard }) => {
        const width = display.isMobile ? '200' : '500'
        const Content = (
            <ContentLoader height="70" width={width}>
                <circle cx="20" cy="20" r="20" />
                <rect x="60" y="10" rx="1" ry="1" width="80" height="5" />
                <rect x="60" y="25" rx="1" ry="1" width="30" height="5" />
                <rect x="110" y="25" rx="1" ry="1" width="25" height="5" />
            </ContentLoader>
        )
        if (display.isMobile) {
            return (
                <MobilePageHeader>
                    <div style={{ marginTop: 50 }}>
                        {Content}
                    </div>
                </MobilePageHeader>
            )
        }
        return useCard ? (
            <Card>
                {Content}
            </Card>
        ) : Content
    })
)

ProfileLoading.propTypes = {
    useCard: PropTypes.bool
}

ProfileLoading.defaultProps = {
    useCard: true
}

export default ProfileLoading