import { types } from "mobx-state-tree"
import {
    aggregateColumnShowAsOptions, assessmentCategoryForOptions,
    cognitiveColumnTypeOptions, positionInOptions,
} from '../actions/ReportCardTemplate';

export const ReportCardTemplateCognitiveColumn = types
    .model("ReportCardTemplateCognitiveColumn",{
        id: types.identifier,
        type: types.literal("report_card_template_cognitive_column"),
        attributes: types.model({
            position: types.number,
            type: types.enumeration(cognitiveColumnTypeOptions),
            show_on_report: types.boolean,
            heading: types.string,
            is_final_column: types.boolean,
            is_primary_column: types.boolean,
            aggregatable: types.boolean,

            aggregate_column_show_as: types.enumeration(aggregateColumnShowAsOptions),
            aggregate_column_over: types.maybeNull(types.string),

            assessment_category_id:types.maybeNull(types.number),
            assessment_category_show_as: types.enumeration(aggregateColumnShowAsOptions),
            assessment_category_for: types.maybeNull(types.enumeration(assessmentCategoryForOptions)),
            assessment_category_over: types.maybeNull(types.string),

            position_cognitive_column_id: types.maybeNull(types.number),
            position_in: types.enumeration(positionInOptions),

            average_grade_cognitive_column_id: types.maybeNull(types.number),
            average_grade_in: types.enumeration(positionInOptions),

            student_count_in: types.enumeration(positionInOptions),

            column_weights: types.array(types.model({
                id: types.number,
                column_to_aggregate_id: types.number,
                weight: types.number
            }))
        })
    })
    .views(self => ({
        get isSpecificDateRange() {
            const {type, assessment_category_for} = self.attributes
            const a = type === "ReportCardTemplate::CognitiveColumn::AssessmentCategoryGrade"
            const b = assessment_category_for === "specific_date_range"
            return a && b
        }
    }))