export function mapModelNameToOptions (modelMap) {
    const options = []
    modelMap.forEach(({ id: value, attributes }) => {
        const { name: text } = attributes
        options.push({ text, value })
    })
    return options
}

export function mapModelNameToOptionsWithStringValue (modelMap) {
    const options = []
    modelMap.forEach(({ id: value, attributes }) => {
        const { name: text } = attributes
        options.push({ text, value: value.toString() })
    })
    return options
}

export function mapModelNameToGlobalIDOptions (modelArray) {
    const options = []
    modelArray.forEach((modelMap) => {
        modelMap.forEach(({ globalID: value, attributes }) => {
            const { name: text } = attributes
            options.push({ text, value })
        })
    })
    return options
}