import './BatchAttendanceModal.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from 'mstform'
import { isValidReference } from 'mobx-state-tree'
import Button from '../common/button'
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label'
import MultilineInput from '../common/form/MultilineInput'
import InlineError from '../common/form/InlineError'
import SwitchInput from '../common/form/SwitchInput'
import { modelOf } from '../lib/modelOf'
import { Attendance } from '../store/models/Attendance'
import { Attendance as AttendanceFormStore } from '../store/actions/Attendance'
import {formatDate} from "../lib/formatDate";


const form = new Form(AttendanceFormStore, {
    reason: new Field(converters.maybe(converters.string)),
    half_day: new Field(converters.boolean, { controlled: controlled.object }),
})

@inject('store', 'display')
@observer
class BatchAttendanceModal extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        stuId: PropTypes.string.isRequired,
        dayClicked: PropTypes.instanceOf(Date).isRequired,
        attendanceRecord: modelOf(Attendance),
    }
    
    static defaultProps = {
        attendanceRecord: undefined,
    }

    constructor(props) {
        super(props)
        const { store, attendanceRecord } = this.props
        const { batchStore: { updateBatchAttendanceRecord }, view: { selectedBatchId } } = store
        
        const formRecord = AttendanceFormStore.create({
            student_id: props.stuId,
            date: formatDate(props.dayClicked),
            reason: ''
        })
        if (attendanceRecord) formRecord.setFormInstance(attendanceRecord)

        this.formState = form.state(formRecord, {
            addMode: false,
            save: (node) => {
                const { errors } = updateBatchAttendanceRecord(selectedBatchId, node.toJSON())
                return errors
            }
        })
    }

    handleSubmit = async () => {
        const { onClose } = this.props
        const success = await this.formState.save()
        if (success) {
            onClose()
        }
    }

    handleSubmitMarkPresent = async () => {
        const { store, onClose, attendanceRecord } = this.props
        const { attendanceStore: { deleteAttendanceById } } = store
        const success = await deleteAttendanceById(attendanceRecord.id)
        if (success) {
            onClose()
        }
    }

    render() {
        const { store, onClose, attendanceRecord } = this.props
        const { batchStore: {isLoading} } = store
        
        let isSaving = false
        if (attendanceRecord && isValidReference(()=> attendanceRecord)) isSaving = attendanceRecord.isSaving

        const reason = this.formState.field('reason')
        const half_day = this.formState.field('half_day')
        
        return (
            <ModalForm
                isOkButtonDisabled={!this.formState.isValid || (isLoading || isSaving)}
                okButtonText={(isLoading || isSaving) ? 'Please wait...' : 'Mark Absent'}
                okButtonType="danger"
                onOk={this.handleSubmit}
                onCancel={onClose}
            >
                <Label text="reason">
                    <InlineError field={reason}>
                        <MultilineInput
                            {...reason.inputProps}
                            placeholder="eg: Was sick with malaria"
                        />
                    </InlineError>
                </Label>
                
                <Label text="Half Day?">
                    <InlineError field={half_day}>
                        <SwitchInput {...half_day.inputProps} />
                    </InlineError>
                </Label>
                
                { attendanceRecord && (
                    <div className="markPresentBtn">
                        <Button loading={(isLoading || isSaving)} onClick={this.handleSubmitMarkPresent}>Mark Present</Button>
                    </div>
                )}

            </ModalForm>
        )
    }
}

export default BatchAttendanceModal

