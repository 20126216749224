import { types } from "mobx-state-tree"
import { Gender } from "./Gender";
import { dateRangeType } from "../types/dateRangeType";
import { Search } from "./Search";

export const statusOptions = ["all", "active", "left_institution", "no_wards"]

export const GuardianSearch = types
    .compose(
        Search,
        types.model("GuardianSearch", {
            query: "",
            status: types.optional(
                types.enumeration("statusOptions", statusOptions),
                "active"
            ),
            gender: types.maybe(Gender),
            period_activated: types.maybe(dateRangeType),
            period_left_institution: types.maybe(dateRangeType),
        })
    )