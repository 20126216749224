import { types } from 'mobx-state-tree'

export const SidebarModel = types
    .model('Sidebar', {
        isVisible: false,
        isUserPaneVisible: false,
        isSettingsPaneVisible: false,
    })
    .actions(self => ({
        setVisible(isVisible) {
            self.isVisible = isVisible
        },
        toggleVisible() {
            self.isVisible = !self.isVisible
        },
        toggleUserPane() {
            self.isUserPaneVisible = !self.isUserPaneVisible
        },
        toggleSettingsPane(value) {
            if (value === false && self.isSettingsPaneVisible === false) return;
            if (typeof value === 'undefined') {
                self.isSettingsPaneVisible = !self.isSettingsPaneVisible;
                return;
            }
            self.isSettingsPaneVisible = value;
        },
    }))
