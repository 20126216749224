import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'antd'
// import withFormInput from './withFormInput'


// temporarily disable custom css until it is modified to handle 'small' size prop value
import './SwitchInput.css'

function SwitchInput({ onChange, value, size }) {
  return (
    <Switch
      onChange={onChange}
      checkedChildren=""
      unCheckedChildren=""
      defaultChecked={value}
      size={size}
    />
  )
}
SwitchInput.propTypes = {
  // placeholder: PropTypes.string,
  // error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  // success: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  onChange: PropTypes.func.isRequired,
  // onBlur: PropTypes.func.isRequired,
  // onFocus: PropTypes.func.isRequired,
  // className: PropTypes.string.isRequired,
}

SwitchInput.defaultProps = {
  size: 'default'
}
// export default withFormInput(SwitchInput)
export default SwitchInput