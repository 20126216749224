import { types } from "mobx-state-tree"
import { dateRangeType } from "../types/dateRangeType";
import { Search } from "../models/Search";

export const ReportCardGroupSearch = types
    .compose(
        Search,
        types
            .model("ReportCardGroupSearch", {
                date_created_range: types.maybeNull(dateRangeType),
            })
    )
