import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { ModalContext, ModalProvider, ModalConsumer } from '../common/modal'
import Sidebar from '../common/sidebar'
import SchoolSwap from '../common/schoolswap'
import Desktop from '../common/display/Desktop'
import Search from '../common/assets/Search.svg'
import AccountActions from '../common/account_actions'
import Feedback from '../common/feedback/Feedback';
import ErrorBoundary from '../common/error_boundary';


import SelectedStudent from '../students/SelectedStudent'
import SelectedFinanceTransaction from '../finance/SelectedFinanceTransaction';
import TransactionPreflightForm from '../students/TransactionPreflightForm';
import Events from '../events/Events';
import SelectedEvent from '../events/SelectedEvent'
import SelectedAssessment from "../subjects/SelectedAssessment";
import StudentReportCardPage from "../students/StudentReportCardPage";

function Toolbar() {
    return (
        <Desktop>
            <div className="WrapperToolbar">
                <div className="WrapperToolbar-searchContainer FormInputControl">
                    <img src={Search} className="WrapperToolbar-searchIcon" alt="search" />
                    <input
                        className="FormInputControl-textInput WrapperToolbar-searchInput"
                        type="text"
                        placeholder="Search"
                    />
                </div>
                <AccountActions />
            </div>
        </Desktop>
    )
}

function Sider({ viewStore }) {
    return (
        <>
            <SchoolSwap />
            <Sidebar viewStore={viewStore} />
        </>
    )
}

const Container = inject('store', 'display')(
    observer(({ store, display, children }) => (
        <ModalProvider>
            <ModalContext.Consumer>
                {context => (
                    <div className={display.screensize}>
                        <div
                            className={classNames(
                                'Wrapper',
                                { 'Wrapper-isModalVisible': context.state.isModalVisible },
                                { 'Settings': store.view.isSettingsPage }
                            )}
                        >
                            <Sider viewStore={store.view} />
                            <div className="Wrapper-content">
                                <Toolbar />
                                <ErrorBoundary>
                                    {children}
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div id="portal-root">
                            <Feedback />
                        </div>
                    </div>
                )}
            </ModalContext.Consumer>
            <ModalConsumer screensize={display.screensize} />
        </ModalProvider>
    )))

const StudentApp = inject("store")(
    observer(({ store }) => {
        if (store.view.templateVariant === 'html'){
            return (
                <Container> 
                    {renderPage(store.view)}
                </Container>
            )
        } 

        return renderPage(store.view)
    })
)

function renderPage(viewStore) {
    switch (viewStore.page) {
        case "student":
            return <SelectedStudent />
        case "studentPayFees":
            return <TransactionPreflightForm />
        case "finance/transaction":
            return <SelectedFinanceTransaction />
        case "studentReportCard":
            return <StudentReportCardPage />
        case "event":
            return <SelectedEvent />
        case "events":
            return <Events />
        case "assessment":
            return <SelectedAssessment />
        default:
            return "Welcome! Choose a page from the menu."
    }
}

export default StudentApp;
