import {applySnapshot, types} from "mobx-state-tree"

const ElectiveSubjectRegistrationRow = types
    .model("ElectiveSubjectRegistrationRow", {
        student_id: types.string,
        register: types.boolean
    })


export const ElectiveSubjectRegistration = types
    .model("ElectiveSubjectRegistration", {
        elective_students: types.array(ElectiveSubjectRegistrationRow),

        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, false)
        }), {})
    })
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        },

        get selectedStudentIDs(){
            const selectedStudents = []
            self.elective_students.forEach(row=>{
                if (row.register) selectedStudents.push(row.student_id)
            })
            return selectedStudents
        },

        get areAllStudentsSelected(){
            return !self.elective_students.some(row=> !row.register)
        },
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        
        setFormInstance(subject) {
            const {registeredStudents, assignableStudents} = subject
            const arr = assignableStudents.map((nStudent) => {
                return {student_id: nStudent.id, register: registeredStudents.includes(nStudent)}
            })
            applySnapshot(self, {elective_students: arr})
        },

        selectStudentById(id) {
            self.elective_students.forEach(row=>{
                if (row.student_id === id) row.register = !row.register
            })
        },
        
        selectAllStudents() {
            if (self.areAllStudentsSelected) {
                // deselect all students
                self.elective_students.forEach(row=>{
                    if (row.register) row.register = false
                })
            } else {
                // select all students
                self.elective_students.forEach(row=>{
                    if (!row.register) row.register = true
                })
            }
        }
    }));