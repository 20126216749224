import { types } from "mobx-state-tree"

export const WebPayStudentFeeTransaction = types
    .model("WebPayStudentFeeTransaction", {
        id: types.identifier,
        type: "web_pay_student_fee_transaction",
        attributes: types.maybe(types.model({
            trans_ref: "",
            total_amount: "",
            total_amount_in_kobo: types.maybe(types.integer),
            school_amount: "",
            service_charge_in_kobo: types.maybe(types.integer),
            verified: false,
            transaction_time: "",
            created_at: "",
            // student_id: types.reference(Student)
        }))
    })