import { types, getParent } from "mobx-state-tree"
import { Student } from "./Student"
import { Fee } from "./Fee"
import { User } from "./User"
import { FeeTransaction} from "./FeeTransaction"
import { StudentFeeDiscount } from "./StudentFeeDiscount"

export const StudentFee = types
    .model("StudentFee", {
        id: types.identifier,
        type: "student_fee",
        attributes: types.model({
            student_id: types.number,
            balance: "",
            fee_id: types.number,
            is_paid: false,
            created_by_id: types.maybe(types.number),
            total_discount: "",
            total_paid: "",
            fee_type_name: "",
            fee_description: "",
            fee_amount: "",
            charged: "",
            created_at: "",
        }),
        relationships: types.maybe(types.model({
            student: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(Student))
                })
            })),
            fee: types.model({
                data: types.model({
                    id: types.late(() => types.safeReference(Fee)),
                    type: types.literal("fee")
                })
            }),
            created_by: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.safeReference(User))
                })
            })),
            student_fee_transactions: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.safeReference(FeeTransaction))
                }))
            })),
            discounts: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(StudentFeeDiscount))
                }))
            })),
        }), {}),
    })
    .views(self => ({
        get fee(){
            return self.relationships.fee.data.id
        },
        get discounts(){
            try {
                return self.relationships.discounts.data.map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get studentFeeDiscounts(){
            return self.discounts
        },
        get student(){
            try {
                return self.relationships.student.data.id
            } catch (e) {
                console.error(e)
                return []
            }
        },
        
        get balance() {
            return Number(self.attributes.balance)
        },
        
        get isSelected() {
            return getParent(self, 2)
                .students
                .get(self.attributes.student_id)
                .selectedFees
                .includes(self.id)
        }
    })).actions(self => ({
        removeStudentFeeDiscount(discount_id){
            const foundDiscountIndex = self.discounts.findIndex(({id}) => id === discount_id)
            if(foundDiscountIndex >=0){
                const elem = self.discounts[foundDiscountIndex]
                self.attributes.total_discount = (self.attributes.total_discount - elem.attributes.amount).toString();
                self.relationships.discounts.data.splice(foundDiscountIndex, 1);
            }

        }
}))