import { types } from 'mobx-state-tree'
import { GlobalIDPattern } from '../patterns/globalIDPattern'

export const StudentCategory = types
    .compose(
        "StudentCategory",
        GlobalIDPattern,
        types
            .model({
                id: types.identifier,
                type: types.literal("student_category"),
                attributes: types.maybe(types.model({
                    name: "",
                })),
            })
    )