import React, {Component} from 'react';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';
import isEmpty from 'lodash.isempty';

import {toUpperFirst} from "../lib/stringUtility";

import Card from '../common/card';
import NotMobile from '../common/display/NotMobile';
import ContentLoader from '../common/content_loader'
import StripeLoading from '../common/loading/StripeLoading';
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup';
import BatchBehaviouralDomainGroups from './BatchBehaviouralDomainGroups';
import {ModalContext} from '../common/modal'

import './BatchBehaviouralScoresheet.css';

const termOptions = [
    {label: 'First Term', value: 1},
    {label: 'Second Term', value: 2},
    {label: 'Third Term', value: 3}
]

@inject('store', 'display')
@observer
class BatchBehaviouralScoresheet extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedTermID: 1,
            selectedStudent: {}
        };
    }

    async componentDidMount() {
        const {store, batchDetails} = this.props;
        const {batchStore: {loadBehaviouralScoresheetById}} = store;
        
        await loadBehaviouralScoresheetById(batchDetails.id);
    }

    get selectedStudentScores() {
        const {batchDetails: {behaviouralScoresheet: {studentsBehaviouralScores}}} = this.props;
        const {selectedTermID, selectedStudent} = this.state;

        if (isEmpty(selectedStudent)) return undefined
        
        const selectedStudentID = parseInt(selectedStudent.id, 10);
        const studentBehaviouralTermScores = [];

        studentsBehaviouralScores.forEach(elem => {
            const {termID, attributes: {student_id}} = elem;
            if ((student_id === selectedStudentID) && (termID === selectedTermID)) {
                studentBehaviouralTermScores.push(elem)
            }
        })
        
        return studentBehaviouralTermScores
    }

    get selectedTermStudents() {
        const {batchDetails: {behaviouralScoresheet}} = this.props;
        const {selectedTermID} = this.state;
        const {attributes: {student_ids_for_terms}} = behaviouralScoresheet;
        
        let termStudents = [];

        student_ids_for_terms.forEach((students_id_for_term)=>{
            const {term_id, student_ids} = students_id_for_term;

            if (term_id === selectedTermID) {
                termStudents = student_ids.map(x => x);
            }
        })
        
        return termStudents
    }

    handleTermSelect = (selectedValue) => {
        this.setState({selectedTermID: selectedValue})
    }

    onHandleStudentNameClick = (student, context) => {
        const {display} = this.props;

        this.setState({
            selectedStudent: student,
        }, () => {
            if (display.isMobile) {
                const {selectedStudent, selectedTermID} = this.state;
                const {batchDetails} = this.props
                context.setContent(
                    `Update Scores`,
                    <BatchBehaviouralDomainGroups
                        studentDetails={selectedStudent}
                        currentTerm={selectedTermID}
                        studentBehavDomainSores={this.selectedStudentScores}
                        batchDetails={batchDetails}
                    />,
                    true
                )
            }
        });
    };

    render() {
        const {batchDetails, display} = this.props;
        const {selectedStudent, selectedTermID} = this.state;
        const {behaviouralScoresheet} = batchDetails;

        if (!behaviouralScoresheet || isEmpty(behaviouralScoresheet.attributes)) return <Loading />;
        
        const {isLoading} = behaviouralScoresheet;
        
        const sortedStudents = this.selectedTermStudents.sort((a, b) =>
            a.studentName.localeCompare(b.studentName, 'en', {sensitivity: 'base', ignorePunctuation: true})
        );

        const activeStudents = [];
        const formerStudents = [];
        
        sortedStudents.forEach((student) => {
            const {attributes: {status}} = student;
            if (status === 'active') {
                activeStudents.push(student);
            } else {
                formerStudents.push(student);
            }
        });
        
        const promptClass = display.isMobile ? "pl-1 pt-1" : "pl-2 pt-2 text-h3"
        const studentListCardClass = display.isMobile ? "studentBehaviour-card utils-padded-small" : "studentBehaviour-card utils-padded"

        return (
            <div>
                <Card style={{marginBottom: 10, position: ' relative'}}>
                    <div className="BatchAttendanceReport-summary1">
                        <SelectButtonGroup
                            selectOptions={termOptions}
                            onChange={this.handleTermSelect}
                            value={selectedTermID}
                        />
                    </div>
                </Card>

                <Card title={undefined} style={{position: 'relative'}}>
                    <StripeLoading loading={isLoading} />
                    
                    <div className={promptClass}><span>Select Student to view {termOptions.find((o)=> o.value === selectedTermID).label} Grades</span></div>
                    
                    <div className="flex">
                        <div className={studentListCardClass}>
                            <p className="text--bold utils-padded-small">Active Students</p>
                            <ModalContext.Consumer>
                                {(context) => (
                                    <>
                                        {
                                            activeStudents.map((student) => {
                                                const {id, studentName} = student;
                                                return (
                                                    <div
                                                        key={id}
                                                        onClick={() => this.onHandleStudentNameClick(student, context)}
                                                        className={classNames('utils-padded-small touchable-opacity students-behaviourList', {
                                                            'text--primary': selectedStudent.id === id
                                                        })}
                                                    >
                                                        {studentName}
                                                    </div>
                                                )
                                            })
                                        }

                                        <p className="text--bold utils-padded-small">Former Students</p>

                                        {
                                            formerStudents.map((student) => {
                                                const {id, studentName, attributes: {status}} = student;
                                                return (
                                                    <div
                                                        key={id}
                                                        className={classNames('utils-padded-small touchable-opacity students-behaviourList', {
                                                            'text--primary': selectedStudent.id === id
                                                        })}
                                                    >
                                                        <div onClick={() => this.onHandleStudentNameClick(student, context)}>{studentName}</div>
                                                        <div className={classNames('text--grey', {'text--success': (status === 'graduated')})}>
                                                            {toUpperFirst(status)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                )}
                            </ModalContext.Consumer>
                        </div>
                        <NotMobile>
                            <div className="studentBehaviour-card utils-padded">
                                {!isEmpty(selectedStudent) && (
                                    <BatchBehaviouralDomainGroups
                                        studentDetails={selectedStudent}
                                        currentTerm={selectedTermID}
                                        studentBehavDomainSores={this.selectedStudentScores}
                                        batchDetails={batchDetails}
                                    />
                                )}
                            </div>
                        </NotMobile>
                    </div>
                </Card>

            </div>
        );
    }
}

export default BatchBehaviouralScoresheet;

const Loading = () => (
    <Card>
        <ContentLoader height="70" width='300'>
            <rect x="20" y="10" rx="1" ry="1" width="30" height="8" />
            <rect x="60" y="10" rx="1" ry="1" width="30" height="8" />
        </ContentLoader>
    </Card>
)
