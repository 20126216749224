import React, { Component } from 'react'
import * as PropTypes from "prop-types";
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash.isempty';
import {Col, Row} from "antd";

import ContentLoader from '../common/content_loader';

@inject("store")
@observer
class FinanceSummary extends Component {
    
    static propTypes = {
        studentIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }
    
    constructor(props) {
        super(props)
        this.state = { financeSummary: {} }
    }
    
    async componentDidMount() {
        const { store, studentIDs } = this.props
        const { studentStore } = store
        // eslint-disable-next-line no-unused-vars
        const {data, error} = await studentStore.fetchFinanceSummary(studentIDs)
        if (data) this.setState({financeSummary: data})
    }

    render() {
        const { store: {formatCurrency} } = this.props
        const { financeSummary } = this.state
        
        const LoadingText = (
            <div style={{ width: 150, height: 20 }}>
                <ContentLoader width="150" height="20">
                    <rect x="20" y="2.5" rx="4" ry="4" width="130" height="15" />
                </ContentLoader>
            </div>
        )

        return (
            <>
                <Row gutter={15} type="flex" className="text-h4 mb-1">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">DUE FEES</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_due_fees)}</span>
                        )}
                    </Col>
                </Row>
                <Row gutter={15} type="flex" className="text-h4 mb-1">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">UNPAID FEES</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_unpaid_fees)}</span>
                        )}
                    </Col>
                </Row>
                <Row gutter={15} type="flex" className="text-h4">
                    <Col span={12} className="text--right">
                        <span className="text--primary utils-prepend">WALLET BALANCE</span>
                    </Col>
                    <Col span={12}>
                        {isEmpty(financeSummary) ? LoadingText : (
                            <span className="utils-append">{formatCurrency(financeSummary.total_wallet_balance)}</span>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default FinanceSummary
