import { types } from "mobx-state-tree"
import { LoadingPattern } from "../patterns/LoadingPattern"
import { includeAttendanceOptions, pdfTemplateOptions, roundingStrategyOptions } from '../actions/ReportCardTemplate';
import { SpecialRole } from './SpecialRole';
import { ReportCardTemplateCognitiveColumn } from './ReportCardTemplateCognitiveColumn';
import { GradeScale } from '../actions/GradeScale';

export const ReportCardTemplate = types
    .compose(
        "ReportCardTemplate",
        LoadingPattern(),
        types
            .model({
                id: types.identifier,
                type: "report_card_template",
                attributes: types.model({
                    name: types.maybeNull(types.string),
                    title: types.maybeNull(types.string),
                    shorten_numeric_value_by: types.enumeration(roundingStrategyOptions),
                    numeric_value_decimal_places: types.maybe(types.number),
                    include_attendance: types.enumeration(includeAttendanceOptions),
                    include_affective_domain: types.boolean,
                    include_psychomotor_domain: types.boolean,
                    affective_domain_header: types.maybeNull(types.string),
                    psychomotor_domain_header: types.maybeNull(types.string),
                    include_signatures: types.boolean,
                    include_positions: types.string,
                    pdf_template: types.maybeNull(types.optional(types.enumeration(pdfTemplateOptions), pdfTemplateOptions[0])),
                    header: types.maybeNull(types.string),
                    footer: types.maybeNull(types.string),
                    comment_role_ids: types.maybeNull(types.array(types.reference(SpecialRole))),
                    final_column_id: types.maybeNull(types.reference(ReportCardTemplateCognitiveColumn)),
                    watermark_image_url: types.maybeNull(types.string)
                }),
                relationships: types.model({
                    grade_scale: types.model({
                        data: types.maybeNull(types.model({
                            id: types.late(() => types.safeReference(GradeScale))
                        }))
                    }),
                    cognitive_columns: types.maybe(types.model({
                        data: types.array(types.model({
                            id: types.late(() => types.reference(ReportCardTemplateCognitiveColumn))
                        }))
                    })),
                })
            })
        
    )
    .views(self => ({
        get showPositionsBoolean() {
            return self.attributes.include_positions !== 'no'
        },
        get columnsWithSpecificDateRange() {
            const columns = []
            self.columns.forEach((column) =>{
                if (column.isSpecificDateRange) columns.push(column)
            })
            return columns
        },
        get shownColumns() {
            return self.columns.filter((column) => column.attributes.show_on_report )
        },
        get usesSpecificAttendanceDateRange() {
            return self.attributes.include_attendance === 'specific_date_range'
        },
        get columns() {
            try {
                return self.relationships.cognitive_columns.data.map(item => item.id)
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
    }))