import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Popover, Row, Col } from 'antd'
import Collection from '../common/Collection'
import BatchCollectionSearch from './BatchCollectionSearch';
import BatchCollectionItem from './BatchCollectionItem';
import more_vert from '../common/assets/more-vert.svg'
import people from './assets/people.svg'
import NewBatch from './NewBatch';
import MoreButtonPopover from './MoreButtonPopover'


const columns = [
    { key: 'name', render: 'Name' },
    { key: 'student_count', render: 'No. of students' },
    { key: 'more_vert', render: ''}
]

@inject('store', 'display')
@observer
class BatchCollection extends Component{

  get popoverPlacement() {
    const { display } = this.props
    return display.isDesktop ? 'bottomRight' : 'topRight'
  }

    render(){
        const { store } = this.props;
        const { view, batchStore: settings } = store
        const { deleteBatch, searchResults} =settings
        const tableData = searchResults
            .map((curSearchResult) => {
              const { id, attributes: { name, student_count } } = curSearchResult;
              return ({
                id,
                name: (
                    <a
                        href={`/batches/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openBatchPageById(id)
                        }}
                    >
                        {name}
                    </a>
                ),
                student_count,
                more_vert: (
                    <Popover
                        content={<MoreButtonPopover batchInfo={curSearchResult} deleteBatch={deleteBatch} />}
                        placement={this.popoverPlacement}
                    >
                        <div className="touchable-opacity text--alignCenter ">
                          <img src={more_vert} alt="..." />
                        </div>  
                    </Popover>
                   
                )}
              )
            })
        const tableProps = { onRowSelectionChange: null }
        const tableSettings = {
            tableData,
            columns,
            tableProps
        }
        const buildMobileItem = (batch) => (
            <BatchCollectionItem id={batch.id} key={batch.id} batch={batch} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div>&nbsp;</div>
                    {collectionSummaryText}
                    <NewBatch />
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={people} alt="" />
                        <span className="text-small utils-append text--uppercase">Batches</span>
                    </Col>
                    <Col span={12}>
                        <NewBatch />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12} offset={6}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={BatchCollectionSearch}
            />
        )
    }
}


export default BatchCollection