import { types, flow } from "mobx-state-tree"
import FileSaver from "file-saver";
import * as ReactGA from "react-ga";

import { SearchMeta } from "./models/SearchMeta";
import { FinanceTransaction } from "./models/FinanceTransaction";
import { FinanceTransactionSearch } from "./models/FinanceTransactionSearch";
import { filterType } from "./helpers/filterType";
import {BankTeller} from "./models/BankTeller";
import {WebPayStudentFeeTransaction} from "./models/WebPayStudentFeeTransaction";
import {LoadingPattern} from "./patterns/LoadingPattern";
import {UsesBluebicStorePattern} from "./patterns/UsesBluebicStorePattern";

export const FinanceTransactionStore = types
    .compose(
        LoadingPattern(),
        UsesBluebicStorePattern,
        types
            .model("FinanceTransactionStore", {
                financeTransactions: types.map(FinanceTransaction),
                searchResults: types.optional(
                    types.array(types.reference(FinanceTransaction)),
                    []
                ),
                bankTellers: types.map(BankTeller),
                webPayStudentFeeTransactions: types.map(WebPayStudentFeeTransaction),
                searchFormInstance: types.optional(FinanceTransactionSearch, {}),
                searchMeta: types.optional(SearchMeta, {}),
                selectedFinanceTransactions: types.array(types.string),
            })
            .views(self => ({
                get isAllSelected() {
                    return !self.searchMeta.total_ids.some(val => self.selectedFinanceTransactions.indexOf(val) === -1)
                },
                get service() {
                    return self.bluebic.financeTransactionService
                }
            }))
            .actions(self => {

                function updateFinanceTransactions(data) {
                    data.forEach(json => self.financeTransactions.put(json))
                }

                function updateBankTellers(data) {
                    data.forEach(json => self.bankTellers.put(json))
                }

                function updateWebPayStudentFeeTransactions(data) {
                    data.forEach(json => self.webPayStudentFeeTransactions.put(json))
                }

                function updateSearchResults(data) {
                    updateFinanceTransactions(data)
                    self.searchResults = []
                    data.forEach(json => self.searchResults.push(json.id))
                }

                const search = flow(function* search() {
                    try {
                        self.markLoading(true)
                        const { data, meta, included } = yield self.bluebic.financeTransactionService.search(self.searchFormInstance.toJSON())
                        self.bluebic.handleUpdateStores(included)
                        updateSearchResults(data)
                        self.searchMeta = meta
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to load finance transactions", err)
                    }
                })

                function onUpdate(included) {
                    filterType("finance_transaction", included, updateFinanceTransactions)
                    filterType("bank_teller", included, updateBankTellers)
                    filterType("web_pay_student_fee_transaction", included, updateWebPayStudentFeeTransactions)
                }

                function selectTransactionById(id) {
                    if (self.selectedFinanceTransactions.includes(id)) {
                        const index = self.selectedFinanceTransactions.findIndex(id_ => id_ === id)
                        self.selectedFinanceTransactions.splice(index, 1)
                    } else {
                        self.selectedFinanceTransactions.push(id)
                    }
                }

                function selectAllTransactions() {
                    if (self.isAllSelected) {
                        self.selectedFinanceTransactions.length = 0
                    } else {
                        self.searchMeta.total_ids.forEach(id => {
                            if (!self.selectedFinanceTransactions.includes(id)) {
                                self.selectedFinanceTransactions.push(id)
                            }
                        })
                    }
                }

                function updateSearch(model) {
                    self.searchFormInstance = model
                    search()
                }

                function markTransactionLoadingById(id, flag) {
                    if (!self.financeTransactions.has(id)) {
                        self.financeTransactions.put({ id })
                    }
                    self.financeTransactions.get(id).isLoading = flag
                }

                const loadFinanceTransactionById = flow(function* loadFinanceTransactionById(id) {
                    try {
                        markTransactionLoadingById(id, true)
                        const { data, included } = yield self.bluebic.financeTransactionService.showFinanceTransaction(id)
                        self.bluebic.handleUpdateStores(included)
                        self.financeTransactions.put(data)
                        self.financeTransactions.get(id).isAssociationsLoaded = true
                        markTransactionLoadingById(id, false)
                    } catch (err) {
                        markTransactionLoadingById(id, false)
                        console.error(`Failed to load finance transaction with id ${id}`, err)
                    }
                })

                const downloadFinanceTransactionListSpreadsheet = flow(function* downloadFinanceTransactionListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadFinanceTransactionListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Receipt Data',
                            nonInteraction: false,
                            value: action.item_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of Finance Transaction list`, err)
                        action.markSaving(false)
                    }
                })

                const fetchFinanceSummary = flow(function* fetchFinanceSummary(studentIDs) {
                    try {
                        const {data} = yield self.bluebic.financeTransactionService.fetchFinanceSummary(studentIDs)
                        return {data}
                    } catch (err) {
                        console.error("Failed to fetch finance transactions summary", err)
                        return {error: "Failed to fetch finance transactions summary"}
                    }
                })

                return {
                    loadFinanceTransactionById,
                    onUpdate,
                    search,
                    selectAllTransactions,
                    selectTransactionById,
                    updateSearch,
                    downloadFinanceTransactionListSpreadsheet,
                    fetchFinanceSummary
                }
            })
    )

