import ApplicationPolicy from "./ApplicationPolicy";

class WalletTransactionPolicy extends ApplicationPolicy{
    get can_index(){
        return this.user.has_any_role(['finance_control','finance'])
    }

    get can_create(){
        return this.can_index
    }


}

export default WalletTransactionPolicy