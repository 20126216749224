import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform";
import * as PropTypes from "prop-types";
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import SelectInput from '../common/form/SelectInput';
import SwitchInput from '../common/form/SwitchInput';
import {CreateSubject} from '../store/actions/Subject'
import ContentLoader from '../common/content_loader'
import InlineError from '../common/form/InlineError'
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions'

const form = new Form(CreateSubject, {
    id: new Field(converters.maybeNull(converters.string)),
    subject_base_id: new Field(converters.string, {
        controlled: controlled.object, required: true
    }),
    batch_id:new Field(converters.string, {
        controlled: controlled.object, required: true
    }),
    is_elective: new Field(converters.boolean, {
        controlled: controlled.object, required: true
    }),
})

@inject('store', 'display')
@observer
class AddSubject extends React.Component {
    state = {
        selectedSubject: null
     }

    async componentWillMount() {

        const { store, editMode, subjectId } = this.props
        const { subjectStore } = store
        const { addSubjectFormInstance, createSubject, editSubject, selectSubjectForEdit, searchFormInstance } = subjectStore


        if (editMode && subjectId) {
            const selectedSubject = await selectSubjectForEdit(subjectId)
            if (selectedSubject) {
                this.formState = form.state(addSubjectFormInstance, {
                    addMode: false,
                    save: async (node) => {
                        const { errors } = await editSubject(node.toJSON())
                        return errors
                    }
                })
                this.setState({ selectedSubject })
            }
        } else {
            this.formState = form.state(addSubjectFormInstance, {
                addMode: false,
                save: async (node) => {
                    const { errors } = await createSubject(node.toJSON())
                    return errors
                }
            })
            this.formState.field('subject_base_id').setValue(undefined)
            const batchField = this.formState.field('batch_id')
            if (searchFormInstance.batch_id && !batchField.value) batchField.setValue(searchFormInstance.batch_id)
        }
    }

    handleSubmit = async () => {
        const { onCancel } = this.props
        const success = await this.formState.save()
        if (success) {
            const { store : { subjectStore : { resetAddSubjectForm }} } = this.props
            resetAddSubjectForm()
            onCancel()
        }
    }

    render() {
        const { store, display, onCancel, editMode } = this.props
        const { subjectStore } = store
        const { isLoading } = subjectStore
        const {selectedSubject} = this.state
        
        if (editMode) {
            if ((selectedSubject === null) || selectedSubject.isLoading) return <Loading />
        }

        const subject_base_id = this.formState.field("subject_base_id")
        const is_elective = this.formState.field("is_elective")
        const batch_id = this.formState.field("batch_id")
        
        const subject_bases = subjectStore.subjectBases
        const batches = store.batchStore.items
        
        
        return (
            <ModalForm
                isOkButtonDisabled={!this.formState.isValid || isLoading}
                okButtonText={isLoading ? "Please wait..." : "Save Subject"}
                onOk={this.handleSubmit}
                onCancel={onCancel}
            >
                <Label text="Subject Base" required>
                    <InlineError field={subject_base_id}>
                        <SelectInput
                            {...subject_base_id.inputProps}
                            placeholder="Select Subject"
                            options={mapModelNameToOptions(subject_bases)}
                            defaultOptionText='Select Subject'
                        />
                    </InlineError>
                </Label>
                <Label text="Batch" inline={false} required>
                    <InlineError field={batch_id}>
                        <SelectInput
                            {...batch_id.inputProps}
                            options={mapModelNameToOptions(batches)}
                            placeholder="Select Batch"
                            defaultOptionText='Batch'
                        />
                    </InlineError>
                </Label>
                <Label text="Is Elective" inline={display.isNotMobile}>
                    <SwitchInput {...is_elective.inputProps} />
                </Label>
            </ModalForm>
        )
    }
}
AddSubject.propTypes = {
    subjectId: PropTypes.string,
    onCancel: PropTypes.func.isRequired
}

AddSubject.defaultProps = {
    subjectId: ''
}

export default AddSubject

const Loading = () => (
    <ContentLoader height="70" width='300'>
        <rect x="20" y="10" rx="1" ry="1" width="30" height="8" />
        <rect x="80" y="7" rx="1" ry="1" width="100" height="14" />
        <rect x="20" y="30" rx="1" ry="1" width="30" height="8" />
        <rect x="80" y="29" rx="1" ry="1" width="20" height="10" />
    </ContentLoader>
)