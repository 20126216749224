import './DateRangePicker.css'

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {isSameDay, isBefore, addMonths, subMonths} from 'date-fns'
import { inject, observer } from 'mobx-react';
import classNames from 'classnames'
import isEmpty from 'lodash.isempty';

import Button from '../button'
import Calendar from './Calendar'
import { dateRangePropType } from '../../lib/dateRangePropType';
import { dateRangeTypeToDates, datesToDateRangeType } from '../../lib/formatDate';

const reduceBoolsOr = (values) => values.includes(true)

@inject('display', 'store')
@observer
class DateRangePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultRange: dateRangePropType,
    showOptions: PropTypes.bool.isRequired
  }

  static defaultProps = {
    defaultRange: null
  }

  state = {
    currentMonth: new Date(),
    formattedValue: null,
    startDate: null,
    endDate: null
  }

    static getDerivedStateFromProps(nextProps, prevState){
        const {defaultRange} = nextProps
        const {formattedValue: prevFormattedValue} = prevState || {}
        
        if (!defaultRange && typeof defaultRange !== 'string') return null

        if (prevFormattedValue !== defaultRange){
            let currentMonth = null;
            let endDate = null;
            let startDate = null;
            
            const trimmed = defaultRange.trim();
            
            if (trimmed) {
                [startDate, endDate] = dateRangeTypeToDates(trimmed);
                currentMonth = startDate
            }else {
                [startDate, endDate] = [null, null]
                currentMonth = new Date()
            }

            return { currentMonth, startDate, endDate, formattedValue: defaultRange };
        }
        
        return null
    }

  get options() {
    const { store: {meta: { currentAcademicSession }}} = this.props
      
    if (!currentAcademicSession ) return  []
      
    const {
        attributes: {
            first_term_begins,
            first_term_ends,
            second_term_begins,
            second_term_ends,
            third_term_begins,
            third_term_ends
        }
    } = currentAcademicSession
      
    return [
      { text: 'Current Session', startDate: new Date(first_term_begins), endDate: new Date(third_term_ends) },
      { text: 'First Term', startDate: new Date(first_term_begins), endDate: new Date(first_term_ends) },
      { text: 'Second Term', startDate: new Date(second_term_begins), endDate: new Date(second_term_ends) },
      { text: 'Third Term', startDate: new Date(third_term_begins), endDate: new Date(third_term_ends) },
      { text: 'Custom Range', startDate: null, endDate: null },
    ]
  }

  onChange = ({ startDate, endDate }) => {
    const { onChange } = this.props
    const outputFormat = datesToDateRangeType(startDate, endDate)
      return this.setState({ formattedValue: outputFormat }, () => {
          onChange(outputFormat)
      })
  }
  
  onClear = () => {
      const { onChange } = this.props
      return this.setState({ startDate: null, endDate: null, formattedValue: '' }, () => {
          onChange('')
      })
  }

  onChangeOption = ({ startDate, endDate }) => {
    const currentMonth = startDate || new Date()
    this.setState({ currentMonth, startDate, endDate })
    if (startDate && endDate) {
      this.onChange({ startDate, endDate })
    }
  }

  handleChange = (date) => {
    const { startDate, endDate } = this.state
 
    if (startDate && endDate) {
      if (isSameDay(date, endDate)) {
        return this.setState({ endDate: null })
      }
      return this.setState({ startDate: date, endDate: null })
    }
    if (!startDate) {
      return this.setState({ startDate: date })
    }
    
    if (isBefore(date, startDate)) {
      return this.setState({ startDate: date })
    }
    
    return this.setState({ endDate: date }, () => {
      const { startDate: start, endDate: end } = this.state
      this.onChange({ startDate: start, endDate: end })
    })
  }

  nextMonth = () => {
    const { currentMonth } = this.state
    this.setState({
      currentMonth: addMonths(currentMonth, 1)
    })
  }

  prevMonth = () => {
    const { currentMonth } = this.state
    this.setState({
      currentMonth: subMonths(currentMonth, 1)
    })
  }

  render() {
      // eslint-disable-next-line no-unused-vars
    const { display, showOptions, defaultRange } = this.props
      // eslint-disable-next-line no-unused-vars
    const { currentMonth, startDate, endDate, formattedValue } = this.state
    const nextMonth = addMonths(currentMonth, 1)
    const isOptionActive = (option) => {
      const isSameStartDate = isSameDay(option.startDate, startDate)
      const isSameEndDate = isSameDay(option.endDate, endDate)
      if (isSameStartDate && isSameEndDate) return true
      if (option.startDate === null && option.endDate === null) {
        const values = this.options.filter(o => o.text !== option.text).map(o => isOptionActive(o))
        return !reduceBoolsOr(values)
      }
      return false
    }
    const datePickerOptionBtnSize = display.isDesktop ? null : "large"
    const datePickerOptionBtnClassName = (option) => classNames('DateRangePicker-option', { 'DateRangePicker-option-isActive': isOptionActive(option) })
    const datePickerClassName = () => classNames(display.screensize, "DateRangePicker", { 'DateRangePicker-hide-options': !showOptions })

    const datePickerOptionBtnType = (option) => (
      isOptionActive(option) ? 'primary' : 'secondary'
    )

    return (
      <div className={datePickerClassName()}>
        {showOptions && !isEmpty(this.options) && (
          <div className="DateRangePicker-options">
              {this.options.map((option) => (
                <Button
                  onClick={() => this.onChangeOption(option)}
                  buttonType={datePickerOptionBtnType(option)}
                  className={datePickerOptionBtnClassName(option)}
                  key={option.text}
                  size={datePickerOptionBtnSize}
                >{option.text}
                </Button>
              ))}
          </div>
        )}
        <div className="DateRangePicker-calendarGroup">
          <Calendar
            currentMonth={currentMonth}
            startDate={startDate}
            endDate={endDate}
            onChange={this.handleChange}
            selectsStart
            selectsEnd={display.isMobile}
            nextMonth={this.nextMonth}
            prevMonth={this.prevMonth}
          />
          
          {display.isMobile || (
            <Calendar
              currentMonth={nextMonth}
              startDate={startDate}
              endDate={endDate}
              selectsEnd
              onChange={this.handleChange}
              nextMonth={this.nextMonth}
              prevMonth={this.prevMonth}
            />
          )}

          <Button
              fullwidth
              buttonType="outline"
              onClick={this.onClear}
          >
              Clear Selection
          </Button>
        </div>
      </div>
    )
  }
}
export default DateRangePicker