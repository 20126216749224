import { getParent, types } from 'mobx-state-tree'
import {parseISO} from 'date-fns'
import { EmployeeDepartment } from './EmployeeDepartment'
import { EmployeeCategory } from './EmployeeCategory'
import { EmployeePosition } from './EmployeePosition'
import { Course } from './Course'
import { StudentCategory } from './StudentCategory'
import {PolymorphicJSONReference} from '../types/JSONAPIReferenceTypes'


export const Event = types
    .model("Event", {
        id: types.identifier,
        type: "event",
        attributes: types.maybe(types.model({
            title: types.string,
            description: types.maybeNull(types.string),
            start_time: types.string,
            end_time: types.maybeNull(types.string),
            all_day: types.boolean,
            common: types.boolean,
            holiday: types.boolean,
        })),
        relationships: types.optional(types.model({
            user_groups: types.maybe(types.model({
                data: types.array(PolymorphicJSONReference([Course, StudentCategory, EmployeeCategory, EmployeePosition,EmployeeDepartment]))
            })),
        }), {}),
        isLoading: false
    })
    .views(self => ({
        get isInitialized(){
            return self.attributes
        },
        get userGroupsGid(){
            return self.relationships.user_groups.data.map((group) => group.id.globalID)
        },
        get fullCalendarFormat(){
            const { URL, startTime, endTime, attributes: {all_day, title } } = self
            try {
                return {
                    id: self.id,
                    title,
                    start: startTime,
                    end: endTime,
                    allDay: all_day,
                    url: URL,
                    classNames: [],
                    editable: false,
                    rendering: undefined,
                    color: undefined,
                    backgroundColor: undefined,
                    borderColor: undefined,
                    textColor: undefined,
                    extendedProps: undefined
                }
            } catch (e) {
                console.error(e)
                return undefined
            }
        },
        get startTime(){
            const { attributes: {start_time} } = self
            return parseISO(start_time)
        },
        get endTime(){
            const { attributes: {end_time} } = self
            if (!end_time) return null
            return parseISO(end_time)
        },
        get URL(){
            return getParent(self, 2).bluebic.view.getEventPage(self.id)
        }
    }))
