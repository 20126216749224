import React from 'react';
import { observer, inject } from 'mobx-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import isEmpty from 'lodash.isempty'
import Card from '../common/card';
import { colors } from '../common/colors';
import { objectHash } from '../common/objectHash';


import icon from './assets/analysis.svg';
import './StudentPerformance.css';

const title = 'Income Trend';

@inject('display', 'store')
@observer
class StudentPerformance extends React.Component {

    renderTooltip = (value, name, props) => {
        const {store: {formatCurrency}} = this.props
        
        if (isEmpty(value.payload)) return "Loading..."
        
        const {current_name, previous_name, 'Previous 4 Months': previous, 'Last 4 Months': current} = value.payload[0].payload
        
        return (
            <Card className="text--primary text-small">
                <div>{`Wk ended ${previous_name  } - ${ formatCurrency(previous)}`}</div>
                <div>{`Wk ended ${current_name  } - ${ formatCurrency(current)}`}</div>
            </Card>
        )
    }

    renderLegend = (props) => {
        const {display} = this.props
        const { payload } = props
        
        const legendGroup = (name, color, index) => (
                <React.Fragment key={name}>
                      <span
                          className={[
                              'studentPerformance-legendKey',
                              (index === 1) ? 'studentPerformance-legendKey--dashed' : '',
                          ].join(' ')}
                          style={{ borderColor: color }}
                      />
                    <span className="studentPerformance-legendText">{name}</span>
                </React.Fragment>
            )
        
        if (isEmpty(payload)){
            return (
                <div className={`flex-row ${  display.isTablet ? 'flex-justifyCenter' : ''}`}>
                {['Last 4 Months','Previous 4 Months'].map((x, index) => legendGroup(x, index === 1 ? colors.blue : colors.grey, index))}
                </div>
            )
        }
        
        
        return (
            <div className={`flex-row ${  display.isTablet ? 'flex-justifyCenter' : ''}`}>
                {payload.map((entry, index) => ( legendGroup(entry.value, entry.color, index) ))}
            </div>
        );
    };
    
    render() {
        const {display} = this.props
        let {chartData} = this.props
        
        
        const chartMargin = display.isNotDesktop ? { top: 0, left: 20, right: 0 } : { top: 15, left: 30, right: 30 };
        const fontSize = display.isNotDesktop ? 8 : 10;
        let maxData = 0
        let minData = 0
        
        if(chartData){
            const minArray = chartData.map(({'Last 4 Months': current, 'Previous 4 Months': previous}) => current < previous ? current : previous)
            const maxArray = chartData.map(({'Last 4 Months': current, 'Previous 4 Months': previous}) => current > previous ? current : previous)
            maxData = Math.max(...maxArray)
            minData = Math.min(...minArray)
        }else{
            chartData = Array(5).fill("0").map((zero) => (
                {
                    'Last 4 Months': zero,
                    'Previous 4 Months': zero,
                    'current_name': "Current",
                    'previous_name': "Previous"
                }
            ))
        }
        
        return (
            <div className='dashboard-studentPerformance studentPerformance'>
                <div className="flex-row flex-spaceBetween studentPerformance-head">
                    <div className="flex-row">
                        <img className="dashboard-icon" src={icon} alt="" />
                        <span className="text-small text--uppercase">{title}</span>
                    </div>
                    <span className="text--primary text-small text--bold text--uppercase touchable-opacity">View reports</span>
                </div>
                <Card>
                    <ResponsiveContainer height={280}>
                        <LineChart data={chartData} margin={chartMargin}>
                            <XAxis
                                xAxisId={'trend-X'}
                                interval="preserveStartEnd"
                                dataKey="current_name"
                                height={50}
                                stroke={colors.grey}
                                strokeWidth={0.25}
                                tick={{ fontSize, fill: colors.grey }}
                            />
                            <YAxis
                                yAxisId="trend-Y"
                                axisLine={false}
                                interval="preserveStartEnd"
                                dataKey="Last 4 Months"
                                width={15}
                                tick={{ fontSize, fill: colors.grey }}
                                domain={[minData, maxData]}
                            />
                            <CartesianGrid
                                vertical={false}
                                stroke={colors.grey}
                                strokeWidth={0.25}
                            />
                            <Tooltip cursor={false} content={this.renderTooltip} />
                            <Legend content={this.renderLegend} />
                            <Line
                                xAxisId="trend-X"
                                yAxisId="trend-Y"
                                type="monotoneX"
                                dataKey="Last 4 Months"
                                stroke={colors.blue}
                                dot={false}
                                strokeWidth={1.5}
                            />
                            <Line
                                xAxisId="trend-X"
                                yAxisId="trend-Y"
                                type="monotoneX"
                                dataKey="Previous 4 Months"
                                stroke={colors.grey}
                                dot={false}
                                strokeDasharray="5 5"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Card>
            </div>
        );
    }
}

export default StudentPerformance;