import { types, flow, destroy } from "mobx-state-tree";
import { validationErrorsHandler } from "./helpers/errors";
import { LoadingPattern } from "./patterns/LoadingPattern";
import { UsesBluebicStorePattern } from "./patterns/UsesBluebicStorePattern";
import { AcademicSession } from "./models/AcademicSession";
import { CreateAcademicSession } from "./actions/AcademicSession";
import { prepareAcademicSessionDates } from "../lib/formatDate";
import {filterType} from "./helpers/filterType";

export const AcademicSessionStore = types
    .compose(
        LoadingPattern({
            isFetchSessionsLoading: false,
            isUpdateSessionLoading: false,
            isCreateSessionLoading: false,
            isDeleteSessionLoading: false,
        }),
        UsesBluebicStorePattern,
        types
            .model("AcademicSessionStore", {
                academicSessions: types.map(AcademicSession),
                addAcademicSessionFormInstance: types.optional(CreateAcademicSession, {}),
            })
            .actions(self => {

                function updateAcademicSessions(sessions) {
                    sessions.forEach((session) => self.academicSessions.put(session))
                }

                function onUpdate(included) {
                    filterType("academic_session", included, updateAcademicSessions)
                }

                const getAcademicSessions = flow(function* getAcademicSessions() {
                    try {
                        self.markLoading("isFetchSessionsLoading", true);
                        const { data } = yield self.bluebic.academicSessionService.getAcademicSessions();
                        updateAcademicSessions(data);
                        self.markLoading("isFetchSessionsLoading", false);
                        return { data };
                    } catch (err) {
                        self.markLoading("isFetchSessionsLoading", false);
                        return validationErrorsHandler(err);
                    }
                });

                const createAcademicSession = flow(function* createAcademicSession(academic_session) {
                    try {
                        self.markLoading("isCreateSessionLoading", true);
                        const { data } = yield self.bluebic.academicSessionService.createAcademicSession(prepareAcademicSessionDates(academic_session));
                        updateAcademicSessions([data]);
                        self.markLoading("isCreateSessionLoading", false);
                        return { data };
                    } catch (err) {
                        self.markLoading("isCreateSessionLoading", false);
                        return validationErrorsHandler(err);
                    }
                });

                const updateAcademicSession = flow(function* updateAcademicSession(academic_session, id) {
                    try {
                        self.markLoading("isUpdateSessionLoading", true);
                        const { data } = yield self.bluebic.academicSessionService.updateAcademicSession(prepareAcademicSessionDates(academic_session), id);
                        updateAcademicSessions([data]);
                        self.markLoading("isUpdateSessionLoading", false);
                        return { data };
                    } catch (err) {
                        self.markLoading("isUpdateSessionLoading", false);
                        return validationErrorsHandler(err);
                    }
                });

                const deleteAcademicSession = flow(function* deleteAcademicSession(id) {
                    try {
                        self.markLoading("isDeleteSessionLoading", true);
                        const { data } = yield self.bluebic.academicSessionService.deleteAcademicSession(id);
                        destroy(self.academicSessions.get(id));
                        self.markLoading("isDeleteSessionLoading", false);
                        return { data };
                    } catch (err) {
                        self.markLoading("isDeleteSessionLoading", false);
                        return validationErrorsHandler(err);
                    }
                });

                return {
                    getAcademicSessions,
                    createAcademicSession,
                    updateAcademicSession,
                    deleteAcademicSession,
                    onUpdate
                }
            })
    );
