import React from 'react';
import { observer, inject } from 'mobx-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import isEmpty from 'lodash.isempty'
import Card from '../common/card';
import { colors } from '../common/colors';


import icon from '../dashboard/assets/analysis.svg';
import '../dashboard/StudentPerformance.css';
import {Colors} from "../lib/colors";
import {dateFormatLong, formatDate} from "../lib/formatDate";


@inject('display', 'store')
@observer
class StudentPerformanceChart extends React.Component {
    
    state = {colorMap: {}}

    static getDerivedStateFromProps(nextProps, prevState){
        const {chartData} = nextProps
        const {colorMap: prevColorMap} = prevState || {}

        const subjectNames = chartData.map((subjectData) => subjectData.short_name )
        const prevSubjectNames = prevColorMap.keys
        
        if (prevSubjectNames){
            const difference = subjectNames.filter( ( el ) => !prevSubjectNames.includes( el ) );

            alert(difference.length)
            if (difference.length < 1  ) return null
        }
        
        
        const colorsArray = Object.values(Colors.names)
        const colorMap = {}
        chartData.forEach((subjectData, index) => {
            colorMap[subjectData.short_name] = colorsArray[index]
        })

        return { colorMap };
    }

    get transformedChartData() {
        const {chartData} = this.props
        let transformedChartData = []
        const regroup = {}
        
        chartData.forEach(({id, name, short_name, data}) => {
            data.forEach(({period, value}) => {
                if (isEmpty(regroup[period])) regroup[period] = {}
                regroup[period][short_name] = value
            })

        })
        
        Object.keys(regroup).forEach((period)=>{
            const formattedDate = formatDate(period, dateFormatLong)
            const c = {name: formattedDate}
            Object.keys(regroup[period]).forEach((subject_name) => {
                c[subject_name] = regroup[period][subject_name]
            })
            transformedChartData.push(c)
        })

        transformedChartData = transformedChartData.reverse()

        return transformedChartData
    }

    renderTooltip = (value, name, props) => {
        if (isEmpty(value.payload)) return "Loading..."
        const {colorMap} = this.state
        
        return (
            <Card className="text--primary text-small">
                {
                    value.payload.map((payload) => (<div key={payload.name}><span style={{color: colorMap[payload.name] }}>⬤</span> {`${payload.name } - ${ parseFloat(payload.value).toFixed(1) }%`}</div>))
                }
            </Card>
        )
    }
    
    
    
    render() {
        const {display, chartData} = this.props
        const {colorMap} = this.state
        
        
        const chartMargin = display.isNotDesktop ? { top: 0, left: 20, right: 0 } : { top: 15, left: 30, right: 30 };
        const fontSize = display.isNotDesktop ? 8 : 10;
        
        
        return (
            <div className='studentPerformanceTrend'>
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">
                        <img className="dashboard-icon" src={icon} alt="" />
                        <span className="text-small text--uppercase">Performance Trend</span>
                    </div>
                </div>
                <Card>
                    <ResponsiveContainer height={280}>
                        <LineChart data={this.transformedChartData} margin={chartMargin}>
                            <XAxis
                                xAxisId="trend-X"
                                interval="preserveStartEnd"
                                dataKey="name"
                                height={50}
                                stroke={colors.grey}
                                strokeWidth={0.25}
                                tick={{ fontSize, fill: colors.grey }}
                            />
                            <YAxis
                                yAxisId="trend-Y"
                                axisLine={false}
                                interval="preserveStartEnd"
                                dataKey="Last 4 Months"
                                width={15}
                                tick={{ fontSize, fill: colors.grey }}
                                domain={[0, 100]}
                            />
                            <CartesianGrid
                                vertical={false}
                                stroke={colors.grey}
                                strokeWidth={0.25}
                            />
                            <Tooltip cursor={false} content={this.renderTooltip} />
                            {
                                chartData.map((subjectData) => (
                                    <Line
                                        key={subjectData.id}
                                        xAxisId="trend-X"
                                        yAxisId="trend-Y"
                                        type="monotoneX"
                                        dataKey={subjectData.short_name}
                                        stroke={colorMap[subjectData.short_name]}
                                        dot={false}
                                        strokeWidth={1.5}
                                    />
                                ))
                            }
                        </LineChart>
                    </ResponsiveContainer>
                </Card>
            </div>
        );
    }
}

export default StudentPerformanceChart