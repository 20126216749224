import React from 'react'
import PropTypes from 'prop-types'

export default function TextInput({ className, onChange, placeholder, value, type, disabled, handleReturnKey }) {
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleReturnKey && handleReturnKey()
    }
  }
  return (
    <div className={`FormInputControl ${className}`}>
      <input
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className="FormInputControl-textInput"
        onChange={onChange}
        type={type}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  handleReturnKey: PropTypes.func
}

TextInput.defaultProps = {
  placeholder: "",
  className: "",
  type: "text",
  handleReturnKey: null,
  disabled: false,
}
