import {types, getParent, flow } from "mobx-state-tree"
import isEmpty from "lodash.isempty";
import {reaction} from "mobx";


import {setCurrentInstitutionTenantName} from "../../service/axios";
import { validationErrorsHandler } from "../helpers/errors";


import { Lga } from "./Lga"
import { Bank } from "./Bank"
import { User } from "./User"
import { Batch } from "./Batch"
import { FeeType } from "./FeeType"
import { Institution } from "./Institution"
import { AcademicSession } from "./AcademicSession"
import { StudentCategory } from "./StudentCategory"
import { EmployeeCategory } from "./EmployeeCategory"
import { EmployeeDepartment } from "./EmployeeDepartment"
import { CustomPaymentMethod } from "./CustomPaymentMethod"
import { FinanceTransactionCategory } from "./FinanceTransactionCategory"
import { EmployeePosition } from "./EmployeePosition";
import { SpecialRole } from "./SpecialRole";
import { BankAccount } from "./BankAccount";
import { AssessmentCategory } from './AssessmentCategory'

export const BluebicMeta = types
    .model("BluebicMeta", {
        id: "",
        type: "bluebic_meta",
        attributes: types.optional(types.model({
            current_academic_term: types.maybeNull(types.number),
            current_institution_id: types.maybe(types.reference(Institution)),
            student_categories: types.maybe(types.array(StudentCategory)),
            employee_categories: types.maybe(types.array(EmployeeCategory)),
            employee_departments: types.maybe(types.array(EmployeeDepartment)),
            employee_positions: types.maybe(types.array(EmployeePosition)),
            fee_types: types.maybe(types.array(FeeType)),
            custom_payment_methods: types.maybe(types.array(CustomPaymentMethod)),
            finance_transaction_categories: types.maybe(types.array(FinanceTransactionCategory)),
            lgas: types.maybe(types.array(Lga)),
            banks: types.maybe(types.array(Bank)),
        }), {}),
        relationships: types.maybe(types.model({
            current_academic_session: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(AcademicSession))
                }))
            }),
            current_user: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(User))
                }))
            }),
            active_batches: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Batch))
                }))
            }),
            special_roles: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(SpecialRole))
                }))
            }),
            batches_with_students: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Batch))
                }))
            }),
            accessible_batches: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Batch))
                }))
            }),
            accessible_subjects_batches: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Batch))
                }))
            }),
            institution_bank_accounts: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(BankAccount))
                }))
            }),
            assessment_categories: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(AssessmentCategory))
                }))
            }),
        })),
        isLoading: true,
        isUpdateInstitutionLoading: false,
    })
    .views(self => ({
        get bluebic() {
            return getParent(self)
        },
        get currentAcademicSession() {
            try {
                return self
                    .relationships
                    .current_academic_session
                    .data
                    .id
            } catch (e) {
                return undefined
            }
        },
        get currentUser() {
            try {
                return self
                    .relationships
                    .current_user
                    .data
                    .id
            } catch (e) {
                return undefined
            }
        },
        get currentInstitution() {
            return self.attributes.current_institution_id
        },
        get paystackSubAccountCode() {
            try {
                return self.currentInstitution.paystackSubAccountCode
            } catch (e) {
                console.error(e)
                return undefined
            }
        },
        get activeBatches() {
            try {
                return self
                    .relationships
                    .active_batches
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return undefined
            }
        },
        get specialRoles() {
            try {
                return self
                    .relationships
                    .special_roles
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return undefined
            }
        },
        get batchesWithStudents() {
            try {
                return self
                    .relationships
                    .batches_with_students
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return undefined
            }
        },
        get accessibleBatches() {
            try {
                return self
                    .relationships
                    .accessible_batches
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return undefined
            }
        },
        get accessibleSubjectsBatches() {
            try {
                return self
                    .relationships
                    .accessible_subjects_batches
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return undefined
            }
        },
        get institutionBankAccounts() {
            try {
                return self
                    .relationships
                    .institution_bank_accounts
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return undefined
            }
        },
        get assessmentCategories() {
            try {
                return self
                    .relationships
                    .assessment_categories
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                return undefined
            }
        },
    }))
    .actions(self => {

        function afterAttach() {
            if (!isEmpty(self.currentInstitution)){
                setCurrentInstitutionTenantName(self.currentInstitution.tenantName)
            }
            
            reaction(
                () => self.attributes.current_institution_id,
                newValue => {
                    const newHeader = newValue ? newValue.tenantName : undefined
                    setCurrentInstitutionTenantName(newHeader)
                }
            )
        }

        function markLoading(loading, key) {
            self[key || "isLoading"] = loading
        }

        function setCurrentInstitution(current_institution) {
            self.attributes.current_institution_id = current_institution.id
        }

        const getInstitution = flow(function* getInstitution(domain_name) {
            try {
                markLoading(true)
                const { data } = yield self.bluebic.authService.getInstitution(domain_name)
                self.bluebic.handleUpdateStores([data])
                setCurrentInstitution(data)
                markLoading(false)
            } catch (err) {
                console.error(`Failed to get institution using domain name '${domain_name}'`, err)
            }
        })

        const updateInstitution = flow(function* updateSchoolDetails(details) {
            try {
                markLoading(true, "isUpdateInstitutionLoading");
                const { data } = yield self.bluebic.authService.updateInstitution(details);
                self.bluebic.handleUpdateStores([data]);
                setCurrentInstitution(data);
                markLoading(false, "isUpdateInstitutionLoading");
                return data;
            } catch (err) {
                markLoading(false, "isUpdateInstitutionLoading");
                return validationErrorsHandler(err);
            }
        });

        return {
            getInstitution,
            afterAttach,
            updateInstitution,
        }
    })
