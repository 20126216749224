import {types, getParent, applySnapshot} from "mobx-state-tree"

export const AssessmentSearch = types
    .model("AssessmentSearch", {
        assessment_category_id: "",
        academic_term: "",
        included_in_final_grade: "",
        published: "",
        scores_published: "",
        include_total_ids: true,
        page: 1,
        per_page: 20
    })
    .actions(self => ({
        nextPage() {
            const { assessmentsMeta: meta, getAssessments } = getParent(self)
            const { total_pages } = meta
            if (self.page < total_pages) {
                self.page += 1
                getAssessments()
            }
        },
        prevPage() {
            const { getAssessments } = getParent(self)
            if (self.page > 1) {
                self.page -= 1
                getAssessments()
            }
        },
    }))

export const StudentAssessmentSearch = types
    .model("StudentAssessmentSearch", {
        academic_session_id: "",
        academic_term: types.maybe(types.number),
        subject_id: types.maybe(types.string),
        
        include_total_ids: true,
        include_statistics: true,
        page: 1,
        per_page: 20,

        client_state: types.optional(types.model({
            isLoading: types.optional(types.boolean, false)
        }), {})
    })
    .views(self => ({
        get isLoading() {
            return self.client_state.isLoading
        }
    }))
    .actions(self => ({
        markLoading(flag){
            self.client_state.isLoading = flag
        },
        reset(){
            applySnapshot(self, {})
        },
        nextPage() {
            const {assessmentsMeta: {total_pages}} = getParent(self)
            if (self.page < total_pages) self.page += 1
        },
        prevPage() {
            if (self.page > 1) self.page -= 1
        },
        goToFirstPage() {
            self.page = 1
        },
        setAcademicTerm(term) {
            self.academic_term = term
        },
        setAcademicSessionId(id) {
            self.academic_session_id = id
        },
        setAcademicSession({ id }) {
            self.academic_session_id = id
        }
    }))