/* eslint-disable camelcase */
import { types } from "mobx-state-tree"

export const SpecialRole = types
    .model("SpecialRole", {
        id: types.identifier,
        type: "special_role",
        attributes: types.maybe(types.model({
            role_type: types.string,
            assignment_level: types.string,
            female_name: types.maybeNull(types.string),
            male_name: types.maybeNull(types.string),
            name: types.string,
        }))
    })