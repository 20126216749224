import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import eventsJSON from '../json/events.json'
import eventJSON from '../json/event.json'
import updateEventJSON from '../json/update_event.json'
import createEventJSON from '../json/create_event.json'
import deleteEventJSON from '../json/delete_event_success.json'

export function EventService(env) {
    async function getEvents(action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("event_search", action)
            const { data } = await axios.get(`/api/v1/events?${queryString}`)
            return data
        }
        return eventsJSON
    }
    
    async function getEvent(id){
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/events/${id}`)
            return data
        }
        return eventJSON
    }

    async function createEvent(event) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/events`,{ event })
            return data
        }
        return createEventJSON
    }

    async function updateEvent(event) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/events/${event.id}`,
                { event }
            )
            return data
        }
        return updateEventJSON
    }

    async function deleteEvent(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/events/${id}`)
            return data
        }
        return deleteEventJSON
    }

    return {
        getEvents,
        getEvent,
        createEvent,
        updateEvent,
        deleteEvent
    }
}