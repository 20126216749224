/* eslint-disable global-require */
import './Avatar.css'

import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from "lodash.isempty";
import fallback from './assets/unknown.svg'

export const AVI_TYPES = Object.freeze({
  EMPLOYEE: 'EMPLOYEE',
  GUARDIAN: 'GUARDIAN',
  STUDENT: 'STUDENT'
})

const maleSex = ["male", 'm']
const femaleSex = ["female", 'f']

export default function Avatar({ className: className_, src, small, xsmall, borderColor, bordered, sex, type }) {
  const borderColorStyle = (borderColor && borderColor.length > 0) ?
    { background: borderColor } :
    {}

  const avatarClass = className('Avatar', className_, {
    'Avatar--xsmall': xsmall,
    'Avatar--small': small,
    'Avatar--isBordered': bordered
  })

  function getDefaultAvatar() {
    let aviSrc = fallback
    if (type === AVI_TYPES.STUDENT) {
      aviSrc = maleSex.includes(sex) ?
        require('./assets/student_boy.svg') :
        require('./assets/student_girl.svg')
    }
    if (type === AVI_TYPES.EMPLOYEE) {
      aviSrc = maleSex.includes(sex) ?
        require('./assets/employee_male.svg') :
        require('./assets/employee_female.svg')
    }
    if (type === AVI_TYPES.GUARDIAN) {
      aviSrc = maleSex.includes(sex) ?
        require('./assets/guardian_male.svg') :
        require('./assets/guardian_female.svg')
    }
    return aviSrc
  }

  return (
    <div className={avatarClass} style={borderColorStyle}>
      <img src={isEmpty(src) ? getDefaultAvatar() : src} alt={type} />
    </div>
  )
}
Avatar.propTypes = {
  src: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  bordered: PropTypes.bool,
  borderColor: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(AVI_TYPES)),
  sex: PropTypes.oneOf([...maleSex, ...femaleSex])
}
Avatar.defaultProps = {
  src: fallback,
  className: '',
  small: false,
  xsmall: false,
  bordered: false,
  borderColor: '',
  type: AVI_TYPES.STUDENT,
  sex: 'f'
}