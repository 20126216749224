import React from 'react';
import { inject, observer } from 'mobx-react';
import { controlled, converters, Field, Form, RepeatingForm } from 'mstform';
import isEmpty from 'lodash.isempty'
import SelectInput from '../common/form/SelectInput';
import { BehaviouralDomainGroup } from '../store/actions/BehaviouralDomainGroup';
import Table from '../common/table';
import Button from '../common/button';

import './BatchBehaviouralDomainGroup.css';


const columnsBehaviouralTable = [
    { key: 'indicator', render: 'Indicator' },
    { key: 'grade', render: 'Grade' }
];

const form = new Form(BehaviouralDomainGroup, {
	grades: new RepeatingForm({
		indicator_id: new Field(converters.maybe(converters.string), { required: true }),
		grade_scale_level_id: new Field((converters.string), {
			controlled: controlled.object
		})
	})
});

@inject('store', 'display')
@observer
class BatchBehaviouralDomainGroups extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            formState: undefined,
            formModel: undefined,
            // eslint-disable-next-line react/no-unused-state
            selectedTerm: undefined,
            // eslint-disable-next-line react/no-unused-state
            studentID: undefined,
            affectiveIndicators: undefined,
            psychomotorIndicators: undefined,
            baseFormErrors: undefined
        };
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
	    const {studentDetails, currentTerm: nextTerm, studentBehavDomainSores, batchDetails, store} = nextProps
	    const {studentID, selectedTerm: prevTerm} = prevState || {}

        if (studentDetails.id === studentID && nextTerm === prevTerm) return null;
        
        const {affectiveDomainGroup, psychomotorDomainGroup} = batchDetails;
        const {batchStore: { updateBehaviouralScoreSheet } } = store
        
        const indicators = []
        
        let affectiveIndicators
        let psychomotorIndicators
        
        if (affectiveDomainGroup){
            ({ attributes: { indicators: affectiveIndicators } } = affectiveDomainGroup)
            indicators.push(...affectiveIndicators)
        }
        
        if (psychomotorDomainGroup){
            ({ attributes: { indicators: psychomotorIndicators } } = psychomotorDomainGroup)
            indicators.push(...psychomotorIndicators)
        }


        const formInstance = BehaviouralDomainGroup.create({});
        formInstance.setFormInstance(studentBehavDomainSores || [], indicators);

        
        const formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors } = await updateBehaviouralScoreSheet(batchDetails.id, studentDetails.id, nextTerm, node.toJSON());
                if (errors) this.setState({baseFormErrors: errors.base})
            }
        })
        
        return { formState, formModel: formInstance, selectedTerm: nextTerm, studentID: studentDetails.id, psychomotorIndicators, affectiveIndicators };
    }
    

    handleSubmit = async () => {
        const {formState} = this.state
        await formState.save();
    };
	
	render() {
        const { batchDetails, studentDetails } = this.props;
	    const {formModel, affectiveIndicators, psychomotorIndicators, formState, baseFormErrors } = this.state
        
		if (isEmpty(formState)) return <div>Loading</div>;
        if (isEmpty(studentDetails)) return 'Select student...'
        

        const { studentName, attributes: { username } } = studentDetails
		const { affectiveDomainGroup, psychomotorDomainGroup, behaviouralScoresheet: { isLoading } } = batchDetails;

		const gradesForm = formState.repeatingForm('grades');
		
		function getDomainTableData(indicators, gradeScale){
            const gradeScaleLevels = gradeScale.attributes.levels
            const tableData = indicators.map(({ id, name }) => {
                const index = formModel.grades.findIndex((grade)=> grade.indicator_id === id.toString() )
                const currentGradeSubForm = gradesForm.index(index);
                const grade_scale_level_id = currentGradeSubForm.field('grade_scale_level_id');

                const options = gradeScaleLevels.map(({ id: value, name: text }) => {
                    value = value.toString();
                    return { text, value }
                });

                return {
                    indicator: <div>{name}</div>,
                    grade: (
                        <SelectInput
                            {...grade_scale_level_id.inputProps}
                            defaultOptionText="Select grade"
                            placeholder="Select grade"
                            options={options}
                        />
                    )
                }
            });
            
            return tableData
        }

		
		let tableDataAffective
        let tableDataPsychomotor
		
		if (affectiveIndicators){
            tableDataAffective = getDomainTableData(affectiveIndicators, affectiveDomainGroup.gradeScale)
        }
		
		if (psychomotorIndicators){
            tableDataPsychomotor = getDomainTableData(psychomotorIndicators, psychomotorDomainGroup.gradeScale)
        }

		return (
			<div style={{ position: ' relative', backgroundColor: 'white' }}>
                
                <div className="pb-1"><h2>{`${studentName} - ${username}`}</h2></div>


                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                
				{tableDataAffective && (
                    <Table
                        striped
                        header={(<h3>Affective Domain</h3>)}
                        containerClass="Card pb-1"
                        columns={columnsBehaviouralTable}
                        data={tableDataAffective}
                        onError={(e) => console.error(e)}
                        loading={isLoading}
                        onColumnKeysChange={(e) => console.log(e)}
                    />
				)}
				
				{tableDataPsychomotor && (
				    <div className="pt-1">
                        <Table
                            striped
                            header={(<h3>Psychomotor Domain</h3>)}
                            containerClass="Card"
                            columns={columnsBehaviouralTable}
                            data={tableDataPsychomotor}
                            onError={(e) => console.error(e)}
                            loading={isLoading}
                            onColumnKeysChange={(e) => console.log(e)}
                        />
				    </div>
				)}
				
				<div className="behaviouralGroup--submit">
					<Button fullwidth onClick={this.handleSubmit}>
						Save
					</Button>
				</div>
			</div>
		);
	}
}

export default BatchBehaviouralDomainGroups;