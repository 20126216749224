import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import { observer } from 'mobx-react'
// import withFormInput from './withFormInput'
// import error_ from './assets/Error.svg'
// import success_ from './assets/Success.svg'

export default function MultilineInput({ className, onChange, value, placeholder, rows }) {
  const formInputControlClass = classNames(
    "FormInputControl FormInputControl--multiline",
    "flex-row flex-spaceBetween flex-alignCenter",
    className
  )
  return (
    <div className={formInputControlClass}>
      <textarea
        placeholder={placeholder}
        className="FormInputControl-textInput"
        onChange={onChange}
        value={value}
        rows={rows}
      />
      {/* {error && <img src={error_} alt="error" />}
      {success && <img src={success_} alt="success" />} */}
    </div>
  )
}
MultilineInput.propTypes = {
  placeholder: PropTypes.string,
  // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // success: PropTypes.bool,
  value: PropTypes.string,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // onBlur: PropTypes.func.isRequired,
  // onFocus: PropTypes.func.isRequired,
  className: PropTypes.string,
  rows: PropTypes.string
}
MultilineInput.defaultProps = {
  placeholder: "",
  className: "",
  rows: "5",
  value: ""
}