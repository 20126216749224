import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ModalContext } from '../common/modal'
import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import ReactHelmet from "../containers/ReactHelmet";
import StudentSummary from './StudentSummary'
import StudentBioData from './StudentBioData';
import StudentNotes from './StudentNotes';
import StudentBatchHistory from './StudentBatchHistory'
import StudentReportCards from './StudentReportCards'
import StudentGuardianRelations from './StudentGuardianRelations'
import StudentAttendanceReport from './StudentAttendanceReport'
import StudentFinance from './StudentFinance';
import DisableStudentLogin from './DisableStudentLogin';
import ArchiveStudent from './ArchiveStudent';
import DeleteStudent from './DeleteStudent';
import UpdatePassword from '../common/update_password';
import ProfileLoading from '../common/loading/ProfileLoading';
import UserPolicy from "../policies/UserPolicy";
import StudentPolicy from "../policies/StudentPolicy";

import studentIcon from './assets/graduate-student-avatar.svg'
import chat from '../common/assets/chat.svg'
import password from './assets/password.svg'
import block from '../common/assets/block.svg'
import archive from './assets/archive.svg'
import trash from './assets/trash.svg'
import edit from '../common/assets/edit.svg'
import StudentAssessmentList from "./StudentAssessmentList";
import EnableStudentLogin from "./EnableStudentLogin";
import {handleSendMessage} from "../messages/SendMessageForm";
import MessagePolicy from "../policies/MessagePolicy";
import ReEnrollStudent from "./ReEnrollStudent";

const Guardians = inject("store")(
    observer(({ store }) => {
        const { view, studentStore } = store
        const { selectedStudent: student } = view
        const { assignGuardian, unassignGuardian } = studentStore

        return (
            <div className="mb-3">
                <StudentGuardianRelations
                    student={student}
                    handleAssignGuardian={assignGuardian}
                    handleUnassignGuardian={(idx) => unassignGuardian(idx, student.id)}
                />
            </div>
        )
    })
)

@inject("store")
@observer
class SelectedStudent extends Component {
    tabs = []

    componentDidMount() {
        const {
            store: {
                studentStore,
                view: {
                    selectedStudentId
                }
            }
        } = this.props
        studentStore.loadStudentById(selectedStudentId)
    }

    options = (context) => {
        const {store: bluebicStore} = this.props
        const {meta: {currentUser}, view: {selectedStudent, openEditStudentPage}} = bluebicStore


        
        const policy = new StudentPolicy(currentUser, selectedStudent)
        const userPolicy = new UserPolicy(currentUser, selectedStudent.user)
        const messagePolicy = new MessagePolicy(currentUser, selectedStudent)
        
        if (currentUser.is_employee_or_admin){
            const op = [
                {
                    text: 'Edit Student',
                    disabled: !policy.can_update,
                    title: policy.can_update ? undefined : "No permission.",
                    onClick: () => openEditStudentPage(selectedStudent),
                    icon: edit
                },
                {
                    text: 'Send Message',
                    disabled: false,
                    title: messagePolicy.can_create ? undefined : "No permission.",
                    onClick: () => {
                        if (!messagePolicy.can_create){
                            bluebicStore.alert({ message: { warning: "You do not have permission to send messages." } })
                            return
                        }
                        handleSendMessage(context, { studentIDs: [selectedStudent.id]})
                    },
                    icon: chat
                },
                {
                    icon: password,
                    text: 'Change Password',
                    disabled: !userPolicy.can_update_password,
                    title: userPolicy.can_update_password ? undefined : "No permission.",
                    onClick: () => context.setContent(
                        'Change Password',
                        <UpdatePassword onCancel={context.closeModal} id={selectedStudent.user.id} />,
                        true
                    ),
                },
                (
                    selectedStudent.isBlocked ?
                        {
                            icon: block,
                            disabled: !policy.can_block,
                            title: policy.can_block ? undefined : "No permission.",
                            text: 'Enable Login',
                            onClick: () => context.setContent(
                                'Unblock Student Portal Access',
                                <EnableStudentLogin studentIds={[selectedStudent.id]} onClose={context.closeModal} />,
                                true
                            ),
                        } :
                        {
                            icon: block,
                            disabled: !policy.can_block,
                            title: policy.can_block ? undefined : "No permission.",
                            text: 'Disable Login',
                            onClick: () => context.setContent(
                                'Block Student Portal Access',
                                <DisableStudentLogin studentIds={[selectedStudent.id]} onClose={context.closeModal} />,
                                true
                            ),
                        }
                )
            ]
            
            if (selectedStudent.isEnrolled){
                op.push({
                    icon: archive,
                    disabled: !policy.can_drop,
                    title: policy.can_drop ? undefined : "No permission.",
                    text: 'Archive Student',
                    onClick: () => context.setContent(
                        'Drop Student',
                        <ArchiveStudent studentId={selectedStudent.id} onClose={context.closeModal} />,
                        true
                    ),
                })
            }
            
            if (!selectedStudent.isEnrolled){
                op.push({
                    icon: archive,
                    disabled: !policy.can_restore_student,
                    title: policy.can_restore_student ? undefined : "No permission.",
                    text: 'Re-enroll Student',
                    onClick: () => context.setContent(
                        'Re-enroll Student',
                        <ReEnrollStudent studentId={selectedStudent.id} onClose={context.closeModal} />,
                        true
                    ),
                })
            }

            op.push({
                icon: trash,
                disabled: !policy.can_destroy,
                title: policy.can_destroy ? undefined : "No permission.",
                text: 'Delete Student',
                onClick: () => context.setContent(
                    'Deleting Student',
                    <DeleteStudent studentId={selectedStudent.id} onClose={context.closeModal} />,
                    true
                ),
            })
            
            return op
        }
        
        if (currentUser.is_student){
            return [
                {
                    icon: password,
                    text: 'Change Password',
                    disabled: !userPolicy.can_update_password,
                    title: userPolicy.can_update_password ? undefined : "No permission.",
                    onClick: () => context.setContent(
                        'Change Password',
                        <UpdatePassword onCancel={context.closeModal} id={selectedStudent.user.id} />,
                        true
                    ),
                },
            ]
        }
        
        return []
    }

    render() {
        const { store } = this.props
        const {view: {selectedStudent}} = store

        if (!selectedStudent || !selectedStudent.isInitialized) return <ProfileLoading />
        
        const activeTab = 1
        const tabs = []
        tabs.push(new Tab("Bio", <StudentBioData {...selectedStudent.bioData} />, activeTab === 1))
        tabs.push(new Tab("Fees", <StudentFinance studentData={selectedStudent} />, activeTab === 2))
        tabs.push(new Tab("Guardians", <Guardians />, activeTab === 3))
        tabs.push(new Tab("Assessments", <StudentAssessmentList />, activeTab === 4))
        tabs.push(new Tab("Attendance", <StudentAttendanceReport />, activeTab === 5))
        tabs.push(new Tab("Report Cards", <StudentReportCards />, activeTab === 6))
        tabs.push(new Tab("Notes", <StudentNotes notes={selectedStudent.notes} />, activeTab === 7))
        tabs.push(new Tab("Batch History", <StudentBatchHistory />, activeTab === 8))
        
        
        return (
            <>
                <ReactHelmet title={`Student Profile | ${selectedStudent.studentName}`} />
                
                <ModalContext.Consumer>
                    {context => (
                        <Tabs
                            title={{ text: 'Student Profile', icon: studentIcon }}
                            tabs={tabs}
                            canPrint={false}
                            content={<StudentSummary student={selectedStudent} />}
                            options={selectedStudent.isLoading ? [] : this.options(context)}
                        />
                    )}
                </ModalContext.Consumer>
            </>
            
        )
    }
}

export default SelectedStudent
