import React from 'react'
import { inject, observer } from 'mobx-react'
import TrendChart from './TrendChart';
import Card from '../common/card';
import ShortLoadingText from '../common/loading/ShortLoadingText'
import icon from './assets/analytics.svg';
import './GeneralStatistics.css';


const GeneralStatistics = inject("store")(observer(({ store, peopleStat }) => {
    const { view : { openStudentsPage, openEmployeesPage, openGuardiansPage } } = store

    const people = ['student', 'employee', 'guardian']
    const handleClick = (page) => {
        if( page === "student") {
            openStudentsPage()
        } else if(page === "employee") {
            openEmployeesPage()
        } else {
            openGuardiansPage()
        }
    }
    const personStat = (name, amount, trend) => (
        <Card key={name} className="flex-row flex-spaceBetween">
            <div>
                <div className="text-small text--grey">{name.toUpperCase()}</div>
                <div className="text-h3 text--bold">{amount}</div>
                <div className="text-small text--uppercase text--primary touchable-opacity text--bold" onClick={() => handleClick(name)}>view reports</div>
            </div>
            <TrendChart trend={trend} />
        </Card>
    )

    return (
        <div className='dashboard-generalStatistics generalStatistics'>
            <div className="flex-row">
                <img className="dashboard-icon" src={icon} alt="" />
                <span className="text-small text--uppercase">General Statistics</span>
            </div>
            {peopleStat && peopleStat.map(x => personStat(x.name, x.amount, x.trend))}
            {!peopleStat && people.map(name => personStat(name, ShortLoadingText(), 0))}
        </div>
    );
}))

export default GeneralStatistics
