import { types } from "mobx-state-tree"

export const AcademicSession = types
    .model("AcademicSession", {
        id: types.identifier,
        type: types.literal("academic_session"),
        attributes: types.maybe(types.model({
            name: "",
            first_term_begins: "",
            first_term_ends: "",
            second_term_begins: "",
            second_term_ends: "",
            third_term_begins: "",
            third_term_ends: "",
        })),
    })