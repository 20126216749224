import React from 'react'
import * as PropTypes from 'prop-types'
// import { observer } from 'mobx-react';
import classNames from 'classnames'
import {dateFormatMonthYearLong, formatDate} from "../../lib/formatDate";

export default function CalendarHeader({ navigationDisabled, currentMonth, prevMonth, nextMonth }) {
  const calendarHeaderClass = classNames(
    'Calendar-header',
    { 'Calendar-header-isNavigationEnabled': !navigationDisabled }
  )

  return (
    <div className={calendarHeaderClass}>
      {!navigationDisabled && (
        <button type="button" onClick={prevMonth}>
          <i className="material-icons touchable-opacity">chevron_left</i>
        </button>
      )}
      <div>{formatDate(currentMonth, dateFormatMonthYearLong)}</div>
      {navigationDisabled || (
        <button type="button" onClick={nextMonth}>
          <i className="material-icons touchable-opacity">chevron_right</i>
        </button>
      )}
    </div>
  )
}
CalendarHeader.propTypes = {
  currentMonth: PropTypes.instanceOf(Date),
  navigationDisabled: PropTypes.bool,
  prevMonth: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired
}
CalendarHeader.defaultProps = {
  currentMonth: new Date(),
  navigationDisabled: false,
}
