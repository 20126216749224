import { titleCase } from "./titleCase";

const mapStringListToOptionsConfig = {
    transformCase: true
}
export const mapStringListToOptions = (options, config = mapStringListToOptionsConfig) => {
    const { transformCase } = config
    return options
        .map((option) => ({
            text: transformCase ? titleCase(option) : option,
            value: option
        }))
}