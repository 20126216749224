import './CalendarCell.css'

import React from 'react'
// import PropTypes from 'prop-types'
import {startOfMonth, isSameMonth} from 'date-fns'
import uuidv1 from 'uuid/v1'
import { Tooltip } from 'antd'
import {dateFormatOnlyDay, formatDate} from "../../lib/formatDate";

function CalendarCell({ currentMonth, day, calendarData }) {
    const monthStart = startOfMonth(currentMonth)
    const formattedDate = formatDate(day)
    const { type, memo } = calendarData.get(formattedDate) || { type: null, memo: null }
    const id = uuidv1()

    if (!isSameMonth(day, monthStart)) {
      return <div key={day}>&nbsp;</div>
    }

    if (memo) {
      return (
        <Tooltip
          title={renderMemo(memo, type)}
          placement="top"
          arrowPointAtCenter
          getPopupContainer={() => document.getElementById(id)}
        >
          <div style={{ position: 'relative' }}>
            <div
              key={day}
              className={`CalendarCell CalendarCell--${type}`}
              // onClick={ () => onDateClick(dateFns.parse(cloneDay)) }
            >
              <div id={id}>{formatDate(day, dateFormatOnlyDay)}</div>
            </div>
            <div className={`CalendarMemo CalendarMemo--${type}`}>
              <i className="material-icons">info</i>
            </div>
          </div>
        </Tooltip>
      )
    }

    return (
      <div className={`CalendarCell CalendarCell--${type}`}>
        <div id={id}>{formatDate(day, dateFormatOnlyDay)}</div>
      </div>
    )
}

export default CalendarCell

function renderMemo(memo, type) {
  const title = (type === 'holiday') ? 'Holiday' : 'Reason'
  return (
    <React.Fragment>
      <div
        className="
          text-small
          text--bold
          text--uppercase
          text--spacedLetters
        "
        style={{paddingBottom: 10}}
      >
        {title}
      </div>
      <div className="text-p" style={{ fontWeight: 'normal' }}>{ memo }</div>
    </React.Fragment>
  )
}