import { types, flow, destroy } from 'mobx-state-tree';
import FileSaver from 'file-saver';

import { filterType } from "./helpers/filterType";
import { ReportCardGroup } from './models/ReportCardGroup';
import { StudentReportCardDatum } from './models/StudentReportCardDatum';
import { ReportCardGroupSearch } from './actions/ReportCardGroupSearch';
import { CollectionPattern } from './patterns/collectionPattern';
import { validationErrorsHandler } from './helpers/errors';
import { StudentReportCardComment } from './models/StudentReportCardComment';
import { BehaviouralDomainGroup } from './models/BehaviouralDomainGroup';
import { GradeScale } from "./models/GradeScale";
import { ReportCardTemplate } from './models/ReportCardTemplate';
import { ReportCardTemplateCognitiveColumn } from './models/ReportCardTemplateCognitiveColumn';
import { ReportCardGroup as ReportCardGroupAction } from './actions/ReportCardGroup';

export const ReportCardGroupStore = types
    .compose(
        "ReportCardGroupStore",
        CollectionPattern({
            collectionType: ReportCardGroup,
            searchType: ReportCardGroupSearch
        }),
        types
            .model({
                isLoading: false,
                studentReportCardData: types.map(StudentReportCardDatum),
                studentReportCardComments: types.map(StudentReportCardComment),
                behaviouralDomainGroups: types.map(BehaviouralDomainGroup),
                gradeScale: types.map(GradeScale),
                reportCardTemplates: types.map(ReportCardTemplate),
                reportCardTemplateCognitiveColumns: types.map(ReportCardTemplateCognitiveColumn),
                addReportCardGroupFormInstance: types.optional(ReportCardGroupAction, {}),
            })
            .views(self => ({
                get reportCardGroups() {
                    return self.items
                },
                get searchService() {
                    return self.service.search
                },
                get service() {
                    return self.bluebic.reportCardGroupService
                },
            }))
            .actions(self => {
                function updateReportCardGroups(groups) {
                    groups.forEach(json => self.items.put(json))
                }
                
                function updateStudentReportCardData(data) {
                    data.forEach(json => self.studentReportCardData.put(json))
                }
                
                function updateStudentReportCardComments(data) {
                    data.forEach(json => self.studentReportCardComments.put(json))
                }
                
                function updateBehaviouralDomainGroups(data) {
                    data.forEach(json => self.behaviouralDomainGroups.put(json))
                }
                
                function updateGradeScale(data) {
                    data.forEach(json => self.gradeScale.put(json))
                }

                function updateReportCardTemplateCognitiveColumns(data) {
                    data.forEach(json => self.reportCardTemplateCognitiveColumns.put(json))
                }
                
                function updateReportCardTemplates(data) {
                    data.forEach(json => self.reportCardTemplates.put(json))
                }

                function onUpdate(included) {
                    filterType("student_report_card_datum", included, updateStudentReportCardData)
                    filterType("student_report_card_comment", included, updateStudentReportCardComments)
                    filterType("report_card_group", included, updateReportCardGroups)
                    filterType("behavioural_domain_group", included, updateBehaviouralDomainGroups)
                    filterType("grade_scale", included, updateGradeScale)
                    filterType("report_card_template", included, updateReportCardTemplates)
                    filterType("report_card_template_cognitive_column", included, updateReportCardTemplateCognitiveColumns)
                }

                function markReportCardGroupLoadingById(id, flag) {
                    if (!self.items.has(id)) self.items.put({ id })
                    self.items.get(id).isLoading = flag
                }

                const getReportCardTemplates = flow(function* getReportCardTemplates() {
                    try {
                        self.isLoading = true
                        const { data, included } = yield self.bluebic.reportCardTemplateService.index()
                        self.bluebic.handleUpdateStores(included)
                        onUpdate(data)
                        self.isLoading = false
                    } catch (err) {
                        console.error(`Failed to get report card templates`, err)
                        self.isLoading = false
                    }
                })
                
                function markReportCardTemplateLoadingById(id, flag) {
                    if (!self.reportCardTemplates.has(id)) {
                        self.reportCardTemplates.put({ id })
                    }
                    self.reportCardTemplates.get(id).isLoading = flag
                }
                

                const getReportCardTemplateByID = flow(function* getReportCardTemplateByID(id) {
                    try {
                        markReportCardTemplateLoadingById(id, true)
                        const { data, included } = yield self.bluebic.reportCardTemplateService.show(id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markReportCardTemplateLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to get report card template`, err)
                        markReportCardTemplateLoadingById(id, false)
                    }
                })

                const loadReportCardGroupData = flow(function* loadReportCardGroupData(id, action = {}) {
                    try {
                        markReportCardGroupLoadingById(id, true)
                        const {client_state, ...action_without_client} = action
                        const { data, included } = yield self.service.showReportCardGroup(id, action_without_client)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markReportCardGroupLoadingById(id, false)
                    } catch (err) {
                        console.error(`Failed to load ReportCardGroup with id ${id}`, err)
                        // TODO: next line should only be called when the API can't be reached. Otherwise the API
                        //  handles alerts
                        // self.bluebic.alert({ error: 'Report Card failed to load due to network issues.' })
                        markReportCardGroupLoadingById(id, false)
                    }
                })
                
                const createReportCardGroup = flow(function* createReportCardGroup(action) {
                    try {
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { data } = yield self.service.createReportCardGroup(action_without_client)
                        onUpdate([data])
                        self.searchResults.unshift(data.id)
                        action.markSaving(false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to create Report Card Group", err)
                        action.markSaving(false)
                        return validationErrorsHandler(err)
                    }
                })
                
                const updateReportCardGroup = flow(function* updateReportCardGroup(group_id, action) {
                    try {
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { data } = yield self.service.updateReportCardGroup(group_id, action_without_client)
                        onUpdate([data])
                        action.markSaving(false)
                        return { data }
                    } catch (err) {
                        action.markSaving(false)
                        console.error("Failed to update Report Card Group", err)
                        return validationErrorsHandler(err)
                    }
                });
                
                const updateReportCardGroupPublished = flow(function* updateReportCardGroupPublished(group_id, published) {
                    try {
                        const { data } = yield self.service.updateReportCardGroup(group_id, {published})
                        onUpdate([data])
                        return { data }
                    } catch (err) {
                        console.error("Failed to update Report Card Group", err)
                        return validationErrorsHandler(err)
                    }
                });
                
                const deleteReportCardGroup = flow(function* deleteReportCardGroup(group_id) {
                    try {
                        markReportCardGroupLoadingById(group_id, true)
                        yield self.service.deleteReportCardGroup(group_id)
                        // search result list is automatically updated by safeReference
                        self.searchMeta.total_count -= 1
                        destroy(self.items.get(group_id))
                        return {}
                    } catch (err) {
                        markReportCardGroupLoadingById(group_id, false)
                        console.error("Failed to delete Report Card Group", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const regenerateReportCardGroup = flow(function* regenerateReportCardGroup(group_id) {
                    try {
                        markReportCardGroupLoadingById(group_id, true)
                        const { data } = yield self.service.regenerateReportCardGroup(group_id)
                        self.putItem(data)
                        markReportCardGroupLoadingById(group_id, false)
                        return {data}
                    } catch (err) {
                        markReportCardGroupLoadingById(group_id, false)
                        console.error("Failed to regenerate Report Card Group", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const addComment = flow(function* addComment(report_card_group_id, action){
                    try{
                        action.markSaving(true)
                        const  reportCardGroup = self.items.get(report_card_group_id)
                        const {client_state, ...action_without_client} = action
                        const { data } = yield self.service.addComment(report_card_group_id, action_without_client)
                        self.bluebic.handleUpdateStores([data])
                        reportCardGroup.addComment(data)
                        action.markSaving(false)
                        return { data }
                    } catch (err) {
                        action.markSaving(false)
                        console.error(`Failed to Add Comment to ReportCardGroup with ${report_card_group_id}`, err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const downloadPDFReport = flow(function* downloadPDFReport(report_card_group_id, action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadPDFReport(report_card_group_id, action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                    } catch (err) {
                        action.markSaving(false)
                        console.error(`Failed to Initiate PDF download for ReportCardGroup with ${report_card_group_id}`, err)
                    }
                })

                const downloadBroadsheet = flow(function* downloadBroadsheet(report_card_group_id, action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadBroadsheet(report_card_group_id, action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                    } catch (err) {
                        action.markSaving(false)
                        console.error(`Failed to initiate Broadsheet download for ReportCardGroup with ${report_card_group_id}`, err)
                    }
                })
                

                return {
                    getReportCardTemplates,
                    getReportCardTemplateByID,
                    loadReportCardGroupData,
                    createReportCardGroup,
                    updateReportCardGroup,
                    updateReportCardGroupPublished,
                    deleteReportCardGroup,
                    regenerateReportCardGroup,
                    addComment,
                    downloadPDFReport,
                    onUpdate,
                    downloadBroadsheet
                }
            })
    )

