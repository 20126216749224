import React, {Component} from 'react'
import * as PropTypes from "prop-types";
import {inject} from "mobx-react";
import { ModalContext } from '../common/modal'
import CreateBatchForm from './CreateBatchForm'
import BatchAssignRole from './BatchAssignRole'
import BatchPolicy from "../policies/BatchPolicy";
import {modelOf} from "../lib/modelOf";
import {Batch} from "../store/models/Batch";

@inject('store')
class MoreButtonPopover extends Component {
    static propTypes = {
        batchInfo: modelOf(Batch).isRequired,
        deleteBatch: PropTypes.func.isRequired
    }
    
    render() {
        const {batchInfo, deleteBatch, store: { meta: {currentUser} }} = this.props;
        const policy = new BatchPolicy(currentUser, batchInfo)
        
        return (
            <ModalContext.Consumer>
                {context => (
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                context.setContent(
                                    `Assign Employee`,
                                    <BatchAssignRole batchInfo={batchInfo} editMode />
                                )
                            }}
                            disabled={!policy.can_update_role}
                            title={policy.can_update_role ? undefined : "No permission."}
                            className="Button Button--clear touchable-opacity text-p"
                        >
                            Assign Employee
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            onClick={() => {
                                context.setContent(
                                    `Edit Arm (${batchInfo.name})`,
                                    <CreateBatchForm closeModal={context.closeModal} batchInfo={batchInfo} editMode />
                                )
                            }}
                            disabled={!policy.can_update}
                            title={policy.can_update ? undefined : "No permission."}
                            className="Button Button--clear touchable-opacity text-p"
                        >
                            Edit Arm
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            onClick={() => deleteBatch(batchInfo.id)}
                            disabled={!policy.can_destroy}
                            title={policy.can_destroy ? undefined : "No permission."}
                            className="Button Button--clear touchable-opacity text-p "
                        >
                            Delete Arm
                        </button>
                    </div>
                )}
            </ModalContext.Consumer>
        )
    }
}

export default MoreButtonPopover