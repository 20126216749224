import React from 'react'
import { inject, observer } from 'mobx-react'
import {isSameDay, isSameMonth, isFuture, addDays, startOfDay, endOfMonth, startOfMonth, isWeekend } from 'date-fns'
import isEmpty from 'lodash.isempty'


import {
    dateFormatMonthYearLong,
    formatDate,
    apiDateFormat,
    dateFormatLongLeadingZero,
    dateFormatOnlyDayLeadingZero, localDayOfWeekNameShort
} from "../lib/formatDate";


import Card from '../common/card'
import AttendanceReportLoading from '../common/loading/AttendanceReportLoading'
import './BatchAttendanceRegister.css'
import { ModalContext } from '../common/modal'
import BatchAttendanceModal from './BatchAttendanceModal'

import check from './assets/check.svg'
import holiday from './assets/holiday.svg'
import halfDay from './assets/half-day.svg'
import absent from './assets/absent.svg'
import {AttendanceSpreadsheet} from "../store/actions/Attendance";
import spreadsheet from "../common/assets/spreadsheet.svg";
import Loading from "../common/loading/Loading";


@inject('store', 'display')
@observer
class BatchAttendanceRegister extends React.Component {
    constructor(props){
        super(props)

        const downloadSpreadsheetAction = AttendanceSpreadsheet.create({})

        this.state = { downloadSpreadsheetAction }
    }

    async componentDidMount(){
        const { store: {batchStore: {loadAttendanceRegisterById} }, batchDetails} = this.props;
        await loadAttendanceRegisterById(batchDetails.id)
        this.setStateFromBatchDetails()
    }

    setStateFromBatchDetails(){
        const { batchDetails} = this.props;
        const {attendanceRegister: {attributes:{  in_session_dates } }} = batchDetails
        const months = []
        in_session_dates.forEach(date => {
            const monthSeen = Boolean(months.find(month => isSameMonth(month, new Date(date))))
            if (!monthSeen) months.push(new Date(date))
        })
        // Calculate month to show
        const currentInSessionMonth = months.find(month => isSameMonth(new Date(), month) )
        const currentMonth =  currentInSessionMonth || months.slice().pop()

        this.setState({ currentMonth })
        this.setState({ months })
    }
    
    currentMonthIndex = () => {
        const { currentMonth, months } = this.state;
        return months.findIndex(month => isSameMonth(currentMonth, month))
    }

    adjustCurrentMonth = (by) => {
        const { months } = this.state;
        const currentMonthIndex = this.currentMonthIndex()
        const newIndex = currentMonthIndex + by;
        // if currentMonthIndex is first or last month
        if (newIndex < 0 || newIndex >= (months.length)) return
        this.setState({ currentMonth: months[newIndex] })
    }

    nextMonth = () => {
        this.adjustCurrentMonth(1)
    }

    prevMonth = () => {
        this.adjustCurrentMonth(-1)
    }

    isSessionDate = (date) => {
        const { batchDetails: {attendanceRegister: {attributes: {in_session_dates}}} } = this.props
        return Boolean(in_session_dates.find(nDate =>
            isSameDay(new Date(nDate), date)
        ))
    }

    onClickCell = (context, student, dayClicked, attendanceRecord) => {
        const { setContent, closeModal } = context

        const modalTitle = `Attendance for ${formatDate(dayClicked, dateFormatLongLeadingZero)}`

        setContent(
            modalTitle,
            <BatchAttendanceModal
                onClose={closeModal}
                stuId={student.id}
                dayClicked={dayClicked}
                attendanceRecord={attendanceRecord}
            />,
            true,
        )
    }

    initiateSpreadsheetDownload = () => {
        const { batchDetails: {id: batchID}, store: {attendanceStore: {downloadSpreadsheet}}  } = this.props
        const { downloadSpreadsheetAction } = this.state
        downloadSpreadsheetAction.setToGetBatch(batchID)
        downloadSpreadsheet(batchID, downloadSpreadsheetAction)
    }

    render() {
        const { batchDetails: {attendanceRegister}, display: {isMobile} } = this.props;
        const { downloadSpreadsheetAction } = this.state
        if (isEmpty(attendanceRegister) || isEmpty(attendanceRegister.attributes)) return <AttendanceReportLoading />
        
        const { attributes/* , isLoading */ } = attendanceRegister
        
        if (isEmpty(this.state)) return <AttendanceReportLoading />
        
        const { holiday_event_dates_and_titles } = attributes
        const { students, attendances} = attendanceRegister
        
        
        const attendanceRegisterStudents = students.sort((a, b) =>
            a.studentName.localeCompare(b.studentName, 'en', {sensitivity: 'base', ignorePunctuation: true})
        )
        const { currentMonth } = this.state
        

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);

        const groupedAttendances = {}
        attendances.forEach(attendance => {
            const {attributes: {date, student_id}} = attendance
            if (!groupedAttendances.hasOwnProperty( date)) groupedAttendances[date] = {}
            groupedAttendances[date][student_id] = attendance
        })

        const datesToShow = []
        let nday = monthStart
        while (nday <= monthEnd) {
            const isSchoolDay =  !isWeekend(nday) && this.isSessionDate(nday)
            const showDate = isSameMonth(nday, currentMonth) && isSchoolDay

            if (showDate) datesToShow.push(nday)
            nday = addDays(nday, 1);
        }


        const columns = datesToShow.map(day => {
            const dayIsFuture = isFuture(startOfDay(day))

            let isHoliday
            holiday_event_dates_and_titles.forEach(
                (title, date) => {
                    if (formatDate(day, apiDateFormat) === date){
                        isHoliday = title || 'Holiday'
                    }
                }
            )

            return (
                <ModalContext.Consumer key={day}>
                    {context => (
                        <div className="BatchAttendanceRegister-col">
                            <div className="BatchAttendanceRegister-cell flex-col">
                                <div>{formatDate(day, localDayOfWeekNameShort)}</div>
                                <div className="text--grey">
                                    {formatDate(day, dateFormatOnlyDayLeadingZero)}
                                </div>
                            </div>

                            {attendanceRegisterStudents.map((student) => {
                                const dayToString = formatDate(day, apiDateFormat)
                                const studentIdToNumber = parseInt(student.id, 10)
                                const nStudentAttRecord = groupedAttendances[dayToString] && groupedAttendances[dayToString][studentIdToNumber]

                                let title
                                let image

                                if (isHoliday){
                                    title = isHoliday
                                    image = holiday
                                }else if(nStudentAttRecord){
                                    title = nStudentAttRecord.attributes.reason
                                    image = nStudentAttRecord.attributes.half_day ? halfDay : absent
                                }else if(!dayIsFuture){
                                    image = check
                                }

                                return (
                                    <div
                                        key={student.id}
                                        onClick={() => {
                                            if (!isHoliday && !dayIsFuture){
                                                this.onClickCell(context, student, day, nStudentAttRecord)
                                            }
                                        }}
                                        className="BatchAttendanceRegister-cell touchable-highlight"
                                        title={title}
                                    >
                                        <img src={image} alt="" />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </ModalContext.Consumer>
            )
        })
        
        return (
            <Card style={{ marginBottom: 10 }}>
                <div className="BatchAttendanceRegister">
                    <div className="card BatchAttendanceRegister-main">
                        <div className="BatchAttendanceRegister-title text-small text--spacedLetters">
                            <span className="text--uppercase text-small text--spacedLetters">Batch Attendance Register</span>

                            <div onClick={() => this.initiateSpreadsheetDownload()} className="touchable-opacity">
                                {downloadSpreadsheetAction.isSaving || <img src={spreadsheet} alt="" className="utils-prepend" />}
                                <span className="text-p">{downloadSpreadsheetAction.isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Print '}</span>
                            </div>
                        </div>
                        <div className="BatchAttendanceRegister-nav">
                            <i
                                className="material-icons text--grey touchable-opacity"
                                onClick={this.prevMonth}
                            >
                                chevron_left
                            </i>
                            <div>
                                { formatDate(currentMonth, dateFormatMonthYearLong) }
                            </div>
                            <i
                                className="material-icons text--grey touchable-opacity"
                                onClick={this.nextMonth}
                            >
                                chevron_right
                            </i>
                        </div>
                        <div className="BatchAttendanceRegister-grid">
                            <div className="BatchAttendanceRegister-col BatchAttendanceRegister-leftPane">
                                <div className="BatchAttendanceRegister-cell">
                                    Name
                                </div>
                                {attendanceRegisterStudents.map(
                                    ({ id, studentName, nameAbbreviation }) => (
                                        <div key={id} className="BatchAttendanceRegister-cell">
                                            {isMobile ? nameAbbreviation : studentName}
                                        </div>
                                    )
                                )}
                            </div>
                            {columns}
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

export default BatchAttendanceRegister;