import React from 'react'
import ContentLoader from '../content_loader';

const ShortLoadingText = () => (
    <ContentLoader
        height="130"
        width="400"
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="0" y="0" rx="4" ry="4" width="400" height="130" />
    </ContentLoader>
)

export default ShortLoadingText