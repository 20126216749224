import { types } from "mobx-state-tree"

export const statusOptions = ["active", "retired"]

export const GradeScaleLevel = types
    .model("GradeScaleLevel", {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        min_percentage: types.maybeNull(types.number),
        points: types.maybeNull(types.number),
        remark: types.maybeNull(types.string),
        _destroy: types.optional(types.boolean, false)
    })

export const GradeScale = types
    .model("GradeScale", {
        name: types.maybeNull(types.string),
        grade_scale_type: types.maybeNull(types.enumeration(['cognitive', 'behavioural'])),
        status: types.optional(types.enumeration(statusOptions), statusOptions[0]),
        description: types.maybeNull(types.string),
        levels_attributes: types.array(GradeScaleLevel)
    })