import "./MessageView.css"
import "../students/StudentFinance.css"

import React, {Component} from 'react'
import {Row, Col} from "antd"
import { inject, observer } from 'mobx-react'

import Card from '../common/card'
import {formatDate, fullDateTimeFormat} from '../lib/formatDate'
import { titleCase } from '../lib/titleCase'
import Desktop from "../common/display/Desktop";
import MobilePageHeader from "../containers/MobilePageHeader";
import StripeLoading from "../common/loading/StripeLoading";



import Loading from "../common/loading/Loading";
import Table from "../common/table";
import Mobile from "../common/display/Mobile";
import Tablet from "../common/display/Tablet";
import MobileListLoading from "../common/loading/MobileListLoading";


@inject('store')
@observer
class MessageView extends Component {
    render() {
        const {store, messageData} = this.props
        const {view} = store

        if ( !messageData || !messageData.isInitialized || !messageData.isAssociationsLoaded) return <Loading />

        const {isLoading, messageRecipients, attributes: {send_as_email, send_as_sms}} = messageData

        const messageRecipientTableColumns = [
            { key: 'name', render: 'Name' }
        ]

        if (send_as_sms) messageRecipientTableColumns.push({ key: 'mobile_number', render: 'Mobile' })
        if (send_as_email) messageRecipientTableColumns.push({ key: 'email_address', render: 'Email' })
        if (send_as_sms){
            messageRecipientTableColumns.push({ key: 'sms_status', render: 'SMS Status' })
            messageRecipientTableColumns.push({ key: 'sms_status_message', render: 'SMS Status Message' })
            messageRecipientTableColumns.push({ key: 'sms_error_description', render: 'SMS Error' })
            messageRecipientTableColumns.push({ key: 'sms_units_used', render: 'SMS Units' })
        }
        if (send_as_email){
            messageRecipientTableColumns.push({ key: 'email_status', render: 'Email Status' })
            messageRecipientTableColumns.push({ key: 'email_error_description', render: 'Email Error' })
        }
        
        const messageRecipientsTableRows = messageRecipients.map((mr) => {
            const {
                id: messageRecipientID,
                attributes: {
                    mobile_number,
                    email_address,
                    sms_status,
                    sms_status_message,
                    sms_error_description,
                    sms_units_used,
                    email_status,
                    email_error_description
                },
                recipient
            } = mr
            
            let user_id
            let fullName
            let username
            
            if (recipient) ({id: user_id, fullName: fullName, attributes: {username}} = recipient)

            return {
                name: !recipient ? '' : (
                    <a
                        href={view.getUserablePageForUser(recipient)}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openUserablePageForUser(recipient)
                        }}
                    >
                        {fullName}
                    </a>
                ),
                mobile_number,
                email_address,
                sms_status,
                sms_status_message,
                sms_error_description,
                sms_units_used,
                email_status,
                email_error_description,
                user_id,
                username,
                fullName
            }
        })

        return (
            <div className="TransactionReceipt StripeLoadingContainer">
                <StripeLoading loading={isLoading} />
                <Desktop>
                    <Card title={<span className="text--uppercase text-small text--spacedLetters">Message</span>}>
                        <MessageStats messageData={messageData} />
                        <Table
                            columns={messageRecipientTableColumns}
                            data={messageRecipientsTableRows}
                            striped
                        />
                    </Card>
                </Desktop>
                <Tablet>
                    <MobilePageHeader tablet>
                        <span className="text--uppercase text-small text--spacedLetters">Message</span>
                        <MessageStats messageData={messageData} />
                    </MobilePageHeader>
                    <Table
                        columns={messageRecipientTableColumns}
                        data={messageRecipientsTableRows}
                        striped
                    />
                </Tablet>
                <Mobile>
                    <div className='mb-1'>
                        <MobilePageHeader>
                            <span className="text--uppercase text-small text--spacedLetters">Message</span>
                            <MessageStats messageData={messageData} />
                        </MobilePageHeader>
                    </div>

                    <MobileListLoading isLoading={isLoading}>
                        {messageRecipientsTableRows.map((item) => <StudentTransactionsItem key={item.id} keys={item} messageRecipientTableColumns={messageRecipientTableColumns} />)}
                    </MobileListLoading>
                </Mobile>
            </div>
        )
    }
}

function StudentTransactionsItem({ keys, messageRecipientTableColumns }) {
    return (
        <Card className="StudentTransactionsItem">
            {messageRecipientTableColumns.map(({ key, render }) => (
                <div key={key}>
                    <div className="text-small text--uppercase text--bold text--spacedLetters">{render}</div>
                    <div className="text-p">{keys[key]}</div>
                </div>
            ))}
        </Card>
    )
}

const MessageStats = inject("store")(
    observer(({ messageData }) => {
        
        const {
            id: message_id,
            attributes: {
                subject,
                body,
                send_as_email,
                send_as_sms,
                sms_units_used: totalSMSUnits,
                created_at,
                status,
                sms_error_description,
                email_error_description
            },
            createdByName,
            isLoading
        } = messageData
        
        const createdAt = formatDate(created_at, fullDateTimeFormat)

        return (
            <div className="TransactionReceiptStats">
                <Row gutter={15}>
                    <Col xs={9} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Send Email</div>
                        <div className="text-p text">{send_as_email ? 'Yes' : 'No'}</div>
                    </Col>
                    <Col xs={9} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Send SMS</div>
                        <div className="text-p text">{send_as_sms ? 'Yes' : 'No'}</div>
                    </Col>
                    <Col xs={9} md={4} lg={3}>
                        <div className="text--uppercase text-small text--bold text--grey">Status</div>
                        <div className="text-p text">{titleCase(status)}</div>
                    </Col>

                    <Col xs={10} md={4} lg={4}>
                        <div className="text--uppercase text-small text--bold text--grey">Created At</div>
                        <div className="text-p text">{createdAt}</div>
                    </Col>

                    {send_as_sms && (
                        <Col xs={9} md={4} lg={3}>
                            <div className="text--uppercase text-small text--bold text--grey">SMS Units Used</div>
                            <div className="text-p text">{totalSMSUnits ? totalSMSUnits : 0}</div>
                        </Col>
                    )}
                </Row>

                {send_as_email && email_error_description && (
                    <Row>
                        <Col span={24}>
                            <div className="text--uppercase text-small text--bold text--grey">Email Error</div>
                            <div className="text-h4 text--bold">{email_error_description}</div>
                        </Col>
                    </Row>
                )}

                {send_as_sms && sms_error_description && (
                    <Row>
                        <Col span={24}>
                            <div className="text--uppercase text-small text--bold text--grey">SMS Error</div>
                            <div className="text-h4 text--bold">{sms_error_description}</div>
                        </Col>
                    </Row>
                )}
                
                <Row>
                    <Col span={24}>
                        <div className="text--uppercase text-small text--bold text--grey">Message Body</div>
                        <div className="text-h4 text--bold">{body}</div>
                    </Col>
                </Row>
            </div>
        )
    })
)

export default MessageView