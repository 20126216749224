import { types, applySnapshot } from "mobx-state-tree"
import { dateType } from "../types/dateType"
import { Gender } from "../models/Gender"
import { bloodGroupOptions, religionOptions } from "./Student";
import {ActionPattern} from "../patterns/ActionPattern";
import {AttachedDocument, publishTypeOptions, scoreDisplayAsOptions} from "./Assessment";

export const UpdateEmployeePrivileges = types
    .model("UpdateEmployeePrivileges", {
        privileges: types.array(types.string)
    })

export const BankAccount = types
    .model("BankAccount", {
        bank_id: types.maybeNull(types.string),
        account_name: types.maybeNull(types.string),
        account_number: types.maybeNull(types.string),
    })

export const DropEmployee = types
    .model("DropEmployee", {
        date_left: dateType,
        reason_for_leaving: types.string,
    })

export const qualificationOptions = [
    "Bsc",
    "Msc",
    "HND",
    "OND",
    "NCE",
    "BA",
    "PGDE",
    "PROF",
    "DR",
    "KCPE",
    "KCSE",
    "UNDERGRADUATE",
    "ECDE"
]

export const maritalStatusOptions = ["single", "married"]

export {
    bloodGroupOptions,
    religionOptions
}

export const CreateEmployee  = types.compose(
    ActionPattern({}),
    types
        .model("CreateEmployee", {
            id: types.maybeNull(types.string),
            username: "",
            first_name: "",
            last_name: "",
            employee_category_id: "",
            employee_position_id: "",
            employee_department_id: "",
            middle_name: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            date_of_birth: types.maybeNull(
                types.union(dateType, types.string)
            ),
            gender: types.maybeNull(Gender),
            photo: types.maybeNull(types.string),
            signature_photo: types.maybeNull(types.string),
            blood_group: types.maybeNull(types.enumeration("blood_groups", bloodGroupOptions)),
            nationality: types.maybeNull(types.string),
            religion: types.maybeNull(types.enumeration("religions", religionOptions)),
            marital_status: types.maybeNull(types.enumeration("marital_status", maritalStatusOptions)),
            email: types.maybeNull(types.string),
            mobile_phone: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            date_joined: types.maybeNull(
                types.union(dateType, types.string)
            ),
            qualification: types.maybeNull(types.enumeration("qualifications", qualificationOptions)),
            next_of_kin_name: types.maybeNull(types.string),
            relationship_with_next_of_kin: types.maybeNull(types.string),
            next_of_kin_mobile_phone: types.maybeNull(types.string),
            next_of_kin_phone: types.maybeNull(types.string),
            next_of_kin_address: types.maybeNull(types.string),
            bank_account_attributes: types.optional(BankAccount, {})
        })
        .actions(self => ({
            setFormInstance(employee) {
                const { id, attributes, employee_category_id, employee_position_id, employee_department_id } = employee
                applySnapshot(self, { id, ...attributes, employee_category_id, employee_position_id, employee_department_id })
            }
        }))
);
