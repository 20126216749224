import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import TransactionReceipt from "../finance/TransactionReceipt";
import MaybeBackPage from "../containers/MaybeBackPage";
import MessageView from "./MessageView";


@inject("store","display")
@observer
class SelectedMessage extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        const {
            store: {
                view: { selectedMessageId },
                messageStore: {
                    loadMessageById
                }
            }
        } = this.props
        loadMessageById(selectedMessageId)
    }
    
    render() {
        const {
            store: {view: { selectedMessage}},
            display: { isDesktop }
        } = this.props
        
        const receipt = (<MessageView messageData={selectedMessage} />)
        return (<MaybeBackPage>{receipt}</MaybeBackPage>)
    }
}

export default SelectedMessage
