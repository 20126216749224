import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form, converters } from "mstform";
import { observer, inject } from "mobx-react";

import "./NewSubjectName.css";
import { CreateSubjectBase } from "../../store/actions/Subject";
import InlineError from "../../common/form/InlineError";
import Label from "../../common/form/Label";
import TextInput from "../../common/form/TextInput";
import Button from "../../common/button";

const validators = [(value) => (value.length > 0) ? false : 'Field is required']
const form = new Form(CreateSubjectBase, {
    name: new Field(converters.string, { required: true, validators }),
    short_name: new Field(converters.string, { required: true, validators }),
});

@inject("store", "display")
@observer
export default class NewSubjectName extends Component {
    constructor(props) {
        super(props);
        const { store, editMode, selectedSubjectNameId, closeForm } = props;
        const { subjectStore: { subjectBases, updateSubjectBase, createSubjectBase, addSubjectBaseFormInstance } } = store;

        if (editMode) {
            const selectedSubjectName = subjectBases.get(selectedSubjectNameId);
            this.formState = form.state(CreateSubjectBase.create({ ...selectedSubjectName, ...selectedSubjectName.attributes }), {
                addMode: false,
                save: async (node) => {
                    const { errors, data } = await updateSubjectBase(node.toJSON(), selectedSubjectNameId);
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        } else {
            this.formState = form.state(addSubjectBaseFormInstance, {
                addMode: false,
                save: async (node) => {
                    this.setState({ errors: null });
                    const { errors, data } = await createSubjectBase(node.toJSON());
                    if (errors) this.setState({ errors });
                    if (data) return closeForm();
                    return errors;
                }
            });
        }

        this.state = {
            errors: null,
        };
    }

    handleSubmit = async () => {
        await this.formState.save();
    }

    render() {
        const { errors } = this.state;
        const name = this.formState.field("name")
        const short_name = this.formState.field("short_name");
        const { editMode, store: { subjectStore: { isUpdateSubjectBaseLoading, isCreateSubjectBaseLoading } } } = this.props;

        return (
            <div className="NewSubjectName">
                <div className="NewSubjectName--Header">
                    <h2>{`${editMode ? 'Edit' : 'Create New'} Subject Name`}</h2>
                </div>
                {
                    errors && (
                        <div className="NewSubjectName--Errors">
                            {
                                Object.keys(errors).map(key => (<p key={errors[key] + key}>{`Error Message - ${key.toUpperCase()}: ${errors[key]}`}</p>))
                            }
                        </div>
                    )
                }

                <div className="NewSubjectName--Body">
                    <Label text="Subject" required={name.required}>
                        <InlineError field={name}>
                            <TextInput {...name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Abbreviation" required={short_name.required}>
                        <InlineError field={short_name}>
                            <TextInput {...short_name.inputProps} />
                        </InlineError>
                    </Label>
                    <Button
                        loading={isCreateSubjectBaseLoading || isUpdateSubjectBaseLoading}
                        onClick={this.handleSubmit}
                    >
                        {`${editMode ? 'Edit' : 'Create'} Subject Name`}
                    </Button>
                </div>
            </div>
        );
    }
}

NewSubjectName.defaultProps = {
    editMode: false,
};

NewSubjectName.propTypes = {
    editMode: PropTypes.bool,
    closeForm: PropTypes.func.isRequired,
    selectedSubjectNameId: PropTypes.string,
};
