import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { format } from "date-fns";
import { Popover } from "antd";
import PropTypes from "prop-types";

import "./Classes.css";
import BackButtonMobile from "../BackButtonMobile";
import NoDataAvailable from "../NoDataAvailable";
import Button from "../../common/button";
import Table from "../../common/table";
import NotDesktop from "../../common/display/NotDesktop";
import Desktop from "../../common/display/Desktop";
import Card from "../../common/card/Card";
import moreVert from "../../subjects/assets/more-vert.svg";
import NewClass from "./NewClass";
import { ModalContext } from "../../common/modal";
import ButtonDropDownContent from "../ButtonDropDownContent";
import SwitchInput from "../../common/form/SwitchInput";

@inject("store")
@observer
class Classes extends Component {
    state = {
        selectedRowId: null,
        displayMobileCreateForm: false,
        isMobileEditMode: false,
    };

    async componentDidMount() {
        const { store } = this.props;
        const { courseStore: { getCourses } } = store;

        await getCourses();
    }

    onRowSelectionChange = selectedRowId => this.setState({ selectedRowId });

    toggleMobileCreateForm = (isMobileEditMode = false, selectedRowId = null) => {
        const { displayMobileCreateForm } = this.state;
        this.setState({ displayMobileCreateForm: !displayMobileCreateForm, isMobileEditMode, selectedRowId });
    }

    handleEditClick = (context) => () => {
        const { selectedRowId } = this.state;
        context.setContent("", <NewClass editMode selectedClassId={selectedRowId} closeForm={context.closeModal} />, {});
    }

    handleDeleteClick = (id) => async () => {
        const { store } = this.props;
        const { courseStore: { deleteCourse } } = store;

        await deleteCourse(id);
    }

    handleAddNewSessionClick = (context) => () => {
        context.setContent("", <NewClass editMode={false} closeForm={context.closeModal} />, {});
    }

    handleStatusToggle = (courseId) => async (value) => {
        const { store } = this.props;
        const { courseStore: { courses, updateCourse } } = store;

        await updateCourse({ ...courses.get(courseId).attributes, is_active: value }, courseId);
    }

    render() {
        const { selectedRowId, displayMobileCreateForm, isMobileEditMode } = this.state;
        const { store } = this.props;
        const { courseStore: { courses, isCourseListLoading } } = store;

        const availableCourses = Array.from(courses.keys()).map(key => {
            const session = courses.get(key);

            return { ...session, ...session.attributes };
        });

        const columns = genColumns(this.handleStatusToggle);

        return (
            <div className="Classes">
                <div className="Classes--Header">
                    <BackButtonMobile onClick={displayMobileCreateForm ? this.toggleMobileCreateForm : null} />
                    {
                        !displayMobileCreateForm && <h2>Classes</h2>
                    }
                </div>
                <div className="Classes--Body">
                    <Desktop>
                        <ModalContext.Consumer>
                            {(context) => !availableCourses.length
                                ? (
                                    <NoDataAvailable
                                        handleButtonClick={this.handleAddNewSessionClick(context)}
                                        buttonText="Create New Class"
                                        title="There's Nothing Here At The Moment"
                                        description="You haven't added any classes yet. Tap the button below to create a new class"
                                    />
                                )
                                : (
                                    <div className="Classes--cta-table-wrapper">
                                        <div className="Classes--cta-wrapper">
                                            <Button
                                                popover={{
                                                    content: <ButtonDropDownContent disabled={!selectedRowId} handleEditClick={this.handleEditClick(context)} handleDeleteClick={this.handleDeleteClick(selectedRowId)} />,
                                                    placement: 'bottomLeft',
                                                }}
                                            >
                                                Actions ▼
                                            </Button>
                                            <Button
                                                buttonType="outline"
                                                onClick={this.handleAddNewSessionClick(context)}
                                            >
                                                Add New Class
                                            </Button>
                                        </div>
                                        <div className="Classes--table-wrapper">
                                            <Table
                                                bordered
                                                containerClass="Card"
                                                columns={columns}
                                                data={availableCourses}
                                                onError={e => console.log(e)}
                                                loading={isCourseListLoading}
                                                shouldSelectAllColumns
                                                onColumnKeysChange={e => console.log(e)}
                                                selectedRows={[selectedRowId]}
                                                onRowSelectionChange={this.onRowSelectionChange}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </ModalContext.Consumer>
                    </Desktop>

                    <NotDesktop>
                        {
                            !availableCourses.length && !displayMobileCreateForm
                                ? (
                                    <NoDataAvailable
                                        handleButtonClick={this.toggleMobileCreateForm}
                                        buttonText="Create New Class"
                                        title="There's Nothing Here At The Moment"
                                        description="You haven't added any classes yet. Tap the button below to create a new class"
                                    />
                                )
                                : displayMobileCreateForm
                                    ? (<NewClass closeForm={this.toggleMobileCreateForm} editMode={isMobileEditMode} selectedClassId={selectedRowId} />)
                                    : (
                                        <div className="Classes--cta-card-wrapper">
                                            <div className="Classes--cta-wrapper">
                                                <Button onClick={this.toggleMobileCreateForm}>Add New Class</Button>
                                            </div>
                                            <div className="Classes--cta-card-wrapper">
                                                {
                                                    availableCourses.map((row, index) => (
                                                        <MobileRow
                                                            handleEditClick={this.toggleMobileCreateForm}
                                                            handleDeleteClick={this.handleDeleteClick}
                                                            handleStatusToggle={this.handleStatusToggle}
                                                            {...row}
                                                            key={row.id || index}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                        }
                    </NotDesktop>
                </div>
            </div>
        )
    }
}

export default Classes;

const genColumns = onStatusChange => [
    { key: 'abbreviation', render: 'Code' },
    { key: 'name', render: 'Name' },
    { key: 'is_active', render: 'Status', display: ({ id, is_active }) => <SwitchInput value={is_active} onChange={onStatusChange(id)} /> },
];

function MobileRow(props) {
    const {
        id, name, abbreviation, is_active, handleEditClick, handleDeleteClick, handleStatusToggle,
    } = props;

    return (
        <Card>
            <div className="Classes-MobileRow">
                <div className="Classes-MobileRow--Body">
                    <div className="Classes-MobileRow--Body--top">
                        <div className="Classes-MobileRow--Row">
                            <span><strong>Code</strong></span>
                            <span>{abbreviation}</span>
                        </div>
                        <div className="Classes-MobileRow--Row">
                            <span><strong>Status</strong></span>
                            <span>
                                <SwitchInput size="small" value={is_active} onChange={handleStatusToggle(id)} />
                            </span>
                        </div>
                    </div>
                    <div className="Classes-MobileRow--Body--bottom">
                        <div className="Classes-MobileRow--Row">
                            <span><strong>Name</strong></span>
                            <span>{name}</span>
                        </div>
                    </div>
                </div>
                <div className="Classes-MobileRow--cta">
                    <Popover
                        placement="bottomRight"
                        content={<ButtonDropDownContent disabled={!id} handleEditClick={() => handleEditClick(true, id)} handleDeleteClick={handleDeleteClick(id)} />}
                        trigger="click"
                    >
                        <img
                            src={moreVert}
                            alt="icon: more outlined"
                        />
                    </Popover>
                </div>
            </div>
        </Card>
    );
}

MobileRow.propTypes = {
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    handleStatusToggle: PropTypes.func.isRequired,
}
