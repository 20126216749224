import React from 'react'
import { observer, inject } from 'mobx-react';
import Notes from '../common/notes'
import { Note as action } from '../store/actions/Note'
import EmployeePolicy from "../policies/StudentPolicy";

const EmployeeNotes = inject("store")(
    observer(({ store, notes }) => {
        const { employeeStore, view, meta: {currentUser} } = store
        const { selectedEmployee } = view
        const { isLoading, id: employeeId } = selectedEmployee

        const employeePolicy = new EmployeePolicy(currentUser, selectedEmployee)
        

        const localNotes = notes
            .map(({ attributes, id, author, updated, visible }) => ({
                id, author, updated, visible, ...attributes,
            }))

        function addNote(note) {
            employeeStore.addNote(employeeId, action.create(note))
        }

        function removeNote(noteId) {
            employeeStore.removeNote(employeeId, noteId)
        }

        function editNote(noteId, note) {
            employeeStore.editNote(employeeId, noteId, action.create(note))
        }

        return (
            <Notes
                canCreateNote={employeePolicy.can_create_note}
                isLoading={isLoading}
                notes={localNotes}
                onCreateNote={addNote}
                onDeleteNote={removeNote}
                onEditNote={editNote}
                visibilityText="Visible to employee"
            />
        )
    }))

export default EmployeeNotes
