import { types, flow } from "mobx-state-tree"
import Cookies from 'cookie-universal';
import ReactGA from "react-ga";

import { UsesBluebicStorePattern } from "./patterns/UsesBluebicStorePattern";
import { LoadingPattern } from "./patterns/LoadingPattern";
import { setAuthHeader, removeAuthHeader } from "../service/axios";

const cookies = new Cookies();

export const AuthStore = types
    .compose(
        UsesBluebicStorePattern,
        LoadingPattern(false),
        types
            .model("AuthStore", {
                accessToken: types.maybeNull(types.string),
            })
            .views(self => ({
                get hasToken() {
                    return self.accessToken !== null
                }
            }))
            .actions(self => {

                const createAccessToken = flow(function* createAccessToken(action) {
                    try {
                        self.markLoading(true)
                        const { data: { access_token }, meta_new: meta } = yield self.bluebic.authService.createToken(action)
                        self.markLoading(false)
                        self.accessToken = access_token
                        const cookieMaxAge = !self.bluebic.rememberMe ? undefined : 60 * 60 * 24 * 14 // 2 weeks
                        const cookieOptions = { maxAge: cookieMaxAge}
                        const { data: meta_data, included } = meta
                        
                        self.bluebic.handleUpdateStores(included)
                        self.bluebic.setMetaData(meta_data)
                        
                        // we want to be sure store updates successfully before storing access token
                        cookies.set('access_token', self.accessToken, cookieOptions );
                        setAuthHeader(access_token)
                        
                        self.bluebic.view.openNextPage()

                        if (typeof window.newrelic === 'object') window.newrelic.addPageAction('User Login', {rememberMe: self.bluebic.rememberMe});
                        if (typeof window.mixpanel === 'object'){
                            window.mixpanel.track("User Login", { "rememberMe": self.bluebic.rememberMe });
                            window.mixpanel.people.increment({"User Login": 1});
                        }

                        setAnalyticsUser()
                        
                    } catch (err) {
                        self.markLoading(false)
                        console.error(`Failed to create access token`, err)
                    }
                })

                function logout() {
                    removeAuthHeader()
                    self.accessToken = null
                    self.bluebic.clearMetaData()
                    self.bluebic.clearStorage()
                }

                function setAnalyticsUser() {
                    const {meta: {currentUser, currentInstitution} } = self.bluebic

                    ReactGA.set({
                        userId: currentUser.global_id,
                        dimension1: currentInstitution.attributes.tenant_name,
                        dimension2: currentUser.userType
                    });

                    if (typeof window.newrelic === 'object') {
                        window.newrelic.setCustomAttribute('SchoolID', currentInstitution.attributes.tenant_name);
                        window.newrelic.setCustomAttribute('UserType', currentUser.userType);
                        window.newrelic.setCustomAttribute('UserID', currentUser.attributes.bluebic_email);
                        window.newrelic.setCustomAttribute('preferredEmail', currentUser.attributes.preferred_email);
                    }

                    if (typeof window.FS === 'object'){
                        window.FS.identify(currentUser.attributes.preferred_email, {
                            displayName: currentUser.fullName,
                            preferredEmail_str: currentUser.attributes.preferred_email,
                            tenantName_str: currentInstitution.attributes.tenant_name,
                            isAdmin_bool: currentUser.is_admin,
                            accountType_str: currentUser.userType
                        });
                    }

                    if (typeof window.mixpanel === 'object') {
                        window.mixpanel.identify(currentUser.attributes.preferred_email);
                        window.mixpanel.people.set({
                            'name': currentUser.fullName,
                            'phone': currentUser.attributes.mobile_phone,
                            'School Name': currentInstitution.attributes.name,
                            'Preferred Email': currentUser.attributes.preferred_email,
                            'School ID': currentInstitution.attributes.tenant_name,
                            'Is Admin': currentUser.is_admin,
                            'Account Type': currentUser.userType
                        });
                    }

                    if (window.Beacon){
                        window.Beacon('identify', {
                            name: currentUser.fullName,
                            email: currentUser.attributes.preferred_email,
                            signature: currentUser.attributes.helpscout_signature,
                            company: currentInstitution.attributes.name,
                            jobTitle: currentUser.userType,
                            'User ID': currentUser.global_id,
                            'School ID': currentInstitution.attributes.tenant_name,
                            'User Name': currentUser.fullName,
                            'Is Admin': currentUser.is_admin
                        })
                    }

                }

                return {
                    createAccessToken,
                    setAnalyticsUser,
                    logout
                }
            })
    )
