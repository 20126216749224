import axios from './axios'
import { TESTENV } from './constants'
import showEmployeeJSON from '../json/employee_data.json'
import updateUserPasswordJSON from "../json/update_user_password";

export function UserService(env) {

    async function showUser(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/users/${id}`)
            return data
        }
        return showEmployeeJSON
    }

    async function updateUserPassword(id, update_password) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/users/${id}/password`, { update_password })
            return data
        }
        return updateUserPasswordJSON
    }


    return {
        showUser,
        updateUserPassword
        
    }
}

export default UserService(TESTENV)