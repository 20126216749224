import 'react-day-picker/lib/style.css'
import './DateInput.css'

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import classNames from 'classnames'

import {
    formatDate,
    parseDate,
    defaultDateFormat,
    dateRegexFormat,
    apiDateFormat,
    apiDateRegexFormat
} from '../../lib/formatDate'



// import Error from './assets/Error.svg'
// import Success from './assets/Success.svg'
import Calendar from './assets/Calendar.svg'


class DateInput extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        // success: PropTypes.bool,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        // onBlur: PropTypes.func.isRequired,
        // onFocus: PropTypes.func.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        placeholder: "Select date",
        className: ""
    }

    /* onBlur = () => {
        if (this.dateInput.value && formatDate(this.dateInput.value) === "Date Error") this.dateInput.value = ""
    } */

    onDayChange = date => {
        const { onChange } = this.props
        const a = formatDate(date)
        // consider saving the field as an ISO standard date i.e onChange(unFormattedDate)
        if (a && dateRegexFormat.test(a)) onChange(a)
        else onChange("")
    }

    parseInputDate = date => {
        if (date && apiDateRegexFormat.test(date)) return parseDate(date, apiDateFormat)
        return parseDate(date, defaultDateFormat)
    }

    onClick = (e) => {
        const a = e.currentTarget
        if (a.querySelector('.FormInputControl-textInput')) a.querySelector('.FormInputControl-textInput').focus()
    }
    
    render() {
        const { className, value, placeholder } = this.props
        const formInputControlClass = classNames(
            'FormInputControl',
            'flex-row flex-spaceBetween flex-alignCenter',
            className
        )

        return (
            <div onClick={this.onClick} className={formInputControlClass} style={{ paddingLeft: 0 }}>
                <div className="flex-row flex-alignCenter">
                    <div className="dateInput-prepend touchable-opacity"><img src={Calendar} alt="" /></div>
                    <DayPickerInput
                        formatDate={formatDate}
                        value={this.parseInputDate(value)}
                        inputProps={{ style: { paddingLeft: 10 }, className: "FormInputControl-textInput" }}
                        format={defaultDateFormat}
                        parseDate={this.parseInputDate}
                        placeholder={placeholder || `${formatDate(new Date(), defaultDateFormat)}`}
                        onDayChange={this.onDayChange}
                        classNames={{ overlay: 'Card dateInput-overlay' }}
                        // Disabled due to https://github.com/gpbl/react-day-picker/issues/819
                        /* component={props => (
                            <input
                                {...props}
                                onBlur={this.onBlur}
                                ref={(input) => { this.dateInput = input }}
                            />
                        )} */
                    />
                </div>
                {/* {error && <img src={Error} alt="" />}
        {success && <img src={Success} alt="" />} */}
            </div>
        )
    }
}
export default DateInput