import { types } from 'mobx-state-tree';
import {User} from "./User";
import isEmpty from "lodash.isempty";


export const MessageRecipient = types
    .model('MessageRecipient', {
        id: types.identifier,
        type: 'message_recipient',
        attributes: types.maybe(
            types.model({
                mobile_number: types.maybeNull(types.string),
                email_address: types.maybeNull(types.string),
                sms_status: types.maybeNull(types.string),
                sms_status_message: types.maybeNull(types.string),
                sms_error_description: types.maybeNull(types.string),
                sms_units_used: types.maybeNull(types.number),
                email_status: types.maybeNull(types.string),
                email_error_description: types.maybeNull(types.string),
            }),
        ),
        relationships: types.maybe(
            types.model({
                recipient: types.model({
                    data: types.maybeNull(types.model({
                        id: types.late(() => types.reference(User)),
                        type: "user"
                    }))
                })
            }),
        )
    }).views(self => ({
        get recipient() {
            try {
                return self.relationships
                    .recipient
                    .data
                    .id
            } catch (err) {
                console.error(err)
                return null
            }
        },
    }));


export const Message = types
    .model('Message', {
        id: types.identifier,
        type: 'message',
        attributes: types.maybe(
            types.model({
                subject: types.maybeNull(types.string),
                body: types.string,
                send_as_email: types.boolean,
                send_as_sms: types.boolean,
                sms_units_used: types.maybeNull(types.number),
                status: types.string,
                sms_error_description: types.maybeNull(types.string),
                email_error_description: types.maybeNull(types.string),
                created_at: types.string
            }),
        ),
        relationships: types.maybe(
            types.model({
                created_by: types.model({
                    data: types.model({
                        id: types.late(() => types.reference(User)),
                        type: types.literal('user')
                    })
                }),
                message_recipients: types.maybe(types.model({
                    data: types.array(types.model({
                        id: types.late(() => types.reference(MessageRecipient)),
                        type: types.literal("message_recipient")
                    }))
                }))
            })
        ),
        isLoading: false,
    })
    .views(self => ({
        get isInitialized() {
            return !isEmpty(self.attributes)
        },
        
        get isAssociationsLoaded(){
            return !!self.messageRecipients
        },
        
        get name() {
            try {
                return self.attributes.subject ? self.attributes.subject : self.attributes.body;
            } catch (e) {
                return null;
            }
        },

        get title() {
            if (this.name.length > 50) return this.name.substring(0, 47) + "..."
            return this.name
        },

        get messageRecipients() {
            try {
                return self.relationships.message_recipients.data.map(({ id: ref }) => ref)
            } catch (err) {
                console.error(err)
                return null
            }
        },

        get createdByName() {
            try {
                return self.createdBy.fullName
            } catch (err) {
                console.error(err)
                return ''
            }
        },

        get createdBy() {
            try {
                return self.relationships
                    .created_by
                    .data
                    .id
            } catch (err) {
                console.error(err)
                return null
            }
        },
    })); 
