import { types } from "mobx-state-tree"
import { dateRangeType } from '../types/dateRangeType';

export const StudentAttendanceReportSort = types
    .model("StudentAttendanceReportSort", {
        academic_term: types.maybe(
            types.union(types.number, types.string)
        ),
        academic_session_id: ""
    })
    .actions(self => ({
        setAcademicTerm(term) {
            self.academic_term = term
        },
        setAcademicSessionId(id) {
            self.academic_session_id = id
        },
        setAcademicSession({ id }) {
            self.academic_session_id = id
        }
    }))

export const StudentAttendanceReport = types
    .model("StudentAttendanceReport", {
        id: "",
        type: "student_attendance_report",
        attributes: types.maybe(types.model({
            date_range: dateRangeType,
            academic_session_id: types.number,
            academic_term: types.maybeNull(types.number),
            academic_days_count: types.number,
            full_day_leaves_count: types.number,
            half_day_leaves_count: types.number,
            leaves_count: types.number,
            score: types.maybeNull(types.number),
            percentage: types.maybeNull(types.number),
            holiday_dates_and_title: types.map(types.string),
            insession_dates: types.array(types.string),
            leaves: types.array(types.model({
                date: "",
                half_day: false,
                reason: types.maybeNull(types.string)
            }))
        })),
        sort: types.optional(StudentAttendanceReportSort, {}),
        isLoading: true
    })
    .views(self => ({
        get daysOpened() {
            try {
                return self.attributes.academic_days_count / 2
            } catch (e) {
                return 0
            }
        },
        get daysPresent() {
            try {
                return self.daysOpened - self.attributes.full_day_leaves_count
            } catch (e) {
                return 0
            }
        }
    }))
    .actions(self => ({
        markLoading: (loading) => {
            self.isLoading = loading
        },
        update({ id, attributes }) {
            self.id = id
            self.attributes = attributes
        }
    }))