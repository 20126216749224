import { types } from 'mobx-state-tree'
import { Assessment } from './Assessment'
import { Student } from './Student'
import { User } from './User';

export const AssessmentScore = types.model('AssessmentScore', {
    id: types.identifier,
    type: 'assessment_score',
    attributes: types.maybe(
        types.model({
            comment: types.maybeNull(types.string),
            exempt: types.boolean,
            late: types.boolean,
            collected: types.boolean,
            missing: types.boolean,
            score: types.maybeNull(types.string),
            subject_name: types.maybeNull(types.string),
            batch_name_without_session: types.string,
            cell_value: types.string,
            updated_at: types.maybeNull(types.string),
            created_at: types.maybeNull(types.string),
        }),
    ),
    relationships: types.maybe(
        types.model({
            student: types.model({
                data: types.model({
                    id: types.late(() => types.reference(Student))
                })
            }),
            created_by: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(User))
                }))
            }),
            assessment:
                types.model({
                    data: types.model({
                        id: types.late(() => types.reference(Assessment))
                    })
                }),
        }),
    ),
}).views(self => ({
    get student() {
        return self.relationships
            .student
            .data
            .id
    },
    
    get createdByName() {
        try {
            return self.relationships
                .created_by
                .data
                .id
                .fullName
            
        } catch (err) {
            return ''
        }
    },
    
    get assessment() {
        return self.relationships
            .assessment
            .data
            .id
    },
    
    get subjectName() {
        return self.attributes.subject_name
    },
    
    get updateActionJSON() {
        const {
            id,
            student: {id: student_id},
            assessment: {id: assessment_id},
            attributes: {
                late,
                collected,
                missing,
                exempt,
                comment,
                cell_value,
                score
            }
        } = self

        return {
            id,
            student_id,
            assessment_id,
            late,
            collected,
            missing,
            exempt,
            comment,
            cell_value,
            score
        }

    }
    
}))
