import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { Checkbox } from 'antd';
import { AsYouType } from 'libphonenumber-js'
import { formatDate } from '../lib/formatDate'
import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import EmptyList from '../common/empty_list/empty_list';
import MobileListLoading from '../common/loading/MobileListLoading';
import { ModalContext } from '../common/modal'
import DisableStudentLogin from '../students/DisableStudentLogin';
import BatchStudentTransfer from "./BatchStudentTransfer"

import arrowDown from '../common/assets/arrow-down-white.svg'
import studentIcon from '../common/assets/graduate-student-avatar.svg'
import calculator from '../common/assets/calculator.svg'
import printer from '../common/assets/printer.svg'
import block from '../common/assets/block.svg'
import chat from '../common/assets/chat.svg'
import Change_batch from './assets/change_batch.svg'
import graduate_students from './assets/graduate_students.svg'
import {Batch} from "../store/models/Batch";
import {modelOf} from "../lib/modelOf";
import StudentPolicy from "../policies/StudentPolicy";


@inject('store')
@observer
class ActionsButton extends Component {
    render() {
        const {batchDetails, store : {meta: {currentUser} }, ...rest} = this.props
        const studentPolicy = new StudentPolicy(currentUser, 'Student')
        
        
        const {selectedStudents, id: batch_id} = batchDetails;
        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedStudents.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        {false && (
                            <>
                                <button
                                    type="button"
                                    className={selectedStudentsButtonClass}
                                    onClick={() => context.setContent(
                                        `Finance Summary for Students`,
                                        true
                                    )}
                                >
                                    <img src={calculator} style={{width: 18}} className="utils-prepend" alt="" />
                                    Finance Summary
                                </button>
                                <div className="divider" />
                                <div className="Button Button--clear touchable-opacity text-p">
                                    <img src={printer} style={{width: 18}} className="utils-prepend" alt="" />
                                    Print List
                                </div>
                                <div className="divider" />
                                <div className="Button Button--clear touchable-opacity text-p">
                                    <img src={chat} style={{width: 18}} className="utils-prepend" alt="" />
                                    Send Message
                                </div>
                                <div className="divider" />
                            </>
                        )}
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            disabled={!studentPolicy.can_block}
                            title={studentPolicy.can_block ? '' : "No permission."}
                            onClick={() => context.setContent(
                                "Block Student Portal Access",
                                <DisableStudentLogin studentIds={[selectedStudents]} onClose={context.closeModal} />,
                                true
                            )}
                        >
                            <img src={block} style={{width: 18}} className="utils-prepend" alt="" />
                            Disable Login
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            disabled={!studentPolicy.can_transfer_students}
                            title={studentPolicy.can_transfer_students  ? '' : "No permission."}
                            onClick={() => context.setContent(
                                "Graduate Students",
                                <BatchStudentTransfer studentIds={selectedStudents} batch_transfer_type="graduation" onClose={context.closeModal} batchId={batch_id} />,
                                true
                            )}
                        >
                            <img src={graduate_students} style={{width: 18}} className="utils-prepend" alt="" />
                            Graduate Students
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            disabled={!studentPolicy.can_transfer_students}
                            title={studentPolicy.can_transfer_students ? '' : "No permission."}
                            onClick={() => context.setContent(
                                "Change Student Batch",
                                <BatchStudentTransfer studentIds={selectedStudents} batch_transfer_type="change_batch" onClose={context.closeModal} batchId={batch_id} />,
                                true
                            )}
                        >
                            <img src={Change_batch} style={{width: 18}} className="utils-prepend" alt="" />
                            Change Batch
                        </button>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

ActionsButton.propTypes = {
    batchDetails: modelOf(Batch).isRequired
}


@inject('store') @observer
class BatchStudentList extends Component {

    render () {
        const { store, batchDetails } = this.props;
        const { studentsInBatch } = batchDetails
        const { formatCurrency, view: { openStudentPageById } } = store
        const {

            isLoading,
            selectStudentById,
            selectedStudents,
            isAllStudentSelected,
            selectAllStudents,
        } = batchDetails

        const columns = [
            { key: 'photo', render: 'Photo' },
            { key: 'name', render: 'Name' },
            { key: 'admissionNumber', render: 'Adm. No.' },
            { key: 'batch', render: 'Batch' },
            { key: 'admission_date', render: 'Admission Date' },
            { key: 'address', render: 'Address' },
            { key: 'blood_group', render: 'Blood Group' },
            { key: 'date_of_birth', render: 'Date of Birth' },
            { key: 'due_fees_balance', render: 'Due Fees Balance' },
            { key: 'wallet_balance', render: 'Wallet Balance' },
            { key: 'email', render: 'Email' },
            { key: 'updated_at', render: 'Last Updated' },
            { key: 'mobile_phone', render: 'Mobile Phone' },
            { key: 'nationality', render: 'Nationality' },
            { key: 'phone', render: 'Phone' },
            { key: 'religion', render: 'Religion' },
        ]
        const defaultColumns = ['name', 'admissionNumber', 'batch', 'admission_date']

        const sortedStudents = studentsInBatch.sort((a, b) => a.studentName.localeCompare(b.studentName, 'en', {sensitivity: 'base', ignorePunctuation: true}))
        
        const tableData = sortedStudents.map(({ id, attributes, studentName, admissionNumber, batch }) => ({
            id,
            name: (
                <a
                    href={`/students/${id}`}
                    onClick={(e) => {
                        e.preventDefault()
                        openStudentPageById(id)
                    }}
                >
                    {studentName}
                </a>
            ),
            admissionNumber,
            batch,
            admission_date: formatDate(attributes.admission_date),
            address: attributes.address,
            blood_group: attributes.blood_group,
            date_of_birth: formatDate(attributes.date_of_birth),
            due_fees_balance: formatCurrency(attributes.due_fees_balance),
            wallet_balance: formatCurrency(attributes.wallet_balance),
            email: attributes.email,
            updated_at: attributes.updated_at,
            mobile_phone: new AsYouType().input(attributes.mobile_phone),
            nationality: attributes.nationality,
            phone: new AsYouType().input(attributes.phone),
            religion: attributes.religion,
            photo: (
                <Avatar
                    src={attributes.photo_url}
                    small
                    type={AVI_TYPES.STUDENT}
                    sex={attributes.gender === 'male' ? 'm' : 'f'}
                />
            ),
        }))

        if (isLoading) return <EmptyList />

        return (
            <div>
                <div className="StudentList">
                    <NotMobile>
                        <div className="flex-row flex-spaceBetween">
                            <div className="flex-row">
                                <ActionsButton batchDetails={batchDetails} className="utils-prepend" />
                                {Boolean(selectedStudents.length) && (
                                    `${selectedStudents.length} selected`
                                )}
                            </div>
                            {/* {this.listSummary} */}
                        </div>
                        <Table
                            striped
                            containerClass="Card"
                            columns={columns}
                            defaultVisibleColumnKeys={defaultColumns}
                            data={tableData}
                            onRowSelectionChange={selectStudentById}
                            onError={e => console.log(e)}
                            loading={isLoading}
                            onColumnKeysChange={e => console.log(e)}
                            selectedRows={selectedStudents}
                            isAllSelected={isAllStudentSelected}
                            onSelectAll={selectAllStudents}
                        />
                    </NotMobile>

                    <Mobile>
                        <Card className="StudentListItem mb-1">
                            <div className="text--center">
                                <div className="text-small text--uppercase pb-1">
                                    <img src={studentIcon} alt="" />
                                    <span className="utils-append">Student List</span>
                                </div>
                                <div className="flex-row flex-justifyCenter">
                                    <ActionsButton batchDetails={batchDetails} className="pb-1" fullwidth />
                                </div>
                            </div>
                        </Card>
                        <>
                            <div className="flex-row flex-spaceBetween mr-1 ">
                                <div className="text--bold text-p utils-prepend">Select All</div>
                                <div>
                                    {Boolean(selectedStudents.length) && (`${selectedStudents.length} selected`)}
                                    <Checkbox
                                        checked={isAllStudentSelected}
                                        onChange={selectAllStudents}
                                              className="utils-append"
                                    />
                                </div>

                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {sortedStudents.map(student => <StudentListItem id={student.id} key={student.id} student={student} />)}
                            </MobileListLoading>

                        </>
                    </Mobile>
                </div>
            </div>
        )
    }
}

export default BatchStudentList

const StudentListItem = inject('store')(
    observer(
        ({ student, store }) => {
            const { id, studentName, attributes: { photo_url, gender }, admissionNumber } = student
            const { attributes: { admission_date: unformatted_admission_date } } = student
            const { view: { selectedBatch } } = store
            const { selectStudentById, selectedStudents } = selectedBatch

            const admission_date = formatDate(unformatted_admission_date)
            return (
                <Card className="StudentListItem mb-1">
                    <Checkbox
                        className="StudentListItem-checkbox"
                        checked={selectedStudents.includes(id)}
                        onChange={() => selectStudentById(id)}
                    />
                    <Avatar
                        small
                        className="StudentListItem-avi"
                        src={photo_url}
                        sex={gender && gender[0]}
                        type={AVI_TYPES.STUDENT}
                    />
                    <a
                        href={`/student/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            store.view.openStudentPageById(id)
                            return false
                        }}
                    >{studentName}
                    </a>
                    <div>Adm. No: {admissionNumber}</div>
                    <div>Adm. Date: {admission_date}</div>
                </Card>
            )
        },
    ))