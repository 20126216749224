import { types } from "mobx-state-tree"
import {Batch} from "./Batch";

export const StudentsReportCardData = types
    .model("StudentsReportCardData", {
        id: types.identifier, // ReportCardGroup.id + student.id
        batch_id: types.late(()=> types.reference(Batch)),
        student_id: types.number,
        
        cognitive: types.array(types.model({
            column_id: types.number,
            subjects: types.array(types.model({
                subject_base_id: types.number,
                position_in_batch: types.maybeNull(types.number),
                position_in_class: types.maybeNull(types.number)
            })),
            subject_cell: types.array(types.model({
                subject_base_id: types.number,
                score: types.maybeNull(types.union(types.string, types.number)),
            })),
            summary: types.maybeNull(types.model({
                position_in_batch: types.maybeNull(types.number),
                position_in_class: types.maybeNull(types.number),
                weighted_grade_sum: types.maybeNull(types.union(types.number, types.string)),
                total_possible_points: types.number,
                equivalent_over_value_sum: types.maybeNull(types.union(types.number, types.string)),
                total_points_over_equivalent_over_value_sum: types.maybeNull(types.union(types.number, types.string)),
                total_points: types.maybeNull(types.union(types.number, types.string)),
                average_weighted_grade: types.maybeNull(types.union(types.number, types.string))
            })),
        })),
        
        attendance: types.maybeNull(
            types.model({
                academic_days_count: types.number,
                full_day_leaves: types.number,
                half_day_leaves: types.number,
                leaves_count: types.number,
                score: types.number,
                percentage: types.number,
            })
        ),
        
        affective: types.array(types.model({
            indicator_id: types.number,
            grade: types.string,
            remark: types.maybeNull(types.string),
        })),

        psychomotor: types.array(types.model({
            indicator_id: types.number,
            grade: types.string,
            remark: types.maybeNull(types.string),
        }))
    })