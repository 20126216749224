// import React from 'react'
import { observer } from 'mobx-react';
import { display } from './index'

function Desktop({ children }) {
    if (display.isDesktop) {
        return children
    }
    return null
}

export default observer(Desktop)
