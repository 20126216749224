import './FinanceList.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from "classnames";
import { Row, Col } from 'antd';
import { formatDate } from '../lib/formatDate'
import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar'


import FinanceTransactionSearch from './FinanceTransactionSearch';

import moneyIcon from '../common/assets/money-bag.svg'
import {ExportCollection} from "../store/actions/ExportCollection";
import {ModalContext} from "../common/modal";
import { WalletTransactionsSummary } from "./FinanceTransactionsSummary";
import calculator from "../common/assets/calculator.svg";
import spreadsheet from "../common/assets/spreadsheet.svg";
import Loading from "../common/loading/Loading";
import Button from "../common/button";
import arrowDown from "../common/assets/arrow-down-white.svg";
import Collection from "../common/Collection";

const columns = [
    { key: 'trans_ref', render: 'Receipt No.' },
    { key: 'transaction_date', render: 'Trans. Date' },
    { key: 'payment_method_name', render: 'Pay. Method' },
    { key: 'credit', render: 'Credit' },
    { key: 'debit', render: 'Debit' },
    { key: 'student_name', render: 'Student' },
    { key: 'created_by_name', render: 'Recorded By' },
    { key: 'created_at', render: 'Creation Date' },
    { key: 'note', render: 'Note' },
]
const defaultColumns = [
    'trans_ref', 'transaction_date', 'payment_method_name',
    'credit', 'debit', 'student_name'
]

@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportWalletTransactionListAction = ExportCollection.create({})
        this.state = { exportWalletTransactionListAction }
    }

    initiateWalletTransactionsSpreadsheetDownload = () => {
        const { exportWalletTransactionListAction } = this.state
        const {store} = this.props;
        const { walletTransactionStore: {selectedItemKeys, downloadWalletTransactionListSpreadsheet}} = store

        exportWalletTransactionListAction.setToGetItems(selectedItemKeys.toJSON())
        downloadWalletTransactionListSpreadsheet(exportWalletTransactionListAction)
    }

    render() {
        const { exportWalletTransactionListAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { walletTransactionStore: {selectedItemKeys}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedItemKeys.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                context.setContent(
                                    `Finance Summary for ${selectedItemKeys.length} Transactions`,
                                    <WalletTransactionsSummary walletTransactionIDs={selectedItemKeys} />,
                                    true
                                )
                            }}
                        >
                            <img src={calculator} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Transactions Summary
                        </button>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                this.initiateWalletTransactionsSpreadsheetDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheet} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

const WalletTransactions = inject("store")(
    observer(({ store }) => {
        const {
            walletTransactionStore: settings,
            view: {
                openFinanceTransactionPageById,
                setBackPage,
                getExpenseAccountTransactionsPage
            },
            formatCurrency
        } = store

        const {walletTransactionStore: {selectedItemKeys, searchResults}} = store

        const tableData = searchResults
            .map((walletTransaction) => {
                const {
                    id,
                    attributes: {
                        credit,
                        debit,
                        created_at
                    },
                    financeTransaction: {
                        id: financeTransactionID,
                        attributes: {
                            trans_ref,
                            transaction_date,
                            payment_method_name,
                            created_by_name,
                            note
                        },
                        studentName: student_name
                    },
                    student: {
                        id: studentID,
                    }
                } = walletTransaction
                
                return {
                    id,
                    transaction_date: formatDate(transaction_date),
                    payment_method_name,
                    credit: formatCurrency(credit),
                    debit: formatCurrency(debit),
                    note,
                    trans_ref: (
                        <a
                            href={`/finance/transactions/${financeTransactionID}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Wallet Transactions', getExpenseAccountTransactionsPage())
                                openFinanceTransactionPageById(financeTransactionID)
                            }}
                        >
                            {trans_ref}
                        </a>
                    ),
                    student_name: (
                        <a
                            href={`/students/${studentID}`}
                            onClick={(e) => {
                                e.preventDefault()
                                store.view.openStudentPageById(studentID)
                            }}
                        >
                            {student_name}
                        </a>
                    ),
                    created_by_name,
                    created_at: formatDate(created_at)
                }
            })
        
        
        const tableSettings = {
            tableData,
            columns,
            defaultColumns,
            tableProps: {}
        }
        
        const buildMobileItem = walletTransaction => (
            <WalletTransactionsItem key={walletTransaction.id} walletTransaction={walletTransaction} />
        )
        
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">
                        <ActionsButton className="utils-prepend" />
                        {Boolean(selectedItemKeys.length) && (`${selectedItemKeys.length} selected`)}
                    </div>
                    {collectionSummaryText}
                    <div>&nbsp;</div>
                </div>
            </>
        )
        
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={moneyIcon} alt="" />
                        <span className="text-small utils-append text--uppercase">Wallet Transactions</span>
                    </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12}>
                        <ActionsButton fullwidth />
                    </Col>
                    <Col span={12}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )
        
        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={FinanceTransactionSearch}
            />
        )
    }))



export default WalletTransactions

const WalletTransactionsItem = inject("store")(
    observer(({ store, walletTransaction }) => {
        if (!walletTransaction.financeTransaction.student) return null
        const { view: { openStudentPageById, openFinanceTransactionPageById }, formatCurrency } = store
        const {
            id,
            attributes: {
                credit,
                debit,
            },
            financeTransaction: {
                attributes: {
                    trans_ref,
                    transaction_date,
                    payment_method_name,
                },
                student: {
                    id: student_id,
                    attributes: { photo_url, gender },
                    studentName
                },
            }
        } = walletTransaction
        return (
            <Card key={id} className="mb-1">
                <Row gutter={15} type="flex" className="mb-1">
                    <Col span={4}>
                        <Avatar
                            type={AVI_TYPES.STUDENT}
                            src={photo_url}
                            sex={gender && gender[0]}
                            small
                        />
                    </Col>
                    <Col span={20}>
                        <a
                            href={`/students/${student_id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openStudentPageById(student_id)
                            }}
                        >
                            {studentName}
                        </a>
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={8} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Receipt No.</div>
                        <a
                            href={`/finance/transactions/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openFinanceTransactionPageById(id)
                            }}
                        >
                            {trans_ref}
                        </a>
                    </Col>
                    <Col span={16} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Trans. Date</div>
                        <div>{formatDate(transaction_date)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Credit</div>
                        <div>{formatCurrency(credit)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Debit</div>
                        <div>{formatCurrency(debit)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Pay Method</div>
                        <div>{payment_method_name}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)