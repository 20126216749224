import {applySnapshot, types} from 'mobx-state-tree';

export const ExportCollection = types.model({
    student_ids: types.array(types.string),
    employee_ids: types.array(types.string),
    guardian_ids: types.array(types.string),
    
    item_ids: types.array(types.string),
    
    client_state: types.optional(types.model({
        isSaving: types.optional(types.boolean, false)
    }), {})
})
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        }
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },
        setToGetStudents(studentIDs){
            applySnapshot(self, {student_ids: studentIDs})
        },
        setToGetEmployees(employeeIDs){
            applySnapshot(self, {employee_ids: employeeIDs})
        },
        setToGetGuardians(guardianIDs){
            applySnapshot(self, {guardian_ids: guardianIDs})
        },
        setToGetItems(guardianIDs){
            applySnapshot(self, {item_ids: guardianIDs})
        }
    }))