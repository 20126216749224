/* eslint-disable react/no-multi-comp */
import "./StudentListSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import { Row, Col, Checkbox } from 'antd';

import { StudentSearch, statusOptions } from "../store/models/StudentSearch"

import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import ComparatorInput from "../common/form/ComparatorInput";
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { genderOptions } from '../store/models/Gender';
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'
import { titleCase } from '../lib/titleCase'
import MultiselectInput from "../common/form/MultiselectInput";

import { dateRangeConverter } from '../lib/formatDate';

import filter from './assets/filter-blue.svg'


const formModel = new MstForm(StudentSearch, {
    query: new Field(converters.string, {
        controlled: controlled.string
    }),
    status: new Field(converters.string, {
        controlled: controlled.object
    }),
    gender: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    course: new Field(converters.string, {
        controlled: controlled.object
    }),
    batch: new Field(converters.string, {
        controlled: controlled.object
    }),
    due_fees_balance: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    wallet_balance: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    admission_period: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    date_of_birth_period: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    category_ids: new Field(converters.stringArray, {
        controlled: controlled.object
    }),
})

const QueryField = observer(({ formState }) => {
    const query = formState.field("query")
    return (
        <Label text="Direct Search">
            <TextInput
                placeholder="Name, Admin No, Phone number, Email Address"
                {...query.inputProps}
            />
        </Label>
    )
})

const StatusField = observer(({ formState }) => {
    const status = formState.field("status")
    return (
        <Label text="Status">
            <SelectInput
                {...status.inputProps}
                placeholder="Select status"
                options={statusOptions.map(option => {
                    if (option === 'active') return { text: 'Enrolled', value: option };
                    if (option === 'graduated') return { text: 'Graduated', value: option };
                    if (option === 'dropped') return { text: 'Dropped', value: option };
                    return { text: option, value: option };
                })}
            />
        </Label>
    )
})

const BatchField = inject("store")(
    observer(({ store, formState }) => {
        const { meta: { batchesWithStudents } } = store
        const options = []
        batchesWithStudents.forEach(({ id, attributes: { name } }) => {
            options.push({ text: name, value: id })
        })
        const batch = formState.field("batch")
        return (
            <Label text="Batch">
                <SelectInput
                    {...batch.inputProps}
                    placeholder="Select batch"
                    options={options}
                    defaultOptionText="All batches"
                />
            </Label>
        )
    })
)

const ClassField = inject("store")(
    observer(({ store, formState }) => {
        const { courseStore: {courses} } = store
        const options = []
        courses.forEach(({ id, attributes }) => {
            options.push({ text: attributes.name, value: id })
        })
        const course = formState.field("course")
        return (
            <Label text="Class">
                <SelectInput
                    {...course.inputProps}
                    placeholder="Select class"
                    options={options}
                    defaultOptionText="All classes"
                />
            </Label>
        )
    })
)

const GenderField = observer(({ formState }) => {
    const gender = formState.field("gender")
    return (
        <Label text="Gender">
            <SelectInput
                {...gender.inputProps}
                placeholder="Select gender"
                options={genderOptions.map(option => ({ text: titleCase(option), value: option }))}
                defaultOptionText="All genders"
            />
        </Label>
    )
})

const DueBalanceField = observer(({ formState }) => {
    const due_fees_balance = formState.field("due_fees_balance")
    return (
        <Label text="Due Ballance">
            <ComparatorInput {...due_fees_balance.inputProps} />
        </Label>
    )
})

const WalletBalanceField = observer(({ formState }) => {
    const wallet_balance = formState.field("wallet_balance")
    return (
        <Label text="Wallet Ballance">
            <ComparatorInput {...wallet_balance.inputProps} />
        </Label>
    )
})

const AdmissionPeriodField = observer(({ formState }) => {
    const admission_period = formState.field("admission_period")
    return (
        <Label text="Date Admitted">
            <DateRangeInput label="Date Admitted" {...admission_period.inputProps} />
        </Label>
    )
})

const DateofBirthField = observer(({ formState }) => {
    const date_of_birth_period = formState.field("date_of_birth_period")
    return (
        <Label text="Date of Birth">
            <DateRangeInput showOptions={false} label="Date of Birth" {...date_of_birth_period.inputProps} />
        </Label>
    )
})

const StudentCategoryField = inject("store")
    (observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { student_categories }
            }
        } = store
        const options = []
        student_categories.forEach(({ id: value, attributes }) => {
            const { name: text } = attributes
            options.push({ text, value })
        })
        const category_ids = formState.field("category_ids")
        return (
            <Label text="Student Category">
                <MultiselectInput
                    {...category_ids.inputProps}
                    options={options}
                />
            </Label>
        )
    }))

export default function StudentListSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" />
        Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { studentStore: { searchFormInstance } } = props
        
        const formInstance = StudentSearch.create(searchFormInstance.toJSON())
        this.state = {formInstance}
        this.formState = formModel.state(formInstance)
    }

    render() {
        const { studentStore: { updateSearch} } = this.props
        const { formInstance } = this.state
        
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(formInstance.toJSON())}
            >
                <form>
                    <QueryField formState={this.formState} />
                    <StatusField formState={this.formState} />
                    <BatchField formState={this.formState} />
                    <ClassField formState={this.formState} />
                    <GenderField formState={this.formState} />
                    <DueBalanceField formState={this.formState} />
                    <WalletBalanceField formState={this.formState} />
                    <AdmissionPeriodField formState={this.formState} />
                    <DateofBirthField formState={this.formState} />
                    <StudentCategoryField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { studentStore: { searchFormInstance } } = props

        const formInstance = StudentSearch.create(searchFormInstance.toJSON())
        this.state = {formInstance}
        this.formState = formModel.state(formInstance)
    }

    render() {
        const { studentStore: { updateSearch, search} } = this.props
        const { formInstance } = this.state
        return (
            <form className="StudentListSearch-TabletForm">
                <QueryField formState={this.formState} />
                <BatchField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(formInstance.toJSON())}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <QueryField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <BatchField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <StatusField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <ClassField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <GenderField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DueBalanceField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <WalletBalanceField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <AdmissionPeriodField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DateofBirthField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <StudentCategoryField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

function MoreFields({ fields, formState }) {
    const moreFields = Array
        .from(fields)
        .map(([key, value]) => {
            // eslint-disable-next-line no-shadow
            const Field = key
            const { hidden, label } = value
            return hidden ? null : <Field key={label} formState={formState} />
        })
    return (
        <div className="StudentListSearch-MoreFields">
            {moreFields}
        </div>
    )
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const {studentStore: { searchFormInstance }} = this.props
        
        this.state = {
            moreFields: new Map([
                [ClassField, { hidden: !searchFormInstance.course, label: 'Class' }],
                [GenderField, { hidden: !searchFormInstance.gender, label: 'Gender' }],
                [DueBalanceField, { hidden: !searchFormInstance.due_fees_balance, label: 'Due Balance' }],
                [WalletBalanceField, { hidden: !searchFormInstance.wallet_balance, label: 'Wallet Balance' }],
                [AdmissionPeriodField, { hidden: !searchFormInstance.admission_period, label: 'Date Admitted' }],
                [DateofBirthField, { hidden: !searchFormInstance.date_of_birth_period, label: 'Date of Birth' }],
                [StudentCategoryField, { hidden: !searchFormInstance.category_ids.length, label: 'Student Category' }],
            ])
        }
        this.formState = formModel.state(searchFormInstance)
    }

    toggleFieldHiddenState = (fieldKey, hiddenState) => {
        const { moreFields } = this.state
        const updatedFields = Array
            .from(moreFields)
            .map(([key, value]) => {
                const { label, hidden } = value
                return [
                    key,
                    { label, hidden: (fieldKey === key ? hiddenState : hidden) }
                ]
            })
        this.setState({ moreFields: updatedFields })
    }

    render() {
        const { studentStore } = this.props
        const { moreFields } = this.state
        
        const { search, isLoading } = studentStore
        
        const popoverContent = Array
            .from(moreFields)
            .map(([key, value], idx) => {
                const { hidden, label } = value
                return (
                    <React.Fragment key={label}>
                        {idx > 0 && <div className="divider" />}
                        <div className="Button Button--clear">
                            <Checkbox
                                checked={!hidden}
                                onChange={() => this.toggleFieldHiddenState(key, !hidden)}
                            >{label}
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            })

        return (
            <form className="StudentListSearch-DesktopForm">
                <QueryField formState={this.formState} />
                <StatusField formState={this.formState} />
                <BatchField formState={this.formState} />
                <Button
                    buttonType="outline"
                    fullwidth
                    popover={{ content: popoverContent }}
                >
                    <img className="utils-prepend" src={filter} alt="" />
                    More Filters
                </Button>
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
                <MoreFields fields={moreFields} formState={this.formState} />
            </form>
        )
    }
}

StudentListSearch.MobileForm = MobileForm
StudentListSearch.TabletForm = TabletForm
StudentListSearch.DesktopForm = DesktopForm
