import { types, applySnapshot } from "mobx-state-tree"
import {ActionPattern} from "../patterns/ActionPattern";
export const CreateSubject = types.compose(
    ActionPattern({}),
    types
        .model("CreateSubject", {
            id: types.maybeNull(types.string),
            subject_base_id: types.optional(types.string, ""),
            batch_id: types.optional(types.string, ""),
            is_elective: types.maybeNull(types.boolean),
        })
        .actions(self => ({
            setFormInstance(subject) {
                const { id, subjectBase: { id: subject_base_id }, isElective: is_elective, batch: { id: batch_id } } = subject
                applySnapshot(self, { id, subject_base_id, batch_id, is_elective })
            },
        }))
);

export const RoleAssignment = types
    .model("RoleAssignment", {
        subject_id: types.optional(types.string, ""),
        employee_id: types.optional(types.string, ""),
        special_role_id: types.optional(types.string, ""),
    })
    .actions(self => ({
        setFormInstance(assignment) {
            const { employee_id, special_role_id, subject_id } = assignment
            applySnapshot(self, { employee_id, special_role_id, subject_id })
        },
    }))

export const CreateSubjectBase = types.model("CreateSubjectBase", {
    name: "",
    short_name: "",
});
