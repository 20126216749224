import { observer } from 'mobx-react';
import { display } from './index'

function NotDesktop({ children }) {
    if (display.isNotDesktop) {
        return children
    }
    return null
}

export default observer(NotDesktop)