import { types } from "mobx-state-tree"


export const StudentReportCardComment = types.model({
    student_id: types.string,
    special_role_id: types.string,
    content: types.maybe(types.string),

    client_state: types.optional(types.model({
        isSaving: types.optional(types.boolean, false)
    }), {})
}).views(self => ({
    get isSaving() {
        return self.client_state.isSaving
    }
})).actions(self => ({
    markSaving(flag){
        self.client_state.isSaving = flag
    }
}))