import { applySnapshot, types } from 'mobx-state-tree'

export const Batch = types.model({
    course_id: "",
    arm: "",
    academic_session_id: "",
}).actions(self => ({
    setFormInstance(batchModel) {
        const { attributes: {arm}, course: {id: course_id}, academicSession: {id: academic_session_id}} = batchModel
        applySnapshot(self, { arm, course_id, academic_session_id })
    },
}))

export const BatchRoleAssignment = types
    .model("RoleAssignment", {
        batch_id: types.optional(types.string, ""),
        employee_id: types.optional(types.string, ""),
        special_role_id: types.optional(types.string, ""),
    })
    .actions(self => ({
        setFormInstance(assignment) {
            const { employee_id, special_role_id, batch_id} = assignment
            applySnapshot(self, { employee_id, special_role_id, batch_id })
        },
    }))