import { types, getParent, flow, destroy } from "mobx-state-tree"
import { filterType } from "./helpers/filterType"
import { Course } from './models/Course';
import { CourseSet } from './models/CourseSet';
import { CreateCourse } from "./actions/Course";
import { LoadingPattern } from "./patterns/LoadingPattern";
import { validationErrorsHandler } from "./helpers/errors";

export const CourseStore = types.compose(
    LoadingPattern({
        isLoading: false,
        isCourseListLoading: false,
        isCreateCourseLoading: false,
        isUpdateCourseLoading: false,
        isDeleteCourseLoading: false,
    }),
    types
        .model("CourseStore", {
            courses: types.map(Course),
            courseSets: types.map(CourseSet),
            addCourseFormInstance: types.optional(CreateCourse, {}),
        })
        .views(self => ({
            get bluebic() {
                return getParent(self)
            },
        }))
        .actions(self => {
            function updateCourses(courses) {
                courses.forEach((course) => self.courses.put(course))
            }

            function updateCourseSets(courseSets) {
                courseSets.forEach(json => self.courseSets.put(json))
            }

            function onUpdate(included) {
                filterType("course", included, updateCourses)
                filterType("course_set", included, updateCourseSets)
            }

            const getCourseSets = flow(function* getCourseSets() {
                try {
                    self.markLoading(true)
                    const { data } = yield self.bluebic.courseSetService.index()
                    onUpdate(data)
                    self.markLoading(false)
                } catch (err) {
                    self.markLoading(false)
                    console.error(`Failed to get course sets`, err)
                }
            })

            const getCourses = flow(function* getCourses() {
                try {
                    self.markLoading("isCourseListLoading", true)
                    const { data } = yield self.bluebic.courseSetService.getCourses()
                    onUpdate(data)
                    self.markLoading("isCourseListLoading", false)
                } catch (err) {
                    self.markLoading("isCourseListLoading", false)
                }
            })

            const createCourse = flow(function* createCourse(course) {
                try {
                    self.markLoading("isCreateCourseLoading", true)
                    const { data } = yield self.bluebic.courseSetService.createCourse(course)
                    onUpdate([data])
                    self.markLoading("isCreateCourseLoading", false)
                    return { data }
                } catch (err) {
                    self.markLoading("isCreateCourseLoading", false)
                    return validationErrorsHandler(err)
                }
            })

            const updateCourse = flow(function* updateCourse(course, id) {
                try {
                    self.markLoading("isUpdateCourseLoading", true)
                    const { data } = yield self.bluebic.courseSetService.updateCourse(course, id)
                    onUpdate([data])
                    self.markLoading("isUpdateCourseLoading", false)
                    return { data }
                } catch (err) {
                    self.markLoading("isUpdateCourseLoading", false)
                    return validationErrorsHandler(err)
                }
            })

            const deleteCourse = flow(function* deleteCourse(course_id) {
                try {
                    self.markLoading("isDeleteCourseLoading", true)
                    const { data } = yield self.bluebic.courseSetService.deleteCourse(course_id)
                    destroy(self.courses.get(course_id))
                    self.markLoading("isDeleteCourseLoading", false)
                    return { data }
                } catch (err) {
                    self.markLoading("isDeleteCourseLoading", false)
                    return validationErrorsHandler(err)
                }
            })


            return {
                getCourseSets,
                onUpdate,
                getCourses,
                createCourse,
                updateCourse,
                deleteCourse,
            }
        })
);
