import './MobilePageHeader.css'

import React from 'react'
import NotDesktop from '../common/display/NotDesktop';
import Mobile from '../common/display/Mobile';

export default function MobilePageHeader({ children, tablet = false }) {
    if (tablet) {
        return (
            <NotDesktop>
                <div className="MobilePageHeader">
                    {children}
                </div>
            </NotDesktop>
        )
    }
    return (
        <Mobile>
            <div className="MobilePageHeader">
                {children}
            </div>
        </Mobile>
    )
}
