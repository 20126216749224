import './SubjectAssessment.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col, Popover, Popconfirm, Icon } from 'antd'
import { reaction, values } from 'mobx';
import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import { ModalContext } from '../common/modal'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import Pagination from '../common/pagination/Pagination';
import MobilePageHeader from '../containers/MobilePageHeader';
import { scrollToTop } from '../lib/scrollToTop';
import EmptyList from '../common/empty_list/empty_list';
import MobileListLoading from '../common/loading/MobileListLoading';
import AddAssessment from './AddAssessment'
import MobileConfirmDialog from '../common/mobile_confirm_dialog';

import add from './assets/add-blue.svg'
import addWhite from './assets/add-white.svg'
import more_vert from './assets/more-vert.svg';
import SubjectPolicy from "../policies/SubjectPolicy";

@inject('store')
@observer
class MoreButton extends React.Component{
    render(){
        const { assessment, store } = this.props
        const  {meta: {currentUser},subjectStore : { deleteAssessmentById }, view:{selectedSubject }} = store
        const subjectPolicy = new SubjectPolicy(currentUser, selectedSubject)

        function handleDelete() {
            return deleteAssessmentById(assessment)
        }

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <div className="SubjectAssessment-popover SubjectAssessment-popover--more">
                        <div
                            onClick={() => context.setContent(
                                'Edit',
                                <AddAssessment onCancel={context.closeModal} editMode assessmentId={assessment} />,
                                true
                            )}
                            disabled={!subjectPolicy.can_manage_assessments}
                            title={subjectPolicy.can_manage_assessments ? undefined : 'No permission.'}
                        >
                            Edit
                        </div>
                        <Mobile>
                            <div
                                disabled={!subjectPolicy.can_manage_assessments}
                                title={subjectPolicy.can_manage_assessments? undefined : 'No permission.'}
                                onClick={() => context.setContent(
                                    "Deleting Assessment...",
                                    <MobileConfirmDialog
                                        handleConfirm={() => {
                                            handleDelete()
                                            context.closeModal()
                                        }}
                                        handleCancel={context.closeModal}
                                    />,
                                    true
                                )}
                            >
                                Delete
                            </div>
                        </Mobile>
                        <NotMobile>
                            <Popconfirm
                                disabled={!subjectPolicy.can_manage_assessments}
                                title="Are you sure you want to delete this assessment?"
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={handleDelete}
                            >
                                <div>Delete</div>
                            </Popconfirm>
                        </NotMobile>
                    </div>
                )}
            </ModalContext.Consumer>
        )
        
        return (
            <Popover placement="bottomRight" content={popover} overlayClassName="override-popover">
                <div className="SubjectAssessment-more">
                    <img src={more_vert} alt="more_vert" />
                </div>
            </Popover>
        )
        
    }
}

@inject('store','display')
@observer
class CreateAssessmentBtn extends React.Component{
    
    render(){
        const {store, display} = this.props
        const  {meta: {currentUser}, view:{selectedSubject}} = store
        const subjectPolicy = new SubjectPolicy(currentUser, selectedSubject)
        
        return (
            <ModalContext.Consumer>
                {(context) => (
                    <Button
                        buttonType={display.isMobile ? 'flat': 'primary'}
                        fullwidth={display.isMobile}
                        disabled={!subjectPolicy.can_manage_assessments}
                        title={subjectPolicy.can_manage_assessments ? undefined : 'No permission.'}
                        onClick={() => context.setContent(
                            'New Assessment',
                            <AddAssessment onCancel={context.closeModal} />,
                            true
                        )}
                    >
                        <img className="utils-prepend" src={display.isMobile ? add : addWhite} alt="" />
                        Add Assessment
                    </Button>
                )}
            </ModalContext.Consumer>
        )
    }
}

export {CreateAssessmentBtn}

@inject("store")
@observer
class SubjectAssessment extends Component {
    async componentWillMount() {
        const {store: {view :{selectedSubject}}} = this.props
        await selectedSubject.getAssessments()
        
        reaction(
            () => selectedSubject.isLoading,
            loading => {
                if (loading) scrollToTop()
            }
        )
    }

    get listSummary() {
        const {store: {view :{selectedSubject}}} = this.props
        const { assessmentsMeta, assessments, searchAssessmentInstance } = selectedSubject

        const totalCount = assessmentsMeta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = searchAssessmentInstance.per_page * searchAssessmentInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - assessments.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }
    
    render() {
        const {store: {view :{selectedSubject, openAssessmentPageById}}} = this.props
        const { assessmentsMeta, isLoading, assessments, searchAssessmentInstance } = selectedSubject

        const columns = [
            { key: 'category', render: 'Category' },
            { key: 'name', render: 'Name' },
            { key: 'points', render: 'Points' },
            { key: 'due_date', render: 'Due Date' },
            { key: 'publish_scores', render: 'Publish Scores' },
            { key: 'include_in_final_grade', render: 'Final Grade?' },
            { key: 'options', render: '' },
        ]
        const tableData = values(assessments)
            .map(({id, name, describePoints, dueDate, finalGrade, publishScores, assessmentCategoryName}) =>({
                    name: (
                        <a
                            href={`/assessments/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openAssessmentPageById(id)
                            }}
                        >
                            {name}
                        </a>
                    ),
                    points: describePoints,
                    due_date: dueDate,
                    category: assessmentCategoryName,
                    publish_scores: publishScores ? "YES" : "NO",
                    include_in_final_grade: finalGrade ? "YES" : "NO",
                    options: (<MoreButton assessment={id} />),
                })
            )
        const pagination = (
            <Pagination
                pageCount={assessmentsMeta.total_pages}
                currentPage={searchAssessmentInstance.page}
                onClickPrev={searchAssessmentInstance.prevPage}
                onClickNext={searchAssessmentInstance.nextPage}
            />
        )

        return (
            <div className="SubjectAssessment">
                <NotMobile>
                    <div className="flex-row flex-spaceBetween">
                        {this.listSummary}
                        <CreateAssessmentBtn />
                    </div>
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        data={tableData}
                        onError={e => console.error(e)}
                        loading={isLoading}
                        footer={pagination}
                        responsive
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <span className="text-small utils-append text--uppercase">Assessments List</span>
                            </Col>
                            <Col span={12}>
                                <CreateAssessmentBtn />
                            </Col>
                        </Row>
                    </MobilePageHeader>

                    {!isLoading && ( values(assessments).length === 0) && <EmptyList />}

                    {(isLoading ||  values(assessments).length > 0) && (
                        <>
                            <div className="flex-row flex-spaceBetween">
                                {this.listSummary}
                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {values(assessments).map(assessment => <SubjectAssessmentItem id={assessment.id} key={assessment.id} assessment={assessment} />)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default SubjectAssessment

const SubjectAssessmentItem = inject("store")(
    observer(
        ({ assessment, store }) => {
            const {view: {openAssessmentPageById}} = store
            const { id, name, abbreviation, describePoints, dueDate, finalGrade, publishScores, assessmentCategoryName } = assessment
            
            return (
                <Card className="SubjectAssessmentItem mb-1">
                    <div>
                        <a
                            href={`/assessments/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openAssessmentPageById(id)
                            }}
                        >
                            {name}
                        </a>
                    </div>
                    <div><MoreButton assessment={id} /></div>
                    <div>
                        <span>Due Date</span>
                        {dueDate}
                    </div>
                    <div className="assCatBlock">
                        <span>Category</span>
                        {assessmentCategoryName}
                    </div>
                    <div>
                        <span>Points</span>
                        {describePoints}
                    </div>
                    <div>
                        <span>Publish Scores?</span>
                        {publishScores ? "YES" : "NO"}
                    </div>
                </Card>
            )
        }
    ))