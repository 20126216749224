import { types } from "mobx-state-tree"
import { ReportCardGroup } from "./ReportCardGroup";
import {Student} from "./Student";

export const StudentReportCardDatum = types
    .model("StudentReportCardDatum", {
        id: types.identifier,
        type: "student_report_card_datum",
        attributes: types.model({
            student_id: types.number,
            report_card_group_id: types.number
        }),
        relationships: types.model({
            student: types.model({
                data: types.model({
                    id: types.late(() => types.reference(Student)),
                    type: types.literal('student')
                })
            }),
            report_card_group: types.model({
                data: types.model({
                    id: types.late(() => types.reference(ReportCardGroup)),
                    type: types.literal('report_card_group')
                })
            }),
        })
    })
    .views(self => ({
        get reportCardGroup() {
            return self.relationships.report_card_group.data.id
        }
    }))