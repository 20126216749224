import { types } from "mobx-state-tree"
import {Course} from "./Course";
import {AcademicSession} from "./AcademicSession";
import {BehaviouralDomainGroup} from "./BehaviouralDomainGroup";
import {SpecialRoleAssignment} from "./SpecialRoleAssignment";

export const CourseSet = types
    .model("CourseSet", {
        id: types.identifier,
        type: "course_set",
        attributes: types.model({
            name: "",
            academic_session_id: types.number,
            course_id: types.number,
            psychomotor_domain_group_id: types.maybeNull(types.number),
            affective_domain_group_id: types.maybeNull(types.number)
        }),
        relationships: types.model({
            course: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.safeReference(Course))
                }))
            }),
            academic_session: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.safeReference(AcademicSession))
                }))
            }),
            affective_domain_group: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.safeReference(BehaviouralDomainGroup))
                }))
            }),
            psychomotor_domain_group: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.safeReference(BehaviouralDomainGroup))
                }))
            }),
            special_role_assignments: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(SpecialRoleAssignment))
                }))
            }),
        })
    })
    .views(self => ({
        get affectiveDomainGroup() {
            const a = self.relationships.affective_domain_group.data
            if (a) return a.id
            return undefined
        },
        get psychomotorDomainGroup() {
            const a = self.relationships.psychomotor_domain_group.data
            if (a) return a.id
            return undefined
        },
    }))