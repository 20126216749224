import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd';
import Card from '../common/card';
import Collection from '../common/Collection'

import MessageCollectionSearch from './MessageCollectionSearch';

import newspaper from '../common/assets/newspaper.svg';
import {formatDate, fullDateTimeFormat} from "../lib/formatDate";
import MaybeBackPage from "../containers/MaybeBackPage";

const columns = [
    { key: 'units', render: 'SMS Units' },
    { key: 'amount_paid', render: 'Amount Paid' },
    { key: 'note', render: 'Note' },
    { key: 'created_at', render: 'Created At' }
]

@inject('store', 'display')
@observer
class SmsTopupCollection extends Component{

    render(){
        const { store } = this.props;
        const { smsTopupStore, formatCurrency, meta: {currentInstitution: {smsMaster}}  } = store
        const { searchResults } = smsTopupStore
        
        const tableData = searchResults
            .map((curSearchResult) => {
                const { id, attributes: { units, amount_paid, note, created_at } } = curSearchResult;
                return ({
                    id,
                    units,
                    amount_paid: formatCurrency(amount_paid),
                    note,
                    created_at: formatDate(created_at, fullDateTimeFormat)
                })
            })
        const tableProps = { onRowSelectionChange: null }
        const tableSettings = {
            tableData,
            columns,
            tableProps
        }
        const buildMobileItem = (smsTopUp) => (
            <SmsTopupCollectionItem id={smsTopUp.id} key={smsTopUp.id} smsTopUp={smsTopUp} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="text-h2 text--primary">SMS Unit Balance: {smsMaster.unitBalance}</div>
                    {collectionSummaryText}
                    <div>&nbsp;</div>
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={newspaper} alt="" />
                        <span className="text-small utils-append text--uppercase">Sms Top-ups</span>
                    </Col>
                    <Col span={12}>
                        <div>&nbsp;</div>
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={18} offset={3}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <MaybeBackPage>
                <Collection
                    settings={smsTopupStore}
                    tableSettings={tableSettings}
                    buildHeader={buildHeaderComponent}
                    buildMobileHeader={buildMobileHeader}
                    buildMobileItem={buildMobileItem}
                    useSearchComponent={MessageCollectionSearch}
                />
            </MaybeBackPage>
        )
    }
}


export default SmsTopupCollection


const SmsTopupCollectionItem = inject("store")(
    observer(({store, smsTopUp}) => {
        const { formatCurrency } = store
        const { attributes: { units, amount_paid, note, created_at } } = smsTopUp
        
        return (
            <Card className="mb-1">
                <span>Units Added: {units}</span>
                <div className="flex-col flex-alignStart mt-1 mb-1">
                    <div className="">
                        <div>Amount Paid: {formatCurrency(amount_paid)}</div>
                        <div>Note: {note}</div>
                    </div>
                </div>
                
                <div className="flex-row  flex-spaceBetween">
                    <div className="">
                        <span className="text-small text--bold text--uppercase text--spacedLetters utils-prepend">
                            {formatDate(created_at, fullDateTimeFormat)}
                        </span>
                    </div>
                </div>
            </Card>
        )
    })
)