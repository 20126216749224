import {types, getParent, applySnapshot} from "mobx-state-tree"
import { Gender } from "./Gender"
import { dateRangeType } from "../types/dateRangeType";

export const statusOptions = ["active", "graduated", "dropped"]

export const StudentSearch = types
    .model("StudentSearch", {
        query: "",
        status: types.optional(
            types.enumeration("status", statusOptions),
            "active"
        ),
        gender: types.maybe(Gender),
        admission_period: types.maybe(dateRangeType),
        date_of_birth_period: types.maybe(dateRangeType),
        course: "",
        batch: "",
        category_ids: types.array(types.string),
        due_fees_balance: types.maybe(types.string),
        wallet_balance: types.maybe(types.string),
        include_total_ids: true,
        page: 1,
        per_page: 20
    })
    .actions(self => ({
        nextPage() {
            const { meta } = getParent(self)
            const { total_pages } = meta
            if (self.page < total_pages) {
                self.page += 1
            }
        },
        prevPage() {
            if (self.page > 1) {
                self.page -= 1
            }
        },

        goToFirstPage() {
            self.page = 1
        },
        
        applyData(snapshot){
            applySnapshot(self, snapshot)
        }
    }))
