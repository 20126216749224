import { types, applySnapshot } from "mobx-state-tree"
import { SpecialRole } from '../models/SpecialRole';

export const roundingStrategyOptions = ["rounded", "truncated"]
export const includeAttendanceOptions = ["no", "first_term", "second_term", "third_term", "full_session", "specific_date_range"]

export const cognitiveColumnTypeOptions = ["ReportCardTemplate::CognitiveColumn::AssessmentCategoryGrade", "ReportCardTemplate::CognitiveColumn::AggregateColumn",
    "ReportCardTemplate::CognitiveColumn::AverageGrade", "ReportCardTemplate::CognitiveColumn::Position",
    "ReportCardTemplate::CognitiveColumn::StudentCount", "ReportCardTemplate::CognitiveColumn::Teachers"]

export const aggregatableColumnTypes = ["ReportCardTemplate::CognitiveColumn::AssessmentCategoryGrade", 
    "ReportCardTemplate::CognitiveColumn::AggregateColumn"]

export const aggregateColumnShowAsOptions = ["percentage", "equivalent_over", "letter_grade",  "letter_grade_remark", "equivalent_over_possible_points"]

export const assessmentCategoryForOptions = ["first_term", "second_term", "third_term", "session", "specific_date_range"]


export const positionInOptions = ["batch", "class"]

export const pdfTemplateOptions = ["default", "gab", "gpa", "hmus", "lcs", "ngs", "oyede", "phenomville", "stanps", "tcs",
    "torch", "tosvvi", "tosvvips", "mms"]




export const CreateCognitiveColumnWeight = types
    .model('CreateCognitiveColumnWeight', {
        id: types.string,
        column_to_aggregate_id: types.maybeNull(types.string),
        weight: types.maybeNull(types.number),
        _destroy: types.optional(types.boolean, false)
    })
    .actions(self => ({

        // TODO
        setFormInstanceFromFullCalendarFetchInfo(viewInfo) {
            const { timeZone, start, end, startStr, endStr } = viewInfo
            applySnapshot(self, {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
                all_events: true
            })
        }


    }))

export const CreateCognitiveColumn = types
    .model('CreateCognitiveColumn', {
        id: types.string,
        position: types.number, // For sorting
        type: types.enumeration(cognitiveColumnTypeOptions),
        show_on_report: types.optional(types.boolean, true),
        heading: types.maybeNull(types.string),
        is_final_column: types.optional(types.boolean, true),
        is_primary_column: types.optional(types.boolean, true),
        
        aggregate_column_show_as: types.optional(types.enumeration(aggregateColumnShowAsOptions), aggregateColumnShowAsOptions[0]),
        aggregate_column_over: types.maybeNull(types.number),
        
        assessment_category_id: types.maybeNull(types.string),
        assessment_category_show_as: types.optional(types.enumeration(aggregateColumnShowAsOptions), aggregateColumnShowAsOptions[0]),
        assessment_category_for: types.optional(types.enumeration(assessmentCategoryForOptions), assessmentCategoryForOptions[0]),
        assessment_category_over: types.maybeNull(types.number),
        
        position_cognitive_column_id: types.maybeNull(types.string),
        position_in: types.optional(types.enumeration(positionInOptions), positionInOptions[0]),
        
        average_grade_cognitive_column_id: types.maybeNull(types.string),
        average_grade_in: types.optional(types.enumeration(positionInOptions), positionInOptions[0]),
        
        student_count_in: types.optional(types.enumeration(positionInOptions), positionInOptions[0]),
        
        column_weights_attributes: types.array(CreateCognitiveColumnWeight),
        
        _destroy: types.optional(types.boolean, false)
    })
    .actions(self => ({

        // TODO
        setFormInstanceFromFullCalendarFetchInfo(viewInfo) {
            const { timeZone, start, end, startStr, endStr } = viewInfo
            applySnapshot(self, {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
                all_events: true
            })
        }


    }))
    .views(self => ({
        isAggregatable() {
            aggregatableColumnTypes.includes(self.type)
        }
    }))



export const CreateReportCardTemplate = types
    .model("CreateReportCardTemplate", {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        title: types.maybeNull(types.string),
        shorten_numeric_value_by: types.optional(types.enumeration(roundingStrategyOptions), roundingStrategyOptions[0]),
        numeric_value_decimal_places: types.maybe(types.number),
        include_attendance: types.optional(types.enumeration(includeAttendanceOptions), includeAttendanceOptions[0]),
        include_affective_domain: types.optional(types.boolean, false),
        include_psychomotor_domain: types.optional(types.boolean, false),
        affective_domain_header: types.maybeNull(types.string),
        psychomotor_domain_header: types.maybeNull(types.string),
        include_signatures: types.optional(types.boolean, false),
        include_positions: types.optional(types.string, 'no'),
        grade_scale_id: types.maybeNull(types.string),
        watermark_image: types.maybeNull(types.string),
        header: types.maybeNull(types.string),
        footer: types.maybeNull(types.string),
        cognitive_columns_attributes: types.array(CreateCognitiveColumn),
        pdf_template: types.optional(types.enumeration(pdfTemplateOptions), pdfTemplateOptions[0]),
        comment_role_ids: types.array(types.reference(SpecialRole)),
    })
    .actions(self => ({
        
        
        // TODO
        setFormInstance(subject) {
            const { id, subjectBase: {id: subject_base_id}, isElective: is_elective, batch: {id: batch_id}} = subject
            applySnapshot(self, { id, subject_base_id, batch_id, is_elective })
        },
        
        
    }))