import React, {Component} from 'react'
import * as PropTypes from "prop-types";
import { inject } from 'mobx-react';
import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Button from '../common/button';
import { ModalContext } from '../common/modal'
import CreateBatchForm from './CreateBatchForm'

import addUser from '../common/assets/add-user-white.svg'
import addUserBlue from '../common/assets/add-user.svg'
import BatchPolicy from "../policies/BatchPolicy";

function handleNewBatch({ setContent, closeModal }) {
        return setContent(
            "New Batch",
            <CreateBatchForm closeModal={closeModal} />,
            true
        )
}

function NewBatch() {
    return (
        <ModalContext.Consumer>
            {(context) => (
                <NewBatchButton onClick={() => handleNewBatch(context)} />
            )}
        </ModalContext.Consumer>
    )
}

export default NewBatch

@inject('store')
class NewBatchButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired
    }
    
    render() {
        const {onClick, store: {meta: {currentUser}}} = this.props
        const batchPolicy = new BatchPolicy(currentUser, 'Batch')

        return (
            <>
                <Mobile>
                    <div
                        className="flex-justifyEnd Button touchable-highlight flex-row flex-alignCenter Button--flat Button--large text-p utils-fullwidth"
                        onClick={onClick}
                        disabled={!batchPolicy.can_create}
                        title={batchPolicy.can_create ? undefined : 'No permission.'}
                    >
                        <img src={addUserBlue} alt="" className="utils-prepend" />
                        Add New Batch
                    </div>
                </Mobile>
                <NotMobile>
                    <Button
                        onClick={onClick}
                        disabled={!batchPolicy.can_create}
                        title={batchPolicy.can_create ? undefined : 'No permission.'}
                    >
                        <img src={addUser} alt="" className="utils-prepend" />
                        Add New Batch
                    </Button>
                </NotMobile>
            </>
        )
    }
}

