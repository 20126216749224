import { types } from "mobx-state-tree"
import {Employee} from "./Employee";

export const StudentReportCardComment = types
    .model("StudentReportCardComment", {
        id: types.identifier,
        type: types.literal("student_report_card_comment"),
        attributes: types.maybe(types.model({
            student_id: types.number,
            special_role_id: types.number,
            comment: types.maybeNull(types.string),
            auto_comment: types.maybeNull(types.string),
            employee_ids: types.array(types.late(() => types.reference(Employee)))
        }))
    })