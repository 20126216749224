import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform';
import { stringifyQuery } from '../lib/stringifyQuery';

import searchJSON from '../json/subjects.json'
import showSubjectJSON from '../json/subject_data.json'
import createSubjectJSON from '../json/create_employee_success.json'
import editSubjectJSON from '../json/edit_employee_success.json'
import deleteSubjectJSON from '../json/delete_subject_success.json'
import unassignEmployeeJSON from '../json/unassign_employee_success.json'
import subjectScoresheetJSON from '../json/subject_scoresheet.json'
import assignStudentToSubjectJSON from '../json/assign_student_to_subject_success'


export function SubjectService(env) {
    async function search(subject_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("subject_search", subject_search)
            const { data } = await axios.get(`/api/v1/subjects?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showSubject(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/subjects/${id}`)
            return data
        }
        return showSubjectJSON
    }

    async function createSubject(subject) {
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/subjects`,
                { subject },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createSubjectJSON
    }

    async function editSubject(subject) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/subjects/${subject.id}`,
                { subject },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return editSubjectJSON
    }

    async function assignRole({subject_id, employee_id, special_role_id}) {
        const subject_role = {
            employee_id,
            special_role_id
        }
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/subjects/${subject_id}/roles`,
                { subject_role },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createSubjectJSON
    }

    async function updateElectiveSubjectRegistration(id, elective_students) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/subjects/${id}/elective_students`, { elective_students })
            return data
        }
        return assignStudentToSubjectJSON
    }

    async function deleteSubject(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/subjects/${id}`)
            return data
        }
        return deleteSubjectJSON
    }
    async function unassignRole(id, special_role_assignment_id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/subjects/${id}/roles/${special_role_assignment_id}`)
            return data
        }
        return unassignEmployeeJSON
    }

    async function getAssessments(subject_id, assessment_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("assessment_search", assessment_search)
            const { data } = await axios.get(`/api/v1/subjects/${subject_id}/assessments?${queryString}`)
            return data
        }
        return showSubjectJSON
    }

    async function createAssessment(assessment) {
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/subjects/${assessment.subject_id}/assessments`,
                { assessment },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createSubjectJSON
    }

    async function getScoresheet(id, term = '') {
        if (env !== TESTENV) {
            const { data } = await axios.get(
                `/api/v1/subjects/${id}/scoresheet?term=${term}`)
            return data
        }
        return subjectScoresheetJSON
    }


    return {
        createSubject,
        editSubject,
        search,
        showSubject,
        assignRole,
        deleteSubject,
        getScoresheet,
        unassignRole,
        getAssessments,
        createAssessment,
        updateElectiveSubjectRegistration
    }
}

export default SubjectService(TESTENV)