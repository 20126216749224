import './MultiselectInput.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Menu, Dropdown, Checkbox } from 'antd'
import select from './assets/select.svg'

const CheckboxGroup = Checkbox.Group

class MultiselectInput extends Component {
  state = { isDropdownOpen: false }

  static propTypes = {
    placeholder: PropTypes.string,
    // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // success: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string, value: PropTypes.string
    })).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // onBlur: PropTypes.func.isRequired,
    // onFocus: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  static defaultProps = {
    className: null,
    placeholder: "Please select",
    style: null,
      value: []
  }

  get selected() {
    const { value, placeholder, options } = this.props
    if (!value || !value.length) {
      return <span className="text--grey">{placeholder}</span>
    }
    return value
      .map((val) => {
        const op = options.find(option => option.value === val)
        return op.text
      })
      .join(', ')
  }

  get options() {
    const { options } = this.props
    return options.map(({ text: label, value }) => ({ label, value }))
  }

  handleVisibleChange = (isDropdownOpen) => {
    this.setState({ isDropdownOpen })
  }

  render() {
    const { className, onChange, value, style } = this.props
    const dropdownOverlay = (
      // <Menu selectable onClick={onBlur}>
      <Menu>
        <CheckboxGroup
          options={this.options}
          defaultValue={value}
          onChange={(checkedValues) => onChange(checkedValues)}
        />
      </Menu>
    )
    const formInputControlClass = classNames(
      'FormInputControl',
      'flex-row flex-spaceBetween flex-alignCenter',
      className
    )
    const selectedClass = classNames(
      'text-p utils-fullwidth',
      { 'text--grey': !value }
    )
    const { isDropdownOpen } = this.state
    return (
      <Dropdown
        overlayClassName="MultiselectInput"
        overlay={dropdownOverlay}
        trigger={['click']}
        visible={isDropdownOpen}
        onVisibleChange={this.handleVisibleChange}
      >
        <div
          style={{ ...style, whiteSpace: 'nowrap' }}
          className={formInputControlClass}
        >
          <div className={selectedClass}>{this.selected}</div>
          <img src={select} className="utils-append" alt="" />
        </div>
      </Dropdown>
    )
  }
}
export default MultiselectInput
