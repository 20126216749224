import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import { Block } from '../store/actions/Block'
import MultilineInput from '../common/form/MultilineInput'
import Label from '../common/form/Label'
import ModalForm from '../containers/ModalForm';

@inject('store')
@observer
class DisableStudentLogin extends React.Component {
    static propTypes = {
        studentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    state = { reason: "" }

    handleSubmit = () => {
        const { studentIds: student_ids, store, onClose } = this.props
        const { reason } = this.state
        const action = Block.create({ student_ids, reason })
        const { studentStore } = store
        studentStore.blockStudents(action.toJSON())
        onClose()
    }

    render() {
        const { studentIds, onClose, store: {studentStore: {isLoading}} } = this.props
        const { reason } = this.state

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Submit"
                isOkButtonDisabled={reason.length === 0 || isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--info"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">You are about to block {studentIds.length} student(s)</div>
                        Blocking a student means that such a student,
                        while remaining an enrolled student, will be
                        denied access to the school&apos;s portal.
                    </div>
                </div>

                <Label text="Reason" required>
                    <MultilineInput
                        value={reason}
                        onChange={(e) => this.setState({ reason: e.target.value })}
                        placeholder="eg: student owes fees"
                    />
                </Label>
            </ModalForm>
        )
    }
}
export default DisableStudentLogin