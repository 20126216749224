import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform";
import * as PropTypes from "prop-types";
import ReactQuill from 'react-quill';
import {Collapse} from "react-collapse";

import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import InlineError from '../common/form/InlineError';
import TextInput from '../common/form/TextInput';
import DateInput from '../common/form/DateInput';
import SelectInput from '../common/form/SelectInput';
import SwitchInput from '../common/form/SwitchInput';
import {CreateAssessment } from '../store/actions/Assessment'
import ContentLoader from '../common/content_loader'
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions'
import MultiFileInput from "../common/form/MultiFileInput";

import 'react-quill/dist/quill.snow.css';

const form = new Form(CreateAssessment, {
    id: new Field(converters.maybeNull(converters.string)),
    name: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.value, required: true
    }),
    abbreviation: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.value, required: true
    }),
    description: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    assessment_category_id: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object, required: true
    }),
    possible_points: new Field(converters.maybeNull(converters.number), {
        controlled: controlled.value, required: true
    }),
    extra_points: new Field(converters.maybeNull(converters.number), {
        controlled: controlled.value
    }),
    due_date: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object, required: true
    }),
    include_in_final_grade: new Field(converters.boolean, {
        controlled: controlled.object
    }),
    publish_type: new Field(converters.string, {
        controlled: controlled.object, required: true
    }),
    publish_days_before_due_value: new Field(converters.maybeNull(converters.number), {
        controlled: controlled.value
    }),
    publish_on: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    score_display_as: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object
    }),
    publish_scores: new Field(converters.boolean, {
        controlled: controlled.object
    }),
    attached_documents_attributes: new Field(converters.object, {
        controlled: controlled.object
    }),
})

@inject("store")
@observer
class AddAssessment extends React.Component {
    state = { 
        createdAssessment: null, 
        selectedAssessment: null,
        showMore: false
     }

    async componentWillMount() {

        const { store, editMode, assessmentId } = this.props
        const { subjectStore, view : { selectedSubjectId } } = store
        const { addAssessmentFormInstance, createAssessment, editAssessment, selectAssessmentForEdit } = subjectStore;

        if (editMode && assessmentId) {
            const selectedAssessment = await selectAssessmentForEdit(assessmentId)
            if (selectedAssessment) {
                this.formState = form.state(addAssessmentFormInstance, {
                    addMode: false,
                    save: async (node) => {
                        const { data: createdAssessment, errors } = await editAssessment(node.toJSON())
                        if (createdAssessment) {
                            this.setState({ createdAssessment })
                        }
                        return errors
                    }
                })
                this.setState({ selectedAssessment })
            }
        } else {
            subjectStore.resetAddAssessmentForm()
            addAssessmentFormInstance.setSubjectId(selectedSubjectId)
            this.formState = form.state(addAssessmentFormInstance, {
                addMode: false,
                save: async (node) => {
                    const { data: createdAssessment, errors } = await createAssessment(node.toJSON())
                    if (createdAssessment) {
                        this.setState({ createdAssessment })
                    }
                    return errors
                }
            })
        }
    }

    handleSubmit = async () => {
        const { onCancel } = this.props
        const success = await this.formState.save()
        const {createdAssessment} = this.state
        if (success && createdAssessment) {
            onCancel()
        }
    };

    toggleShowMore = () => {
        let { showMore } = this.state
        showMore = !showMore
        this.setState({ showMore })
    }

    render() {
        const { store, onCancel, editMode } = this.props
        const { subjectStore } = store
        const { isLoading, assessmentCategories, addAssessmentFormInstance } = subjectStore
        const { selectedAssessment, showMore } = this.state
        const publishTypeOptions = [
            {text: "Immediately", value: "immediately"},
            {text: "Due date", value: "due_date"},
            {text: "Days before due", value: "days_before_due"},
            {text: "Specific date", value: "specific_date"},
        ]
        const scoreDisplayOptions = [
            {text: "Points", value: "points"},
            {text: "Percentage", value: "percentage"},
            {text: "Letter grade", value: "letter_grade"}
        ]

        if (editMode) {
            const modalLoading = (selectedAssessment === null) || isLoading
            if (modalLoading) {
                return <Loading />
            }
        } 

        const name = this.formState.field("name")
        const abbreviation = this.formState.field("abbreviation")
        const description = this.formState.field("description")
        const assessment_category_id = this.formState.field("assessment_category_id")
        const possible_points = this.formState.field("possible_points")
        const extra_points = this.formState.field("extra_points")
        const due_date = this.formState.field("due_date")
        const include_in_final_grade = this.formState.field("include_in_final_grade")
        const publish_type = this.formState.field("publish_type")
        const publish_days_before_due_value = this.formState.field("publish_days_before_due_value")
        const publish_on = this.formState.field("publish_on")
        const score_display_as = this.formState.field("score_display_as")
        const publish_scores = this.formState.field("publish_scores")
        const attached_documents_attributes = this.formState.field("attached_documents_attributes")
        
        return (
            <ModalForm
                isOkButtonDisabled={isLoading}
                okButtonText={isLoading ? "Please wait..." : "Save Assessment"}
                onOk={this.handleSubmit}
                onCancel={onCancel}
            >
                <Label text="Assessment category" required>
                    <InlineError field={assessment_category_id}>
                        <SelectInput
                            {...assessment_category_id.inputProps}
                            placeholder="Select category"
                            options={mapModelNameToOptions(assessmentCategories)}
                        />
                    </InlineError>
                </Label>
                <Label text="Name" required>
                    <InlineError field={name}>
                        <TextInput {...name.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Abbreviation" required>
                    <InlineError field={abbreviation}>
                        <TextInput {...abbreviation.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Due date" required>
                    <InlineError field={due_date}>
                        <DateInput {...due_date.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Possible points" required>
                    <InlineError field={possible_points}>
                        <TextInput type="number" {...possible_points.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Description">
                    <InlineError field={description}>
                        <ReactQuill theme="snow" {...description.inputProps} />
                    </InlineError>
                </Label>
                
                <UploadDocuments field={attached_documents_attributes} attacheableModel={addAssessmentFormInstance} />

                <Label text="">
                    <span className="touchable-opacity text-p text--bold text--primary" onClick={this.toggleShowMore}>
                        {showMore ? 'Show less' : 'Show more'}
                    </span>
                </Label>

                <Collapse isOpened={showMore}>
                    <Label text="Extra points">
                        <InlineError field={extra_points}>
                            <TextInput type="number" {...extra_points.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Include in final grade" required={include_in_final_grade.required}>
                        <InlineError field={include_in_final_grade}>
                            <SwitchInput {...include_in_final_grade.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Show To Students" required={publish_type.required}>
                        <InlineError field={publish_type}>
                            <SelectInput
                                {...publish_type.inputProps}
                                placeholder="Select type"
                                options={publishTypeOptions}
                            />
                        </InlineError>
                    </Label>
                    {addAssessmentFormInstance.publish_type === "days_before_due" && (
                        <Label text="Show days before due" required={addAssessmentFormInstance.publish_type === "days_before_due"}>
                            <InlineError field={publish_days_before_due_value}>
                                <TextInput required={addAssessmentFormInstance.publish_type === "days_before_due"} type="number" {...publish_days_before_due_value.inputProps} />
                            </InlineError>
                        </Label>
                    )}
                    {addAssessmentFormInstance.publish_type === "specific_date" && (
                        <Label text="Show on" required={addAssessmentFormInstance.publish_type === "specific_date"}>
                            <InlineError field={publish_on}>
                                <DateInput required={addAssessmentFormInstance.publish_type === "specific_date"} {...publish_on.inputProps} />
                            </InlineError>
                        </Label>
                    )}
                    <Label text="Show scores to students" required={publish_scores.required}>
                        <InlineError field={publish_scores}>
                            <SwitchInput {...publish_scores.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Display score as" required={score_display_as.required}>
                        <InlineError field={score_display_as}>
                            <SelectInput
                                {...score_display_as.inputProps}
                                placeholder="Select display type"
                                options={scoreDisplayOptions}
                            />
                        </InlineError>
                    </Label>
                </Collapse>
            </ModalForm>
        )
    }
}
AddAssessment.propTypes = {
    assessmentId: PropTypes.string,
    onCancel: PropTypes.func.isRequired
}

AddAssessment.defaultProps = {
    assessmentId: ''
}

export default AddAssessment

const Loading = () => (
    <ContentLoader height="70" width='300'>
        <rect x="20" y="10" rx="1" ry="1" width="30" height="8" />
        <rect x="80" y="7" rx="1" ry="1" width="100" height="14" />
        <rect x="20" y="30" rx="1" ry="1" width="30" height="8" />
        <rect x="80" y="29" rx="1" ry="1" width="20" height="10" />
    </ContentLoader>
)

@inject("display")
@observer
class UploadDocuments extends React.Component{
    
    render(){
        const { display, field, attacheableModel } = this.props
        const {attachDocument} = attacheableModel
        const InputControl = (
            <MultiFileInput
                style={display.isDesktop ? { gridRow: "span 2" } : null}
                {...{1: 3 /* field.inputProps */ }}
                attachDocument={attachDocument}
            />
        )
        if (display.isDesktop) {
            return InputControl
        }
        return (
            <Label text="Upload Files">
                <InlineError field={field}>
                    {InputControl}
                </InlineError>
            </Label>
        )
        
    }
}