import {types, getParent, applySnapshot} from "mobx-state-tree"

export const Search = types
    .model({
        include_total_ids: true,
        page: 1,
        per_page: 20,

        // FIXME // TODO client_state added. Refactor classes that use this
        client_state: types.optional(types.model({
            isLoading: types.optional(types.boolean, false)
        }), {})
    })
    .actions(self => ({
        nextPage() {
            const { searchMeta: { total_pages }, search } = getParent(self)
            if (self.page < total_pages) {
                self.page += 1
                search()
            }
        },
        prevPage() {
            const { search } = getParent(self)
            if (self.page > 1) {
                self.page -= 1
                search()
            }
        },

        applyData(snapshot){
            applySnapshot(self, snapshot)
        }
    }))