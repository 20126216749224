import uuidv1 from 'uuid/v1'

class Tab {
  constructor(text, component, isActive = false) {
    this.text = text
    this.isActive = isActive
    this.id = uuidv1()
    this.component = component
  }

  id

  component

  text

  isActive
}

export default Tab