import React from 'react';
import {inject} from "mobx-react";
import isEmpty from "lodash.isempty";

import NotMobile from '../common/display/NotMobile';
import Mobile from '../common/display/Mobile';
import Table from '../common/table';
import Button from '../common/button';
import Card from '../common/card';
import EmptyList from "../common/empty_list/empty_list";

import './Debtors.css';

import icon from './assets/debt.svg';

@inject("store")
class Debtors extends React.Component {
    render() {

        const { debtorsByClass, store: {view: {openStudentsPage}, studentStore: {searchFormInstance}} } = this.props;

        const columns = [
            { key: 'class', render: 'Class' },
            { key: 'student_count', render: 'Student No' },
            { key: 'amount', render: 'Amount' },
            {
                key: 'Action',
                render:(
                    <Button
                        onClick={() => {
                            searchFormInstance.applyData({due_fees_balance: '> 0'})
                            openStudentsPage()
                        }}
                        size="small"
                        fullwidth
                    >
                        {false ? 'Remind All' : 'View all'}
                    </Button>
                ),
                style: { width: 120, textAlign: 'right' },
            },
        ];


        const data = !debtorsByClass ? [] : debtorsByClass.map(({ course_id, className, debtorsCount, totalDebt }) => ({
            'class': className,
            'student_count': debtorsCount,
            'amount': totalDebt,
            'Action': (
                <Button
                    size="small"
                    fullwidth
                    onClick={() => {
                        searchFormInstance.applyData({course: course_id, due_fees_balance: '> 0'})
                        openStudentsPage()
                    }}
                >
                    {false ? 'Send reminder' : 'View list'}
                </Button>
            )
        }))

        const dataMobile = !debtorsByClass ? [] : debtorsByClass.map(({ course_id, className, debtorsCount, totalDebt }) => ({
            className,
            course_id,
            studentNo: debtorsCount,
            amount: totalDebt,
            sendReminder: true,
        }));

        return (
            <div className='dashboard-debtors debtors'>
                <div className="flex-row flex-spaceBetween debtors-head">
                    <div className="flex-row flex-alignCenter">
                        <img className="dashboard-icon" src={icon} alt="" />
                        <span className="text-small text--uppercase">Debtors</span>
                    </div>
                    <Mobile>
                        <div
                            onClick={() => {
                                searchFormInstance.applyData({due_fees_balance: '> 0'})
                                openStudentsPage()
                            }}
                            className="button button--primary touchable-highlight text-small"
                        >
                            {false ? 'Remind All' : 'VIEW ALL'}
                        </div>
                    </Mobile>
                </div>
                {isEmpty(data) && <EmptyList />}

                {!isEmpty(data) && (
                    <>
                        <NotMobile>
                            <Table columns={columns} data={data} height={250} containerClass="Card" responsive={false} />
                        </NotMobile>
                        <Mobile>
                            <>
                                {dataMobile.filter((_, i) => i < 3).map(({ course_id, className, studentNo, amount }) => (
                                    <Card key={course_id} className="debtors-mobileCard">
                                        <div>
                                            <div className="text-p">{className}</div>
                                            <div className="text-small text--danger">{studentNo} debtors</div>
                                        </div>
                                        <div>
                                            <div className="text-p text--bold text--primary">{amount}</div>
                                            <div
                                                onClick={() => {
                                                    searchFormInstance.applyData({course: course_id, due_fees_balance: '> 0'})
                                                    openStudentsPage()
                                                }}
                                                className="text-small text--primary touchable-opacity text--bold text--uppercase"
                                            >
                                                {false ? 'Send reminder' : 'View list'}
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </>
                            <div
                                onClick={() => {
                                    searchFormInstance.applyData({due_fees_balance: '> 0'})
                                    openStudentsPage()
                                }}
                                className="text--primary text-small text--bold touchable-opacity"
                            >
                                VIEW ALL
                            </div>
                        </Mobile>
                    </>
                )}
            </div>
        );
        
    }
}

export default Debtors
