import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'

import MaybeBackPage from '../containers/MaybeBackPage'
import StudentReportCard from "../report_cards/StudentReportCard"


import {ReportCardGroupData} from "../store/actions/ReportCardGroupData";



@inject("store")
@observer
class StudentReportCardPage extends Component {

    componentDidMount() {
        const { store: {reportCardGroupStore, view} } = this.props
        const { loadReportCardGroupData, studentReportCardData } = reportCardGroupStore

        const { selectedStudentReportCardId, openDefaultPage } = view
        
        const studentReportCardDatum = studentReportCardData.get(selectedStudentReportCardId)
        
        if (isEmpty(studentReportCardDatum)){
            // this most likely means a direct link was followed here. The API currently doesn't support fetching
            // information using a studentReportCardDatumID, a direct visit to this page is therefore currently unsupported
            openDefaultPage()
            return
        }
        
        
        const {attributes:{student_id, report_card_group_id}} = studentReportCardDatum
        const action = ReportCardGroupData.create({student_ids: [student_id.toString()]})
        loadReportCardGroupData(report_card_group_id, action)
    }
    

    render() {
        const { store: {reportCardGroupStore, studentStore, view: {selectedStudentReportCardId}} } = this.props
        const { studentReportCardData, reportCardGroups } = reportCardGroupStore
        const { students } = studentStore

        const studentReportCardDatum = studentReportCardData.get(selectedStudentReportCardId)
        if (isEmpty(studentReportCardDatum)) return '...'

        
        const {attributes: {student_id, report_card_group_id}} = studentReportCardDatum
        

        const reportCardGroup = reportCardGroups.get(report_card_group_id)
        const student = students.get(student_id)

        return (
            <MaybeBackPage>
                <StudentReportCard student={student} reportCardGroup={reportCardGroup} />
            </MaybeBackPage>
        )
    }
}

export default StudentReportCardPage
