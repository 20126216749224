import { objectToFormData } from "../lib/objectToFormData";

export function formDataTransform(data) {
    // we need to set Content-Type to undefined,
    // to make the browser set it to multipart/form-data 
    // and fill in the correct *boundary*.
    // setting Content-Type to multipart/form-data manually 
    // will fail to fill in the boundary parameter of the request.
    return objectToFormData(data);

}