import './StudentBatchHistory.css'
import '../common/notes/Notes.css'

import React from 'react'
import { Row, Col, Popconfirm, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import Card from '../common/card';
import Table from '../common/table';
import { ModalContext } from '../common/modal'
import EditStudentBatchHistoryItem from './EditStudentBatchHistoryItem';
import MobileConfirmDialog from '../common/mobile_confirm_dialog';
import Mobile from '../common/display/Mobile';
import Desktop from "../common/display/Desktop";
import Tablet from "../common/display/Tablet";



const StudentBatchHistory = inject("store")(
    observer(({ store }) => {
        const {studentStore: {removeBatchTransfer}, view, meta: {currentUser}} = store
        const { selectedStudent : { batchTransfers : batchTransferItems, isLoading }} = view

        const tableColumns = [
            { key: 'batchName', render: 'Batch' },
            { key: 'firstDay', render: 'First Day' },
            { key: 'lastDay', render: 'Last Day' },
            { key: 'transfer_out_reason', render: 'Reason' }
        ]

        if (currentUser.is_employee_or_admin) tableColumns.push({ key: 'actions', render: null })
        
        const tableData = batchTransferItems.map((batchHistoryItem) => {
            const { id, attributes: batchHistoryItemAttrs, batchName, firstDay, lastDay } = batchHistoryItem
            const {transfer_out_reason, is_active} = batchHistoryItemAttrs
            
            let actions
            if (currentUser.is_employee_or_admin){
                actions = (
                    <StudentBatchHistoryItemActions
                        key={id}
                        batchHistoryItem={batchHistoryItem}
                        handleDelete={() => removeBatchTransfer(batchHistoryItem)}
                    />
                )
            }
            return {
                id,
                batchName,
                firstDay,
                lastDay: is_active ? 'Till Date' : lastDay,
                transfer_out_reason: is_active ? 'Current Batch' : transfer_out_reason,
                actions
            }
        })
        
        return (
            <>
                <Desktop>
                    <Card title="Student Batch History">
                        <Table
                            columns={tableColumns}
                            data={tableData}
                            striped
                            responsive
                            loading={isLoading}
                        />
                    </Card>
                </Desktop>
                <Tablet>
                    <Card className="Notes-head">
                        <div className="text-small text--uppercase text--spacedLetters utils-prepend">Student Batch History</div>
                    </Card>
                    <Table
                        columns={tableColumns}
                        data={tableData}
                        striped
                        responsive
                        loading={isLoading}
                    />
                </Tablet>
                <Mobile>
                    <Card className="Notes-head">
                        <div className="text-small text--uppercase text--spacedLetters utils-prepend">Student Batch History</div>
                    </Card>
                    
                    {tableData.map(item => <StudentBatchHistoryItem key={item.id} currentUser={currentUser} data={item} />)}
                </Mobile>
            </>
        )
    })
)

export default StudentBatchHistory

const StudentBatchHistoryItemActions = inject('display')(
    observer(({ display, batchHistoryItem, handleDelete }) => (
        <ModalContext.Consumer>
            {context => (
                <div className="flex-row">
                    <button
                        type="button"
                        className="
                            touchable-opacity
                            text--primary
                            text-p
                            utils-prepend
                        "
                        onClick={() => context.setContent(
                            'Edit Student Batch Transfer',
                            <EditStudentBatchHistoryItem
                                batchHistoryItem={batchHistoryItem}
                                onClose={context.closeModal}
                            />,
                            true
                        )}
                    >
                        Edit
                    </button>
                    {display.isMobile && (
                        <button
                            type="button"
                            className="
                                touchable-opacity
                                text--danger
                                text-p
                                utils-append
                            "
                            onClick={() => context.setContent(
                                "Deleting student batch history...",
                                <MobileConfirmDialog
                                    handleConfirm={() => {
                                        handleDelete()
                                        context.closeModal()
                                    }}
                                    handleCancel={context.closeModal}
                                />,
                                true
                            )}
                        >
                            Delete
                        </button>
                    )}
                    {display.isMobile || (
                        <Popconfirm
                            title="Are you sure delete this item?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={handleDelete}
                        >
                            <button
                                type="button"
                                className="
                                    touchable-opacity
                                    text--danger
                                    text-p
                                    utils-append
                                "
                            >
                                Delete
                            </button>
                        </Popconfirm>
                    )}
                </div>
            )}
        </ModalContext.Consumer>
    ))
)

function StudentBatchHistoryItem({ data, currentUser }) {
    return (
        <Card className="StudentBatchHistoryItem">
            <Row>
                <div className="text-p">{data.firstDay} ~ {data.lastDay}</div>
                <Col span={24} className="text-h3 mb-1">{data.batchName}</Col>
                
                <Col span={24}>
                    <div className="mb-1">
                        <div className="text-small text--uppercase text--bold">Reason</div>
                        <div className="text-p">{data.transfer_out_reason}</div>
                    </div>
                </Col>
                
                {currentUser.is_employee_or_admin && (
                    <Col span={24}>
                    <div className="flex-row flex-justifyEnd">{data.actions}</div>
                    </Col>
                )}
            </Row>
        </Card>
    )
}