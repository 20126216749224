import { types } from "mobx-state-tree"
import { CourseSet } from "./CourseSet"
import { StudentsReportCardData } from './StudentsReportCardData';
import { BatchesReportCardData } from './BatchesReportCardData';
import { SubjectsReportCardData } from './SubjectsReportCardData';
import { ReportCardTemplate } from './ReportCardTemplate';
import { Student } from './Student';
import { BehaviouralDomainGroup } from './BehaviouralDomainGroup';
import { Employee } from './Employee';
import { SubjectBase } from './SubjectBase';
import { StudentReportCardComment } from './StudentReportCardComment';
import { forOptions } from '../actions/ReportCardGroup';

export const ReportCardGroup = types
    .model("ReportCardGroup", {
        id: types.identifier,
        type: "report_card_group",
        attributes: types.maybe(types.model({
            name: types.string,
            report_card_template_id: types.number,
            course_set_id: types.number,
            course_set_name: types.maybeNull(types.string),
            report_type: types.string,
            for: types.enumeration(forOptions),
            status: types.string,
            status_message: types.maybeNull(types.string),
            published: types.maybeNull(types.string),
            
            cognitive_column_date_ranges: types.array(types.model({
                id: types.string,
                start_date: types.string,
                end_date: types.string
            })),


            created_at: types.string,
            student_ids: types.array(types.number),
            
            
            
            attendance_start_date: types.maybeNull(types.string),
            attendance_end_date: types.maybeNull(types.string),
            batch_ids: types.maybeNull(types.array(types.number)),
            

            students_report_card_data: types.array(StudentsReportCardData),
            batches_report_card_data: types.array(BatchesReportCardData),
            subjects_report_card_data: types.array(SubjectsReportCardData),
            class_report_card_data: types.maybe(types.model({
                cognitive_columns_summary: types.array(types.model({
                    column_id: types.number,
                    average_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
                    max_weighted_grade: types.maybeNull(types.union(types.string, types.number))
                })),
                student_ids: types.array(types.number),
                course_set_id: types.reference(CourseSet),
            }))
        })),
        relationships: types.maybe(types.model({
            course_set: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(CourseSet))
                })
            })),
            template: types.model({
                data: types.model({
                    id: types.late(() => types.reference(ReportCardTemplate))
                })
            }),
            comments: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(StudentReportCardComment))
                }))
            })),
            subject_bases: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(SubjectBase))
                }))
            })),
            employees: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Employee))
                }))
            })),
            students: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            })),
            affective_domain_group: types.maybe(types.model({
                data: types.maybeNull(
                    types.model({
                        id: types.late(() => types.reference(BehaviouralDomainGroup))
                    })
                )
            })),
            psychomotor_domain_group: types.maybe(types.model({
                data: types.maybeNull(
                    types.model({
                        id: types.late(() => types.reference(BehaviouralDomainGroup))
                    })
                )
            }))
        })),
        isLoading: false,
    })
    .views(self => ({
        get classSet() {
            try {
                return self.relationships.course_set.data.id
            } catch (e) {
                console.warn(e);
                return undefined;
            }
        },
        get template() {
            try {
                return self.relationships.template.data.id
            } catch (e) {
                console.warn(e);
                return undefined;
            }
        },
        get classSetName() {
            return  self.attributes.course_set_name
        },
        get comments() {
            try {
                return self.relationships.comments.data.map(item => item.id)
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
        get students() {
            try {
                return self.relationships.students.data.map(item => item.id)
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
        get sortedStudents() {
            return self.students.sort((a, b) => a.studentName.localeCompare(b.studentName, 'en', { sensitivity: 'base', ignorePunctuation: true }))
        },
        get sortedBatches() {
            const batches = self.attributes.batches_report_card_data.map((brcd) => brcd.batch_id)
            const arr = batches.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base', ignorePunctuation: true }))
            return arr
        },
        commentForStudent(student_id, special_role_id) {
            return  self.comments.find((nCommentModel) => {
                const {attributes: {student_id: nStudentID, special_role_id: nSpecialRoleID }} = nCommentModel
                return parseInt(student_id, 10) === nStudentID && nSpecialRoleID === parseInt(special_role_id, 10)
            })
        },
        studentReportCardData(student_id) {
            return  self.attributes.students_report_card_data.find(({student_id: recordStudentId})=> parseInt(student_id, 10) === recordStudentId  )
        },
        studentSubjectBases(student_id) {
            return self.attributes.subjects_report_card_data.filter(
                ({student_ids: recordStudentIds}) => recordStudentIds.includes(parseInt(student_id, 10))
            ).map(({subject_base_id})=> subject_base_id)
        },
        batchReportCardData(batch_id) {
            return self.attributes.batches_report_card_data.find(({batch_id: nBatchID})=> batch_id === nBatchID.id  )
        },
        isStudentReportCardLoaded(studentReportCardID) {
            return self.students.map(({id}) => id).includes(studentReportCardID)
        },
        get classReportCardData() {
            return self.attributes.class_report_card_data
        },
    }))
    .actions(self => ({
        addComment({id, type}){
            const foundIndex = self.comments.findIndex(({id: nID}) => id === nID)
            if(foundIndex < 0){
                self.relationships.comments.data.push( {id, type})
            }
        }
    }))