import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/guardians.json'
import showGuardianJSON from '../json/guardian_data.json'
import createGuardianJSON from '../json/create_guardian_success.json'
import editGuardianJSON from "../json/edit_guardian_success.json";
import deleteGuardianJSON from "../json/delete_guardian_success.json"
import { formDataTransform } from './formDataTransform';

export function GuardianService(env) {
    async function search(guardian_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("guardian_search", guardian_search)
            const { data } = await axios.get(`/api/v1/guardians?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showGuardian(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/guardians/${id}`)
            return data
        }
        return showGuardianJSON
    }

    async function createGuardian(guardian) {
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/guardians`,
                { guardian },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createGuardianJSON
    }
    
    async function updateGuardian(id, guardian) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/guardians/${id}`,
                { guardian },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return editGuardianJSON
    }

    async function deleteGuardian(id) {
        if (env !== TESTENV) {
            const {data} = await axios.delete(`/api/v1/guardians/${id}`);
            return data
        }
        return deleteGuardianJSON
    }

    async function downloadGuardianListSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/guardians`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: action
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        search,
        showGuardian,
        createGuardian,
        updateGuardian,
        deleteGuardian,
        downloadGuardianListSpreadsheet
    }
}

export default GuardianService(TESTENV)