// import React from 'react'
import { observer } from 'mobx-react'
import { display } from './index'

function Mobile({ children }) {
    if (display.isMobile) {
        return children
    }
    return null
}

export default observer(Mobile)
