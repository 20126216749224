import { types } from "mobx-state-tree"

export const GradeScale = types
    .model("GradeScale", {
        id: types.identifier,
        type: "grade_scale",
        
        
        attributes: types.maybe(types.model({
            name: types.string,
            description: types.maybeNull(types.string),
            grade_scale_type: types.enumeration(['cognitive', 'behavioural']),
            levels: types.array(
                types.model({
                    id: types.number,
                    name: types.string,
                    min_percentage: types.maybeNull(types.number),
                    points: types.maybeNull(types.number),
                    remark: types.maybeNull(types.string)
                })
            )
        }))

    })