import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'

import Card from "../common/card";
import Loading from "../common/loading";
import SelectInput from "../common/form/SelectInput";

import arrow_left from "../common/assets/arrow_left.svg";
import arrow_right from "../common/assets/arrow_right.svg";
import newspaper from "../common/assets/newspaper.svg";


import './ReportCardDesktop.css'
import StudentReportCard from "./StudentReportCard";
import {OptionsPopover} from "../common/tabs/OptionsPopover";

import {ReportCardGroupData} from "../store/actions/ReportCardGroupData";


@inject("store","display")
@observer
class SelectedReportCardGroup extends Component {
    constructor(props){
        super(props)
        const reportCardGroupAction = ReportCardGroupData.create({})
        this.state = { selectedStudentID: undefined, reportCardGroupAction }
    }

    componentDidMount() {
        const { store: {view: {selectedReportCardGroupId}, reportCardGroupStore: { loadReportCardGroupData }} } = this.props
        loadReportCardGroupData(selectedReportCardGroupId)
    }
    
    selectedStudentIndex = () => {
        const { store: {view: {selectedReportCardGroup: {sortedStudents}} } } = this.props
        const { selectedStudentID } = this.state

        let selectedIndex = -1
        if (selectedStudentID) selectedIndex = sortedStudents.findIndex((student) => student.id === selectedStudentID)

        return [sortedStudents,selectedIndex]
    }
    
    handleClickNext = () => {
        const [sortedStudents, selectedIndex] = this.selectedStudentIndex()
        if (this.canClickNext() ) this.setState({selectedStudentID: sortedStudents[selectedIndex + 1].id })
    }

    handleClickPrevious = () => {
        const [sortedStudents, selectedIndex] = this.selectedStudentIndex()
        if (this.canClickPrevious() ) this.setState({selectedStudentID: sortedStudents[selectedIndex - 1].id })
    }

    canClickPrevious = () => {
        // eslint-disable-next-line no-unused-vars
        const [sortedStudents, selectedIndex] = this.selectedStudentIndex()
        return (selectedIndex - 1) >= 0;
    }

    canClickNext = () => {
        const [sortedStudents, selectedIndex] = this.selectedStudentIndex()
        return sortedStudents.length > selectedIndex + 1;
    }

    initiateBroadsheetDownload = (batchIDs) => {
        const { reportCardGroupAction } = this.state
        const {store: {view: {selectedReportCardGroup}, reportCardGroupStore: {downloadBroadsheet}}} = this.props;
        reportCardGroupAction.setToGetBatches(batchIDs)
        reportCardGroupAction.isBroadsheetRequest(true)
        downloadBroadsheet(selectedReportCardGroup.id, reportCardGroupAction)
    }
    
    initiateMultipleReportCardsDownload = (batchIDs) => {
        const { reportCardGroupAction } = this.state
        const {store: {view: {selectedReportCardGroup}, reportCardGroupStore: {downloadPDFReport}}} = this.props;
        reportCardGroupAction.setToGetBatches(batchIDs)
        reportCardGroupAction.isBroadsheetRequest(false)
        downloadPDFReport(selectedReportCardGroup.id, reportCardGroupAction)
    }

    options = () =>{
        const { store: {view: {selectedReportCardGroup} } } = this.props
        const { reportCardGroupAction } = this.state
        const { isSaving, batch_ids: actionBatchIDs } = reportCardGroupAction

        const batchIDS = selectedReportCardGroup.sortedBatches.map((batch) => batch.id)

        let optionIsSaving = reportCardGroupAction.isSaving && true
        optionIsSaving = reportCardGroupAction.isSaving && isEqual(batchIDS, actionBatchIDs) && reportCardGroupAction.is_broadsheet
        
        const options =  [
            {
                text: optionIsSaving ? 'Downloading...' : 'Broadsheet All',
                disabled: optionIsSaving,
                title: "Print Broadsheet for all classes",
                onClick: () => {
                    this.initiateBroadsheetDownload(batchIDS)
                }
            }
        ]

        selectedReportCardGroup.sortedBatches.forEach((batch) => {
            optionIsSaving = reportCardGroupAction.isSaving && isEqual([batch.id], actionBatchIDs) && reportCardGroupAction.is_broadsheet
            
            options.push({
                text: optionIsSaving ? 'Downloading...' :  `Broadsheet (${batch.name})`,
                disabled: reportCardGroupAction.isSaving,
                title: `Print Broadsheet for ${batch.name}`,
                onClick: () => {
                    this.initiateBroadsheetDownload([batch.id])
                }
            })
        })

        optionIsSaving = reportCardGroupAction.isSaving && isEqual(batchIDS, actionBatchIDs) && !reportCardGroupAction.is_broadsheet
        
        options.push(
            {
                text: optionIsSaving ? 'Downloading...' :  'Report Cards All',
                disabled: reportCardGroupAction.isSaving,
                title: "Print Report Cards for all classes",
                onClick: () => {
                    this.initiateMultipleReportCardsDownload(batchIDS)
                }
            }
        )

        selectedReportCardGroup.sortedBatches.forEach((batch) => {
            optionIsSaving = reportCardGroupAction.isSaving && isEqual([batch.id], actionBatchIDs) && !reportCardGroupAction.is_broadsheet
            options.push({
                text: optionIsSaving ? 'Downloading...' :  `Report Card (${batch.name})`,
                disabled: reportCardGroupAction.isSaving,
                title: `Print Report Card for ${batch.name}`,
                onClick: () => {
                    this.initiateMultipleReportCardsDownload([batch.id])
                }
            })
        })

        return options
    }

    render() {
        const { display, store: {view: {selectedReportCardGroup} } } = this.props
        const { selectedStudentID } = this.state
        
        if (!selectedReportCardGroup) return  <Loading />
        
        const { sortedStudents } = selectedReportCardGroup

        if (isEmpty(sortedStudents)) return  <Loading />;
        
        let selectedStudent
        
        if (selectedStudentID) selectedStudent = sortedStudents.find((student) => selectedStudentID === student.id)
        
        
        return (
            <>
                <Card
                    className="mb-1 reportCardGroupHeader"
                    title={(
                        <div className="flex-row flex-spaceBetween">
                            <div><img src={newspaper} className="utils-prepend" alt="" />Report Cards</div>
                            <div><OptionsPopover options={this.options()} /></div>
                        </div>
                    )}
                >
                    <div className='navigatorWrapper flex-row flex-justifyCenter flex-wrap mb-1 mt-1'>
                        {this.canClickPrevious() && (
                            <button type="button" className='pR-1' title="Previous Student" onClick={this.handleClickPrevious}>
                                <img src={arrow_left} className="touchable-opacity" alt="" />
                            </button>
                        )}

                        <div className='navigatableSelectWrapper inline-block'>
                            <SelectInput
                                className='navigatableSelect'
                                options={sortedStudents.map((student) => ({text: (display.isMobile ? student.nameAbbreviation : student.studentName), value: student.id }) )}
                                onChange={(value) => {this.setState({selectedStudentID: value})}}
                                value={selectedStudentID}
                                placeholder="Select student"
                            />
                        </div>

                        {this.canClickNext() && (
                            <button type="button" className='pl-1' title="Next Student" onClick={this.handleClickNext}>
                                <img src={arrow_right} className="touchable-opacity" alt="" />
                            </button>
                        )}
                    </div>
                </Card>
                
                {!selectedStudentID && (
                    <Card>
                        <h3>Select student to view report card</h3>
                    </Card>
                )}
                
                {selectedStudentID && (
                    <StudentReportCard student={selectedStudent} reportCardGroup={selectedReportCardGroup} />
                )}
            </>
        )
    }
}

export default SelectedReportCardGroup
