import './FinanceList.css'

import React from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd';
import { formatDate } from '../lib/formatDate'
import FeeSearch from './FeeSearch';
import { formatCurrency } from '../lib/formatCurrency';
import Collection from '../common/Collection';
import FeesItem from './FeesItem';
import NewFee from './NewFee';

import moneyIcon from '../common/assets/money-bag.svg'

const Fees = inject("store")(
    observer(({ store }) => {
        const {
            currency,
            feeStore: settings,
            view: {
                getFeesPage,
                openFeePageById,
                setBackPage
            }
        } = store
        const { searchResults } = settings
        const columns = [
            { key: 'name', render: 'Fee Type' },
            { key: 'description', render: 'Description' },
            { key: 'amount', render: 'Amount' },
            { key: 'expected_income', render: 'Expected' },
            { key: 'total_balance', render: 'Balance' },
            { key: 'start_date', render: 'Start Date' },
            { key: 'due_date', render: 'Due Date' },
            { key: 'created_at', render: 'Creation Date' }
        ]
        const tableData = searchResults
            .map((fee) => {
                const {
                    id,
                    feeType: {
                        attributes: {
                            name,
                        }
                    },
                    attributes: {
                        description,
                        amount,
                        expected_income,
                        total_balance,
                        start_date,
                        due_date,
                        created_at,
                    }
                } = fee
                return {
                    id,
                    name: (
                        <a
                            href={`/finance/fees/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                 setBackPage('Fee Management', getFeesPage())
                                openFeePageById(id)
                            }}
                        >
                            {name}
                        </a>
                    ),
                    description,
                    amount: formatCurrency(amount, currency),
                    expected_income: formatCurrency(expected_income, currency),
                    total_balance: formatCurrency(total_balance, currency),
                    start_date: formatDate(start_date),
                    due_date: formatDate(due_date),
                    created_at: formatDate(created_at)
                }
            })
        const tableSettings = {
            tableData,
            columns,
        }
        const buildMobileItem = fee => (
            <FeesItem key={fee.id} fee={fee} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">&nbsp;</div>
                    {collectionSummaryText}
                    <NewFee />
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={moneyIcon} alt="" />
                        <span className="text-small utils-append text--uppercase">Fee Management</span>
                    </Col>
                    <Col span={12}>
                        <NewFee />
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12} offset={6}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={FeeSearch}
            />
        )
    }))

export default Fees
