import ApplicationPolicy from "./ApplicationPolicy";

class StudentGuardianRelationPolicy extends ApplicationPolicy{
    get can_show(){
        return this.user.has_any_role(['student_control','admission'])
    }

    get can_create(){
        return this.can_show
    }

    get can_destroy(){
        return this.user.has_role('student_control')
    }
        
    
}

export default StudentGuardianRelationPolicy