import axios from './axios'
import { TESTENV } from './constants'
import subjectBasesJSON from '../json/subject_bases.json'

export function SubjectBaseService(env) {

    async function subjectBases() {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/configuration/subject_bases`)
            return data
        }
        return subjectBasesJSON
    }

    async function createSubjectBase(subjectBase) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/configuration/subject_bases`, subjectBase)
            return data
        }
        return []
    }

    async function updateSubjectBase(subjectBase, subjectBaseId) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/configuration/subject_bases/${subjectBaseId}`, subjectBase)
            return data
        }
        return {}
    }

    async function deleteSubjectBase(subjectBaseId) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/configuration/subject_bases/${subjectBaseId}`)
            return data
        }
        return {}
    }

    return {
        subjectBases,
        createSubjectBase,
        updateSubjectBase,
        deleteSubjectBase
    }
}

export default SubjectBaseService(TESTENV)