import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import { Field, Form as MstForm, converters, controlled } from "mstform"
import MultilineInput from '../common/form/MultilineInput'
import Label from '../common/form/Label'
import { DropEmployee } from '../store/actions/Employee';
import DateInput from '../common/form/DateInput';
import Alert from '../common/alert'
import ModalForm from '../containers/ModalForm';
import { formatDate } from '../lib/formatDate';

const formModel = new MstForm(DropEmployee, {
    date_left: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    reason_for_leaving: new Field(converters.string, {
        controlled: controlled.string,
        required: true
    })
})

@inject('store')
@observer
class ArchiveEmployee extends React.Component {
    static propTypes = {
        employeeId: PropTypes.string.isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    constructor(props) {
        super(props)
        const { store, employeeId } = props
        const {  employeeStore } = store
        const { archiveEmployeeById } = employeeStore
        const formInstance = DropEmployee.create({
            date_left: formatDate(Date.now()),
            reason_for_leaving: ""
        })
        this.formState = formModel.state(formInstance, {
            
            save: async (node) => {console.log(node.toJSON()); 
                return archiveEmployeeById(employeeId, node.toJSON())}
        })
    }

    handleSubmit = () => {
        this.formState.save()
        const { onClose } = this.props
        onClose()
    }

    render() {
        const date_left = this.formState.field("date_left")
        const reason_for_leaving = this.formState.field("reason_for_leaving")
        const { onClose, store: {employeeStore: {isLoading}} } = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Move To Archive"
                isOkButtonDisabled={!this.formState.isValid || isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--info"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">Employee leaving institution</div>
                        For employee leaving the institution.
                        Use this option to remove them from the list
                        of current employees and place them in the
                        archived employees list.
                    </div>
                </div>

                <Label text="Last Day In Batch" required={date_left.required}>
                    <DateInput {...date_left.inputProps} />
                </Label>

                <div className="mb-1">
                    <Alert
                        type="warning"
                        message="
                            Be sure to select the last date employee is 
                            assumed to have been an active employee of this 
                            institution. Selected date has effects across
                            the application and cannot be edited or reversed.
                        "
                    />
                </div>

                <Label text="Reason" required={reason_for_leaving.required}>
                    <MultilineInput
                        {...reason_for_leaving.inputProps}
                        placeholder="eg: relocating to a different state"
                    />
                </Label>
            </ModalForm>
        )
    }
}
export default ArchiveEmployee