/* eslint-disable react/no-multi-comp */
import "./GuardianListSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import { Row, Col, Checkbox } from 'antd';
import { GuardianSearch, statusOptions } from "../store/models/GuardianSearch"
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { genderOptions } from '../store/models/Gender';
import { debounce } from '../lib/debounce'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'
import { titleCase } from "../lib/titleCase";
import { dateRangeConverter } from '../lib/formatDate';

import filter from './assets/filter-blue.svg'

const formModel = new MstForm(GuardianSearch, {
    query: new Field(converters.string, {
        controlled: controlled.string
    }),
    status: new Field(converters.string, {
        controlled: controlled.object
    }),
    gender: new Field(converters.string, {
        controlled: controlled.object
    }),
    period_activated: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    period_left_institution: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
})

const QueryField = observer(({ formState }) => {
    const query = formState.field("query")
    return (
        <Label text="Direct Search">
            <TextInput
                placeholder="Name, Phone number, Email Address"
                {...query.inputProps}
            />
        </Label>
    )
})

const StatusField = observer(({ formState }) => {
    const status = formState.field("status")
    return (
        <Label text="Status">
            <SelectInput
                {...status.inputProps}
                placeholder="Select status"
                options={statusOptions.map(option => ({ text: titleCase(option === 'active' ? 'enrolled' : option), value: option }))}
            />
        </Label>
    )
})

const GenderField = observer(({ formState }) => {
    const gender = formState.field("gender")
    return (
        <Label text="Gender">
            <SelectInput
                {...gender.inputProps}
                placeholder="Select"
                options={genderOptions.map(option => ({ text: titleCase(option), value: option }))}
                defaultOptionText="All genders"
            />
        </Label>
    )
})

const PeriodActivatedField = observer(({ formState }) => {
    const period_activated = formState.field("period_activated")
    return (
        <Label text="Date Added">
            <DateRangeInput {...period_activated.inputProps} />
        </Label>
    )
})

const PeriodLeftField = observer(({ formState }) => {
    const period_left_institution = formState.field("period_left_institution")
    return (
        <Label text="Date Left">
            <DateRangeInput {...period_left_institution.inputProps} />
        </Label>
    )
})

export default function GuardianListSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" />
        Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, searchFormInstance } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <QueryField formState={this.formState} />
                    <StatusField formState={this.formState} />
                    <GenderField formState={this.formState} />
                    <PeriodActivatedField formState={this.formState} />
                    <PeriodLeftField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, search, searchFormInstance, isLoading } } = this.props
        return (
            <form className="GuardianListSearch-TabletForm">
                <QueryField formState={this.formState} />
                <StatusField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(searchFormInstance)}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <QueryField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <StatusField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <GenderField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <PeriodActivatedField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <PeriodLeftField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

function MoreFields({ fields, formState }) {
    const moreFields = Array
        .from(fields)
        .map(([key, value]) => {
            // eslint-disable-next-line no-shadow
            const Field = key
            const { hidden, label } = value
            return hidden ? null : <Field key={label} formState={formState} />
        })
    return (
        <div className="GuardianListSearch-MoreFields">
            {moreFields}
        </div>
    )
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        this.state = {
            moreFields: new Map([
                [PeriodActivatedField, { hidden: !searchFormInstance.period_activated, label: 'Date Added' }],
                [PeriodLeftField, { hidden: !searchFormInstance.period_left_institution, label: 'Date Left' }],
            ])
        }
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    toggleFieldHiddenState = (fieldKey, hiddenState) => {
        const { moreFields } = this.state
        const updatedFields = Array
            .from(moreFields)
            .map(([key, value]) => {
                const { label, hidden } = value
                return [
                    key,
                    { label, hidden: (fieldKey === key ? hiddenState : hidden) }
                ]
            })
        this.setState({ moreFields: updatedFields })
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        const { moreFields } = this.state
        const popoverContent = Array
            .from(moreFields)
            .map(([key, value], idx) => {
                const { hidden, label } = value
                return (
                    <React.Fragment key={label}>
                        {idx > 0 && <div className="divider" />}
                        <div className="Button Button--clear">
                            <Checkbox
                                checked={!hidden}
                                onChange={() => this.toggleFieldHiddenState(key, !hidden)}
                            >{label}
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            })

        return (
            <form className="GuardianListSearch-DesktopForm">
                <QueryField formState={this.formState} />
                <StatusField formState={this.formState} />
                <GenderField formState={this.formState} />
                <Button
                    buttonType="outline"
                    fullwidth
                    popover={{ content: popoverContent }}
                >
                    <img className="utils-prepend" src={filter} alt="" />
                    More Filters
                </Button>
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
                <MoreFields fields={moreFields} formState={this.formState} />
            </form>
        )
    }
}

class DirectSearchForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        return (
            <div className="GuardianListSearch-DirectSearchForm">
                <QueryField formState={this.formState} />
                <Button onClick={search} disabled={isLoading}>
                    Search
                </Button>
            </div>
        )
    }
}

GuardianListSearch.MobileForm = MobileForm
GuardianListSearch.TabletForm = TabletForm
GuardianListSearch.DesktopForm = DesktopForm
GuardianListSearch.DirectSearchForm = DirectSearchForm
