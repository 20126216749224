/* eslint-disable camelcase */
import { flow, getParent, types } from 'mobx-state-tree'
import { validationErrorsHandler } from '../helpers/errors'
import { Student } from './Student';
import { Batch } from './Batch';
import { SpecialRoleAssignment } from './SpecialRoleAssignment';
import { SubjectBase } from './SubjectBase';
import { SearchMeta } from './SearchMeta'
import { Assessment } from './Assessment'
import { AssessmentSearch } from '../actions/AssessmentSearch'

export const Subject = types
    .model('Subject', {
        id: types.identifier,
        type: 'subject',
        attributes: types.maybe(
            types.model({
                name: types.maybeNull(types.string),
                base_name: types.maybeNull(types.string),
                base_short_name: types.maybeNull(types.string),
                is_elective: types.maybeNull(types.boolean),
                student_count: types.maybeNull(types.number),
                employee_count: types.maybeNull(types.number),
            })
        ),
        relationships: types.maybe(
            types.model({
                batch: types.model({
                    data: types.maybeNull(
                        types.model({
                            id: types.late(() => types.reference(Batch)),
                        }),
                    ),
                }),
                subject_base: types.model({
                    data: types.model({
                        id: types.late(() =>
                            types.reference(SubjectBase),
                        )
                    })
                }),
                special_role_assignments: types.model({
                    data: types.array(
                        types.model({
                            id: types.late(() =>
                                types.reference(SpecialRoleAssignment),
                            )
                        })
                    )
                }),
                students: types.model({
                    data: types.array(
                        types.model({
                            id: types.late(() => types.reference(Student)),
                        })
                    )
                }),
                batch_students: types.model({
                    data: types.array(
                        types.model({
                            id: types.late(() => types.reference(Student)),
                        })
                    )
                }),
                registered_students: types.model({
                    data: types.array(
                        types.model({
                            id: types.late(() => types.reference(Student)),
                        })
                    )
                }),
            }),
        ),
        searchAssessmentInstance: types.optional(AssessmentSearch, {}),
        assessments: types.array(types.safeReference(Assessment)),
        assessmentsMeta: types.optional(SearchMeta, {}),
        selectedStudents: types.array(types.string),
        isLoading: true
    })
    .views(self => ({
        get subjectStore() {
            return getParent(self, 2)
        },
        get bluebic() {
            return self.subjectStore.bluebic
        },
        get subjectName() {
            try {
                return self.attributes.name;
            } catch (e) {
                return null;
            }
        },
        get scoresheet() {
            return self.subjectStore.subjectScoresheets.get(self.id)
        },
        get subjectBaseName() {
            try {
                return self.attributes.base_name;
            } catch (e) {
                return null;
            }
        },
        get assignedEmployees() {
            try {
                return self.relationships.special_role_assignments.data.map(
                    item => item.id
                )
            } catch (e) {
                console.warn(e);
                return [];
            }
        },
        get studentCount() {
            try {
                return self.attributes.student_count;
            } catch (e) {
                return null;
            }
        },
        get specialRoleAssignments(){
            try {
                return self
                    .relationships
                    .special_role_assignments
                    .data.map(item => item.id)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get employeeCount() {
            return self.attributes.employee_count
        },
        get isElective() {
            return self.attributes.is_elective;
        },
        get batch() {
            return self.relationships.batch.data.id
        },
        get batchName() {
            return self.batch.name
        },
        get subjectBase() {
            return self.relationships.subject_base.data.id
        },
        get studentsDoingSubject() {
            try {
                return self
                    .relationships
                    .students
                    .data
                    .map(({id}) => id)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get registeredStudents(){
            try {
                return self
                    .relationships
                    .registered_students
                    .data.map(item => item.id)
            } catch (e) {
                console.error(e)
                return []
            }
        },

        get assignableStudents() {
            const { registeredStudents } = self
            const { studentsInBatch } = self.batch;
            const registeredStudentsNotInBatch = []

            registeredStudents.forEach((student) => {
                if(!studentsInBatch.includes(student)) registeredStudentsNotInBatch.push(student)
            })

            return [ ...studentsInBatch, ...registeredStudentsNotInBatch]
        },

        get isAllStudentSelected() {
            return (self.selectedStudents.length > 0 ) && ( self.selectedStudents.length === self.studentsDoingSubject.length)
        }
    })).actions(self =>{
        function markLoading(loading) {
            self.isLoading = loading
        }
        function selectStudentById(id) {
            if (self.selectedStudents.includes(id)) {
                const index = self.selectedStudents.findIndex(id_ => id_ === id)
                self.selectedStudents.splice(index, 1)
            } else {
                self.selectedStudents.push(id)
            }
        }

        function selectAllStudents() {
            if (self.isAllStudentSelected) {
                self.selectedStudents.length = 0
            } else {
                self.studentsDoingSubject.forEach(({ id }) => {
                    if (!self.selectedStudents.includes(id)) {
                        self.selectedStudents.push(id)
                    }
                })
            }
        }

        function updateAssessmentReferences(assessments) {
            self.assessments=[]
            assessments.forEach(assessment => self.assessments.push(assessment.id))
        }

        function AddAssessmentReference({id}){
            const foundIndex = self.assessments.findIndex(({ id: AssID }) => AssID === id)
            if(foundIndex < 0){
                self.assessments.push( id )
            }
        }

        const getAssessments = flow(function* getAssessments() {
            try {
                markLoading(true)
                const { data, meta } = yield self.bluebic.subjectService.getAssessments(self.id, self.searchAssessmentInstance.toJSON())
                updateAssessmentReferences(data)
                self.subjectStore.updateAssessmentStore(data)
                self.assessmentsMeta = meta
                markLoading(false)
            } catch (err) {
                console.error("Failed to fetch assessments", err)
                markLoading(false)
                return validationErrorsHandler(err)
            }
            return null;
        })
        return{
            AddAssessmentReference,
            getAssessments,
            selectStudentById,
            selectAllStudents
        }
    })
