import { mergeDeep } from '../../lib/deepMerge'

function getSomething(key, error){
    const keys = key.split('.')
    const m = keys.shift()
    if (keys.length === 0) return {[m]: error}
    const subkeys = keys.join('.')
    return {[m]: getSomething(subkeys, error)}
}

export function validationErrorsHandler(e) {
    if (Object.prototype.hasOwnProperty.call(e, 'errors') && Array.isArray(e.errors)) {
        const { validation_errors } = e.errors.slice().shift()
        const converted_validation_errors = {}
        if (!validation_errors) return { errors: undefined }
        Object
            .keys(validation_errors)
            .forEach(key => {
                const error = validation_errors[key].slice().shift()
                mergeDeep(converted_validation_errors, getSomething(key, error));
            })
        return { errors: converted_validation_errors }
    }
    return { errors: undefined }
}