import { types } from "mobx-state-tree"

export const FeeType = types
    .model("FeeType", {
        id: types.identifier,
        type: types.literal("fee_type"),
        attributes: types.maybe(types.model({
            name: "",
            description: types.maybeNull(types.string),
            rank: types.number,
        })),
    })