import './StudentGuardianRelations.css'

import React, {Component} from 'react'
import { inject, observer } from 'mobx-react';
import { AsYouType } from 'libphonenumber-js';
import { Popover } from 'antd';
import * as PropTypes from "prop-types";

import Card from '../common/card';
import Avatar, { AVI_TYPES } from '../common/avatar';
import Button from '../common/button';
import more_vert from './assets/more-vert.svg'
import user_plus from '../common/assets/add-user-white.svg'
import AssignGuardian from './AssignGuardian';
import { ModalContext } from '../common/modal';
import Alert from '../common/alert';
import CreateGuardian from './CreateGuardian';
import block from '../common/assets/block.svg'
import user from './assets/user.svg'
import Loading from '../common/loading/WardGuardianCardLoading'
import GuardianPolicy from "../policies/GuardianPolicy";
import StudentGuardianRelationPolicy from "../policies/StudentGuardianRelationPolicy";
import {CreateStudent} from "../store/actions/Student";
import {Student} from "../store/models/Student";
import {modelOf} from "../lib/modelOf";
import {StudentGuardianRelation} from "../store/models/StudentGuardianRelation";

@inject("store", "display")
@observer
class StudentGuardianRelations extends React.Component{

    static propTypes = {
        student: PropTypes.oneOfType([modelOf(Student), modelOf(CreateStudent)]).isRequired,
        handleUnassignGuardian: PropTypes.func.isRequired,
        handleAssignGuardian: PropTypes.func.isRequired
    }
    
    render(){
        const { display, handleAssignGuardian, handleUnassignGuardian, student, store } = this.props
        const {meta: {currentUser}} = store
        const relationPolicy = new StudentGuardianRelationPolicy(currentUser, 'StudentGuardianRelation')

        let isLoaded
        let isLoading
        if (CreateStudent.is(student)){
            isLoaded = true
            isLoading = false
        }else if(Student.is(student)) {
            ({isLoading, isAssociationsLoaded: isLoaded } = student)
        }
        
        const studentId = student.id
        const relationsData = student.guardianRelations
        
        const popoverContent = (
            <ModalContext.Consumer>
                {context => (
                    <>
                        <button
                            type="button"
                            className="
                                Button Button--clear touchable-opacity
                                utils-fullwidth text--alignCenter text-p
                            "
                            onClick={() => context.setContent(
                                'Assign Guardian',
                                <AssignGuardian
                                    onSave={handleAssignGuardian}
                                    onClose={context.closeModal}
                                    studentId={studentId}
                                />
                            )}
                        >
                            Assign Existing Guardian
                        </button>
                        <div className="divider" />
                        <button
                            type="button"
                            className="
                                Button Button--clear touchable-opacity
                                utils-fullwidth text--alignCenter text-p
                            "
                            onClick={() => context.setContent(
                                'Add Guardian',
                                <CreateGuardian
                                    onSave={handleAssignGuardian}
                                    onClose={context.closeModal}
                                    studentId={studentId}
                                    editMode={false}
                                />
                            )}
                        >
                            Create New Guardian
                        </button>
                    </>
                )}
            </ModalContext.Consumer>
        )
        
        const popover = {
            content: popoverContent,
            placement: display.isDesktop ? 'bottomLeft' : 'top'
        }
        
        const information = () => {
            const {is_employee, is_admin} = currentUser
            
            return (
                <>
                    {(is_admin || is_employee) && (
                        <Button
                            title={relationPolicy.can_create ? undefined : "No permission."}
                            disabled={!relationPolicy.can_create}
                            popover={popover}
                        >
                            <img src={user_plus} className="utils-prepend" alt="add user" />
                            Assign Guardian
                        </Button>
                    )}
                    {relationsData.length === 0 && (
                        <Alert type="notice" message="No Guardians Added" />
                    )}
                    <div className="StudentGuardianRelations-grid">
                        {
                            relationsData.map((relationsDatum, idx) => {
                                return (
                                    <StudentGuardianRelationsItem
                                        relationData={relationsDatum}
                                        key={relationsDatum.id || idx}
                                        handleUnassignGuardian={() => handleUnassignGuardian(relationsDatum.id || idx)}
                                    />
                                )
                            })
                        }
                    </div>
                </>
            )
        }

        return (
            <Card title="Guardian(s) Information">
                <div className="StudentGuardianRelations">
                    { !isLoaded && isLoading && <Loading />  }
                    { isLoaded && information() }
                </div>
            </Card>
        )
    }
}

export default StudentGuardianRelations

@inject("store")
class StudentGuardianRelationsItem extends Component {
    static propTypes = {
        relationData: modelOf(StudentGuardianRelation).isRequired,
        handleUnassignGuardian: PropTypes.func.isRequired
    }
    
    render() {
        const {relationData, handleUnassignGuardian, store} = this.props;
        const {view: {openGuardianPage}, meta: {currentUser}} = store
        const {id: relationId, attributes: relationAttributes, guardian} = relationData
        const {relation, emergency_contact, authorized_to_pick_up} = relationAttributes
        const {
            id: guardianId,
            guardianName,
            attributes: {photo_url, mobile_phone, email, gender},
            studentGuardianRelations
        } = guardian

        const guardianPolicy = new GuardianPolicy(currentUser, guardian )

        return (
            <Card className="StudentGuardianRelationsItem">
                <Avatar
                    src={photo_url}
                    small
                    sex={gender}
                    type={AVI_TYPES.GUARDIAN}
                />
                <div>
                    
                    { guardianPolicy.can_show ? (
                        <a
                            href={`/guardians/${guardianId}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openGuardianPage(guardian)
                            }}
                        >
                            {guardianName}
                        </a>
                    ) : guardianName
                    }
                    <div className="text-small">{relation}</div>
                </div>
                <span>Mobile - {new AsYouType().input(mobile_phone)}</span>
                <span>Email - {email}</span>
                <span>Emergency Contact - {emergency_contact ? 'Yes' : 'No'}</span>
                <span>Can Pick Up? - {authorized_to_pick_up ? 'Yes' : 'No'}</span>
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row text--primary">
                        <div className="flex-row utils-prepend StudentGuardianRelationsItem-wards">
                            {studentGuardianRelations.map(({student: ward}) => (
                                <Avatar
                                    src={ward.attributes.photo_url}
                                    sex={ward.attributes.gender}
                                    title={ward.studentName}
                                    xsmall
                                    bordered
                                    borderColor="white"
                                    key={ward.studentName}
                                />
                            ))}
                        </div>
                        {studentGuardianRelations.length} wards
                    </div>
                    
                    {
                        currentUser.is_employee_or_admin && (
                            <Popover
                                content={(
                                    <PopoverContent
                                        guardian={guardian}
                                        handleUnassignGuardian={() => handleUnassignGuardian(relationId)}
                                    />
                                )}
                                placement="topRight"
                                overlayClassName="override-popover"
                            >
                                <img
                                    src={more_vert}
                                    className="touchable-opacity"
                                    alt="more_vert"
                                />
                            </Popover>
                        )
                    }
                    
                </div>
            </Card>
        )
    }
}

const PopoverContent = inject("store")(
    observer(({ guardian, store, handleUnassignGuardian }) => {
        const { view, meta: {currentUser} } = store
        const { openGuardianPageById } = view
        const guardianPolicy = new GuardianPolicy(currentUser, guardian)
        const relationPolicy = new StudentGuardianRelationPolicy(currentUser, 'StudentGuardianRelation')
        
        return (
            <>
                <button
                    type="button"
                    disabled={!relationPolicy.can_destroy}
                    title={relationPolicy.can_destroy.can_update ? undefined : "No permission."}
                    onClick={handleUnassignGuardian}
                    className="Button Button--clear touchable-opacity text-p"
                >
                    <img src={block} style={{ width: 15 }} className="utils-prepend" alt="" />
                    Unassign
                </button>
                <div className="divider" />
                <button
                    type="button"
                    disabled={!guardianPolicy.can_show}
                    title={guardianPolicy.can_destroy.can_update ? undefined : "No permission."}
                    className="Button Button--clear touchable-opacity text-p"
                    onClick={() => openGuardianPageById(guardian.id)}
                >
                    <img src={user} style={{ width: 15 }} className="utils-prepend" alt="" />
                    View Profile
                </button>
            </>
        )
    })
)
