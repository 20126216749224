import React from 'react'
import Card from '../card';
import ContentLoader from '../content_loader';
import Desktop from '../display/Desktop';
import Tablet from '../display/Tablet';
import Mobile from '../display/Mobile';

export default function AttendanceReportLoading() {
    return (
      <Card>
        <Desktop>
          <ContentLoader height="300" width="700"> 
            <rect x="0" y="0" rx="1" ry="1" width="60" height="5" />
            <rect x="0" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="0" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="15%" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="15%" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="28%" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="28%" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="45%" y="26" rx="1" ry="1" width="40" height="5" />
            <rect x="60%" y="26" rx="1" ry="1" width="40" height="5" />
            <rect x="75%" y="26" rx="1" ry="1" width="40" height="5" />
            <rect x="90%" y="26" rx="1" ry="1" width="40" height="5" />
          </ContentLoader>
        </Desktop>
        <Tablet>
          <ContentLoader height="200" width="1000"> 
            <rect x="38%" y="0" rx="1" ry="1" width="60" height="5" />
            <rect x="6%" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="8%" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="20%" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="22%" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="35%" y="20" rx="1" ry="1" width="30" height="5" />
            <rect x="37%" y="33" rx="1" ry="1" width="10" height="5" />
            <rect x="60%" y="18" rx="1" ry="1" width="40" height="5" />
            <rect x="60%" y="35" rx="1" ry="1" width="40" height="5" />
            <rect x="85%" y="18" rx="1" ry="1" width="40" height="5" />
            <rect x="85%" y="35" rx="1" ry="1" width="40" height="5" />
          </ContentLoader>
        </Tablet>
        <Mobile>
          <ContentLoader height="200" width="1000">
            <rect x="0" y="5" rx="2" ry="2" width="40%" height="10" />
            <rect x="10%" y="55" rx="2" ry="2" width="60" height="10" />
            <rect x="13%" y="80" rx="2" ry="2" width="30" height="10" />
            <rect x="40%" y="55" rx="2" ry="2" width="60" height="10" />
            <rect x="43%" y="80" rx="2" ry="2" width="30" height="10" />
            <rect x="70%" y="55" rx="2" ry="2" width="60" height="10" />
            <rect x="73%" y="80" rx="2" ry="2" width="30" height="10" />
          </ContentLoader>
        </Mobile>
      </Card>
    )
  }
  