import axios from './axios'
import { TESTENV } from './constants'
import createAccessTokenJSON from '../json/create_access_token_success.json'
import getInstitutionJSON from '../json/get_institution_using_domain_name_success.json'
import updateUserPasswordJSON from '../json/update_user_password.json'
import { formDataTransform } from './formDataTransform'

export function AuthService(env) {
    async function createToken(create_access_token) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/oauth/token`, create_access_token)
            return data
        }
        return createAccessTokenJSON
    }

    async function getInstitution(domain_name) {
        if (env !== TESTENV) {
            const url = domain_name
                ? `/api/v1/institution/find_by_domain_name?domain_name=${domain_name}`
                : '/api/v1/configuration/institution'
            const { data } = await axios.get(url)
            return data
        }
        return getInstitutionJSON
    }

    async function updateUserPassword(id, update_password) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/users/${id}/password`, { update_password })
            return data
        }
        return updateUserPasswordJSON
    }


    async function updateInstitution(institution) {
        if (env !== TESTENV) {
            const { data } = await axios.patch('/api/v1/configuration/institution', { institution }, { transformRequest: formDataTransform })
            return data
        }
        return {}
    }

    return {
        createToken,
        getInstitution,
        updateUserPassword,
        updateInstitution,
    }
}

export default AuthService(TESTENV)
