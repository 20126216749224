import './style.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TableHead from './TableHead'
import TableBody from './TableBody';
import TableLoading from './TableLoading'

export const CONSTANTS = Object.freeze({
  'CHECKED': '__CHECKED__'
})

export const List = props => <Table {...props} list />

class Table extends Component {
  state = { visibleColumnKeys: [] }

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      render: PropTypes.node,
      style: PropTypes.object,
      onSort: PropTypes.func,
      display: PropTypes.func,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    emptyMessage: PropTypes.node,
    striped: PropTypes.bool,
    bordered: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    containerClass: PropTypes.string,
    showHead: PropTypes.bool,
    header: PropTypes.node,
    footer: PropTypes.node,
    onRowSelectionChange: PropTypes.func,
    rowSelectionKey: PropTypes.string,
    selectedRows: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])),
    defaultVisibleColumnKeys: PropTypes.arrayOf(PropTypes.string),
    onColumnKeysChange: PropTypes.func,
    onError: PropTypes.func,
    loading: PropTypes.bool,
    // list: PropTypes.bool,
    // stackedFields: PropTypes.bool,
    responsive: PropTypes.bool,
    isAllSelected: PropTypes.bool,
    onSelectAll: PropTypes.func,
    useShowMore: PropTypes.bool,
    shouldSelectAllColumns: PropTypes.bool,
  }

  static defaultProps = {
    emptyMessage: "No records to display",
    striped: false,
    bordered: false,
    height: 'auto',
    containerClass: '',
    showHead: true,
    header: null,
    footer: null,
    onRowSelectionChange: null,
    // pagination: null,
    onError: () => { },
    loading: false,
    onColumnKeysChange: null,
    // list: false,
    // stackedFields: false,
    responsive: true,
    selectedRows: [],
    isAllSelected: false,
    rowSelectionKey: 'id',
    onSelectAll: null,
    defaultVisibleColumnKeys: null,
    useShowMore: false,
    shouldSelectAllColumns: false,
  }

  componentWillMount() {
    const { defaultVisibleColumnKeys } = this.props
    this.setState({ visibleColumnKeys: defaultVisibleColumnKeys })
    // this.visibleColumnKeys = defaultVisibleColumnKeys
  }

  get visibleColumns() {
    const { columns } = this.props
    const { visibleColumnKeys } = this.state
    if (visibleColumnKeys) {
      return columns.filter(col => visibleColumnKeys.includes(col.key))
    }
    return columns
  }

  get isDefaultVisibleColumnsKeysExceeded() {
    const { columns } = this.props
    return this.visibleColumns.length < columns.length
  }

  onRowSelect = row => {
    const { rowSelectionKey: key, onRowSelectionChange } = this.props
    if (key) {
      onRowSelectionChange(row[key])
    }
  }

  toggleAllChecked = () => {
    const { onSelectAll, isAllSelected } = this.props
    if (isAllSelected && onSelectAll) {
      onSelectAll(false)
    } else {
      onSelectAll(true)
    }
  }

  toggleColumnKeyVisibility = key => {
    const { onError, onColumnKeysChange } = this.props
    const { visibleColumnKeys } = this.state
    if (visibleColumnKeys.includes(key)) {
      this.setState({
        visibleColumnKeys: visibleColumnKeys.filter(_key => _key !== key)
      })
    } else {
      const visibleKeysMax = 6
      if (visibleColumnKeys.length === visibleKeysMax) {
        onError(`You can only view ${visibleKeysMax} columns at once`)
      } else {
        this.setState({
          visibleColumnKeys: [...visibleColumnKeys, key]
        })
        // this.visibleColumnKeys.push(key)
      }
    }
    if (onColumnKeysChange) {
      onColumnKeysChange(visibleColumnKeys.slice())
    }
  }

  render() {
    const {
      striped, height, bordered, columns, loading,
      containerClass, header, footer, showHead, onRowSelectionChange,
      data, selectedRows, rowSelectionKey, isAllSelected, responsive,
      emptyMessage, useShowMore, shouldSelectAllColumns,
    } = this.props

    const tableClass = classNames(
      'Table',
      containerClass,
      {
        'Table--striped': striped,
        'Table--bordered': bordered,
        'Table-isLoading': loading,
        'Table-responsive': responsive
      }
    )

    const content = (
      <React.Fragment>
        {Boolean(header) && <div className="Table-header">{header}</div>}

        <TableLoading loading={loading} />

        <table>
          {showHead && (
            <TableHead
              showToggleAllCheckbox={!!onRowSelectionChange && !shouldSelectAllColumns}
              onToggleAllChecked={this.toggleAllChecked}
              isAllChecked={isAllSelected}
              visibleColumns={this.visibleColumns}
              columns={columns}
              isDefaultVisibleColumnsKeysExceeded={this.isDefaultVisibleColumnsKeysExceeded}
              toggleColumnKeyVisibility={this.toggleColumnKeyVisibility}
            />
          )}
          {Boolean(data.length) && (
            <TableBody
              rows={data}
              selectedRows={selectedRows}
              rowSelectionKey={rowSelectionKey}
              onRowSelect={onRowSelectionChange ? this.onRowSelect : null}
              visibleColumns={this.visibleColumns}
              isDefaultVisibleColumnsKeysExceeded={this.isDefaultVisibleColumnsKeysExceeded}
              useShowMore={useShowMore}
              shouldSelectAllColumns={shouldSelectAllColumns}
            />
          )}
        </table>
        {Boolean(data.length) || (
          <React.Fragment>
            <div className="Table-emptyState">
              <i className="material-icons">inbox</i>
              <span>{emptyMessage}</span>
            </div>
          </React.Fragment>
        )}
        {Boolean(footer) && <div className="Table-footer">{footer}</div>}
      </React.Fragment>
    )

    return (
      <div className={tableClass} style={{ height }}>
        {content}
      </div>
    )
  }
}

export default Table
