import React from 'react'
import { observer, inject } from 'mobx-react';
import Notes from '../common/notes'
import { Note as action } from '../store/actions/Note'
import GuardianPolicy from "../policies/StudentPolicy";

const GuardianNotes = inject("store")(
    observer(({ store, notes }) => {
        const { guardianStore, view, meta: {currentUser} } = store
        const { selectedGuardian } = view
        const { isLoading, id: guardianId } = selectedGuardian

        const guardianPolicy = new GuardianPolicy(currentUser, selectedGuardian)
        

        const localNotes = notes
            .map(({ attributes, id, author, updated, visible }) => ({
                id, author, updated, visible, ...attributes,
            }))

        function addNote(note) {
            guardianStore.addNote(guardianId, action.create(note).toJSON())
        }

        function removeNote(noteId) {
            guardianStore.removeNote(guardianId, noteId)
        }

        function editNote(noteId, note) {
            guardianStore.editNote(guardianId, noteId, action.create(note).toJSON())
        }

        return (
            <Notes
                canCreateNote={guardianPolicy.can_create_note}
                isLoading={isLoading}
                notes={localNotes}
                onCreateNote={addNote}
                onDeleteNote={removeNote}
                onEditNote={editNote}
                visibilityText="Visible to parent"
            />
        )
    }))

export default GuardianNotes
