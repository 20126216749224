import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/finance_transactions.json'
import showFinanceTransactionJSON from '../json/finance_transaction_success.json'
import financeSummaryJSON from '../json/finance_transactions_summary.json'

export function FinanceTransactionService(env) {
    async function search(finance_transaction_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("finance_transaction_search", finance_transaction_search)
            const { data } = await axios.get(`/api/v1/finance/transactions?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showFinanceTransaction(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/finance/transactions/${id}`)
            return data
        }
        return showFinanceTransactionJSON
    }

    async function fetchFinanceSummary(ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/transactions/summary`, {transaction_ids: ids})
            return data
        }
        return financeSummaryJSON
    }
    
    async function downloadFinanceTransactionListSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/finance/transactions`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: {receipt_ids: action.item_ids}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        fetchFinanceSummary,
        search,
        showFinanceTransaction,
        downloadFinanceTransactionListSpreadsheet,
    }
}

export default FinanceTransactionService(TESTENV)