import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform";
import * as PropTypes from "prop-types";

import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Button from '../common/button';
import { ModalContext } from '../common/modal'
import ModalForm from '../containers/ModalForm'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import InlineError from '../common/form/InlineError';
import { CreateFee } from '../store/actions/Finance';
import SelectInput from '../common/form/SelectInput';
import MultilineInput from '../common/form/MultilineInput';
import DateInput from '../common/form/DateInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';

import plus from '../common/assets/plus.svg'
import plusBlue from '../common/assets/plus-blue.svg'
import FeePolicy from "../policies/FeePolicy";


function handleNewFee({ setContent, closeModal }) {
        return setContent(
            "New Fee",
            <NewFeeForm closeModal={closeModal} />,
            true
        )
}

function NewFee() {
    return (
        <ModalContext.Consumer>
            {(context) => (
                <NewFeeButton onClick={() => handleNewFee(context)} />
            )}
        </ModalContext.Consumer>
    )
}

export default NewFee

@inject('store')
class NewFeeButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired
    }
    
    render() {
        const {onClick, store} = this.props;
        
        const {meta: {currentUser}} = store
        const policy = new FeePolicy(currentUser, 'Fee')

        return (
            <>
                <Mobile>
                    <button
                        type="button"
                        disabled={!policy.can_create}
                        title={policy.can_create ? undefined : 'No permission.'}
                        className="Button touchable-highlight flex-row flex-alignCenter Button--flat Button--large text-p utils-fullwidth"
                        onClick={onClick}
                    >
                        <img src={plusBlue} alt="" className="utils-prepend" />
                        New Fee
                    </button>
                </Mobile>
                <NotMobile>
                    <Button
                        disabled={!policy.can_create}
                        title={policy.can_create ? undefined : 'No permission.'}
                        onClick={onClick}
                    >
                        <img src={plus} alt="" className="utils-prepend" />
                        New Fee
                    </Button>
                </NotMobile>
            </>
        )
    }
}

const form = new Form(CreateFee, {
    fee_type_id: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    amount: new Field(converters.string, { required: true }),
    description: new Field(converters.string, { required: true }),
    start_date: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    due_date: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    status: new Field(converters.string, {
        controlled: controlled.object,
    }),
})

@inject('store', 'display')
@observer
class NewFeeForm extends React.Component {
    static propTypes = {
        feeId: PropTypes.string,
        feeInfo: PropTypes.object,
        closeModal: PropTypes.func,
        editMode: PropTypes.bool
    }

    constructor(props) {
        super(props)
        const { editMode, feeInfo, feeId } = this.props;
        const {store: {feeStore: {createFee, editFee}}} = this.props
        const formInstance = CreateFee.create({})
        
        if (editMode && feeInfo) formInstance.setFormInstance(feeInfo);
        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                if(!editMode) {
                    const { errors } = await createFee(node.toJSON())
                    return errors
                } else {
                    const { errors } = await editFee(node.toJSON(), feeId)
                    return errors
                }
               
            }
        })
        
        if(!editMode) this.formState.field("status").setValue("inactive");
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const { display, closeModal, editMode, store} = this.props
        const {
            meta: { attributes: { fee_types } },
            currency,
            feeStore: { createFeeLoading }
        } = store
        
        let editIsLoading;
        
        if(editMode) {
            const { view : { selectedFee : { isLoading } } }= store
            editIsLoading = isLoading;
        }
        
        const fee_type_id = this.formState.field("fee_type_id")
        const description = this.formState.field("description")
        const amount = this.formState.field("amount")
        const start_date = this.formState.field("start_date")
        const due_date = this.formState.field("due_date")
        const status = this.formState.field("status")

        const statusOptions = [{ text: "inactive",value: "inactive" }, { text: "active",value: "active" }, { text: "hold",value: "hold" }]
        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={editMode ? "Update Fee" : "Create Fee"}
                isOkButtonDisabled={!this.formState.isValid || createFeeLoading || editIsLoading}
            >
                <Label text="Fee Type" inline={display.isNotMobile} required={fee_type_id.required}>
                    <InlineError field={fee_type_id}>
                        <SelectInput
                            {...fee_type_id.inputProps}
                            options={mapModelNameToOptions(fee_types)}
                            placeholder="Select fee type"
                        />
                    </InlineError>
                </Label>
                <Label text="Description" inline={display.isNotMobile} required={description.required}>
                    <InlineError field={description}>
                        <MultilineInput {...description.inputProps} />
                    </InlineError>
                </Label>
                <Label text={`Amount (${currency})`} inline={display.isNotMobile} required={amount.required}>
                    <InlineError field={amount}>
                        <TextInput {...amount.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Start Date" inline={display.isNotMobile} required={start_date.required}>
                    <InlineError field={start_date}>
                        <DateInput {...start_date.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Due Date" inline={display.isNotMobile} required={due_date.required}>
                    <InlineError field={due_date}>
                        <DateInput {...due_date.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Status" inline={display.isNotMobile}>
                    <InlineError field={status}>
                        <SelectInput
                            {...status.inputProps}
                            options={statusOptions}
                            placeholder="Select status"
                        />
                    </InlineError>
                </Label> 
            </ModalForm>
        )
    }
}

export {NewFeeForm}