import { types } from "mobx-state-tree"
import { GlobalIDPattern } from '../patterns/globalIDPattern'

export const Course = types
    .compose(
        "Course",
        GlobalIDPattern,
        types
            .model({
                id: types.identifier,
                type: types.literal("course"),
                attributes: types.maybe(types.model({
                    name: "",
                    abbreviation: "",
                    is_active: false,
                })),
            })
    )
