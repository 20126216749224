// TODO: THIS FILE NEEDS TO BE REFACTORED

import './CreateGuardian.css'

import React from 'react'
import { Field, Form, converters, controlled, RepeatingForm } from "mstform";
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react';

import { titleCase } from '../lib/titleCase';

import ModalForm from '../containers/ModalForm';
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import PhoneInput from '../common/form/PhoneInput';
import SelectInput from '../common/form/SelectInput';
import MultilineInput from '../common/form/MultilineInput';
import SwitchInput from '../common/form/SwitchInput';
import PhotoInput from '../common/form/PhotoInput';
import InlineError from '../common/form/InlineError';
import Avatar, { AVI_TYPES} from '../common/avatar'

import { Guardian } from '../store/actions/Guardian';
import { StudentGuardianRelation } from '../store/actions/StudentGuardianRelation';
import { genderOptions } from '../store/models/Gender';

const guardianForm = new Form(Guardian, {
    first_name: new Field(converters.string, { required: true }),
    last_name: new Field(converters.string, { required: true }),
    middle_name: new Field(converters.maybeNull(converters.string)),
    title: new Field(converters.maybeNull(converters.string)),
    gender: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    photo: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    email: new Field(converters.maybeNull(converters.string)),
    mobile_phone: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    phone: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    address: new Field(converters.maybeNull(converters.string)),
    student_guardian_relations_attributes: new RepeatingForm({
        student_id: new Field(converters.maybeNull(converters.string)),
        relation: new Field(converters.string, {required: true}),
        emergency_contact: new Field(converters.boolean),
        authorized_to_pick_up: new Field(converters.boolean),
    })
})
const studentGuardianRelationForm = new Form(StudentGuardianRelation, {
    student_id: new Field(converters.maybeNull(converters.string)),
    guardian_id: new Field(converters.maybeNull(converters.string)),
    relation: new Field(converters.string, { required: true }),
    emergency_contact: new Field(converters.boolean, { controlled: controlled.object }),
    authorized_to_pick_up: new Field(converters.boolean, { controlled: controlled.object }),
})

@inject("store")
@observer
class CreateGuardian extends React.Component {

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        guardianInfo: PropTypes.any,
        studentId: PropTypes.string,
        editMode: PropTypes.bool.isRequired
    }

    static defaultProps = {
        studentId: null,
        onClose: () => { }
    }

    state = {baseFormErrors: undefined }

    constructor(props) {
        super(props)
        const { studentId, editMode, guardianInfo, store, onSave } = this.props
        const { guardianStore : { updateGuardian, createGuardian}} = store

        const guardianFormInstance = Guardian.create({
            first_name: "",
            last_name: "",
            student_guardian_relations_attributes: []
        })

        if(!editMode) {
            this.studentGuardianRelationFormInstance = StudentGuardianRelation.create()
            this.studentGuardianRelationFormState = studentGuardianRelationForm.state(this.studentGuardianRelationFormInstance)
            this.studentGuardianRelationFormState.field("student_id").setValue(studentId)
        }

        if (editMode && guardianInfo) {
            guardianFormInstance.setFormInstance(guardianInfo);
        }

        this.formState =  guardianForm.state(guardianFormInstance, {
            addMode: false,
            save: async (node) => {
                if (studentId) {
                    if(node.repeatingForm) node.repeatingForm("student_guardian_relations_attributes").push(this.studentGuardianRelationFormInstance)
                }
                if(editMode) {
                    const {  errors } = await updateGuardian(guardianInfo.id, node)
                    if (errors) this.setState({baseFormErrors: errors.base})
                    return errors
                }else{

                    const {data: newGuardian, errors} = await createGuardian(node)
                    if (errors) this.setState({baseFormErrors: errors.base})
                    if (newGuardian) {
                        this.studentGuardianRelationFormState.field("guardian_id").setValue(newGuardian.id)
                        onSave(this.studentGuardianRelationFormInstance)
                    }
                    return errors
                }
            }
        })

        this.state = {formModel: guardianFormInstance}
    }

    handleCreateGuardian = async () => {
        const success = await this.formState.save()
        if (success) {
            const { onClose } = this.props
            onClose()
        }
    }

    render() {
        const { onClose, editMode } = this.props;
        const { formModel: {student_guardian_relations_attributes, isSaving } } = this.state;
        const {baseFormErrors} = this.state;
        
        
        const first_name = this.formState.field("first_name");
        const last_name = this.formState.field("last_name");
        const middle_name = this.formState.field("middle_name");
        const title = this.formState.field("title");
        const gender = this.formState.field("gender");
        const phone = this.formState.field("phone");
        const mobile_phone = this.formState.field("mobile_phone");
        const address = this.formState.field("address");
        const photo = this.formState.field("photo");
        const email = this.formState.field("email");


        const studentGuardianRelationsAttributesForm = this.formState.repeatingForm("student_guardian_relations_attributes");
        
        const okButtonText = isSaving ? 'Saving...' : (!editMode ? "Create Guardian" : "Update Guardian")
        return (
            <ModalForm
                okButtonText={okButtonText}
                onOk={this.handleCreateGuardian}
                onCancel={onClose}
                isOkButtonDisabled={isSaving}
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                
                <div className="CreateGuardian-form">
                    <Label text="Title" required={title.required}>
                        <InlineError field={title}>
                            <TextInput {...title.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Surname" required={last_name.required}>
                        <InlineError field={last_name}>
                            <TextInput {...last_name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="First name" required={first_name.required}>
                        <InlineError field={first_name}>
                            <TextInput {...first_name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Middle name" required={middle_name.required}>
                        <InlineError field={middle_name}>
                            <TextInput {...middle_name.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Phone number" required={mobile_phone.required}>
                        <InlineError field={mobile_phone}>
                            <PhoneInput {...mobile_phone.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Secondary number" required={phone.required}>
                        <InlineError field={phone}>
                            <PhoneInput {...phone.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Email" required={email.required}>
                        <InlineError field={email}>
                            <TextInput {...email.inputProps} />
                        </InlineError>
                    </Label>
                    <Label text="Gender" required={gender.required}>
                        <InlineError field={gender}>
                            <SelectInput
                                {...gender.inputProps}
                                options={genderOptions.map(option => ({ text: titleCase(option), value: option }))}
                            />
                        </InlineError>
                    </Label>
                    <UploadPhoto field={photo} />
                    <Label text="Address" required={address.required} style={{ gridColumn: 'span 2' }}>
                        <InlineError field={address}>
                            <MultilineInput {...address.inputProps} />
                        </InlineError>
                    </Label>
                </div>

                <div className="mt-1 mb-2">Ward Details</div>

                {!editMode && <StudentRelationForm formState={this.studentGuardianRelationFormState} />}

                {editMode && (
                    <div className="ward-Details">
                        {student_guardian_relations_attributes.map((elem, index) => {
                            const { guardianInfo : { students }} = this.props;
                            const guardianRelationsForm = studentGuardianRelationsAttributesForm.index(index);
                            return (<StudentRelationForm key={guardianRelationsForm.index} formState={guardianRelationsForm} students={students} editMode={editMode} />)
                        })}
                    </div>
                )}
            </ModalForm>
        )
    }
}
export default CreateGuardian

const StudentRelationForm = observer(({ formState, students, editMode }) => {
    const relation = formState.field("relation");
    const emergency_contact = formState.field("emergency_contact");
    const authorized_to_pick_up = formState.field("authorized_to_pick_up");
    const student_id = formState.field("student_id");

    let gender
    let studentName

    if(students){
        students.forEach((student) =>{
            if(student.id === student_id.value){
                gender = student.gender
                studentName = student.studentName
            }
        })
    }
    return (
        <div className={!editMode ? "CreateGuardian-wardDetails" : ''}>
            {editMode && (
                <Label text="Student Name" required={student_id.required}>
                    <InlineError field={student_id}>
                        <div style={{ position: 'relative'}}>
                            <Avatar
                                type={AVI_TYPES.STUDENT}
                                className="ward-input-image"
                                sex={gender}
                                src={null}
                            />

                            <TextInput
                                value={studentName}
                                disabled
                                className="wardName-input--text"
                                onChange={(e) => {}}
                            />
                        </div>
                    </InlineError>
                </Label>
            )}
            <Label text="Relationship" required={relation.required}>
                <InlineError field={relation}>
                    <TextInput {...relation.inputProps} placeholder="eg: Mother, Father, Uncle, Aunt" />
                </InlineError>
            </Label>
            <Label text="Emergency Contact">
                <InlineError field={emergency_contact}>
                    <SwitchInput {...emergency_contact.inputProps} value={emergency_contact.value} onChange={(e) => emergency_contact.setRaw(e)} />
                </InlineError>
            </Label>
            <Label text="Authorized to pick up">
                <InlineError field={authorized_to_pick_up}>
                    <SwitchInput {...authorized_to_pick_up.inputProps} value={authorized_to_pick_up.value} onChange={(e) =>  authorized_to_pick_up.setRaw(e)} />
                </InlineError>
            </Label>
        </div>
    )
})


const UploadPhoto = inject("display")(observer(({ display, field }) => {
    const InputControl = (
        <PhotoInput
            style={display.isDesktop ? { gridRow: "span 2", gridColumn: "span 2" } : null}
            {...field.inputProps}
        />
    )
    if (display.isDesktop) {
        return InputControl
    }
    return (
        <Label text="Upload Picture" required={field.required}>
            <InlineError field={field}>
                {InputControl}
            </InlineError>
        </Label>
    )
}))