/* eslint-disable react/no-multi-comp */
import "./FeeTransactionSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import { Row, Col, Checkbox } from 'antd';
import { FeeTransactionSearch as FeeTransactionSearchModel } from "../store/models/FeeTransactionSearch"
import SelectInput from '../common/form/SelectInput';
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'
import {
    formModelConfig,
    TransactionDateField,
    DateCreatedField,
    UsernameField,
    PaymentMethodField
} from "./FinanceTransactionSearch";

import filter from '../common/assets/filter-blue.svg'
import { mapModelNameToOptions } from "../lib/mapModelNameToOptions";

const localFormModelConfig = {
    fee_type_id: new Field(converters.string, {
        controlled: controlled.object
    }),
    ...formModelConfig
}
const formModel = new MstForm(FeeTransactionSearchModel, localFormModelConfig)

const FeeTypeField = inject("store")(
    observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { fee_types }
            }
        } = store
        const fee_type_id = formState.field("fee_type_id")
        return (
            <Label text="Fee Type">
                <SelectInput
                    {...fee_type_id.inputProps}
                    placeholder="Select fee type"
                    options={mapModelNameToOptions(fee_types)}
                    defaultOptionText="All fee types"
                />
            </Label>
        )
    })
)

export default function FeeTransactionSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" />
        Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, searchFormInstance } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <FeeTypeField formState={this.formState} />
                    <TransactionDateField formState={this.formState} />
                    <DateCreatedField formState={this.formState} />
                    <UsernameField formState={this.formState} />
                    <PaymentMethodField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { searchFormInstance, updateSearch, search, isLoading } } = this.props
        return (
            <form className="FeeTransactionSearch-TabletForm">
                <TransactionDateField formState={this.formState} />
                <DateCreatedField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(searchFormInstance)}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <FeeTypeField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <TransactionDateField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DateCreatedField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <UsernameField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <PaymentMethodField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

function MoreFields({ fields, formState }) {
    const moreFields = Array
        .from(fields)
        .map(([key, value]) => {
            // eslint-disable-next-line no-shadow
            const Field = key
            const { hidden, label } = value
            return hidden ? null : <Field key={label} formState={formState} />
        })
    return (
        <div className="FeeTransactionSearch-MoreFields">
            {moreFields}
        </div>
    )
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        this.state = {
            moreFields: new Map([
                [UsernameField, { hidden: !searchFormInstance.username, label: 'Username' }],
                [PaymentMethodField, { hidden: !searchFormInstance.payment_method, label: 'Payment Method' }],
            ])
        }
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    toggleFieldHiddenState = (fieldKey, hiddenState) => {
        const { moreFields } = this.state
        const updatedFields = Array
            .from(moreFields)
            .map(([key, value]) => {
                const { label, hidden } = value
                return [
                    key,
                    { label, hidden: (fieldKey === key ? hiddenState : hidden) }
                ]
            })
        this.setState({ moreFields: updatedFields })
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        const { moreFields } = this.state
        const popoverContent = Array
            .from(moreFields)
            .map(([key, value], idx) => {
                const { hidden, label } = value
                return (
                    <React.Fragment key={key}>
                        {idx > 0 && <div className="divider" />}
                        <div className="Button Button--clear">
                            <Checkbox
                                checked={!hidden}
                                onChange={() => this.toggleFieldHiddenState(key, !hidden)}
                            >{label}
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            })

        return (
            <form className="FeeTransactionSearch-DesktopForm">
                <FeeTypeField formState={this.formState} />
                <TransactionDateField formState={this.formState} />
                <DateCreatedField formState={this.formState} />
                <Button
                    buttonType="outline"
                    fullwidth
                    popover={{ content: popoverContent }}
                >
                    <img className="utils-prepend" src={filter} alt="" />
                    More Filters
                </Button>
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
                <MoreFields fields={moreFields} formState={this.formState} />
            </form>
        )
    }
}

FeeTransactionSearch.MobileForm = MobileForm
FeeTransactionSearch.TabletForm = TabletForm
FeeTransactionSearch.DesktopForm = DesktopForm
