import {types} from 'mobx-state-tree'
import {SmsTopupSearch} from "./actions/Message";
import {CollectionPattern} from "./patterns/collectionPattern";
import {filterType} from "./helpers/filterType";
import {SmsTopup} from "./models/SmsTopup";

export const SmsTopupStore = types
    .compose(
        'SmsTopupStore',
        CollectionPattern({
            collectionType: SmsTopup,
            searchType: SmsTopupSearch
        }),
        types
            .model({})
            .views(self => ({
                get searchService() {
                    return self.bluebic.messageService.sms_topup_search
                },
                get sms_topups() {
                    return self.items
                },
            }))
            .actions(self => {
                function onUpdate(included) {
                    filterType('sms_topup', included, self.updateCollection)
                }
                
                return {
                    onUpdate
                }
            }),
    )