import { types } from "mobx-state-tree"
import { Student } from "./Student";
import { Guardian } from "./Guardian";

export const StudentGuardianRelation = types
    .model("StudentGuardianRelation", {
        id: types.identifier,
        type: types.literal("student_guardian_relation"),
        attributes: types.model({
            relation: "",
            emergency_contact: false,
            authorized_to_pick_up: false,
        }),
        relationships: types.model({
            student: types.model({
                data: types.model({
                    id: types.late(() => types.reference(Student))
                })
            }),
            guardian: types.model({
                data: types.model({
                    id: types.late(() => types.reference(Guardian))
                })
            }),
        }),
    })

    .views(self => ({
        get student() {
            try {
                return self
                    .relationships
                    .student
                    .data
                    .id
            } catch (e) {
                console.error(e)
                return null
            }
        },
        get guardian() {
            try {
                return self
                    .relationships
                    .guardian
                    .data
                    .id
            } catch (e) {
                console.error(e)
                return null
            }
        },
    }))
