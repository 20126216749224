import { types } from 'mobx-state-tree'
import camelCase from 'lodash.camelcase'

export const GlobalIDPattern = types
    .model({})
    .views(self => ({
        get globalID() {
            let type = camelCase(self.type)
            type = type.charAt(0).toUpperCase() + type.slice(1) // convert to Pascal case
            return `gid://bluebic/${type}/${self.id}`
        },
    }))