import ApplicationPolicy from "./ApplicationPolicy";

class BatchPolicy extends ApplicationPolicy{

    get can_index() {
        const a = this.user.has_any_role([ 'batch_control', 'student_control', 'admission', 'enter_score', 'view_score'] )
        const b = this.user.has_any_special_role( ['class_teacher', 'principal', 'guidance_counsellor'])
        
        return a || b
    }
    
    get can_create() {
        return this.user.has_role('batch_control')
    }
    
    get can_import_setup() {
        return this.user.has_role('batch_control')
    }

    get can_update() {
        return this.can_create
    }

    get can_destroy() {
        return this.can_create
    }

    get can_attendance_register() {
        const a = this.user.has_any_role(['student_attendance_register', 'student_attendance_view'])
        const b = this.user.has_any_special_role( ['class_teacher', 'principal'], this.record)
        
        return a || b
    }
    
    get can_attendance_report() {
        return this.can_attendance_register
    }

    get can_demographics() {
        const a = this.user.has_any_role([ 'admission', 'student_control', 'batch_control'] )
        const b = this.user.has_any_special_role([ 'class_teacher', 'principal', 'guidance_counsellor'], this.record )

        return a || b
    }
    
    get can_update_role() {
        return this.user.has_role('batch_control')
    }
    
    get can_behavioural_scoresheet() {
        if (this.user.has_role('batch_control')) return true
        if (this.user.is_employee) return this.user.has_any_special_role( ['class_teacher', 'principal'], this.record)
        return false
    }
    
    get can_update_behavioural_scoresheet() {
        const a = this.user.has_role('enter_score')
        const b = this.user.has_special_role('class_teacher', this.record)
        return a || b
    }

}

export default BatchPolicy