import React from 'react'
import * as PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import { UnblockStudent } from '../store/actions/Block'
import ModalForm from '../containers/ModalForm';

@inject('store')
@observer
class EnableStudentLogin extends React.Component {
    static propTypes = {
        studentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    constructor(props) {
        super(props)
        const { studentIds: student_ids } = this.props
        const unblockStudentAction = UnblockStudent.create({ student_ids})
        this.state = { unblockStudentAction }
    }
    
    handleSubmit = () => {
        const { store: { studentStore: {unblockStudents} }, onClose } = this.props
        const { unblockStudentAction } = this.state
        unblockStudents(unblockStudentAction)
        onClose()
    }

    render() {
        const { studentIds, onClose } = this.props
        const { unblockStudentAction: {isSaving} } = this.state

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText={isSaving ? "Saving..." : "Confirm"}
                isOkButtonDisabled={isSaving}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i className="material-icons utils-prepend text--info" style={{ paddingTop: 4 }}>info</i>
                    <div>
                        <div className="text-h3 text--bold">Are you sure you want to unblock {studentIds.length} student(s)</div>
                        Unblocking a student means that such a student will be allowed access to the school&apos;s portal.
                    </div>
                </div>
            </ModalForm>
        )
    }
}
export default EnableStudentLogin