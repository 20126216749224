import { Col, Icon, Popconfirm, Popover, Row } from 'antd';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import Button from '../common/button';
import Card from '../common/card';
import Desktop from '../common/display/Desktop';
import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Tablet from '../common/display/Tablet';
import EmptyList from '../common/empty_list/empty_list';
import MobileListLoading from '../common/loading/MobileListLoading';
import MobileConfirmDialog from '../common/mobile_confirm_dialog';
import { ModalContext } from '../common/modal';
import Pagination from '../common/pagination/Pagination';
import Table from '../common/table';
import MobilePageHeader from '../containers/MobilePageHeader';
import Segment from '../containers/Segment';
import { scrollToTop } from '../lib/scrollToTop';
import AddSubject from './AddSubject';
import AssignRole from './AssignRole';
import SubjectListSearch from './SubjectListSearch';

import add from './assets/add-blue.svg';
import addWhite from './assets/add-white.svg';
import subjectIcon from './assets/book.svg';
import more_vert from './assets/more-vert.svg';

import SubjectPolicy from "../policies/SubjectPolicy";


import './SubjectList.css';



const { MobileForm, TabletForm, DesktopForm } = SubjectListSearch

const MoreButton = inject('store')(
    observer(({ subject, store: { subjectStore : { deleteSubjectById }} }) => {
        function handleDelete() {
            return deleteSubjectById(subject)
        }
        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <div className="SubjectList-popover SubjectList-popover--more">
                        <div 
                            onClick={() => context.setContent(
                                'Assign Employees',
                                <AssignRole onCancel={context.closeModal} subjectId={subject} />,
                                true
                            )}
                        >
                            Assign Employee
                        </div>
                        <div 
                            onClick={() => context.setContent(
                                'Edit',
                                <AddSubject onCancel={context.closeModal} editMode subjectId={subject} />,
                                true
                            )}
                        >
                            Edit
                        </div>
                        <Mobile>
                            <div
                                onClick={() => context.setContent(
                                    "Deleting subject...",
                                    <MobileConfirmDialog
                                        handleConfirm={() => {
                                            handleDelete()
                                            context.closeModal()
                                        }}
                                        handleCancel={context.closeModal}
                                    />,
                                    true
                                )}
                            >
                                Delete
                            </div>
                        </Mobile>
                        <NotMobile>
                            <Popconfirm
                                title="Are you sure you want to delete this subject?"
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={handleDelete}
                            >
                                <div>Delete</div>
                            </Popconfirm>
                        </NotMobile>
                    </div>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Popover placement="bottomRight" content={popover} overlayClassName="override-popover">
                <div className="SubjectList-more">
                    <img src={more_vert} alt="more_vert" />
                </div>
            </Popover>
        )
}
    )
) 

@inject('store','display')
@observer
class CreateSubjectBtn extends React.Component{
    render() {
        const {store: {meta: {currentUser}} , display } = this.props
        const subjectPolicy = new SubjectPolicy(currentUser, 'Batch')
        
        return (
            <ModalContext.Consumer>
                {(context) => (
                    <Button
                        className="flex-justifyEnd"
                        buttonType={display.isMobile ? 'flat': 'primary'}
                        disabled={!subjectPolicy.can_create}
                        title={subjectPolicy.can_create ? undefined : 'No permission.'}
                        fullwidth={display.isMobile}
                        onClick={() => context.setContent(
                            'New Subject',
                            <AddSubject onCancel={context.closeModal} />,
                            true
                        )}
                    >
                        <img className="utils-prepend" src={display.isMobile ? add : addWhite} alt="" />
                        Add Subject
                    </Button>
                )}
            </ModalContext.Consumer>
        )
    }
}


class                                                       SubjectList extends Component {
    async componentWillMount() {
        const { store } = this.props
        const { subjectStore } = store
        await subjectStore.loadSubjectBases()
        subjectStore.initPage() 
        subjectStore.search(                                                                                                        )
        
        reaction(
            () => subjectStore.isLoading,
            loading => {
                if (loading) scrollToTop()
            }
        )
    }

    get listSummary() {
        const { store } = this.props
        const { subjectStore } = store
        const {
            meta,
            searchResults,
            searchFormInstance
        } = subjectStore

        const totalCount = meta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = searchFormInstance.per_page * searchFormInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }
    

    render() {
        const { 
            store : { 
                subjectStore, 
                view 
            }
        } = this.props
        const {
            meta,
            isLoading,
            searchResults,
            searchFormInstance
        } = subjectStore

        const columns = [
            { key: 'name', render: 'Name' },
            { key: 'batch', render: 'Batch' },
            { key: 'students', render: 'Students' },
            { key: 'employee', render: 'Employees' },
            { key: 'is_elective', render: 'Is Elective?' },
            { key: 'options', render: '' },
        ]
        const tableData = searchResults
            .map(({id, subjectBaseName, employeeCount, studentCount, isElective, batch}) => ({
                name: (
                    <a
                        href={`/subjects/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openSubjectPageById(id)
                        }}
                    >
                        {subjectBaseName}
                    </a>
                ),
                batch: batch.attributes.name,
                students: studentCount,
                employee: employeeCount || (
                    <span>None (
                        <ModalContext.Consumer>
                         {context => (
                            <a
                                href='/'
                                onClick={(e) => {
                                    e.preventDefault()
                                    return context.setContent(
                                        'Assign Employees',
                                        <AssignRole onCancel={context.closeModal} subjectId={id} />,
                                        true
                                    )
                                }}
                            >
                                Assign
                            </a>
                         )}
                        </ModalContext.Consumer>
                    )
                    </span>
                ),
                is_elective: isElective ? "YES" : "NO",
                options: (<MoreButton subject={id} />),
            }))
        
        const pagination = (
            <Pagination
                pageCount={meta.total_pages}
                currentPage={searchFormInstance.page}
                onClickPrev={searchFormInstance.prevPage}
                onClickNext={searchFormInstance.nextPage}
            />
        )

        return (
            <div className="SubjectList">
                <NotMobile>
                    <Segment>
                        <Tablet>
                            <TabletForm store={subjectStore} />
                        </Tablet>
                        <Desktop>
                            <DesktopForm store={subjectStore} />
                        </Desktop>
                    </Segment>

                    <div className="flex-row flex-spaceBetween">
                        {this.listSummary}
                        <CreateSubjectBtn />
                    </div>
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        data={tableData}
                        onError={e => console.error(e)}
                        loading={isLoading}
                        footer={pagination}
                        responsive
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <img src={subjectIcon} alt="" />
                                <span className="text-small utils-append text--uppercase">Subjects List</span>
                            </Col>
                            <Col span={12}>
                                <CreateSubjectBtn />
                            </Col>
                        </Row>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={24}>
                                <MobileForm store={subjectStore} />
                            </Col>
                        </Row>
                    </MobilePageHeader>

                    {!isLoading && (searchResults.length === 0) && <EmptyList />}

                    {(isLoading || searchResults.length > 0) && (
                        <>
                            <div className="flex-row flex-spaceBetween">
                                {this.listSummary}
                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {searchResults.map(subject => <SubjectListItem id={subject.id} key={subject.id} subject={subject} />)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default inject("store")(observer(SubjectList))

const SubjectListItem = inject("store")(
    observer(
        ({ subject, store : { view } }) => {
            const { id, subjectBaseName, employeeCount, studentCount, isElective, batch } = subject
            return (
                <Card className="SubjectListItem mb-1">
                    <div className="SubjectListItem--span-3">{batch.attributes.name} | {isElective ? 'Elective' : 'Required'}</div>
                    <a 
                        className="SubjectListItem--span-3" 
                        href="/" 
                        onClick={(e) => {
                            e.preventDefault()
                            view.openSubjectPageById(id)
                        }}
                    >
                        {subjectBaseName}
                    </a>
                    <div>{studentCount} Students</div>
                    <div>{employeeCount} Employees {!employeeCount && <span>(<a href="/" onClick={(e) =>  e.preventDefault()}>Assign</a>)</span> }</div>
                    <div><MoreButton subject={id} /></div>
                </Card>
            )
        }
    ))