/* eslint-disable react/no-multi-comp */
import "./FinanceTransactionSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import { Row, Col } from 'antd';
import { FinanceTransactionSearch as FinanceTransactionSearchModel } from "../store/models/FinanceTransactionSearch";
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'

import { titleCase } from "../lib/titleCase";
import filter from '../common/assets/filter-blue.svg'
import { mapModelNameToOptions } from "../lib/mapModelNameToOptions";
import { dateRangeConverter } from '../lib/formatDate';


const formModelConfig = {
    transaction_date_range: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    date_created_range: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    username: new Field(converters.string, {
        controlled: controlled.string
    }),
    payment_method: new Field(converters.string, {
        controlled: controlled.object
    }),
}
const formModel = new MstForm(FinanceTransactionSearchModel, formModelConfig)
export { formModelConfig }

const TransactionDateField = observer(({ formState }) => {
    const transaction_date_range = formState.field("transaction_date_range")
    return (
        <Label text="Transaction Date">
            <DateRangeInput {...transaction_date_range.inputProps} />
        </Label>
    )
})

const DateCreatedField = observer(({ formState }) => {
    const date_created_range = formState.field("date_created_range")
    return (
        <Label text="Date Created">
            <DateRangeInput {...date_created_range.inputProps} />
        </Label>
    )
})

const UsernameField = observer(({ formState }) => {
    const username = formState.field("username")
    return (
        <Label text="Username">
            <TextInput placeholder="Student or Employee username" {...username.inputProps} />
        </Label>
    )
})

const PaymentMethodField = inject("store")(
    observer(({ store, formState }) => {
        const {
            defaultPaymentMethods,
            meta: {
                attributes: { custom_payment_methods }
            }
        } = store
        
        const options = defaultPaymentMethods.map((paymentMethod) => ({
            text: titleCase(paymentMethod),
            value: paymentMethod
        })).concat(
            mapModelNameToOptions(custom_payment_methods)
        )
        const payment_method = formState.field("payment_method")
        return (
            <Label text="Payment Method">
                <SelectInput
                    {...payment_method.inputProps}
                    placeholder="Select payment method"
                    options={options}
                    defaultOptionText="All payment methods"
                />
            </Label>
        )
    })
)

export { TransactionDateField, DateCreatedField, UsernameField, PaymentMethodField }
export default function FinanceTransactionSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" /> Filter
    </Button>
)

class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, searchFormInstance } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <TransactionDateField formState={this.formState} />
                    <DateCreatedField formState={this.formState} />
                    <UsernameField formState={this.formState} />
                    <PaymentMethodField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { searchFormInstance, updateSearch, search, isLoading } } = this.props
        return (
            <form className="FinanceTransactionSearch-TabletForm">
                <TransactionDateField formState={this.formState} />
                <DateCreatedField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(searchFormInstance)}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <TransactionDateField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DateCreatedField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <UsernameField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <PaymentMethodField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        return (
            <form className="FinanceTransactionSearch-DesktopForm">
                <TransactionDateField formState={this.formState} />
                <DateCreatedField formState={this.formState} />
                <UsernameField formState={this.formState} />
                <PaymentMethodField formState={this.formState} />
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

FinanceTransactionSearch.MobileForm = MobileForm
FinanceTransactionSearch.TabletForm = TabletForm
FinanceTransactionSearch.DesktopForm = DesktopForm
