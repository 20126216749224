import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import NotDesktop from '../display/NotDesktop'
import AccountActions from '../account_actions'
import { sidebar } from './index'

function SidebarHead() {
  const sidebarHeadClass = classNames('sidebar-head', {
    'sidebar-head-isExpanded': sidebar.isUserPaneVisible
  })
  return (
    <NotDesktop>
      <div className={sidebarHeadClass}>
        <AccountActions inverted />
      </div>
    </NotDesktop>
  )
}

export default observer(SidebarHead)
