import './GuardianFinance.css'

import React from 'react'
import {observer, inject} from 'mobx-react'
import {Icon, Popconfirm} from 'antd'
import numeral from "numeral";

import Table from '../common/table'
import Button from '../common/button'
import Desktop from '../common/display/Desktop'
import {ModalContext} from '../common/modal'
import Avatar from '../common/avatar'
// import { PayFeesModal, Avatar } from '../common/components'
import PayFees, { handlePayFees } from '../students/PayFees';

import pdf from './assets/pdf.svg'
import Alert from "../common/alert";


const columns = [
    {key: 'fee_type_name', render: 'Fee Type'},
    {key: 'fee_description', render: 'Description'},
    {key: 'fee_amount', render: 'Fee Amount'},
    {key: 'total_paid', render: 'Paid'},
    {key: 'balance', render: 'Unpaid'},
    {key: 'dueDate', render: 'Due Date'},
    {key: 'action', render: null},
]

@inject("store")
@observer
class GuardianFinanceItemDesktop extends React.Component{
    render(){
        const {store, ward}  = this.props
        const {formatCurrency, meta: {currentUser}, studentStore: {deleteFeeById}} = store

        const {
            id: studentID,
            attributes: wardAttributes,
            studentName,
            admissionNumber,
            unpaidStudentFees,
            selectedUnpaidFees,
            totalUnpaidStudentFees,
            totalSelectedUnpaidFees,
            areAllUnpaidFeesSelected,
            selectAllUnpaidFees,
            selectUnpaidFeeById
        } = ward
        const {photo_url, wallet_balance, gender} = wardAttributes
        
        
        const tableData = unpaidStudentFees.map((unpaidStudentFee) => {
            const {id, attributes: {fee_description, fee_type_name, fee_amount, total_paid, balance}, fee} = unpaidStudentFee
            const  {attributes: {due_date: dueDate}} = fee

            const morePopoverContent = (
                <div className="flex-row">
                    <PayFees studentFeeIds={[id]} studentID={studentID} />

                    { currentUser.is_employee_or_admin && (
                        <Popconfirm
                            title="Are you sure you want to unassign this fee?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={() => deleteFeeById(id)}
                        >
                            <button type="button" className="touchable-opacity text--danger text-p utils-append">
                                Unassign
                            </button>
                        </Popconfirm>
                    )}
                </div>
            )
            
            return {
                id,
                fee_description,
                fee_type_name,
                fee_amount: formatCurrency(fee_amount),
                total_paid: formatCurrency(total_paid),
                balance: formatCurrency(balance),
                dueDate,
                action: morePopoverContent
            }
        })

        const feeIdsToPay = selectedUnpaidFees.length > 0 ? selectedUnpaidFees.toJSON() : unpaidStudentFees.map(({id}) => id)

        return (
            <Desktop>
                <div className="financeItem">
                    <div className="financeItem-summary">
                        <div>
                            <Avatar sex={gender} src={photo_url} small />
                            <div>
                                <div className="text-h4">{studentName}</div>
                                <div className="text-p">{admissionNumber}</div>
                            </div>
                        </div>
                        <div>
                            <div className="flex-row flex-alignCenter text-p">
                                <span className="utils-prepend">Wallet Balance:</span>
                                <span className={`${numeral(wallet_balance).value() > 0 ? 'text--primary text--bold' : ''}`}>{formatCurrency(wallet_balance)}</span>
                            </div>
                            <div className="flex-row flex-alignCenter text-p">
                                <span className="utils-prepend">Due Fees:</span>
                                <span className={`${numeral(totalUnpaidStudentFees).value() > 0 ? 'text--danger text--bold' : ''}`}>{formatCurrency(totalUnpaidStudentFees)}</span>
                            </div>
                        </div>
                        { false && (
                            <div className="flex-row flex-alignCenter text-p touchable-opacity">
                                <img src={pdf} alt="pdf" className="utils-prepend" />
                                <span>Print As Pdf</span>
                            </div>
                        )}

                    </div>
                    
                    <div className="guardianFinance-head">
                        <ModalContext.Consumer>
                            {(context) => (
                                <Button
                                    buttonType="success"
                                    onClick={() => handlePayFees(context, feeIdsToPay, studentID)}
                                >
                                    { totalUnpaidStudentFees > 0 && (
                                        <>Pay {selectedUnpaidFees.length > 0 ? `Selected (${selectedUnpaidFees.length})` : 'Due Fees'}</>
                                    ) }

                                    { totalUnpaidStudentFees <= 0 && (
                                        <>Credit Student Wallet</>
                                    ) }

                                </Button>
                            )}
                        </ModalContext.Consumer>
                        {selectedUnpaidFees.length > 0 && (
                            <span className="utils-append text-p">
                                Selected Fees: {formatCurrency(totalSelectedUnpaidFees)}
                            </span>
                        )}
                    </div>

                    { tableData.length === 0 && (
                        <div className="mt-3 mb-1">
                            <Alert
                                type="success"
                                title="Hurray!"
                                message="No pending unpaid fees."
                            />
                        </div>
                    ) }

                    { tableData.length > 0 && (
                        <Table
                            striped
                            columns={columns}
                            data={tableData}
                            onRowSelectionChange={selectUnpaidFeeById}
                            selectedRows={selectedUnpaidFees}
                            isAllSelected={areAllUnpaidFeesSelected}
                            onSelectAll={selectAllUnpaidFees}
                        />
                    ) }
                </div>
            </Desktop>
        )
    }
}

export default GuardianFinanceItemDesktop