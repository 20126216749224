import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/list/main.css'

import Card from '../common/card'

import calendarIcon from './assets/calendar.svg'
import SelectButtonGroup from '../common/select_button_group/SelectButtonGroup'
import plus from '../common/assets/plus.svg'
import arrow_left from '../common/assets/arrow_left.svg'
import arrow_right from '../common/assets/arrow_right.svg'
import Button from '../common/button'
import EventPolicy from "../policies/EventPolicy";


@inject('store','display')
@observer
class Events extends Component {
    calendarRef = React.createRef()
    
    constructor(props) {
        super(props)

        const { display } = this.props

        const termOptions = [
            { label: 'List', value: 'listYear' },
            { label: 'Month', value: 'dayGridMonth' },
            { label: 'Week', value: 'timeGridWeek' },
            { label: 'Day', value: 'timeGridDay' }
        ]

        const defaultView = display.isMobile ? "listYear" : "dayGridMonth"
        
        this.state = {termOptions, defaultView, currentView: defaultView, viewTitle: 'Loading...'}
    }


    getEvents = async (fetchInfo, successCallback, failureCallback) => {
        const { store: { eventStore, meta: {currentUser} } } = this.props
        const policy = new EventPolicy(currentUser, 'Event')
        try {
            eventStore.searchFormInstance.setFormInstanceFromFullCalendarFetchInfo(fetchInfo)
            eventStore.searchFormInstance.setAllEvents(policy.can_index)
            const events = await eventStore.getEvents()
            successCallback(events.map(event => event.fullCalendarFormat))
        } catch(e) {
            failureCallback(e)
        }
    }

    handleViewSelect = (selectedOption) => {
        const calendarApi = this.calendarRef.current.getApi()
        calendarApi.changeView(selectedOption, Date.now());
    }

    handleDatesRender = ({view}) => {
        const { viewTitle } = this.state
        if (viewTitle === view.title) return
        this.setState({ viewTitle: view.title })
    }

    handleClickNext = () => {
        const calendarApi = this.calendarRef.current.getApi()
        calendarApi.next()
    }

    handleClickPrevious = () => {
        const calendarApi = this.calendarRef.current.getApi()
        calendarApi.prev()
    }
    
    render() {
        const { store: { view: {openEventPageById, openAddEventPage}, meta: {currentUser} } } = this.props
        const { defaultView, viewTitle, termOptions, currentView } = this.state
        const { display } = this.props
        const aspectRatio = display.isMobile ? 0.9 : display.isDesktop ? 1.8 : 1.5
        
        
        const policy = new EventPolicy(currentUser, 'Event')
        
        
        
        
        const FullCalendarNode = (
            <FullCalendar
                ref={this.calendarRef}
                defaultView={defaultView}
                navLinks
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                events={this.getEvents}
                header={null}
                eventClick={(info) => {
                    info.jsEvent.preventDefault();
                    openEventPageById(info.event.id)
                }}
                datesRender={this.handleDatesRender}
                aspectRatio={aspectRatio}
            />
        )

        return (
            <div className="EventCalendar">
                <Card
                    className="mb-1"
                    title={(
                        <div className="flex-row">
                            <img src={calendarIcon} className="utils-prepend" alt="" />
                            Event Calendar
                        </div>
                    )}
                >
                    <div className='flex-row flex-spaceBetween flex-wrap'>
                        <SelectButtonGroup
                            selectOptions={termOptions}
                            value={currentView}
                            onChange={this.handleViewSelect}
                        />
                        
                        <div className='flex-row mb-1 mt-1'>
                            <button type="button" title="Edit" onClick={this.handleClickPrevious}>
                                <img src={arrow_left} className="touchable-opacity" alt="" />
                            </button>
                            <div className='text-h2 inline-block pl-1 pR-1'>
                                {viewTitle}
                            </div>
                            <button type="button" title="Edit" onClick={this.handleClickNext}>
                                <img src={arrow_right} className="touchable-opacity" alt="" />
                            </button>
                        </div>
                        
                        <div>
                        { (currentUser.is_admin || currentUser.is_employee) && (
                            <Button
                                disabled={!policy.can_create}
                                title={policy.can_create ? undefined : "No permission."}
                                onClick={openAddEventPage}
                            >
                                <img className="utils-prepend" src={plus} alt="" />
                                Add Event
                            </Button>
                        )}
                        </div>
                    </div>
                </Card>
                
                <Card>
                    {FullCalendarNode}
                </Card>
            </div>


        )
                            
        
        

    }
}

export default Events