import ApplicationPolicy from "./ApplicationPolicy";

class UserPolicy extends ApplicationPolicy{
    get can_update_password(){
        if (!this.record) return false
        if (this.user === this.record) return true  // Users can update their own password
        if (this.record.is_student || this.record.is_guardian) return this.user.has_role('student_control')
        if (this.record.is_employee) return this.user.has_role('hr_control')
        return this.user.is_admin
    }

    get can_view_profile(){
        return this.user === this.record
    }

    get can_show(){
        return this.user === this.record || this.user.is_admin
    }
        
    
}

export default UserPolicy