import ApplicationPolicy from "./ApplicationPolicy";

class GuardianPolicy extends ApplicationPolicy{

    get can_index(){
        return this.user.has_any_role(['admission', 'student_control', 'student_view'])
    }

    get can_show(){
        if (this.user.is_guardian){
            return this.user === this.record.user
        }
        return this.user.has_any_role(['admission', 'student_control', 'student_view'])
    }

    get can_create(){
        return this.user.has_any_role(['admission', 'student_control'])
    }

    get can_update(){
        return this.can_create
    }

    get can_destroy(){
        return this.user.has_role('student_control')
    }

    get can_create_note() {
        return this.user.has_any_role([ 'admission', 'student_control', 'finance', 'finance_control'] )
    }
}

export default GuardianPolicy