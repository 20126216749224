import {types, flow} from 'mobx-state-tree'
import * as ReactGA from "react-ga";

import {Message, MessageRecipient} from './models/Message'
import { validationErrorsHandler } from './helpers/errors'
import { MessageSearch } from "./actions/Message";
import {CollectionPattern} from "./patterns/collectionPattern";
import {filterType} from "./helpers/filterType";


export const MessageStore = types
    .compose(
        'MessageStore',
        CollectionPattern({
            collectionType: Message,
            searchType: MessageSearch
        }),
        types
            .model({
                message_recipients: types.map(MessageRecipient)
            })
            .views(self => ({
                get searchService() {
                    return self.bluebic.messageService.search
                },
                get messages() {
                    return self.items
                },
            }))
            .actions(self => {

                function updateMessageRecipients(mrs) {
                    mrs.forEach(message_recipient => {
                        self.message_recipients.put(message_recipient)
                    })
                }
                
                function markMessageLoadingById(id, flag) {
                    if (!self.items.has(id)) self.items.put({ id })
                    self.items.get(id).isLoading = flag
                }

                const loadMessageById = flow(function* loadMessageById(id) {
                    try {
                        markMessageLoadingById(id, true)
                        const { data, included } = yield self.bluebic.messageService.getMessage(id)
                        self.bluebic.handleUpdateStores([data, ...included])
                        markMessageLoadingById(id, false)
                    } catch (err) {
                        markMessageLoadingById(id, false)
                        console.error(`Failed to load message with id ${id}`, err)
                    }
                })

                const sendMessage = flow(function* sendMessage(message) {
                    try {
                        message.markSaving(true)
                        const {client_state, ...action_without_client} = message
                        const { data, included } = yield self.bluebic.messageService.sendMessage(action_without_client)
                        self.bluebic.handleUpdateStores([data, ...included])
                        message.markSaving(false)
                        ReactGA.event({
                            category: 'Communication',
                            action: 'Send Message',
                            nonInteraction: false,
                            // 'label: 'Game Widget'',
                            // value: undefined,
                        });
                        return { data }
                    } catch (err) {
                        console.error("Failed to send Message", err)
                        message.markSaving(false)
                        return validationErrorsHandler(err)
                    }
                })
                
                function onUpdate(included) {
                    filterType('message', included, self.updateCollection)
                    filterType('message_recipient', included, updateMessageRecipients)
                }
                
                return {
                    sendMessage,
                    loadMessageById,
                    onUpdate
                }
            }),
    )