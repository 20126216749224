import React from 'react'
import Card from '../card';
import ContentLoader from '../content_loader';
import Desktop from '../display/Desktop';
import Tablet from '../display/Tablet';
import Mobile from '../display/Mobile';

export default function EditFormLoading() {
    return (
        <Card>
            <ContentLoader height="200" width="600">
                <Desktop>
                    <rect x="0" y="3" rx="1" ry="1" width="60" height="5" />
                    {[0, 200, 400].map(x => (
                        <React.Fragment key={x}>
                            <rect x={x} y="25" rx="1" ry="1" width="40" height="5" />
                            <rect x={x} y="35" rx="2" ry="2" width="190" height="25" />
                        </React.Fragment>
                    ))}
                </Desktop>
                <Tablet>
                    <rect x="45%" y="3" rx="1" ry="1" width="80" height="5" />
                    {[0, 300].map(x => (
                        <React.Fragment key={x}>
                            <rect x={x} y="25" rx="1" ry="1" width="40" height="5" />
                            <rect x={x} y="35" rx="2" ry="2" width="290" height="25" />
                        </React.Fragment>
                    ))}
                </Tablet>
                <Mobile>
                    <rect x="225" y="3" rx="4" ry="4" width="150" height="20" />
                    <React.Fragment>
                        <rect x="0" y="85" rx="4" ry="4" width="120" height="15" />
                        <rect x="0" y="115" rx="6" ry="6" width="600" height="70" />
                    </React.Fragment>
                </Mobile>
            </ContentLoader>
        </Card>
    )
}