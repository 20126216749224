import axios from './axios'
import { TESTENV } from './constants'
import sendMessageJSON from '../json/send_message.json'
import getAssessmentJSON from "../json/assessment_data";
import {stringifyQuery} from "../lib/stringifyQuery";
import searchJSON from "../json/report_card_groups";



export function MessageService(env) {

    async function search(action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("message_search", action)
            const { data } = await axios.get(`/api/v1/messages?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function sms_topup_search(action) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("sms_topup_search", action)
            const { data } = await axios.get(`/api/v1/messages/sms_topups?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function getMessage(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/messages/${id}`)
            return data
        }
        return getAssessmentJSON
    }
    
    async function sendMessage(message_action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/messages`, { message: message_action })
            return data
        }
        return sendMessageJSON
    }

    return {
        search,
        sms_topup_search,
        getMessage,
        sendMessage
    }
}

export default MessageService(TESTENV)