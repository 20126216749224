import { types } from "mobx-state-tree"
import { FeeType } from "./FeeType"
import { User } from "./User"
import { StudentFee } from "./StudentFee"
import { FeeTransaction } from "./FeeTransaction"
import { LoadingPattern } from "../patterns/LoadingPattern"
import { StudentFeeDiscount } from "./StudentFeeDiscount"

export const Fee = types
    .compose(
        LoadingPattern(),
        types
            .model("Fee", {
                id: types.identifier,
                type: "fee",
                attributes: types.model({
                    fee_type_id: types.reference(FeeType),
                    amount: "",
                    start_date: "",
                    due_date: "",
                    description: "",
                    status: "",
                    total_discount: "",
                    total_paid: "",
                    expected_income: "",
                    total_balance: "",
                    created_by_name: "",
                    created_at: "",
                }),
                relationships: types.maybe(types.model({
                    fee_type: types.maybe(types.model({
                        data: types.model({
                            id: types.late(() => types.safeReference(FeeType))
                        })
                    })),
                    created_by: types.maybe(types.model({
                        data: types.model({
                            id: types.late(() => types.safeReference(User))
                        })
                    })),
                    student_fees: types.maybe(types.model({
                        data: types.array(types.model({
                            id: types.late(() => types.safeReference(StudentFee))
                        }))
                    })),
                    student_fee_transactions: types.maybe(types.model({
                        data: types.array(types.model({
                            id: types.late(() => types.safeReference(FeeTransaction))
                        }))
                    })),
                    student_fee_discounts: types.maybe(types.model({
                        data: types.array(types.model({
                            id: types.late(() => types.reference(StudentFeeDiscount))
                        }))
                    })),
                })),
                // isLoading: true,
            })
            .views(self => ({
                get feeType() {
                    return self.attributes.fee_type_id
                },
                get studentFees(){
                    try {
                        return self.relationships.student_fees.data.map(({id}) => id)
                    } catch (e) {
                        console.warn(e)
                        return []
                    }
                }
            }))
            .actions(self => ({
                removeStudentFee(student_fee_id){
                    const foundFeeIndex = self.studentFees.findIndex(({id}) => id === student_fee_id)
                        if(foundFeeIndex >=0){
                            self.relationships.student_fees.data.splice(foundFeeIndex, 1);
                        }
    
                }
            }))
    )
