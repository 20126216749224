import { types } from 'mobx-state-tree'

export const AssessmentCategory = types
    .model('AssessmentCategory', {
        id: types.identifier,
        type: types.literal('assessment_category'),
        attributes: types.maybe(types.model({
            name: '',
            abbreviation: types.maybeNull(types.string),
            description: types.maybeNull(types.string),
        })),
    })