import React from 'react'
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { modelOf } from "../lib/modelOf"
import SelectInput from '../common/form/SelectInput';
import DateInput from '../common/form/DateInput';
import MultilineInput from '../common/form/MultilineInput';
import Label from '../common/form/Label';
import { Transfer } from '../store/actions/Transfer'
import { StudentBatchTransfer } from '../store/models/StudentBatchTransfer';
import ModalForm from '../containers/ModalForm';

const formModel = new MstForm(Transfer, {
    batch_id: new Field(converters.string, {
        controlled: controlled.object,
        required: true
    }),
    first_day_in_batch: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object,
        required: true
    }),
    last_day_in_batch: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.object,
        required: true
    }),
    transfer_out_reason: new Field(converters.maybeNull(converters.string), {
        controlled: controlled.string,
        required: true
    }),
})

@inject("store", "display")
@observer
class EditStudentBatchHistoryItem extends React.Component {
    static propTypes = {
        batchHistoryItem: modelOf(StudentBatchTransfer).isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    constructor(props) {
        super(props)
        const { batchHistoryItem } = props
        const { attributes, firstDay, lastDay } = batchHistoryItem
        const { batch_id, transfer_out_reason } = attributes
        const formInstance = Transfer.create({
            batch_id: batch_id.id,
            first_day_in_batch: firstDay,
            last_day_in_batch: lastDay,
            transfer_out_reason
        })
        this.formState = formModel.state(formInstance)
    }

    get batchOptions() {
        const { store } = this.props
        const { batches } = store
        const batchOptions = []
        batches.forEach(({ id, attributes }) => {
            batchOptions.push({ text: attributes.name, value: id })
        })
        return batchOptions
    }

    handleSubmit = () => {
        const { store, batchHistoryItem, onClose } = this.props
        const { studentStore } = store
        const { updateBatchTransfer } = studentStore
        const { id } = batchHistoryItem
        updateBatchTransfer(id, this.formState.value)
        onClose()
    }

    render() {
        const batch_id = this.formState.field("batch_id")
        const first_day_in_batch = this.formState.field("first_day_in_batch")
        const last_day_in_batch = this.formState.field("last_day_in_batch")
        const transfer_out_reason = this.formState.field("transfer_out_reason")
        const { onClose, display, store:{ studentStore : {isLoading}} } = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Save"
                isOkButtonDisabled={!this.formState.isValid || isLoading}
            >
                <Label
                    text="Batch"
                    inline={display.isNotMobile}
                    required={batch_id.required}
                >
                    <SelectInput
                        {...batch_id.inputProps}
                        options={this.batchOptions}
                        placeholder="Select batch"
                        disabled
                    />
                </Label>
                <Label text="First Day In Batch" required={first_day_in_batch.required} inline={display.isNotMobile}>
                    <DateInput {...first_day_in_batch.inputProps} />
                </Label>
                <Label text="Last Day In Batch" required={last_day_in_batch.required} inline={display.isNotMobile}>
                    <DateInput {...last_day_in_batch.inputProps} />
                </Label>
                <Label text="Reason" required={transfer_out_reason.required} inline={display.isNotMobile}>
                    <MultilineInput {...transfer_out_reason.inputProps} />
                </Label>
            </ModalForm>
        )
    }
}
export default EditStudentBatchHistoryItem