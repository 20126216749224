import {applySnapshot, types} from "mobx-state-tree"

export const ActionPattern = (defaultSettings = {'defaultSaving': false}) => {
    const defaultSaving = defaultSettings.hasOwnProperty('defaultSaving') ? defaultSettings['defaultSaving'] : false
    let model = {
        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, defaultSaving),
            nullFieldsToExclude: types.optional(types.array(types.string), ['photo', 'signature_photo', 'attached_documents_attributes', 'batch_id_cache']),
        }), {
            isSaving : defaultSaving,
            nullFieldsToExclude: ['photo', 'signature_photo', 'attached_documents_attributes', 'batch_id_cache']
        })
    }

    return types
        .model(model)
        .views(self => ({
            get isSaving() {
                return self.client_state.isSaving
            },
            
            get nullFieldsToExclude(){
                return self.client_state.nullFieldsToExclude
            }
        }))
        .actions(self => {
            function markSaving(flag){
                self.client_state.isSaving = flag
            }

            function reset() {
                applySnapshot(self, {})
            }

            return { markSaving, reset }
        })
}