import { types, getParent } from "mobx-state-tree"
import { Gender } from "./Gender"
import { dateRangeType } from "../types/dateRangeType";

export const statusOptions = ["active", "left_institution"]

export const EmployeeSearch = types
    .model("EmployeeSearch", {
        query: "",
        status: types.optional(
            types.enumeration("status", statusOptions),
            "active"
        ),
        gender: types.maybe(Gender),
        joining_period: types.maybe(dateRangeType),
        date_of_birth_period: types.maybe(dateRangeType),
        category_id: types.maybe(types.string),
        department_id: types.maybe(types.string),
        position_id: types.maybe(types.string),
        include_total_ids: true,
        page: 1,
        per_page: 20
    })
    .actions(self => ({
        nextPage() {
            const { meta, search } = getParent(self)
            const { total_pages } = meta
            if (self.page < total_pages) {
                self.page += 1
                search()
            }
        },
        prevPage() {
            const { search } = getParent(self)
            if (self.page > 1) {
                self.page -= 1
                search()
            }
        }
    }))