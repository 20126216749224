import React from 'react'
import ContentLoader from '../content_loader';

const Loading = () => (
    <ContentLoader height="100" width="400">
        <rect x="0" y="10" rx="4" ry="4" width="60%" height="8" />
        <rect x="0" y="40" rx="4" ry="4" width="40%" height="8" />
        <rect x="0" y="70" rx="4" ry="4" width="55%" height="8" />
    </ContentLoader>
)

export default Loading