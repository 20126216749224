import './DiscountsForStudent.css';

import React from 'react';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {Popconfirm, Icon} from 'antd';
import Desktop from '../common/display/Desktop';
import Tablet from '../common/display/Tablet';
import Mobile from '../common/display/Mobile';
import Table from '../common/table';
import Card from '../common/card';
import AddDiscountForm from './AddDiscountForm';
import bin from '../common/assets/bin.svg';
import LoadingText from './LoadingText'
import {modelOf} from "../lib/modelOf";
import {StudentFee} from "../store/models/StudentFee";

@inject('store')
@observer
class DiscountsForStudent extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        studentFee: modelOf(StudentFee).isRequired,
        shouldFocus: PropTypes.bool
    };
    
    static defaultProps = {
        onClose: () => {},
        shouldFocus: false
    };

    handleDelete = (selected_fee_id, id) => {
        const {store} = this.props;
        const {feeStore: {deleteDiscountbyId}} = store;
        deleteDiscountbyId(selected_fee_id, id);
    };

    deleteConfirm = (selected_fee_id, id) => (
        <Popconfirm
            title="Are you sure you want to delete this discount?"
            icon={<Icon type="question-circle-o" style={{color: 'red'}} />}
            onConfirm={() => this.handleDelete(selected_fee_id, id)}
            overlayClassName="AssignRole-delete-dialog"
        >
            <div style={{cursor: 'pointer'}}><img src={bin} alt="bin" /></div>
        </Popconfirm>
    )

    render() {
        const columns = [
            {key: 'description', render: 'Description'},
            {key: 'amount', render: 'Amount'},
            {key: 'added_by', render: 'Added By'},
            {key: 'delete', render: ''}
        ];

        const {studentFee, shouldFocus, onClose, store} = this.props;
        const {view: {selectedFee: {isLoading}}} = store;
        const {id: studentFeeID, studentFeeDiscounts} = studentFee;

        if (isLoading) return <LoadingText />
        const {view: {selectedFeeId}} = store;
        const tableData = studentFeeDiscounts.map(({id, attributes: {amount, created_by_name, description}}) => ({
            description,
            amount,
            added_by: created_by_name,
            delete: this.deleteConfirm(selectedFeeId, id)
        }));

        return (
            <div className="discountForStudents">
                <Desktop>
                    <Table striped containerClass="discountForStudents-table" columns={columns} data={tableData} />
                </Desktop>
                
                <Tablet>
                    <div className="discountForStudents-items--tablet">
                        {studentFeeDiscounts.map(({id, attributes: {amount, created_by_name, description}}) => {
                            return (
                                <Card key={id}>
                                    <div className="discountForStudents-item">
                                        <div>
                                            <div>Description</div>
                                            <div>{description}</div>
                                        </div>
                                        <div>
                                            <div>Amount</div>
                                            <div>{amount}</div>
                                        </div>
                                        <div>
                                            <div>Added By</div>
                                            <div>{created_by_name}</div>
                                        </div>
                                    </div>
                                    {this.deleteConfirm(selectedFeeId, id)}
                                </Card>
                            );
                        })}
                    </div>
                </Tablet>

                <Mobile>
                    <div className="discountForStudents-items--mobile">
                        {studentFeeDiscounts.map(({id, attributes: {amount, created_by_name, description}}) => {
                            return (
                                <div key={id} className="flex-row">
                                    <Card>
                                        <div className="discountForStudents-item">
                                            <div>
                                                <div>Description</div>
                                                <div>{description}</div>
                                            </div>
                                            <div>
                                                <div>Amount</div>
                                                <div>{amount}</div>
                                            </div>
                                            <div>
                                                <div>Added By</div>
                                                <div>{created_by_name}</div>
                                            </div>
                                        </div>
                                    </Card>
                                    {this.deleteConfirm(selectedFeeId, id)}
                                </div>
                            );
                        })}
                    </div>
                </Mobile>
                
                <AddDiscountForm autoFocus={shouldFocus} studentFeeId={studentFeeID} closeModal={onClose} />
            </div>
        );
    }
}

export default DiscountsForStudent;
