import './GuardianList.css'

import React, {Component} from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'antd'
import classNames from "classnames";


import ReactHelmet from "../containers/ReactHelmet";
import Button from '../common/button'
import Avatar, { AVI_TYPES } from '../common/avatar'
import { formatDate } from '../lib/formatDate'
import {titleCase} from "../lib/titleCase";
import Collection from '../common/Collection'
import {ModalContext} from "../common/modal";
import Loading from "../common/loading/Loading";

import GuardianListSearch from './GuardianListSearch'
import GuardianListItem from './GuardianListItem'
import {ExportCollection} from "../store/actions/ExportCollection";
import {handleSendMessage} from "../messages/SendMessageForm";

import guardianIcon from './assets/parents.svg'
import arrowDown from './assets/arrow-down-white.svg'
import spreadsheetIcon from "../common/assets/spreadsheet.svg";
import chat from "../common/assets/chat.svg";

@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportGuardianListAction = ExportCollection.create({})
        this.state = { exportGuardianListAction }
    }

    initiateGuardianListExportDownload = () => {
        const { exportGuardianListAction } = this.state
        const {store} = this.props;
        const { guardianStore: {selectedGuardians, downloadGuardianListSpreadsheet}} = store

        exportGuardianListAction.setToGetGuardians(selectedGuardians.toJSON())
        downloadGuardianListSpreadsheet(exportGuardianListAction)
    }

    render() {
        const { exportGuardianListAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { guardianStore: {selectedGuardians}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedGuardians.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedGuardians.length === 0) {
                                    alert({message: {warning: "You must select at least one guardian"}})
                                    return
                                }
                                this.initiateGuardianListExportDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheetIcon} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedGuardians.length === 0) {
                                    alert({message: {warning: "You must select at least one guardian"}})
                                    return
                                }
                                handleSendMessage(context, {guardianIDs: selectedGuardians.toJSON()})
                            }}
                        >
                            <img src={chat} style={{width: 18}} className="utils-prepend" alt="" />
                            Send Message
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

const GuardianList = inject("store")(
    observer(({ store }) => {
        const { view, guardianStore: settings } = store
        const { searchResults } = settings
        const columns = [
            { key: 'photo', render: 'Photo' },
            { key: 'name', render: 'Name' },
            { key: 'wards', render: 'Wards' },
            { key: 'status', render: 'Status' },
            { key: 'mobile_phone', render: 'Mobile' },
            { key: 'phone', render: 'Phone' },
            { key: 'email', render: 'Email' },
            { key: 'activated_at', render: 'Activated' },
        ]
        const defaultColumns = ['name', 'wards', 'status', 'activated_at', 'mobile_phone']
        const tableData = searchResults
            .map(({ id, attributes, guardianName, students }) => ({
                id,
                name: (
                    <a
                        href={`/guardians/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openGuardianPageById(id)
                        }}
                    >
                        {guardianName}
                    </a>
                ),
                wards: (
                    students.map(({studentName}) => studentName).join(', ')
                ),
                activated_at: formatDate(attributes.activated_at),
                status: titleCase(attributes.status === 'active' ? 'enrolled' : attributes.status),
                mobile_phone: attributes.mobile_phone,
                phone: attributes.phone,
                email: attributes.email,
                photo: (
                    <Avatar
                        src={attributes.photo_url}
                        small
                        type={AVI_TYPES.GUARDIAN}
                        sex={attributes.gender}
                    />
                )
            }))
        const buildMobileItem = guardian => (
            <GuardianListItem id={guardian.id} key={guardian.id} guardian={guardian} />
        )
        const tableSettings = {
            tableData,
            defaultColumns,
            columns,
        }
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText, collectionSelectedText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">
                        <ActionsButton className="utils-prepend" />
                        {collectionSelectedText}
                    </div>
                    {collectionSummaryText}
                    <span style={{ width: 150 }}>&nbsp;</span>
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={24}>
                        <img src={guardianIcon} alt="" />
                        <span className="text-small utils-append text--uppercase">Guardians</span>
                    </Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12}>
                        <ActionsButton fullwidth />
                    </Col>
                    <Col span={12}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <>
                <ReactHelmet title="Guardian List" />
                <Collection
                    settings={settings}
                    tableSettings={tableSettings}
                    buildHeader={buildHeaderComponent}
                    buildMobileHeader={buildMobileHeader}
                    buildMobileItem={buildMobileItem}
                    useSearchComponent={GuardianListSearch}
                />
            </>
        )
    })
)

export default GuardianList