import { types } from "mobx-state-tree"
import { dateRangeType } from "../types/dateRangeType";
import { Search } from "./Search";

export const statusOptions = ["inactive", "active", "hold"]
export const paymentStatusOptions = ['paid', 'partly_paid', 'unpaid', 'partly_or_unpaid']

export const FeeSearch = types
    .compose(
        Search,
        types
            .model({
                query: "",
                fee_type_id: types.maybe(types.string),
                status: types.optional(
                    types.enumeration("status", [...statusOptions, ""]),
                    ""
                ),
                payment_status: types.optional(
                    types.enumeration("status", [...paymentStatusOptions, ""]),
                    ""
                ),
                amount_range: types.maybe(types.string),
                due_date_range: types.maybe(dateRangeType),
                date_created_range: types.maybe(dateRangeType),
            })
    )