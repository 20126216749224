import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from "lodash.isempty";

import Card from "../common/card";
import {AVI_TYPES} from "../common/avatar";
import Avatar from "../common/avatar/Avatar";
import Loading from "../common/loading";
import Table from "../common/table";
import {ModalContext} from "../common/modal";

import {handleEditComment} from "./StudentReportCardCommentForm";

import {ReportCardGroupData} from "../store/actions/ReportCardGroupData";

import pdf from "../common/assets/pdf.svg";
import edit_icon from "../common/assets/edit-blue.svg";

import StudentReportCardCommentPolicy from "../policies/StudentReportCardCommentPolicy";
import Mobile from "../common/display/Mobile";
import NotMobile from "../common/display/NotMobile";



@inject("store","display")
@observer
class StudentReportCard extends Component {
    constructor(props){
        super(props)

        const downloadReportAction = ReportCardGroupData.create({})
        
        this.state = { downloadReportAction }
    }

    renderCell = (label, text) => (
        <div key={label}>
            <div
                className="text--uppercase text-small text--bold text--grey"
            >
                {label}
            </div>
            <div className="text-p">{text}</div>
        </div>
    )


    initiateStudentReportDownload = () => {
        const { student, reportCardGroup, store: {reportCardGroupStore: {downloadPDFReport}}  } = this.props
        const { downloadReportAction } = this.state

        const {id: reportCardGroupID, } = reportCardGroup
        downloadReportAction.setToGetStudents([student.id])
        
        downloadPDFReport(reportCardGroupID, downloadReportAction)
    }

    prepareStudentReportData(forReportCardGroup, forStudent){
        const { store: {meta: {currentUser}}, display } = this.props;
        
        const {id: currentStudentID, attributes: {username, photo_url, gender}, studentName} = forStudent
        const {id: reportCardGroupID, template: reportCardTemplate, classSet} = forReportCardGroup
        const {psychomotorDomainGroup, affectiveDomainGroup} = classSet

        const {
            shownColumns,
            showPositionsBoolean,
            attributes: {
                final_column_id,
                include_positions,
                include_attendance,
                include_affective_domain,
                include_psychomotor_domain,
                affective_domain_header,
                psychomotor_domain_header,
                comment_role_ids
            }
        }  = reportCardTemplate

        const studentReportCardData = forReportCardGroup.studentReportCardData(currentStudentID)
        const studentSubjectBases = forReportCardGroup.studentSubjectBases(currentStudentID)

        const {
            batch_id: {id: studentBatchID, attributes: {name: batchName}},
            cognitive: studentsCognitiveCells,
            affective: affectiveScores,
            attendance: studentAttendanceData,
            psychomotor: psychomotorScores,
        } = studentReportCardData

        const {classReportCardData} = forReportCardGroup
        const batchReportCardData = forReportCardGroup.batchReportCardData(studentBatchID)

        const {student_ids: classStudentIDs} = classReportCardData
        const {student_ids: batchStudentIDs} = batchReportCardData


        const studentBioSummary = (
            <div className="flex-row flex-alignCenter mb-1">
                <Avatar
                    type={AVI_TYPES.STUDENT}
                    className="ReportCard-avatar"
                    sex={gender}
                    bordered
                    src={photo_url}
                    alt=""
                />
                <div className="ReportCard-profile">
                    <div>
                        <div className="text--uppercase text-small text--grey">Student Name</div>
                        <div className="text-h4">{studentName}</div>
                    </div>
                    {this.renderCell('Adm. No.', username)}
                    {this.renderCell('Batch', batchName)}
                </div>
            </div>
        )



        const subjectsColumns = [
            {key: 'subjectName', render: ''},
        ]
        shownColumns.forEach((column) => {
            const {id, attributes: {heading}} = column
            subjectsColumns.push({key: id, render: heading})
        })

        const subjectsData = studentSubjectBases.map(subject => {
            const {id: currentSubjectBaseId, attributes: {name: subjectName, short_name: subjectShortName}} = subject
            const result = {'subjectName': display.isMobile ? subjectShortName : subjectName}
            shownColumns.forEach(({id: columnId}) => {
                const cellInfo = studentsCognitiveCells.find(({column_id}) => parseInt(columnId, 10) === column_id)
                if (!cellInfo) return
                const { subject_cell } = cellInfo
                const node = subject_cell.find(({subject_base_id}) => subject_base_id === parseInt(currentSubjectBaseId, 10) )
                if (node) result[columnId] = node.score
            })
            return result
        })

        const averages = {'subjectName': <span>Averages</span>}
        shownColumns.forEach(({id: columnId}) => {
            const cellInfo = studentsCognitiveCells.find(({column_id}) => parseInt(columnId, 10) === column_id)
            if (!cellInfo) return
            const { summary } = cellInfo
            if (summary) averages[columnId] =  <span>{summary.average_weighted_grade}</span>
        })
        subjectsData.push(averages)




        let positionsTable
        if (/*currentUser.is_employee_or_admin || */showPositionsBoolean ){
            const summaryColumns = [
                {key: 'key', render: ''},
                {key: 'value', render: ''},
            ]

            const { summary: {position_in_batch, position_in_class} } = studentsCognitiveCells.find(({column_id}) => parseInt(final_column_id.id,10) === column_id)
            const summaryData = [
                {'key': 'Position In Arm', 'value': `${position_in_batch} of ${batchStudentIDs.length}`},
                {'key': 'Position In Class', 'value': `${position_in_class} of ${classStudentIDs.length}`},
            ]

            positionsTable = (
                <Table
                    columns={summaryColumns}
                    data={summaryData}
                    containerClass="ReportCard-summary"
                    bordered
                    striped
                    showHead={false}
                    header={<div className="text-small text--bold text--uppercase">Summary</div>}
                />
            )
        }



        let attendanceTable
        if (include_attendance && studentAttendanceData){
            const {academic_days_count, full_day_leaves, half_day_leaves} = studentAttendanceData

            const attendanceColumns = [
                {key: 'Key', render: ''},
                {key: 'value', render: ''},
            ]
            const attendanceData = [
                {'Key': 'Academic Days', 'value': academic_days_count},
                {'Key': 'Absences', 'value': full_day_leaves},
                {'Key': 'Times Late', 'value': half_day_leaves},
            ]

            attendanceTable = (
                <Table
                    columns={attendanceColumns}
                    data={attendanceData}
                    containerClass="ReportCard-attendance"
                    bordered
                    striped
                    showHead={false}
                    header={<div className="text-small text--bold text--uppercase">Attendance</div>}
                />
            )
        }



        let affectiveTable
        if (include_affective_domain && affectiveDomainGroup){
            const behaviouralTableColumns = [
                {key: 'key', render: (<div className="text-small text--bold text--uppercase">{affective_domain_header || 'Affective'}</div>)},
                {key: 'grade', render: 'Grade'},
                {key: 'remark', render: 'Remark'},
            ]
            const {attributes: {indicators}} = affectiveDomainGroup
            const affectiveData = indicators.map(({id, name, abbreviation, description}) => {
                const indicatorScore = affectiveScores.find(({indicator_id})=> indicator_id === id)
                const row = {'key': name}
                if (indicatorScore){
                    row.grade = indicatorScore.grade
                    row.remark = indicatorScore.remark
                }
                return row
            })

            affectiveTable = (
                <Table
                    columns={behaviouralTableColumns}
                    data={affectiveData}
                    containerClass="ReportCard-affective"
                    bordered
                    striped
                />
            )
        }

        let psychomotorTable
        if (include_psychomotor_domain && psychomotorDomainGroup){
            const behaviouralTableColumns = [
                {key: 'key', render: (<div className="text-small text--bold text--uppercase">{psychomotor_domain_header || 'Psychomotor'}</div>)},
                {key: 'grade', render: 'Grade'},
                {key: 'remark', render: 'Remark'},
            ]
            const {attributes: {indicators}} = psychomotorDomainGroup
            const psychomotorData = indicators.map(({id, name, abbreviation, description}) => {
                const indicatorScore = psychomotorScores.find(({indicator_id})=> indicator_id === id)
                const row = {'key': name}
                if (indicatorScore){
                    row.grade = indicatorScore.grade
                    row.remark = indicatorScore.remark
                }
                return row
            })

            psychomotorTable = (
                <Table
                    columns={behaviouralTableColumns}
                    data={psychomotorData}
                    containerClass="ReportCard-affective"
                    bordered
                    striped
                />
            )
        }

        const commentColumns = [
            {key: 'key', content: ''},
        ]

        const commentTables = isEmpty(comment_role_ids) ? '' : comment_role_ids.map((specialRole) => {

            const nComment = forReportCardGroup.commentForStudent(currentStudentID, specialRole.id)

            const commentPolicy = new StudentReportCardCommentPolicy(currentUser, nComment)

            let comment; let auto_comment; let employee_ids
            if (nComment) ( {attributes: {comment, auto_comment, employee_ids}} = nComment)


            const { attributes: {role_type, female_name, male_name, name } } = specialRole

            const content = comment || auto_comment


            const commentsData = [
                {'key': content},
            ]

            let assumedGender
            let employeeNames
            let roleName = name

            if (employee_ids){
                employeeNames = employee_ids.map((employee) => employee.titleAbbreviationsAndLastName).join(", ")

                if (employee_ids.length === 1) assumedGender = employee_ids[0].attributes.gender
                if (assumedGender){
                    if (assumedGender === 'male' && male_name){
                        roleName = male_name
                    }
                    if (assumedGender === 'female' && female_name){
                        roleName = female_name
                    }
                }
            }

            return (
                <Table
                    key={specialRole.id}
                    columns={commentColumns}
                    data={commentsData}
                    containerClass="ReportCard-comment"
                    bordered
                    striped
                    showHead={false}
                    header={(
                        <div className="flex-row flex-spaceBetween text--uppercase text--bold text-small">
                            <span>{ `${roleName}` }&apos;s Comment  {employeeNames && `— ${employeeNames}` }</span>
                            { commentPolicy.can_update && (
                                <ModalContext.Consumer>
                                    {(context) => (
                                        <div onClick={() => handleEditComment(context, currentStudentID, specialRole.id, reportCardGroupID, content )} className="touchable-opacity">
                                            <img src={edit_icon} alt="" className="utils-prepend" />
                                            <span className="text--primary">Edit</span>
                                        </div>
                                    )}
                                </ModalContext.Consumer>
                            )}
                        </div>
                    )}
                />
            )
        })


        const studentReportBody = (
            <div className="ReportCard-grid pt-1">
                <NotMobile>
                    <Table
                        columns={subjectsColumns}
                        data={subjectsData}
                        containerClass="ReportCard-subjects"
                        bordered
                        striped
                    />
                </NotMobile>
                <Mobile>
                    <Table
                        columns={subjectsColumns}
                        data={subjectsData}
                        containerClass="ReportCard-subjects"
                        bordered
                        striped
                    />
                </Mobile>
                {positionsTable}

                {affectiveTable}

                {psychomotorTable}

                {attendanceTable}

                {commentTables}
            </div>
        )

        return {studentBioSummary, studentReportBody}
    }

    render() {
        const { student, reportCardGroup } = this.props
        const { downloadReportAction } = this.state

        const { isStudentReportCardLoaded } = reportCardGroup
        if (!isStudentReportCardLoaded(student.id)) return  <Loading />;
        
        
        const {studentBioSummary, studentReportBody} = this.prepareStudentReportData(reportCardGroup, student)

        return (
            <React.Fragment>
                <Card>
                    <div className="ReportCard-head">
                        <span className="text-small text--uppercase text--spacedLetters">Student Report Card</span>
                        <div onClick={() => this.initiateStudentReportDownload()} className="touchable-opacity">
                            {downloadReportAction.isSaving || <img src={pdf} alt="" className="utils-prepend" />}
                            <span className="text-p">{downloadReportAction.isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Print'}</span>
                        </div>
                    </div>
    
                    {studentBioSummary}
                </Card>
    
                {studentReportBody}
            </React.Fragment>
        )
    }
}

export default StudentReportCard
