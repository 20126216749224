import { types, getParent } from "mobx-state-tree"
import isEmpty from "lodash.isempty"

import { Student } from "./Student";
import { FeeTransaction } from "./FeeTransaction";
import { CustomTransaction } from "./CustomTransaction";
import { WalletTransaction } from "./WalletTransaction";
import {BankTeller} from "./BankTeller";
import {WebPayStudentFeeTransaction} from "./WebPayStudentFeeTransaction";

const Union = types.union({'dispatcher': (snapshot) => {
    switch (snapshot.type) {
        case "bank_teller": return BankTeller;
        case "web_pay_student_fee_transaction": return WebPayStudentFeeTransaction;
        default: throw new Error();
    }
}}, BankTeller, WebPayStudentFeeTransaction)

export const FinanceTransaction = types
    .model("FinanceTransaction", {
        id: types.identifier,
        type: "finance_transaction",
        attributes: types.maybe(types.model({
            trans_ref: "",
            payment_method_name: "",
            payment_method: types.maybeNull(types.string),
            custom_payment_method_id: types.maybeNull(types.integer),
            total_fee_paid: types.maybeNull(types.string),
            amount_received: types.maybeNull(types.string),
            amount_payed_out: types.maybeNull(types.string),
            service_charge: types.maybeNull(types.string),
            created_by_name: types.maybeNull(types.string),
            bank_details: types.maybeNull(types.string),
            teller_number: types.maybeNull(types.string),
            created_at: "",
            transaction_date: "",
            student_id: types.maybeNull(types.late(() => types.reference(Student))),
            note: types.maybeNull(types.string)
        })),
        relationships: types.maybe(types.model({
            student_fee_transactions: types.model({
                data: types.array(
                    types.model({
                        id: types.late(() => types.reference(FeeTransaction))
                    })
                )
            }),
            expense_account_transactions: types.model({
                data: types.array(
                    types.model({
                        id: types.late(() => types.reference(WalletTransaction))
                    })
                )
            }),
            custom_finance_transactions: types.model({
                data: types.array(
                    types.model({
                        id: types.late(() => types.reference(CustomTransaction))
                    })
                )
            }),
            payment_method_additional_info: types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(Union)),
                    type: types.union(types.literal("bank_teller"), types.literal("web_pay_student_fee_transaction"))
                }))
            }),
        })),
        isLoading: false,
        isAssociationsLoaded: false
    })
    .views(self => ({
        get isInitialized() {
            return !isEmpty(self.attributes)
        },
        get isSelected() {
            return getParent(self, 2).selectedFinanceTransactions.includes(self.id)
        },
        get studentName() {
            try {
                return self.attributes.student_id.studentName
            } catch (e) {
                return ""
            }
        },
        get studentId() {
            try {
                return self.attributes.student_id.id
            } catch (e) {
                return ""
            }
        },
        get student() {
            try {
                return self.attributes.student_id
            } catch (e) {
                return null
            }
        },
        get feeTransactions() {
            try {
                return self
                    .relationships
                    .student_fee_transactions
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get derivedCategoryName() {
            if (self.student){
                if (self.feeTransactions) return 'Fee Payment'
                return 'Student Wallet'
            }
            const {attributes: {transaction_category_name}} = self.customTransactions[0]
            return transaction_category_name
        },
        get walletTransactions() {
            try {
                return self
                    .relationships
                    .expense_account_transactions
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get customTransactions() {
            try {
                return self
                    .relationships
                    .custom_finance_transactions
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
    }))