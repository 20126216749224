import "./FullscreenDialog.css"

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { PortalRoot } from "../portal_root"
import close from './assets/close-white.svg'

export default class FullscreenDialog extends React.Component {
    state = { isVisible: false }

    static propTypes = {
        renderTrigger: PropTypes.func.isRequired,
        onConfirm: PropTypes.func,
        title: PropTypes.string.isRequired,
    }

    static defaultProps = {
        onConfirm: () => { }
    }

    toggleVisible = () => {
        let { isVisible } = this.state
        isVisible = !isVisible
        this.setState({ isVisible })
    }

    handleConfirm = () => {
        const { onConfirm } = this.props
        onConfirm()
        this.toggleVisible()
    }

    render() {
        const { renderTrigger, children, title } = this.props
        const { isVisible } = this.state
        const dialogClass = classNames(
            'FullscreenDialog',
            { 'FullscreenDialog-isHidden': !isVisible }
        )

        return (
            <>
                {renderTrigger(this.toggleVisible)}
                <PortalRoot>
                    <div className={dialogClass}>
                        <div className="FullscreenDialog-title">{title}</div>
                        <button
                            type="button"
                            className="FullscreenDialog-close touchable-opacity"
                            onClick={this.toggleVisible}
                        >
                            <img src={close} alt="close dialog" />
                        </button>
                        <div className="FullscreenDialog-content">
                            {children}
                        </div>
                        <button
                            type="button"
                            onClick={this.handleConfirm}
                            className="FullscreenDialog-confirmButton touchable-highlight"
                        >Apply Filter
                        </button>
                    </div>
                </PortalRoot>
            </>
        )
    }
}