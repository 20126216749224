/* eslint-disable react/no-multi-comp */
import "./FeeSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled, } from "mstform"
import { observer, inject } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import { Row, Col, Checkbox } from 'antd';
import { FeeSearch as FeeSearchModel, statusOptions, paymentStatusOptions } from "../store/models/FeeSearch"
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'
import { titleCase } from "../lib/titleCase";

import filter from '../common/assets/filter-blue.svg'
import {dateRangeConverter} from "../lib/formatDate";


const formModel = new MstForm(FeeSearchModel, {
    query: new Field(converters.string),
    fee_type_id: new Field(converters.string, {
        controlled: controlled.object
    }),
    status: new Field(converters.string, {
        controlled: controlled.object
    }),
    payment_status: new Field(converters.string, {
        controlled: controlled.object
    }),
    amount_range: new Field(converters.string),
    due_date_range: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    date_created_range: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
})

const QueryField = observer(({ formState }) => {
    const query = formState.field("query")
    return (
        <Label text="Direct Search">
            <TextInput placeholder="Search description" {...query.inputProps} />
        </Label>
    )
})

const FeeTypeField = inject("store")(
    observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { fee_types }
            }
        } = store
        const options = []
        fee_types.forEach((fee_type) => {
            const { id, attributes: { name } } = fee_type
            options.push({ text: name, value: id })
        })
        const fee_type_id = formState.field("fee_type_id")
        return (
            <Label text="Fee type">
                <SelectInput
                    {...fee_type_id.inputProps}
                    placeholder="Select fee type"
                    options={options}
                    defaultOptionText="All fee types"
                />
            </Label>
        )
    })
)

const StatusField = observer(({ formState }) => {
    const status = formState.field("status")
    return (
        <Label text="Status">
            <SelectInput
                {...status.inputProps}
                placeholder="Select status"
                defaultOptionText="All"
                options={statusOptions.map(option => ({ text: titleCase(option), value: option }))}
            />
        </Label>
    )
})

const PaymentStatusField = observer(({ formState }) => {
    const payment_status = formState.field("payment_status")
    return (
        <Label text="Payment Status">
            <SelectInput
                {...payment_status.inputProps}
                placeholder="Select"
                defaultOptionText="All"
                options={paymentStatusOptions.map(option => ({ text: titleCase(option), value: option }))}
            />
        </Label>
    )
})

const AmountField = observer(({ formState }) => {
    const amount_range = formState.field("amount_range")
    return (
        <Label text="Amount">
            <TextInput placeholder="min amount - max amount" {...amount_range.inputProps} />
        </Label>
    )
})

const DueDateField = observer(({ formState }) => {
    const due_date_range = formState.field("due_date_range")
    return (
        <Label text="Due Date">
            <DateRangeInput {...due_date_range.inputProps} />
        </Label>
    )
})

const DateCreatedField = observer(({ formState }) => {
    const date_created_range = formState.field("date_created_range")
    return (
        <Label text="Date Created">
            <DateRangeInput {...date_created_range.inputProps} />
        </Label>
    )
})

export default function FeeSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" />
        Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { searchFormInstance, updateSearch } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <QueryField formState={this.formState} />
                    <FeeTypeField formState={this.formState} />
                    {/*<StatusField formState={this.formState} />*/}
                    <PaymentStatusField formState={this.formState} />
                    <AmountField formState={this.formState} />
                    <DueDateField formState={this.formState} />
                    <DateCreatedField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { updateSearch, search, searchFormInstance, isLoading } } = this.props
        return (
            <form className="FeeSearch-TabletForm">
                <QueryField formState={this.formState} />
                <FeeTypeField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(searchFormInstance)}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <QueryField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <FeeTypeField formState={this.formState} />
                        </Col>
                        {/*<Col span={12}>
                            <StatusField formState={this.formState} />
                        </Col>*/}
                        <Col span={12}>
                            <PaymentStatusField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <AmountField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DueDateField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DateCreatedField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

function MoreFields({ fields, formState }) {
    const moreFields = Array
        .from(fields)
        .map(([key, value]) => {
            // eslint-disable-next-line no-shadow
            const Field = key
            const { hidden, label } = value
            return hidden ? null : <Field key={label} formState={formState} />
        })
    return (
        <div className="FeeSearch-MoreFields">
            {moreFields}
        </div>
    )
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        this.state = {
            moreFields: new Map([
                [AmountField, { hidden: !searchFormInstance.amount_range, label: 'Amount' }],
                [DueDateField, { hidden: !searchFormInstance.due_date_range, label: 'Due Date' }],
                [DateCreatedField, { hidden: !searchFormInstance.date_created_range, label: 'Date Created' }],
            ])
        }
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    toggleFieldHiddenState = (fieldKey, hiddenState) => {
        const { moreFields } = this.state
        const updatedFields = Array
            .from(moreFields)
            .map(([key, value]) => {
                const { label, hidden } = value
                return [
                    key,
                    { label, hidden: (fieldKey === key ? hiddenState : hidden) }
                ]
            })
        this.setState({ moreFields: updatedFields })
    }

    render() {
        const { settings } = this.props
        const { search, isLoading } = settings
        const { moreFields } = this.state
        const popoverContent = Array
            .from(moreFields)
            .map(([key, value], idx) => {
                const { hidden, label } = value
                return (
                    <React.Fragment key={label}>
                        {idx > 0 && <div key={key} className="divider" />}
                        <div className="Button Button--clear">
                            <Checkbox
                                checked={!hidden}
                                onChange={() => this.toggleFieldHiddenState(key, !hidden)}
                            >{label}
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            })

        return (
            <form className="FeeSearch-DesktopForm">
                <QueryField formState={this.formState} />
                <FeeTypeField formState={this.formState} />
                {/*<StatusField formState={this.formState} />*/}
                <PaymentStatusField formState={this.formState} />
                <Button
                    buttonType="outline"
                    fullwidth
                    popover={{ content: popoverContent }}
                >
                    <img className="utils-prepend" src={filter} alt="" />
                    More Filters
                </Button>
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
                <MoreFields fields={moreFields} formState={this.formState} />
            </form>
        )
    }
}

FeeSearch.MobileForm = MobileForm
FeeSearch.TabletForm = TabletForm
FeeSearch.DesktopForm = DesktopForm
