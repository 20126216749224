import { types } from 'mobx-state-tree';
import {SmsTopup} from "./SmsTopup";

export const SmsMaster = types
    .model('SmsMaster', {
        id: types.identifier,
        type: 'sms_master',
        attributes: types.maybe(
            types.model({
                unit_balance: types.number,
                units_used: types.number,
                total_topup_units: types.number,
                total_topup_amount: types.maybeNull(types.string),
            }),
        ),
        relationships: types.maybe(
            types.model({
                sms_topups: types.maybe(types.model({
                    data: types.array(types.model({
                        id: types.late(() => types.reference(SmsTopup))
                    }))
                })),
            }),
        )
    }).views(self => ({
        get unitBalance() {
            return self.attributes.unit_balance
        }
    }))
