import axios from './axios'
import { TESTENV } from './constants'
import editAssessmentJSON from '../json/edit_employee_success.json'
import deleteAssessmentJSON from '../json/delete_subject_success.json'
import attendanceScoreUpdateJSON from '../json/assessment_score_update.json'
import { formDataTransform } from './formDataTransform';
import getAssessmentJSON from "../json/assessment_data.json"; // NOT Created
import getStudentAssessmentsJSON from "../json/student_assessments_data";  // NOT Created
import {stringifyQuery} from "../lib/stringifyQuery";

export function AssessmentService(env) {


    async function getAssessment(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/assessments/${id}`)
            return data
        }
        return getAssessmentJSON
    }

    async function getAssessmentsForStudent(student_id, assessment_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("assessment_search", assessment_search)
            const { data } = await axios.get(`/api/v1/students/${student_id}/assessments?${queryString}`)
            return data
        }
        return getStudentAssessmentsJSON
    }

    async function editAssessment(assessment) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/assessments/${assessment.id}`,
                { assessment },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return editAssessmentJSON
    }

    async function deleteAssessment(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/assessments/${id}`)
            return data
        }
        return deleteAssessmentJSON
    }

    async function updateAssessmentScore(assessment_score) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/assessments/${assessment_score.assessment_id}/score/${assessment_score.student_id}`,
                { assessment_score }, { transformRequest: formDataTransform })
            return data
        }
        return attendanceScoreUpdateJSON
    }

    return {
        getAssessment,
        getAssessmentsForStudent,
        editAssessment,
        deleteAssessment,
        updateAssessmentScore
    }
}

export default AssessmentService(TESTENV)