/* eslint-disable camelcase */
import { types } from "mobx-state-tree"
import { Subject } from "./Subject"
import { Employee } from "./Employee"
import {SpecialRole} from "./SpecialRole";
import {Batch} from "./Batch";
import {CourseSet} from "./CourseSet";

const SubjectReference = types.model({id: types.late(() => types.reference(Subject)), type: "subject"})
const BatchReference = types.model({id: types.late(() => types.reference(Batch)), type: "batch"})
const CourseSetReference = types.model({id: types.late(() => types.reference(CourseSet)), type: "course_set"})

const ResourceUnion = types.union({'dispatcher': (snapshot) => {
        if (snapshot === null) return  types.null
        switch (snapshot.type) {
            case "subject": return SubjectReference;
            case "batch": return BatchReference;
            case "course_set": return CourseSetReference;
            default: throw new Error();
        }
    }}, types.null, SubjectReference, BatchReference, CourseSetReference)

export const SpecialRoleAssignment = types
    .model("SpecialRoleAssignment", {
        id: types.identifier,
        type: "special_role_assignment",
        attributes: types.maybe(types.model({
            role_name: types.maybeNull(types.string),
            special_role_id: types.late(() => types.reference(SpecialRole)),
            resource_type: types.string,
            resource_id: types.number,
        })),
        relationships: types.maybe(types.model({
            employee: types.model({
                data: types.model({
                    id: types.late(() => types.reference(Employee))
                })
            }),
            resource: types.model({
                data: types.maybeNull(ResourceUnion)
            }),
        }))
    })
    .views(self => ({
        // SpecialRoleAssignments with deleted resource should ideally be considered deleted and not be returned from API
        // This is a quickfix for filtering out "deleted" SpecialRoleAssignments
        get isDeleted() {
            return self.relationships.resource.data === null
        },

    }))