import { types, getParent } from "mobx-state-tree"

export const SubjectSearch = types
    .model("SubjectSearch", {
        academic_session_id: "",
        course_id: "",
        batch_id: "",
        include_total_ids: true,
        page: 1,
        per_page: 20
    })
    .actions(self => ({
        nextPage() {
            const { meta } = getParent(self)
            const { total_pages } = meta
            if (self.page < total_pages) {
                self.page += 1
            }
        },
        prevPage() {
            if (self.page > 1) {
                self.page -= 1
            }
        },
        goToFirstPage() {
            self.page = 1
        },
    }))