import axios from './axios'
import { TESTENV } from './constants'
import deleteStudentFeeJSON from '../json/delete_student_fee.json'

export function FinanceService(env) {
    async function deleteStudentFee(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/finance/student_fees/${id}`)
            return data
        }
        return deleteStudentFeeJSON
    }

    return {
        deleteStudentFee,
    }
}

export default FinanceService(TESTENV)