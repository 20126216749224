import React from 'react'
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Collapse } from 'react-collapse'
import arrowDownWhite from './assets/arrow-down-white.svg'

function SidebarItemDisplay({ icon, activeIcon, label, isActive, children, isOpened, id, handleClick, href, sidebar, display }) {
    const sidebarItemClass = classNames(
        'touchable-highlight sidebarItem',
        { 'sidebarItem-isOpen': isOpened && !sidebar.isSettingsPaneVisible && !display.isMobile },
        { 'sidebarItem-isSettingsPaneOpen': sidebar.isSettingsPaneVisible && !display.isMobile },
    )
    return (
        <>
            <a
                href={href}
                className={sidebarItemClass}
                onClick={(e) => {
                    e.preventDefault();
                    handleClick(id);
                    sidebar.toggleSettingsPane(false);
                }}
            >
                <div>
                    {isActive && <img src={activeIcon} className="sidebarItem-icon" alt="" />}
                    {isActive || <img src={icon} className="sidebarItem-icon" alt="" />}
                    {!sidebar.isSettingsPaneVisible || display.isMobile ? label : ''}
                </div>
                {children && <img src={arrowDownWhite} className="sidebarItem-caret" alt="" />}
            </a>
            <Collapse isOpened={isOpened && !sidebar.isSettingsPaneVisible}>
                <div className="sidebarItem-collapse">
                    {children}
                </div>
            </Collapse>
        </>
    )
}
SidebarItemDisplay.propTypes = {
    icon: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isOpened: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleClick: PropTypes.func.isRequired
}
SidebarItemDisplay.defaultProps = {
    isActive: false,
    isOpened: false,
}

export const SidebarItem = inject('sidebar', 'display')(observer(SidebarItemDisplay));

export function SidebarItemChild({ label, isActive, handleClick, href }) {
    const sidebarItemChildClass = classNames(
        'sidebarItem-collapseItem touchable-highlight',
        { 'sidebarItem-collapseItem-isActive': isActive }
    )
    return (
        <a
            href={href}
            className={sidebarItemChildClass}
            onClick={(e) => {
                e.preventDefault()
                handleClick()
            }}
        >{label}
        </a>
    )
}
