import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { formatDate } from '../lib/formatDate'
import { Icon, Popconfirm } from 'antd'
import isEmpty from 'lodash.isempty'


import Card from '../common/card'
import edit from '../common/assets/edit.svg'
import trashcan from '../common/assets/trash_can.svg'
import Loading from '../common/loading/Loading'
import SubjectPolicy from "../policies/SubjectPolicy";
import AddAssessment from "./AddAssessment";
import Mobile from "../common/display/Mobile";
import MobileConfirmDialog from "../common/mobile_confirm_dialog";
import NotMobile from "../common/display/NotMobile";
import {ModalContext} from "../common/modal";


@inject("store")
@observer
class SelectedAssessment extends Component {
    constructor(props){
        super(props)
        this.state = { }
    }

    componentDidMount() {
        const { store: {view, subjectStore: {loadAssessmentById}} } = this.props
        loadAssessmentById(view.selectedAssessmentId)
    }

    render() {
        const { store } = this.props
        const { view, meta: {currentUser} } = store
        const { selectedAssessment } = view

        if (!selectedAssessment || isEmpty(selectedAssessment.attributes)) return <Loading />
        
        const {
            isLoading,
            describePoints,
            attributes:{include_in_final_grade, due_date, published, publish_scores, name, abbreviation, description, assessment_category_name}
        } = selectedAssessment
        
        if (isLoading) return <Loading />


        const sanitizeHTML = (html, options = {}) => {
            // To reduce the frontend app size, the server currently handles sanitation.
            const markUp = {__html: html};
            return <div dangerouslySetInnerHTML={markUp} />
        }

        return (
            <Card title={!isLoading && <AssessmentHeader />}>
                <div><span className='text-h1 text--primary'>{name}</span></div>
                <div><span>Points: {describePoints}</span></div>
                <div><span>Due Date: {formatDate(due_date)}</span></div>
                <div><span>Category: {assessment_category_name}</span></div>
                {(currentUser.is_employee || currentUser.is_admin) && (
                    <>
                        <div><span>Include in Report Card: {include_in_final_grade ? 'Yes' : 'No'}</span></div>
                        <div><span>Visible to Students: {published ? 'Yes' : 'No'} {published && (publish_scores ? ', scores also visible' : ', scores not visible') } </span></div>
                    </>
                )}
                
                
                {/* <div className='mt-2'><h4>{description  ? 'See description below.': 'No further description.'}</h4></div> */}
                <div className='mt-1 mb-3'>{sanitizeHTML(description)}</div>
                
                <div className="attachedDocumentsWrapper">
                    <h4>{selectedAssessment.attachedDocuments.length ? '' : 'No '}Attached Documents</h4>
                    {
                        selectedAssessment.attachedDocuments.map((attachedDocument) => {
                            const {id, attributes: {original_file_name, document_url}} = attachedDocument
                            return (
                                <div key={id}>
                                    <a
                                        href={document_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {original_file_name}
                                    </a>
                                    {/* &nbsp;<span className="text-small">(delete)</span> */}
                                </div>
                            )
                        })
                    }
                </div>
            </Card>
        )
    }
}

const AssessmentHeader = inject("store")(
    observer(({ store, children }) => {
        const { view: {
            selectedAssessment, selectedAssessmentId, openSubjectPageById
        }, subjectStore: {deleteAssessmentById}, meta: {currentUser} } = store

        const subjectPolicy = new SubjectPolicy(currentUser, selectedAssessment.subject)


        async function handleDelete() {
            await deleteAssessmentById(selectedAssessmentId)
            openSubjectPageById(selectedAssessment.subject_id)
        }
        
        return (
            <div className="TransactionReceipt-header">
                <span className="text--uppercase text-small text--spacedLetters">Assessment</span>

                <div className="flex-row">
                    {children}
                    
                    { (currentUser.is_admin || currentUser.is_employee) && (
                        <ModalContext.Consumer>
                            {(context) => (
                                <div className="TransactionReceipt-buttonGroup text-p">
                                    <div
                                        className="flex-row flex-alignCenter touchable-highlight"
                                        onClick={() => context.setContent(
                                            'Edit',
                                            <AddAssessment onCancel={context.closeModal} editMode assessmentId={selectedAssessment.id} />,
                                            true
                                        )}
                                        disabled={!subjectPolicy.can_manage_assessments}
                                        title={subjectPolicy.can_manage_assessments ? undefined : 'No permission.'}
                                    >
                                        <img src={edit} alt="" className="utils-prepend" />
                                        <span>Edit</span>
                                    </div>

                                    <Mobile>
                                        <div
                                            className="flex-row flex-alignCenter touchable-highlight"
                                            disabled={!subjectPolicy.can_manage_assessments}
                                            title={subjectPolicy.can_manage_assessments? undefined : 'No permission.'}
                                            onClick={() => context.setContent(
                                                "Deleting Assessment...",
                                                <MobileConfirmDialog
                                                    handleConfirm={() => {
                                                        handleDelete()
                                                        context.closeModal()
                                                    }}
                                                    handleCancel={context.closeModal}
                                                />,
                                                true
                                            )}
                                        >
                                            Delete
                                        </div>
                                    </Mobile>
                                    <NotMobile>
                                        <div className="flex-row flex-alignCenter touchable-highlight">
                                            <Popconfirm
                                                disabled={!subjectPolicy.can_manage_assessments}
                                                title="Are you sure you want to delete this assessment?"
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => handleDelete()}
                                            >
                                                <button
                                                    type='button'
                                                    disabled={!subjectPolicy.can_manage_assessments}
                                                    title={subjectPolicy.can_manage_assessments ? undefined : "No permission."}
                                                    className='text--primary'
                                                >
                                                    <img src={trashcan} alt="" className="utils-prepend" />
                                                    <span>Delete</span>
                                                </button>
                                            </Popconfirm>
                                        </div>
                                    </NotMobile>
                                </div>
                            )}
                        </ModalContext.Consumer>
                    )}
                </div>
            </div>
        )
    })
)

export default SelectedAssessment
