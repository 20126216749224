import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import * as PropTypes from "prop-types";
import { Collapse, Popconfirm, Icon, Checkbox } from 'antd';
import classNames from 'classnames';

import MaybeBackPage from '../containers/MaybeBackPage';
import { formatCurrency } from '../lib/formatCurrency';
import Button from '../common/button';
import Card from '../common/card';
import Table from '../common/table';
import Avatar, { AVI_TYPES } from '../common/avatar'
import MobileConfirmDialog from '../common/mobile_confirm_dialog';
import Desktop from '../common/display/Desktop';
import NotDesktop from '../common/display/NotDesktop';
import Tablet from '../common/display/Tablet';
import NotMobile from '../common/display/NotMobile';
import Mobile from '../common/display/Mobile';
import { ModalContext } from '../common/modal';
import DiscountsForStudent from './DiscountsForStudent';
import AssignSelectedStudents from './AssignSelectedStudents';
import { formatDate } from '../lib/formatDate'
import DeleteFee from './DeleteFee'

import arrowDownWhite from '../common/assets/arrow-down-white.svg';
import arrowDownGrey from '../common/assets/arrow-down-grey.svg';
import more_vert from '../common/assets/more-vert.svg';

import './SelectedFee.css';
import StudentFeeDiscountPolicy from "../policies/StudentFeeDiscountPolicy";
import FeePolicy from "../policies/FeePolicy";
import DisableStudentLogin from "../students/DisableStudentLogin";
import {NewFeeForm} from "./NewFee";

@inject('store')
@observer
class SelectedFee extends Component {
	async componentDidMount() {
		const { store } = this.props;
		const { view: { selectedFeeId }, feeStore: { loadFeeById } } = store;
		await loadFeeById(selectedFeeId);
	}

	render() {
		const { store } = this.props;
		const {
			currency,
			view: { selectedFee, openStudentPageById, getFeePageById, setBackPage },
			studentStore: { selectedStudents }
		} = store;
		const { studentFees, isLoading, attributes } = selectedFee;
		const columns = [
			{ key: 'admissionNumber', render: 'ADM NO' },
			{ key: 'name', render: 'NAME' },
			{ key: 'total_discount', render: 'DISCOUNT' },
			{ key: 'total_paid', render: 'PAID' },
			{ key: 'balance', render: 'BALANCE' },
			{ key: 'more', render: '' }
		];

		const tableData = studentFees.map((fee) => {
			const {
			    id,
				relationships: { student: { data: { id: { id: student_id, admissionNumber, studentName } } } },
				attributes: { total_discount, total_paid, balance }
			} = fee;
			return {
				id,
				name: (
					<a
						href={`/students/${student_id}`}
						onClick={(e) => {
							e.preventDefault();
							setBackPage('Fee Management', getFeePageById(id));
							openStudentPageById(student_id);
						}}
					>
						{studentName}
					</a>
				),
				admissionNumber,
				total_discount: <DiscountButton discount={total_discount} studentFee={fee} studName={studentName} />,
				total_paid: formatCurrency(total_paid, currency),
				balance: formatCurrency(balance, currency),
				more: <MoreButton studentFee={fee} studName={studentName} />
			};
		});

		return (
			<div>
				<Desktop>
					<MaybeBackPage className="selectedFee utils-padded">
						<Card>
							<Header feeAtrributes={attributes} studentFees={studentFees} selectedStudents={selectedStudents} />
							<Table
								striped
								containerClass="selectedFee-table"
								columns={columns}
								data={tableData}
								onError={(e) => console.error(e)}
								loading={isLoading}
								onColumnKeysChange={(e) => console.log(e)}
							/>
						</Card>
					</MaybeBackPage>
				</Desktop>
				<Tablet>
					<div className="selectedFee">
						<Header feeAtrributes={attributes} studentFees={studentFees} selectedStudents={selectedStudents} />
						<div className="selectedFee-item utils-padded-small text--uppercase text-small text--bold text--spacedLetters">
							<div>&nbsp;</div>
							<div>Student</div>
							<div>Discount</div>
							<div>Paid ({currency})</div>
							<div>Balance ({currency})</div>
							<div>&nbsp;</div>
						</div>
						{studentFees.map((fee) => {
							const {
								id,
								relationships: { student: { data: { id: student } } },
								attributes: { total_discount, total_paid, balance }
							} = fee;
							const { admissionNumber, studentName, attributes: {photo_url, gender } } = student
							return (
								<Card key={id}>
									<div className="selectedFee-item">
                                        <Avatar
                                            src={photo_url}
                                            small
                                            type={AVI_TYPES.STUDENT}
                                            sex={gender === 'male' ? 'm' : 'f'}
                                        />
										<div>
											<div>{studentName}</div>
											<div>{admissionNumber}</div>
										</div>
										<DiscountButton discount={total_discount} studentFee={fee} studName={studentName} />
										<div>{total_paid}</div>
										<div>{balance}</div>
										<MoreButton studentFee={fee} studName={studentName} />
									</div>
								</Card>
							);
						})}
					</div>
				</Tablet>

				<Mobile>
					<div className="selectedFee">
						<Header feeAtrributes={attributes} studentFees={studentFees} selectedStudents={selectedStudents} />
						{studentFees.map((fee) => {
                            const {
                                id,
                                relationships: { student: { data: { id: student } } },
                                attributes: { total_discount, total_paid, balance }
                            } = fee;
                            const { admissionNumber, studentName, attributes: {photo_url, gender } } = student
                            
							return (
								<Card key={id}>
									<div className="selectedFee-item">
										<div>
                                            <Avatar
                                                src={photo_url}
                                                small
                                                type={AVI_TYPES.STUDENT}
                                                sex={gender === 'male' ? 'm' : 'f'}
                                            />
											<div>
												<div>{studentName}</div>
												<div>{admissionNumber}</div>
											</div>
										</div>
										<MoreButton studentFee={fee} studName={studentName} />
										<div>
											<div>Discount</div>
											<DiscountButton
												discount={total_discount}
												studentFee={fee}
												studName={studentName}
											/>
										</div>
										<div>
											<div>Paid ({currency})</div>
											<div>{total_paid}</div>
										</div>
										<div>
											<div>Balance ({currency})</div>
											<div>{balance}</div>
										</div>
									</div>
								</Card>
							);
						})}
					</div>
				</Mobile>
			</div>
		);
	}
}

@inject('store')
class Header extends Component {
    static propTypes = {
        feeAtrributes: PropTypes.any,
        studentFees: PropTypes.any,
        selectedStudents: PropTypes.any
    }
    
    render() {
         const {feeAtrributes, studentFees, selectedStudents, store: {currency}} = this.props;

        const feeData = {
            'Type': feeAtrributes.fee_type_id.attributes.name,
            'Start Date': formatDate(feeAtrributes.start_date),
            'Due Date': formatDate(feeAtrributes.due_date),
            // 'Status': feeAtrributes.status,
            'Description': feeAtrributes.description,
            'Created By': feeAtrributes.created_by_name,
            'Student Count': studentFees.length,
            'Amount': formatCurrency(feeAtrributes.amount, currency),
            'Total Discount': formatCurrency(feeAtrributes.total_discount, currency),
            'Expected Income': formatCurrency(feeAtrributes.expected_income, currency),
            'Total Paid': formatCurrency(feeAtrributes.total_paid, currency),
            'Total Balance': formatCurrency(feeAtrributes.total_balance, currency)
        };

        const keys = Object.keys(feeData);
        return (
            <div className="selectedFee-head">
                <div className="selectedFee-optionGroup">
                    <NotDesktop>
                        <div className="text-small text--uppercase text--spacedLetters">Fee Management</div>
                    </NotDesktop>
                </div>
                <div className="selectedFee-dataGroup">
                    {keys.map((key) => (
                        <div key={key}>
                            <div className="text-small text--uppercase text--grey text--bold">{key}</div>
                            <div className="text-p">{feeData[key]}</div>
                        </div>
                    ))}
                    <OptionsButton selectedStudents={selectedStudents} feeInfo={feeAtrributes}/>
                </div>
            </div>
        );
    }
}

@inject('store')
class OptionsButton extends Component {
    render() {
        const { feeInfo } = this.props;
        const {selectedStudents, store} = this.props;
        const { meta: {currentUser},  view : { selectedFeeId} } = store

        const policy = new FeePolicy(currentUser, 'Fee')

        const selectedStudentsButtonClass = classNames(
            'Button Button--clear touchable-opacity text-p selectedFee-popover--selected-students',
            {'Button--disabled': selectedStudents.length === 0}
        );
        const {Panel} = Collapse;
        const genExtra = <img className="utils-append" src={arrowDownGrey} alt="" />;
        const customPanelStyle = {background: 'white', borderRadius: 0, border: 0};

        const ChangeStatusCollapse = () => (
            <div className="selectedFee-popover--change-status">
                <Collapse bordered={false}>
                    <Panel showArrow={false} extra={genExtra} header="Change Status" style={customPanelStyle}>
                        <div><Checkbox /> Active</div>
                        <div><Checkbox /> Hold</div>
                    </Panel>
                </Collapse>
            </div>
        );

        const Options = () => (
            <ModalContext.Consumer>
                {(context) => (
                    <div className="selectedFee-popover selectedFee-popover--options">
                        <button
                            type="button"
                            disabled={!policy.can_update}
                            title={policy.can_update ? undefined : "No permission."}
                            onClick={() =>
                                context.setContent(
                                    'Edit Fee',
                                    <NewFeeForm closeModal={context.closeModal} feeInfo={feeInfo} feeId={selectedFeeId} editMode/>,
                                    true
                                )
                            }
                        >
                            Edit
                        </button>
                        {/* TODO: Add status features */}
                        {/* <ChangeStatusCollapse /> */}
                        <button
                            type="button"
                            onClick={() =>
                                context.setContent(
                                    'Assign fees to students',
                                    <AssignSelectedStudents
                                        onClose={context.closeModal}
                                        selectedStudents={selectedStudents}
                                    />,
                                    true
                                )}
                            className={selectedStudentsButtonClass}
                        >
                            Assign to ({selectedStudents.length}) selected students
                        </button>
                        <button
                            disabled={!policy.can_destroy}
                            title={policy.can_destroy ? undefined : "No permission."}
                            type="button"
                            onClick={() => context.setContent(
                                'Delete Fee',
                                <DeleteFee  feeId={selectedFeeId} onClose={context.closeModal} />,
                                true
                            )}
                        >
                            Delete
                        </button>
                    </div>
                )}
            </ModalContext.Consumer>
        );
        return (
            <Button
                {...this.props}
                popover={{content: <Options />, placement: 'bottomRight'}}
                className="option--button"
            >
                Options
                <img className="utils-append" src={arrowDownWhite} alt="" />
            </Button>
        );
    }
}

const DiscountButton = inject('store')(
	observer(({ discount, studName, studentFee, store }) => {
		const { currency } = store;
		return (
			<ModalContext.Consumer>
				{(context) => (
					<div
						style={{cursor: 'pointer'}}
						onClick={() =>
							context.setContent(
								`Fee Discounts for ${studName}`,
								<DiscountsForStudent onClose={context.closeModal} studentFee={studentFee} />,
								true
							)}
						className="text--blue "
					>
						{formatCurrency(discount, currency)}
					</div>
				)}
			</ModalContext.Consumer>
		);
	})
);

const MoreButton = inject('store')(observer(({ studentFee, studName, store }) => {
    const { meta: {currentUser} } = store

    const policy = new StudentFeeDiscountPolicy(currentUser, 'StudentFeeDiscount')
    const feePolicy = new FeePolicy(currentUser, 'Fee')
	
	const handleDelete = () => {
		const { attributes :{ student_id : id}} = studentFee
		const { feeStore: { UnassignStudentFee }, view : { selectedFeeId}}= store
		UnassignStudentFee(selectedFeeId, id)
	}

	const More = (
        <ModalContext.Consumer>
            {(context) => (
                <div>
                    <button
                        type="button"
                        className="Button Button--clear touchable-opacity text-p"
                        disabled={!policy.can_create}
                        title={policy.can_create ? undefined : 'No permission.'}
                        onClick={() => {
                            context.setContent(
                                `Fee Discounts for ${studName}`,
                                <DiscountsForStudent onClose={context.closeModal} studentFee={studentFee} shouldFocus />,
                                true
                            )
                        }}
                    >
                        Add Discount
                    </button>
                    <div className="divider" />
                    <Mobile>
                        <button
                            type="button"
                            className="Button Button--clear touchable-opacity text-p"
                            disabled={!feePolicy.can_unassign_from_student}
                            title={feePolicy.can_unassign_from_student ? undefined : 'No permission.'}
                            onClick={() =>
                                context.setContent(
                                    'Unassign Student Fee...',
                                    <MobileConfirmDialog
                                        handleConfirm={() => {
                                            handleDelete();
                                            context.closeModal();
                                        }}
                                        handleCancel={context.closeModal}
                                    />,
                                    true
                                )
                            }
                        >
                            Unassign
                        </button>
                    </Mobile>
                    <NotMobile>
                        <Popconfirm
                            title="Are you sure you want to Unassign the fee from this Student?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={handleDelete}
                            disabled={!policy.can_destroy}
                        >
                            <button
                                type="button"
                                className="Button Button--clear touchable-opacity text-p"
                                disabled={!policy.can_destroy}
                                title={policy.can_destroy ? undefined : 'No permission.'}
                            >
                                Unassign
                            </button>
                        </Popconfirm>
                    </NotMobile>
                </div>
            )}
        </ModalContext.Consumer>
	);
	return (
		<Button
            buttonType="flat"
			size="small"
			className="selectedFee-more  text--right"
			popover={{
				content: More,
				placement: 'bottomRight'
			}}
		>
			<div className="touchable-opacity">
				<img src={more_vert} alt="more_vert" />
			</div>
		</Button>
	);
}));


export default SelectedFee