import React from 'react'
import Mobile from '../common/display/Mobile';
import NotMobile from '../common/display/NotMobile';
import Button from '../common/button';
import { ModalContext } from '../common/modal'
import ReportCardGroup from './ReportCardGroupForm'

import plus from '../common/assets/plus.svg'
import plusBlue from '../common/assets/plus-blue.svg'

function handleNewReportCardGroup({ setContent, closeModal }) {
        return setContent(
            "New Report Card Group",
            <ReportCardGroup closeModal={closeModal} />,
            true
        )
}

function NewReportCardGroup() {
    return (
        <ModalContext.Consumer>
            {(context) => (
                <NewReportCardGroupButton onClick={() => handleNewReportCardGroup(context)} />
            )}
        </ModalContext.Consumer>
    )
}

export default NewReportCardGroup

function NewReportCardGroupButton({ onClick }) {
    return (
        <>
            <Mobile>
                <div 
                    className="
                    Button touchable-highlight flex-row
                    flex-alignCenter Button--flat
                    Button--large text-p utils-fullwidth"
                    onClick={onClick}
                >
                    <img src={plusBlue} alt="" className="utils-prepend" />
                    Create New
                </div>
            </Mobile>
            <NotMobile>
                <Button onClick={onClick}>
                    <img src={plus} alt="" className="utils-prepend" />
                    Create New
                </Button>
            </NotMobile>
        </>
    )
}

