import "./Segment.css"

import React from 'react'

export default function Segment({ children }) {
    return (
        <section className="Segment">
            {children}
        </section>
    )
}