import ApplicationPolicy from "./ApplicationPolicy";

class AttendancePolicy extends ApplicationPolicy{
    
    // FIXME employee be allowed to update student old batch attendance
    get can_index(){
        return this.user.has_any_role(['student_attendance_register', 'student_attendance_view'])
                || this.user.has_any_special_role(['class_teacher', 'principal'])
    }
    
    get can_show(){
        return false
    }
    
    get can_create(){
        return this.user.has_role('student_attendance_register')
            || this.user.has_special_role('class_teacher', this.record.student.batch)
    }
    
    get can_destroy(){
        return this.can_create
    }
}

export default AttendancePolicy
