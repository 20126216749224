import React from 'react'
import { inject, observer } from 'mobx-react'
import Card from '../common/card';
import more_vert from '../common/assets/more-vert.svg'
import { Popover } from 'antd'
import MoreButtonPopover from './MoreButtonPopover'

const BatchCollectionItem = inject("store")(
    observer(({ store, batch }) => {
        const { view } = store
        
        const {
            id,
            attributes: { name, student_count }
        } = batch
        const { batchStore:{deleteBatch}} = store;
        return (
            <Card key={id} className="mb-1">
                <a
                    href={`/batches/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                view.openBatchPageById(id)
                    }}
                >
                    {name}
                </a>
                <div className="flex-row  flex-spaceBetween">
                <div className="mt-1">No. of students: {student_count}</div>
                     <Popover
                    content={<MoreButtonPopover batchInfo={batch} deleteBatch={deleteBatch} />}
                    placement='bottomRight'
                    overlayClassName="override-popover"
                  >
                    <div className="touchable-opacity text--right ">
                      <img src={more_vert} alt="..." />
                    </div>
                  </Popover>
                </div>      
            </Card>
        )
    })
)

export default BatchCollectionItem 