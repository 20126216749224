import React from 'react';
import { inject, observer } from 'mobx-react';
import { converters, Field, Form} from 'mstform';
import * as PropTypes from "prop-types";

import ModalForm from '../containers/ModalForm';
import Label from '../common/form/Label';
import InlineError from '../common/form/InlineError';
import {StudentReportCardComment} from "../store/actions/StudentReportCardComment";
import MultilineInput from "../common/form/MultilineInput";


export function handleEditComment(context, currentStudentID, specialRoleID, reportCardGroupID, content ) {
    const { setContent, closeModal } = context

    return setContent(
        "Edit Comment",
        <StudentReportCardCommentForm
            closeModal={closeModal}
            currentStudentID={currentStudentID}
            specialRoleID={specialRoleID}
            reportCardGroupID={reportCardGroupID}
            content={content}
        />,
        true
    )
}


const form = new Form(StudentReportCardComment, {
    content: new Field(converters.maybe(converters.string), { })
})


@inject('store', 'display')
@observer
class StudentReportCardCommentForm extends React.Component {
    static propTypes = {
        content: PropTypes.string,
        closeModal: PropTypes.any.isRequired,
        currentStudentID: PropTypes.string.isRequired,
        specialRoleID: PropTypes.string.isRequired,
        reportCardGroupID: PropTypes.string.isRequired,
    }

    static defaultProps = {
        content: ""
    }

    constructor(props) {
        super(props)

        const { currentStudentID, specialRoleID, reportCardGroupID, content, store } = props;
        const { reportCardGroupStore: {addComment} } = store
        
        const formInstance = StudentReportCardComment.create({
            student_id: currentStudentID,
            special_role_id: specialRoleID,
            content
        })

        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors } = await addComment(reportCardGroupID, node)
                return errors
            }
        })

        this.state = {formModel: formInstance}
    }


    
    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const { formModel: { isSaving } } = this.state
        
        const { closeModal } = this.props
        
        
        const content = this.formState.field("content")
        

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={isSaving ? "Please wait..." : "Save"}
                isOkButtonDisabled={!this.formState.isValid || isSaving}
            >
                <Label text="Comment">
                    <InlineError field={content}>
                        <MultilineInput
                            {...content.inputProps}
                            placeholder="Enter comment"
                        />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}

export default StudentReportCardCommentForm