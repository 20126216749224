import './CreateNote.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../button'
import trash from './assets/trash.svg'
import { formatDate } from '../../lib/formatDate';

export default class CreateNote extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    editNote: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  state = {
    title: '',
    body: ''
  }

  componentWillMount() {
    const { editNote } = this.props
    if (editNote) {
      const { title, body } = editNote
      this.setState({ title, body })
    }
  }

  get canSubmit() {
    const { title, body } = this.state
    return title.length > 0 && body.length > 0
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleSubmit = () => {
    const { onClose, onCreate, onEdit, editNote } = this.props
    if (editNote) {
      onEdit(this.state)
    } else {
      onCreate(this.state)
    }
    onClose()
  }

  handleDelete = () => {
    const { onClose, onDelete } = this.props
    onDelete()
    onClose()
  }

  render() {
    const { onClose, editNote } = this.props
    const { title, body } = this.state
    return (
      <div className="CreateNote">
        <input
          type="text"
          className="text-h1 CreateNote-title"
          placeholder="Enter Title"
          onChange={evt => this.handleChange('title', evt.target.value)}
          autoFocus
          value={title}
        />
        {editNote && (
          <div className="CreateNote-toolbar">
            <div className="text-p text--grey">Created: {formatDate(editNote.created_at)}</div>
            <div className="text-p text--grey">Updated: {formatDate(editNote.updated_at)}</div>
            <button type="button" onClick={this.handleDelete}>
              <img
                src={trash}
                alt="trash"
                className="touchable-opacity"
              />
            </button>
          </div>
        )}
        <div className="CreateNote-textarea">
          <textarea
            placeholder="Enter note"
            onChange={evt => this.handleChange('body', evt.target.value)}
            value={body}
          />
        </div>
        <div className="CreateNote-buttonGroup">
          <button type="button" onClick={onClose}>
            <div className="text--grey touchable-opacity">Cancel</div>
          </button>
          <Button
            onClick={this.handleSubmit}
            disabled={!this.canSubmit}
          >
            Save Note
          </Button>
        </div>
      </div>
    )
  }
}
