import ApplicationPolicy from "./ApplicationPolicy";

class StudentFeeDiscountPolicy extends ApplicationPolicy{

    get can_create(){
        return this.user.has_role('finance_control')
    }

    get can_destroy(){
        return this.can_create
    }
}

export default StudentFeeDiscountPolicy