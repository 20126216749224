import './Label.css'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function Label({ required, inline, inlineWidth, children, text, className, ...rest }) {
    const labelClass = classNames(
        'Label',
        {
            'Label--required': required,
            'Label--inline': inline,
            [className]: className 
        }
    )
    const labelStyle = (inline && inlineWidth) ?
        { gridTemplateColumns: `${inlineWidth} auto` } : null

    return (
        <div className={labelClass} style={labelStyle} {...rest}>
            <div className="Label-text">{text}</div>
            {children}
        </div>
    )
}

Label.propTypes = {
    required: PropTypes.bool,
    inline: PropTypes.bool,
    inlineWidth: PropTypes.string,
    text: PropTypes.string.isRequired,
}

Label.defaultProps = {
    required: false,
    inline: false,
    inlineWidth: null,
}

export default Label
