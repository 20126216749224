import React from 'react';
import * as ReactGA from "react-ga";
import { inject, observer } from 'mobx-react';
import {converters, Field, Form} from 'mstform';
import * as PropTypes from "prop-types";
import {Col, Row} from "antd";
import isEmpty from 'lodash.isempty'

import ModalForm from '../containers/ModalForm';
import Label from '../common/form/Label';
import InlineError from '../common/form/InlineError';
import { SendMessage } from "../store/actions/Message";
import MultilineInput from "../common/form/MultilineInput";
import TextInput from "../common/form/TextInput";
import {SMSTools} from "../lib/SMSTools";
import CheckBoxInput from "../common/form/CheckBoxInput";


export function handleSendMessage(context, {userIDs, studentIDs, employeeIDs, guardianIDs}) {
    const { setContent, closeModal } = context

    return setContent(
        "Compose New Message",
        <SendMessageForm
            closeModal={closeModal}
            userIDs={userIDs}
            studentIDs={studentIDs}
            guardianIDs={guardianIDs}
            employeeIDs={employeeIDs}
        />,
        true
    )
}


const form = new Form(SendMessage, {
    subject: new Field(converters.maybe(converters.string) ),
    body: new Field(converters.maybe(converters.string), { required: true }),
    
    send_as_email: new Field(converters.boolean, { required: true}),
    send_as_sms: new Field(converters.boolean, { required: true }),
    send_to_students: new Field(converters.boolean, { required: true}),
    send_to_student_guardians: new Field(converters.boolean, { required: true}),
    
    
    mobile_numbers: new Field(converters.maybe(converters.string), { }),
    emails: new Field(converters.maybe(converters.string), { })
})


@inject('store', 'display')
@observer
class SendMessageForm extends React.Component {
    static propTypes = {
        userIDs: PropTypes.arrayOf(PropTypes.string),
        studentIDs: PropTypes.arrayOf(PropTypes.string),
        employeeIDs: PropTypes.arrayOf(PropTypes.string),
        guardianIDs: PropTypes.arrayOf(PropTypes.string),
        closeModal: PropTypes.func.isRequired
    }

    static defaultProps = {
        userIDs: [],
        studentIDs: [],
        employeeIDs: [],
        guardianIDs: []
    }

    constructor(props) {
        super(props)

        const { userIDs, studentIDs, employeeIDs, guardianIDs, store } = props;
        const { messageStore: {sendMessage} } = store
        
        const formInstance = SendMessage.create({
            student_ids: studentIDs,
            employee_ids: employeeIDs,
            guardian_ids: guardianIDs,
            user_ids: userIDs
        })

        
        this.formState = form.state(formInstance, {
            addMode: false,
            save: async (node) => {
                const { errors } = await sendMessage(node)
                if (errors) this.setState({baseFormErrors: errors.base})
                return errors
            }
        })

        this.state = {formModel: formInstance, baseFormErrors: undefined}
    }
    
    componentDidMount() {
        ReactGA.modalview('SendMessageModal');
        // ReactGA.event({
        //     category: 'Communication',
        //     action: 'Open Send Message Modal',
        //     nonInteraction: false,
        //     // 'label: 'Game Widget'',
        //     // value: undefined,
        // });
    }


    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { closeModal } = this.props
            closeModal()
        }
    }
    
    render() {
        const {baseFormErrors} = this.state
        const { formModel: {
            isSaving,
            student_ids,
            guardian_ids,
            employee_ids,
            user_ids,
            allIDsAreEmpty,
            send_to_students: sendToStudents,
            send_to_student_guardians: sendToStudentGuardians,
            send_as_sms: sendAsSMS,
            send_as_email: sendAsEmail,
        } } = this.state
        const { closeModal } = this.props
        
        
        const subject = this.formState.field("subject")
        const body = this.formState.field("body")
        const send_as_email = this.formState.field("send_as_email")
        const send_as_sms = this.formState.field("send_as_sms")
        const send_to_students = this.formState.field("send_to_students")
        const send_to_student_guardians = this.formState.field("send_to_student_guardians")
        const mobile_numbers = this.formState.field("mobile_numbers")
        const emails = this.formState.field("emails")
        
        
        const encodingDetection  = new SMSTools(body.value || '')
        const {concatenatedPartsCount: SMSPages, length: SMSLength} = encodingDetection
        const SMSXtersLeft = encodingDetection.maxLengthFor(SMSPages) - SMSLength
        
        const sendingToStudentAndGuardians = !isEmpty(student_ids) && send_to_students.value && send_to_student_guardians.value
        const sendingToGuardiansOfStudents = !isEmpty(student_ids) && !send_to_students.value && send_to_student_guardians.value
        const sendingToOnlyStudents = !isEmpty(student_ids) && send_to_students.value && !send_to_student_guardians.value
        
        
        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={closeModal}
                okButtonText={isSaving ? "Sending..." : "Send"}
                isOkButtonDisabled={!this.formState.isValid || isSaving}
            >
                {baseFormErrors && (
                    <div className="mb-1">
                        <span className="text--danger">{baseFormErrors}</span>
                    </div>
                )}
                
                <ul className="mb-1 text--italic utils-noPadLeft listStyle--none">
                    { sendingToStudentAndGuardians && (
                        <li>Send message to <b>{student_ids.length} student(s) and their assigned guardians</b></li>
                    )}
                    { sendingToGuardiansOfStudents && (
                        <li>Send message to <b>guardians of {student_ids.length} student(s)</b></li>
                    )}
                    { sendingToOnlyStudents && (
                        <li>Send message to <b>{student_ids.length} student(s)</b></li>
                    )}
                    { !isEmpty(guardian_ids) && (
                        <li>Send message to <b>{guardian_ids.length} guardian(s)</b></li>
                    )}
                    { !isEmpty(employee_ids) && (
                        <li>Send message to <b>{employee_ids.length} employee(s)</b></li>
                    )}
                    { !isEmpty(user_ids) && (
                        <li>Send message to <b>{user_ids.length} user(s)</b></li>
                    )}
                </ul>

                <div className="pb-1">
                    <Row gutter={15}>
                        <Col xs={24} sm={8}>
                            <CheckBoxInput
                                className="text-p text--grey utils-padBottom"
                                {...send_as_email.inputProps}
                            >
                                Send as Email
                            </CheckBoxInput>
                        </Col>
                        <Col xs={24} sm={12}>
                            <CheckBoxInput
                                className="text-p text--grey utils-padBottom"
                                {...send_as_sms.inputProps}
                            >
                                Send as SMS
                            </CheckBoxInput>
                        </Col>
                    </Row>
    
    
                    { !isEmpty(student_ids) && (
                        <Row gutter={15}>
                            <Col xs={24} sm={8}>
                                <CheckBoxInput
                                    className="text-p text--grey utils-padBottom"
                                    {...send_to_students.inputProps}
                                    disabled={!sendToStudentGuardians}
                                    checked={sendToStudents}
                                >
                                    Send to students
                                </CheckBoxInput>
                            </Col>
                            <Col xs={24} sm={12}>
                                <CheckBoxInput
                                    className="text-p text--grey utils-padBottom"
                                    {...send_to_student_guardians.inputProps}
                                >
                                    Send to students&apos; guardians
                                </CheckBoxInput>
                            </Col>
                        </Row>
                    ) }

                </div>
                
                
                { allIDsAreEmpty && (
                    <>
                        {send_as_sms.value && (
                            <Label text="Mobile Numbers">
                                <InlineError field={mobile_numbers}>
                                    <MultilineInput
                                        {...mobile_numbers.inputProps}
                                        placeholder="Separate numbers with comma or new line"
                                        rows="2"
                                    />
                                </InlineError>
                            </Label>
                        )}
                        
                        {send_as_email.value && (
                            <Label text="Email Addresses">
                                <InlineError field={emails}>
                                    <MultilineInput
                                        {...emails.inputProps}
                                        placeholder="Separate emails with comma or new line"
                                        rows="2"
                                    />
                                </InlineError>
                            </Label>
                        )}
                    </>
                )}
                
                { sendAsEmail && (
                    <Label text="Subject" required>
                        <InlineError field={subject}>
                            <TextInput {...subject.inputProps} />
                        </InlineError>
                    </Label>
                )}
                
                <Label text="Body" required>
                    <InlineError field={body}>
                        <MultilineInput
                            {...body.inputProps}
                            placeholder="Enter Message"
                        />
                    </InlineError>
                    
                    { sendAsSMS && SMSLength > 0 && true && (
                        <p className="text-small utils-padTop-small">{SMSPages} SMS page(s), {SMSXtersLeft} character(s) left</p>
                    )}
                </Label>
            </ModalForm>
        )
    }
}

export default SendMessageForm