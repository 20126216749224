/**
 * Credits to this article https://blog.flowandform.agency/create-a-custom-calendar-in-react-3df1bfd0b728
 */
import './Calendar.css'

import React from 'react'
import * as PropTypes from 'prop-types'
import { addMonths, isSameMonth, subMonths } from 'date-fns'
import { observer } from 'mobx-react'
import Hashmap from 'hashmap'
import CalendarHeader from './CalendarHeader'
import CalendarDays from './CalendarDays'
import CalendarCells from './CalendarCells'

@observer
class Calendar extends React.Component {

  static propTypes = {
    calendarData: PropTypes.instanceOf(Hashmap).isRequired,
    currentMonth: PropTypes.instanceOf(Date),
    firstMonth: PropTypes.instanceOf(Date),
    lastMonth: PropTypes.instanceOf(Date),
    navigationDisabled: PropTypes.bool,
  }

  static defaultProps = {
    currentMonth: new Date(),
    navigationDisabled: false,
    firstMonth: undefined,
    lastMonth: undefined
  }

    constructor(props) {
        super(props)
        const { currentMonth } = this.props
        this.state = { currentMonth }
    }

  // componentDidUpdate(prevProps) {
  //   const {currentMonth} = this.props
  //   if (currentMonth !== prevProps.currentMonth) {
  //     this.setState({ currentMonth })
  //   }
  // }

  nextMonth = () => {
    const { lastMonth } = this.props
    const { currentMonth } = this.state
    if (lastMonth && isSameMonth(currentMonth, lastMonth)) return

    const newMonth = addMonths(currentMonth, 1)
    this.setState({ currentMonth: newMonth })
  }

  prevMonth = () => {
    const { firstMonth } = this.props
    const { currentMonth } = this.state
    if (firstMonth &&  isSameMonth(currentMonth, firstMonth)) return

    const newMonth = subMonths(currentMonth, 1)
    this.setState({ currentMonth: newMonth })
  }

  render() {
    const { currentMonth } = this.state
    const { navigationDisabled, calendarData } = this.props
    return (
      <div className="Calendar">
        <CalendarHeader
          currentMonth={currentMonth}
          navigationDisabled={navigationDisabled}
          prevMonth={this.prevMonth}
          nextMonth={this.nextMonth}
        />
        <CalendarDays currentMonth={currentMonth} />
        <CalendarCells
          currentMonth={currentMonth}
          calendarData={calendarData}
        />
      </div>
    )
  }
}

export default Calendar