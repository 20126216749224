import {getParent, types} from "mobx-state-tree"
import { Student } from "./Student";


const BatchStudentAttendanceReport = types
    .model("BatchStudentAttendanceReport", {
        full_day_leaves: types.number,
        half_day_leaves: types.number,
        leaves_count: types.number,
        score: types.number,
        percentage: types.number,
        student_id: types.number,
    }) 

export const BatchAttendanceReport = types
    .model("BatchAttendanceReport", {
        id: types.identifier,
        type: "batch_attendance_report",
        attributes: types.maybe(types.model({
            students_report: types.array(BatchStudentAttendanceReport),
            days_opened_count: types.number,
            holidays_count: types.number,
        })),
        relationships: types.maybeNull(types.model({
            students: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            })),
        })),
        filter_term: types.maybe(types.string),
        isLoading: true
    })
    .views(self => ({
        get reportStudents() {
            try {
                return self.relationships.students.data.map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get daysOpenedCount() {
            try {
                return self.attributes.days_opened_count
            } catch (e) {
                console.error(e)
                return undefined
            }
        }
    }))
    .actions(self => ({
        markLoading: (loading) => {
            self.isLoading = loading
        },
        setAcademicTermFilter(term) {
            self.filter_term = term
        },
        update({ id, attributes, relationships }) {
            self.id = id
            self.attributes = attributes
            self.relationships = relationships
        }
    }))
