import './Dashboard.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';

import ReactHelmet from "../containers/ReactHelmet";
import StudentFees from './StudentFees'
import StudentPerformance from './StudentPerformance'
import GeneralStatistics from './GeneralStatistics'
import AbsentStudents from './AbsentStudents'
import RecentTransactions from './RecentTransactions'
import Debtors from './Debtors'
import Announcements from './Announcements'
import Assessments from "./Assessments";

@inject("store")
@observer
class Dashboard extends Component {
    constructor(props) {
        super(props)
        const { store: { dashboardReport } } = this.props
        this.state = { dashboardReport}
    }
    
    async componentDidMount(){
        const { store: { dashboardReport } } = this.props
        await dashboardReport.fetchDashboardReport()

        this.setState({ dashboardReport})
    }
    
    render() {
        const { dashboardReport } = this.state
  
        return (
            <div className='dashboard'>
                <ReactHelmet title="Dashboard" />
                <StudentFees financeStat={dashboardReport.financeStat} />
                <StudentPerformance chartData={dashboardReport.incomeTrendChart} />
                <GeneralStatistics peopleStat={dashboardReport.peopleStat} />
                <AbsentStudents topAbsentStudents={dashboardReport.topAbsentStudents} attendanceStats={dashboardReport.attendanceStats} />
                <RecentTransactions recentTransactions={dashboardReport.recentTransactions} />
                <Debtors debtorsByClass={dashboardReport.debtorsByClass}  />
                <Announcements recentAnnouncements={dashboardReport.recentAnnouncements}  />
                <Assessments recentAssessmentScores={dashboardReport.recentAssessmentScores}  />
            </div>
        )
    }
}
export default Dashboard

