import { types, getParent } from "mobx-state-tree"
import { formatDate } from "../../lib/formatDate"
import { titleCase } from "../../lib/titleCase"
import { Note } from "./Note"
import { Gender } from "./Gender"
import {User} from "./User"
import { StudentGuardianRelation } from "./StudentGuardianRelation"

export const Guardian = types
    .model("Guardian", {
        id: types.identifier,
        type: "guardian",
        attributes: types.maybe(types.model({
            username: types.maybeNull(types.string),
            first_name: "",
            middle_name: types.maybeNull(types.string),
            last_name: "",
            gender: types.maybeNull(Gender),
            email: types.maybeNull(types.string),
            mobile_phone: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            photo_url: types.maybeNull(types.string),
            wards_wallet_balance: "",
            wards_due_fees_balance: "",
            status: "",
            activated_at: types.maybeNull(types.string),
            left_institution_at: types.maybeNull(types.string),
            wards_count: types.maybeNull(types.number),
            notes: types.array(Note)
        })),
        isLoading: false,
        relationships: types.optional(types.model({
            user: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(User))
                })
            })),
            student_guardian_relations: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(StudentGuardianRelation))
                }))
            })),
        }), {}),
    })
    .views(self => ({
        get isInitialized(){
            return self.attributes
        },
        get guardianName() {
            const { last_name, first_name, middle_name } = self.attributes
            return titleCase(
                `${last_name}, ${first_name} ${middle_name || ""}`
            )
        },
        get user() {
            try {
                return self
                    .relationships
                    .user
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get guardianId() {
            return self.attributes.username
        },
        get studentGuardianRelations() {
            try {
                return self
                    .relationships
                    .student_guardian_relations
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get students() {
            try {
                return self.studentGuardianRelations.map(({student}) => student).filter((s) => !!s)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get notes() {
            try {
                return self.attributes.notes
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get bioData() {
            const { attributes } = self
            return {
                guardianProfile: {
                    "title": attributes.title,
                    "last name": attributes.last_name,
                    "first name": attributes.first_name,
                    "middle name": attributes.middle_name,
                    "status": attributes.status,
                    "gender": attributes.gender,
                    "activated at": formatDate(attributes.activated_at)
                },
                contacts: {
                    "phone": attributes.phone,
                    "mobile phone": attributes.mobile_phone,
                    "email": attributes.email
                },
                address: attributes.address
            }
        },
        get isSelected() {
            return getParent(self, 2).selectedGuardians.includes(self.id)
        },
    }))