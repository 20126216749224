import "./AssignGuardian.css"

import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, converters, controlled } from "mstform";
import classNames from 'classnames'
import { observer, inject } from 'mobx-react';
import { Carousel } from 'antd';
import { AsYouType } from 'libphonenumber-js'
import { StudentGuardianRelation } from '../store/actions/StudentGuardianRelation'
import Label from '../common/form/Label';
import TextInput from '../common/form/TextInput';
import Avatar, { AVI_TYPES } from '../common/avatar';
import SwitchInput from '../common/form/SwitchInput';
import Segment from '../containers/Segment'
import GuardianListSearch from '../guardians/GuardianListSearch'
import Button from '../common/button';
import Table from '../common/table';
import Pagination from '../common/pagination';
import ModalForm from "../containers/ModalForm";
import NotMobile from "../common/display/NotMobile";
import Mobile from "../common/display/Mobile";
import Card from "../common/card";
import InlineError from "../common/form/InlineError";

const { DirectSearchForm } = GuardianListSearch
const form = new Form(StudentGuardianRelation, {
    student_id: new Field(converters.maybe(converters.string)),
    guardian_id: new Field(converters.maybe(converters.string)),
    relation: new Field(converters.string, { required: true }),
    emergency_contact: new Field(converters.boolean, { controlled: controlled.object }),
    authorized_to_pick_up: new Field(converters.boolean, { controlled: controlled.object }),
})

@inject('store', 'display')
@observer
class AssignGuardian extends React.Component {
    state = { selectedGuardian: undefined, carouselIndex: 0 }

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        studentId: PropTypes.string
    }

    static defaultProps = {
        studentId: null
    }

    componentDidMount() {
        const { store } = this.props
        const { guardianStore } = store
        guardianStore.search()
    }

    get listSummary() {
        const { store } = this.props
        const { guardianStore } = store
        const {searchMeta, searchResults, searchFormInstance} = guardianStore

        const totalCount = searchMeta.total_count
        if (totalCount === 0) return <span>&nbsp;</span>
        
        let itemsEnd = searchFormInstance.per_page * searchFormInstance.page
        if (itemsEnd > totalCount) itemsEnd = totalCount
        
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b> guardians</span>
    }

    handleAssign = (selectedGuardian) => {
        const { studentId } = this.props
        this.setState({ selectedGuardian })
        this.formInstance = StudentGuardianRelation.create({guardian_id: selectedGuardian.id, student_id: studentId})
        this.formState = form.state(this.formInstance, {
            save: async (node) => {
                const { onSave } = this.props
                await onSave(node.toJSON())
                return null
            }
        })
        this.carousel.next()
    }

    handleCarouselChange = (_, carouselIndex) => {
        this.setState({ carouselIndex })
    }

    handleSave = async () => {
        if (!this.formState) return
        const success = await this.formState.save()
        if (success) {
            const { onClose } = this.props
            onClose()
        }
    }

    render() {
        const { store, display, onClose } = this.props
        const { guardianStore } = store
        const {searchMeta, isLoading, searchResults, searchFormInstance} = guardianStore
        
        const { selectedGuardian, carouselIndex } = this.state
        const guardianName = selectedGuardian ? selectedGuardian.guardianName : ""
        const guardianSex = selectedGuardian ? selectedGuardian.attributes.gender : null
        const guardianPhoto = selectedGuardian ? selectedGuardian.attributes.photo_url : null

        let relation = null
        let emergency_contact = null
        let authorized_to_pick_up = null
        if (this.formState) {
            relation = this.formState.field("relation")
            emergency_contact = this.formState.field("emergency_contact")
            authorized_to_pick_up = this.formState.field("authorized_to_pick_up")
        }
        const inlineLabelWidth = "20%"
        const columns = [
            { key: 'photo', render: null },
            { key: 'name', render: 'Name' },
            { key: 'mobile_phone', render: 'Mobile' },
            { key: 'phone', render: 'Phone' },
            { key: 'email', render: 'Email' },
            { key: 'actions', render: null }
        ]
        const tableData = searchResults
            .map((guardian) => {
                const { attributes, guardianName: name } = guardian
                const {mobile_phone, phone, email, photo_url, gender} = attributes
                
                return {
                    photo: (
                        <Avatar
                            src={photo_url}
                            xsmall={display.isNotMobile}
                            small={display.isMobile}
                            type={AVI_TYPES.GUARDIAN}
                            sex={gender === 'male' ? 'm' : 'f'}
                        />
                    ),
                    name,
                    mobile_phone: new AsYouType().input(mobile_phone),
                    phone: new AsYouType().input(phone),
                    email,
                    actions: (
                        <Button
                            size="small"
                            onClick={() => this.handleAssign(guardian)}
                        >
                            {display.isMobile ? 'Assign Guardian' : 'Assign'}
                        </Button>
                    )
                }
            })
        
        const pagination = (
            <Pagination
                onClickPrev={searchFormInstance.prevPage}
                onClickNext={searchFormInstance.nextPage}
                pageCount={searchMeta.total_pages}
                currentPage={searchFormInstance.page}
            />
        )
        
        const directSearchClass = classNames(
            'AssignGuardian-directSearch',
            { 'AssignGuardian-directSearch-closed': carouselIndex === 1 }
        )

        return (
            <Carousel
                ref={node => (this.carousel = node)}
                dots={false}
                beforeChange={this.handleCarouselChange}
                infinite={false}
                adaptiveHeight
                draggable={false}
                touchMove={false}
            >
                <div className={directSearchClass}>
                    <Segment>
                        <DirectSearchForm settings={guardianStore} />
                    </Segment>
                    
                    <div>{this.listSummary}</div>
                    <NotMobile>
                        <Table striped columns={columns} data={tableData} loading={isLoading} footer={pagination} />
                    </NotMobile>
                    
                    <Mobile>
                        {tableData.map(item => <AssignGuardianItem guardianData={item} keys={columns} key={item.id} />)}
                        {pagination}
                    </Mobile>
                </div>

                {this.formState && (
                    <ModalForm
                        className="AssignGuardian"
                        onOk={this.handleSave}
                        onCancel={onClose}
                        okButtonText="Save"
                        isOkButtonDisabled={!this.formState.isValid || isLoading}
                    >
                        <button
                            type="button"
                            onClick={() => this.carousel.prev()}
                            className="flex-row mb-2"
                        >
                            <Avatar
                                className="utils-prepend"
                                style={{ paddingTop: 4 }}
                                xsmall
                                type={AVI_TYPES.GUARDIAN}
                                sex={guardianSex}
                                src={guardianPhoto}
                            />
                            <div className="text-h4 text--bold">{guardianName}</div>
                        </button>

                        <Label text="Relationship" required={relation.required} inline={display.isNotMobile} inlineWidth={inlineLabelWidth}>
                            <InlineError field={relation}>
                                <TextInput {...relation.inputProps} placeholder="eg: Mother, Father, Uncle, Aunt" />
                            </InlineError>
                        </Label>
                        
                        <Label text="Emergency Contact" inline={display.isNotMobile} inlineWidth={inlineLabelWidth}>
                            <SwitchInput {...emergency_contact.inputProps} />
                        </Label>
                        
                        <Label text="Authorized to pick up" inline={display.isNotMobile} inlineWidth={inlineLabelWidth}>
                            <SwitchInput {...authorized_to_pick_up.inputProps} />
                        </Label>
                        
                    </ModalForm>
                )}
            </Carousel>
        )
    }
}

export default AssignGuardian

function AssignGuardianItem({ guardianData, keys }) {
    return (
        <Card className="AssignGuardianItem">
            {keys.map(({ key }) => (
                <div key={key} className={`AssignGuardianItem-${key}`}>
                    {guardianData[key]}
                </div>
            ))}
        </Card>
    )
}