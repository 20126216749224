import { types } from "mobx-state-tree"
import { Bank } from "./Bank"

export const BankAccount = types
    .model("BankAccount", {
        id: types.identifier,
        type: "bank_account",
        attributes: types.maybe(types.model({
            account_name: "",
            name: types.string,
            account_number: "",
            bank_id: types.reference(Bank)
        }))
    })
    .views(self => ({
        get bankName() {
            return self.attributes.bank_id.attributes.short_name
        },
        
        get name() {
            return self.attributes.name
        }
    }))