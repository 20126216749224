import React from 'react'
import { observer, inject } from 'mobx-react';
import Notes from '../common/notes'
import { Note as action } from '../store/actions/Note'
import StudentPolicy from "../policies/StudentPolicy";

const StudentNotes = inject("store")(
    observer(({ store, notes }) => {
        const { studentStore, view, meta: {currentUser} } = store
        const { selectedStudent } = view
        const { isLoading, id: studentId } = selectedStudent
        
        const studentPolicy = new StudentPolicy(currentUser, selectedStudent )

        const localNotes = notes
            .map(({ attributes, id, author, updated, visible }) => ({
                id, author, updated, visible, ...attributes,
            }))

        function addNote(note) {
            studentStore.addNote(studentId, action.create(note).toJSON())
        }

        function removeNote(noteId) {
            studentStore.removeNote(studentId, noteId)
        }

        function editNote(noteId, note) {
            studentStore.editNote(studentId, noteId, action.create(note).toJSON())
        }

        return (
            <Notes
                canCreateNote={studentPolicy.can_create_note}
                isLoading={isLoading}
                notes={localNotes}
                onCreateNote={addNote}
                onDeleteNote={removeNote}
                onEditNote={editNote}
                visibilityText="Visible to parent"
            />
        )
    }))

export default StudentNotes
