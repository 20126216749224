import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/expense_account_transactions.json'
import financeSummaryJSON from "../json/finance_transactions_summary";

export function WalletTransactionService(env) {
    async function search(transaction_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("transaction_search", transaction_search)
            const { data } = await axios.get(`/api/v1/finance/expense_account_transactions?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function fetchFinanceSummary(ids) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/finance/expense_account_transactions/summary`, {ids})
            return data
        }
        return financeSummaryJSON
    }

    async function downloadWalletTransactionListSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/finance/wallet_transactions`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: {ids: action.item_ids}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        search,
        fetchFinanceSummary,
        downloadWalletTransactionListSpreadsheet
    }
}

export default WalletTransactionService(TESTENV)