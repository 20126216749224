import { types } from "mobx-state-tree"
import { Student } from "./Student";
import { BehaviouralDomainScore } from "./BehaviouralDomainScore"


const BatchStudentIdsForTerms = types
    .model("BatchStudentIdsForTerms", {
        term_id: types.number,
        term_name: types.string,
        student_ids: types.array(types.reference(Student)),
    }) 


export const BatchBehaviouralScoresheet = types
    .model("BatchBehaviouralScoresheet", {
        id: types.identifier,
        type: "batch_behavioural_scoresheet",
        attributes: types.maybe(types.model({
            batch_id: types.number,
            student_ids_for_terms: types.array(BatchStudentIdsForTerms)
        })),
        relationships: types.maybeNull(types.model({
            students: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            })),
            behavioural_domain_scores: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(BehaviouralDomainScore))
                }))
            })),
        })),
        isLoading: true
    })
    .views(self => ({
        get students() {
            try {
                return self
                    .relationships
                    .students
                    .data
                    .map(({id}) => id)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get studentsBehaviouralScores() {
            try {
                return self
                    .relationships
                    .behavioural_domain_scores
                    .data
                    .map(({id}) => id)
            } catch (e) {
                console.warn(e)
                return []
            }
        }
    }))