import './EmployeeList.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox, Row, Col } from 'antd'
import { Collapse } from 'react-collapse'
import { reaction } from 'mobx';
import classNames from "classnames";

import EmployeePolicy from "../policies/EmployeePolicy";

import ReactHelmet from "../containers/ReactHelmet";
import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar'
import { formatDate } from '../lib/formatDate'
import Tablet from '../common/display/Tablet'
import Desktop from '../common/display/Desktop'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import Pagination from '../common/pagination/Pagination';
import MobilePageHeader from '../containers/MobilePageHeader';
import EmployeeListSearch from './EmployeeListSearch';
import Segment from '../containers/Segment'
import { scrollToTop } from '../lib/scrollToTop';
import EmptyList from '../common/empty_list/empty_list';
import MobileListLoading from '../common/loading/MobileListLoading';
import {ExportCollection} from "../store/actions/ExportCollection";
import {ModalContext} from "../common/modal";
import Loading from "../common/loading/Loading";
import {handleSendMessage} from "../messages/SendMessageForm";


import addUser from './assets/add-user.svg'
import addUserWhite from './assets/add-user-white.svg'
import employeeIcon from '../common/assets/teacher-pointing-blackboard.svg'
import arrowDown from './assets/arrow-down-white.svg'
import chat from "../common/assets/chat.svg";
import spreadsheetIcon from "../common/assets/spreadsheet.svg";

const { MobileForm, TabletForm, DesktopForm } = EmployeeListSearch

@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportEmployeeListAction = ExportCollection.create({})
        this.state = { exportEmployeeListAction }
    }

    initiateEmployeeListExportDownload = () => {
        const { exportEmployeeListAction } = this.state
        const {store} = this.props;
        const { employeeStore: {selectedEmployees, downloadEmployeeListSpreadsheet}} = store

        exportEmployeeListAction.setToGetEmployees(selectedEmployees.toJSON())
        downloadEmployeeListSpreadsheet(exportEmployeeListAction)
    }

    render() {
        const { exportEmployeeListAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { employeeStore: {selectedEmployees}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedEmployees.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedEmployees.length === 0) {
                                    alert({message: {warning: "You must select at least one employee"}})
                                    return
                                }
                                this.initiateEmployeeListExportDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheetIcon} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedEmployees.length === 0) {
                                    alert({message: {warning: "You must select at least one employee"}})
                                    return
                                }
                                handleSendMessage(context, {employeeIDs: selectedEmployees.toJSON()})
                            }}
                        >
                            <img src={chat} style={{width: 18}} className="utils-prepend" alt="" />
                            Send Message
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}

@inject("store") @observer
class EmployeeList extends Component {
    componentDidMount() {
        const { store } = this.props
        const { employeeStore } = store
        employeeStore.search()
        reaction(
            () => employeeStore.isLoading,
            loading => { if (loading) scrollToTop() }
        )
    }

    get listSummary() {
        const { store } = this.props
        const { employeeStore } = store
        const {
            meta,
            searchResults,
            searchFormInstance
        } = employeeStore

        const totalCount = meta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = searchFormInstance.per_page * searchFormInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }

    render() {
        const { store } = this.props
        const { employeeStore, view, meta: {currentUser} } = store
        const {
            meta,
            isLoading,
            searchResults,
            selectEmployeeById,
            selectedEmployees,
            isAllSelected,
            selectAllEmployees,
            searchFormInstance
        } = employeeStore
        

        const policy = new EmployeePolicy(currentUser, 'Employee')

        const columns = [
            { key: 'photo', render: 'Photo' },
            { key: 'name', render: 'Name' },
            { key: 'username', render: 'Emp. No.' },
            { key: 'department', render: 'Department' },
            { key: 'category', render: 'Category' },
            { key: 'position', render: 'Position' },
            { key: 'date_joined', render: 'Joining Date' },
            { key: 'date_left', render: 'Date Left' },
            { key: 'date_of_birth', render: 'Date of Birth' },
            { key: 'blood_group', render: 'Blood Group' },
            { key: 'religion', render: 'Religion' },
            { key: 'updated_at', render: 'Last Updated' },
        ]
        const defaultColumns = ['name', 'username', 'department', 'category', 'position', 'date_joined']
        const tableData = searchResults
            .map(({ id, attributes, employeeName, departmentName, categoryName, positionName }) => ({
                id,
                name: (
                    <a
                        href={`/employees/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openEmployeePageById(id)
                        }}
                    >
                        {employeeName}
                    </a>
                ),
                username: attributes.username,
                department: departmentName,
                category: categoryName,
                position: positionName,
                date_joined: formatDate(attributes.date_joined),
                date_left: formatDate(attributes.date_left),
                date_of_birth: formatDate(attributes.date_of_birth),
                blood_group: attributes.blood_group,
                religion: attributes.religion,
                updated_at: attributes.updated_at,
                photo: (
                    <Avatar
                        src={attributes.photo_url}
                        small
                        type={AVI_TYPES.EMPLOYEE}
                        sex={attributes.gender === 'male' ? 'm' : 'f'}
                    />
                )
            }))
        const pagination = (
            <Pagination
                pageCount={meta.total_pages}
                currentPage={searchFormInstance.page}
                onClickPrev={searchFormInstance.prevPage}
                onClickNext={searchFormInstance.nextPage}
            />
        )

        return (
            <div className="EmployeeList">
                <ReactHelmet title="Employee List" />
                
                <NotMobile>
                    <Segment>
                        <Tablet>
                            <TabletForm store={employeeStore} />
                        </Tablet>
                        <Desktop>
                            <DesktopForm store={employeeStore} />
                        </Desktop>
                    </Segment>

                    <div className="flex-row flex-spaceBetween">
                        <div className="flex-row">
                            <ActionsButton className="utils-prepend" />
                            {Boolean(selectedEmployees.length) && (`${selectedEmployees.length} selected`)}
                        </div>
                        {this.listSummary}
                        <Button
                            onClick={view.openAddEmployeePage}
                            title={policy.can_create ? undefined : "No permission."}
                            disabled={!policy.can_create}
                        >
                            <img className="utils-prepend" src={addUserWhite} alt="" />
                            Add New Employee
                        </Button>
                    </div>
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        defaultVisibleColumnKeys={defaultColumns}
                        data={tableData}
                        onRowSelectionChange={selectEmployeeById}
                        onError={e => console.log(e)}
                        loading={isLoading}
                        onColumnKeysChange={e => console.log(e)}
                        selectedRows={selectedEmployees}
                        isAllSelected={isAllSelected}
                        onSelectAll={selectAllEmployees}
                        footer={pagination}
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <img src={employeeIcon} alt="" />
                                <span className="text-small utils-append text--uppercase">Employee List</span>
                            </Col>
                            <Col span={12}>
                                <Button
                                    buttonType="flat"
                                    fullwidth
                                    title={policy.can_create ? undefined : "No permission."}
                                    disabled={!policy.can_create}
                                    onClick={view.openAddEmployeePage}
                                >
                                    <img className="utils-prepend" src={addUser} alt="" />
                                    Add New Employee
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <ActionsButton fullwidth />
                            </Col>
                            <Col span={12}>
                                <MobileForm store={employeeStore} />
                            </Col>
                        </Row>
                        <Collapse isOpened={Boolean(selectedEmployees.length)}>
                            <div className="text--center">{`${selectedEmployees.length} selected`}</div>
                        </Collapse>
                    </MobilePageHeader>

                    {!isLoading && (searchResults.length === 0) && <EmptyList />}

                    {(isLoading || searchResults.length > 0) && (
                        <>
                            <div className="flex-row flex-spaceBetween">
                                {this.listSummary}
                                <div className="flex-row flex-alignCenter utils-prepend--large">
                                    <span className="text--bold text-p utils-prepend">Select All</span>
                                    <Checkbox checked={isAllSelected} onChange={selectAllEmployees} />
                                </div>
                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {searchResults.map(employee => <EmployeeListItem id={employee.id} key={employee.id} employee={employee} />)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default EmployeeList

const EmployeeListItem = inject("store")(
    observer(
        ({ employee, store }) => {
            const { id, attributes: { username, photo_url, gender }, employeeName, departmentName, categoryName, positionName } = employee
            const { employeeStore, view } = store
            const { selectEmployeeById, selectedEmployees } = employeeStore
            return (
                <Card className="EmployeeListItem mb-1">
                    <Checkbox
                        className="EmployeeListItem-checkbox"
                        checked={selectedEmployees.includes(id)}
                        onChange={() => selectEmployeeById(id)}
                    />
                    <Avatar small className="EmployeeListItem-avi" sex={gender && gender[0]} src={photo_url} type={AVI_TYPES.EMPLOYEE} />
                    <a
                        href={`/student/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openEmployeePageById(id)
                            return false
                        }}
                    >
                        {employeeName}
                    </a>
                    <div>Emp. No.: {username}</div>
                    <div>Department: {departmentName}</div>
                    <div>Category: {categoryName}</div>
                    <div>Position: {positionName}</div>
                </Card>
            )
        }
    ))