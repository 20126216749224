import './Modal.css'

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { ModalContext } from './ModalContext'

// Then create a provider Component
@observer
export class ModalProvider extends Component {
    state = {
        isModalVisible: false,
        modalTitle: null,
        modalContent: null,
        isModalSmall: false,
        width: undefined
    }

    render() {
        const { children } = this.props
        return (
            <ModalContext.Provider value={{
                state: this.state,
                setContent: (title, component, config) => {
                    let localConfig = {
                        isModalSmall: false
                    }
                    if (typeof config === 'boolean') {
                        localConfig = {
                            ...localConfig,
                            isModalSmall: config
                        }
                    } else {
                        localConfig = { ...localConfig, ...config }
                    }
                    this.setState({
                        modalTitle: title,
                        modalContent: component,
                        isModalVisible: true,
                        ...localConfig
                    })
                },
                closeModal: () => this.setState({ isModalVisible: false }),
                dispose: () => this.setState({
                    modalTitle: null,
                    modalContent: null,
                    isModalSmall: false,
                    width: undefined
                }),
            }}>
                <React.Fragment>
                    {children}
                </React.Fragment>
            </ModalContext.Provider>
        )
    }
}
