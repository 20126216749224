import { types } from 'mobx-state-tree';
// eslint-disable-next-line import/no-cycle
import {User} from "./User";

export const AttachedDocument = types
    .model('AttachedDocument', {
        id: types.identifier,
        type: 'attached_document',
        attributes: types.maybe(
            types.model({
                original_file_name: types.string,
                document_url: types.string,
            }),
        ),
        relationships: types.maybe(
            types.model({
                created_by: types.model({
                    data: types.maybeNull(types.model({
                        id: types.late(() => types.reference(User)),
                        type: "user"
                    }))
                })
            }),
        )
    });