import {types, getParent, isStateTreeNode, isType } from "mobx-state-tree"
import isEmpty from 'lodash.isempty';

import { titleCase } from "../../lib/titleCase";
import { formatDate } from "../../lib/formatDate"
import { EmployeeCategory } from "./EmployeeCategory"
import { EmployeeDepartment } from "./EmployeeDepartment";
import { EmployeePosition } from "./EmployeePosition";
import { Note } from "./Note"
import { BankAccount } from "./BankAccount";
import { Gender } from "./Gender"
import { UpdateEmployeePrivileges } from "../actions/Employee";
// eslint-disable-next-line import/no-cycle
import {SpecialRoleAssignment} from "./SpecialRoleAssignment";
// eslint-disable-next-line import/no-cycle
import {toPascalCase} from "../../lib/stringUtility";
// eslint-disable-next-line import/no-cycle
import {User} from "./User";
import {isObject} from "../../lib/deepMerge";

export const Employee = types
    .model("Employee", {
        id: types.identifier,
        type: "employee",
        attributes: types.maybe(types.model({
            username: types.maybeNull(types.string),
            first_name: types.maybeNull(types.string),
            middle_name: types.maybeNull(types.string),
            last_name: types.maybeNull(types.string),
            gender: types.maybeNull(Gender),
            email: types.maybeNull(types.string),
            mobile_phone: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            status: types.maybeNull(types.string),
            date_of_birth: types.maybeNull(types.string),
            blood_group: types.maybeNull(types.string),
            religion: types.maybeNull(types.string),
            nationality: types.maybeNull(types.string),
            photo_url: types.maybeNull(types.string),
            date_joined: types.maybeNull(types.string),
            date_left: types.maybeNull(types.string),
            updated_at: types.maybeNull(types.string),
            next_of_kin_name: types.maybeNull(types.string),
            relationship_with_next_of_kin: types.maybeNull(types.string),
            next_of_kin_mobile_phone: types.maybeNull(types.string),
            next_of_kin_phone: types.maybeNull(types.string),
            next_of_kin_address: types.maybeNull(types.string),
            notes: types.array(Note),
            privileges: types.array(types.string)
        })),
        relationships: types.maybe(types.model({
            user: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(User))
                })
            })),
            bank_account: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(BankAccount))
                }))
            })),
            special_role_assignments: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(SpecialRoleAssignment))
                }))
            })),
            category: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(EmployeeCategory))
                }))
            })),
            department: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(EmployeeDepartment))
                }))
            })),
            position: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(EmployeePosition))
                }))
            })),
        })),
        isLoading: true
    })
    .views(self => ({
        get bluebic(){
            return getParent(self,3 )
        },
        
        get isInitialized(){
            return !isEmpty(self.attributes)
        },

        get isAssociationsLoaded(){
            return !isEmpty(self.relationships) && !isEmpty(self.relationships.special_role_assignments)
        },
        
        get user() {
            try {
                return self
                    .relationships
                    .user
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get category() {
            try {
                return self
                    .relationships
                    .category
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get employee_category_id() {
            try {
                return self.category.id
            } catch(e) {
                return ""
            }
        },
        get department() {
            try {
                return self
                    .relationships
                    .department
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get employee_department_id() {
            try {
                return self.department.id
            } catch(e) {
                return ""
            }
        },
        get position() {
            try {
                return self
                    .relationships
                    .position
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get employee_position_id() {
            try {
                return self.position.id
            } catch(e) {
                return ""
            }
        },
        get bankAccount() {
            try {
                return self
                    .relationships
                    .bank_account
                    .data
                    .id
            } catch (e) {
                return null
            }
        },
        get employeeName() {
            const { first_name, middle_name, last_name } = self.attributes
            return titleCase(
                `${last_name}, ${first_name} ${middle_name || ""}`
            )
        },
        get titleAbbreviationsAndLastName() {
            const { first_name, middle_name, last_name } = self.attributes
            return titleCase(
                `${first_name[0]}. ${middle_name ? `${middle_name[0]}.` : ""} ${last_name}`
            )
        },
        get bioData() {
            const { attributes } = self
            return {
                employeeProfile: {
                    "title": attributes.title,
                    "last name": attributes.last_name,
                    "first name": attributes.first_name,
                    "middle name": attributes.middle_name,
                    "status": attributes.status,
                    "date of birth": formatDate(attributes.date_of_birth),
                    "gender": attributes.gender,
                    "blood group": attributes.blood_group,
                    "nationality": attributes.nationality,
                    "religion": attributes.religion,
                    "department": self.departmentName,
                    "position": self.positionName,
                    "category": self.categoryName
                },
                contacts: {
                    "phone": attributes.phone,
                    "mobile phone": attributes.mobile_phone,
                    "email": attributes.email
                },
                address: attributes.address,
                nextOfKin: {
                    "next of kin name": attributes.next_of_kin_name,
                    "relationship": attributes.relationship_with_next_of_kin,
                    "next of kin mobile": attributes.next_of_kin_mobile_phone,
                    "next of kin phone": attributes.next_of_kin_phone,
                    "next of kin address": attributes.next_of_kin_address
                },
                bankAccount: {
                    "bank": self.bankAccount ? self.bankAccount.bankName : self.bankAccount,
                    "account name": self.bankAccount ? self.bankAccount.attributes.account_name : self.bankAccount,
                    "account number": self.bankAccount ? self.bankAccount.attributes.account_number : self.bankAccount
                }
            }
        },
        get categoryName() {
            try {
                return self.category.attributes.name
            } catch (e) {
                return null
            }
        },
        get departmentName() {
            try {
                return self.department.attributes.name
            } catch (e) {
                return null
            }
        },
        get positionName() {
            try {
                return self.position.attributes.name
            } catch (e) {
                return null
            }
        },
        get isSelected() {
            return getParent(self, 2).selectedEmployees.includes(self.id)
        },
        
        get specialRoleAssignments() {
            try {
                return self
                    .relationships
                    .special_role_assignments
                    .data
                    .map(({ id: ref }) => ref)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        has_any_role(roles){
            return !isEmpty(roles.filter(role => self.privileges.includes(role)))
        },

        has_role(role){
            return self.privileges.includes(role)
        },

        // Roles Types: principal, class_teacher, subject_teacher, guidance_counsellor
        has_any_special_role_on_resource(roles, resource = null){
            const specialRolesIDsToCheck = []
            
            self.bluebic.specialRoles.forEach((sr) => {
                if (roles.includes(sr.attributes.role_type)) specialRolesIDsToCheck.push(sr.id) 
            })
            if (isEmpty(specialRolesIDsToCheck)) throw new Error('Unknown Roles types')

            
            const spas = self.specialRoleAssignments.filter((sra) => {
                if ( sra.isDeleted) return false
                
                const {attributes: {resource_type, resource_id, special_role_id: {id: special_role_id}}} = sra
                
                if (!resource) return specialRolesIDsToCheck.includes(special_role_id)
                
                if (typeof resource === 'string') return resource_type === resource
                
                if (isType(resource) && resource.name) return resource_type === resource.name
                
                if (isStateTreeNode(resource) && resource.id && resource.type){
                    return  resource_type === toPascalCase(resource.type) && resource_id === parseInt(resource.id, 10)
                }
                
                if (isObject(resource) && resource.type && resource.id){
                    return resource_type === resource.type && resource_id === resource.id
                }

                throw new Error('Unknown resource type')
            })
            
            return !isEmpty(spas)
        },

        get privileges(){
            return self.attributes.privileges
        }
    }))
    .actions(self => ({
        togglePrivilege(privilege) {
            if (self.attributes.privileges.includes(privilege)) {
                const idx = self.attributes.privileges.findIndex(p => p === privilege)
                self.attributes.privileges.splice(idx, 1)
            } else {
                self.attributes.privileges.push(privilege)
            }
        },
        selectAllPrivilegesInCategory(isAllSelected, allPrivilegesInCategory) {
            if (isAllSelected) {
                allPrivilegesInCategory.forEach(({ key }) => {
                    const index = self.attributes.privileges.findIndex(elem => elem === key)
                    if (index >= 0) {
                        self.attributes.privileges.splice(index, 1)
                    }
                })
            }  else {
                allPrivilegesInCategory.forEach(({ key }) => {
                    if (!self.attributes.privileges.includes(key)) {
                        self.attributes.privileges.push(key)
                    }
                })
            }
        },
        updatePrivileges() {
            const { privileges } = self.attributes
            const employee = UpdateEmployeePrivileges.create({ privileges: privileges.toJSON() })
            getParent(self, 2).updatePrivilegesById(self.id, employee.toJSON())
        }
    }))