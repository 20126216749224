import { types } from "mobx-state-tree"
import {SubjectBase} from "./SubjectBase";

export const SubjectsReportCardData = types
    .model("SubjectsReportCardData", {
        id: types.identifier, // ReportCardGroup.id + subject.id
        subject_base_id: types.reference(SubjectBase),
        student_ids: types.array(types.number),
        cognitive_columns_summary: types.array(types.model({
            column_id: types.number,
            max_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
            average_weighted_grade: types.maybeNull(types.union(types.string, types.number)),
            student_ids: types.array(types.number)
        }))
        
    })