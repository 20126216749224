export function stringifyQuery(key, params) {
    try {
        if (Array.isArray(params)) {
            return params
                .map(param => `${key}[]=${param}`)
                .join("&")
        }
        
        // TODO check for and distinctly handle string and Object. Currently assumes object.
        return Object
            .keys(params)
            .filter((paramsKey) => params[paramsKey])
            .map((paramsKey) => `${key}[${paramsKey}]=${params[paramsKey]}`)
            .join("&")
    } catch (e) {
        console.error(e)
        return ""
    }
}