import React from 'react'
import PropTypes from 'prop-types'
import './Card.css'

function Card({ children, title, className = "", style }) {
    return (
        <div className={`Card ${className}`} style={style}>
            {title && <div className="Card-title">{title}</div>}
            {children}
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.node, PropTypes.string
    ])
}
Card.defaultProps = {
    title: null
}

export default Card

