import {applySnapshot, types} from "mobx-state-tree"
import { today } from "../types/dateType"

const BankTeller = types
    .model("BankTeller", {
        bank_account_id: types.maybe(types.string),
        amount: types.maybe(types.string),
        teller_no: types.maybe(types.string),
        date: types.maybe(types.string)
    })

export const StudentTransaction = types
    .model("StudentTransaction", {
        transaction_date: types.optional(types.string, today),
        note: types.maybe(types.string),
        student_fee_ids: types.optional(types.array(types.string), []),
        payment_method: types.maybe(types.string),
        wallet_credit: types.maybe(types.string),
        wallet_debit: types.maybe(types.string),
        bank_teller: types.optional(BankTeller, {}),
        fee_amount: types.maybe(types.union(types.string, types.number)),
    })
    .actions((self) => ({
        setFormInstanceFromPreflight(preflight){
            const {
                total_fee_to_pay,
                payment_method,
                wallet_credit,
                wallet_debit,
                student_fees_to_be_paid
            } = preflight
            
            applySnapshot(self, {
                fee_amount: total_fee_to_pay,
                payment_method,
                wallet_credit,
                wallet_debit,
                student_fee_ids: student_fees_to_be_paid.map(item => item.student_fee.data.id.id)
            })
        }
    }))