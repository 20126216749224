import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { reaction } from 'mobx';
import { Collapse } from 'react-collapse'
import Table from './table'
import NotMobile from './display/NotMobile'
import Mobile from './display/Mobile'
import Pagination from './pagination';
import { scrollToTop } from '../lib/scrollToTop';
import EmptyList from './empty_list/empty_list';
import MobileListLoading from './loading/MobileListLoading';
import { modelOf } from '../lib/modelOf';
import { CollectionPattern } from '../store/patterns/collectionPattern';
import Segment from '../containers/Segment';
import Tablet from './display/Tablet';
import Desktop from './display/Desktop';
import MobilePageHeader from '../containers/MobilePageHeader';

@observer
class Collection extends Component {

    static propTypes = {
        // TODO
        // settings: modelOf(CollectionPattern).isRequired,
    }

    componentDidMount() {
        const { settings: { isLoading, search } } = this.props
        search()
        reaction(
            () => isLoading,
            loading => {
                if (loading) scrollToTop()
            }
        )
    }

    get collectionSummaryText() {
        const {settings: { searchMeta, searchResults, searchFormInstance}} = this.props

        const totalCount = searchMeta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        const { page, per_page } = searchFormInstance
        let itemsEnd = per_page * page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }

    get collectionSelectedText() {
        const { settings: { selectedItemKeys } } = this.props
        if (selectedItemKeys.length > 0) {
            return `${selectedItemKeys.length} selected`
        }
        return null
    }

    buildHeader = () => {
        const {
            settings,
            buildHeader,
            useSearchComponent
        } = this.props
        const { TabletForm, DesktopForm } = useSearchComponent
        const searchComponent = (
            <Segment>
                <Tablet>
                    <TabletForm settings={settings} />
                </Tablet>
                <Desktop>
                    <DesktopForm settings={settings} />
                </Desktop>
            </Segment>
        )
        return buildHeader({
            collectionSummaryText: this.collectionSummaryText,
            collectionSelectedText: this.collectionSelectedText,
            searchComponent
        })
    }

    buildMobileHeader = () => {
        const {
            settings,
            buildMobileHeader,
            useSearchComponent
        } = this.props
        const { MobileForm } = useSearchComponent
        const searchComponent = <MobileForm settings={settings} />
        return buildMobileHeader({ searchComponent })
    }

    render() {
        const {settings, tableSettings, buildMobileItem, useSearchComponent} = this.props
        
        const {
            isLoading,
            searchMeta,
            searchResults,
            searchFormInstance,
            selectItemById,
            selectedItemKeys,
            isAllSelected,
            selectAllItems,
        } = settings
        const {
            defaultColumns,
            columns,
            tableData,
            tableProps = {}
        } = tableSettings
        const { page, nextPage, prevPage } = searchFormInstance
        const pagination = (
            <Pagination
                pageCount={searchMeta.total_pages}
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
            />
        )
        const Header = useSearchComponent ? this.buildHeader() : null
        const MobileHeader = useSearchComponent ? this.buildMobileHeader() : null

        return (
            <div className="GuardianList">
                <NotMobile>
                    {Header}
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        defaultVisibleColumnKeys={defaultColumns}
                        data={tableData}
                        onRowSelectionChange={selectItemById}
                        onError={e => console.log(e)}
                        loading={isLoading}
                        onColumnKeysChange={e => console.log(e)}
                        selectedRows={selectedItemKeys}
                        isAllSelected={isAllSelected}
                        onSelectAll={selectAllItems}
                        footer={pagination}
                        {...tableProps}
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        {MobileHeader}
                        <Collapse isOpened={Boolean(this.collectionSelectedText)}>
                            <div className="text--center">{this.collectionSelectedText}</div>
                        </Collapse>
                    </MobilePageHeader>
                    {!isLoading && (searchResults.length === 0) && <EmptyList />}

                    {(isLoading || searchResults.length > 0) && (
                        <>
                            {this.collectionSummaryText}
                            <MobileListLoading isLoading={isLoading}>
                                {searchResults.map(buildMobileItem)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default Collection