/* eslint-disable react/no-multi-comp */
import "./MessageCollectionSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import {  Col } from 'antd';
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { debounce } from '../lib/debounce'
import Button from '../common/button'

import { MessageSearch } from '../store/actions/Message';
import { dateRangeConverter } from '../lib/formatDate';


const formModel = new MstForm(MessageSearch, {
    date_created_range: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    })
})

const DateCreatedField = observer(({ formState }) => {
    const date_created_range = formState.field("date_created_range")
    return (
        <Label text="Date Created">
            <DateRangeInput {...date_created_range.inputProps} />
        </Label>
    )
})

export default function MessageCollectionSearch() {
    return null
}

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    render() {
        return (
            <form>
                <DateCreatedField formState={this.formState} />
            </form>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        return (
            <form className="FeeSearch-TabletForm">
                <Col span={12}>
                    <DateCreatedField formState={this.formState} />
                </Col>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { settings: { searchFormInstance, updateSearch } } = props
        this.formState = formModel.state(searchFormInstance)
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    render() {
        const { settings: { search, isLoading } } = this.props
        
        return (
            <form className="FeeSearch-DesktopForm">
                <DateCreatedField formState={this.formState} />
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

MessageCollectionSearch.MobileForm = MobileForm
MessageCollectionSearch.TabletForm = TabletForm
MessageCollectionSearch.DesktopForm = DesktopForm
