import './Tabs.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Tab from './Tab'
import { OptionsPopover, OPTIONS_PROP } from './OptionsPopover'
import NotMobile from '../display/NotMobile'
import NotDesktop from '../display/NotDesktop'
import ErrorBoundary from '../error_boundary'
import pdf from './assets/pdf.svg'


class Tabs extends Component {
    static propTypes = {
        tabs: PropTypes.arrayOf(PropTypes.instanceOf(Tab)).isRequired,
        content: PropTypes.node,
        responsive: PropTypes.bool,
        options: OPTIONS_PROP,
        title: PropTypes.shape({
            icon: PropTypes.string,
            text: PropTypes.string
        }),
        canPrint: PropTypes.bool
    }

    static defaultProps = {
        responsive: true,
        options: [],
        content: null,
        canPrint: true,
        title: {
            icon: null,
            text: null,
        }
    }

    constructor(props) {
        super(props)
        const { tabs } = this.props
        this.state = { tabs }
    }

    setActive = id => {
        const { tabs } = this.state
        tabs.forEach(tab => {
            tab.isActive = tab.id === id
        })
        this.setState({ tabs })
    }

    get activeTabComponent() {
        const { tabs } = this.state
        if (tabs.length) {
            const activeTab = tabs.find(tab => tab.isActive)
            return activeTab.component
        }
        return null
    }

    render() {
        const { options, title, content, responsive, canPrint } = this.props
        const { tabs } = this.state
        const tabsClass = classNames('tabs', { 'tabs-isNotResponsive': !responsive })

        return (
            <React.Fragment>
                <div className={tabsClass}>
                    <div className="tabs-grid">
                        {responsive && (
                            <React.Fragment>
                                <NotDesktop>
                                    <div className="flex-row flex-alignCenter tabs-title">
                                        <img src={title.icon} alt="" />
                                        <span className="text-small text--uppercase utils-append">{title.text}</span>
                                    </div>
                                </NotDesktop>
                                <div className="tabs-options">
                                    {canPrint && (
                                        <NotMobile>
                                            <button
                                                type="button"
                                                className="touchable-opacity flex-row flex-alignCenter flex-justifyCenter text-p"
                                            >
                                                <img src={pdf} alt="" className="utils-prepend" />
                                                Print
                                            </button>
                                        </NotMobile>
                                    )}
                                    {!!options.length && <OptionsPopover options={options} />}
                                </div>
                            </React.Fragment>
                        )}
                        <div className="tabs-content">{content}</div>
                    </div>
                    <div className="tabs-container">
                        {tabs.map(tab => (
                            <div
                                key={tab.id}
                                onClick={() => this.setActive(tab.id)}
                                className={classNames(
                                    'tab',
                                    tab.isActive ? 'tab-isActive' : 'touchable-opacity'
                                )}
                            >
                                {tab.text}
                            </div>
                        ))}
                    </div>
                </div>
                <ErrorBoundary>
                    {this.activeTabComponent}
                </ErrorBoundary>
            </React.Fragment>
        )
    }
}

export default Tabs
