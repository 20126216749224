import React from 'react'
import * as PropTypes from "prop-types";
import {format, isSameDay, isSameMonth, isAfter, isBefore, parseISO, addDays, endOfWeek, startOfWeek, endOfMonth, startOfMonth} from 'date-fns'
import {
    dateFormatMonthYearShort,
    dateFormatOnlyDay,
    dayOfWeekFormat,
    formatDate
} from "../../lib/formatDate";
// import calendar from './assets/Calendar.svg'

export default class Calendar extends React.Component {
  state = { currentMonth: null }

  componentWillMount() {
    let { currentMonth } = this.props
    const { selectsStart, startDate } = this.props
    if (selectsStart && startDate) {
      currentMonth = startDate
    }
    this.setState({ currentMonth })
  }

    // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onUpdate(prevProps)
  }

  onUpdate(prevProps) {
    const { currentMonth } = this.props
    if (currentMonth !== prevProps.currentMonth) {
      this.setState({ currentMonth })
    }
  }

  render() {
    const { currentMonth } = this.state
    const {
      selectsStart,
      selectsEnd,
      nextMonth,
      prevMonth,
    } = this.props

    // generate weekdays
    const weekdays = []
    const startWeek = startOfWeek(currentMonth)
    for (let i = 0; i < 7; i += 1) {
      const weekday = addDays(startWeek, i)
      weekdays.push(
        <div className="DateRangePicker-calendarWeekday" key={weekday}>
          {format(weekday, dayOfWeekFormat)}
        </div>
      )
    }

    // generate days
    const monthStart = startOfMonth(currentMonth)
    const monthEnd = endOfMonth(monthStart)
    const startDay = startOfWeek(monthStart)
    const endDay = endOfWeek(monthEnd)

    const days = []
    let day = startDay

    while (day <= endDay) {
      for (let i = 0; i < 7; i += 1) {
        days.push(
          <Cell
            {...this.props}
            day={day}
            key={day}
            disabled={!isSameMonth(day, monthStart)}
          />
        )
        day = addDays(day, 1)
      }
    }
    return (
      <div className="DateRangePicker-calendar">
        <div className="DateRangePicker-calendarNav">
          {selectsStart && (
            <button
              type="button"
              onClick={prevMonth}
              className="DateRangePicker-calendarNavLeft touchable-opacity"
            >
              <i className="material-icons">chevron_left</i>
            </button>
          )}
          <div className="DateRangePicker-calendarNavDate">{format(currentMonth, dateFormatMonthYearShort)}</div>
          {selectsEnd && (
            <button
              type="button"
              onClick={nextMonth}
              className="DateRangePicker-calendarNavRight touchable-opacity"
            >
              <i className="material-icons">chevron_right</i>
            </button>
          )}
        </div>
        <div className="DateRangePicker-calendarWeekdays">{weekdays}</div>
        <div className="DateRangePicker-calendarDays">{days}</div>
      </div>
    )
  }
}
Calendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired,
  prevMonth: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
}
Calendar.defaultProps = {
  selectsStart: false,
  selectsEnd: false,
  startDate: null,
  endDate: null
}

const Cell = ({ day, onChange, startDate, endDate, disabled }) => (
        <div
            key={day}
            onClick={() => disabled ? null : onChange(day)}
            className={`
          DateRangePicker-calendarDay 
          ${disabled ? 'DateRangePicker-calendarDay-isDisabled' : ''}
          ${isSameDay(day, startDate) ? 'DateRangePicker-calendarDay-isRangeStart' : ''}
          ${isSameDay(day, endDate) ? 'DateRangePicker-calendarDay-isRangeEnd' : ''}
          ${isAfter(day, startDate) && isBefore(day, endDate) ? 'DateRangePicker-calendarDay-isBetweenRange' : ''}
        `}
        >{formatDate(day, dateFormatOnlyDay)}
        </div>
    )