import ApplicationPolicy from "./ApplicationPolicy";

class CustomFinanceTransactionPolicy extends ApplicationPolicy{
    get can_index(){
        return this.user.has_any_role(['finance', 'finance_control'])
    }
    
    get can_create(){
        return this.user.has_any_role(['finance', 'finance_control'])
    }

    get can_update(){
        return this.can_index
    }
}

export default CustomFinanceTransactionPolicy