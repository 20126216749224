import { dateRangeRegex } from './formatDate';


function dateRangePropType(props, propName, componentName) {
    const {propName: value} = props
    if (value && !dateRangeRegex.test(value)) {
        return new Error(`Invalid prop \`${  propName  }\` supplied to \`${  componentName  }\`. Validation failed.`);
    }
    return null
}

dateRangePropType.isRequired = (props, propName) => !(propName in props) ? new Error(`Missing prop ${propName} in props`) : dateRangePropType(props, propName)


export {dateRangePropType};