import * as lodashDebounce from 'lodash.debounce'

/* eslint-disable func-names */
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    if (immediate) return lodashDebounce(func, wait, {leading: true})
    return lodashDebounce(func, wait, {trailing: true})
}