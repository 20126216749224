import React from 'react';
import PropTypes from 'prop-types';
import ShortLoadingText from '../common/loading/ShortLoadingText'
import icon from './assets/tuition-and-fees.svg';
import './StudentFees.css';

import Card from '../common/card';
import invoice from "./assets/invoice.svg";
import money from "./assets/money.svg";
import sandClock from "./assets/sand-clock.svg";
import {inject} from "mobx-react";

const title = 'Student fees'

const Icons = {
    'Invoiced': { icon: invoice, color: '#1B75BB' },
    'Received': {  icon: money, color: '#17BF5F' },
    'Pending': { icon: sandClock, color: '#FAD961' },
}

@inject("store")
class StudentFees extends React.Component {
    render() {
        const {
            financeStat,
            store: {
                feeStore: {searchFormInstance: feeSearchFormInstance},
                financeTransactionStore: {searchFormInstance: financeTransactionSearchFormInstance},
                view
            }
        } = this.props;

        return (
            <div className="dashboard-studentFees studentFees">
                <div className="studentFees-head flex-row">
                    <img className="dashboard-icon" src={icon} alt="" />
                    <span className="text-small text--uppercase">{title}</span>
                </div>
                {Object.keys(Icons).map(statKeyDesc => {
                    const statKeyValue = financeStat ? financeStat[statKeyDesc] : undefined
                    let linkAddress, searchData, pageToOpenFunction, searchFormInstanceToUse

                    if (statKeyDesc == 'Invoiced'){
                        linkAddress = view.getFeesPage()
                        searchData = {}
                        pageToOpenFunction = view.openFeesPage
                        searchFormInstanceToUse = feeSearchFormInstance
                    }else if (statKeyDesc == 'Received'){
                        linkAddress = view.getFinanceTransactionsPage
                        searchData = {}
                        pageToOpenFunction = view.openFinanceTransactionsPage
                        searchFormInstanceToUse = financeTransactionSearchFormInstance
                    }else if (statKeyDesc == 'Pending'){
                        linkAddress = view.getFeesPage()
                        searchData = {payment_status: 'partly_or_unpaid'}
                        pageToOpenFunction = view.openFeesPage
                        searchFormInstanceToUse = feeSearchFormInstance
                    }else {
                        // throw new Error(`Unknown finance statKeyText "${statKeyText}"`)
                    }

                    return (
                        //TODO Add ability to include searchData in URL to enable "Right Click -> Open in new tab"
                        <a
                            href={linkAddress}
                            onClick={e => {
                                e.preventDefault()
                                if (searchFormInstanceToUse) searchFormInstanceToUse.applyData(searchData)
                                if (pageToOpenFunction) pageToOpenFunction()
                            }}
                        >
                            <Card key={statKeyDesc} className="flex-row flex-spaceBetween">
                                <div>
                                    <div className="text-small text--grey text--uppercase">{statKeyDesc}</div>
                                    <div className="text-h2 text--primary">
                                        { statKeyValue ||  ShortLoadingText() }
                                    </div>
                                </div>
                                <div
                                    className="studentFees-iconContainer"
                                    style={{ backgroundColor: Icons[statKeyDesc].color }}
                                >
                                    <img className="studentFees-icon" src={Icons[statKeyDesc].icon} alt="" />
                                </div>
                            </Card>
                        </a>
                    )
                })}
            </div>
        );
    }
}

StudentFees.propTypes = {
    financeStat: PropTypes.shape({
        'Invoiced': PropTypes.string,
        'Received': PropTypes.string,
        'Pending': PropTypes.string
    })
}

StudentFees.defaultProps = {
    financeStat: undefined
}

export default StudentFees
