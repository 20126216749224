import './StudentList.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col, Checkbox } from 'antd';
import classNames from 'classnames'
import { Collapse } from 'react-collapse'
import { AsYouType } from 'libphonenumber-js'
import { reaction } from 'mobx';
import { onPatch } from "mobx-state-tree";


import ReactHelmet from "../containers/ReactHelmet";
import { debounce } from "../lib/debounce";
import { formatDate } from '../lib/formatDate'
import Button from '../common/button'
import Table from '../common/table'
import Card from '../common/card'
import { ModalContext } from '../common/modal'
import Avatar, { AVI_TYPES } from '../common/avatar'
import Tablet from '../common/display/Tablet'
import Desktop from '../common/display/Desktop'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import DisableStudentLogin from './DisableStudentLogin';
import FinanceSummary from './FinanceSummary';
import { handleSendMessage } from "../messages/SendMessageForm";
import MobilePageHeader from '../containers/MobilePageHeader';
import StudentListSearch from './StudentListSearch';
import Segment from '../containers/Segment'
import Pagination from '../common/pagination/Pagination';
import { scrollToTop } from '../lib/scrollToTop';
import EmptyList from '../common/empty_list/empty_list';
import MobileListLoading from '../common/loading/MobileListLoading';
import Loading from "../common/loading/Loading";


import StudentPolicy from "../policies/StudentPolicy";

import arrowDown from './assets/arrow-down-white.svg'
import addUserWhite from '../common/assets/add-user-white.svg'
import calculator from '../common/assets/calculator.svg'
import spreadsheetIcon from '../common/assets/spreadsheet.svg'
import block from '../common/assets/block.svg'
import chat from '../common/assets/chat.svg'
import addUser from '../common/assets/add-user.svg'
import studentIcon from './assets/graduate-student-avatar.svg'
import { ExportCollection } from "../store/actions/ExportCollection";

const { MobileForm, TabletForm, DesktopForm } = StudentListSearch

const columns = [
    { key: 'photo', render: 'Photo' },
    { key: 'name', render: 'Name' },
    { key: 'admissionNumber', render: 'Adm. No.' },
    { key: 'batch', render: 'Batch' },
    { key: 'due_fees_balance', render: 'Due Fees' },
    { key: 'unpaid_fees_balance', render: 'Unpaid Fees' },
    { key: 'admission_date', render: 'Adm. Date' },
    { key: 'address', render: 'Address' },
    { key: 'blood_group', render: 'Blood Group' },
    { key: 'date_of_birth', render: 'Date of Birth' },
    { key: 'wallet_balance', render: 'Wallet' },
    { key: 'email', render: 'Email' },
    { key: 'updated_at', render: 'Last Updated' },
    { key: 'mobile_phone', render: 'Mobile Phone' },
    { key: 'nationality', render: 'Nationality' },
    { key: 'phone', render: 'Phone' },
    { key: 'religion', render: 'Religion' },
]
const defaultColumns = ['name', 'admissionNumber', 'batch', 'due_fees_balance', 'admission_date']


@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportStudentListAction = ExportCollection.create({})
        this.state = { exportStudentListAction }
    }

    initiateStudentListExportDownload = () => {
        const { exportStudentListAction } = this.state
        const { store } = this.props;
        const { studentStore: { selectedStudents, downloadStudentListSpreadsheet } } = store

        exportStudentListAction.setToGetStudents(selectedStudents.toJSON())
        downloadStudentListSpreadsheet(exportStudentListAction)
    }

    render() {
        const { exportStudentListAction: { isSaving } } = this.state
        const { store, ...rest } = this.props;
        const { studentStore: { selectedStudents }, alert } = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            { "Button--disabled": selectedStudents.length === 0 }
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedStudents.length === 0) {
                                    alert({ message: { warning: "You must select at least one student" } })
                                    return
                                }
                                context.setContent(
                                    `Finance Summary for ${selectedStudents.length} Students`,
                                    <FinanceSummary studentIDs={selectedStudents} />,
                                    true
                                )
                            }}
                        >
                            <img src={calculator} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Finance Summary
                        </button>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedStudents.length === 0) {
                                    alert({ message: { warning: "You must select at least one student" } })
                                    return
                                }
                                this.initiateStudentListExportDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheetIcon} style={{ width: 18 }} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedStudents.length === 0) {
                                    alert({ message: { warning: "You must select at least one student" } })
                                    return
                                }
                                handleSendMessage(context, { studentIDs: selectedStudents.toJSON() })
                            }}
                        >
                            <img src={chat} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Send Message
                        </div>
                        <div className="divider" />
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedStudents.length === 0) {
                                    alert({ message: { warning: "You must select at least one student" } })
                                    return
                                }
                                context.setContent(
                                    "Block Student Portal Access",
                                    <DisableStudentLogin studentIds={selectedStudents.toJSON()} onClose={context.closeModal} />,
                                    true
                                )
                            }}
                        >
                            <img src={block} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Disable Login
                        </button>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{ content: popover, placement: 'bottomLeft' }}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}


@inject("store") @observer
class StudentList extends Component {

    constructor(props) {
        super(props)
        const { store: { studentStore: { searchFormInstance, search } } } = props

        onPatch(searchFormInstance, debounce((patch) => {
            if (patch.path !== "/page") searchFormInstance.goToFirstPage()
            search()
        }, 250))
    }

    componentDidMount() {
        const { store } = this.props
        const { studentStore } = store
        studentStore.search()
        reaction(
            () => studentStore.isLoading,
            loading => {
                if (loading) scrollToTop()
            }
        )
    }

    get listSummary() {
        const { store } = this.props
        const { studentStore } = store
        const {
            meta,
            searchResults,
            searchFormInstance
        } = studentStore

        const totalCount = meta.total_count
        if (totalCount === 0) {
            return <span>&nbsp;</span>
        }
        let itemsEnd = searchFormInstance.per_page * searchFormInstance.page
        if (itemsEnd > totalCount) {
            itemsEnd = totalCount
        }
        const itemsStart = itemsEnd - searchResults.length + 1;
        return <span>Listing <b>{itemsStart}</b> - <b>{itemsEnd}</b> of <b>{totalCount}</b></span>
    }

    render() {
        const { store } = this.props
        const {
            studentStore,
            view: {
                openAddStudentPage,
                openStudentPageById
            },
            meta: { currentUser },
            formatCurrency
        } = store

        const {
            meta,
            isLoading,
            searchResults,
            selectStudentById,
            selectedStudents,
            isAllSelected,
            selectAllStudents,
            searchFormInstance,
        } = studentStore

        const policy = new StudentPolicy(currentUser, 'Student')
        const tableData = searchResults
            .map(({ id, attributes, studentName, admissionNumber, batchNameNoSession: batch }) => ({
                id,
                name: (
                    <a
                        href={`/students/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            openStudentPageById(id)
                        }}
                    >
                        {studentName}
                    </a>
                ),
                admissionNumber,
                batch,
                admission_date: formatDate(attributes.admission_date),
                address: attributes.address,
                blood_group: attributes.blood_group,
                date_of_birth: formatDate(attributes.date_of_birth),
                unpaid_fees_balance: formatCurrency(attributes.unpaid_fees_balance),
                due_fees_balance: formatCurrency(attributes.due_fees_balance),
                wallet_balance: formatCurrency(attributes.wallet_balance),
                email: attributes.email,
                updated_at: attributes.updated_at,
                mobile_phone: new AsYouType().input(attributes.mobile_phone),
                nationality: attributes.nationality,
                phone: new AsYouType().input(attributes.phone),
                religion: attributes.religion,
                photo: (
                    <Avatar
                        src={attributes.photo_url}
                        small
                        type={AVI_TYPES.STUDENT}
                        sex={attributes.gender === 'male' ? 'm' : 'f'}
                    />
                )
            }))
        const pagination = (
            <Pagination
                pageCount={meta.total_pages}
                currentPage={searchFormInstance.page}
                onClickPrev={searchFormInstance.prevPage}
                onClickNext={searchFormInstance.nextPage}
            />
        )

        return (
            <div className="StudentList">
                <ReactHelmet title="Student List" />

                <NotMobile>
                    <Segment>
                        <Tablet>
                            <TabletForm studentStore={studentStore} />
                        </Tablet>
                        <Desktop>
                            <DesktopForm studentStore={studentStore} />
                        </Desktop>
                    </Segment>

                    <div className="flex-row flex-spaceBetween">
                        <div className="flex-row">
                            <ActionsButton className="utils-prepend" />
                            {Boolean(selectedStudents.length) && (`${selectedStudents.length} selected`)}
                        </div>
                        {this.listSummary}
                        <Button
                            title={policy.can_create ? undefined : "No permission."}
                            disabled={!policy.can_create}
                            onClick={openAddStudentPage}
                        >
                            <img className="utils-prepend" src={addUserWhite} alt="" />
                            Add New Student
                        </Button>
                    </div>
                    <Table
                        striped
                        containerClass="Card"
                        columns={columns}
                        defaultVisibleColumnKeys={defaultColumns}
                        data={tableData}
                        onRowSelectionChange={selectStudentById}
                        onError={e => console.error(e)}
                        loading={isLoading}
                        onColumnKeysChange={e => console.log(e)}
                        selectedRows={selectedStudents}
                        isAllSelected={isAllSelected}
                        onSelectAll={selectAllStudents}
                        footer={pagination}
                    />
                </NotMobile>

                <Mobile>
                    <MobilePageHeader>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <img src={studentIcon} alt="" />
                                <span className="text-small utils-append text--uppercase">Student List</span>
                            </Col>
                            <Col span={12}>
                                <Button
                                    buttonType="flat"
                                    title={policy.can_create ? undefined : "No permission."}
                                    disabled={!policy.can_create}
                                    fullwidth
                                    onClick={openAddStudentPage}
                                >
                                    <img className="utils-prepend" src={addUser} alt="" />
                                    Add New Student
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={15} type="flex" align="middle">
                            <Col span={12}>
                                <ActionsButton fullwidth />
                            </Col>
                            <Col span={12}>
                                <MobileForm studentStore={studentStore} />
                            </Col>
                        </Row>
                        <Collapse isOpened={Boolean(selectedStudents.length)}>
                            <div className="text--center">{`${selectedStudents.length} selected`}</div>
                        </Collapse>
                    </MobilePageHeader>

                    {!isLoading && (searchResults.length === 0) && <EmptyList />}

                    {(isLoading || searchResults.length > 0) && (
                        <>
                            <div className="flex-row flex-spaceBetween">
                                {this.listSummary}
                                <div className="flex-row flex-alignCenter utils-prepend--large">
                                    <span className="text--bold text-p utils-prepend">Select All</span>
                                    <Checkbox checked={isAllSelected} onChange={selectAllStudents} />
                                </div>
                            </div>
                            <MobileListLoading isLoading={isLoading}>
                                {searchResults.map(student => <StudentListItem id={student.id} key={student.id} student={student} />)}
                            </MobileListLoading>
                            {pagination}
                        </>
                    )}
                </Mobile>
            </div>
        )
    }
}

export default StudentList

const StudentListItem = inject("store")(
    observer(
        ({ student, store }) => {
            const { id, studentName, attributes: { photo_url, gender } } = student
            let { attributes: { admission_date, due_fees_balance } } = student
            const { studentStore, view, formatCurrency } = store
            const { selectStudentById, selectedStudents } = studentStore
            admission_date = formatDate(admission_date)
            due_fees_balance = formatCurrency(due_fees_balance)
            return (
                <Card className="StudentListItem mb-1">
                    <Checkbox
                        className="StudentListItem-checkbox"
                        checked={selectedStudents.includes(id)}
                        onChange={() => selectStudentById(id)}
                    />
                    <Avatar
                        small
                        className="StudentListItem-avi"
                        src={photo_url}
                        sex={gender && gender[0]}
                        type={AVI_TYPES.STUDENT}
                    />
                    <a
                        href={`/student/${id}`}
                        onClick={(e) => {
                            e.preventDefault()
                            view.openStudentPageById(id)
                            return false
                        }}
                    >{studentName}
                    </a>
                    <div>Adm. Date: {admission_date}</div>
                    <div>Due Fees Bal.: {due_fees_balance}</div>
                </Card>
            )
        }
    ))
