import ApplicationPolicy from "./ApplicationPolicy";

class MessagePolicy extends ApplicationPolicy{
    get can_index(){
        return this.user.has_role('message_management')
    }
    
    get can_create(){
        return this.user.has_role('message_management')
    }
    
    get can_show(){
        return this.user.has_role('message_management')
    }
    
    get can_view_sms_topup_history(){
        return this.user.has_role('message_management')
    }
}

export default MessagePolicy