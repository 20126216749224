import React from 'react'
import ContentLoader from '../common/content_loader';

export default function LoadingText() {
    return (
        <div className="text--alignCenter">
            <ContentLoader height="150" width="150%">
                <rect x="0" y="10" rx="2" ry="2" width="150" height="20" />
                <rect x="65%" y="13" rx="2" ry="2" width="250" height="20" />
                <rect x="0" y="46" rx="2" ry="2" width="150" height="20" />
                <rect x="65%" y="49" rx="2" ry="2" width="250" height="20" />
            </ContentLoader>
        </div>
    )
}
