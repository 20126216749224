import axios from './axios'
import { TESTENV } from './constants'
import createNoteJSON from '../json/create_note_success.json'
import updateNoteJSON from '../json/edit_note_success.json'
import removeNoteJSON from '../json/remove_note_success.json'

export function NoteService(env) {

    async function addStudentNote(student_id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/${student_id}/notes`, action)
            return data
        }
        return createNoteJSON
    }

    async function addGuardianNote(guardian_id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/guardians/${guardian_id}/notes`, action)
            return data
        }
        return createNoteJSON
    }

    async function addEmployeeNote(employee_id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/employees/${employee_id}/notes`, action)
            return data
        }
        return createNoteJSON
    }

    async function updateNote(id, action) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/notes/${id}`, action)
            return data
        }
        return updateNoteJSON
    }

    async function removeNote(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/notes/${id}`)
            return data
        }
        return removeNoteJSON
    }

    return {
        addStudentNote,
        addGuardianNote,
        addEmployeeNote,
        updateNote,
        removeNote,
    }
}

export default NoteService(TESTENV)