import axios from './axios'
import { TESTENV } from './constants'
import attendanceRegisterDeleteMarkJSON from '../json/batch_attendance_delete_mark.json'
import attendanceRegisterUpdateMarkJSON from '../json/batch_attendance_update_mark.json'
import { formDataTransform } from './formDataTransform'

export function AttendanceService(env) {

    async function update(attendance) {
        if (env !== TESTENV) {
            const { data } = await axios.post(`/api/v1/students/attendances`, { attendance }, { transformRequest: formDataTransform })
            return data
        }
        return attendanceRegisterUpdateMarkJSON
    }

    async function destroy(id) {
        if (env !== TESTENV) {
            const { data } = await axios.delete(`/api/v1/students/attendances/${id}`)
            return data
        }
        return attendanceRegisterDeleteMarkJSON
    }

    async function downloadSpreadsheet(batch_id) {
        const { data: fileData, headers } = await axios({
            url: `/resources/batches/${batch_id}/attendance_spreadsheet`,
            method: 'GET',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: {}
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }

    return {
        update,
        downloadSpreadsheet,
        destroy
    }
}