import { types } from "mobx-state-tree"

export const CreateAccessToken = types
    .model("CreateAccessToken", {
        username: types.string,
        password: types.string,
        grant_type: types.optional(
            types.union(types.literal('password'), types.literal('refresh_token')),
            'password'
        )
    })
.actions((self) => ({
    resetPassword(){
        self.password = ""
    }
}))