/* eslint-disable react/no-multi-comp */
import "./EmployeeListSearch.css"

import React from 'react'
import { Field, Form as MstForm, converters, controlled } from "mstform"
import { observer, inject } from 'mobx-react';
import { onSnapshot } from 'mobx-state-tree';
import { Row, Col, Checkbox } from 'antd';

import {dateRangeConverter} from "../lib/formatDate";
import { EmployeeSearch, statusOptions } from "../store/models/EmployeeSearch"
import SelectInput from '../common/form/SelectInput';
import TextInput from '../common/form/TextInput';
import DateRangeInput from "../common/form/DateRangeInput";
import Label from '../common/form/Label';
import { genderOptions } from '../store/models/Gender';
import { debounce } from '../lib/debounce'
import { titleCase } from '../lib/titleCase'
import FullscreenDialog from '../common/fullscreen_dialog';
import Button from '../common/button'

import filter from './assets/filter-blue.svg'


const formModel = new MstForm(EmployeeSearch, {
    query: new Field(converters.string, {
        controlled: controlled.string
    }),
    status: new Field(converters.string, {
        controlled: controlled.object
    }),
    gender: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    joining_period: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    date_of_birth_period: new Field(converters.maybe(dateRangeConverter), {
        controlled: controlled.object
    }),
    category_id: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    department_id: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
    position_id: new Field(converters.maybe(converters.string), {
        controlled: controlled.object
    }),
})

const QueryField = observer(({ formState }) => {
    const query = formState.field("query")
    return (
        <Label text="Direct Search">
            <TextInput
                placeholder="Name, Emp. No, Phone number, Email Address"
                {...query.inputProps}
            />
        </Label>
    )
})

const StatusField = observer(({ formState }) => {
    const status = formState.field("status")
    return (
        <Label text="Status">
            <SelectInput
                {...status.inputProps}
                placeholder="Select status"
                options={statusOptions.map(option => ({ text: titleCase(option), value: option }))}
            />
        </Label>
    )
})

const GenderField = observer(({ formState }) => {
    const gender = formState.field("gender")
    return (
        <Label text="Gender">
            <SelectInput
                {...gender.inputProps}
                placeholder="Select gender"
                options={genderOptions.map(option => ({ text: titleCase(option), value: option }))}
                defaultOptionText="All genders"
            />
        </Label>
    )
})

const JoiningPeriodField = observer(({ formState }) => {
    const joining_period = formState.field("joining_period")
    return (
        <Label text="Date Joined">
            <DateRangeInput label="Date Joined" {...joining_period.inputProps} />
        </Label>
    )
})

const DateofBirthField = observer(({ formState }) => {
    const date_of_birth_period = formState.field("date_of_birth_period")
    return (
        <Label text="Date of Birth">
            <DateRangeInput showOptions={false} label="Date of Birth" {...date_of_birth_period.inputProps} />
        </Label>
    )
})

const CategoryField = inject("store")(
    observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { employee_categories }
            }
        } = store
        const options = []
        employee_categories.forEach(({ id, attributes }) => {
            options.push({ text: attributes.name, value: id })
        })
        const category_id = formState.field("category_id")
        return (
            <Label text="Category">
                <SelectInput
                    {...category_id.inputProps}
                    placeholder="Select category"
                    options={options}
                    defaultOptionText="All categories"
                />
            </Label>
        )
    })
)

const DepartmentField = inject("store")(
    observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { employee_departments }
            }
        } = store
        const options = []
        employee_departments.forEach(({ id, attributes }) => {
            options.push({ text: attributes.name, value: id })
        })
        const department_id = formState.field("department_id")
        return (
            <Label text="Department">
                <SelectInput
                    {...department_id.inputProps}
                    placeholder="Select department"
                    options={options}
                    defaultOptionText="All departments"
                />
            </Label>
        )
    })
)

const PositionField = inject("store")(
    observer(({ store, formState }) => {
        const {
            meta: {
                attributes: { employee_positions }
            }
        } = store
        const options = []
        employee_positions.forEach(({ id, attributes }) => {
            options.push({ text: attributes.name, value: id })
        })
        const position_id = formState.field("position_id")
        return (
            <Label text="Position">
                <SelectInput
                    {...position_id.inputProps}
                    placeholder="Select position"
                    options={options}
                    defaultOptionText="All positions"
                />
            </Label>
        )
    })
)

export default function EmployeeListSearch() {
    return null
}

const fullscreenDialogTrigger = (handleTrigger) => (
    <Button buttonType="outline" fullwidth onClick={handleTrigger}>
        <img className="utils-prepend" src={filter} alt="" />
        Filter
    </Button>
)

@observer
class MobileForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { store: { updateSearch, searchFormInstance } } = this.props
        return (
            <FullscreenDialog
                title="Filters"
                renderTrigger={fullscreenDialogTrigger}
                onConfirm={() => updateSearch(searchFormInstance)}
            >
                <form>
                    <QueryField formState={this.formState} />
                    <StatusField formState={this.formState} />
                    <GenderField formState={this.formState} />
                    <CategoryField formState={this.formState} />
                    <DepartmentField formState={this.formState} />
                    <PositionField formState={this.formState} />
                    <JoiningPeriodField formState={this.formState} />
                    <DateofBirthField formState={this.formState} />
                </form>
            </FullscreenDialog>
        )
    }
}

@observer
class TabletForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
    }

    render() {
        const { store: { updateSearch, search, searchFormInstance, isLoading } } = this.props
        return (
            <form className="EmployeeListSearch-TabletForm">
                <QueryField formState={this.formState} />
                <StatusField formState={this.formState} />
                <FullscreenDialog
                    title="Filters"
                    renderTrigger={fullscreenDialogTrigger}
                    onConfirm={() => updateSearch(searchFormInstance)}
                >
                    <Row gutter={15}>
                        <Col span={12}>
                            <QueryField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <StatusField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <GenderField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <CategoryField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DepartmentField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <PositionField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <JoiningPeriodField formState={this.formState} />
                        </Col>
                        <Col span={12}>
                            <DateofBirthField formState={this.formState} />
                        </Col>
                    </Row>
                </FullscreenDialog>
                <Button onClick={search} fullwidth disabled={isLoading}>
                    Apply Filter
                </Button>
            </form>
        )
    }
}

function MoreFields({ fields, formState }) {
    const moreFields = Array
        .from(fields)
        .map(([key, value]) => {
            // eslint-disable-next-line no-shadow
            const Field = key
            const { hidden, label } = value
            return hidden ? null : <Field key={label} formState={formState} />
        })
    return (
        <div className="EmployeeListSearch-MoreFields">
            {moreFields}
        </div>
    )
}

@observer
class DesktopForm extends React.Component {

    constructor(props) {
        super(props)
        const { store: { updateSearch, searchFormInstance } } = props
        this.formState = formModel.state(searchFormInstance)
        this.state = {
            moreFields: new Map([
                [CategoryField, { hidden: !searchFormInstance.category_id, label: 'Category' }],
                [DepartmentField, { hidden: !searchFormInstance.department_id, label: 'Department' }],
                [PositionField, { hidden: !searchFormInstance.position_id, label: 'Position' }],
                [JoiningPeriodField, { hidden: !searchFormInstance.joining_period, label: 'Date Joined' }],
                [DateofBirthField, { hidden: !searchFormInstance.date_of_birth_period, label: 'Date of Birth' }],
            ])
        }
        onSnapshot(searchFormInstance, debounce((snapshot) => updateSearch(snapshot), 250))
    }

    toggleFieldHiddenState = (fieldKey, hiddenState) => {
        const { moreFields } = this.state
        const updatedFields = Array
            .from(moreFields)
            .map(([key, value]) => {
                const { label, hidden } = value
                return [
                    key,
                    { label, hidden: (fieldKey === key ? hiddenState : hidden) }
                ]
            })
        this.setState({ moreFields: updatedFields })
    }

    render() {
        const { store } = this.props
        const { search, isLoading } = store
        const { moreFields } = this.state
        const popoverContent = Array
            .from(moreFields)
            .map(([key, value], idx) => {
                const { hidden, label } = value
                return (
                    <React.Fragment key={key}>
                        {idx > 0 && <div className="divider" />}
                        <div className="Button Button--clear">
                            <Checkbox
                                checked={!hidden}
                                onChange={() => this.toggleFieldHiddenState(key, !hidden)}
                            >
                                {label}
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            })

        return (
            <form className="EmployeeListSearch-DesktopForm">
                <QueryField formState={this.formState} />
                <StatusField formState={this.formState} />
                <GenderField formState={this.formState} />
                <Button
                    buttonType="outline"
                    fullwidth
                    popover={{ content: popoverContent }}
                >
                    <img className="utils-prepend" src={filter} alt="" />
                    More Filters
                </Button>
                <Button onClick={search} disabled={isLoading} fullwidth>
                    Apply Filter
                </Button>
                <MoreFields fields={moreFields} formState={this.formState} />
            </form>
        )
    }
}

EmployeeListSearch.MobileForm = MobileForm
EmployeeListSearch.TabletForm = TabletForm
EmployeeListSearch.DesktopForm = DesktopForm
