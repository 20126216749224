import React from 'react'
import {inject, observer} from 'mobx-react';
import NotMobile from '../common/display/NotMobile';
import Avatar, {AVI_TYPES} from '../common/avatar'
import ProfileLoading from '../common/loading/ProfileLoading';
import './BatchSummary.css'
import BatchAssignRole from "./BatchAssignRole";
import {ModalContext} from "../common/modal";
import BatchPolicy from "../policies/BatchPolicy";


const BatchSummary = inject("store")(
    observer(({batch, store}) => {
        const {attributes, specialRoleAssignments, isLoading} = batch;
        if (isLoading || !attributes) return <ProfileLoading useCard={false} />
        
        const {student_count, name} = attributes;
        if (specialRoleAssignments.length === 0) return null

        const {meta: {currentUser}} = store;
        const policy = new BatchPolicy(currentUser, batch)

        return (
            <React.Fragment>
                <div className='pl-1' style={{fontSize: "18px", fontWeight: 600, paddingBottom: '15px'}}>
                    {name}
                </div>
                <div className="batchSummary">
                    <div className="batchDetails">
                        {specialRoleAssignments.slice(0, 2).map(item => <BatchSpecialRoleAssignment key={item.id} specialRoleAssignment={item} />)}
                    </div>
                    <NotMobile>
                        <div className="pl-2">
                            <div className="mb-1">
                                <div className="text--grey text-small">Students in Batch</div>
                                <div className="text-h4">{student_count}</div>
                            </div>
                            <div>
                                <div className="text--grey text-small">Average Age</div>
                                <div className="text-p">0</div>
                            </div>
                        </div>
                    </NotMobile>
                </div>
                {specialRoleAssignments.length > 2 && (
                    <ModalContext.Consumer>
                        {context => (
                            <div className="pl-1">
                                <button
                                    className='touchable-opacity touchable-highlight text--blue'
                                    type='button'
                                    disabled={!policy.can_update_role}
                                    title={policy.can_update_role && "No permission."}
                                    onClick={() => {
                                        context.setContent(
                                            `Assign Employee`,
                                            <BatchAssignRole batchInfo={batch} editMode />
                                        )
                                    }}
                                >
                                    +{specialRoleAssignments.length - 2} more employee{specialRoleAssignments.length - 2 > 2 && 's'}...
                                </button>
                            </div>
                        )}
                    </ModalContext.Consumer>
                )}
            </React.Fragment>
        )
    })
)
export default BatchSummary;

function BatchSpecialRoleAssignment({specialRoleAssignment}) {
    const {
        attributes: {
            role_name
        },
        relationships: {
            employee: {
                data: {
                    id: {
                        attributes: {
                            username, photo_url, gender
                        },
                        employeeName
                    }
                }
            }
        }
    } = specialRoleAssignment

    return (
        <div className="batchSummary-group pl-1">
            <Avatar
                className="batchSummary-avatar"
                src={photo_url}
                bordered
                sex={gender}
                type={AVI_TYPES.EMPLOYEE}
            />
            <div>
                <div className="text--grey text-small">Employee Assigned</div>
                <div className="text-h4">{employeeName}</div>
            </div>
            <div>
                <div className="text--grey text-small">Employee No.</div>
                <div className="text-p">{username}</div>
            </div>
            <div>
                <div className="text--grey text-small">Role</div>
                <div className="text-p">{role_name}</div>
            </div>
        </div>
    )
}
