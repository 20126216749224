import React from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox } from 'antd'
import Card from '../common/card'
import Avatar, { AVI_TYPES } from '../common/avatar'
import { formatDate } from '../lib/formatDate'

const GuardianListItem = inject("store")(
    observer(
        ({ guardian, store }) => {
            const { id, attributes: { photo_url, gender, activated_at }, guardianName, studentGuardianRelations } = guardian
            const { guardianStore, view } = store
            const { selectItemById, selectedItemKeys } = guardianStore
            return (
                <Card className="GuardianListItem mb-1">
                    <div className="flex-row flex-spaceBetween flex-alignStart">
                        <div className="flex-row flex-alignStart">
                            <Avatar small type={AVI_TYPES.GUARDIAN} sex={gender && gender[0]} src={photo_url} />
                            <a href={`guardians/${id}`} onClick={() => view.openGuardianPageById(id)} className="utils-append">{guardianName}</a>
                        </div>
                        <Checkbox
                            checked={selectedItemKeys.includes(id)}
                            onChange={() => selectItemById(id)}
                        />
                    </div>
                    <div className="GuardianListItem-wards">
                        Wards:
                        <div className="GuardianListItem-wardItems">
                            {studentGuardianRelations.map(({ student }) => (
                                <div key={student.id}>
                                    <Avatar xsmall src={student.attributes.photo_url} />
                                    {student.studentName}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        Activated at: {formatDate(activated_at)}
                    </div>
                </Card>
            )
        }
    ))

export default GuardianListItem