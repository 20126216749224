import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { format } from "date-fns";
import { Popover } from "antd";
import PropTypes from "prop-types";

import "./AcademicSessions.css";
import BackButtonMobile from "../BackButtonMobile";
import NoDataAvailable from "../NoDataAvailable";
import Button from "../../common/button";
import Table from "../../common/table";
import NotDesktop from "../../common/display/NotDesktop";
import Desktop from "../../common/display/Desktop";
import Card from "../../common/card/Card";
import moreVert from "../../subjects/assets/more-vert.svg";
import NewAcademicSession from "./NewAcademicSession";
import { ModalContext } from "../../common/modal";
import ButtonDropDownContent from "../ButtonDropDownContent";

@inject("store")
@observer
class AcademicSessions extends Component {
    state = {
        selectedRowId: null,
        displayMobileCreateForm: false,
        isMobileEditMode: false,
    };

    async componentDidMount() {
        const { store } = this.props;
        const { academicSessionStore: { getAcademicSessions } } = store;

        await getAcademicSessions();
    }

    onRowSelectionChange = selectedRowId => this.setState({ selectedRowId });

    toggleMobileCreateForm = (isMobileEditMode = false, selectedRowId = null) => {
        const { displayMobileCreateForm } = this.state;
        this.setState({ displayMobileCreateForm: !displayMobileCreateForm, isMobileEditMode, selectedRowId });
    }

    handleEditClick = (context) => () => {
        const { selectedRowId } = this.state;
        context.setContent("", <NewAcademicSession editMode selectedSessionId={selectedRowId} closeForm={context.closeModal} />, {});
    }

    handleDeleteClick = (id) => async () => {
        const { store } = this.props;
        const { academicSessionStore: { deleteAcademicSession } } = store;

        await deleteAcademicSession(id);
    }

    handleAddNewSessionClick = (context) => () => {
        context.setContent("", <NewAcademicSession editMode={false} closeForm={context.closeModal} />, {});
    }

    render() {
        const { selectedRowId, displayMobileCreateForm, isMobileEditMode } = this.state;
        const { store } = this.props;
        const { academicSessionStore: { academicSessions, isFetchSessionsLoading } } = store;

        const availableAcademicSessions = Array.from(academicSessions.keys()).map(key => {
            const session = academicSessions.get(key);

            return { ...session, ...session.attributes };
        });

        return (
            <div className="AcademicSessions">
                <div className="AcademicSessions--Header">
                    <BackButtonMobile onClick={displayMobileCreateForm ? this.toggleMobileCreateForm : null} />
                    {
                        !displayMobileCreateForm && <h2>Academic Sessions</h2>
                    }
                </div>
                <div className="AcademicSessions--Body">
                    <Desktop>
                        <ModalContext.Consumer>
                            {(context) => !availableAcademicSessions.length
                                ? (
                                    <NoDataAvailable
                                        handleButtonClick={this.handleAddNewSessionClick(context)}
                                        buttonText="Create New Session"
                                        title="There's Nothing Here At The Moment"
                                        description="You haven't added any academic sessions yet. Tap the button below to create a new session"
                                    />
                                )
                                : (
                                    <div className="AcademicSessions--cta-table-wrapper">
                                        <div className="AcademicSessions--cta-wrapper">
                                            <Button
                                                popover={{
                                                    content: <ButtonDropDownContent disabled={!selectedRowId} handleEditClick={this.handleEditClick(context)} handleDeleteClick={this.handleDeleteClick(selectedRowId)} />,
                                                    placement: 'bottomLeft',
                                                }}
                                            >
                                                Actions ▼
                                            </Button>
                                            <Button
                                                buttonType="outline"
                                                onClick={this.handleAddNewSessionClick(context)}
                                            >
                                                Add New Session
                                            </Button>
                                        </div>
                                        <div className="AcademicSessions--table-wrapper">
                                            <Table
                                                bordered
                                                containerClass="Card"
                                                columns={columns}
                                                data={availableAcademicSessions}
                                                onError={e => console.log(e)}
                                                loading={isFetchSessionsLoading}
                                                shouldSelectAllColumns
                                                onColumnKeysChange={e => console.log(e)}
                                                selectedRows={[selectedRowId]}
                                                onRowSelectionChange={this.onRowSelectionChange}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </ModalContext.Consumer>
                    </Desktop>

                    <NotDesktop>
                        {
                            !availableAcademicSessions.length && !displayMobileCreateForm
                                ? (
                                    <NoDataAvailable
                                        handleButtonClick={this.toggleMobileCreateForm}
                                        buttonText="Create New Session"
                                        title="There's Nothing Here At The Moment"
                                        description="You haven't added any academic sessions yet. Tap the button below to create a new session"
                                    />
                                )
                                : displayMobileCreateForm
                                    ? (<NewAcademicSession closeForm={this.toggleMobileCreateForm} editMode={isMobileEditMode} selectedSessionId={selectedRowId} />)
                                    : (
                                        <div className="AcademicSessions--cta-card-wrapper">
                                            <div className="AcademicSessions--cta-wrapper">
                                                <Button onClick={this.toggleMobileCreateForm}>Create New Session</Button>
                                            </div>
                                            <div className="AcademicSessions--cta-card-wrapper">
                                                {
                                                    availableAcademicSessions.map((row, index) => <MobileRow handleEditClick={this.toggleMobileCreateForm} handleDeleteClick={this.handleDeleteClick} {...row} key={row.id || index} />)
                                                }
                                            </div>
                                        </div>
                                    )
                        }
                    </NotDesktop>
                </div>
            </div>
        )
    }
}

export default AcademicSessions;

function MobileRow(props) {
    const {
        id, name, first_term_begins, first_term_ends, second_term_begins,
        second_term_ends, third_term_begins, third_term_ends, handleEditClick, handleDeleteClick,
    } = props;

    return (
        <Card>
            <div className="MobileRow">
                <div className="MobileRow--Body">
                    <div className="MobileRow--Row">
                        <span><strong>Session</strong></span>
                        <span>{name}</span>
                    </div>
                    <div className="MobileRow--Row">
                        <span><strong>First Term</strong></span>
                        <span>{format(new Date(first_term_begins), "PP")} - {format(new Date(first_term_ends), "PP")}</span>
                    </div>
                    <div className="MobileRow--Row">
                        <span><strong>Second Term</strong></span>
                        <span>{format(new Date(second_term_begins), "PP")} - {format(new Date(second_term_ends), "PP")}</span>
                    </div>
                    <div className="MobileRow--Row">
                        <span><strong>Third Term</strong></span>
                        <span>{format(new Date(third_term_begins), "PP")} - {format(new Date(third_term_ends), "PP")}</span>
                    </div>
                </div>
                <div className="MobileRow--cta">
                    <Popover
                        placement="bottomRight"
                        content={<ButtonDropDownContent disabled={!id} handleEditClick={() => handleEditClick(true, id)} handleDeleteClick={handleDeleteClick(id)} />}
                        trigger="click"
                    >
                        <img
                            src={moreVert}
                            alt="icon: more outlined"
                        />
                    </Popover>
                </div>
            </div>
        </Card>
    );
}

MobileRow.propTypes = {
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
}

const columns = [
    { key: 'name', render: 'Session' },
    { key: 'first_term_begins', render: 'First Term Begins' },
    { key: 'first_term_ends', render: 'First Term Ends' },
    { key: 'second_term_begins', render: 'Second Term Begins' },
    { key: 'second_term_ends', render: 'Second Term Ends' },
    { key: 'third_term_begins', render: 'Third Term Begins' },
    { key: 'third_term_ends', render: 'Third Term Ends' },
];
