import { types } from "mobx-state-tree"

export const FinanceTransactionCategory = types
    .model("FinanceTransactionCategory", {
        id: types.identifier,
        type: types.literal("finance_transaction_category"),
        attributes: types.maybe(types.model({
            name: "",
            description: types.maybeNull(types.string),
        })),
    })