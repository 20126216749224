import {getParent, types} from "mobx-state-tree"
import { AcademicSession } from "./AcademicSession";
import { Course } from "./Course";
import { Student } from "./Student";
import { SpecialRoleAssignment } from "./SpecialRoleAssignment";
import { BehaviouralDomainGroup } from "./BehaviouralDomainGroup"

export const Batch = types
    .model("Batch", {
        id: types.identifier,
        type: types.literal("batch"),
        attributes: types.maybe(types.model({
            name: "",
            arm: "",
            student_count: types.number
        })),
        relationships: types.maybe(types.model({
            academic_session: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(AcademicSession))
                })
            })),
            course: types.maybe(types.model({
                data: types.model({
                    id: types.late(() => types.reference(Course))
                })
            })),
            students: types.maybe(types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            })),
             affective_domain_group: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(BehaviouralDomainGroup))
                }))
            })),
            psychomotor_domain_group: types.maybe(types.model({
                data: types.maybeNull(types.model({
                    id: types.late(() => types.reference(BehaviouralDomainGroup))
                }))
            })),
            special_role_assignments: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(SpecialRoleAssignment))
                }))
            }),
        })),
        selectedStudents: types.array(types.string),
        isLoading: false
    })
    .views(self => ({
        get batchStore() {
            return getParent(self, 2)
        },
        
        get name() {
            return self.attributes.name
        },
        
        get attendanceReport() {
            return self.batchStore.attendanceReports.get(self.id)
        },
        
        get attendanceRegister() {
            return self.batchStore.attendanceRegisters.get(self.id)
        },
        
        get behaviouralScoresheet() {
            return self.batchStore.behaviouralScoresheets.get(self.id)
        },
        
        get academicSession() {
            return self
                .relationships
                .academic_session
                .data
                .id
        },
        get course() {
            return self
                .relationships
                .course
                .data
                .id
        },
        get studentsInBatch() {
            try {
                return self
                    .relationships
                    .students
                    .data
                    .map(({id}) => id)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get specialRoleAssignments(){
            try {
                return self
                    .relationships
                    .special_role_assignments
                    .data.map(item => item.id)
            } catch (e) {
                console.warn(e)
                return []
            }
        },
        get isAllStudentSelected() {
            return (self.selectedStudents.length > 0 ) && ( self.selectedStudents.length === self.studentsInBatch.length);
        },
        get affectiveDomainGroup() {
            try {
                return self
                    .relationships
                    .affective_domain_group
                    .data
                    .id
            } catch (e) {
                console.warn(e)
                return undefined
            }
        },
        get psychomotorDomainGroup() {
            try {
                return self
                    .relationships
                    .psychomotor_domain_group
                    .data
                    .id
            } catch (e) {
                console.warn(e)
                return undefined
            }
        },
    })).actions(self => ({
            selectStudentById(id) {
                if (self.selectedStudents.includes(id)) {
                    const index = self.selectedStudents.findIndex(id_ => id_ === id)
                    self.selectedStudents.splice(index, 1)
                } else {
                    self.selectedStudents.push(id)
                }
            },
            selectAllStudents() {
                if (self.isAllStudentSelected) {
                    self.selectedStudents.length = 0
                } else {
                    self.studentsInBatch.forEach(({ id }) => {
                        if (!self.selectedStudents.includes(id)) {
                            self.selectedStudents.push(id)
                        }
                    })
                }
            },
            removeStudentIDsFromBatch(arr){
                let foundStudentIndex
                let foundSelectedStuIndex
                
                
                arr.forEach(elem => {
                    foundStudentIndex = self.studentsInBatch.findIndex(({id}) => id === elem)
                    foundSelectedStuIndex =  self.selectedStudents.findIndex(id => elem === id)
                    
                    if(foundStudentIndex >=0){
                        self.relationships.students.data.splice(foundStudentIndex, 1);
                    }
                    
                    if(foundSelectedStuIndex >=0){
                        self.selectedStudents.splice(foundSelectedStuIndex, 1);
                    }
                })
            },
            removeSpecialRole(special_role_id){
                const foundStudentIndex = self.specialRoleAssignments.findIndex(({id}) => id === special_role_id)
                if(foundStudentIndex >=0){
                    self.relationships.special_role_assignments.data.splice(foundStudentIndex, 1);
                }
            }
    }))