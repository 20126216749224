import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

function TableLoading({ loading }) {
  const tableLoadingClass = className(
    'TableLoading',
    { 'TableLoading-isHidden': !loading }
  )

  return (
    <div className={tableLoadingClass}>
      <div className="TableLoading-indeterminate" />
    </div>
  )
}
TableLoading.propTypes = {
  loading: PropTypes.bool
}
TableLoading.defaultProps = {
  loading: false
}

export default TableLoading

