import { types } from "mobx-state-tree"
import { Search } from "./Search";

export const BatchSearch = types
    .compose(
        Search,
        types.model({
            academic_session_id: types.maybe(types.string),
            course_id: types.maybe(types.string),
        })
    )