import {onPatch, types} from "mobx-state-tree"
import isEmpty from 'lodash.isempty';

import { dateRangeType } from "../types/dateRangeType";
import { Search } from "../models/Search";


export const MessageSearch = types
    .compose(
        Search,
        types
            .model("MessageSearch", {
                date_created_range: types.maybeNull(dateRangeType),
            })
    )

export const SmsTopupSearch = types
    .compose(
        Search,
        types
            .model("SmsTopupSearch", {
                date_created_range: types.maybeNull(dateRangeType),
            })
    )

export const SendMessage = types
    .model("SendMessage", {
        subject: "",
        body: "",
        
        send_as_email: false,
        send_as_sms: true,
        send_to_students: true,
        send_to_student_guardians: true,
        
        student_ids: types.array(types.string),
        guardian_ids: types.array(types.string),
        employee_ids: types.array(types.string),
        user_ids: types.array(types.string),
        
        mobile_numbers: types.maybe(types.string),
        emails: types.maybe(types.string),
        
        client_state: types.optional(types.model({
            isSaving: types.optional(types.boolean, false)
        }), {})
        
    })
    .views(self => ({
        get isSaving() {
            return self.client_state.isSaving
        },

        get allIDsAreEmpty() {
            return isEmpty(self.student_ids) && isEmpty(self.guardian_ids) && isEmpty(self.employee_ids) && isEmpty(self.user_ids)
        },
    }))
    .actions(self => ({
        markSaving(flag){
            self.client_state.isSaving = flag
        },

        afterCreate() {
            onPatch(self, (patch) => {
                const { path, value } = patch
                if (!value && path === "/send_to_student_guardians") self.send_to_students = true
            })
        },


    }))