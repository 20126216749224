import './Sidebar.css'

import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react';
import SidebarToggle from './SidebarToggle'
import SidebarHead from './SidebarHead'
import SidebarFooter from './SidebarFooter'
import { sidebar } from './index'
import SidebarSettingsPane from './SidebarSettingsPane';

function Sidebar({ children }) {
    const sidebarClass = classNames('sidebar', {
        'sidebar-isHidden': !sidebar.isVisible
    })
    return (
        <>
            <SidebarToggle />
            <div className={sidebarClass}>
                <SidebarHead />
                <div className="sidebarItemList">
                    {children}
                </div>
                <SidebarFooter />
            </div>
            <SidebarSettingsPane />
        </>
    )
}

export default observer(Sidebar)
