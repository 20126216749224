import { types } from "mobx-state-tree"
import { Attendance } from "./Attendance";
import { Student } from './Student'
import {  SafeJSONReference } from '../types/JSONAPIReferenceTypes'

export const BatchAttendanceRegister = types
    .model("BatchAttendanceRegister", {
        id: types.identifier,
        type: "batch_attendance_register",
        attributes: types.maybe(types.model({
            in_session_dates: types.array(types.string),
            holiday_event_dates_and_titles: types.map(types.string),
            attendances: types.model(types.frozen, {})
        })),
        relationships: types.maybeNull(types.model({
            attendances: types.model({
                data: types.array(SafeJSONReference(Attendance))
            }),
            students: types.model({
                data: types.array(types.model({
                    id: types.late(() => types.reference(Student))
                }))
            }),
        })),
        isLoading: true
    })
    .views(self => ({
        get attendances() {
            try {
                return self.relationships
                .attendances
                .data
                .map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        },
        get students() {
            try {
                return self.relationships
                .students
                .data
                .map(({id}) => id)
            } catch (e) {
                console.error(e)
                return []
            }
        }
    }))
    .actions(self => ({
        markLoading: (loading) => {
            self.isLoading = loading
        },
        update({ id, attributes, relationships }) {
            self.id = id
            self.attributes = attributes
            self.relationships = relationships
        },
        addAttendance(  {id, type} ) {
            const { data } = self.relationships.attendances
            const foundIndex = data.findIndex(({ id: stuID }) => stuID === id)
            if(foundIndex < 0){
                self.relationships.attendances.data.push( {id, type})
            }
        }
        
    }))