import axios from './axios'
import { TESTENV } from './constants'

import indexJSON from '../json/report_card_templates.json'
import showJSON from '../json/report_card_template.json'


export function ReportCardTemplateService(env) {
    async function index() {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/configuration/reports/report_card_templates`)
            return data
        }
        return indexJSON
    }

    async function show(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/configuration/reports/report_card_templates/${id}`)
            return data
        }
        return showJSON
    }


    return {
        index,
        show
    }
}

export default ReportCardTemplateService(TESTENV)