import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import ModalForm from '../containers/ModalForm';

@inject('store')
@observer
class ReEnrollStudent extends React.Component {
    static propTypes = {
        studentId: PropTypes.string.isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    handleSubmit = () => {
        const { store, studentId } = this.props
        const { studentStore } = store
        const { reEnrollStudentById } = studentStore
        reEnrollStudentById(studentId)
        const { onClose } = this.props
        onClose()
    }

    render() {
        const {store : {studentStore: {isLoading}}, onClose} = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Restore Student"
                okButtonType="success"
                isOkButtonDisabled={isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--info"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">
                            Are you sure you want to perform this action?
                        </div>
                        Use this option to restored dropped or graduated students back to actively enrolled students.
                    </div>
                </div>
            </ModalForm>
        )
    }
}
export default ReEnrollStudent