import ApplicationPolicy from "./ApplicationPolicy";

class EventPolicy extends ApplicationPolicy{
    
    get can_index(){
        return this.user.has_role('event_management')
    }
    
    get can_view_calendar(){
        return true
    }

    // eslint-disable-next-line class-methods-use-this
    get can_show(){
        // FIXME: function is_user_event has not been created.
        // For now we rely on the API only for access control
        // return this.user.has_role('event_management') || this.record.is_user_event(this.user)
        return false
    }

    get can_create(){
        return this.user.has_role('event_management')
    }

    get can_update(){
        return this.user.has_role('event_management')
    }

    get can_destroy(){
        return this.user.has_role('event_management')
    }
}

export default EventPolicy