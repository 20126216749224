import './Feedback.css'

import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose';
import { observer } from 'mobx-react'
import route from "path-match"
import { onPatch } from 'mobx-state-tree';
import classNames from 'classnames'
import { feedback } from './index'

import error_white from './assets/error-white.svg'
import check_white from './assets/check-white.svg'

const Modal = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        delay: 300,
        transition: {
            y: { type: 'spring', stiffness: 500, damping: 20 },
            default: { duration: 300 }
        }
    },
    exit: {
        y: -50,
        opacity: 0,
        transition: { duration: 150 }
    }
});

@observer
class Feedback extends Component {
    state = { message: {} }

    componentDidMount() {
        onPatch(feedback, (patch) => {
            const { path, value: message } = patch
            const matcher = route()('/alerts/:id')
            const params = matcher(path)
            if (params.id) {
                this.setMessage(message)
                setTimeout(() => {
                    this.clearMessage()
                }, 10000);
            }
        })
    }

    setMessage = (message) => {
        this.setState({ message })
    }

    clearMessage = () => {
        this.setState({ message: {} })
    }

    render() {
        const { message } = this.state
        const { success, info, error, warning } = message
        const isHidden = Object.keys(message).length === 0
        const feedbackClass = classNames(
            'Feedback',
            'text-p',
            {
                'Feedback--success': success,
                'Feedback--error': error,
                'Feedback--info': info,
                'Feedback--warning': warning,
            },
        )

        return (
            <PoseGroup>
                {isHidden || [
                    <Modal key="modal" className={feedbackClass} onClick={this.clearMessage}>
                        {Boolean(error || warning) && <img src={error_white} alt="" />}
                        {Boolean(error || warning) || <img src={check_white} alt="" />}
                        {success || info || error || warning}
                    </Modal>
                ]}
            </PoseGroup>
        )
    }
}
export default Feedback