import {
    types
} from "mobx-state-tree"

export const SubjectBase = types
    .model("SubjectBase", {
        id: types.identifier,
        type: "subject_base",
        attributes: types.optional(types.model({
            name: "",
            short_name: "",
        }), {})
    })