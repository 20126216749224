// export function titleCase(phrase) {
//     if (!phrase) return phrase;
//     let revised = phrase.charAt(0).toUpperCase();

//     // eslint-disable-next-line no-plusplus
//     for (let i = 1; i < phrase.length; i++) {
//         if (phrase.charAt(i - 1) === " ") {
//             revised += phrase.charAt(i).toUpperCase();
//         }
//         else {
//             revised += phrase.charAt(i).toLowerCase();
//         }
//     }

//     return revised;
// }

export function titleCase(str) {
    if (!str) return ""

    const stripped = str.replace(/_/g, " ")
    const splitStr = stripped.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i += 1) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}