import ApplicationPolicy from "./ApplicationPolicy";

class EmployeePolicy extends ApplicationPolicy{
    get can_index(){
        return this.user.has_any_role( ['hr_control', 'employee_view'] )
    }

    get can_show(){
        return this.can_index || this.user.userable === this.record
    }

    get can_create(){
        return this.user.has_any_role(['hr_control'])
    }

    get can_update(){
        return this.can_create
    }

    get can_destroy(){
        return this.can_create
    }

    get can_to_archive(){
        return this.can_create
    }

    get can_update_privileges(){
        return this.user.is_admin
    }

    get can_view_privileges(){
        return this.user.has_any_role(['hr_control', 'employee_view'] ) || this.user.userable === this.record
    }

    get can_create_note() {
        return this.user.has_any_role([ 'hr_control', 'finance', 'finance_control'] )
    }
}

export default EmployeePolicy