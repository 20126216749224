import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-content-loader'

export default function ContentLoader({ children, ...restProps }) {
  return (
    <Loader {...restProps}>
      {children}
    </Loader>
  )
}
ContentLoader.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  speed: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string
}
ContentLoader.defaultProps = {
  speed: 4,
  primaryColor: "#f7f7f7",
  secondaryColor: "#ecebeb",
  height: "",
  width: ""
}
