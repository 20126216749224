import FileSaver from "file-saver";
import * as ReactGA from "react-ga";
import { types, flow } from "mobx-state-tree"


import { FeeTransaction } from "./models/FeeTransaction";
import { FeeTransactionSearch } from "./models/FeeTransactionSearch";
import { filterType } from "./helpers/filterType";
import {CollectionPattern} from "./patterns/collectionPattern";

export const FeeTransactionStore = types
    .compose(
        CollectionPattern({
            collectionType: FeeTransaction,
            searchType: FeeTransactionSearch
        }),
        types
            .model("FeeTransactionStore", {})  
            .views(self => ({
                get service() {
                    return self.bluebic.feeTransactionService
                },
                get searchService() {
                    return self.service.search
                },
            }))
            .actions(self => {
                
                function onUpdate(included) {
                    filterType("student_fee_transaction", included, self.updateCollection)
                }
                
                const downloadFeeTransactionsSpreadsheet = flow(function* downloadFeeTransactionsSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadFeeTransactionsSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Fee Transactions Data',
                            nonInteraction: false,
                            value: action.item_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of Fee Transactions`, err)
                        action.markSaving(false)
                    }
                })

                const fetchFinanceSummary = flow(function* fetchFinanceSummary(IDs) {
                    try {
                        const {data} = yield self.bluebic.feeTransactionService.fetchFinanceSummary(IDs)
                        return {data}
                    } catch (err) {
                        console.error("Failed to fetch fee transactions summary", err)
                        return {error: "Failed to fetch fee transactions summary"}
                    }
                })

                return {
                    onUpdate,
                    downloadFeeTransactionsSpreadsheet,
                    fetchFinanceSummary
                }
            }),
    )

