import './FinanceList.css'

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {Row, Col, } from 'antd';
import classNames from "classnames";

import { formatDate } from '../lib/formatDate'
import Card from '../common/card'

import moneyIcon from '../common/assets/money-bag.svg'
import {ExportCollection} from "../store/actions/ExportCollection";
import {ModalContext} from "../common/modal";
import {FeeTransactionsSummary} from "./FinanceTransactionsSummary";
import calculator from "../common/assets/calculator.svg";
import spreadsheet from "../common/assets/spreadsheet.svg";
import Loading from "../common/loading/Loading";
import Button from "../common/button";
import arrowDown from "../common/assets/arrow-down-white.svg";
import Collection from "../common/Collection";
import FeeTransactionSearch from "./FeeTransactionSearch";

const columns = [
    { key: 'trans_ref', render: 'Receipt No.' },
    { key: 'fee_type_name', render: 'Fee Type' },
    { key: 'fee_description', render: 'Description' },
    { key: 'transaction_date', render: 'Trans. Date' },
    { key: 'payment_method_name', render: 'Pay. Method' },
    { key: 'amount', render: `Amount` },
    { key: 'student_name', render: `Student` },
    { key: 'created_by_name', render: 'Recorded By' },
    { key: 'created_at', render: 'Creation Date' },
];
const defaultColumns = [
    'trans_ref', 'fee_type_name', 'fee_description',
    'transaction_date', 'payment_method_name', 'amount'
];

@inject('store')
@observer
class ActionsButton extends Component {

    constructor(props) {
        super(props)
        const exportFeeTransactionsAction = ExportCollection.create({})
        this.state = { exportFeeTransactionsAction }
    }

    initiateFeeTransactionsSpreadsheetDownload = () => {
        const { exportFeeTransactionsAction } = this.state
        const {store} = this.props;
        const { feeTransactionStore: {selectedItemKeys, downloadFeeTransactionsSpreadsheet}} = store

        exportFeeTransactionsAction.setToGetItems(selectedItemKeys.toJSON())
        downloadFeeTransactionsSpreadsheet(exportFeeTransactionsAction)
    }

    render() {
        const { exportFeeTransactionsAction: {isSaving} } = this.state
        const {store, ...rest} = this.props;
        const { feeTransactionStore: {selectedItemKeys}, alert} = store

        const selectedStudentsButtonClass = classNames(
            "Button Button--clear touchable-opacity text-p",
            {"Button--disabled": selectedItemKeys.length === 0}
        )

        const popover = (
            <ModalContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="button"
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                context.setContent(
                                    `Finance Summary for ${selectedItemKeys.length} Transactions`,
                                    <FeeTransactionsSummary feeTransactionIDs={selectedItemKeys} />,
                                    true
                                )
                            }}
                        >
                            <img src={calculator} style={{ width: 18 }} className="utils-prepend" alt="" />
                            Transactions Summary
                        </button>
                        <div className="divider" />
                        <div
                            className={selectedStudentsButtonClass}
                            onClick={() => {
                                if (selectedItemKeys.length === 0) {
                                    alert({message: {warning: "You must select at least one transaction"}})
                                    return
                                }
                                if (isSaving) {
                                    alert({message: {warning: "Wait for current download to finish."}})
                                    return
                                }
                                this.initiateFeeTransactionsSpreadsheetDownload()
                            }}
                        >
                            {isSaving || <img src={spreadsheet} style={{width: 18}} className="utils-prepend" alt="" />}
                            <span className="text-p">{isSaving ? (<Loading inline message='Downloading...' contentWrapperLoader={false} fontSize={13} color='#3B3B3B' />) : 'Export to Spreadsheet'}</span>
                        </div>
                    </>
                )}
            </ModalContext.Consumer>
        )
        return (
            <Button {...rest} popover={{content: popover, placement: 'bottomLeft'}}>
                Actions
                <img className="utils-append" src={arrowDown} alt="" />
            </Button>
        )
    }
}


const FeeTransactions = inject("store")(
    observer(({ store }) => {
        const {
            feeTransactionStore: settings,
            view: {
                openFinanceTransactionPageById,
                setBackPage,
                getFeeTransactionsPage
            },
            formatCurrency
        } = store

        const {feeTransactionStore: {selectedItemKeys, searchResults}} = store

        const tableData = searchResults
            .map((feeTransaction) => {
                const {
                    id,
                    attributes: {
                        amount,
                        created_at
                    },
                    financeTransaction: {
                        id: financeTransactionID,
                        attributes: {
                            trans_ref,
                            transaction_date,
                            payment_method_name,
                            created_by_name,
                        },
                        studentName: student_name
                    },
                    studentFee: {
                        attributes: {
                            fee_type_name,
                            fee_description
                        }
                    }
                } = feeTransaction
                return {
                    id,
                    trans_ref: (
                        <a
                            href={`/finance/transactions/${financeTransactionID}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Fee Transactions', getFeeTransactionsPage())
                                openFinanceTransactionPageById(financeTransactionID)
                            }}
                        >
                            {trans_ref}
                        </a>
                    ),
                    fee_type_name,
                    fee_description,
                    transaction_date: formatDate(transaction_date),
                    payment_method_name,
                    amount: formatCurrency(amount),
                    student_name,
                    created_by_name,
                    created_at: formatDate(created_at)
                }
            })
        const tableSettings = {
            tableData,
            columns,
            defaultColumns,
            tableProps: {}
        }
        const buildMobileItem = feeTransaction => (
            <FeeTransactionsItem key={feeTransaction.id} feeTransaction={feeTransaction} />
        )
        const buildHeaderComponent = ({ searchComponent, collectionSummaryText }) => (
            <>
                {searchComponent}
                <div className="flex-row flex-spaceBetween">
                    <div className="flex-row">
                        <ActionsButton className="utils-prepend" />
                        {Boolean(selectedItemKeys.length) && (`${selectedItemKeys.length} selected`)}
                    </div>
                    {collectionSummaryText}
                    <div>&nbsp;</div>
                </div>
            </>
        )
        const buildMobileHeader = ({ searchComponent }) => (
            <>
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={12}>
                        <img src={moneyIcon} alt="" />
                        <span className="text-small utils-append text--uppercase">Fee Payments</span>
                    </Col>
                    <Col span={12}>&nbsp;</Col>
                </Row>
                <Row gutter={15} type="flex" align="middle">
                    <Col span={12}>
                        <ActionsButton fullwidth />
                    </Col>
                    <Col span={12}>
                        {searchComponent}
                    </Col>
                </Row>
            </>
        )

        return (
            <Collection
                settings={settings}
                tableSettings={tableSettings}
                buildHeader={buildHeaderComponent}
                buildMobileHeader={buildMobileHeader}
                buildMobileItem={buildMobileItem}
                useSearchComponent={FeeTransactionSearch}
            />
        )
    }))








export default FeeTransactions

const FeeTransactionsItem = inject("store")(
    observer(({ store, feeTransaction }) => {
        const { formatCurrency, view: { openFinanceTransactionPageById } } = store
        const {
            id,
            attributes: {
                amount,
            },
            financeTransaction: {
                id: financeTransactionID,
                attributes: {
                    trans_ref,
                    transaction_date,
                    payment_method_name,
                },
            },
            studentFee: {
                attributes: {
                    fee_type_name,
                    fee_description
                }
            }
        } = feeTransaction
        return (
            <Card key={id} className="mb-1">
                <Row gutter={15} type="flex" align="middle" className="mb-1">
                    <Col span={8} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Receipt No.</div>
                        <a
                            href={`/finance/transactions/${financeTransactionID}`}
                            onClick={(e) => {
                                e.preventDefault()
                                openFinanceTransactionPageById(financeTransactionID)
                            }}
                        >
                            {trans_ref}
                        </a>
                    </Col>
                    <Col span={16} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Fee Type</div>
                        <div>{fee_type_name}</div>
                    </Col>
                    <Col span={24} className="mb-1">
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Fee Description</div>
                        <div>{fee_description}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Amount</div>
                        <div>{formatCurrency(amount)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Trans. Date</div>
                        <div>{formatDate(transaction_date)}</div>
                    </Col>
                    <Col span={8}>
                        <div className="text-small text--bold text--uppercase text--spacedLetters">Pay Method</div>
                        <div>{payment_method_name}</div>
                    </Col>
                </Row>
            </Card>
        )
    })
)