import { types} from "mobx-state-tree"
import { BankAccount } from "./models/BankAccount";
import { filterType } from "./helpers/filterType";

export const BankAccountStore = types
    .model("BankAccountStore", {
        bankAccounts: types.map(BankAccount),
    })
    .actions(self => {

        function updateBankAccounts(data) {
            data.forEach(json => self.bankAccounts.put(json))
        }

        function onUpdate(included) {
            filterType("bank_account", included, updateBankAccounts)
        }
        
        return {
            onUpdate
        }
    })

