import './StudentFinance.css'

import React, {Component} from 'react'
import * as PropTypes from "prop-types";
import { Popconfirm, Icon, Checkbox, Popover } from 'antd'
import { observer, inject } from 'mobx-react'

import { formatDate } from '../lib/formatDate';

import Table from '../common/table'
import Card from '../common/card'
import Button from '../common/button'
import Tabs, { Tab } from '../common/tabs'
import Desktop from '../common/display/Desktop'
import Mobile from '../common/display/Mobile';
import { ModalContext } from '../common/modal'
import MobileConfirmDialog from '../common/mobile_confirm_dialog'
import NotDesktop from '../common/display/NotDesktop';
import NotMobile from '../common/display/NotMobile';

import PayFees, { handlePayFees } from './PayFees';
import { handleDebitWallet, handleCreditWallet } from './CreateWalletTransaction';

import bank from './assets/bank.svg'
import more from './assets/more-vert.svg'
// eslint-disable-next-line no-unused-vars
import pdf from './assets/pdf.svg'
import WalletTransactionPolicy from "../policies/WalletTransactionPolicy";
import Alert from "../common/alert";

const tableColumns = [
    { key: 'feeType', render: 'Fee Type' },
    { key: 'description', render: 'Description' },
    { key: 'feeAmount', render: 'Fee Amount' },
    { key: 'amountPaid', render: 'Amount Paid' },
    { key: 'balance', render: 'Amount Yet Paid' },
    { key: 'dateCreated', render: 'Date Created' },
    { key: 'actions', render: <div>&nbsp;</div> },
]

const studentTransactionsTableColumns = [
    { key: 'trans_ref', render: 'Receipt Number' },
    { key: 'payment_method_name', render: 'Pay. Method' },
    { key: 'created_by_name', render: 'Recorded By' },
    { key: 'amount_received', render: 'Amount In' },
    { key: 'amount_payed_out', render: 'Amount Out' },
    { key: 'created_at', render: 'Date' },
]


function StudentFinance({ store, studentData }) {
    const {formatCurrency} = store
    const { financeTransactions } = studentData
    const { attributes: {wallet_balance, due_fees_balance} } = studentData
    
    const formattedWalletBalance = formatCurrency(wallet_balance)
    const formattedDueFeesBalance = formatCurrency(due_fees_balance)
    
    const tabs = [
        new Tab("Due", <StudentFees />, true),
        new Tab("Transactions", <StudentTransactions transactionsData={financeTransactions} />)
    ]
    
    const tabContent = studentData.isLoading ? <></> : (
        <>
            {/* <button
                type="button"
                className="
                    touchable-opacity
                    flex-row
                    flex-alignCenter
                    flex-justifyEnd
                    text-p
                    printBtn
                "
            >
                <img src={pdf} alt="pdf" className="utils-prepend" />
                Print
            </button> */}
            <Desktop>
                <div className="flex-row utils-fullwidth">
                    <div
                        style={{ marginRight: 30 }}
                        className="text--uppercase text-small text--spacedLetters"
                    >
                        Student Fee Summary
                    </div>
                    <div style={{ marginRight: 30 }}>
                        <span className="text--grey">Wallet Balance:</span>
                        <span className="text--bold text--primary utils-append">{formattedWalletBalance}</span>
                    </div>
                    <div style={{ marginRight: 30 }}>
                        <span className="text--grey">Due Fees:</span>
                        <span className="text--bold text--danger utils-append">{formattedDueFeesBalance}</span>
                    </div>
                </div>
            </Desktop>
            <NotDesktop>
                <div className="utils-fullwidth utils-padTop utils-padBottom StudentFinanceSummary">
                    <div className="text--uppercase text-small text--spacedLetters">
                        Student Fee Summary
                    </div>
                    <div className=" flex-row StudentFinanceSummary-detail">
                        <div style={{ marginRight: 10 }}>
                            <span className="text--grey">Wallet Balance:</span>
                            <span className="text--bold text--primary utils-append">{formattedWalletBalance}</span>
                        </div>
                        <div>
                            <span className="text--grey">Due Fees:</span>
                            <span className="text--bold text--danger utils-append">{formattedDueFeesBalance}</span>
                        </div>
                    </div>
                </div>
            </NotDesktop>

        </>
    )
    
    return <Tabs tabs={tabs} content={tabContent} responsive={false} />
}

export default inject("store")(observer(StudentFinance))

const StudentFees = inject("store")(
    observer(({ store}) => {
        const {studentStore: {deleteFeeById}, view, formatCurrency, meta: {currentUser}} = store
        const {selectedStudent: student} = view

        const {
            id: studentID,
            unpaidStudentFees,
            selectUnpaidFeeById,
            selectAllUnpaidFees,
            areAllUnpaidFeesSelected,
            selectedUnpaidFees,
            totalSelectedUnpaidFees,
            totalUnpaidStudentFees,
            isLoading
        } = student
        
        const tableRows = unpaidStudentFees
            .map(({ id, attributes, balance }) => ({
                id,
                feeType: attributes.fee_type_name,
                description: attributes.fee_description,
                feeAmount: formatCurrency(attributes.fee_amount),
                amountPaid: formatCurrency(attributes.total_paid),
                balance: formatCurrency(balance),
                dateCreated: formatDate(attributes.created_at),
                actions: (
                    <StudentFeesItemActions
                        key={id}
                        feeID={id}
                        studentID={studentID}
                        handleDelete={deleteFeeById}
                    />
                )
            }))

        const feeIdsToPay = selectedUnpaidFees.length > 0 ? selectedUnpaidFees.toJSON() : unpaidStudentFees.map(({id}) => id)
        
        const walletTransactionPolicy = new WalletTransactionPolicy(currentUser, student)
        
        return (
            <div>
                {!isLoading && (
                    <div className="StudentFees-header">
                        <div className="StudentFees-header-left">
                            <ModalContext.Consumer>
                                {(context) => (
                                    <Button
                                        buttonType="success"
                                        onClick={() => handlePayFees(context, feeIdsToPay, studentID )}
                                    >
                                        { totalUnpaidStudentFees > 0 && (
                                            <>Pay {selectedUnpaidFees.length > 0 ? `Selected (${selectedUnpaidFees.length})` : 'Due Fees'}</>
                                        ) }
                                        
                                        { totalUnpaidStudentFees <= 0 && (
                                            <>Credit Student Wallet</>
                                        ) }
                                        
                                    </Button>
                                )}
                            </ModalContext.Consumer>
                            {selectedUnpaidFees.length > 0 && (
                                    <span className="utils-append text-p">
                                        Selected Fees: {formatCurrency(totalSelectedUnpaidFees)}
                                    </span>
                                )
                            }
                        </div>
                        {
                            currentUser.is_employee_or_admin && (
                                <div className="StudentFees-header-right">
                                    <ModalContext.Consumer>
                                        {(context) => (
                                            <>
                                                <Button
                                                    onClick={() => handleDebitWallet(context)}
                                                    disabled={!walletTransactionPolicy.can_create}
                                                    title={walletTransactionPolicy.can_create ? undefined : 'No permission.'}
                                                >
                                                    <img src={bank} alt="" className="utils-prepend" />
                                                    Debit Wallet
                                                </Button>
                                                <Button
                                                    buttonType="success"
                                                    disabled={!walletTransactionPolicy.can_create}
                                                    title={walletTransactionPolicy.can_create ? undefined : 'No permission.'}
                                                    onClick={() => handleCreditWallet(context)}
                                                >
                                                    <img src={bank} alt="" className="utils-prepend" />
                                                    Credit Wallet
                                                </Button>`
                                            </>
                                        )}
                                    </ModalContext.Consumer>
                                </div>
                            )
                        }
                    </div>
                )}

                { !student.unpaidStudentFees.length && (
                    <div className="mb-1">
                        <Alert
                            type="success"
                            title="Hurray!"
                            message="No pending unpaid fees."
                        />
                    </div>
                )}

                { student.unpaidStudentFees.length > 0 && (
                    <>
                        <Desktop>
                            <Card>
                                <Table
                                    columns={tableColumns}
                                    data={tableRows}
                                    striped
                                    responsive
                                    onRowSelectionChange={selectUnpaidFeeById}
                                    selectedRows={selectedUnpaidFees}
                                    isAllSelected={areAllUnpaidFeesSelected}
                                    onSelectAll={selectAllUnpaidFees}
                                    loading={isLoading}
                                />
                            </Card>
                        </Desktop>
                        <NotDesktop>
                            {student.unpaidStudentFees.map((feeData) => (
                                <StudentFeesItem
                                    key={feeData.id}
                                    feeData={feeData}
                                    studentData={student}
                                    handleDelete={deleteFeeById}
                                />
                            ))}
                        </NotDesktop>
                    </>
                )}
            </div>
        )
    })
)

const StudentFeesItemActions = inject("store", "display")(
    observer(
        ({ feeID, studentID, handleDelete, display, store }) => {
            
            const {meta: {currentUser}} = store

            if (display.isMobile) {
                return (
                    <ModalContext.Consumer>
                        {context => (
                            <>
                                <PayFees studentFeeIds={[feeID]} studentID={studentID} />
                                
                                {
                                    currentUser.is_employee_or_admin && (
                                        <button
                                            onClick={() => context.setContent(
                                                "Unassigning student fee...",
                                                <MobileConfirmDialog
                                                    handleConfirm={() => {
                                                        handleDelete(feeID)
                                                        context.closeModal()
                                                    }}
                                                    handleCancel={context.closeModal}
                                                />,
                                                true
                                            )}
                                            type="button"
                                            className="Button Button--clear touchable-opacity text--danger text-p"
                                        >
                                            Unassign
                                        </button>
                                    )
                                }
                            </>
                        )}
                    </ModalContext.Consumer>
                )
            }
            
            return (
                <div className="flex-row">
                    <PayFees studentFeeIds={[feeID]} studentID={studentID} />
                    
                    { currentUser.is_employee_or_admin && (
                        <Popconfirm
                            title="Are you sure you want to unassign this fee?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={() => handleDelete(feeID)}
                        >
                            <button type="button" className="touchable-opacity text--danger text-p utils-append">
                                Unassign
                            </button>
                        </Popconfirm>
                    )}
                </div>
            )
        }
    )
)

@inject("store")
class StudentFeesItem extends Component {
    static propTypes = {
        feeData: PropTypes.any,
        studentData: PropTypes.any,
        handleDelete: PropTypes.func.isRequired
    }
    
    render() {
        const {store, feeData, studentData, handleDelete} = this.props;
        const {formatCurrency} = store
        
        const {id, attributes, balance} = feeData
        const {fee_type_name, fee_description, fee_amount, total_paid} = attributes
        const {selectUnpaidFeeById, selectedUnpaidFees} = studentData

        function renderField(label, value) {
            return (
                <div className="flex-col flex-alignStart">
                    <div className="text-small text--bold text--spacedLetters">
                        {label}
                    </div>
                    <div className={label === 'Unpaid' ? "text--danger" : "text--primary"}>{value}</div>
                </div>
            )
        }

        return (
            <Card className="StudentFeesItem">
                <div className="flex-col flex-alignStart">
                    <div className="text--uppercase text--spacedLetters">
                        {fee_type_name} &middot; {formatCurrency(fee_amount)}
                    </div>
                </div>
                <div>{fee_description}</div>
                {renderField('Unpaid', formatCurrency(balance))}
                {renderField('Paid', formatCurrency(total_paid))}

                <Checkbox
                    checked={selectedUnpaidFees.includes(id)}
                    onChange={() => selectUnpaidFeeById(id)}
                    className="StudentFeesItem-checkbox"
                />

                <Popover
                    content={(
                        <div className="StudentFeesItem-buttonGroup flex-row">
                            <StudentFeesItemActions studentID feeID={id} handleDelete={handleDelete} />
                        </div>
                    )}
                >
                    <img className="touchable-opacity flex-justifySelfEnd flex-row StudentFeesItem-menu" src={more} alt="more" />
                </Popover>
            </Card>
        )
    }
}

const StudentTransactions = inject("store")(
    observer(({ store, transactionsData }) => {
        const {
            view: {
                setBackPage,
                getStudentPage,
                openFinanceTransactionPageById,
                selectedStudent
            },
            formatCurrency
        } = store
        
        const tableRows = transactionsData
            .map(({ id, attributes }) => {
                const { trans_ref, payment_method_name, created_by_name, amount_received, amount_payed_out, created_at } = attributes
                return ({
                    id,
                    trans_ref: (
                        <a
                            href={`/finance/transactions/${id}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setBackPage('Student profile', getStudentPage())
                                openFinanceTransactionPageById(id)
                            }}
                        >
                            {trans_ref}
                        </a>
                    ),
                    payment_method_name,
                    created_by_name,
                    amount_received: formatCurrency(amount_received),
                    amount_payed_out: formatCurrency(amount_payed_out), 
                    created_at: formatDate(created_at)
                })
            })
        
        return (
            <>
                <NotMobile>
                    <Table
                        columns={studentTransactionsTableColumns}
                        data={tableRows}
                        striped
                        responsive
                        loading={selectedStudent.isLoading}
                    />
                </NotMobile>
                <Mobile>
                    {tableRows.length === 0 && (
                        <Alert type="notice" message="No Finance Transactions" />
                    )}
                    {tableRows.map((item) => <StudentTransactionsItem key={item.id} keys={item} />)}
                </Mobile>
            </>
        )
    })
)

function StudentTransactionsItem({ keys }) {
    return (
        <Card className="StudentTransactionsItem">
            {studentTransactionsTableColumns.map(({ key, render }) => (
                <div key={key}>
                    <div className="text-small text--uppercase text--bold text--spacedLetters">{render}</div>
                    <div className="text-p">{keys[key]}</div>
                </div>
            ))}
        </Card>
    )
}